import React from 'react';
import Modal from 'Components/common/base/Modal';
import ControlInput from 'Components/common/base/ControlInput';
import Button from 'Components/common/base/Button';

function SelectExportType(props) {
  const {
    closePopup,
    addSequence,
    getToggleValue,
    addContact,
    continueExport,
  } = props;

  return (
    <Modal className="salesgear-select-type" show closeModal={closePopup}>
      <Modal.Title>Salesgear Export</Modal.Title>
      <Modal.Content>
        <ControlInput
          type="radio"
          label="Add to a Sequence"
          value="addToSeq"
          checked={addSequence}
          onChange={getToggleValue}
        />
        <ControlInput
          type="radio"
          label="Export to Salesgear"
          value="exportContact"
          checked={addContact}
          onChange={getToggleValue}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button buttonProps={{ text: 'Continue' }} onClick={continueExport} />
        <Button
          buttonProps={{ text: 'Cancel' }}
          onClick={closePopup}
          variant="outline"
        />
      </Modal.Footer>
    </Modal>
  );
}

export default SelectExportType;
