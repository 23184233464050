import React, { useState } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import SalesgearExportCRM from 'Components/salesgear/SalesgearExportCRM';
import SalesgearUpgradePopup from 'Components/salesgear/SalesgearUpgradePopup';
import SalesgearConnectInbox from 'Components/salesgear/SalesgearConnectInbox';
import SalesforceExportIcon from 'Assets/svg/export_icons/salesforce.svg';
import HubspotExportIcon from 'Assets/svg/export_icons/hubspot.svg';
import PipedriveExportIcon from 'Assets/svg/export_icons/pipedrive.svg';
import ZohoExportIcon from 'Assets/svg/export_icons/zoho.svg';
import CsvExportIcon from 'Assets/svg/export_icons/csv.svg';
import OutreachExportIcon from 'Assets/svg/export_icons/outreach.svg';
import MarketoExportIcon from 'Assets/svg/export_icons/marketo.svg';
import SalesgearIcon from 'Assets/svg/export_icons/salesgear.svg';
import SalesgearAPIConnect from 'Components/salesgear/SalesgearAPIConnect';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import advSearchState from 'Stores/advSearch';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import myLeadsState from 'Stores/myLeads';
import userDetail from 'Stores/userDetail';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import ExportLeadsTypePopup, { exportTypeState } from './exportType';
import PipedriveAuthenticationPopup, {
  pipedriveExportState,
} from './pipedriveAuthenticationPopup';
import MarketoAuthenticationPopup, {
  marketoExportState,
} from './marketoAuthenticationPopup';
import OutreachSequencePopup, {
  outreachSequenceState,
} from './outreachSequencePopup';
import OwnershipMappingPopup from '../OwnershipMapping';
import ownershipMappingState from '../OwnershipMapping/state';
import ChooseExportModal from '../ChooseExportModal';
import { commonModalState } from '../base/Modal/CommonModal';
import { showAssignOwnersPopup } from '../exportCompanies/modals';
import { showExportFailedModal, showExportStatusPopup } from './modals';
import './styles.scss';

const {
  CRM_EXPORT_TYPE,
  CRM_EXPORT_LIMIT,
  EXPORT_SOURCE,
  EXPORT_PAGE_SOURCE,
  CONTACT_EXPORT_TYPES,
  CRM_EXPORT_AS_TYPE,
  UPGRADE_TRIGGERS,
} = CONSTANTS;
const { ADVANCED_SEARCH, MY_LEADS } = EXPORT_SOURCE;
const { ASYNC_EXPORT, ASYNC_EXPORT_CRITERIA } = CONTACT_EXPORT_TYPES;

const exportOptions = [
  {
    text: 'CSV',
    Icon: CsvExportIcon,
  },
  {
    text: 'Salesgear',
    Icon: SalesgearIcon,
  },
  {
    text: 'Salesforce',
    Icon: SalesforceExportIcon,
  },
  {
    text: 'Zoho',
    Icon: ZohoExportIcon,
  },
  {
    text: 'Pipedrive',
    Icon: PipedriveExportIcon,
  },
  {
    text: 'Hubspot',
    Icon: HubspotExportIcon,
  },
  {
    text: 'Outreach',
    Icon: OutreachExportIcon,
  },
  {
    text: CRM_EXPORT_TYPE.MARKETO,
    Icon: MarketoExportIcon,
  },
];

const allExportTypes = [
  'CSV',
  'Salesgear',
  'Salesforce',
  'Zoho',
  // 'Pipedrive',
  'Hubspot',
  'Outreach',
  CRM_EXPORT_TYPE.MARKETO,
];

let oAuthWindow = null;

const defaultExportMxData = {
  pageType: 'AS', // AS/ML
  searchType: 'CONTACT',
};

class ExportLeadsState {
  @observable accessor showExportLeads = false;

  @observable accessor exportOption = '';

  @observable accessor showProcessingExport = false;

  @observable accessor exportPostData = {};

  @observable accessor exportMxData = defaultExportMxData;

  @observable accessor exportSource = '';

  additionalOptions = {
    callback: () => {},
    customExportCallback: null,
  };

  @action
  setShowExportLeads = (val, options = {}) => {
    this.showExportLeads = val;
    if (Object.keys(options).length > 0) {
      this.additionalOptions.callback = options.callback;
      this.additionalOptions.customExportCallback =
        options.customExportCallback;
    } else {
      this.additionalOptions = {
        callback: () => {},
        customExportCallback: null,
      };
    }
  };

  @action
  setExportOption = (val) => {
    this.exportOption = val;
  };

  @action
  setExportPostData = (val = {}) => {
    this.exportPostData = val;
  };

  @action
  setExportMxData = (val = defaultExportMxData) => {
    this.exportMxData = { ...this.exportMxData, ...val };
  };

  @action
  setExportSource = (val = '') => {
    this.exportSource = val;

    this.setExportMxData({ pageType: 'ML' });
  };

  @action
  setShowProcessingExport = (value) => {
    this.showProcessingExport = value;
  };

  @action
  checkIsAdvExport = () => {
    const thisObj = this;
    if (thisObj.exportSource === ADVANCED_SEARCH) {
      return true;
    }

    return false;
  };

  @action
  checkIsAdvBulkExport = () => {
    const thisObj = this;
    if (thisObj.exportSource === ADVANCED_SEARCH) {
      if (
        advSearchState &&
        (advSearchState.selectedContactFromRange ||
          advSearchState.selectedAllContactAcrossPage)
      ) {
        return true;
      }
    }

    return false;
  };

  @action
  checkAndExport = (str, source, exportOption, isExportEnabled) => {
    const thisObj = this;
    this.exportPostData = {};

    if (!isExportEnabled) {
      Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_UPGRADE_CLICK, {
        type: str,
        optionType: exportOption,
      });

      if (str === 'CSV') {
        commonUpgradePopupState.setShowUpgradePopup(
          UPGRADE_TRIGGERS.CSV_EXPORT,
        );
      } else {
        commonUpgradePopupState.setShowUpgradePopup(
          UPGRADE_TRIGGERS.CRM_EXPORT,
        );
      }
      thisObj.setShowExportLeads(false);
    }

    if (isExportEnabled) {
      Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_TYPE_CLICK, {
        type: str,
        optionType: exportOption,
      });

      if (
        this.additionalOptions.customExportCallback !== null &&
        typeof this.additionalOptions.customExportCallback === 'object' &&
        typeof this.additionalOptions.customExportCallback[str] === 'function'
      ) {
        this.additionalOptions.customExportCallback[str]();
        thisObj.setShowExportLeads(false);
      } else {
        if (str === 'CSV') {
          this.exportLeadsToCSV(str, source, exportOption);
          thisObj.setShowExportLeads(false);
        }
        if (str === 'Salesforce') {
          const exportAs =
            userDetail.userInfo.teamCRMPreferences?.SALESFORCE?.export_as;
          this.exportLeadsToCRM({
            type: CRM_EXPORT_TYPE.SALESFORCE,
            source,
            option: exportOption,
            ...(exportAs && { exportAs: exportAs.toUpperCase() }),
          });
        } else if (str === 'Zoho') {
          this.exportContactsToZoho(str, source, exportOption);
        } else if (str === 'Outreach') {
          this.exportContactsToOutreach(str, source, exportOption);
        } else if (str === 'Pipedrive') {
          this.exportLeadsToPipedrive(str, source, exportOption);
        } else if (str === 'Hubspot') {
          this.exportLeadsToCRM({
            type: CRM_EXPORT_TYPE.HUBSPOT,
            source,
            option: exportOption,
            exportAs: CRM_EXPORT_AS_TYPE.CONTACT,
          });
        } else if (str === CRM_EXPORT_TYPE.MARKETO) {
          this.exportLeadsToMarketo(str, source, exportOption);
        }

        thisObj.setShowExportLeads(false, this.additionalOptions);
      }
    }
  };

  @action
  exportContactsMakeApiCall = (apiUrl, callBack, appendPostData = false) => {
    const thisObj = this;
    const postData = Utils.copyObjectWithoutReference(thisObj.exportPostData);
    if (appendPostData) {
      postData.exportSource = thisObj.exportSource;
    }

    if (postData.exportRequestCount !== undefined) {
      delete postData.exportRequestCount;
    }

    const config = {
      url: apiUrl,
      method: 'POST',
      data: postData,
    };
    makeApi(config)
      .then((response) => {
        callBack(response);
        // thisObj.resultLoading = false;
      })
      .catch((error) => {
        // this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  };

  @action
  pipeDriveExport = (inputValues = null, selectedSrc) => {
    const thisObj = this;
    this.showProcessingExport = true;
    const apiConfig = {
      url: URLS.pipeDriveToken ? URLS.pipeDriveToken : '',
      method: 'POST',
      data: inputValues
        ? {
            token: inputValues.apikey ? inputValues.apikey : '',
            instanceUrl: inputValues.companyDomain
              ? inputValues.companyDomain
              : '',
          }
        : {},
    };
    this.showProcessingExport = true;
    makeApi(apiConfig)
      .then((res) => {
        this.showProcessingExport = false;
        const resData = res && res.data ? res.data : {};
        const { status, errorType } = resData || '';
        this.showProcessingExport = false;
        if (status === 'success') {
          this.constructExportStatusPopup('Success', resData, 'Pipedrive');
        } else if (status === 'failure' && errorType === 'generic') {
          Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_FAILED, {
            type: selectedSrc,
          });
          showExportFailedModal({ type: selectedSrc });
        } else {
          Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_FAILED, {
            type: selectedSrc,
          });
          showExportFailedModal({ type: selectedSrc, message: resData.msg });
        }
        thisObj.clearSelectedContacts();
      })
      .catch((error) => {
        this.showProcessingExport = false;
        console.log('Pipedrive error ', error);
      });
  };

  @action
  clearSelectedContacts = () => {
    const thisObj = this;
    if (thisObj.checkIsAdvExport()) {
      advSearchState.clearAllSelection(true);
    } else {
      myLeadsState.clearPreviouslySelectedContacts();
    }
  };

  @action
  constructExportStatusPopup = (
    statusType = 'Success',
    result,
    selectedSrc = '',
    customMsg,
  ) => {
    const thisObj = this;
    thisObj.clearSelectedContacts();

    if (!customMsg) {
      Utils.totangoTrack(selectedSrc, CONSTANTS.TOTANGO.MODULES.MY_LEADS);
      const { exportSuccessCount, exportFailedCount, duplicateLeadCount } =
        result || '';

      let totalCount = exportSuccessCount
        ? parseInt(exportSuccessCount, 10)
        : 0;
      totalCount += exportFailedCount ? parseInt(exportFailedCount, 10) : 0;
      totalCount += duplicateLeadCount ? parseInt(duplicateLeadCount, 10) : 0;

      const duplicateCount = duplicateLeadCount && duplicateLeadCount;
      if (exportSuccessCount > 0 || duplicateCount > 0) {
        thisObj.trackPurchaseEvent(selectedSrc, totalCount, null);
      } else {
        Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_FAILED, {
          type: selectedSrc,
          reason: customMsg !== undefined && customMsg !== '' ? customMsg : '',
        });
      }
    }

    showExportStatusPopup({
      type: selectedSrc,
      status: statusType,
      result,
      customMsg,
    });
    this.showProcessingExport = false;
  };

  @action
  exportLeadsToCSV = (type, source, option) => {
    const thisObj = this;

    let paramsBody = {};
    paramsBody = {
      export_type: type,
      source,
      exportSource: thisObj.exportSource,
    };
    let exportLeadsCount = 1;
    const selectedListData = userDetail.myLeadList.find((data) => {
      return data.id === myLeadsState.selectedList.id;
    });

    paramsBody.memberId = userDetail.userInfo.memberId;
    paramsBody.teamId = userDetail.userInfo.teamId;
    paramsBody.sharedList = selectedListData?.isSharedList || false;

    if (option === 'export selected') {
      paramsBody.listId = myLeadsState.selectedList.id || '*';
      paramsBody.contactIds = myLeadsState.bulkPurchaseIds;
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      paramsBody.listId = myLeadsState.selectedList.id;
      exportLeadsCount = myLeadsState.leadsResultCount;
    } else {
      exportLeadsCount = myLeadsState.leadsResultCount;
    }

    this.exportPostData = paramsBody;

    this.exportLeadsToCSVFlow(exportLeadsCount);
  };

  @action
  exportLeadsToCSVFlow = (exportLeadsCount) => {
    const thisObj = this;

    this.showProcessingExport = true;
    if (exportLeadsCount !== undefined && exportLeadsCount !== null) {
      thisObj.clearSelectedContacts();

      const csvExportCallback = (response) => {
        this.showProcessingExport = false;

        if (response.data?.status === 'success') {
          advSearchState.setAsyncPurchaseOrExportMsg('CSV_EXPORT');
        } else {
          advSearchState.setAsyncPurchaseOrExportMsg('UNKNOWN_ERROR');
        }
      };

      this.exportContactsMakeApiCall(URLS.exportToCSVV2, csvExportCallback);
    }
  };

  @action
  exportLeadsToPipedrive = (type, source, option) => {
    let paramsBody = {};
    paramsBody = {
      export_type: type,
      source,
    };
    let exportLeadsCount = 1;

    if (option === 'export selected') {
      paramsBody.contactIds = myLeadsState.bulkPurchaseIds;
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      paramsBody.listId = myLeadsState.selectedList.id;
      exportLeadsCount = myLeadsState.leadsResultCount;
    } else {
      exportLeadsCount = myLeadsState.leadsResultCount;
    }

    this.exportPostData = paramsBody;

    this.exportLeadsToPipedriveFlow(exportLeadsCount, true);
  };

  @action
  exportLeadsToPipedriveFlow = (exportLeadsCount, appendPostData = false) => {
    const thisObj = this;

    this.showProcessingExport = true;
    if (
      exportLeadsCount !== undefined &&
      exportLeadsCount !== null &&
      exportLeadsCount > CRM_EXPORT_LIMIT
    ) {
      thisObj.clearSelectedContacts();
      this.showProcessingExport = false;
      this.exportFailureLimit('Pipedrive', exportLeadsCount);
    } else {
      const pipedriveExportCallback = (response) => {
        this.showProcessingExport = false;

        if (response.data.status === 'auth_success') {
          this.pipeDriveExport(null, this.exportPostData.export_type);
        } else if (
          response &&
          response.data &&
          response.data.responseCode !== undefined &&
          response.data.responseCode === 102
        ) {
          this.showProcessingExport = false;
          const pipDriveCbk = (inputValues) => {
            this.pipeDriveExport(inputValues, this.exportPostData.export_type);
          };
          this.showProcessingExport = true;
          pipedriveExportState.setPopupValues({
            callback: pipDriveCbk,
          });
          this.showProcessingExport = false;
          pipedriveExportState.setShowPopup(true);
        } else {
          this.exportContactsResponseHandler(
            response,
            exportLeadsCount,
            'Pipedrive',
          );
        }
      };

      this.exportContactsMakeApiCall(
        URLS.exportLeadsToPipedrive,
        pipedriveExportCallback,
        appendPostData,
      );
    }
  };

  @action
  exportLeadsToMarketo = (type, source, option) => {
    const thisObj = this;

    let paramsBody = {};
    paramsBody = {
      export_type: type,
      source,
    };
    let exportLeadsCount = 1;

    if (option === 'export selected') {
      paramsBody.contactIds = myLeadsState.bulkPurchaseIds;
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      paramsBody.listId = myLeadsState.selectedList.id;
      exportLeadsCount = myLeadsState.leadsResultCount;
    } else {
      exportLeadsCount = myLeadsState.leadsResultCount;
    }

    this.exportPostData = paramsBody;

    if (exportLeadsCount > CRM_EXPORT_LIMIT) {
      thisObj.clearSelectedContacts();
      this.showProcessingExport = false;
      this.exportFailureLimit('Marketo', exportLeadsCount);
    } else {
      this.exportLeadsToMarketoFlow(exportLeadsCount, null, true);
    }
  };

  @action
  exportLeadsToMarketoFlow = (
    exportLeadsCount,
    authData,
    appendPostData = false,
  ) => {
    const thisObj = this;

    const authDataBackup = JSON.parse(JSON.stringify(authData));
    thisObj.showProcessingExport = true;
    const marketoExportCallback = (response) => {
      thisObj.showProcessingExport = false;
      if (authDataBackup === null) {
        marketoExportState.setAuthValues();
      }

      const marketoAuthCallback = (errorRes = null) => {
        const marketoCbk = (inputValues) => {
          thisObj.exportLeadsToMarketoFlow(exportLeadsCount, inputValues);
        };
        marketoExportState.setPopupValues({
          callback: marketoCbk,
        });
        if (errorRes !== null) {
          marketoExportState.setErrorResponse(errorRes);
        }
        marketoExportState.setShowPopup(true);
      };
      thisObj.exportContactsResponseHandler(
        response,
        exportLeadsCount,
        CRM_EXPORT_TYPE.MARKETO,
        marketoAuthCallback,
      );
    };

    if (authData !== null) {
      let { exportPostData } = thisObj;
      exportPostData = { ...exportPostData, ...authData };
      thisObj.exportPostData = exportPostData;
    }

    thisObj.exportContactsMakeApiCall(
      URLS.exportLeadsToMarketo,
      marketoExportCallback,
      appendPostData,
    );
  };

  @action
  exportContactsToOutreach = (type, source, option) => {
    let paramsBody = {};
    paramsBody = {
      export_type: type,
      source,
    };
    let exportLeadsCount = 1;

    if (option === 'export selected') {
      paramsBody.contactIds = myLeadsState.bulkPurchaseIds;
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      paramsBody.listId = myLeadsState.selectedList.id;
      exportLeadsCount = myLeadsState.leadsResultCount;
    } else {
      exportLeadsCount = myLeadsState.leadsResultCount;
    }

    this.exportPostData = paramsBody;

    this.exportContactsToOutreachFlow(exportLeadsCount, '', '', '', '', true);
  };

  @action
  exportContactsToOutreachFlow = (
    exportLeadsCount,
    assignSequence,
    sequenceId = '',
    mailBoxId = '',
    tagValue = '',
    appendPostData = false,
  ) => {
    const thisObj = this;

    this.showProcessingExport = true;
    if (
      exportLeadsCount !== undefined &&
      exportLeadsCount !== null &&
      exportLeadsCount > CRM_EXPORT_LIMIT
    ) {
      thisObj.clearSelectedContacts();
      this.showProcessingExport = false;
      this.exportFailureLimit('Outreach', exportLeadsCount);
    } else {
      const outreachExportCallback = (res) => {
        this.showProcessingExport = false;
        this.exportContactsResponseHandler(res, exportLeadsCount, 'Outreach');
      };
      const { exportPostData } = this;
      if (assignSequence !== undefined && assignSequence === true) {
        exportPostData.assignSequence = true;
      }
      if (sequenceId !== undefined && sequenceId !== '') {
        exportPostData.sequenceId = sequenceId;
      }
      if (mailBoxId !== undefined && mailBoxId !== '') {
        exportPostData.mailBoxId = mailBoxId;
      }
      if (tagValue !== undefined && tagValue !== '') {
        exportPostData.tag = tagValue;
      }

      this.exportPostData = exportPostData;

      this.exportContactsMakeApiCall(
        URLS.exportLeadsToOutReach,
        outreachExportCallback,
        appendPostData,
      );
    }
  };

  // Deprecate this after all export moved to exportContactsToCrmResponseHandler
  @action
  exportContactsResponseHandler = (
    res,
    exportLeadsCount,
    exportType,
    customCallback = null,
  ) => {
    const thisObj = this;

    if (res && res.data) {
      const response = res.data;
      const exportTypeVal =
        exportType !== undefined
          ? exportType
          : thisObj.exportPostData.export_type;
      if (response.responseCode === 102) {
        this.setShowExportLeads(false);
        if (customCallback !== null && exportType === CRM_EXPORT_TYPE.MARKETO) {
          if (
            response.errorCode !== undefined &&
            response.errorDescription !== undefined
          ) {
            customCallback(response);
          } else {
            customCallback();
          }
        } else {
          this.openOAuthWindow(
            response.authUrl,
            `${exportType} Login`,
            900,
            600,
            exportType,
            exportLeadsCount,
            this.exportContactsResponseHandler,
          );
        }
      } else if (response.responseCode === 103) {
        if (exportTypeVal === CRM_EXPORT_TYPE.OUTREACH) {
          this.showOutreachSequencePopUp();
        } else if (exportTypeVal === CRM_EXPORT_TYPE.SALESFORCE) {
          this.showPreferencePopUp(exportTypeVal);
        } else if (exportTypeVal === CRM_EXPORT_TYPE.ZOHO) {
          this.showPreferencePopUp(exportTypeVal, exportLeadsCount);
        } else if (exportTypeVal === CRM_EXPORT_TYPE.HUBSPOT) {
          this.askAssignOwnerShipPopup(exportTypeVal);
        }
      } else if (response.responseCode === 104) {
        if (exportTypeVal === CRM_EXPORT_TYPE.SALESFORCE) {
          thisObj.askAssignOwnerShipPopup(exportTypeVal);
        }
      } else if (response.responseCode === 200) {
        if (exportTypeVal === 'CSV' && response.exportSuccessCount > 0) {
          Utils.downloadToCsv(response);
        }

        this.setShowExportLeads(false);
        thisObj.clearSelectedContacts();
        this.constructExportStatusPopup('Success', response, exportType);
      } else if (response.error === 'limit' || response.errorType === 'limit') {
        this.showProcessingExport = false;
        thisObj.clearSelectedContacts();
        thisObj.exportFailureLimit(exportTypeVal, exportLeadsCount, 5000);
      } else if (response.responseCode === 429) {
        // Outreach range limit exceed
        let timeToShow = 60;
        if (
          response.retryAfter &&
          response.retryAfter !== undefined &&
          response.retryAfter !== ''
        ) {
          const dateVal = response.retryAfter;
          const newDate = new Date(parseInt(dateVal, 10));
          const curDate = new Date();

          const timestampDiff = newDate.getTime() - curDate.getTime();
          const secDiff = Math.floor(timestampDiff / 1000);
          const minsDiff = Math.floor(secDiff / 60);

          if (minsDiff > 45) {
            timeToShow = 60;
          } else if (minsDiff > 30) {
            timeToShow = 45;
          } else if (minsDiff > 15) {
            timeToShow = 30;
          } else {
            timeToShow = 15;
          }
        }

        const errorMsg = `You've reached Outreach's hourly limit. Please try again in another ${timeToShow} minutes`;
        this.constructExportStatusPopup('Failed', {}, exportType, errorMsg);
      } else if (
        response.responseCode === 500 &&
        response.exportSuccessCount &&
        parseInt(response.exportSuccessCount, 10) > 0 &&
        response.exportFailedCount &&
        parseInt(response.exportFailedCount, 10) > 0
      ) {
        const errorMsg = `We were able to export only ${parseInt(
          response.exportSuccessCount,
          10,
        )} contacts to ${exportType}. Please try again to export the remaining contacts.`;
        this.constructExportStatusPopup('Failed', {}, exportType, errorMsg);
      } else if (response.responseCode === 500) {
        if (response.errorMsg !== null && response.errorMsg !== undefined) {
          this.constructExportStatusPopup(
            'Failed',
            {},
            exportType,
            response.errorMsg,
          );
        } else {
          this.constructExportStatusPopup('Failed', {}, exportType);
        }
      } else {
        this.constructExportStatusPopup('Failed', {}, exportType);
      }
    } else {
      this.constructExportStatusPopup('Failed', {}, exportType);
    }
  };

  @action
  showOutreachSequencePopUp = () => {
    const thisObj = this;

    const outreachExportSequenceCallback = (res) => {
      thisObj.showProcessingExport = false;
      if (res && res.status === 200 && res.data !== undefined) {
        if (
          res.data.sequences &&
          res.data.sequences !== undefined &&
          res.data.mailBoxes &&
          res.data.mailBoxes !== undefined
        ) {
          const sequence = res.data.sequences;
          const mailboxes = res.data.mailBoxes;
          const seqKeys = Object.keys(sequence);
          const mailBoxKeys = Object.keys(mailboxes);
          if (seqKeys.length > 0) {
            const sequenceList = seqKeys.map((item) => {
              return {
                name: item,
                id: sequence[item],
              };
            });
            const mailBoxList = mailBoxKeys.map((item) => {
              return {
                name: item,
                id: mailboxes[item],
              };
            });
            const outreachSequenceSelectedCallback = (
              selectedSequenceId,
              selectedMailBoxId,
              tagValue,
            ) => {
              if (selectedSequenceId === '' && selectedMailBoxId === '') {
                thisObj.exportContactsToOutreachFlow(
                  null,
                  true,
                  '',
                  '',
                  tagValue,
                );
              } else {
                thisObj.exportContactsToOutreachFlow(
                  null,
                  true,
                  selectedSequenceId,
                  selectedMailBoxId,
                  tagValue,
                );
              }

              console.log('selectedSequenceId', selectedSequenceId);
            };
            thisObj.setShowExportLeads(false);

            outreachSequenceState.setPopupValues({
              sequenceList,
              mailBoxList,
              callback: outreachSequenceSelectedCallback,
            });
            outreachSequenceState.setShowPopup(true);
          }
        }
      }
    };
    this.showProcessingExport = true;
    this.exportContactsMakeApiCall(
      URLS.exportLeadsOutreachSequence,
      outreachExportSequenceCallback,
    );
  };

  @action
  showPreferencePopUp = (exportType, exportLeadsCount) => {
    const thisObj = this;
    const typeSelectedCallback = (selectedType, savePreference) => {
      const prePostData = thisObj.exportPostData;
      if (exportType === 'Salesforce') {
        prePostData.export_as = selectedType.toUpperCase();
      } else if (selectedType !== undefined) {
        prePostData.save_type = selectedType;
      }
      if (selectedType !== undefined) {
        prePostData.save_preference = savePreference;
      }
      thisObj.setExportPostData(prePostData);
      if (exportType === 'Zoho') {
        thisObj.exportContactsToZohoFlow(exportLeadsCount);
      } else if (exportType === 'Salesforce') {
        thisObj.askAssignOwnerShipPopup('Salesforce');
      }
    };
    this.setShowExportLeads(false, this.additionalOptions);
    const data = {
      title: exportType,
      type: exportType,
      callback: typeSelectedCallback,
    };
    if (exportType === 'Salesforce') {
      data.defaultPreferenceCheckBox = false;
    }
    exportTypeState.setPopupValues(data);
    exportTypeState.setShowPopup(true);
  };

  @action
  exportContactsToZoho = (type, source, option) => {
    const paramsBody = {
      export_type: type,
      source,
    };
    let exportLeadsCount = 1;

    if (option === 'export all') {
      if (
        myLeadsState.selectedList.id === null &&
        myLeadsState.selectedList.id === ''
      ) {
        paramsBody.selected_type = 'All';
        paramsBody.export_ids = 'Nothing';
        exportLeadsCount = myLeadsState.leadsResultCount;
      } else {
        paramsBody.selected_type = 'List';
        paramsBody.export_ids = myLeadsState.selectedList.id;
        exportLeadsCount = myLeadsState.leadsResultCount;
      }
    } else if (option === 'export selected') {
      paramsBody.selected_type = 'Checked';
      paramsBody.export_ids = myLeadsState.bulkPurchaseIds;
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
    }

    this.exportPostData = paramsBody;
    this.exportContactsToZohoFlow(exportLeadsCount, true);
  };

  @action
  exportContactsToZohoFlow = (zohoExportCount, appendPostData = false) => {
    const thisObj = this;
    this.showProcessingExport = true;

    if (zohoExportCount !== undefined && zohoExportCount > CRM_EXPORT_LIMIT) {
      thisObj.clearSelectedContacts();
      this.showProcessingExport = false;
      this.exportFailureLimit('Zoho', zohoExportCount);
    } else {
      const zohoExportCallback = (res) => {
        this.showProcessingExport = false;
        this.exportContactsResponseHandler(res, zohoExportCount, 'Zoho');
      };
      this.exportContactsMakeApiCall(
        URLS.exportSelectedLeadsToZoho,
        zohoExportCallback,
        appendPostData,
      );
    }
  };

  @action
  exportFailureLimit = (
    str,
    exportLeadsCount,
    exportLimit = CRM_EXPORT_LIMIT,
  ) => {
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_LIMIT_EXCEED, {
      type: str,
      triedLimit: exportLeadsCount,
      currentLimit: exportLimit,
    });

    commonModalState.setShowModal(true, {
      title: 'Limit Exceeded',
      description: `You can export a maximum of ${exportLimit} contacts in a single transaction.`,
      primaryButtonText: 'OK',
    });
  };

  @action
  openOAuthPopThruButClick = (tempOAuthData, callback) => {
    const { url, title, width, height, top, left, exportType } = tempOAuthData;

    window.addEventListener('message', (ev) => {
      if (ev.data && ev.data.adaptExport === true) {
        oAuthWindow.close();
        const response = {};
        response.data = ev.data;
        callback(response, null, exportType);
      }
    });
    oAuthWindow = window.open(
      url,
      title,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`,
    );

    if (exportType === 'ZOHO') {
      console.log('zoho openOAuthPopThruButClick');
    }
    // showZohoLoginIndicator();
  };

  @action
  openOAuthWindow = (
    url,
    title,
    width,
    height,
    exportType,
    exportCount,
    oAuthCallBack,
    askExportConfirmPopup = true,
  ) => {
    const thisObj = this;
    if (thisObj.oAuthWindow) {
      thisObj.oAuthWindow.close();
    }

    const left = window.screen.width / 2 - width / 2;
    const top = window.height / 2 - height / 2;

    const tempOAuthData = {};
    tempOAuthData.url = url;
    tempOAuthData.title = title;
    tempOAuthData.width = width;
    tempOAuthData.height = height;
    tempOAuthData.top = top;
    tempOAuthData.left = left;
    if (exportType !== undefined) {
      tempOAuthData.exportType = exportType;
    }

    if (askExportConfirmPopup) {
      const showAskForOAuthPopCallback = () => {
        // console.log('callback');
        thisObj.openOAuthPopThruButClick(tempOAuthData, oAuthCallBack);
      };
      commonModalState.setShowModal(true, {
        title: `${exportType} Export`,
        description: `Export ${exportCount} lead${
          exportCount > 1 ? 's' : ''
        } to ${exportType}`,
        primaryButtonText: 'Continue',
        buttonCallback: showAskForOAuthPopCallback,
      });
    } else {
      thisObj.openOAuthPopThruButClick(tempOAuthData, oAuthCallBack);
    }
    if (thisObj.oAuthWindow) {
      thisObj.oAuthWindow.focus();
    }
  };

  @action
  trackPurchaseEvent(crmType = '', count, purchaseFlowType) {
    const thisObj = this;

    const mxData = {};
    mxData['Number of contacts purchased'] = count;
    mxData['Purchase Type'] =
      CONSTANTS.CRM_EXPORT_TYPE[crmType.toUpperCase()] || crmType;
    mxData.zeroBounce = true;
    mxData.searchType = 'Contact';
    mxData.currentTab = thisObj.checkIsAdvExport() ? 'contact' : 'myleads';
    mxData.purchaseFlowType = purchaseFlowType;
    Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_LB_CONTACT_PURCHASED, mxData);
    Utils.trackRefinerRemainingCredits();
  }

  @action
  exportLeadsToCRM = (props) => {
    const { type, source, option, exportAs = null } = props;
    const thisObj = this;

    let paramsBody = {};
    paramsBody = {
      export_type: type,
      source,
      isEu: featureFlagsAndPreferencesState.isEUSearchEnabled(),
      ...(exportAs && { export_as: exportAs }),
    };
    let exportLeadsCount = 1;
    let requestType = '';

    if (thisObj.checkIsAdvExport()) {
      exportLeadsCount = advSearchState.getSelectedContactsCount();
      const advPostData = advSearchState.getExportIdsPostData();
      requestType = advSearchState.getExportType();
      paramsBody = { ...paramsBody, ...advPostData };
    } else if (option === 'export selected') {
      paramsBody.contactIds = JSON.stringify(myLeadsState.bulkPurchaseIds);
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
      requestType = 'SELECTED';
    } else if (option === 'job update') {
      paramsBody.contactIds = JSON.stringify(myLeadsState.bulkPurchaseIds);
      exportLeadsCount = myLeadsState.bulkPurchaseIds.length;
      paramsBody.listId = myLeadsState.selectedList.id;
      paramsBody.isMyleadsJobExport = true;
      requestType = 'SELECTED';
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      paramsBody.listId = myLeadsState.selectedList.id;
      exportLeadsCount = myLeadsState.leadsResultCount;
      requestType = 'LIST';
    } else {
      exportLeadsCount = myLeadsState.leadsResultCount;
      requestType = 'ALL';
    }

    paramsBody.requestType = requestType;
    paramsBody.exportRequestCount = exportLeadsCount;
    this.exportPostData = paramsBody;

    this.authorizeToCRM(type);
  };

  @action
  getCRMExportUrl(crmType) {
    const thisObj = this;

    const { exportSource, exportPostData } = thisObj;

    const { requestType, export_as: exportAs } = exportPostData;

    let requestSource = EXPORT_PAGE_SOURCE.MY_LEADS;

    if (exportSource === ADVANCED_SEARCH) {
      requestSource = EXPORT_PAGE_SOURCE.ADVANCED_SEARCH;
    }

    const url = Utils.generateUrl(URLS?.crmExport?.export, {
      crmType,
      type: requestType,
      source: requestSource,
      ...(exportAs && { exportType: exportAs }),
    });
    return url;
  }

  @action
  authorizeToCRM = (crmType) => {
    const thisObj = this;
    const { exportPostData } = thisObj;
    const { exportRequestCount } = exportPostData;

    this.showProcessingExport = true;
    if (exportRequestCount > CRM_EXPORT_LIMIT) {
      thisObj.clearSelectedContacts();
      this.showProcessingExport = false;
      this.exportFailureLimit(crmType, exportRequestCount);
    } else {
      const crmAuthorizeCallback = (res) => {
        this.showProcessingExport = false;
        this.exportContactsToCrmResponseHandler(res, crmType);
      };

      thisObj.exportPostData = {
        ...thisObj.exportPostData,
        now: Date.now(),
      };

      this.exportContactsMakeApiCall(
        thisObj.getCRMExportUrl(crmType.toUpperCase()),
        crmAuthorizeCallback,
        true,
      );
    }
  };

  @action
  exportLeadsToCRMFlow = (accountOwners, crmType) => {
    const thisObj = this;
    const { exportPostData } = this;
    if (accountOwners !== undefined && accountOwners !== '') {
      exportPostData.accountOwners = accountOwners;
    }

    this.exportPostData = exportPostData;
    this.showProcessingExport = true;

    const crmExportCallback = (res) => {
      this.showProcessingExport = false;
      thisObj.exportContactsToCrmResponseHandler(res, crmType);
    };

    thisObj.exportPostData = {
      ...thisObj.exportPostData,
      now: Date.now(),
    };

    this.exportContactsMakeApiCall(
      thisObj.getCRMExportUrl(crmType.toUpperCase()),
      crmExportCallback,
      false,
    );
  };

  @action
  askAssignOwnerShipPopup = (exportType) => {
    const assignOwnerShipCallback = () => {
      this.assignOwnerShipToCRM(exportType);
    };
    const doNotAssignOwnerShipCallback = () => {
      Utils.mixpanelTrack(
        CONSTANTS.MX_ML_EVENTS.ASSIGN_OWNERS[exportType.toUpperCase()],
        {
          isAllowed: 'No',
        },
      );
      this.exportLeadsToCRMFlow({}, exportType);
    };
    showAssignOwnersPopup({
      assignOwnerShipCallback,
      doNotAssignOwnerShipCallback,
      exportType,
      saveType: this.exportPostData.save_type,
    });
  };

  @action
  assignOwnerShipToCRM = (exportType) => {
    const thisObj = this;
    const assignedOwners = (assignOwnerData) => {
      Utils.mixpanelTrack(
        CONSTANTS.MX_ML_EVENTS.ASSIGN_OWNERS[exportType.toUpperCase()],
        {
          isAllowed: 'Yes',
        },
      );
      thisObj.exportLeadsToCRMFlow(assignOwnerData, exportType);
    };
    const withoutAssignOwners = () => {
      Utils.mixpanelTrack(
        CONSTANTS.MX_ML_EVENTS.ASSIGN_OWNERS[exportType.toUpperCase()],
        {
          isAllowed: 'Yes',
        },
      );
      thisObj.exportLeadsToCRMFlow({}, exportType);
    };
    ownershipMappingState.setExportSource(thisObj.exportSource);
    ownershipMappingState.setExportType(exportType);
    ownershipMappingState.setPostData(this.exportPostData);
    ownershipMappingState.setExportCallback(assignedOwners);
    ownershipMappingState.setCancelCallback(withoutAssignOwners);
    ownershipMappingState.setCompanyExport(false);
    ownershipMappingState.setShowPopup(true);
  };

  // Use this function to handle api response for all export going forward
  @action
  exportContactsToCrmResponseHandler = (res, crmType) => {
    const thisObj = this;

    if (res && res.data) {
      const response = res.data;
      const { exportPostData } = thisObj;
      const { requestType, exportRequestCount } = exportPostData;

      const crmTypeVal = crmType && thisObj.exportPostData.export_type;
      const { responseCode = '', responseData } = response;
      switch (responseCode) {
        case 303:
        case 412:
        case 407:
          Utils.showCrmConnectionError({
            showPopup: true,
            crmType: crmTypeVal,
            ...response,
            ...responseData,
            redirectToIntegrations: responseCode === 303,
          });
          break;
        case 417: {
          const { inputRequired } = responseData;
          if (inputRequired === 'ACCOUNT_OWNERS') {
            this.askAssignOwnerShipPopup(crmTypeVal);
          }
          if (inputRequired === 'CRM_PREFERENCES') {
            if (crmType === CRM_EXPORT_TYPE.SALESFORCE) {
              this.showPreferencePopUp(crmType);
            }
          }
          break;
        }
        case 200:
          if (
            requestType === ASYNC_EXPORT ||
            requestType === ASYNC_EXPORT_CRITERIA ||
            (this.exportSource === MY_LEADS && exportRequestCount > 5)
          ) {
            // for async flow, getMemberEntity not made so saving preference when export success
            if (
              this.exportSource === MY_LEADS &&
              exportPostData.export_as &&
              exportPostData.save_preference
            ) {
              userDetail.updateUserInfo({
                teamCRMPreferences: {
                  ...(userDetail.userInfo.teamCRMPreferences || {}),
                  [crmType.toUpperCase()]: {
                    ...(userDetail.userInfo.teamCRMPreferences?.[crmType] ||
                      {}),
                    export_as: exportPostData.export_as.toLowerCase(),
                  },
                },
              });
            }
            thisObj.trackPurchaseEvent(
              crmType,
              exportRequestCount,
              requestType,
            );
            advSearchState.setAsyncPurchaseOrExportMsg('CRM_EXPORT');
            this.additionalOptions.callback('SUCCESS');
          } else {
            const {
              duplicateCount,
              failedCount,
              newlyAddedCount,
              successCount,
              totalCount,
              contactIdsWithoutEmail = [],
            } = response || {};

            const resData = {
              responseCode: 200,
              exportSuccessCount: successCount,
              newDownloadedCount: newlyAddedCount,
              exportFailedCount: failedCount,
              duplicateLeadCount: duplicateCount,
              creditsReduced: newlyAddedCount,
              totalCount,
              contactIdsWithoutEmail,
            };

            this.constructExportStatusPopup('Success', resData, crmType);

            this.additionalOptions.callback('SUCCESS');
            this.setShowExportLeads(false);
          }
          thisObj.clearSelectedContacts();
          break;
        case 402: {
          this.additionalOptions.callback('FAILURE');
          this.showProcessingExport = false;
          thisObj.clearSelectedContacts();
          break;
        }
        default:
          if (
            requestType === ASYNC_EXPORT ||
            requestType === ASYNC_EXPORT_CRITERIA
          ) {
            advSearchState.setAsyncPurchaseOrExportMsg('UNKNOWN_ERROR');
          } else {
            this.constructExportStatusPopup('Failed', {}, crmType);
          }
          this.additionalOptions.callback('FAILURE');
          break;
      }
    } else {
      this.additionalOptions.callback('FAILURE');
      this.constructExportStatusPopup('Failed', {}, crmType);
    }
  };
}

const exportLeadsState = new ExportLeadsState();

function ExportLeads(props) {
  const { enabledExportTypes = allExportTypes } = props;
  const [showAeUpgradePopup, setShowAeUpgradePopup] = useState(false);
  const [showAeConnectInbox, setShowAeConnectInbox] = useState(false);
  const [showAeExportCrm, setShowAeExportCrm] = useState(false);
  const [exportOption, setExportOption] = useState('');
  const [upgradeValue, setUpgradeValue] = useState('');
  const [showSgConnectApi, setSgConnectApi] = useState(false);

  const { showProcessingExport, showExportLeads } = exportLeadsState;

  const exportOptionListMap = exportOptions.filter((option) => {
    return enabledExportTypes.includes(option.text);
  });

  const isCsvEnabled = featureFlagsAndPreferencesState.isCSVExportEnabled();

  const isCrmEnabled = featureFlagsAndPreferencesState.isCRMExportEnabled();

  const exportContactToSalesgear = (option, isExportEnabled) => {
    exportLeadsState.setShowExportLeads(false);
    if (isExportEnabled) {
      setExportOption(option);
      if (userDetail?.salesgearUserInfo?.email) {
        Utils.mixpanelTrack(CONSTANTS.MX_EVENTS_AE.exportContact);
        setShowAeExportCrm(true);
      } else {
        setSgConnectApi(true);
      }
    } else {
      commonUpgradePopupState.setShowUpgradePopup(UPGRADE_TRIGGERS.CRM_EXPORT);
    }
  };

  const closeSgConnectApiPopup = (proceedToExport = false) => {
    setSgConnectApi(false);
    if (proceedToExport) {
      exportContactToSalesgear(exportLeadsState.exportOption, isCrmEnabled);
    }
  };

  const hideSalesgearConnectInbox = () => {
    setShowAeConnectInbox(false);
  };

  const hideAeExportCrm = () => {
    setShowAeExportCrm(false);
  };

  const processingLoader = (value) => {
    exportLeadsState.setShowProcessingExport(value);
    hideAeExportCrm();
  };

  const showSalesgearUpgrade = (value) => {
    setUpgradeValue(value);
    processingLoader(false);
    setShowAeUpgradePopup(true);
  };

  const hideSalesgearUpgrade = () => {
    setShowAeUpgradePopup(false);
  };

  const showSalesgearConnectInbox = () => {
    hideAeExportCrm();
    processingLoader(false);
    setShowAeConnectInbox(true);
  };

  const clearSelectedFields = () => {
    exportLeadsState.clearSelectedContacts();
  };

  const aeExportResult = (resStatus, data = {}) => {
    processingLoader(false);
    if (resStatus === 'failed') {
      exportLeadsState.constructExportStatusPopup('Failed', data, 'Salesgear');
    } else {
      exportLeadsState.constructExportStatusPopup(
        'Success',
        resStatus,
        'Salesgear',
      );
    }
  };

  const exportLimitExceed = (count) => {
    processingLoader(false);
    exportLeadsState.exportFailureLimit('Salesgear', count);
  };

  return (
    <div className="export-leads">
      {showSgConnectApi && (
        <SalesgearAPIConnect closePopup={closeSgConnectApiPopup} />
      )}
      {showAeUpgradePopup && (
        <SalesgearUpgradePopup
          hide={hideSalesgearUpgrade}
          value={upgradeValue}
        />
      )}
      {showAeConnectInbox && (
        <SalesgearConnectInbox hide={hideSalesgearConnectInbox} />
      )}
      {showAeExportCrm && (
        <SalesgearExportCRM
          hide={hideAeExportCrm}
          option={exportOption}
          upgrade={showSalesgearUpgrade}
          loader={processingLoader}
          resultResponse={aeExportResult}
          showConnectInbox={showSalesgearConnectInbox}
          limit={exportLimitExceed}
          clearSelectedFields={clearSelectedFields}
        />
      )}
      <ExportLeadsTypePopup />
      <OutreachSequencePopup />
      <PipedriveAuthenticationPopup />
      <MarketoAuthenticationPopup />
      <OwnershipMappingPopup />
      <div
        className={`processing-export-container${showProcessingExport ? '' : ' hide'}`}
      >
        <p className="processing-export">
          Please wait, we&#39;re processing your export
        </p>
      </div>
      {showExportLeads && (
        <ChooseExportModal
          title="Export Leads"
          closeModal={() => {
            exportLeadsState.setShowExportLeads(false);
          }}
          list={exportOptionListMap.map((option) => {
            const isSalesgear = option.text === 'Salesgear';
            const { text } = option;
            const optionEnabled = text === 'CSV' ? isCsvEnabled : isCrmEnabled;
            return {
              ...option,
              optionEnabled,
              callback: () => {
                if (isSalesgear) {
                  exportContactToSalesgear(
                    exportLeadsState.exportOption,
                    optionEnabled,
                  );
                } else {
                  exportLeadsState.checkAndExport(
                    text,
                    'salesLift',
                    exportLeadsState.exportOption,
                    optionEnabled,
                  );
                }
              },
            };
          })}
        />
      )}
    </div>
  );
}

export { exportLeadsState };

export default observer(ExportLeads);
