import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import AdaptLogo from 'Assets/svg/adapt-logo.svg';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import enrichmentState from 'Stores/enrichment';
import Utils from 'Utils/utils';
import { MX_ENRICHMENT_EVENTS } from 'Utils/constants';
import Select from 'Components/common/base/Select';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import SubMenuIcon from 'Assets/svg/new_icons/sub-menu.svg';
import './styles.scss';

function CsvFieldMappingPopup(props) {
  const { togglePopup, pagination } = props;
  const [showError, setShowError] = useState(false);
  const wrapperRef = useRef();

  const handleClickEnrich = async () => {
    let isValid = false;
    const fieldsMapped = enrichmentState.fileEnrichmentFieldMapping.reduce(
      (mappedFields, mapping) => {
        if (mapping.csvLabel && mapping.adaptKey) { return [...mappedFields, mapping.adaptKey]; }
        return mappedFields;
      },
      [],
    );
    if (
      (fieldsMapped.includes('firstName') &&
        fieldsMapped.includes('lastName') &&
        fieldsMapped.includes('domain')) ||
      fieldsMapped.includes('linkedInURL') ||
      fieldsMapped.includes('email')
    ) { isValid = true; } else { setShowError(true); }
    if (isValid) {
      await enrichmentState.updateFileEnrichmentMapping({ pagination });
      togglePopup();
      enrichmentState.showFileEnrichmentInitiatedPopup = true;
    }
  };

  useEffect(() => {
    Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.CSV_FIELD_MAPPING_SHOWN);
  }, []);

  const CSVHeaderLabels = enrichmentState.fileEnrichmentCSVFields
    .filter(
      (item) => {
        return !enrichmentState.fileEnrichmentFieldMapping.some(
          (prop) => { return prop.csvLabel === item; },
        );
      },
    )
    .map((prop) => {
      return { label: prop, value: prop };
    });

  if (CSVHeaderLabels.length === 0) {
    CSVHeaderLabels.push({
      label: 'No data',
      value: 'No data',
      disabled: true,
    });
  }

  return (
    <Modal
      show
      closeModal={togglePopup}
      className="csv-field-mapping"
    >
      <Modal.Content>
        <div className="top-wrapper">
          <div className="icon-wrap">
            <SubMenuIcon />
          </div>
          <div className="title-wrapper">
            <div className="title">CSV Field Mapping</div>
            <div className="description">
              Map the columns in your CSV to Adapt field.
            </div>
          </div>
        </div>
        <div className="field-mapping-body">
          <div className="table-header">
            <div className="adapt-field">
              <AdaptLogo className="logo" />
              <span>Adapt</span>
            </div>
            <div className="csv-header">CSV Header</div>
            <div className="sample-data-title">Sample Field Data</div>
          </div>
          <div className="table-body">
            {enrichmentState.fileEnrichmentFieldMapping.map((data) => {
              const { adaptKey, csvLabel, adaptLabel } = data;
              return (
                <div className="field" key={adaptKey}>
                  <div className="adapt-field">
                    <div>
                      <TickIcon
                        className={`tick-icon${csvLabel ? '' : ' not-selected'}`}
                      />
                      {adaptLabel}
                    </div>
                    <span className="hyphen" />
                  </div>
                  <div className={`csv-header${csvLabel ? '' : ' no-data'}`}>
                    <Select
                      enableValuesOutsideOfOptions
                      canClearValue
                      searchInputProps={{ placeholder: 'Search Field' }}
                      value={csvLabel ? [csvLabel] : []}
                      dropdownOffset={
                        wrapperRef.current
                          ? {
                              top: wrapperRef.current?.getBoundingClientRect()
                                ?.top,
                              bottom:
                                window.innerHeight -
                                // eslint-disable-next-line no-unsafe-optional-chaining
                                wrapperRef.current?.getBoundingClientRect()
                                  ?.bottom,
                            }
                          : { top: 0, bottom: 0 }
                      }
                      showPlaceholderInOptions={false}
                      placeholder="Not mapped yet"
                      onChange={(selectedVal) => {
                        if (
                          [
                            'domain',
                            'email',
                            'linkedin',
                            'firstName',
                            'lastName',
                          ].includes(adaptKey)
                        ) { setShowError(false); }
                        enrichmentState.setFileEnrichmentFieldMapping(
                          enrichmentState.fileEnrichmentFieldMapping.map((prop) => {
                            if (adaptKey === prop.adaptKey) {
                              return {
                                ...prop,
                                csvLabel: selectedVal,
                                sampleData:
                                  enrichmentState.fileEnrichmentSampleData[
                                    selectedVal
                                  ],
                              };
                            }
                            return prop;
                          }),
                        );
                      }}
                      options={CSVHeaderLabels}
                    />
                    <span className="hyphen" />
                  </div>
                  <div className="sample-data" title={data.sampleData}>
                    {data.sampleData}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="csv-field-mapping-error">
          {showError && (
            <span>
              <sup>*</sup>Kindly map any one of these fields - Email or Linkedin
              URL or First name, Last name & Company
            </span>
          )}
        </div>
        <div className="button-wrapper">
          <Button
            variant="outline"
            buttonProps={{
              text: 'Cancel',
            }}
            onClick={togglePopup}
          />
          <Button
            buttonProps={{
              text: 'Enrich',
            }}
            isLoading={enrichmentState.fileEnrichmentLoader}
            onClick={handleClickEnrich}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default observer(CsvFieldMappingPopup);
