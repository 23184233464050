import React, {
  useState,
  useEffect,
  useMemo,
  useTransition,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import { VariableSizeList } from 'react-window';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import Accordion from 'Components/common/base/Accordion';
import InlineLoader from 'Components/common/base/InlineLoader';
import { applyFiltersWithDelay } from 'Pages/prospectSearch/utils';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Utils from 'Utils/utils';
import trackMixpanelEvent from '../trackMixpanelEvent';
import UpgradeSection from '../UpgradeSection';
import './styles.scss';

function generateList(data) {
  const {
    currentFilterState,
    search,
    dataList,
    suggestionKey,
    excludeSuggestionKey,
    enableExclude,
    isIncluded,
    isExcluded,
  } = data;

  const appliedFilter = currentFilterState.appliedFilterSectionData;

  return dataList.reduce((output, topList) => {
    const label = topList?.label;
    const isMainListIncluded = isIncluded({
      appliedFilter,
      listData: topList,
      suggestionKey,
    });
    const isMainListExcluded = enableExclude
      ? isExcluded({
          appliedFilter,
          listData: topList,
          suggestionKey: excludeSuggestionKey,
        })
      : false;

    const isParentListMatch = label
      .toLowerCase()
      .includes(search.toLowerCase());

    const subList = topList?.subList?.reduce((subListOutput, subListData) => {
      const subListLabel = subListData.label;
      const isSubListIncluded = isIncluded({
        appliedFilter,
        listData: subListData,
        suggestionKey,
        topList,
      });
      const isSubListExcluded = enableExclude
        ? isExcluded({
            appliedFilter,
            listData: subListData,
            suggestionKey: excludeSuggestionKey,
            topList,
          })
        : false;

      return [
        ...subListOutput,
        {
          label: subListLabel,
          value: subListData?.value || subListLabel,
          checked:
            isSubListIncluded || (isMainListIncluded && !isSubListExcluded),
          excluded:
            isSubListExcluded || (isMainListExcluded && !isSubListIncluded),
          show:
            subListLabel.toLowerCase().includes(search.toLowerCase()) ||
            isParentListMatch,
        },
      ];
    }, []);

    return [
      ...output,
      {
        label,
        value: topList?.value || label,
        checked: isMainListIncluded,
        excluded: isMainListExcluded,
        subList,
        show:
          subList?.some((subListData) => {
            return subListData.show;
          }) || isParentListMatch,
      },
    ];
  }, []);
}

function setCheckedAndExcluded(data) {
  const { input, output, parentList = {}, getData } = data;
  const overrideValues = {};
  const checkSubList = () => {
    input?.subList?.forEach((subListData) => {
      setCheckedAndExcluded({
        input: { ...subListData, ...overrideValues },
        output,
        parentList: input,
        getData,
      });
    });
  };
  if (input.checked) {
    output.includedFilters.push(getData(input, parentList));
    overrideValues.checked = false;
  } else if (input.excluded) {
    output.excludedFilters.push(getData(input, parentList));
    overrideValues.excluded = false;
  }
  checkSubList(overrideValues);
  return output;
}

function getCheckedAndExcluded(action, data) {
  if (action === 'include' || action === 'exclude') {
    return {
      checked: action === 'include',
      excluded: action === 'exclude',
    };
  }

  if (action === 'checkbox') {
    return {
      checked: !(data.checked || data.excluded),
      excluded: false,
    };
  }
  return data;
}

function processAction(data) {
  const { value, parentListLabel, list, action } = data;
  const newList = list;

  const parentListIndex = newList.findIndex((listData) => {
    return listData.value === (parentListLabel || value);
  });
  let parentListData = Utils.copyObjectWithoutReference(
    newList[parentListIndex],
  );
  const isParentListChange = !parentListLabel;
  if (isParentListChange) {
    const { checked, excluded } = getCheckedAndExcluded(action, parentListData);
    parentListData = {
      ...parentListData,
      excluded,
      checked,
      subList: parentListData?.subList?.map((prop) => {
        return { ...prop, excluded, checked };
      }),
    };
  } else {
    const subListIndex = parentListData?.subList?.findIndex((listData) => {
      return listData.value === value;
    });
    let subListData = Utils.copyObjectWithoutReference(
      parentListData?.subList[subListIndex],
    );
    const { checked, excluded } = getCheckedAndExcluded(action, subListData);
    subListData = {
      ...subListData,
      checked,
      excluded,
    };
    parentListData?.subList.splice(subListIndex, 1, subListData);
    if (checked) {
      const isParentListExcluded = parentListData.excluded;
      if (isParentListExcluded) {
        parentListData = {
          ...parentListData,
          checked: false,
          excluded: false,
          subList: parentListData?.subList.map((subList) => {
            if (subList.value === subListData.value) {
              return subListData;
            }
            return { ...subListData, excluded: false, checked: false };
          }),
        };
      }
      const isAllSubListSelected = parentListData?.subList.every((subList) => {
        return subList.checked;
      });
      if (isAllSubListSelected) {
        parentListData.checked = true;
        parentListData.excluded = false;
      }
    } else if (!excluded) {
      if (parentListData.checked) {
        parentListData.checked = false;
      } else if (parentListData.excluded) {
        parentListData.excluded = false;
      }
    } else {
      const isAllSubListExcluded = parentListData?.subList.every((subList) => {
        return subList.excluded;
      });
      if (isAllSubListExcluded) {
        parentListData.checked = false;
        parentListData.excluded = true;
      }
    }
  }
  newList.splice(parentListIndex, 1, parentListData);
  return newList;
}

function getNewData(data) {
  const newList = processAction(Utils.copyObjectWithoutReference(data));
  return newList.reduce(
    (output, input) => {
      const outputData = setCheckedAndExcluded({
        input,
        output,
        getData: data.getData,
      });
      return outputData;
    },
    { includedFilters: [], excludedFilters: [] },
  );
}

function processSelectionForSearch(list, search, getData) {
  let newList = Utils.copyObjectWithoutReference(list);

  newList.forEach((listData) => {
    const { label: parentListLabel, show, value: parentListValue } = listData;
    if (show) {
      const isParentListMatch = parentListLabel
        .toLowerCase()
        .includes(search.toLowerCase());
      if (isParentListMatch) {
        newList = processAction({
          value: parentListValue,
          parentListLabel: null,
          list: newList,
          action: 'include',
        });
      } else {
        listData?.subList.forEach((subListData) => {
          const {
            label: subListLabel,
            show: showSubList,
            value: subListValue,
          } = subListData;
          if (showSubList) {
            const isSubListMatch = subListLabel
              .toLowerCase()
              .includes(search.toLowerCase());
            if (isSubListMatch) {
              newList = processAction({
                value: subListValue,
                parentListLabel: parentListValue,
                list: newList,
                action: 'include',
              });
            }
          }
        });
      }
    }
  });

  return newList.reduce(
    (output, input) => {
      const outputData = setCheckedAndExcluded({ input, output, getData });
      return outputData;
    },
    { includedFilters: [], excludedFilters: [] },
  );
}

function CheckBoxWrapper(props) {
  const { label, checked, excluded, applyFilter, enableExclude } = props;

  return (
    <div
      className="list-checkbox-wrapper"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ControlInput
        type="checkbox"
        label={label}
        checked={checked || excluded}
        color={excluded ? 'error' : 'primary'}
        onChange={() => {
          applyFilter('checkbox');
        }}
      />
      {enableExclude && (
        <div className="include-exclude">
          <span
            className="include"
            onClick={() => {
              applyFilter('include');
            }}
          >
            Include
          </span>
          <span
            className="exclude"
            onClick={() => {
              applyFilter('exclude');
            }}
          >
            Exclude
          </span>
        </div>
      )}
    </div>
  );
}

const Row = observer((props) => {
  const { index, style, data } = props;
  const { applyFilter, list, enableExclude, listRef, accordionClosedIndexes } =
    data;

  const {
    label,
    value,
    subList,
    checked = false,
    excluded = false,
    show,
  } = list[index];

  const applyListFilter = (action) => {
    applyFilter({ value, parentListLabel: null, action });
  };

  return (
    <div style={style}>
      {show && (
        <Accordion
          isAccordionOpen={!accordionClosedIndexes?.current.includes(index)}
          key={value}
          setIsAccordionOpen={(showAccordion) => {
            if (accordionClosedIndexes?.current) {
              if (showAccordion) {
                accordionClosedIndexes.current =
                  accordionClosedIndexes.current.filter((indexValue) => {
                    return indexValue !== index;
                  });
              } else {
                accordionClosedIndexes.current = [
                  ...accordionClosedIndexes.current,
                  index,
                ];
              }
            }
            listRef.current?.resetAfterIndex(index);
          }}
          title={
            <CheckBoxWrapper
              label={label}
              value={value}
              checked={checked}
              excluded={excluded}
              applyFilter={applyListFilter}
              enableExclude={enableExclude}
            />
          }
        >
          <div className="checkbox-grid">
            {subList?.map((subListData) => {
              const {
                label: subListLabel,
                value: subListValue,
                checked: isSubListChecked = false,
                excluded: isSubListExcluded = false,
                show: showSubList,
              } = subListData;

              const applySubListFilter = (action) => {
                applyFilter({
                  value: subListValue,
                  parentListLabel: value,
                  action,
                });
              };

              return (
                showSubList && (
                  <CheckBoxWrapper
                    key={subListValue}
                    label={subListLabel}
                    value={subListValue}
                    checked={isSubListChecked}
                    excluded={isSubListExcluded}
                    applyFilter={applySubListFilter}
                    enableExclude={enableExclude}
                  />
                )
              );
            })}
          </div>
        </Accordion>
      )}
    </div>
  );
});

function AccordionCheckboxList(props) {
  const {
    currentFilterState,
    selectedFilter,
    upgradeData = {},
    page,
    dataList,
    enableExclude = false,
    suggestionKey = '',
    excludeSuggestionKey = '',
    mxEventName = '',
    getData,
    isIncluded = EMPTY_FUNCTION,
    isExcluded = EMPTY_FUNCTION,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [list, setList] = useState([]);
  const filterRef = useRef();
  const listRef = useRef();
  const accordionClosedIndexes = useRef([]);
  const [isPending, startTransition] = useTransition();

  const updateList = (search = '') => {
    setList(
      generateList({
        currentFilterState,
        search,
        dataList,
        suggestionKey,
        excludeSuggestionKey,
        enableExclude,
        isIncluded,
        isExcluded,
      }),
    );
  };

  const debouncedUpdateList = useMemo(() => {
    return Utils.debounce(updateList);
  }, []);

  useEffect(() => {
    if (list.length === 0) {
      startTransition(() => {
        updateList(searchValue);
      });
    } else {
      updateList(searchValue);
    }
  }, [dataList, currentFilterState.appliedFilterSectionData]);

  const applyFilter = (filterProps) => {
    const { value, parentListLabel = null, action = 'include' } = filterProps;
    const exclude = action === 'exclude';

    const key = exclude ? excludeSuggestionKey : suggestionKey;
    trackMixpanelEvent({
      value,
      key,
      page,
      showExcludeToggle: true,
      isExcludeEnabled: exclude,
      mxUniqueKey: 'filterValue',
      mxEventName,
    });

    const { includedFilters, excludedFilters } = getNewData({
      value,
      parentListLabel,
      list,
      action,
      getData,
    });

    if (enableExclude) {
      currentFilterState.setAppliedFilters(
        excludeSuggestionKey,
        excludedFilters,
        null,
        true,
        false,
      );
    }
    currentFilterState.setAppliedFilters(
      suggestionKey,
      includedFilters,
      null,
      true,
      false,
    );
    currentFilterState.resultLoading = true;
    applyFiltersWithDelay({
      suggestionKey,
      selectedFilters: includedFilters,
      currentFilterState,
      doSearch: true,
    });
  };

  const allDataSelected = useMemo(() => {
    return (
      (list?.length > 0 &&
        list?.every((data) => {
          return data.checked;
        })) ??
      false
    );
  }, [list]);

  const toggleAllData = () => {
    if (searchValue.length === 0) {
      if (allDataSelected) {
        currentFilterState.setAppliedFilters(
          suggestionKey,
          [],
          null,
          true,
          true,
        );
      } else {
        currentFilterState.setAppliedFilters(
          suggestionKey,
          list.map((data) => {
            return getData(data, null);
          }),
          null,
          true,
          true,
        );
      }
    } else {
      const { includedFilters, excludedFilters } = processSelectionForSearch(
        list,
        searchValue,
        getData,
      );
      if (enableExclude) {
        currentFilterState.setAppliedFilters(
          excludeSuggestionKey,
          excludedFilters,
          null,
          true,
          false,
        );
      }
      currentFilterState.setAppliedFilters(
        suggestionKey,
        includedFilters,
        null,
        true,
        true,
      );
    }
  };

  const isRestricted = selectedFilter.restricted
    ? selectedFilter.restricted()
    : false;

  const filteredListsToShow = useMemo(() => {
    return list.filter((listData, index) => {
      listRef.current?.resetAfterIndex(index);
      return listData?.show;
    });
  }, [list]);

  return (
    <div className="accordion-checkbox-list" ref={filterRef}>
      {isRestricted && (
        <UpgradeSection
          title={upgradeData?.title}
          message={upgradeData?.message}
          imageURL={upgradeData?.imageURL}
        />
      )}
      <div className={`content ${isRestricted ? 'disable' : ''}`}>
        <div className="top-wrapper">
          <ControlInput
            eventStopPropagation
            type="checkbox"
            label="All"
            checked={allDataSelected}
            onChange={toggleAllData}
          />
          <Input
            inputProps={{ placeholder: 'Search', value: searchValue }}
            onChange={(val) => {
              setSearchValue(val);
              debouncedUpdateList(val);
            }}
            showSearchIcon
            showClearIcon
          />
        </div>
        {isPending && <InlineLoader />}
        {filteredListsToShow.length > 0 && (
          <VariableSizeList
            ref={listRef}
            itemCount={filteredListsToShow.length}
            width="100%"
            height={
              filterRef?.current
                ? filterRef.current.getBoundingClientRect().height - 60
                : 450
            }
            itemSize={(index) => {
              if (accordionClosedIndexes.current.includes(index)) {
                return 66;
              }
              const subListsShown = filteredListsToShow?.[
                index
              ]?.subList.filter((listData) => {
                return listData.show;
              })?.length;
              const noOfSubRows = Math.ceil((subListsShown ?? 0) / 3);
              // 95 includes margin, padding, accordion title height
              // remaining calculate height of sub rows
              return 95 + (noOfSubRows * 17.5 + (noOfSubRows - 1) * 15);
            }}
            itemData={{
              applyFilter,
              enableExclude,
              list: filteredListsToShow,
              listRef,
              accordionClosedIndexes,
            }}
          >
            {Row}
          </VariableSizeList>
        )}
      </div>
    </div>
  );
}

export default observer(AccordionCheckboxList);
