/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams, useOutletContext } from 'react-router-dom';
import AdaptLogo from 'Assets/svg/new_icons/adapt-logo.svg';
import PlusIcon from 'Assets/svg/new_icons/plus-icon-rounded.svg';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import SubMenuIcon from 'Assets/svg/new_icons/sub-menu.svg';
import Select from 'Components/common/base/Select';
import Button from 'Components/common/base/Button';
import Menu from 'Components/common/base/Menu';
import Title from 'Components/common/base/Title';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import fieldMappingState from 'Stores/fieldMapping';
import Utils from 'Utils/utils';
import {
  enrichmentPreferenceOptions,
  CRM_FIELD_TYPES,
  DO_NOT_MAP_FIELD,
  ENRICHMENT_IGNORED_CRMS,
  FIELD_MAPPING_VALUE_EXAMPLES,
  EMPTY_FUNCTION,
} from 'Utils/constants';
import integrationDataMap from '../integrationDataMap';
import PicklistMappingPopup from '../PicklistMappingPopup';
import './styles.scss';

const mandatoryFields = ['email'];

const { ENUM, STRING } = CRM_FIELD_TYPES;

function FieldMapping(props) {
  const { callback = EMPTY_FUNCTION, alwaysEnableButton = false } = props;

  const context = useOutletContext();
  const { redirectToIntegrations } = context ?? {
    redirectToIntegrations: EMPTY_FUNCTION,
  };

  const { crmType, mappingType = 'contacts' } = useParams();
  const currentCRMData = integrationDataMap[crmType?.toUpperCase()];

  const [validateField, setValidateField] = useState(false);

  const customValueWrapperRef = React.useRef();

  const subMenu = currentCRMData?.sublinks?.find((data) => {
    return data.linkKey === 'field-mapping';
  })?.subMenu;

  const exportType =
    subMenu?.find((menu) => {
      return menu.title.toLowerCase() === mappingType;
    })?.API_KEY ?? 'CONTACT';

  const CRMLogo = currentCRMData?.imgComponent;

  useEffect(() => {
    fieldMappingState.getCRMFieldMappings({
      crmType: crmType.toUpperCase(),
      exportType,
      notConnectedCallback: redirectToIntegrations,
    });
  }, [mappingType]);

  useEffect(() => {
    return () => {
      fieldMappingState.setFieldMappingDataState(
        fieldMappingState.fieldMappingResponseData,
      );
    };
  }, []);

  const addCustomValueField = () => {
    fieldMappingState.updateCustomValueMapping({
      data: { crmKey: '', adaptValue: '' },
      type: 'ADD',
    });
  };

  const isAdmin = fieldMappingState.fieldMappingData?.isAdmin;

  const hasEditAccess = isAdmin;

  // const hideEnrichmentPreference = !isAdmin;
  const hideEnrichmentPreference =
    !Utils.isCrmEnrichmentEnabled() ||
    ENRICHMENT_IGNORED_CRMS.includes(crmType.toUpperCase());

  const processSelectedCrmField = (selectedVal, fieldData) => {
    const isDoNotMap = selectedVal === DO_NOT_MAP_FIELD.value;
    const {
      enrichmentPreference: epVal,
      adaptKey,
      ...remainingProps
    } = fieldData;

    const adaptFieldData =
      fieldMappingState.fieldMappingData?.adaptFields?.find((data) => {
        return data.key === adaptKey;
      });

    const crmFieldData = fieldMappingState.fieldMappingData?.crmFields?.find(
      (data) => {
        return data.key === selectedVal;
      },
    );

    const updateObj = {
      adaptKey,
      ...remainingProps,
      ...(!isDoNotMap && {
        enrichmentPreference: epVal,
      }),
      crmKey: selectedVal,
      crmLabel: isDoNotMap ? DO_NOT_MAP_FIELD.label : crmFieldData?.label,
    };

    if (!isDoNotMap) {
      const {
        type: adaptFieldType,
        label: adaptFieldLabel,
        key: adaptFieldKey,
        options: adaptOptions,
      } = adaptFieldData;
      const { type: crmFieldType, options: crmOptions } = crmFieldData;

      const isTypeMatch = Utils.checkFieldMappingTypeSupport(
        adaptFieldType,
        crmFieldType,
      );

      if (!isTypeMatch) {
        updateObj.isTypeMismatch = true;
        updateObj.selectedCrmType = crmFieldType;
        updateObj.selectedAdaptType = adaptFieldType;
        delete updateObj.options;
        delete updateObj.enrichmentPreference;
      } else {
        delete updateObj.isTypeMismatch;
        delete updateObj.selectedCrmType;
        delete updateObj.selectedAdaptType;
      }

      if (adaptFieldType === ENUM && crmFieldType === ENUM) {
        const previousMapping =
          fieldMappingState.fieldMappingData.customFieldMapping?.find(
            (data) => {
              return data.adaptKey === adaptKey;
            },
          );
        const newObj = Utils.copyObjectWithoutReference(updateObj);
        if (fieldData.enrichmentPreference === undefined) {
          newObj.enrichmentPreference = enrichmentPreferenceOptions[2].value;
        }
        const popupData = {
          adaptFieldLabel,
          adaptFieldKey,
          adaptOptions,
          crmOptions,
          customMappingOptions: [],
          parentMappingObj: newObj,
          isInitialMapping: true,
        };
        const { crmKey = '' } = previousMapping || {};
        if (crmKey === selectedVal) {
          popupData.customMappingOptions =
            fieldData.options?.length > 0 ? fieldData.options : [];
        }
        fieldMappingState.setPicklistFieldMappingData(popupData);
      } else {
        delete updateObj.options;
      }
    } else {
      delete updateObj.isTypeMismatch;
      delete updateObj.selectedCrmType;
      delete updateObj.selectedAdaptType;
      delete updateObj.options;
    }

    fieldMappingState.updateCustomFieldMappings(updateObj);
  };

  const processValueMappingSelectedCrmField = (
    selectedVal,
    index,
    customAdaptValue,
  ) => {
    const crmFieldData = fieldMappingState.fieldMappingData?.crmFields?.find(
      (data) => {
        return data.key === selectedVal;
      },
    );
    const { type: crmFieldType, label } = crmFieldData;
    const updateObj = {
      crmKey: selectedVal,
      adaptValue: customAdaptValue,
    };
    if (crmFieldType !== STRING && crmFieldType !== ENUM) {
      updateObj.crmLabel = label;
      updateObj.isTypeMismatch = true;
      updateObj.selectedCrmType = crmFieldType;
      updateObj.selectedAdaptType = STRING;
    } else {
      delete updateObj.isTypeMismatch;
      delete updateObj.selectedCrmType;
      delete updateObj.selectedAdaptType;
    }
    fieldMappingState.updateCustomValueMapping({
      type: 'EDIT',
      index,
      data: updateObj,
    });
  };

  const processOpenPicklist = (selectedAdaptKey) => {
    const customMapData =
      fieldMappingState.fieldMappingData?.customFieldMapping?.find((data) => {
        return data.adaptKey === selectedAdaptKey;
      });
    const adaptFieldData =
      fieldMappingState.fieldMappingData?.adaptFields?.find((data) => {
        return data.key === selectedAdaptKey;
      });
    const { crmKey, options } = customMapData;
    const crmFieldData = fieldMappingState.fieldMappingData?.crmFields?.find(
      (data) => {
        return data.key === crmKey;
      },
    );
    const {
      label: adaptFieldLabel,
      key: adaptFieldKey,
      options: adaptOptions,
    } = adaptFieldData;
    const { options: crmOptions } = crmFieldData;

    const popupData = {
      adaptFieldLabel,
      adaptFieldKey,
      adaptOptions,
      crmOptions,
      customMappingOptions: Utils.copyObjectWithoutReference(options),
      parentMappingObj: Utils.copyObjectWithoutReference(customMapData),
    };
    fieldMappingState.setPicklistFieldMappingData(popupData);
  };

  const getErrorStr = (type = '') => {
    if (type === ENUM) {
      return 'picklist';
    }
    return type.toLowerCase();
  };

  const handleSave = async () => {
    const errorFieldMapping =
      fieldMappingState.fieldMappingData.customFieldMapping?.filter((data) => {
        return data.isTypeMismatch;
      }) || [];
    const errorValueMapping =
      fieldMappingState.fieldMappingData.customValueMapping?.length > 0
        ? fieldMappingState.fieldMappingData.customValueMapping?.filter(
            (data) => {
              return data.isTypeMismatch;
            },
          )
        : [];

    const errorMapping = [...errorFieldMapping, ...errorValueMapping];
    if (errorMapping.length > 0) {
      const { adaptKey, crmKey } = errorMapping[0];
      const selectorKey = adaptKey ?? crmKey;
      // eslint-disable-next-line no-undef
      const element = document.getElementById(`field-${selectorKey}`);
      // eslint-disable-next-line no-unused-expressions
      element?.scrollIntoView?.();
    } else {
      const isCustomValueFieldsValid =
        fieldMappingState.fieldMappingData.customValueMapping?.length > 0
          ? fieldMappingState.fieldMappingData.customValueMapping?.every(
              (data) => {
                return data.crmKey !== '' && data.adaptValue !== '';
              },
            )
          : true;
      setValidateField(!isCustomValueFieldsValid);
      if (isCustomValueFieldsValid) {
        const isSuccess = await fieldMappingState.updateCRMFieldMappings({
          crmType: crmType.toUpperCase(),
          exportType,
        });
        callback(isSuccess);
      } else {
        // eslint-disable-next-line no-unused-expressions
        customValueWrapperRef.current?.scrollIntoView?.();
      }
    }
  };
  const handleCancel = () => {
    setValidateField(false);
    fieldMappingState.handleCancel();
  };

  return (
    <div className="crm-field-mapping">
      <Title title={`${Utils.capitalize(crmType)} Field Mapping`} />
      {fieldMappingState.picklistFieldMappingData !== null && (
        <PicklistMappingPopup currentCRMData={currentCRMData} />
      )}
      <div className="top-wrapper">
        <div className="header">
          Field Mapping - <span className="mapping-type">{mappingType}</span>
        </div>
        <div className="button-wrapper">
          {hasEditAccess &&
            (fieldMappingState.areFieldsUpdated || alwaysEnableButton) && (
              <>
                <Button
                  onClick={handleCancel}
                  variant="outline"
                  buttonProps={{
                    text: 'Cancel',
                  }}
                />
                <Button
                  onClick={handleSave}
                  buttonProps={{
                    text: 'Save',
                  }}
                />
              </>
            )}
        </div>
      </div>
      <div
        className={`field-mapping-wrapper${
          hideEnrichmentPreference ? ' no-enrich-pref' : ''
        }`}
      >
        <div className="field-header">
          <div className="title-wrap adapt">
            <AdaptLogo className="logo" />
            <span>Adapt</span>
          </div>
          <div className="title-wrap crm">
            <CRMLogo className="logo" />
            <span>{currentCRMData.title}</span>
          </div>
          {!hideEnrichmentPreference && (
            <div className="title-wrap enrich-title">
              <span>Enrichment Preference</span>
              <ToolTipWithIcon
                className="enrich-pref-tooltip"
                offset={{ right: 20 }}
              >
                Choose your preference on fields that you want to{' '}
                <span>enrich, update or skip.</span>
              </ToolTipWithIcon>
            </div>
          )}
        </div>
        <div className="field-body">
          {fieldMappingState.isFieldMappingLoading ? (
            <>
              {Array.from({ length: 10 }).map((item, index) => {
                return (
                  <div
                    className="field placeholder"
                    key={`placeholder-field-${index + 1}`}
                  >
                    <div className="field-inner-wrap">
                      <div className="adapt-field">
                        <div className="text-placeholder linear-background" />
                      </div>
                      <div className="select-placeholder linear-background" />
                      {!hideEnrichmentPreference && (
                        <div className="enrich-pref">
                          <div className="select-placeholder linear-background" />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {fieldMappingState.fieldMappings?.map((fieldData) => {
                const {
                  adaptKey,
                  adaptLabel,
                  enrichmentPreference,
                  crmKey,
                  crmLabel,
                  isTypeMismatch = false,
                  selectedAdaptType = '',
                  selectedCrmType = '',
                  options = null,
                } = fieldData;

                return (
                  <div
                    className="field"
                    id={`field-${adaptKey}-${mappingType}`}
                    key={adaptLabel}
                  >
                    <div className="field-inner-wrap">
                      <div className="adapt-field">
                        <div>
                          <TickIcon
                            className={`tick-icon${
                              crmKey ? '' : ' not-selected'
                            }`}
                          />
                          {adaptLabel}
                        </div>
                        {mandatoryFields.includes(adaptKey) && <sup>*</sup>}
                      </div>
                      <div
                        className={`crm-field${
                          hasEditAccess ? '' : ' disabled'
                        }`}
                      >
                        <div className="field-value">
                          {adaptKey === 'email' ? (
                            <div className="email-wrapper">
                              <input
                                disabled
                                className="email-field"
                                value={crmLabel}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="crm-field-select-wrapper">
                                <Select
                                  selectInputProps={{
                                    disabled: !hasEditAccess,
                                  }}
                                  enableValuesOutsideOfOptions
                                  searchInputProps={{
                                    placeholder: 'Search Field',
                                  }}
                                  value={crmLabel}
                                  enableSearch
                                  dropdownOffset={{ top: 220, bottom: 0 }}
                                  showPlaceholderInOptions={false}
                                  placeholder="Yet to be mapped"
                                  onChange={(value) => {
                                    const selectedVal = value;
                                    processSelectedCrmField(
                                      selectedVal,
                                      fieldData,
                                    );
                                  }}
                                  options={
                                    fieldMappingState.availableCRMFields
                                      ?.length > 0
                                      ? [
                                          ...(fieldMappingState?.availableCRMFields?.map(
                                            (data) => {
                                              return {
                                                label: data.label,
                                                value: data.key,
                                              };
                                            },
                                          ) ?? []),
                                          ...(mandatoryFields.includes(adaptKey)
                                            ? []
                                            : [DO_NOT_MAP_FIELD]),
                                        ]
                                      : []
                                  }
                                  selectWrapperProps={{
                                    className: isTypeMismatch
                                      ? 'type-mismatch-error'
                                      : '',
                                  }}
                                />
                                {options !== null && (
                                  <div
                                    className="picklist-mapping-btn"
                                    onClick={() => {
                                      return processOpenPicklist(adaptKey);
                                    }}
                                  >
                                    <SubMenuIcon />
                                  </div>
                                )}
                              </div>

                              {isTypeMismatch && (
                                <div className="type-mismatch-error-msg">
                                  The field '{crmLabel}' in your CRM is defined
                                  as '{getErrorStr(selectedCrmType)}' whereas in
                                  Adapt it is defined as '
                                  {getErrorStr(selectedAdaptType)}
                                  '. Kindly change the mapping.
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="field-example">
                          {adaptKey === 'email' ? (
                            <>
                              <span>Note:</span>Email fields are automatically
                              mapped with your CRM’s fields
                            </>
                          ) : (
                            `Ex. ${FIELD_MAPPING_VALUE_EXAMPLES[adaptKey]}`
                          )}
                        </div>
                      </div>
                      {!hideEnrichmentPreference && (
                        <div className="enrich-pref">
                          <div
                            className={`dropdown-wrap${
                              crmKey &&
                              crmKey !== DO_NOT_MAP_FIELD.value &&
                              hasEditAccess &&
                              !isTypeMismatch &&
                              adaptKey !== 'email'
                                ? ''
                                : ' disabled'
                            }`}
                          >
                            <Select
                              selectInputProps={{
                                disabled:
                                  !crmKey ||
                                  !hasEditAccess ||
                                  crmKey === DO_NOT_MAP_FIELD.value ||
                                  adaptKey === 'email',
                              }}
                              selectWrapperProps={{
                                className: 'enrich-pref-dropdown',
                              }}
                              dropdownOffset={{ top: 220, bottom: 0 }}
                              showPlaceholderInOptions={false}
                              placeholder="-NA-"
                              onChange={(value) => {
                                fieldMappingState.updateCustomFieldMappings({
                                  ...fieldData,
                                  enrichmentPreference: value,
                                });
                              }}
                              options={enrichmentPreferenceOptions}
                              value={
                                enrichmentPreference && adaptKey !== 'email'
                                  ? enrichmentPreference
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              {fieldMappingState.fieldMappingData?.customValueMapping?.length >
                0 && (
                <div className="field custom-header">Custom Adapt Value</div>
              )}
              <div
                className="custom-value-mappings"
                ref={customValueWrapperRef}
              >
                {fieldMappingState.fieldMappingData?.customValueMapping?.map(
                  (customValue, index) => {
                    const {
                      adaptValue = '',
                      crmKey,
                      isTypeMismatch = false,
                    } = customValue;

                    const crmFieldData =
                      fieldMappingState.fieldMappingData?.crmFields?.find(
                        (data) => {
                          return data.key === crmKey;
                        },
                      );

                    return (
                      <div
                        className="field"
                        key={`key-${index + 1}`}
                        id={`field-${crmKey}-${mappingType}`}
                      >
                        <div className="custom-value-wrapper">
                          {crmFieldData && crmFieldData.type === 'ENUM' ? (
                            <Select
                              enableValuesOutsideOfOptions
                              selectWrapperProps={{
                                className: `${
                                  validateField && crmKey === '' ? 'error' : ''
                                } ${hasEditAccess ? '' : ' disabled'}`,
                              }}
                              selectInputProps={{ disabled: !hasEditAccess }}
                              searchInputProps={{ placeholder: 'Search Field' }}
                              value={adaptValue}
                              enableSearch
                              dropdownOffset={{ top: 220, bottom: 0 }}
                              showPlaceholderInOptions={false}
                              placeholder="Yet to be mapped"
                              onChange={(value) => {
                                const selectedVal = value;
                                fieldMappingState.updateCustomValueMapping({
                                  type: 'EDIT',
                                  index,
                                  data: {
                                    crmKey,
                                    isTypeMismatch,
                                    adaptValue: selectedVal,
                                  },
                                });
                              }}
                              options={
                                Object.keys(crmFieldData.options)?.length > 0
                                  ? Object.entries(crmFieldData.options)?.map(
                                      (entry) => {
                                        const [key, label] = entry;
                                        return {
                                          label,
                                          value: key,
                                        };
                                      },
                                    )
                                  : []
                              }
                            />
                          ) : (
                            <div className="input-with-error">
                              <input
                                disabled={!hasEditAccess}
                                placeholder="Enter your custom value"
                                className={`custom-value${
                                  validateField && adaptValue === ''
                                    ? ' error'
                                    : ''
                                }`}
                                value={adaptValue}
                                onChange={(e) => {
                                  fieldMappingState.updateCustomValueMapping({
                                    type: 'EDIT',
                                    index,
                                    data: {
                                      crmKey,
                                      isTypeMismatch,
                                      adaptValue: e.target.value,
                                    },
                                  });
                                }}
                              />
                              {validateField && adaptValue === '' && (
                                <span className="error-message">
                                  Please enter a value
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="custom-value-crm">
                          <div
                            className={`crm-field${
                              hasEditAccess ? '' : ' disabled'
                            }`}
                          >
                            <Select
                              enableValuesOutsideOfOptions
                              selectWrapperProps={{
                                className: `${
                                  validateField && crmKey === '' ? 'error' : ''
                                } ${
                                  isTypeMismatch ? 'type-mismatch-error' : ''
                                }`,
                              }}
                              selectInputProps={{ disabled: !hasEditAccess }}
                              searchInputProps={{ placeholder: 'Search Field' }}
                              value={
                                crmKey
                                  ? fieldMappingState.fieldMappingData?.crmFields?.find(
                                      (data) => {
                                        return data.key === crmKey;
                                      },
                                    )?.label ?? crmKey
                                  : ''
                              }
                              enableSearch
                              dropdownOffset={{ top: 220, bottom: 0 }}
                              showPlaceholderInOptions={false}
                              placeholder="Yet to be mapped"
                              onChange={(value) => {
                                const selectedVal = value;
                                processValueMappingSelectedCrmField(
                                  selectedVal,
                                  index,
                                  adaptValue,
                                );
                              }}
                              options={
                                fieldMappingState.availableCRMFields?.length > 0
                                  ? [
                                      ...(fieldMappingState.availableCRMFields?.map(
                                        (data) => {
                                          return {
                                            label: data.label,
                                            value: data.key,
                                          };
                                        },
                                      ) ?? []),
                                    ]
                                  : []
                              }
                            />
                            {isTypeMismatch && (
                              <div className="type-mismatch-error-msg">
                                Please select a 'string' or 'picklist' field.
                              </div>
                            )}
                            {validateField && crmKey === '' && (
                              <span className="error-message">
                                Please select a value
                              </span>
                            )}
                          </div>
                          {hasEditAccess && (
                            <Menu align="center">
                              <Menu.Item
                                text="Remove"
                                onClick={() => {
                                  fieldMappingState.updateCustomValueMapping({
                                    type: 'DELETE',
                                    index,
                                  });
                                }}
                              />
                            </Menu>
                          )}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
              <div
                className={`add-custom-field${
                  hasEditAccess ? '' : ' disabled'
                }`}
              >
                <div className="plus-icon-wrap" onClick={addCustomValueField}>
                  <PlusIcon className="plus-icon" />
                </div>
                <span className="add-field-text" onClick={addCustomValueField}>
                  Add Custom Field
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(FieldMapping);
