/* eslint-disable no-restricted-globals */
import { observable, action } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import { toasterState } from 'Components/common/base/Toaster';
import { EMPTY_FUNCTION } from '../utils/constants';
import userDetail from './userDetail';

const defaultCrmConnectionStatus = {
  SALESFORCE: false,
  HUBSPOT: false,
  PIPEDRIVE: false,
  ZOHO: false,
  OUTREACH: false,
  MARKETO: false,
  SALESGEAR: false,
};

let oAuthWindow = null;

class CrmIntegrationState {
  @observable accessor crmConnectionStatus = { ...defaultCrmConnectionStatus };

  @observable accessor isLoading = false;

  allowedCrms = [
    'SALESFORCE',
    'HUBSPOT',
    'PIPEDRIVE',
    'ZOHO',
    'OUTREACH',
    'MARKETO',
    'SALESGEAR',
  ];

  apiStat = {
    getAllStatusFetched: false,
  };

  tempDataForOAuthCallback = {
    crmType: '',
    callback: EMPTY_FUNCTION,
  };

  @action
  setCrmConnectionStatus(obj = {}) {
    this.crmConnectionStatus = {
      ...this.crmConnectionStatus,
      ...obj,
      PIPEDRIVE: obj.PIPE_DRIVE,
    };
  }

  @action
  getCrmConnectionDetails = async () => {
    this.isLoading = true;
    const thisObj = this;
    try {
      const config = {
        url: URLS?.crmIntegrations?.getAllStatus,
      };
      const res = await makeApi(config);

      if (res?.data) {
        const { responseCode, ...remainingData } = res.data;
        if (responseCode === 200) {
          thisObj.setCrmConnectionStatus(remainingData);
          this.apiStat.getAllStatusFetched = true;
        }
      }
      this.isLoading = false;
    } catch (e) {
      console.log('get Crm Connection Details failed', e);
      this.isLoading = false;
    }
  };

  eventCallback = (ev) => {
    console.log('data', ev);
    if (ev.data && ev.data.adaptIntegrations === true) {
      oAuthWindow.close();
      window.removeEventListener('message', this.eventCallback);
      const response = {};
      response.data = ev.data;
      this.tempDataForOAuthCallback.callback(
        response,
        null,
        this.tempDataForOAuthCallback.crmType,
      );
    }
  };

  @action
  openOAuthPopThruButClick = (tempOAuthData, callback) => {
    const { url, title, width, height, top, left, crmType } = tempOAuthData;
    this.tempDataForOAuthCallback = {
      crmType,
      callback,
    };
    window.removeEventListener('message', this.eventCallback);
    window.addEventListener('message', this.eventCallback);
    oAuthWindow = window.open(
      url,
      title,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`,
    );
  };

  @action
  openOAuthPopup(oAuthCallBack = () => {}, authUrl, crmType) {
    const thisObj = this;
    if (thisObj.oAuthWindow) {
      thisObj.oAuthWindow.close();
    }

    const width = 900;
    const height = 900;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;

    const tempOAuthData = {};
    tempOAuthData.url = authUrl;
    tempOAuthData.title = `${crmType} Login`;
    tempOAuthData.width = width;
    tempOAuthData.height = height;
    tempOAuthData.top = top;
    tempOAuthData.left = left;
    if (crmType !== undefined) {
      tempOAuthData.crmType = crmType;
    }

    thisObj.openOAuthPopThruButClick(tempOAuthData, oAuthCallBack);
    if (thisObj.oAuthWindow) {
      thisObj.oAuthWindow.focus();
    }
  }

  updateConnectionStatusInMemberPreferences = (crmType, connected) => {
    if (crmType === 'SALESFORCE' || crmType === 'HUBSPOT') {
      userDetail.setUserInfo({
        ...userDetail.userInfo,
        crmPreferences: {
          ...(userDetail.userInfo?.crmPreferences || {}),
          [crmType]: {
            ...(userDetail.userInfo?.crmPreferences?.[crmType] || {}),
            connected,
          },
        },
      });
    }
  };

  @action
  connectToCrm = async (props) => {
    const { crmType, successCallback } = props;
    const thisObj = this;
    try {
      const url = Utils.generateUrl(URLS?.crmIntegrations?.connect, {
        crmType,
      });
      const config = {
        url,
        method: 'POST',
      };
      const res = await makeApi(config);

      if (res?.data) {
        const { responseCode, responseData } = res.data;
        const { authUrl = null } = responseData;
        if (responseCode === 407 && authUrl !== null) {
          const authCallback = (response) => {
            if (response && response.data) {
              const { responseCode: resCode, responseData: resData } =
                response.data;
              if (resCode === 200) {
                thisObj.setCrmConnectionStatus({ [crmType]: true });
                this.updateConnectionStatusInMemberPreferences(crmType, true);
                successCallback();
                toasterState.setToastMsg(
                  ` You have successfully connected to ${crmType}`,
                  'success',
                );
              } else {
                console.log('resData ', resData);
                Utils.showCrmConnectionError({
                  showPopup: true,
                  responseCode: resCode,
                  ...resData,
                });
              }
            }
          };
          thisObj.openOAuthPopup(authCallback, authUrl, crmType);
        } else if (responseCode === 403 || responseCode === 412) {
          Utils.showCrmConnectionError({
            showPopup: true,
            ...res.data,
            ...responseData,
          });
        }
      }
    } catch (e) {
      console.error('connectToCrm failed', e);
    }
  };

  @action
  disconnectCrm = async (crmType) => {
    const thisObj = this;
    this.isLoading = true;
    try {
      const url = Utils.generateUrl(URLS?.crmIntegrations?.disconnect, {
        crmType,
      });
      const config = {
        url,
        method: 'POST',
      };
      const res = await makeApi(config);

      if (res?.data) {
        const { responseCode } = res.data;
        if (responseCode === 200) {
          toasterState.setToastMsg(
            `Successfully disconnected from ${crmType} CRM`,
            'success',
          );
          thisObj.setCrmConnectionStatus({ [crmType]: false });
          this.updateConnectionStatusInMemberPreferences(crmType, false);
        } else {
          toasterState.setToastMsg(
            'Something went wrong. Please try again. If the problem persists, please write to us at <a href="mailto:support@adapt.io" target="_blank">support@adapt.io</a>',
            'failure',
          );
        }
        this.isLoading = false;
      }
    } catch (e) {
      console.error('disconnectCrm failed', e);
      this.isLoading = false;
    }
  };
}

const crmIntegrationState = new CrmIntegrationState();

export default crmIntegrationState;
