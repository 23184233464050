import React from 'react';
import ExportIcon from 'Assets/svg/new_icons/export.svg';
import ItemIcon from 'Assets/svg/new_icons/item.svg';
import { commonModalState } from '../base/Modal/CommonModal';

function showExportFailedModal(data) {
  const { type, message } = data;
  commonModalState.setShowModal(true, {
    title: `${type} Export Failed`,
    description: message || (
      <>
        An error occurred while exporting the contact. Please try again or
        contact{' '}
        <a href="mailto:support@adapt.io" target="_blank" rel="noreferrer">
          support@adapt.io
        </a>
        .
      </>
    ),
    primaryButtonText: 'OK',
  });
}

function Note() {
  return (
    <div className="note">
      <span className="note-title">Note :</span>
      <span>Contacts without email will not be exported.</span>
    </div>
  );
}

function Description(props) {
  const { type, result, customMsg } = props;
  if (customMsg !== undefined && customMsg !== '') {
    return customMsg;
  }
  const {
    exportSuccessCount,
    exportFailedCount,
    duplicateLeadCount,
    errorType,
    contactIdsWithoutEmail = [],
  } = result || '';
  const text = exportSuccessCount && exportSuccessCount > 1 ? 'Leads' : 'Lead';
  const failLeadName = exportFailedCount > 1 ? 'Leads' : 'Lead';

  let totalCount = exportSuccessCount ? parseInt(exportSuccessCount, 10) : 0;
  totalCount += exportFailedCount ? parseInt(exportFailedCount, 10) : 0;
  totalCount += duplicateLeadCount ? parseInt(duplicateLeadCount, 10) : 0;

  const duplicateCount = duplicateLeadCount && duplicateLeadCount;
  const dupLeadName = duplicateCount > 1 ? 'Leads' : 'Lead';
  if (exportSuccessCount > 0 || duplicateCount > 0) {
    return (
      <>
        <ul>
          {exportSuccessCount > 0 && (
            <li>
              <ItemIcon />
              <p>
                {exportSuccessCount} {text} exported successfully
              </p>
            </li>
          )}
          {exportFailedCount > 0 && (
            <li>
              <ItemIcon />
              <p>
                {exportFailedCount} {failLeadName} export failed
              </p>
            </li>
          )}
          {duplicateCount > 0 && (
            <li>
              <ItemIcon />
              <p>
                {duplicateCount} {dupLeadName} not exported as they already
                exist in your {type} account
              </p>
            </li>
          )}
        </ul>
        {exportFailedCount > 0 && contactIdsWithoutEmail.length > 0 && <Note />}
      </>
    );
  }
  if (errorType === 'CONTACT_ALREADY_PRESENT_IN_SEQUENCE') {
    return '1 Lead not exported as they already exist in your Salesgear account.';
  }
  if (
    contactIdsWithoutEmail.length === totalCount &&
    contactIdsWithoutEmail.length === 1
  ) {
    return "Hey! It looks like this contact doesn't have an email address and hence we are not exporting this contact.";
  }
  if (
    contactIdsWithoutEmail.length === totalCount &&
    contactIdsWithoutEmail.length > 1
  ) {
    return "Hey! It looks like these contacts doesn't have email address and hence we are not exporting these contacts.";
  }
  return (
    <>
      <div>
        An error occurred while exporting the {failLeadName}. Please try again
        or contact{' '}
        <a href="mailto:support@adapt.io" target="_blank" rel="noreferrer">
          support@adapt.io
        </a>
        .
      </div>
      {exportFailedCount > 0 &&
        contactIdsWithoutEmail.length > 0 &&
        contactIdsWithoutEmail.length !== totalCount && <Note />}
    </>
  );
}

function showExportStatusPopup(data) {
  const { type, result, customMsg } = data;
  const { exportSuccessCount, duplicateLeadCount } = result || '';

  const status =
    exportSuccessCount > 0 || duplicateLeadCount > 0 ? 'Success' : 'Failed';

  commonModalState.setShowModal(true, {
    modalProps: { className: 'export-status-popup' },
    Icon: ExportIcon,
    title: `${type} Export ${status}`,
    description: (
      <Description type={type} result={result} customMsg={customMsg} />
    ),
    primaryButtonText: 'OK',
  });
}

export { showExportFailedModal, showExportStatusPopup };
