/* eslint-disable */
'use strict';
import { TOTANGO_SERVICE_ID } from '../constants';

/*start Totango*/
window.totango_options = {
  service_id: TOTANGO_SERVICE_ID,
}; 
(function() {
  window.totango_tmp_stack = [];
  window.totango = {
    go: function() {
      return -1;
    },
    setAccountAttributes: function() {},
    identify: function() {},
    track: function(t, o, n, a) {
      console.log('Totango event Tracked' + t + o + n + a);
      window.totango_tmp_stack.push({
        activity: t,
        module: o,
        org: n,
        user: a,
      });
      return -1;
    },
  };
  var e = document.createElement('script');
  e.type = 'text/javascript';
  e.async = true;
  e.src =
    ('https:' == document.location.protocol ? 'https://' : 'http://') +
    'tracker.totango.com/totango3.js';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(e, s);
})();
/*end Totango*/
