import React from 'react';
import integrationDataMap from './integrationDataMap';

const getCRMName = (crmType) => {
  return (integrationDataMap[crmType]?.title
    ?.split(' ')
    ?.map((data) => { return `${data.substring(0, 1).toUpperCase()}${data.substring(1)}`; })
    ?.join(' ') ?? crmType);
};

const getAdminNotConnectedMsg = (props) => {
  const { crmType, adminEmail, redirectToIntegrations, userName } = props;
  const title = 'Connection Alert';
  const crmName = getCRMName(crmType);
  // redirectToIntegrations is made use to differentiate between integrations and contacts page
  const description =
    redirectToIntegrations !== undefined ? (
      <>
        Sorry {userName}! We’re unable to connect to {crmName}{' '}
        CRM as the admin has not connected to it. Please write to your account
        administrator{' '}
        <a
          href={`mailto:${adminEmail}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {adminEmail}
        </a>{' '}
        or{' '}
        <a
          href="mailto:success@adapt.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          success@adapt.io
        </a>{' '}
        to resolve.
      </>
    ) : (
      <>
        Please contact your account administrator{' '}
        <a
          href={`mailto:${adminEmail}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {adminEmail}
        </a>{' '}
        to connect and configure the {crmName} integration before proceeding.
        Please contact us at{' '}
        <a
          href="mailto:success@adapt.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          success@adapt.io
        </a>{' '}
        to learn more.
      </>
    );

  const btnText = 'ok';
  return { title, description, btnText };
};

const getUserNotConnectedMsg = (props) => {
  const { crmType, redirectToIntegrations } = props;
  const title = 'Connection Alert';
  const description = `Your ${getCRMName(
    crmType,
  )} CRM account is not connected! To proceed with the export, please connect your CRM with your Adapt account.`;

  let btnText = 'ok';
  if (redirectToIntegrations) {
     btnText = 'Go to Integrations';
  }
  return { title, description, btnText };
};

const getAuthorizationRequiredMsg = (props) => {
  const { crmType, redirectToIntegrations } = props;
  const title = 'Connection Alert';
  const description = `Your ${getCRMName(
    crmType,
  )} CRM account is not connected or authorization expired! To proceed with the export, please connect your CRM or disconnect and connect if already connected with your Adapt account.`;

  let btnText = 'ok';
  if (redirectToIntegrations) {
    btnText = 'Go to Integrations';
  }
  return { title, description, btnText };
};

const getAdminConnectedToDifferentCrmMsg = (props) => {
  const { crmType, adminEmail, adminDomain, userDomain, userName } = props;
  const title = 'Connection Alert';
  const description = (
    <>
      <div>Hey {userName},</div>
      <div>
        It looks like the CRM account ({getCRMName(crmType)} - {userDomain})
        you’re trying to connect is different from the CRM account your admin is
        connected to ({getCRMName(crmType)} - {adminDomain}).
      </div>
      <div>
        Please write to your account administrator{' '}
        <a
          href={`mailto:${adminEmail}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {adminEmail}
        </a>{' '}
        or{' '}
        <a
          href="mailto:success@adapt.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          success@adapt.io
        </a>{' '}
        to resolve.
      </div>
    </>
  );

  const btnText = 'ok';
  return { title, description, btnText };
};

export {
  getAdminConnectedToDifferentCrmMsg,
  getAdminNotConnectedMsg,
  getUserNotConnectedMsg,
  getAuthorizationRequiredMsg,
};
