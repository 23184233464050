import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import { UPGRADE_TRIGGERS } from 'Utils/constants';
import { observable, action } from 'mobx';

class FilterState {
  @observable accessor selectedFilter = null;

  @action
  setSelectedFilter = (value) => {
    if (
      value.key === 'companyInclusion' &&
      !featureFlagsAndPreferencesState.flags.inclusionListEnabled
    ) {
      if (this.selectedFilter !== null) {
        this.closeFilter();
      }
      commonUpgradePopupState.setShowUpgradePopup(
        UPGRADE_TRIGGERS.ABM_LIST_BUILDING,
      );
    } else {
      if (value.focus) {
        document.getElementsByClassName(value.key)?.[0]?.scrollIntoView();
      }
      this.selectedFilter = value;
    }
  };

  @action
  closeFilter = () => {
    this.selectedFilter = null;
  };
}

const filterState = new FilterState();

export default filterState;
