import React from 'react';
import { observer } from 'mobx-react';
import format from 'date-fns/format';
import Utils from 'Utils/utils';
import './styles.scss';

function CompanyFundingDetails(props) {
  const {
    lastFundingAmount,
    lastFundingDate,
    lastFundingType,
    totalFundingAmount,
  } = props;

  return (
    <div className="company-funding-details">
      {totalFundingAmount > 0 || lastFundingType ? (
        <>
          {totalFundingAmount > 0 && (
            <p>
              Total Funding:{' '}
              <span className="value">
                ${Utils.formatNumber(totalFundingAmount)}
              </span>
            </p>
          )}
          {lastFundingType && (
            <p
              className="show-ellipsis"
              title={`$${Utils.formatNumber(lastFundingAmount)} ${lastFundingType}`}
            >
              Last Round:{' '}
              <span className="value">
                {`${lastFundingAmount > 0 ? `$${Utils.formatNumber(lastFundingAmount)}` : ''} ${lastFundingType}`}
              </span>
            </p>
          )}
          {lastFundingDate && lastFundingType && (
            <p>{format(lastFundingDate, 'dd-MMM-yyyy')}</p>
          )}
        </>
      ) : (
        '- NA -'
      )}
    </div>
  );
}

export default observer(CompanyFundingDetails);
