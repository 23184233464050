import React, { useMemo, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate, useMatch, Navigate } from 'react-router-dom';
import SavedSearchEmpty from 'Assets/png/saved-search-empty.png';
import AccountIcon from 'Assets/svg/new_icons/account.svg';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import CalendarIcon from 'Assets/svg/calendar.svg';
import Input, { getSuggestionStyle } from 'Components/common/base/Input';
import LoadingWrapper from 'Components/common/base/Loader';
import Pagination from 'Components/common/pagination';
import SharedListIcon from 'Components/common/SharedListIcon';
import SavedSearchPopUp, {
  savedSearchPopupState,
} from 'Components/common/savedSearchPopup';
import ChipList from 'Components/common/base/ChipList';
import Button from 'Components/common/base/Button';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import useOutsideClick from 'Components/common/base/useOutsideClick';
import Menu from 'Components/common/base/Menu';
import Title from 'Components/common/base/Title';
import PageContentHeader from 'Components/common/PageContentHeader';
import { getAppliedFilters } from 'Pages/prospectSearch/filterSection/filters';
import userDetail from 'Stores/userDetail';
import savedSearchState from 'Stores/savedSearch';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

const pageFeatures = {
  contact: { emailSchedule: true, sharing: true },
  company: { emailSchedule: false, sharing: false },
};

function returnCapitalizedWord(word = '') {
  return word.charAt(0) + word.slice(1).toLowerCase();
}

function getMaxValues(filter, maxValue) {
  const { included, excluded } = filter;
  if (included.length > maxValue) {
    return { ...filter, included: included.slice(0, maxValue), excluded: [] };
  }
  if (included.length > 0 && excluded.length > 0) {
    return {
      ...filter,
      included,
      excluded: excluded.slice(0, maxValue - included.length),
    };
  }
  return { ...filter, included, excluded: excluded.slice(0, maxValue) };
}

function getSelectiveFilters(appliedFilters) {
  let selectiveFilters = [];
  let remainingCount = 0;
  let valuesShown = 0;

  const getFilterValuesLength = (filter) => {
    return [...filter.included, ...filter.excluded].length;
  };

  appliedFilters.forEach((appliedFilter) => {
    if (valuesShown <= 4 && selectiveFilters.length < 2) {
      const modifiedFilter = getMaxValues(appliedFilter, 4 - valuesShown);
      selectiveFilters = [...selectiveFilters, modifiedFilter];
      valuesShown = [...modifiedFilter.included, ...modifiedFilter.excluded]
        .length;
      remainingCount += getFilterValuesLength(appliedFilter) - valuesShown;
    } else {
      remainingCount += getFilterValuesLength(appliedFilter);
    }
  });
  return { selectiveFilters, remainingCount };
}

function RenderAppliedFilters(props) {
  const { savedSearch, page } = props;
  const { searchTemplate, id } = savedSearch;
  const [showMore, setShowMore] = useState(false);

  const appliedFilters = useMemo(() => {
    return getAppliedFilters(JSON.parse(searchTemplate), page);
  }, [savedSearch]);

  const { selectiveFilters, remainingCount } = getSelectiveFilters(
    Utils.copyObjectWithoutReference(appliedFilters),
  );

  const handleClickOutside = () => {
    setShowMore(false);
  };

  const filtersToShow = showMore ? appliedFilters : selectiveFilters;

  const ref = useOutsideClick(handleClickOutside);

  return (
    <div
      className={`wrapper ${showMore ? 'show-more' : ''}`}
      style={
        ref.current
          ? getSuggestionStyle(ref.current, {}, { considerHeight: false })
          : {}
      }
      ref={ref}
    >
      {filtersToShow.map((appliedFilter, index) => {
        const { text, included, excluded } = appliedFilter;
        return (
          <div className="item-wrapper" key={`${text}-${id}`}>
            <ChipList
              title={text}
              keySuffix={id}
              includedList={included}
              excludedList={excluded}
              limitNumberOfTags={false}
            />
            {remainingCount > 0 &&
              !showMore &&
              index === filtersToShow.length - 1 && (
                <span
                  className="more-text"
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  {`+${remainingCount} more`}
                </span>
              )}
          </div>
        );
      })}
    </div>
  );
}

function EmptySavedSearches({
  searchTerm,
  noSavedSearch,
  emptySavedSearch,
  isContactSavedSearches,
}) {
  return (
    <>
      {noSavedSearch && (
        <div className="save-searches-no-results">
          <div className="image-wrap">
            <img src={SavedSearchEmpty} alt="Empty state illustration" />
          </div>
          <div className="no-data-text">
            You have not saved any searches. Start saving your search criteria
            and retrieve search results easily without having to enter all
            filters each time.
          </div>
          <div className="nolist-action-wrap">
            <Link
              to={
                isContactSavedSearches
                  ? CONSTANTS.PAGE_PATH_MAP.CONTACT_SEARCH
                  : CONSTANTS.PAGE_PATH_MAP.COMPANY_SEARCH
              }
              className="button outline"
            >
              {isContactSavedSearches
                ? 'Go to Contact Search'
                : 'Go to Company Search'}
            </Link>
          </div>
        </div>
      )}
      {emptySavedSearch && (
        <div className="save-searches-no-results">
          <div className="image-wrap">
            <img src={SavedSearchEmpty} alt="" />
          </div>
          <div className="no-data-text">
            No result found for the saved search, <br />
            <b>{`“${searchTerm}”`}</b>
          </div>
        </div>
      )}
    </>
  );
}

function SavedSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isUpdateAPILoading, setIsUpdateAPIloading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, count: 10 });
  const tableBodyRef = useRef();
  const navigate = useNavigate();
  const match = useMatch('advanced-search/:type/saved-searches');
  const page = match.params?.type;
  const isContactSavedSearches = page === 'contact';

  const savedSearches = useMemo(() => {
    return page === 'contact'
      ? savedSearchState.contactSavedSearches
      : savedSearchState.companySavedSearches;
  }, [
    match,
    savedSearchState.contactSavedSearches,
    savedSearchState.companySavedSearches,
  ]);

  const features = pageFeatures[page];

  const searchRegex = new RegExp(searchTerm, 'gi');

  const searchedResults = savedSearches.filter((savedSearch) => {
    const { templateName } = savedSearch;
    return (templateName || '').match(searchRegex);
  });

  const savedSearchesToShow = searchedResults.filter((...args) => {
    return (
      args[1] >= (pagination.page - 1) * pagination.count &&
      args[1] < pagination.page * pagination.count
    );
  });

  const changePageLimit = (val) => {
    setPagination({ count: val, page: 1 });
  };

  const changePage = (pageNo) => {
    setPagination({ ...pagination, page: pageNo + 1 });
  };

  const updateSavedSearch = async (data) => {
    const { id, searchTemplate } = savedSearchPopupState.data;
    const { templateName, isSharedSearch, ...remainingProps } = data;
    const isEmailScheduleUpdated = Object.keys(remainingProps).some((key) => {
      return remainingProps[key] !== savedSearchPopupState.data[key];
    });
    setIsUpdateAPIloading(true);
    const isSuccess = await savedSearchState.updateSavedSearch(
      {
        id,
        searchTemplate,
        templateName,
        isSharedSearch,
        ...(features.emailSchedule && isEmailScheduleUpdated
          ? { ...remainingProps }
          : remainingProps.frequency
            ? {}
            : {
                status: 'INACTIVE',
              }),
      },
      undefined,
      page,
    );
    if (isSuccess) {
      Utils.mixpanelTrack(CONSTANTS.MX_SAVED_SEARCH_EVENTS.EDIT_SAVED_SEARCH, {
        name: templateName,
        ...remainingProps,
      });
      savedSearchPopupState.setShowModal(false);
      savedSearchPopupState.setData({});
    }
    setIsUpdateAPIloading(false);
  };

  const updateSharing = async (data, eventName) => {
    setIsUpdateAPIloading(true);
    const { id, searchTemplate, templateName, isSharedSearch } = data;
    const isSuccess = await savedSearchState.updateSavedSearch(
      {
        id,
        searchTemplate,
        templateName,
        isSharedSearch,
      },
      isSharedSearch
        ? {
            success: 'Search shared successfully! Ready to explore.',
            failure: 'Oops! Sharing failed. Please try again.',
          }
        : {
            success: 'Search unshared successfully. Your privacy is secured.',
            failure: 'Uh-oh! Unsharing failed. Please try again.',
          },
      page,
    );
    if (isSuccess) {
      Utils.mixpanelTrack(eventName, {
        data,
      });
    }
    setIsUpdateAPIloading(false);
  };

  const shareSavedSearch = async (data) => {
    updateSharing(data, CONSTANTS.MX_SAVED_SEARCH_EVENTS.SHARE_SAVED_SEARCH);
  };

  const unShareSavedSearch = async (data) => {
    updateSharing(data, CONSTANTS.MX_SAVED_SEARCH_EVENTS.UNSHARE_SAVED_SEARCH);
  };

  const deleteSavedSearch = (savedSearch) => {
    const callback = () => {
      setShowLoader(true);
      savedSearchState.deleteSavedSearch(
        savedSearch.id,
        () => {
          setShowLoader(false);
        },
        page,
      );
    };
    commonModalState.setShowModal(true, {
      title: 'Delete Saved Search',
      description: (
        <>
          Are you sure you want to delete <b>“{savedSearch.templateName}”</b>?
        </>
      ),
      primaryButtonText: 'Cancel',
      secondaryButtonText: 'Delete',
      secondaryButtonCallback: callback,
      Icon: WarningIcon,
    });
  };

  useEffect(() => {
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  }, [pagination.page, pagination.count, tableBodyRef]);

  useEffect(() => {
    Utils.mixpanelTrack(
      CONSTANTS.MX_SAVED_SEARCH_EVENTS.SAVED_SEARCH_PAGE_LOAD,
      { count: savedSearches.length, page },
    );
  }, []);

  const isEmailScheduleEnabled =
    featureFlagsAndPreferencesState.isJobAlertsEnabled() &&
    features.emailSchedule;

  return (
    <div
      className={`saved-search-wrapper${
        isEmailScheduleEnabled ? '' : ' update-disabled'
      }`}
    >
      <Title
        title={
          isContactSavedSearches
            ? 'Contact Saved Search'
            : 'Company Saved Search'
        }
      />
      {!featureFlagsAndPreferencesState.isSavedSearchEnabled() && (
        <Navigate to={CONSTANTS.PAGE_PATH_MAP.DASHBOARD} />
      )}
      <SavedSearchPopUp
        isEdit
        isAPILoading={isUpdateAPILoading}
        data={savedSearchPopupState.data}
        onSubmit={updateSavedSearch}
      />
      <LoadingWrapper
        className="saved-search-page-loader"
        show={
          showLoader || !savedSearchState.initialLoadedAPIs.contactSavedSearches
        }
        position="fixed"
      />
      <PageContentHeader
        className="top-wrapper"
        titleData={{
          text: 'Saved Searches',
          href: isContactSavedSearches
            ? CONSTANTS.PAGE_PATH_MAP.CONTACT_SEARCH
            : CONSTANTS.PAGE_PATH_MAP.COMPANY_SEARCH,
        }}
      >
        <Input
          clearClick={() => {
            setSearchTerm('');
          }}
          showClearIcon
          showSearchIcon
          inputProps={{
            disabled: savedSearches.length === 0,
            value: searchTerm,
            placeholder: 'Search by saved name',
          }}
          onChange={(value) => {
            if (pagination.page > 1) {
              setPagination({ ...pagination, page: 1 });
            }
            setSearchTerm(value);
          }}
        />
      </PageContentHeader>
      <div className="content-wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <span>Search Details</span>
            <span>Filters Applied</span>
            {isEmailScheduleEnabled && (
              <span className="email-schedule">Email Scheduled</span>
            )}
            <span className="open-search"> </span>
          </div>
          <div className="table-body" ref={tableBodyRef}>
            {savedSearchesToShow.length > 0
              ? savedSearchesToShow.map((savedSearch) => {
                  const {
                    id,
                    templateName,
                    creationTimeStamp,
                    searchTemplate,
                    memberId,
                    isSharedSearch,
                  } = savedSearch;
                  return (
                    <div className="table-row" key={id} data-id={id}>
                      <div className="table-row-inner-wrap">
                        <div className="saved-search-name">
                          <div className="name-wrapper">
                            <div title={templateName} className="show-ellipsis">
                              {templateName}
                            </div>
                            <SharedListIcon
                              condition={isSharedSearch}
                              iconTitle="Shared search"
                            />
                          </div>
                          <div className="additional-info">
                            <div className="creator">
                              <AccountIcon />
                              {memberId === userDetail.userInfo.memberId ||
                              !memberId
                                ? 'Myself'
                                : userDetail.sharedListAuthorData[memberId]}
                            </div>
                            <div
                              className={`created-on${
                                creationTimeStamp ? '' : ' no-data'
                              }`}
                            >
                              <CalendarIcon />
                              {creationTimeStamp
                                ? Utils.getFormattedDate(
                                    creationTimeStamp,
                                    true,
                                  )
                                : '-NA-'}
                            </div>
                          </div>
                        </div>
                        <div className="applied-filters">
                          <RenderAppliedFilters
                            savedSearch={savedSearch}
                            page={page}
                          />
                        </div>
                        {isEmailScheduleEnabled && (
                          <div
                            className={`email-schedule${
                              savedSearch.frequency &&
                              savedSearch.status === 'ACTIVE'
                                ? ''
                                : ' no-data'
                            }`}
                          >
                            {savedSearch.frequency &&
                            savedSearch.status === 'ACTIVE'
                              ? `${returnCapitalizedWord(
                                  savedSearch.frequency,
                                )} on${
                                  savedSearch.ordinalDay
                                    ? ` ${returnCapitalizedWord(
                                        savedSearch.ordinalDay,
                                      )}`
                                    : ''
                                } ${returnCapitalizedWord(savedSearch.day)}`
                              : '-NA-'}
                          </div>
                        )}
                        <div className="open-search">
                          <div className="wrapper">
                            {searchTemplate !== null && (
                              <Button
                                variant="outline"
                                buttonProps={{ text: 'Open Search' }}
                                onClick={() => {
                                  Utils.mixpanelTrack(
                                    CONSTANTS.MX_SAVED_SEARCH_EVENTS
                                      .OPEN_SEARCH_CLICKED,
                                    {
                                      id,
                                      name: templateName,
                                    },
                                  );
                                  navigate(`/advanced-search/${page}/${id}`);
                                }}
                              />
                            )}
                            {userDetail.userInfo.memberId ===
                            savedSearch.memberId ? (
                              <Menu align="end">
                                <Menu.Item
                                  text="Edit"
                                  onClick={() => {
                                    savedSearchPopupState.setShowModal(true);
                                    savedSearchPopupState.setData(savedSearch, {
                                      enableEmailSchedule:
                                        isContactSavedSearches,
                                      type: page,
                                    });
                                  }}
                                />
                                <Menu.Item
                                  text="Delete"
                                  onClick={() => {
                                    deleteSavedSearch(savedSearch);
                                  }}
                                />

                                {!savedSearch.isSharedSearch &&
                                  features.sharing && (
                                    <Menu.Item
                                      text="Share"
                                      onClick={() => {
                                        shareSavedSearch({
                                          ...savedSearch,
                                          isSharedSearch: true,
                                        });
                                      }}
                                    />
                                  )}
                                {savedSearch.isSharedSearch &&
                                  features.sharing && (
                                    <Menu.Item
                                      text="Unshare"
                                      onClick={() => {
                                        unShareSavedSearch({
                                          ...savedSearch,
                                          isSharedSearch: false,
                                        });
                                      }}
                                    />
                                  )}
                              </Menu>
                            ) : (
                              <div className="menu-placeholder" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : savedSearchState.initialLoadedAPIs.contactSavedSearches && (
                  <EmptySavedSearches
                    searchTerm={searchTerm}
                    noSavedSearch={savedSearches.length === 0}
                    isContactSavedSearches={isContactSavedSearches}
                    emptySavedSearch={
                      savedSearches.length > 0 &&
                      savedSearchesToShow.length === 0 &&
                      searchTerm !== ''
                    }
                  />
                )}
          </div>
        </div>
        <Pagination
          totalCount={searchedResults.length}
          rowsPerPage={pagination.count}
          currentPage={pagination.page}
          updateCurrentPage={changePage}
          onRowsPerPageUpdate={changePageLimit}
          rowsPerPageValues={[10, 25, 50].map((data) => {
            return { label: data.toString(), value: data };
          })}
        />
      </div>
    </div>
  );
}

export default observer(SavedSearch);
