import React, { useState } from 'react';
import IntlTelInput from 'intl-tel-input/react';
import { loadStripe } from '@stripe/stripe-js';
import { observer } from 'mobx-react';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import pricingDataState from 'Stores/pricingData';
import { STRIPE_KEY } from 'Utils/constants';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import ErrorAlertImg from 'Assets/png/error-alert-icon.png';
import Button from 'Components/common/base/Button';
import Input from 'Components/common/base/Input';

const stripePromise = loadStripe(STRIPE_KEY);

const paymentFormObj = {
  address1: '',
  city: '',
  state: '',
  country: '',
  phone: '',
};

const StripeBillingForm = observer(() => {
  const stripe = useStripe();
  const elements = useElements();

  const formFieldValue = paymentFormObj;

  const [cardErr, setCardErr] = useState('');
  const [inputVal, setInputVal] = useState(formFieldValue);
  const [errMsgAddress, setErrMsgAddress] = useState('');
  const [errMsgCity, setErrMsgCity] = useState('');
  const [errMsgState, setErrMsgState] = useState('');
  const [errMsgCountry, setErrMsgCountry] = useState('');
  const [errMsgPhone, setErrMsgPhone] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);
  const [errUserField, setErrUserField] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  const updateErrMsgField = (id) => {
    if (errUserField.length > 0 && errUserField.indexOf(id) !== -1) {
      errUserField.filter((item) => {
        return item !== id;
      });
      switch (id) {
        case 'address1':
          setErrMsgAddress('');
          break;
        case 'city':
          setErrMsgCity('');
          break;
        case 'state':
          setErrMsgState('');
          break;
        case 'country':
          setErrMsgCountry('');
          break;
        case 'phone':
          setErrMsgPhone('');
          break;
        default:
          break;
      }
    }
  };

  const changeInputVal = (id, data) => {
    const stateObj = { ...inputVal };
    stateObj[id] = data;
    updateErrMsgField(id);
    setInputVal(stateObj);
  };

  const checkPhoneNumberValidation = () => {
    const input = document.querySelector('#phone-number');
    const iti = window.intlTelInputGlobals.getInstance(input);
    return iti.isValidNumber();
  };

  const validateFormField = async () => {
    const errField = [];
    const { address1, city, state, country, phone } = inputVal;
    if (!Utils.validateStringLength(address1, 1)) {
      setErrMsgAddress('Address is required');
      errField.push('address1');
    }
    if (!Utils.validateStringLength(city, 1)) {
      setErrMsgCity('City is required');
      errField.push('city');
    }
    if (!Utils.validateStringLength(state, 1)) {
      setErrMsgState('State is required');
      errField.push('state');
    }
    if (!Utils.validateStringLength(country, 1)) {
      setErrMsgCountry('Country is required');
      errField.push('country');
    }
    if (!Utils.validateStringLength(phone, 1)) {
      setErrMsgPhone('Phone number is required');
      errField.push('phone');
    } else if (!checkPhoneNumberValidation(phone)) {
      setErrMsgPhone('Enter a valid phone number');
      errField.push('phone');
    }
    setErrUserField(errField);
    return errField;
  };

  const { isAnnualSelected, selectedPlan } = pricingDataState;
  const { price, annualPrice } = selectedPlan;
  let pricingAmount = price;
  if (isAnnualSelected) {
    pricingAmount = annualPrice;
  }

  const paymentSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setApiErrorMsg('');
    const errField = await validateFormField();

    if (errField.length < 1) {
      setBtnLoader(true);
      const card = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(card, inputVal);
      console.log('token ', token);
      console.log('error ', error);
      setCardErr('');
      if (error && Object.keys(error).length > 0) {
        setCardErr(error.message);
        setBtnLoader(false);
        // setTimeout(() => {
        //   setCardErr('');
        // }, 2000);
        Utils.mixpanelTrack(
          CONSTANTS.MX_PRICING_PAGE.LB_PRICING_FORM_STRIPE_TOKEN_ERROR,
          {
            isAnnual: isAnnualSelected,
            userAddressDetails: inputVal,
            stripeError: error.message,
          },
        );
        return false;
      }
      if (token && token.id) {
        const handleCbk = (message) => {
          setBtnLoader(false);
          setApiErrorMsg(message);
        };
        pricingDataState.paymentSubmit(inputVal, token, handleCbk);
      }
    } else {
      Utils.mixpanelTrack(
        CONSTANTS.MX_PRICING_PAGE.LB_PRICING_FORM_VALIDATION_ERROR,
        {
          isAnnual: isAnnualSelected,
          userAddressDetails: inputVal,
          errFields: errField,
        },
      );
      setBtnLoader(false);
    }
  };

  const allowOnlyNumbers = (event) => {
    const charCode = event.data.charCodeAt(0);
    const isAllowed =
      (charCode >= 48 && charCode <= 57) ||
      charCode === 32 ||
      charCode === 40 ||
      charCode === 41 ||
      charCode === 43 ||
      charCode === 45 ||
      charCode === 46;
    if (!isAllowed) {
      event.preventDefault();
    }
  };

  const setPhoneNumber = (value) => {
    changeInputVal('phone', value);
  };

  return (
    <form
      onSubmit={paymentSubmit}
      autoComplete="off"
      name="paymentForm"
      className="payment-form"
      noValidate={true}
    >
      <div className="plan-info-wrap">
        <div className="plan-type-info">
          Adapt {isAnnualSelected ? 'Annual' : 'Monthly'} Subscription
        </div>
        <div className="plan-cost-info">
          You will be charged ${Utils.viewsDownloadsNumberFormat(pricingAmount)}
          {isAnnualSelected ? ' / year' : ' / month'}
        </div>
      </div>
      <div className="card-input">
        <CardElement />
        <span className="error">{cardErr}</span>
      </div>

      <div className="popup-body">
        <Input
          inputProps={{
            name: 'address1',
            value: inputVal.address1,
            placeholder: 'Address',
          }}
          label="Address"
          onChange={(value) => {
            changeInputVal('address1', value);
          }}
          error={errMsgAddress}
        />
        <div className="form-input-group">
          <Input
            inputProps={{
              name: 'city',
              value: inputVal.city,
              placeholder: 'City',
            }}
            label="City"
            onChange={(value) => {
              changeInputVal('city', value);
            }}
            error={errMsgCity}
          />
          <Input
            inputProps={{
              name: 'state',
              value: inputVal.state,
              placeholder: 'State',
            }}
            label="State"
            onChange={(value) => {
              changeInputVal('state', value);
            }}
            error={errMsgState}
          />
        </div>
        <Input
          inputProps={{
            name: 'country',
            value: inputVal.country,
            placeholder: 'Country',
          }}
          label="Country"
          onChange={(value) => {
            changeInputVal('country', value);
          }}
          error={errMsgCountry}
        />
        <div className="phone-input-wrapper">
          <IntlTelInput
            inputProps={{ id: 'phone-number', onBeforeInput: allowOnlyNumbers }}
            onChangeNumber={setPhoneNumber}
            initOptions={{
              formatAsYouType: true,
              initialCountry: 'us',
              utilsScript: `${process.env.BUILD_VERSION ? `/${process.env.BUILD_VERSION}` : ''}/assets/utils.js`,
            }}
          />
          <span
            className="error"
            dangerouslySetInnerHTML={{
              __html: errMsgPhone || '',
            }}
          />
        </div>
      </div>
      {apiErrorMsg !== '' && (
        <div className="api-error-section">
          <div className="left-section">
            <img
              src={ErrorAlertImg}
              alt="ErrorAlertImg"
              className="error-img"
            />
          </div>
          <div className="right-section">
            <div className="error-title">Payment Failed</div>
            <div className="error-msg">{apiErrorMsg}</div>
          </div>
        </div>
      )}
      <div className="submit-section">
        <Button
          buttonProps={{ text: 'Purchase Plan', type: 'submit' }}
          isLoading={btnLoader}
        />
      </div>
    </form>
  );
});

function StripePayment(props) {
  return (
    <Elements stripe={stripePromise}>
      <StripeBillingForm {...props} />
    </Elements>
  );
}

export default observer(StripePayment);
