import React from 'react';
import { observer } from 'mobx-react';
import RightArrowIcon from 'Assets/usageAnalytics/right-arrow.svg';
import DropDownIcon from 'Assets/svg/dropdown-icon.svg';
import FolderIcon from 'Assets/svg/folder.svg';
import ExportIcon from 'Assets/svg/export-icon.svg';
import RemoveFromListIcon from 'Assets/svg/new_icons/document-error.svg';
import ExportLeads, { exportLeadsState } from 'Components/common/ExportLeads';
import Dropdown from 'Components/common/base/Dropdown';
import SharedListIcon from 'Components/common/SharedListIcon';
import ControlInput from 'Components/common/base/ControlInput';
import Button from 'Components/common/base/Button';
import useOutsideClick from 'Components/common/base/useOutsideClick';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import userDetail from 'Stores/userDetail';
import myLeadsState from 'Stores/myLeads';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

const { EXPORT_SOURCE } = CONSTANTS;
const { MY_LEADS } = EXPORT_SOURCE;

function CheckboxLabel(props) {
  const { count } = props;
  const formatNumberWithText = (number, text = '', showContactText = true) => {
    return (
      number !== undefined &&
      number !== null && (
        <>
          {Utils.usNumberFormat(number)}
          &nbsp;{text}
          {showContactText && <>{number > 1 ? 'contacts' : 'contact'}&nbsp;</>}
        </>
      )
    );
  };
  return count > 0 ? (
    <>{formatNumberWithText(count, '', false)} Selected</>
  ) : (
    'Select'
  );
}

function ExportButton() {
  return (
    <Button
      variant="outline"
      buttonProps={{
        className: 'export-button',
        text: (
          <>
            <ExportIcon />
            <span>Export</span>
            <DropDownIcon className="icon" />
          </>
        ),
      }}
    />
  );
}

function ListView(props) {
  const { action, selectedListId, leadList, listAction } = props;

  return leadList
    .filter((item) => {
      return item.id !== selectedListId && item.id !== null;
    })
    .map((item) => {
      return (
        <div
          className="leads-list-item"
          key={item.id}
          onClick={() => {
            listAction(item.id, item.name, action);
          }}
        >
          <FolderIcon />
          <span className="lead-name show-ellipsis" title={item.name}>
            {item.name}
          </span>
          <SharedListIcon data={item} />
          <span className="lead-count">{Utils.numberFormat(item.count)}</span>
        </div>
      );
    });
}

function MiddleSection(props) {
  const { listData = { isSharedList: false } } = props;
  const [actions, setActions] = React.useState(null);
  const [showMoveToListPopup, setShowMoveToListPopup] = React.useState(false);

  const outsideClick = () => {
    setShowMoveToListPopup(false);
    setActions(null);
  };

  const ref = useOutsideClick(outsideClick);

  const listAction = (id, name, actionMethod) => {
    if (actionMethod === 'add') {
      myLeadsState.addToAnotherList(id, name);
    } else {
      myLeadsState.moveToAnotherList(id, name);
    }
  };

  const deleteLeadsFromList = () => {
    if (myLeadsState.bulkPurchaseIds.length > 0) {
      const callback = async () => {
        await myLeadsState.deleteFromList(
          myLeadsState.selectedList.id,
          myLeadsState.selectedList.name,
        );
        myLeadsState.clearPreviouslySelectedContacts();
      };

      commonModalState.setShowModal(true, {
        title: 'Remove from List',
        description:
          'Are you sure about removing the selected contacts from this list?',
        primaryButtonText: 'Yes',
        buttonCallback: callback,
        secondaryButtonText: 'Cancel',
        Icon: RemoveFromListIcon,
      });
    }
  };

  const showExportOptions = (exportOption, count) => {
    exportLeadsState.setExportSource(MY_LEADS);
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_EXPORT_CLICK, {
      optionType: exportOption,
      selectedCount: count,
    });

    exportLeadsState.setExportOption(exportOption);
    exportLeadsState.setShowExportLeads(true);
  };

  const selectedListName = myLeadsState.selectedList.name;

  const addToBulk = (key) => {
    setActions(key);
    if (myLeadsState.bulkPurchaseIds.length > 0) {
      setShowMoveToListPopup(true);
    }
  };

  const moveToBulk = (key) => {
    setActions(key);
    if (myLeadsState.bulkPurchaseIds.length > 0) {
      setShowMoveToListPopup(true);
    }
  };

  const selectedRowsInPage = () => {
    myLeadsState.updateSelectedBulkContactAll();
  };

  return (
    <>
      <ExportLeads
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(listData.isSharedList ? { enabledExportTypes: ['CSV'] } : {})}
      />
      <div className="list-page-actions">
        <ControlInput
          onChange={() => {
            if (myLeadsState.leadsResultCount > 0) {
              selectedRowsInPage();
            }
          }}
          type="checkbox"
          label={<CheckboxLabel count={myLeadsState.bulkPurchaseIds.length} />}
          checked={myLeadsState.allContactSelected}
        />
        <Dropdown
          wrapperClassName={`export-dropdown${
            myLeadsState.leadsResultCount === 0 ? ' disabled' : ''
          }`}
          toggleElement={<ExportButton />}
        >
          <div className="dropdown-list">
            <div
              className="dropdown-item"
              onClick={() => {
                showExportOptions('export all', myLeadsState.leadsResultCount);
              }}
              data-close="true"
            >
              <span className="lead-name">Export All</span>
              <span className="lead-count">
                ({Utils.numberFormat(myLeadsState.leadsResultCount)})
              </span>
            </div>
            {myLeadsState.bulkPurchaseIds.length > 0 && (
              <div
                className="dropdown-item"
                onClick={() => {
                  showExportOptions(
                    'export selected',
                    myLeadsState.bulkPurchaseIds.length,
                  );
                }}
                data-close="true"
              >
                <span className="lead-name">Export Selected</span>
                <span className="lead-count">
                  ({Utils.numberFormat(myLeadsState.bulkPurchaseIds.length)})
                </span>
              </div>
            )}
          </div>
        </Dropdown>

        {myLeadsState.bulkPurchaseIds.length > 0 && !listData.isSharedList && (
          <Dropdown
            onCloseDropdown={outsideClick}
            className="more-actions-dropdown"
            toggleElement={
              <Button
                variant="outline"
                buttonProps={{
                  text: 'More Actions',
                  className: 'more-actions-button',
                }}
                Icon={DropDownIcon}
              />
            }
          >
            <div className="dropdown-list">
              {showMoveToListPopup && (
                <div
                  className={`drop-box animated-fast animated-dropdown ${
                    actions === 'move' ? 'move' : ''
                  }`}
                  ref={ref}
                >
                  <div className="leads-list">
                    <ListView
                      action={actions}
                      listAction={listAction}
                      selectedListId={myLeadsState.selectedList.id}
                      leadList={userDetail.myLeadList}
                    />
                  </div>
                </div>
              )}
              {userDetail.myLeadList.length > 0 &&
                (userDetail.myLeadList.length !== 1 ||
                  selectedListName === 'All Leads') && (
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      addToBulk('add');
                    }}
                  >
                    Add contacts to list{' '}
                    <RightArrowIcon className="arrow-icon" />
                  </div>
                )}
              {selectedListName !== 'All Leads' &&
                selectedListName !== 'Plugin History' &&
                userDetail.myLeadList.length !== 1 && (
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      moveToBulk('move');
                    }}
                  >
                    Move selected contacts{' '}
                    <RightArrowIcon className="arrow-icon" />
                  </div>
                )}
              {selectedListName !== 'All Leads' &&
                selectedListName !== 'Plugin History' && (
                  <div
                    className="dropdown-item delete"
                    onClick={deleteLeadsFromList}
                    data-close="true"
                  >
                    Delete Selection
                  </div>
                )}
            </div>
          </Dropdown>
        )}
      </div>
    </>
  );
}

export default observer(MiddleSection);
