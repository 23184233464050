import React from 'react';
import { Outlet, useMatch, useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import * as CONSTANTS from 'Utils/constants';
import DisconnectIcon from 'Assets/svg/disconnect.svg';
import LoadingWrapper from 'Components/common/base/Loader';
import SideBarNavigation from 'Components/common/SideBarNavigation';
import Button from 'Components/common/base/Button';
import PageContentHeader from 'Components/common/PageContentHeader';
import crmIntegrationState from 'Stores/crmIntegration';
import fieldMappingState from 'Stores/fieldMapping';
import integrationDataMap from '../integrationDataMap';
import PageNotFound from '../../pageNotFound';
import './styles.scss';

function ConnectedCrmWrapper() {
  const match = useMatch('/integrations/:crmType/:menu/:mappingType?');
  const { crmType, mappingType = '', menu: selectedMenu } = match?.params ?? {};
  const crmData = integrationDataMap[crmType?.toUpperCase()];
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToIntegrations = () => {
    navigate(CONSTANTS.PAGE_PATH_MAP.INTEGRATIONS);
  };

  const currentCRMData = crmData;

  const isValidRoute = currentCRMData.sublinks?.some((subLink) => {
    return subLink?.subMenu?.length > 0
      ? subLink.subMenu.some((menu) => {
          return menu.link === location.pathname;
        })
      : subLink.linkValue === location.pathname;
  });

  const disconnectClick = async () => {
    fieldMappingState.isLoading = true;
    await crmIntegrationState.disconnectCrm(crmType?.toUpperCase());
    fieldMappingState.isLoading = false;
    if (!crmIntegrationState.crmConnectionStatus.SALESFORCE) {
      redirectToIntegrations();
    }
  };

  return isValidRoute ? (
    <div className="connected-crm-wrapper">
      <PageContentHeader
        titleData={{ text: 'Go Back', href: '/integrations' }}
      />

      <div className="page-content">
        <div className="left-section">
          <SideBarNavigation
            collapseInactiveMenu
            data={currentCRMData}
            activeMenu={selectedMenu}
            selectedSubMenu={mappingType}
            connectionStatus="Connected"
          >
            <Button
              onClick={disconnectClick}
              Icon={DisconnectIcon}
              buttonProps={{
                text: 'Disconnect',
                className: 'disconnect-btn',
              }}
            />
          </SideBarNavigation>
        </div>
        <div className="main-section">
          <LoadingWrapper show={fieldMappingState.isLoading} position="fixed" />
          <Outlet context={{ redirectToIntegrations }} />
        </div>
      </div>
    </div>
  ) : (
    <PageNotFound />
  );
}

export default observer(ConnectedCrmWrapper);
