import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import HeadcountGrowthImg from 'Assets/png/headcount-growth.png';
import ControlInput from 'Components/common/base/ControlInput';
import advSearchFiltersState from 'Stores/advSearchFilters';
import Slider from 'Components/common/base/Slider';
import { applyFilterValueForObjects } from 'Pages/prospectSearch/utils';
import { MX_AS_EVENTS } from 'Utils/constants';
import trackMixpanelEvent from '../trackMixpanelEvent';
import UpgradeSection from '../UpgradeSection';
import './styles.scss';

const deptSelectionList = [
  {
    label: 'Overall Headcount',
    value: 'overall',
  },
  {
    label: 'Specific Departments',
    value: 'specific',
  },
];

function HeadcountGrowth(props) {
  const { currentFilterState, page, selectedFilter } = props;

  const filterStaticValues = useMemo(() => {
    return selectedFilter.getValues();
  }, []);

  const applyValue = (data) => {
    trackMixpanelEvent({
      selectedValue: data.value,
      key: 'headcountGrowth',
      page,
      mxUniqueKey: data.key,
      mxEventName: MX_AS_EVENTS.AS_HEAD_COUNT_GROWTH_SEARCH_PERFORMED,
    });
    applyFilterValueForObjects({
      ...data,
      currentFilterState,
      suggestionKey: 'headcountGrowth',
      mandatoryFields: ['percentage', 'months'],
    });
  };

  const isRestricted = selectedFilter.restricted
    ? selectedFilter.restricted()
    : false;

  const appliedFilter =
    currentFilterState.appliedFilterSectionData?.headcountGrowth;
  const appliedDept = appliedFilter?.dept;

  useEffect(() => {
    if (
      !currentFilterState.appliedFilterSectionData?.headcountGrowth
        ?.percentage &&
      Object.keys(
        currentFilterState.appliedFilterSectionData?.headcountGrowth ?? {},
      )?.length > 0
    ) {
      currentFilterState.removeAppliedFilterFromGroup({
        category: 'headcountGrowth',
      });
    }
  }, [currentFilterState.appliedFilterSectionData]);

  return (
    <div className={`head-count-growth${isRestricted ? ' disable' : ''}`}>
      {isRestricted && (
        <UpgradeSection
          // message={selectedFilter.description}
          title="Our current plan doesn't allow you to filter by company headcount growth"
          imageURL={HeadcountGrowthImg}
        />
      )}
      <div className="growth-slider-wrapper">
        <p className="slider-title">Growth % in the last 3 months</p>
        <div className="growth-slider-wrap">
          <Slider
            type={1}
            list={filterStaticValues.percentage}
            value={
              currentFilterState.appliedFilterSectionData?.headcountGrowth
                ?.percentage ?? filterStaticValues.percentage?.[0]?.value
            }
            onChange={(value) => {
              if (value === 'any') {
                currentFilterState.removeAppliedFilterFromGroup({
                  category: 'headcountGrowth',
                });
              } else {
                if (
                  !currentFilterState.appliedFilterSectionData?.headcountGrowth
                    ?.months
                ) {
                  applyFilterValueForObjects({
                    key: 'months',
                    value: '3',
                    multiple: false,
                    currentFilterState,
                    suggestionKey: 'headcountGrowth',
                    mandatoryFields: ['percentage', 'months'],
                  });
                }
                applyValue({ key: 'percentage', value, multiple: false });
              }
            }}
          />
        </div>
      </div>
      {/* <div className="time-period-wrapper">
        <p className="radio-title">
          Select the time period of growth in headcount
        </p>
        <div className="radio-wrapper">
          {filterStaticValues.months.map((timeData) => {
            const { label, value } = timeData;
            return (
              <ControlInput
                type="radio"
                label={label}
                value={value}
                key={`time-period-${value}`}
                checked={
                  currentFilterState.appliedFilterSectionData?.headcountGrowth
                    ?.months === value
                }
                onChange={(appliedValue) => {
                  applyValue({
                    key: 'months',
                    value: appliedValue,
                    multiple: false,
                  });
                }}
              />
            );
          })}
        </div>
      </div> */}
      <div
        className={`dept-radio-wrapper${appliedFilter?.percentage ? '' : ' disable'}`}
      >
        <p className="radio-title">Department</p>
        <div className="radio-wrapper">
          {deptSelectionList.map((deptData) => {
            const { label, value } = deptData;
            return (
              <ControlInput
                type="radio"
                label={label}
                value={value}
                key={`dept-${value}`}
                checked={value === 'overall' ? !appliedDept : !!appliedDept}
                onChange={(newValue) => {
                  if (newValue === 'overall') {
                    currentFilterState.removeAppliedFilterFromGroup({
                      category: 'headcountGrowth',
                      objectKey: 'dept',
                    });
                  }
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="dept-selection">
        {advSearchFiltersState.filterListValues.department?.map((dept) => {
          const { name } = dept;
          return (
            <ControlInput
              key={`headcount-${name}`}
              type="checkbox"
              label={name}
              checked={appliedDept?.includes(name) ?? false}
              onChange={() => {
                applyValue({ key: 'dept', value: name });
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default observer(HeadcountGrowth);
