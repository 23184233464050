import React from 'react';
import IndustryIcon from 'Assets/svg/new_icons/industry.svg';
import RevenueIcon from 'Assets/svg/new_icons/revenue.svg';
import AccountIcon from 'Assets/svg/new_icons/contacts.svg';
import PhoneIcon from 'Assets/svg/new_icons/phone2.svg';
import './styles.scss';
import { PhoneItem } from '../ContactPhone';

function ContactIndustry(props) {
  const {
    industry,
    employeeCount,
    revenue,
    companyPhone,
    showNA = true,
  } = props;

  return (
    <div className="industry-wrapper">
      {industry && (
        <div className="info-wrap">
          <IndustryIcon title="Industry" />
          <span className="show-ellipsis" title={industry}>
            {industry}
          </span>
        </div>
      )}
      {(employeeCount || revenue) && (
        <div className="industry-content-info">
          {employeeCount && (
            <span className="info-wrap">
              <AccountIcon title="Employee Count" />
              <span>{employeeCount}</span>
            </span>
          )}
          {revenue && (
            <span className="info-wrap">
              <RevenueIcon title="Revenue" />
              <span>{revenue}</span>
            </span>
          )}
        </div>
      )}
      {companyPhone && (
        <div className="company-number contact-phone">
          <PhoneItem
            phoneNumber={companyPhone}
            pageName="advsearch"
            Icon={PhoneIcon}
          />
        </div>
      )}
      {showNA && !employeeCount && !revenue && !industry && !companyPhone && (
        <span className="not-available-text">- NA -</span>
      )}
    </div>
  );
}

export default ContactIndustry;
