import React from 'react';
import IndustryIcon from 'Assets/svg/new_icons/industry.svg';

function CompanyIndustry(props) {
  const { industry = '', parentClass = '', clsName = '' } = props;

  return industry !== undefined && industry !== '' && industry !== null ? (
    <div className={`company-industry icon-wrap ${parentClass}`}>
      <IndustryIcon className="svg-icon" />
      <div className={`item-name show-ellipsis  ${clsName}`} title={industry}>
        {industry}
      </div>
    </div>
  ) : (
    ''
  );
}

export { CompanyIndustry };
export default CompanyIndustry;
