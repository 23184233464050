import React from 'react';
import { observer } from 'mobx-react';
import EmptyCompanyLogo from 'Assets/svg/empty-company-logo.svg';
import advSearchState from 'Stores/advSearch';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import { LazyImage } from '../../lazyImage';
import { CompanyName } from '../../CompanyList/companyName';
import { CompanyIndustry } from '../../CompanyList/companyIndustry';
import { CompanyLocation } from '../../CompanyList/companyLocation';
import SocialLinks from '../../SocialLinks';
import { CompanyRevenue } from '../../CompanyList/companyRevenue';
import { CompanyEmployeeCount } from '../../CompanyList/companyEmployeeCount';
import { CompanyWebsite } from '../../CompanyList/companyWebsite';
import Dropdown from '../../base/Dropdown';
import ContactLocation from '../ContactLocation';
import './styles.scss';

// be here for now, will update with design change
const CompanyCard = observer((props) => {
  const { companyCardData, showCompanyCardLoading } = props;
  const {
    name,
    logo,
    industry,
    revenue,
    employeeCount,
    city,
    state,
    country,
    primaryDomain,
  } = companyCardData;

  function CompanyCardInfoDataLoadingPlaceHolder() {
    return (
      <div className="contact-card company-card loading-placeholder">
        <span className="sharp-point" />
        <div className="contact-card-details">
          <div className="basic-info-wrapper">
            <div className="company-logo-wrapper linear-background" />
            <div className="company-name-location-wrap">
              <CompanyName parentClass="linear-background" />
              <div className="company-phone-location">
                <CompanyLocation city="loading" clsName="linear-background" />
              </div>
            </div>
          </div>
          <div className="additional-info-wrapper">
            <CompanyIndustry industry="loading" clsName="linear-background" />
            <CompanyRevenue revenue="loading" clsName="linear-background" />
            <CompanyEmployeeCount
              employeeCount="loading"
              clsName="linear-background"
            />
            <CompanyWebsite
              primaryDomain="loading"
              clsName="linear-background"
            />
          </div>
        </div>
      </div>
    );
  }

  function CompanyCardInfoData() {
    return (
      <div className="contact-card company-card">
        <span className="sharp-point" />
        <div className="contact-card-details">
          <div className="basic-info-wrapper">
            <LazyImage
              alt={name}
              src={logo}
              clsName="logo"
              threshold={200}
              parentClass="company-logo-wrapper"
            />
            <div className="company-name-location-wrap">
              <CompanyName name={name} />
              <div className="company-phone-location">
                <CompanyLocation city={city} state={state} country={country} />
              </div>
            </div>
          </div>
          <div className="additional-info-wrapper">
            <CompanyIndustry industry={industry} />
            <CompanyRevenue revenue={revenue} />
            <CompanyEmployeeCount employeeCount={employeeCount} />
            <CompanyWebsite primaryDomain={primaryDomain} />
          </div>
          <SocialLinks {...companyCardData} />
        </div>
      </div>
    );
  }

  return showCompanyCardLoading ? (
    <CompanyCardInfoDataLoadingPlaceHolder />
  ) : (
    <CompanyCardInfoData />
  );
});

function Name(props) {
  const { name, company, primaryDomain } = props;
  const companyNameOrDomain = name || primaryDomain;
  return (
    <div className="name-wrapper">
      <div className="company-name show-ellipsis" title={companyNameOrDomain}>
        {companyNameOrDomain}
      </div>
      <SocialLinks
        facebookId={company.facebookURL}
        linkedInId={company.linkedinURL}
        // youtubeId={company.youtubeURL}
        twitterId={company.twitterURL}
        website={company.website}
      />
    </div>
  );
}

function CompanyLogo(props) {
  const { companyLogo, cname } = props;

  return companyLogo ? (
    <div className="logo-section">
      <div className="logo-wrapper">
        <img src={companyLogo} alt={`${cname} logo`} loading="lazy" />
      </div>
    </div>
  ) : (
    <div className="logo-wrapper">
      <EmptyCompanyLogo className="empty-company-logo" />
    </div>
  );
}

function ContactCompany(props) {
  const {
    cname,
    companyCountry,
    companyState,
    companyCity,
    companyId,
    companyLogo,
    showLogo = true,
    company = {},
    primaryDomain,
    children,
  } = props;

  const [showCompanyCardLoading, setShowCompanyCardLoading] =
    React.useState(false);
  const [companyCardData, setCompanyCardData] = React.useState({});

  const onMouserEnterCompanyCard = (comId = null) => {
    window.timeoutVar = setTimeout(() => {
      setShowCompanyCardLoading(true);
      const companyData = advSearchState.getCompanyCardData(comId);

      Utils.mixpanelTrack(
        CONSTANTS.MX_AS_EVENTS.LB_RESULT_HOVER_COMPANY_NAME,
        {},
      );
      if (companyData !== null) {
        setCompanyCardData(companyData);
        setShowCompanyCardLoading(false);
      } else {
        const companyInfoCallback = (cData) => {
          if (cData !== null) {
            setCompanyCardData(cData);
            setShowCompanyCardLoading(false);
            advSearchState.setCompanyCardData(comId, cData);
          }
        };
        advSearchState.getCompanyInfoById(comId, companyInfoCallback);
      }
    }, 500);
  };

  const onMouserLeaveCompanyCard = () => {
    if (window.timeoutVar !== undefined) {
      clearTimeout(window.timeoutVar);
    }
    setCompanyCardData({});
    setShowCompanyCardLoading(false);
  };

  return cname || primaryDomain ? (
    <div className="company-name-wrapper">
      {showLogo &&
        (companyId ? (
          <div
            className="company-name-container"
            onMouseEnter={() => {
              onMouserEnterCompanyCard(companyId);
            }}
            onMouseLeave={() => {
              onMouserLeaveCompanyCard();
            }}
          >
            <Dropdown
              toggleElement={
                <CompanyLogo companyLogo={companyLogo} cname={cname} />
              }
              dropdownHeight={250}
              showOnHover
            >
              {(Object.keys(companyCardData).length > 0 ||
                showCompanyCardLoading) && (
                <CompanyCard
                  companyCardData={companyCardData}
                  showCompanyCardLoading={showCompanyCardLoading}
                />
              )}
            </Dropdown>
          </div>
        ) : (
          <CompanyLogo companyLogo={companyLogo} cname={cname} />
        ))}
      <div className="text-section">
        <Name name={cname} company={company} primaryDomain={primaryDomain} />
        <ContactLocation
          city={companyCity}
          state={companyState}
          country={companyCountry}
        />
        {children}
      </div>
    </div>
  ) : (
    <div>Unavailable</div>
  );
}

export default observer(ContactCompany);
