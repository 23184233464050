import { observable, action } from 'mobx';

class OwnershipMappingState {
  @observable accessor showPopup = false;

  @observable accessor companyExport = false;

  @observable accessor exportCallback;

  @observable accessor cancelCallback;

  @observable accessor exportType = 'Salesforce';

  @observable accessor exportSource = '';

  // used only when enrichment flag enabled and as of now hubspot only
  @observable accessor postData = {};

  @action
  setCompanyExport = (val) => {
    this.companyExport = val;
  };

  @action
  setExportCallback = (val) => {
    this.exportCallback = val;
  };

  @action
  setCancelCallback = (val) => {
    this.cancelCallback = val;
  };

  @action
  setShowPopup = (val) => {
    this.showPopup = val;
  };

  @action
  setExportType = (val) => {
    this.exportType = val;
  };

  @action
  setExportSource = (val) => {
    this.exportSource = val;
  };

  @action
  setPostData = (data) => {
    this.postData = data;
  };
}
const ownershipMappingState = new OwnershipMappingState();

export default ownershipMappingState;
