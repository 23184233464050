import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PlusIcon from 'Assets/svg/plus-circle.svg';
import MinusIcon from 'Assets/svg/minus-circle.svg';
import EmailIcon from 'Assets/svg/contact-email.svg';
import ChatIcon from 'Assets/svg/chat.svg';
import ThunderIcon from 'Assets/svg/thunder.svg';
import Modal from 'Components/common/base/Modal';
import Accordion from 'Components/common/base/Accordion';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import LoadingWrapper from 'Components/common/base/Loader';
import Title from 'Components/common/base/Title';
import userDetail from 'Stores/userDetail';
import pricingDataState from 'Stores/pricingData';
import {
  EXTERNAL_LINKS,
  HUBSPOT_EVENTS,
  MX_PRICING_PAGE,
  MX_PROFILE_PROPERTIES,
  PRICING_FREQ_ASK_QUESTIONS,
} from 'Utils/constants';
import Utils from 'Utils/utils';
import PricingCard from './pricingCard';
import StripePayment from './StripePayment';
import PricingComparison from './pricingComparison';
import CaseStudyList from './caseStudyList';
import ImageGrid from './imageGrid';
import './styles.scss';

const listItems = [
  { title: '7-day free trial' },
  {
    title: 'No credit card required',
  },
  { title: 'Customized plans' },
];

const EXISTING_PLAN_TYPES = {
  ENTERPRISE_TRIAL: 'ENTERPRISE_TRIAL',
  PAID: 'PAID',
  TRIAL: 'TRIAL',
  FREEMIUM: 'FREE',
};

const isFreePlan = (planName = '') => {
  return planName === 'FREE';
};

const ExistingPlanCard = observer(() => {
  const { userInfo = {} } = userDetail;

  const { currentSubscriptionPlan, memberSubscription, frequencyType } = userInfo;
  const { displayName } = currentSubscriptionPlan;

  const { lbCredits, renewalDate, creditResetPeriod, price } =
    memberSubscription;

  const renewalDateObj = new Date(renewalDate);
  return (
    <div className="existing-plan-section">
      <p className="card-title">Plan and billing</p>

      <div className="plan-detail-card">
        <div className="plan-info">
          <div className="plan-icon-wrapper">
            <ThunderIcon />
          </div>
          <div className="plan-text-wrapper">
            <div className="plan-heading">Current Plan</div>
            <span>{`${displayName} - ${price}${frequencyType === 0 ? ' / month' : ' / year'}`}</span>
          </div>
        </div>
        <div className="credit-info-detail">
          <div className="credits-detail">
            {Utils.viewsDownloadsNumberFormat(lbCredits)} credits{' '}
            {Utils.getCreditResetPeriodInString(frequencyType, creditResetPeriod)}
          </div>
          <p className="access-detail">
            Chrome Extension & List Builder Access
          </p>
          <div className="renewal-date">
            Next Renewal Date :{' '}
            <span>
              {`${renewalDateObj.toLocaleString('default', {
                month: 'long',
              })} ${renewalDateObj.getDate()}, ${renewalDateObj.getFullYear()}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

function GetInTouchSection(props) {
  const { existingPlanType } = props;
  return (
    <div className="get-in-touch-section">
      <p className="card-title">Get in touch</p>
      <div className="chat-card">
        <p className="chat-title">Looking to upgrade your plan?</p>
        <p className="chat-sub-title">
          or have any questions related to pricing?
        </p>
        <a
          className="email-box"
          href={
            existingPlanType === EXISTING_PLAN_TYPES.PAID
              ? 'mailto:success@adapt.io'
              : 'mailto:sales@adapt.io'
          }
        >
          <div className="icon-wrapper">
            <EmailIcon />
          </div>
          <span>{Utils.getSupportEmail()}</span>
        </a>
        <div className="chat-box">
          <a href="#pricingEnterpriseContact">
            <div className="icon-wrapper">
              <ChatIcon />
            </div>
            <span>Chat Now</span>
          </a>
        </div>
      </div>
    </div>
  );
}

function renderIcon(isOpen) {
  return isOpen ? <MinusIcon /> : <PlusIcon />;
}

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);
  const [existingPlanType, setExistingPlanType] = useState('');
  const [enterpriseInterestApiLoading, setEnterpriseInterestApiLoading] =
    useState(false);

  const { showPaymentForm } = pricingDataState;

  const isAnnualSelected = activeTab === 1;
  const { userInfo } = userDetail;

  const openPaymentForm = (selectedPlanInfo) => {
    const { name, annualPrice, price, version } = selectedPlanInfo;

    const mxData = {
      selectedPlanType: name,
      isAnnual: isAnnualSelected,
      planAmount: isAnnualSelected ? annualPrice : price,
      version,
    };

    const {
      userStatus,
      email,
      memberSubscription,
      isEnterpriseAdmin,
      teamAdminEmail,
    } = userInfo;

    if (!isFreePlan(name)) {
      if (userStatus === 0) {
        Utils.hubspotEventPush(HUBSPOT_EVENTS.SELF_SERVE_BUY_NOW_CLICK, 'true');
        if (memberSubscription !== null && !isEnterpriseAdmin) {
          commonModalState.setShowModal(true, {
            modalProps: { align: 'center' },
            description: (
              <>
                Please contact your account administrator{' '}
                <a href={`mailto:${teamAdminEmail}`}>${teamAdminEmail}</a> to
                upgrade your subscription.
              </>
            ),
            primaryButtonText: 'Ok',
          });
        } else {
          Utils.mixpanelTrack(
            MX_PRICING_PAGE.CLICK_LB_PRICING_PLAN_SELECTED,
            mxData,
          );
          pricingDataState.setSelectedPlan(selectedPlanInfo);
          pricingDataState.setShowPaymentForm(true);
        }
      } else {
        Utils.showEmailConfirmationPopup(email);
      }
    }
  };

  const closePaymentForm = () => {
    pricingDataState.setShowPaymentForm(false);
  };

  const upgradeCallback = (isEnterprisePlan, planDetails) => {
    if (isEnterprisePlan) {
      Utils.mixpanelTrack(MX_PRICING_PAGE.CLICK_LB_PRICING_TALK_TO_US, {});
      Utils.mixpanelUpdateProfileProperty({
        [MX_PROFILE_PROPERTIES.ENTERPRISE_PLAN_REQUEST]: true,
      });
      window.open(EXTERNAL_LINKS.CUSTOM_PRICING_MEET, '_blank');
    } else {
      openPaymentForm(planDetails);
    }
  };

  useEffect(() => {
    Utils.mixpanelTrack(MX_PRICING_PAGE.VIEW_LB_PRICING_PAGE, {});
    Utils.hubspotEventPush(HUBSPOT_EVENTS.SELF_SERVE_PAGE_VIEWED, 'true');
    if (Utils.isFreeTrialPlan()) {
      Utils.mixpanelUpdateProfileProperty({
        [MX_PROFILE_PROPERTIES.TAM_PRICING_VIEWED]: true,
      });
    }
  }, []);

  useEffect(() => {
    if (Utils.isEnterpriseFreeTrialPlan()) {
      setExistingPlanType(EXISTING_PLAN_TYPES.ENTERPRISE_TRIAL);
    } else if (Utils.isFreeTrialPlan()) {
      setExistingPlanType(EXISTING_PLAN_TYPES.TRIAL);
    } else if (
      userInfo.currentSubscriptionPlan !== null &&
      userInfo.currentSubscriptionPlan !== undefined
    ) {
      const { name = '' } = userInfo.currentSubscriptionPlan;
      if (name && isFreePlan(name)) {
        setExistingPlanType(EXISTING_PLAN_TYPES.FREEMIUM);
      } else if (userInfo.currentSubscriptionPlan !== null) {
        setExistingPlanType(EXISTING_PLAN_TYPES.PAID);
      } else {
        setExistingPlanType('');
      }
    } else {
      setExistingPlanType('');
    }
  }, [userInfo.currentSubscriptionPlan]);

  const showPlans =
    (existingPlanType === '' ||
      existingPlanType === EXISTING_PLAN_TYPES.TRIAL) &&
    !Utils.isPaymentPendingReview();

  return (
    <div className={`pricing-page${showPlans ? ' show-plans' : ''}`}>
      <LoadingWrapper show={enterpriseInterestApiLoading} position="fixed" />
      <Title title="Pricing" />
      {existingPlanType === EXISTING_PLAN_TYPES.PAID &&
        !Utils.isPaymentPendingReview() && (
          <div className="cards-wrapper">
            <ExistingPlanCard />
            <GetInTouchSection existingPlanType={existingPlanType} />
          </div>
        )}

      {showPlans && (
        <>
          <div className="top-wrapper">
            <h1>Adapt.io plans and pricing</h1>
            <div className="list">
              {listItems.map((listItem) => {
                const { title } = listItem;
                return (
                  <div className="item" key={title}>
                    <div className="icon-wrap" />
                    <div className="text-wrap">
                      <p className="item-title">{title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="plan-period-tabs">
            <div
              className={`plan-period-tab ${activeTab === 0 ? 'active' : ''}`}
              onClick={() => {
                pricingDataState.setAnnualSelected(false);
                setActiveTab(0);
              }}
            >
              Monthly
            </div>
            <div
              className={`plan-period-tab ${activeTab === 1 ? 'active' : ''}`}
              onClick={() => {
                pricingDataState.setAnnualSelected(true);
                setActiveTab(1);
              }}
            >
              Yearly
            </div>
            <span className="save-tag">Save 20%</span>
          </div>
          <PricingCard
            activeTab={activeTab}
            pricingPlans={userDetail.subscriptionPlans}
            upgradeCallback={upgradeCallback}
            existingPlanType={existingPlanType}
          />
          <PricingComparison
            pricingPlans={userDetail.subscriptionPlans}
            upgradeCallback={upgradeCallback}
          />
        </>
      )}
      <CaseStudyList />
      <ImageGrid />

      <div className="freq-ask-ques-section">
        <h4 className="title">Frequently Asked Questions</h4>
        <div className="freq-ask-ques-row">
          <div className="content-col">
            {PRICING_FREQ_ASK_QUESTIONS.map((item, index) => {
              return (
                <Accordion
                  key={`freq-ask-question${index + 1}`}
                  title={item.question}
                  renderIcon={renderIcon}
                >
                  <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      {showPaymentForm && (
        <Modal
          show
          className="payment-form-section"
          closeModal={closePaymentForm}
        >
          <StripePayment showPaymentForm={showPaymentForm} />
        </Modal>
      )}
    </div>
  );
}

export default observer(Pricing);
