import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import BookmarkSuccessIcon from 'Assets/svg/new_icons/bookmark-success.svg';
import LockIcon from 'Assets/svg/lock-icon-new.svg';
import Input from 'Components/common/base/Input';
import Select from 'Components/common/base/Select';
import Modal, { ModalState } from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import ToggleType from 'Components/common/base/Toggle';
import ControlInput from 'Components/common/base/ControlInput';
import SetEmailNotification from 'Components/common/setEmailNotification';
import advSearchState from 'Stores/advSearch';
import savedSearchState from 'Stores/savedSearch';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import {
  EMPTY_FUNCTION,
  time as timeList,
  MX_SAVED_SEARCH_EVENTS,
} from 'Utils/constants';
import Utils from 'Utils/utils';
import './styles.scss';

const defaultOptions = { enableEmailSchedule: true, type: 'contact' };
class SavedSearchPopupState extends ModalState {
  @observable accessor data = defaultOptions;

  options = defaultOptions;

  @action
  setData = (data, options = {}) => {
    this.data = data;
    this.options = { ...defaultOptions, ...options };
  };
}

const savedSearchPopupState = new SavedSearchPopupState();

function returnCapitalizedWord(word = '') {
  if (word !== null) {
    return word.charAt(0) + word.slice(1).toLowerCase();
  }
  return '';
}

function getTime(time) {
  return (
    timeList.find((data) => {
      return data.value === time;
    }).label || time
  );
}

function setValue(data) {
  const { frequency, ordinalDay, day, time, timeZone } = data;

  return {
    selectedTime: frequency ? returnCapitalizedWord(frequency) : 'Weekly',
    ordinalDay: ordinalDay ? returnCapitalizedWord(ordinalDay) : 'First',
    day: day ? returnCapitalizedWord(day) : 'Monday',
    time: time || '11:00 AM',
    timeZone: timeZone || 'GMT',
  };
}

function SavedSearchPopUp(props) {
  const {
    isEdit = false,
    onSubmit = EMPTY_FUNCTION,
    isAPILoading = false,
  } = props;

  const navigate = useNavigate();

  const [selectedSearchItem, setSelectedSearchItem] = useState('');
  const [selectedSearch, setSelectedSearch] = useState('new');
  const [errorMessage, setErrorMessage] = useState({ new: '', replace: '' });

  const [enable, setEnable] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [emailNotification, setEmailNotification] = useState({
    selectedTime: '',
    ordinalDay: '',
    day: '',
    time: '',
    timeZone: '',
  });

  const savedSearchList =
    savedSearchPopupState.options.type === 'company'
      ? savedSearchState.companySavedSearches
      : savedSearchState.contactSavedSearches;

  const isEmailNotificationEnabled =
    featureFlagsAndPreferencesState.isJobAlertsEnabled();

  const handleOnChange = (value) => {
    setSelectedSearch(value);
    setInputValue('');
    setSelectedSearchItem('');
    setErrorMessage({ new: '', replace: '' });
  };

  const handleOnChangeEmail = (type, value) => {
    setEmailNotification((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
  };

  const handleOnChangeInputField = (value = '') => {
    setInputValue(value);
    setErrorMessage({ new: '', replace: '' });
  };

  const onClickSave = () => {
    let isValid = true;
    if (isEdit) {
      if (inputValue.length === 0) {
        setErrorMessage({
          ...errorMessage,
          new: 'Please enter new search name',
        });
        isValid = false;
      }
    } else if (selectedSearch === 'new') {
      if (inputValue.length === 0) {
        setErrorMessage({ ...errorMessage, new: 'Please enter search name' });
        isValid = false;
      }
    } else if (selectedSearchItem === '') {
      setErrorMessage({
        ...errorMessage,
        replace: 'Please select a saved search',
      });
      isValid = false;
    }

    if (isValid) {
      const newFrequency = emailNotification.selectedTime.toUpperCase();
      onSubmit({
        templateName: inputValue,
        ...(newFrequency === 'MONTHLY' &&
          enable && {
            ordinalDay: emailNotification.ordinalDay.toUpperCase(),
          }),
        ...(enable && {
          status: 'ACTIVE',
          frequency: newFrequency,
          day: emailNotification.day.toUpperCase(),
          time: emailNotification.time,
          timeZone: emailNotification.timeZone,
        }),
        ...(!isEdit &&
          selectedSearch === 'replace' && {
            id: selectedSearchItem,
            templateName: savedSearchList?.find((savedSearch) => {
              return savedSearch.id === selectedSearchItem;
            })?.templateName,
          }),
      });
    }
  };

  const closeModal = () => {
    savedSearchPopupState.setShowModal(false);
  };

  useEffect(() => {
    const { templateName = '', status } = savedSearchPopupState.data;
    setEnable(status === 'ACTIVE');
    setInputValue(templateName);
    setEmailNotification(setValue(savedSearchPopupState.data));
  }, [savedSearchPopupState.data]);

  useEffect(() => {
    Utils.mixpanelTrack(MX_SAVED_SEARCH_EVENTS.SAVED_SEARCH_POPUP_SHOWN, {
      resultsCount: advSearchState.searchResultCount,
      type: isEdit ? 'edit' : 'add',
    });
  }, []);

  const emailScheduleEnabled =
    savedSearchPopupState.options.enableEmailSchedule;

  return (
    <Modal
      closeModal={closeModal}
      show={savedSearchPopupState.showModal}
      className={`saved-search-popup${emailScheduleEnabled ? '' : ' small'}`}
    >
      <Modal.Icon SvgIcon={BookmarkSuccessIcon} />
      <Modal.Title>{isEdit ? 'Edit Saved Search' : 'Save Search'}</Modal.Title>
      <Modal.Content>
        <div className={`main-container${isEdit ? ' edit' : ''}`}>
          {isEdit ? (
            <Input
              error={errorMessage.new}
              label="Search Name"
              className="input-wrapper-edit"
              onChange={handleOnChangeInputField}
              inputProps={{
                value: inputValue,
                placeholder: 'Name your saved search',
              }}
            />
          ) : (
            <>
              <div className="input-field">
                <ControlInput
                  type="radio"
                  label="New Search"
                  value="new"
                  name="newSearch"
                  id="newSearch"
                  onChange={handleOnChange}
                  checked={selectedSearch === 'new'}
                />
                <Input
                  onChange={handleOnChangeInputField}
                  error={errorMessage.new}
                  inputProps={{
                    disabled: selectedSearch !== 'new',
                    placeholder: 'Name your saved search',
                    value: inputValue,
                  }}
                />
              </div>
              <div className="saved-list">
                <ControlInput
                  type="radio"
                  disabled={savedSearchList.length === 0}
                  label="Replace Existing Search"
                  value="replace"
                  name="replaceExistingSearch"
                  onChange={handleOnChange}
                  checked={selectedSearch === 'replace'}
                />
                <Select
                  enableSearch={savedSearchList?.length > 10}
                  showPlaceholderInOptions={false}
                  searchInputProps={{
                    placeholder: 'Search from saved lists name',
                  }}
                  selectWrapperProps={{
                    className: `${
                      selectedSearch !== 'replace'
                        ? 'saved-search-disabled'
                        : ''
                    } save-search-dropdown ${
                      errorMessage.replace ? 'saved-search-email-error' : ''
                    }`,
                  }}
                  options={
                    savedSearchList?.length > 0
                      ? savedSearchList?.map((prop) => {
                          return {
                            label: prop.templateName,
                            value: prop.id,
                          };
                        })
                      : []
                  }
                  value={selectedSearchItem}
                  placeholder="Select from saved list"
                  onChange={(prop) => {
                    setErrorMessage((prevState) => {
                      return {
                        ...prevState,
                        replace: '',
                      };
                    });
                    const newValue = prop;
                    setSelectedSearchItem(newValue);
                    if (isEmailNotificationEnabled) {
                      const savedSearchData = savedSearchList?.find(
                        (savedSearch) => {
                          return savedSearch.id === newValue;
                        },
                      );

                      const isEmailScheduleAvailable =
                        savedSearchData.status === 'ACTIVE';
                      setEnable(isEmailScheduleAvailable);
                      if (isEmailScheduleAvailable) {
                        setEmailNotification({
                          selectedTime: returnCapitalizedWord(
                            savedSearchData.frequency,
                          ),
                          ordinalDay:
                            returnCapitalizedWord(savedSearchData.ordinalDay) ||
                            'First',
                          day: returnCapitalizedWord(savedSearchData.day),
                          time: savedSearchData.time,
                          timeZone: savedSearchData.timeZone,
                        });
                      }
                    }
                  }}
                />
                <span className="error">{errorMessage.replace}</span>
                {savedSearchList.length === 0 && (
                  <div className="no-saved-search">
                    <sup>*</sup>No saved searches available to replace
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {emailScheduleEnabled && (
          <div className="email-notifications">
            <div className="title-wrapper">
              <p className="email-title">Email Notifications</p>
              {!isEmailNotificationEnabled && (
                <div className="advanced-feature-tag">
                  <LockIcon />
                  <span>Advanced Feature</span>
                </div>
              )}
            </div>
            <div className="mail-toogle-container">
              <div className="mail-toggle">
                <p className="mail-notification-text">
                  Turn on email notification to be alerted when we add new
                  contacts that
                  <br />
                  match your search criteria
                </p>
                <div className="save-search-toggle">
                  <ToggleType
                    toggle={enable}
                    cbk={() => {
                      const newValue = !enable;
                      setEnable(newValue);
                      Utils.mixpanelTrack(
                        MX_SAVED_SEARCH_EVENTS.EMAIL_NOTIFICATION_TOGGLED,
                        {
                          resultsCount: advSearchState.searchResultCount,
                          enabled: newValue,
                          type: isEdit ? 'edit' : 'add',
                        },
                      );
                      setEmailNotification({
                        selectedTime: 'Weekly',
                        ordinalDay: 'First',
                        day: 'Monday',
                        time: '11:00 AM',
                        timeZone: 'GMT',
                      });
                    }}
                  />
                </div>
              </div>
              {enable && (
                <SetEmailNotification
                  customTitle="Email Frequency"
                  customClassName="save-search-time-container"
                  handleChangeRadio={handleOnChangeEmail}
                  selectedEmailValues={emailNotification}
                />
              )}
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        {enable &&
          isEmailNotificationEnabled &&
          (emailNotification.selectedTime === 'Weekly' ? (
            <p className="footer-text">
              {`This email is scheduled to send every week on ${
                emailNotification.day
              } at ${getTime(emailNotification.time)} ${
                emailNotification.timeZone
              }.`}
            </p>
          ) : (
            <p className="footer-text">
              {`This email is scheduled to send every month on ${
                emailNotification.ordinalDay
              } ${emailNotification.day} at ${getTime(
                emailNotification.time,
              )} ${emailNotification.timeZone}.`}
            </p>
          ))}
        {!isEmailNotificationEnabled && enable ? (
          <>
            <Button
              buttonProps={{ text: 'No, Thanks!' }}
              onClick={() => {
                setEnable(false);
              }}
              variant="outline"
            />
            <Button
              buttonProps={{ text: 'Upgrade Now' }}
              onClick={() => {
                Utils.mixpanelTrack(MX_SAVED_SEARCH_EVENTS.UPGRADE_CLICKED, {
                  resultsCount: advSearchState.searchResultCount,
                  type: isEdit ? 'edit' : 'add',
                });
                navigate('/pricing');
              }}
            />
          </>
        ) : (
          <>
            <Button
              variant="outline"
              buttonProps={{ text: 'Cancel' }}
              onClick={closeModal}
            />
            <Button
              buttonProps={{ text: 'Save Search' }}
              onClick={onClickSave}
              isLoading={isAPILoading}
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export { savedSearchPopupState };

export default observer(SavedSearchPopUp);
