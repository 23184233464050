import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import userDetail from 'Stores/userDetail';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import Utils from 'Utils/utils';
import './styles.scss';

const defaultData = {
  limit: '',
  firstName: '',
  lastName: '',
  email: '',
  credits: -1,
};

function UserDataPopupContent(props) {
  const {
    userData,
    setUserData,
    editPersonalFields,
    onSubmit,
    popupBoundary,
    setErrors,
    errors,
    lbCreditsIndividualLimitUsedMonthly,
    lbCreditsIndividualLimitUsedYearly,
  } = props;
  const { lbCredits, memberSubscription } = userDetail.userInfo;

  const newCreditsValue = userData.credits;

  return (
    <form id="user-form" onSubmit={onSubmit}>
      <Input
        label="First Name"
        className="first-name"
        inputProps={{
          disabled: !editPersonalFields,
          placeholder: 'Enter First Name',
          value: userData.firstName,
        }}
        onChange={(value) => {
          setUserData('firstName', value);
          setErrors({ ...errors, firstName: !value });
        }}
        error={errors.firstName ? 'Please enter first name' : ''}
      />
      <Input
        label="Last Name"
        className="last-name"
        inputProps={{
          disabled: !editPersonalFields,
          placeholder: 'Enter Last Name',
          value: userData.lastName,
        }}
        onChange={(value) => {
          setUserData('lastName', value);
          setErrors({ ...errors, lastName: !value });
        }}
        error={errors.lastName ? 'Please enter last name' : ''}
      />
      <Input
        label="Email ID"
        className="email"
        inputProps={{
          disabled: !editPersonalFields,
          placeholder: 'Enter Email ID',
          value: userData.email,
        }}
        onChange={(value) => {
          setUserData('email', value);
          setErrors({ ...errors, email: !value });
        }}
        error={errors.lastName ? 'Please enter email' : ''}
      />
      <div className="credit-limit-wrap">
        <div className="credit-limit-left-section">
          <div className="credit-limit-title">
            Credit Usage Limit
            <ToolTipWithIcon
              offset={popupBoundary}
              className="credit-limit-tooltip"
            >
              Choose how you want to allocate credits for you team members.
            </ToolTipWithIcon>
          </div>
          <div className="radio-wrap">
            <ControlInput
              type="radio"
              checked={userData.limit === ''}
              onChange={() => {
                setUserData('limit', '');
              }}
              label="No Limits"
              value="No Limits"
            />
            <ControlInput
              type="radio"
              checked={userData.limit === 'MONTHLY'}
              onChange={() => {
                setUserData('limit', 'MONTHLY');
              }}
              label="Monthly"
              value="MONTHLY"
            />
            <ControlInput
              type="radio"
              checked={userData.limit === 'YEARLY'}
              onChange={() => {
                setUserData('limit', 'YEARLY');
              }}
              disabled={memberSubscription?.frequencyType !== 1}
              label="Yearly"
              value="YEARLY"
            />
          </div>
        </div>
        {userData.limit !== '' && (
          <Input
            label={
              userData.limit === 'YEARLY'
                ? 'Maximum credits per year'
                : 'Maximum credits per month'
            }
            className="max-credits"
            inputProps={{
              min: 0,
              type: 'number',
              placeholder: 'Enter Credits',
              value: newCreditsValue >= 0 ? newCreditsValue : '',
            }}
            onChange={(value) => {
              setUserData('credits', value);
              setErrors({ ...errors, credits: !value });
            }}
            error={
              errors.credits
                ? newCreditsValue > lbCredits
                  ? 'The credit limit value shouldn’t exceed more than your credit balance'
                  : newCreditsValue < lbCreditsIndividualLimitUsedMonthly ||
                      newCreditsValue < lbCreditsIndividualLimitUsedYearly
                    ? 'Limit should not be more than credits used'
                    : 'Please enter credits'
                : ''
            }
          />
        )}
      </div>
    </form>
  );
}

function UserDataPopup(props) {
  const { title, onClose, type, data, onDataSubmit } = props;

  const { lbCredits } = userDetail.userInfo;
  const {
    lbCreditsIndividualLimitUsedMonthly = 0,
    lbCreditsIndividualLimitUsedYearly = 0,
    ...remainingValues
  } = data ?? {};
  const defaultValues =
    Object.keys(remainingValues).length > 0 ? remainingValues : defaultData;
  const [userData, updateUserData] = useState(defaultValues);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    credits: false,
  });
  const popupRef = useRef();
  const [popupBoundary, setPopupBoundary] = useState({ left: 0, right: 0 });
  const isEdited = Object.keys(defaultValues).some((key) => {
    return defaultValues[key] !== userData[key];
  });
  useEffect(() => {
    if (popupRef.current) {
      const boundary = popupRef.current.getBoundingClientRect();
      setPopupBoundary({
        left: boundary.left,
        right: window.innerWidth - boundary.right,
      });
    }
  }, []);

  const setUserData = (label, value) => {
    updateUserData({ ...userData, [label]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fieldErrors = {};
    const isValid = Object.keys(userData).every((field) => {
      const fieldValue = userData[field];
      let isFieldInvalid = false;
      if (field === 'credits') {
        const parsedValue = parseInt(fieldValue);
        const userLimit = userData.limit;
        isFieldInvalid =
          userLimit !== ''
            ? parsedValue > lbCredits ||
              Number.isNaN(parsedValue) ||
              parsedValue < 0 ||
              (userLimit === 'MONTHLY' &&
                parsedValue < lbCreditsIndividualLimitUsedMonthly) ||
              (userLimit === 'YEARLY' &&
                parsedValue < lbCreditsIndividualLimitUsedYearly)
            : false;
      } else if (field === 'limit') {
        isFieldInvalid = false;
      } else if (type === 'ADD') {
        isFieldInvalid = !fieldValue;
      }
      fieldErrors[field] = isFieldInvalid;
      return !isFieldInvalid;
    });
    setErrors(fieldErrors);
    if (isValid) {
      onDataSubmit(userData);
    }
  };

  const isCreditLimitSet = userData.limit !== '';

  return (
    <Modal show modalRef={popupRef} className="user-popup" closeModal={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Content>
        <UserDataPopupContent
          errors={errors}
          setErrors={setErrors}
          popupBoundary={popupBoundary}
          userData={userData}
          setUserData={setUserData}
          editPersonalFields={type === 'ADD'}
          onSubmit={onSubmit}
          lbCreditsIndividualLimitUsedMonthly={
            lbCreditsIndividualLimitUsedMonthly
          }
          lbCreditsIndividualLimitUsedYearly={
            lbCreditsIndividualLimitUsedYearly
          }
        />
      </Modal.Content>
      <Modal.Footer>
        <div className="credit-section">
          <span className="available-credits">
            {Utils.numberFormat(lbCredits)} Credits
          </span>
          <span className="credits-text">
            Available in your {Utils.getPlanType()} plan
          </span>
        </div>
        <Button
          buttonProps={{
            text: 'Cancel',
            disabled: !isEdited,
            form: 'user-form',
            className: 'cancel-button',
          }}
          variant="outline"
          onClick={onClose}
        />
        {isCreditLimitSet &&
        !featureFlagsAndPreferencesState.isTeamManageEnabled() ? (
          <Button
            buttonProps={{
              text: 'Upgrade',
              form: 'user-form',
            }}
            onClick={Utils.redirectToLBPricingPage}
          />
        ) : (
          <Button
            buttonProps={{
              text: type === 'ADD' ? 'Invite User' : 'Save',
              disabled: !isEdited,
              form: 'user-form',
              type: 'submit',
            }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default observer(UserDataPopup);
