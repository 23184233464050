import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import CsvIcon from 'Assets/svg/csv-icon-new.svg';
import HubspotIcon from 'Assets/svg/export_icons/hubspot.svg';
import Utils from 'Utils/utils';
import './styles.scss';
import { MX_ENRICHMENT_EVENTS } from 'Utils/constants';

function EnrichmentLanding() {
  useEffect(() => {
    Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.ENRICHMENT_LANDING_VIEWED);
  }, []);

  return (
    <div className="page-content">
      <div className="enrichment-landing">
        <h1 className="title">
          <span>Get Started With Enrichment </span>
        </h1>
        <div className="description">
          We&#39;ve got you covered! Select the option to enrich your records
          with Adapt&#39;s accurate data.
        </div>
        <div className="card-wrapper">
          <Link
            to="/enrichment/file"
            onClick={() => {
              Utils.mixpanelTrack(
                MX_ENRICHMENT_EVENTS.ENRICHMENT_CARD_CLICKED,
                {
                  type: 'File',
                },
              );
            }}
          >
            <div className="enrichment-card">
              <div className="icon-wrapper">
                <CsvIcon className="csv-enrichment-icon" />
              </div>
              <div className="title">CSV Enrichment</div>
              <div className="description">
                Upload your CSV file and get it enriched with the data from
                Adapt.
              </div>
            </div>
          </Link>
          <Link
            to="/enrichment/hubspot/enrichment-log/contacts"
            onClick={() => {
              Utils.mixpanelTrack(
                MX_ENRICHMENT_EVENTS.ENRICHMENT_CARD_CLICKED,
                {
                  type: 'Hubspot',
                },
              );
            }}
          >
            <div className="enrichment-card">
              <div className="icon-wrapper">
                <HubspotIcon className="hubspot-enrichment-icon" />
              </div>
              <div className="title">Hubspot Enrichment</div>
              <div className="description">
                Enrich your Hubspot contacts with the data from Adapt
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(EnrichmentLanding);
