import React from 'react';
import InfoIcon from 'Assets/svg/info-icon.svg';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';

function ToolTipWithIcon(props) {
  const {
    className = '',
    children,
    Icon = '',
    offset: { right = 0, left = 0 } = {},
    callback = EMPTY_FUNCTION,
    variant = 'light', // add dark as other value
    wrapperClassName = '',
  } = props;

  const toolTipRef = React.useRef();

  const calculatePosition = () => {
    let offsetPosition = 0;
    if (toolTipRef.current) {
      const elementAttributes = toolTipRef.current.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      if (elementAttributes.right > screenWidth - (right + 5)) {
        offsetPosition = screenWidth - (right + 5) - elementAttributes.right;
      } else if (elementAttributes.left < left + 5) {
        offsetPosition = left + 5 - elementAttributes.left;
      }
      if (offsetPosition !== 0) {
        toolTipRef.current.style.transform = `translateX(calc(-50% ${
          offsetPosition > 0 ? '+' : '-'
        } ${Math.abs(offsetPosition)}px))`;
        const sharpPoint = toolTipRef.current.querySelector('.sharp-point');
        if (sharpPoint) {
          sharpPoint.style.transform = `translateX(calc(-50% ${
            offsetPosition > 0 ? '-' : '+'
          } ${Math.abs(offsetPosition)}px)) rotate(-45deg)`;
        }
      }

      callback();
    }
  };

  return (
    <div
      className={`new-tooltip info-with-icon ${variant} ${wrapperClassName}`}
      onMouseEnter={calculatePosition}
    >
      <div className="icon-wrap">
        {Icon || <InfoIcon className="info-icon" />}
      </div>
      <div className={`tooltip-wrap ${className}`} ref={toolTipRef}>
        <div className="tooltip-text-wrap">{children}</div>
        <span className="sharp-point" />
      </div>
    </div>
  );
}

export default ToolTipWithIcon;
