import React from 'react';
import { createRoot } from 'react-dom/client';
import Utils from 'Utils/utils';
import './assets/css/animate.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';

import './app/utils/scripts/mixpanel';
import './app/utils/scripts/totango';
import App from './app/App';

if (Utils.isProductionEnv()) {
 import('@sentry/react').then((Sentry) => {
    Sentry.init({
      dsn: 'https://89086f06ed7f47b4a4db2b8af0fa0639@o1303101.ingest.sentry.io/6541647',
      tracesSampleRate: 1.0,
    });
  });
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
