import React, { useEffect, useState } from 'react';
import CalenderIcon from 'Assets/svg/calendar.svg';
import RightArrowIcon from 'Assets/usageAnalytics/right-arrow.svg';
import Menu from 'Components/common/base/Menu';
import useOutsideClick from 'Components/common/base/useOutsideClick';
import MonthRangeDropdown from '../MonthRangeDropdown';
import './styles.scss';

function CustomRangeSelection(props) {
  const {
    fetchUsageAnalytics,
    getMonthRangeFromDropDown,
    currentTab = 0,
    monthsDropDown,
    monthRange,
    setMonthRange,
  } = props;

  const [calenderPopup, setCalendarPopUp] = useState(false);
  const [monthsValue, setMonthsValue] = useState(monthsDropDown[3].label);

  function handleClickOutside() {
    setCalendarPopUp(false);
  }

  const monthRangeRef = useOutsideClick(handleClickOutside);

  useEffect(() => {
    setMonthsValue(monthsDropDown[3].label);
  }, [currentTab]);

  return (
    <div className="custom-date-range-wrapper">
      <Menu
        excludeElementForOutsideClick=".calender-tool-tip"
        wrapperClassName="custom-date-range-dropdown"
        toggleElement={
          <>
            <CalenderIcon />
            <span>
              {monthsValue !== monthsDropDown[3].label
                ? monthsValue
                : `${monthRange.startMonth.slice(0, 3)} ${monthRange.startYear} -
                  ${monthRange.endMonth.slice(0, 3)} ${monthRange.endYear}`}
            </span>
          </>
        }
      >
        {monthsDropDown.map((ele, i) => {
          if (i < 3) {
            return (
              <Menu.Item
                onClick={() => {
                  getMonthRangeFromDropDown(ele.label);
                  setMonthsValue(ele.label);
                }}
                key={ele.label}
                text={ele.label}
              />
            );
          }
          return (
            <Menu.Item
              dataClose="false"
              onClick={() => {
                setCalendarPopUp(true);
              }}
              key={ele.label}
              text={
                <>
                  <span>{ele.label}</span>
                  <RightArrowIcon />
                </>
              }
            />
          );
        })}
      </Menu>
      {calenderPopup === true && (
        <div className="calender-tool-tip" ref={monthRangeRef}>
          <MonthRangeDropdown
            maxRange={currentTab === 0 ? 10 : 6}
            onApply={(data) => {
              document.querySelector('.custom-date-range-wrapper')?.click();
              setMonthsValue(monthsDropDown[3].label);
              setMonthRange(data);
              fetchUsageAnalytics(data);
            }}
            defaultValue={monthRange}
            onClose={() => {
              setCalendarPopUp(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default CustomRangeSelection;
