import React from 'react';
import './styles.scss';

function LoadingWrapper(props) {
  const { position, show, className } = props;

  return (
    <div
      className={`common-loader${className ? ` ${className}` : ''}`}
      style={{
        display: show ? 'block' : 'none',
      }}
    >
      <div
        className="common-loading-mask"
        style={{
          position: position || 'absolute',
        }}
      />

      <div
        className="spinner-loader-center"
        style={{
          position: position || 'absolute',
        }}
      >
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    </div>
  );
}

export default LoadingWrapper;
