import React from 'react';
import { observer } from 'mobx-react';
import CSVEnrichmentSample from 'Assets/csv/csvEnrichmentSample.csv';
import CsvIcon from 'Assets/svg/csv-icon-new.svg';
import AlertSoundIcon from 'Assets/svg/new_icons/alert-sound.svg';
import UploadIcon from 'Assets/svg/new_icons/upload.svg';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { EMPTY_FUNCTION, MX_ENRICHMENT_EVENTS } from 'Utils/constants';
import './styles.scss';

function showUpgradePopup() {
  commonModalState.setShowModal(true, {
    Icon: WarningIcon,
    title: 'No Enrichment credits left!',
    description:
      'You do not have enough enrichment credits available to perform this action, please upgrade now or buy more credits to continue using it.',
    primaryButtonText: 'Upgrade',
    buttonCallback: () => {
      Utils.showUserPricingPopup();
    },
  });
}

function UploadFile(props) {
  const {
    processFile,
    onFileUpload,
    uploadText = 'Upload file',
    setShowUploadPopup = EMPTY_FUNCTION,
  } = props;

  const handleFileDrop = (e) => {
    if (userDetail.userInfo.enrichmentCreditsRemaining === 0) {
      showUpgradePopup();
    } else {
      e.persist();
      e.preventDefault();
      setShowUploadPopup(false);
      e.target.classList.remove('hover-effect');
      const file = e.dataTransfer.files[0];
      if (file.type === 'text/csv') {
        processFile(file, 'dragndrop');
      } else {
        Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.ERROR_POPUP_SHOWN, {
          type: 'Invalid Format',
        });
        commonModalState.setShowModal(true, {
          title: 'Invalid File Format',
          Icon: WarningIcon,
          description: 'Please select a CSV file and try again.',
          primaryButtonText: 'Ok',
        });
      }
    }
  };

  return (
    <section
      className="upload-csv-wrapper"
      onDragEnter={(e) => {
        e.preventDefault();
        e.target.classList.add('hover-effect');
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.target.classList.remove('hover-effect');
      }}
      onDrop={handleFileDrop}
    >
      <div className="icon-wrapper">
        <CsvIcon className="csv-icon" />
      </div>
      <div className="upload-title">Drop your CSV file here or</div>
      <div className="upload-button-wrapper">
        <label htmlFor="upload-csv" className="csv-upload-label">
          <UploadIcon />
          {uploadText}
          <input
            className="csv-upload"
            type="file"
            id="upload-csv"
            accept=".csv"
            onChange={(e) => {
              setShowUploadPopup(false);
              if (userDetail.userInfo.enrichmentCreditsRemaining === 0) {
                showUpgradePopup();
              } else {
                onFileUpload(e, 'fileChooser');
              }
            }}
          />
        </label>
      </div>
      <div className="enrichment-instructions">
        Please make sure to include
        <span className="highlight">First Name, Last Name, Domain.</span>
        If they are not available try adding
        <span className="highlight">Email Ids or LinkedIn URLs</span>and we will
        find the rest.{' '}
        <a
          href={CSVEnrichmentSample}
          download="csvEnrichmentSample"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Sample File
        </a>
      </div>
      <div className="note">
        <span className="span-mr note-title">Note:</span>
        <span>
          Contact Information will not be enriched for Disposable, Personal
          emails & Group Email addresses.
        </span>
      </div>
      <div className="info-card">
        <AlertSoundIcon />
        <div className="message">
          Adapt will enrich your file with additional data attributes like job
          title, mobile number, company name, and more.
        </div>
      </div>
    </section>
  );
}

export default observer(UploadFile);
