/* eslint-disable no-underscore-dangle */
import React, { lazy, Suspense, useRef } from 'react';
import {
  createBrowserRouter,
  useLocation,
  useNavigate,
  Outlet,
  Navigate,
  useParams,
} from 'react-router-dom';
import Header from 'Components/header';
import Dashboard from 'Pages/dashboard';
import Profile from 'Pages/profile';
import MyLeads from 'Pages/myLeads';
import NewRelease from 'Pages/newRelease/newRelease';
import SuppressionList from 'Pages/profile/suppressionList';
import PageNotFound from 'Pages/pageNotFound';
import Pricing from 'Pages/pricing';
import ActivateAccount from 'Pages/activateAccount';
import ListPage from 'Pages/myLeads/ListPage';
import SavedSearch from 'Pages/savedSearch';
import PasswordSetup from 'Pages/passwordSetup';
import ProfileSettings from 'Pages/profile/profileSettings';
import ManageBilling from 'Pages/profile/manageBilling';
import ManageTeam from 'Pages/profile/manageTeam';
import UsageAnalytics from 'Pages/profile/usageAnalytics';
import APIUsageChart from 'Pages/profile/usageAnalytics/api';
import PurchaseHistory from 'Pages/profile/purchaseHistory';
import Enrichment from 'Pages/enrichment';
import EnrichmentLanding from 'Pages/enrichment/enrichmentLanding';
import FileEnrichment from 'Pages/enrichment/fileEnrichment';
import EnrichmentSettings from 'Pages/enrichment/crmEnrichment/enrichmentSettings';
import EnrichmentLog from 'Pages/enrichment/enrichmentLog';
import EnrichmentRoutes from 'Pages/enrichment/crmEnrichment/enrichmentRoutes';
import Integration from 'Pages/integration';
import FieldMapping from 'Pages/integration/FieldMapping';
import ConnectedCrmWrapper from 'Pages/integration/ConnectedCrmWrapper';
import CrmSettings from 'Pages/integration/CrmSettings';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { PAGE_PATH_MAP, SEOCookieQueryMapping } from 'Utils/constants';
import ProspectSearch from 'Pages/prospectSearch';
import ContactSearch from 'Pages/prospectSearch/contactSearch';
import CompanySearch from 'Pages/prospectSearch/companySearch';
import CommonModal from 'Components/common/base/Modal/CommonModal';
import FileEnrichmentReport from 'Pages/enrichment/fileEnrichment/FileEnrichmentReport';
import CommonUpgradePopup from 'Components/common/CommonUpgradePopup';
import LoadingWrapper from 'Components/common/base/Loader';

const Workflow = lazy(() => {
  return import('Pages/workflow');
});

const WorkflowEditor = lazy(() => {
  return import('Pages/workflow/workflowEditor');
});

const EnrollmentHistory = lazy(() => {
  return import('Pages/workflow/EnrollmentHistory');
});

function Index() {
  Utils.enableBodyScroll();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const pathnameRef = useRef('');
  const hubspotIdentifyCalled = useRef(false);

  Utils.routerProps = { location, navigate };

  const { isFreeTrial } = userDetail;

  React.useEffect(() => {
    if (
      !Utils.checkIsEmailVerified(false) ||
      !Utils.checkIsSecondaryEmailVerified()
    ) {
      Utils.showActivateAccountFlow();
      freeTrialPopupState.setFreeTrialPrePopup(null);
    } else if (
      Utils.checkPasswordSetupFlow() &&
      pathname !== PAGE_PATH_MAP.PASSWORD_SETUP
    ) {
      Utils.showPasswordSetupFlow();
    } else if (
      pathname !== '/advanced-search/contact' &&
      !isFreeTrial &&
      pathname !== PAGE_PATH_MAP.PASSWORD_SETUP
    ) {
      if (!pathname.includes('/advanced-search')) {
        Utils.removeLocalStorage('companySerPostData');
        Utils.removeLocalStorage('searchJson');
      }
      Utils.deleteCookie(SEOCookieQueryMapping.seoContactURL);
      Utils.deleteCookie('seo_search');
      userDetail.setCurrentTab('');
      if (pathname === '/') {
        navigate('/dashboard');
      }
    } else if (pathname === '/') {
      navigate('/dashboard');
    }

    // if (Utils.isPaymentPendingReview()) {
    //   userDetail.setShowNotificationType(
    //     NOTIFICATION_BAR_TYPE.PAYMENT_PENDING_REVIEW,
    //   );
    // }
    const { _hsq } = window;
    if (_hsq !== undefined) {
      if (!hubspotIdentifyCalled.current) {
        _hsq.push([
          'identify',
          {
            email: userDetail.userInfo.email,
          },
        ]);
        hubspotIdentifyCalled.current = true;
      }
      // skip first render since we are assigning value only after this block
      if (
        pathnameRef.current !== '' &&
        !pathname.includes('/advanced-search')
      ) {
        _hsq.push(['setPath', pathname]);
        _hsq.push(['trackPageView']);
      }
    }
    if (pathname.includes('/dashboard') || pathname.includes('/my-leads')) {
      setTimeout(() => {
        Utils.checkAndShowG2Popup();
      }, 1000);
    }
    pathnameRef.current = pathname;
  }, [pathname]);

  return (
    <>
      <Header />
      <section
        className={`site-content${
          userDetail.showNotificationType !== '' ? ' has-notification-bar' : ''
        }`}
      >
        <Outlet />
        <CommonModal />
        {/* having react router import, bringing under router provider */}
        <CommonUpgradePopup />
      </section>
    </>
  );
}

function MyLeadsRedirect() {
  const params = useParams();
  return <Navigate to={`/my-leads/list/${params.id}`} replace />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <h1>An Error occured</h1>,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'activate-account',
        element: <ActivateAccount />,
      },
      {
        path: 'password-setup',
        element: <PasswordSetup />,
      },
      {
        path: 'advanced-search/:type/saved-searches',
        element: <SavedSearch />,
      },
      {
        path: 'advanced-search',
        element: <ProspectSearch />,
        children: [
          {
            path: 'contact/:savedTemplate?',
            element: <ContactSearch />,
          },
          {
            path: 'company/:savedTemplate?',
            element: <CompanySearch />,
          },
        ],
      },
      {
        path: 'my-leads',
        element: <MyLeads />,
      },
      {
        path: 'my-leads/list/:id?',
        element: <ListPage />,
      },
      {
        path: 'profile',
        element: <Profile />,
        children: [
          {
            path: 'settings',
            element: <ProfileSettings />,
          },
          {
            path: 'billing',
            element: <ManageBilling />,
          },
          {
            path: 'manage-team',
            element: <ManageTeam />,
          },
          {
            path: 'usage-analytics',
            element: <UsageAnalytics />,
          },
          {
            path: 'usage-analytics/api',
            element: <APIUsageChart />,
          },
          {
            path: 'purchase-history',
            element: <PurchaseHistory />,
          },
          {
            path: 'suppression-list',
            element: <SuppressionList />,
          },
        ],
      },
      {
        path: 'release',
        element: <NewRelease />,
      },
      {
        path: 'pricing',
        element: <Pricing />,
      },
      {
        path: 'enrichment',
        element: <Enrichment />,
        children: [
          {
            index: true,
            element: <EnrichmentLanding />,
          },
          {
            path: ':crmType',
            element: <EnrichmentRoutes />,
            children: [
              {
                path: 'enrichment-log/:subMenu',
                element: <EnrichmentLog />,
              },
              {
                path: 'settings',
                element: <EnrichmentSettings />,
              },
            ],
          },
          {
            path: 'file',
            children: [
              {
                index: true,
                element: <FileEnrichment />,
              },
              {
                path: ':id',
                element: <FileEnrichmentReport />,
              },
            ],
          },
        ],
      },
      {
        path: 'integrations',
        element: <Integration />,
      },
      {
        path: 'integrations/:crmType',
        element: <ConnectedCrmWrapper />,
        children: [
          { path: 'field-mapping/:mappingType', element: <FieldMapping /> },
          { path: 'settings', element: <CrmSettings /> },
        ],
      },
      {
        path: 'workflow',
        element: (
          <Suspense fallback={<LoadingWrapper show position="fixed" />}>
            <Workflow />
          </Suspense>
        ),
      },
      {
        path: 'workflow/:id',
        element: (
          <Suspense fallback={<LoadingWrapper show position="fixed" />}>
            <WorkflowEditor />
          </Suspense>
        ),
      },
      {
        path: 'workflow/:id/enrollment-history',
        element: (
          <Suspense fallback={<LoadingWrapper show position="fixed" />}>
            <EnrollmentHistory />
          </Suspense>
        ),
      },
      // redirects
      {
        path: 'my-leads/:id',
        element: <MyLeadsRedirect />,
      },
      {
        path: 'edit-profile',
        element: <Navigate to="/profile/settings" replace />,
      },
      {
        path: 'suppression-list',
        element: <Navigate to="/profile/suppression-list" replace />,
      },
      // not found
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
]);

export default router;
