import React, { useEffect } from 'react';
import AddYourAccount from 'Assets/salesgear/png/add-your-account.png';
import Button from 'Components/common/base/Button';
import Modal from 'Components/common/base/Modal';
import Utils from 'Utils/utils';
import { MX_EVENTS_AE, SALESGEAR_URL } from 'Utils/constants';
import './styles.scss';

function SalesgearConnectInbox(props) {
  const { hide } = props;

  const closePopup = () => {
    hide(false);
  };

  const redirectToAeConnectInbox = () => {
    hide(false);
    Utils.mixpanelTrack(MX_EVENTS_AE.addEmailAccountClicked);
    window.open(`${SALESGEAR_URL}/settings/account`);
  };

  useEffect(() => {
    Utils.mixpanelTrack(MX_EVENTS_AE.addEmailAccountShown);
  }, []);

  return (
    <Modal
      align="center"
      className="salesgear-banner-popup connect-inbox"
      show
      closeModal={closePopup}
    >
      <img src={AddYourAccount} alt="Illustration" className="illustration" />
      <Modal.Title>Add your email account</Modal.Title>
      <Modal.Content>
        <p className="message">
          Before adding contacts to sequences, you must add your email account
          with Salesgear.
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button
          buttonProps={{ text: 'Add Email Account' }}
          onClick={redirectToAeConnectInbox}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default SalesgearConnectInbox;
