import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useOutletContext } from 'react-router-dom';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import Toggle from 'Components/common/base/Toggle';
import { toasterState } from 'Components/common/base/Toaster';
import CRMPreferencesCard from 'Components/common/CRMPreferencesCard';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import fieldMappingState from 'Stores/fieldMapping';
import userDetail from 'Stores/userDetail';
import integrationDataMap from 'Pages/integration/integrationDataMap';
import {
  EMPTY_FUNCTION,
  MX_ENRICHMENT_EVENTS,
  TOAST_MSG_TYPES,
} from 'Utils/constants';
import Utils from 'Utils/utils';

function SettingsSwitch(props) {
  const {
    description,
    isChecked = false,
    onToggle = () => {},
    className = '',
    animateSwitch,
    setSwitchAnimation,
  } = props;

  const ref = useRef();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (animateSwitch && ref.current) {
      if (checked) {
        setTimeout(() => {
          setSwitchAnimation(false);
          setChecked(false);
        }, 2000);
      } else {
        ref.current.classList.add('click');
        setTimeout(() => {
          setChecked(true);
          ref.current.classList.remove('click');
        }, 2000);
      }
    }
  }, [animateSwitch, checked]);

  return (
    <div
      className={`enrichment-toggle ${className ? ` ${className}` : ''}`}
      ref={ref}
    >
      <div className="message">{description}</div>
      <Toggle
        firstText="Off"
        toggle={isChecked || checked}
        cbk={onToggle}
        lastText="On"
      />
    </div>
  );
}

/**
 * This component shares styles with crmSettings
 */

function EnrichmentSettings(props) {
  const {
    callback = EMPTY_FUNCTION,
    // showToast = true,
    enableEdit = false,
    animateSwitch = false,
    isEnrichmentSettingsFetched: isFetchedFromProps,
  } = props;
  const context = useOutletContext();
  const isEnrichmentSettingsFetched =
    isFetchedFromProps || context?.isFetchedFromContext;

  const { crmType } = useParams();
  const currentCRMData = integrationDataMap[crmType?.toUpperCase()];
  const [switchAnimation, setSwitchAnimation] = useState(false);

  const hasLeads =
    currentCRMData?.sublinks?.find((data) => {
      return data.linkKey === 'field-mapping';
    })?.subMenu?.length === 2 || false;

  useEffect(() => {
    if (isEnrichmentSettingsFetched) {
      setSwitchAnimation(animateSwitch);
    }
  }, [isEnrichmentSettingsFetched]);

  const hasEditAccess =
    enableEdit || fieldMappingState.enrichmentSettingsData?.isAdmin || false;
  const crmName =
    currentCRMData?.title
      ?.split(' ')
      ?.map((data) => {
        return `${data.substring(0, 1).toUpperCase()}${data.substring(1)}`;
      })
      ?.join(' ') ?? crmType;
  const { userInfo } = userDetail;
  const { enrichmentCreditsRemaining } = userInfo;
  const isNotificationEnabled =
    fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
      ?.crmNotificationEnable;

  const toggleAutoEnrichment = async (checked) => {
    if (enrichmentCreditsRemaining === 0) {
      commonModalState.setShowModal(true, {
        Icon: WarningIcon,
        title: 'No Enrichment credits left!',
        description: (
          <>
            You have utilized all of your enrichment credits. Hence
            Auto-enrichment to your {crmName} CRM is disabled. Please contact
            our <a href="mailto:support@adapt.io">support</a> to add enrichment
            credits to your account.
          </>
        ),
        primaryButtonText: 'OK',
      });
    } else {
      Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.AUTO_ENRICHMENT_TOGGLED, {
        value: checked,
      });
      const newData = {
        autoEnrichConfig: {
          crmContact: checked,
          ...(!checked &&
            isNotificationEnabled && { crmNotificationEnable: false }),
        },
      };
      const success = await fieldMappingState.updateEnrichmentSettings(
        crmType.toUpperCase(),
        newData,
      );
      if (success) {
        toasterState.setToastMsg(
          'Enrichment settings updated successfully',
          TOAST_MSG_TYPES.SUCCESS,
        );
        fieldMappingState.enrichmentSettingsData = {
          ...fieldMappingState.enrichmentSettingsData,
          autoEnrichConfig: {
            ...fieldMappingState.enrichmentSettingsData.autoEnrichConfig,
            ...(newData?.autoEnrichConfig || {}),
          },
        };
      }
      callback(success);
    }
  };

  const toggleEmailForAutoEnrichment = async (checked) => {
    Utils.mixpanelTrack(
      MX_ENRICHMENT_EVENTS.AUTO_ENRICHMENT_NOTIFICATION_TOGGLED,
      {
        value: checked,
      },
    );
    const newData = {
      autoEnrichConfig: { crmNotificationEnable: checked },
    };
    const isSuccess = await fieldMappingState.updateEnrichmentSettings(
      crmType.toUpperCase(),
      newData,
    );

    if (isSuccess) {
      fieldMappingState.enrichmentSettingsData = {
        ...fieldMappingState.enrichmentSettingsData,
        autoEnrichConfig: {
          ...fieldMappingState.enrichmentSettingsData.autoEnrichConfig,
          ...(newData?.autoEnrichConfig || {}),
        },
      };

      toasterState.setToastMsg(
        'Enrichment settings updated successfully',
        TOAST_MSG_TYPES.SUCCESS,
      );
    }
  };

  return (
    <div className={`enrichment-settings ${hasEditAccess ? '' : ' no-edit'}`}>
      {switchAnimation && <div className="mask" />}
      <CRMPreferencesCard
        header="Auto Enrichment"
        title="Contacts"
        className={switchAnimation ? ' animate-switch' : ''}
      >
        {hasLeads && (
          <>
            <SettingsSwitch
              crmType={crmType}
              isChecked={
                fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
                  ?.crmLead
              }
              onToggle={(checked) => {
                return fieldMappingState.updateEnrichmentSettings(
                  crmType.toUpperCase(),
                  { autoEnrichConfig: { crmLead: checked } },
                );
              }}
              title="Leads"
              description={`Automatically enrich new Leads added to ${crmName} with Adapt's
          data every 30 minutes.`}
            />
            <div className="divider" />
          </>
        )}
        <SettingsSwitch
          crmType={crmType}
          animateSwitch={switchAnimation}
          setSwitchAnimation={setSwitchAnimation}
          isChecked={
            fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
              ?.crmContact
          }
          onToggle={toggleAutoEnrichment}
          className="enrichment-enable"
          description={`Automatically enrich new Contacts added to ${crmName} with Adapt's
          data every 30 minutes.`}
        />
      </CRMPreferencesCard>
      <CRMPreferencesCard
        title="Email notification for Auto enrichment"
        header="Notification"
      >
        <SettingsSwitch
          className={`enrichment-email-toggle${
            !(
              fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
                ?.crmContact || false
            )
              ? ' disabled'
              : ''
          }`}
          isChecked={isNotificationEnabled}
          crmType={crmType}
          onToggle={toggleEmailForAutoEnrichment}
          description="I&#39;d like to receive a weekly email notification on Auto Enrichment every Monday."
        />
      </CRMPreferencesCard>
    </div>
  );
}

export default observer(EnrichmentSettings);
