import React from 'react';
import ContactIcon from 'Assets/svg/new_icons/contacts.svg';

function CompanyEmployeeCount(props) {
  const { employeeCount = '', parentClass = '', clsName = '' } = props;

  return employeeCount !== undefined &&
    employeeCount !== '' &&
    employeeCount !== null ? (
    <div className={`company-empcount icon-wrap ${parentClass}`}>
      <ContactIcon className="svg-icon" />
      <div
        className={`item-name show-ellipsis ${clsName}`}
        title={employeeCount}
      >
        {employeeCount}
      </div>
    </div>
  ) : (
    ''
  );
}

export { CompanyEmployeeCount };
export default CompanyEmployeeCount;
