import { useEffect } from 'react';

function Title(props) {
  const { title } = props;
  useEffect(() => {
    document.title = `${title} | Lead Builder`;
  }, []);

  return null;
}

export default Title;
