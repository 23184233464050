import React from 'react';
import { observer } from 'mobx-react';
import SuccessIcon from 'Assets/svg/tick-icon-new.svg';
import ErrorIcon from 'Assets/svg/new_icons/warning.svg';
import DownloadIcon from 'Assets/svg/new_icons/download.svg';
import Button from 'Components/common/base/Button';
import InlineLoader from 'Components/common/base/InlineLoader';
import enrichmentState from 'Stores/enrichment';
import Utils from 'Utils/utils';
import { MX_ENRICHMENT_EVENTS } from 'Utils/constants';
import './styles.scss';
import { Link } from 'react-router-dom';

function RenderLogField(data) {
  const {
    fileName,
    uploadedOn,
    totalRecord,
    enrichedRecord,
    creditUsed,
    status,
    id,
    className = '',
    enrichedCsvFile,
  } = data;

  const isPartialOrTotallyComplete =
    status === 'COMPLETED' || status === 'PARTIALLY_COMPLETED';

  const isToBeProcessed =
    status === 'TO_BE_PROCESSED' ||
    status === 'DATA_REQUESTED' ||
    status === 'ENRICHED_DATA_RECEIVED';

  return (
    <div key={id} className={`log-field ${className}`.trim()}>
      <div className="log-field-inner">
        <span className="file-name show-ellipsis" title={fileName}>
          {fileName}
        </span>
        <span className="uploaded-on">
          {uploadedOn !== null ? Utils.getFormattedDate(uploadedOn, true) : '-'}
        </span>
        <span className="total-count">
          {totalRecord !== null ? Utils.numberFormat(totalRecord) : '-'}
        </span>
        <span className="enriched-count">
          {enrichedRecord !== null && !isToBeProcessed
            ? Utils.numberFormat(enrichedRecord)
            : '-'}
        </span>
        <span className="credits-used">
          {creditUsed !== null && !isToBeProcessed
            ? Utils.numberFormat(creditUsed)
            : '-'}
        </span>
        <span className={`enrichment-status ${status}`}>
          {isPartialOrTotallyComplete ? (
            <SuccessIcon />
          ) : isToBeProcessed ? (
            <InlineLoader />
          ) : (
            <ErrorIcon />
          )}
          <span>
            {isToBeProcessed
              ? 'Processing'
              : isPartialOrTotallyComplete
                ? 'Complete'
                : status.toLowerCase()}
          </span>
        </span>
        <span className="action">
          {isPartialOrTotallyComplete && enrichedRecord > 0 ? (
            <>
              <Link
                className="button outline"
                to={`/enrichment/file/${id}`}
                onClick={() => {
                  Utils.mixpanelTrack(
                    MX_ENRICHMENT_EVENTS.VIEW_REPORT_BUTTON_CLICKED,
                    { enrichedCount: enrichedRecord },
                  );
                }}
              >
                View Report
              </Link>
              <Button
                buttonProps={{ text: 'Download' }}
                variant="outline"
                onClick={() => {
                  Utils.mixpanelTrack(
                    MX_ENRICHMENT_EVENTS.DOWNLOAD_BUTTON_CLICKED,
                    { enrichedCount: enrichedRecord },
                  );
                  window.open(enrichedCsvFile, '_blank').focus();
                }}
                Icon={DownloadIcon}
              />
            </>
          ) : null}
        </span>
      </div>
    </div>
  );
}

function EnrichedList(props) {
  const { isListLoading, page } = props;

  return (
    <div className="enriched-list-table-wrapper">
      <div className="table-header">
        <div className="header-inner-wrap">
          <span className="file-name">File Name</span>
          <span className="uploaded-on">Uploaded Date</span>
          <span className="total-count">Total Count</span>
          <span className="enriched-count">Enriched Count</span>
          <span className="credits-used">Credits Used</span>
          <span className="enrichment-status">Enrichment Status</span>
          <span className="action">Action</span>
        </div>
      </div>
      <div className="table-body">
        {isListLoading
          ? Array.from({ length: 10 }).map((item, index) => {
              return (
                <div
                  key={`log-placeholder-${index + 1}`}
                  className="log-field placeholder"
                >
                  <div className="log-field-inner">
                    <span className="file-name linear-background" />
                    <span className="uploaded-on linear-background" />
                    <span className="total-count linear-background" />
                    <span className="enriched-count linear-background" />
                    <span className="credits-used linear-background" />
                    <span className="enrichment-status linear-background" />
                    <div className="action">
                      <span className="action linear-background" />
                      <span className="action linear-background" />
                    </div>
                  </div>
                </div>
              );
            })
          : enrichmentState.fileEnrichmentList.map((enrichmentData) => {
              return (
                <RenderLogField
                  key={enrichmentData.id}
                  {...enrichmentData}
                  page={page}
                />
              );
            })}
      </div>
    </div>
  );
}

export default observer(EnrichedList);
