import React from 'react';
import ReactHookSelect from 'react-hook-select';
import DropDownIcon from 'Assets/svg/dropdown-icon.svg';
import SearchIcon from 'Assets/svg/open-search.svg';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';

const renderDropDownIcon = ({ isDropDownOpen }) => {
  return <DropDownIcon className={isDropDownOpen ? 'open' : ''} />;
};

function Select(props) {
  const {
    options,
    value = '',
    onChange = EMPTY_FUNCTION,
    enableMultiple = false,
    inlineLabel = true,
    label = '',
    wrapperClassName = '',
    enableValuesOutsideOfOptions,
    canClearValue,
    searchInputProps,
    dropdownOffset,
    showPlaceholderInOptions = false,
    placeholder,
    selectWrapperProps,
    enableSearch,
    defaultValue,
    selectInputProps,
    renderOption,
    customFilterMethod,
    showSelectedValue = true,
  } = props;

  return (
    <div className={`select-with-label ${wrapperClassName}`.trim()}>
      {!inlineLabel && label !== '' && <p className="select-label">{label}</p>}
      <ReactHookSelect
        defaultValue={defaultValue}
        renderDropDownIcon={renderDropDownIcon}
        options={options}
        enableMultiple={enableMultiple}
        value={value ? [value].flat(2) : []}
        getValue={(newValue) => {
          onChange(enableMultiple ? newValue : newValue[0]);
        }}
        enableValuesOutsideOfOptions={enableValuesOutsideOfOptions}
        canClearValue={canClearValue}
        searchInputProps={searchInputProps}
        dropdownOffset={dropdownOffset}
        showPlaceholderInOptions={showPlaceholderInOptions}
        placeholder={placeholder}
        selectWrapperProps={selectWrapperProps}
        enableSearch={enableSearch}
        label={inlineLabel ? label : ''}
        searchIcon={<SearchIcon className="search-icon" />}
        selectInputProps={selectInputProps}
        renderOption={renderOption}
        customFilterMethod={customFilterMethod}
        chipView={false}
        showSelectedValue={showSelectedValue}
      />
    </div>
  );
}

export default Select;
