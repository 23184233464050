import { observable, action } from 'mobx';
import { URLS, makeApi } from 'Utils/apiUtil';
import Utils from 'Utils/utils';

class PurchaseListState {
  @observable accessor purchaseListData = { count: 0, list: [] };

  @observable accessor isLoading = false;

  @action
  setPurchaseLists = (list) => {
    this.purchaseListData = { count: this.purchaseListData.count, list };
  };

  fetchPurchaseList = async (params) => {
    const config = {
      url: Utils.generateUrl(URLS.getBulkPurchaseList, params),
      method: 'GET',
    };
    const response = await makeApi(config);
    return response;
  };

  @action
  getPurchaseList = async (page, size) => {
    this.isLoading = true;
    const response = await this.fetchPurchaseList({ page, size });
    if (response.status === 200) {
      const { list, count } = response.data;
      this.purchaseListData = { list, count };
    }
    this.isLoading = false;
  };
}

const purchaseListState = new PurchaseListState();

export default purchaseListState;
