import React from 'react';
import { observer } from 'mobx-react';
import InfoIcon from 'Assets/svg/info-icon.svg';
import ControlInput from 'Components/common/base/ControlInput';
import { toasterState } from 'Components/common/base/Toaster';
import advSearchState from 'Stores/advSearch';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import './styles.scss';
import Utils from 'Utils/utils';

function EmailAccuracy() {
  const showContacts = (key, checked) => {
    const newValues = {
      showContactsWithEmail:
        advSearchFilterPopupState.checkBoxFilters.showContactsWithEmail,
      showContactsWithoutEmail:
        advSearchFilterPopupState.checkBoxFilters.showContactsWithoutEmail,
      [key]: checked,
    };

    if (newValues.showContactsWithEmail || newValues.showContactsWithoutEmail) {
      advSearchState.saveEmailSearchPreferenceToAPI(newValues);
      advSearchFilterPopupState.setCheckBoxFilters(key);
    } else {
      toasterState.setToastMsg('At least one value must be selected');
    }
  };

  return (
    <div className="email-accuracy-wrapper">
      <ControlInput
        type="checkbox"
        label="Available"
        checked={
          advSearchFilterPopupState.checkBoxFilters.showContactsWithEmail
        }
        onChange={(checked) => {
          showContacts('showContactsWithEmail', checked);
        }}
      />
      <ControlInput
        type="checkbox"
        label="Not available"
        checked={
          advSearchFilterPopupState.checkBoxFilters.showContactsWithoutEmail
        }
        onChange={(checked) => {
          showContacts('showContactsWithoutEmail', checked);
        }}
      />
      {Utils.isLBPaidSubscription() && (
        <p className="note">
          <InfoIcon className="info-icon" />
          Email deliverability is no longer available in search. We perform
          email verification when you purchase contacts and you can choose if
          you'd like to add only verified or both verified and unverified
          contacts.
        </p>
      )}
    </div>
  );
}

export default observer(EmailAccuracy);
