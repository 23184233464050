import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import OutreachLogo from 'Assets/svg/export_icons/outreach.svg';
import Select from '../base/Select';
import Modal from '../base/Modal';
import Button from '../base/Button';
import Input from '../base/Input';
import CRMExportHeader from './CRMExportHeader';

class OutreachSequenceState {
  @observable accessor showPopup = false;

  defaultPopupValue = {
    sequenceList: [],
    mailBoxList: [],
    callback() {},
  };

  @observable accessor popupValues = this.defaultPopupValue;

  @action
  setShowPopup = (val) => {
    this.showPopup = val;
    if (val === false) {
      this.popupValues = this.defaultPopupValue;
    }
  };

  @action
  setPopupValues = (val) => {
    this.popupValues = { ...this.popupValues, ...val };
  };
}
const outreachSequenceState = new OutreachSequenceState();

function OutreachSequencePopup() {
  const [selectedSeq, setSelectedSeq] = React.useState('');

  const [showSelectSequenceErr, setShowSelectSequenceErr] =
    React.useState(false);

  const [selectedMailBox, setSelectedMailBox] = React.useState('');

  const [showSelectMailBoxErr, setShowSelectMailBoxErr] = React.useState(false);

  const dateObj = new Date();
  const currentMonth =
    dateObj.getMonth() + 1 < 10
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1;
  const tagDefaultValue = `${currentMonth}${dateObj.getDate()}${dateObj.getFullYear()}`;
  const { sequenceList, mailBoxList } = outreachSequenceState.popupValues;

  const [tagValue, setTagValue] = React.useState(tagDefaultValue);

  React.useEffect(() => {
    if (!outreachSequenceState.showPopup) {
      setTagValue(tagDefaultValue);
      setSelectedSeq('');
      setSelectedMailBox('');
    }
  }, [outreachSequenceState.showPopup]);

  const confirmClick = () => {
    if (selectedSeq !== '' && selectedMailBox !== '') {
      outreachSequenceState.popupValues.callback(
        selectedSeq,
        selectedMailBox,
        tagValue,
      );
      outreachSequenceState.setShowPopup(false);
      setTagValue('');
    } else if (selectedMailBox === '') {
      setShowSelectMailBoxErr(true);
    } else {
      setShowSelectSequenceErr(true);
    }
  };

  const skipAndContinue = () => {
    outreachSequenceState.popupValues.callback('', '', tagValue);
    outreachSequenceState.setShowPopup(false);
    setTagValue('');
  };

  const onTagChange = (value) => {
    setTagValue(value);
  };

  const closeModal = () => {
    outreachSequenceState.setShowPopup(false);
  };

  return (
    outreachSequenceState.showPopup && (
      <Modal show closeModal={closeModal} className="outreach-sequence-popup">
        <CRMExportHeader Logo={OutreachLogo} title="Export to Outreach" />
        <Modal.Content>
          <div className="export-sequence-wrap">
            <Select
              inlineLabel={false}
              label="Target Sequence"
              value={selectedSeq}
              showPlaceholderInOptions={false}
              placeholder="Select from dropdown"
              onChange={(value) => {
                if (showSelectSequenceErr) {
                  setShowSelectSequenceErr(false);
                }
                setSelectedSeq(value);
              }}
              options={
                sequenceList?.length > 0
                  ? sequenceList?.map((data) => {
                      return {
                        label: data.name,
                        value: data.id,
                      };
                    })
                  : []
              }
            />
            {showSelectSequenceErr && (
              <div className="error-text">Select sequence to continue</div>
            )}
          </div>

          <div className="export-sequence-wrap">
            <Select
              inlineLabel={false}
              label="Select a Mail Box"
              value={selectedMailBox ? [selectedMailBox] : []}
              showPlaceholderInOptions={false}
              placeholder="Select from dropdown"
              onChange={(value) => {
                if (showSelectMailBoxErr) {
                  setShowSelectMailBoxErr(false);
                }
                setSelectedMailBox(value[0]);
              }}
              options={
                mailBoxList?.length > 0
                  ? mailBoxList?.map((data) => {
                      return {
                        label: data.name,
                        value: data.id,
                      };
                    })
                  : []
              }
            />
            {showSelectMailBoxErr && (
              <div className="error-text">Select mail box to continue</div>
            )}
          </div>
          <Input
            inputProps={{
              type: 'text',
              value: tagValue,
            }}
            onChange={(value) => {
              onTagChange(value);
            }}
            label="Tags (multiples separated by commas)"
          />
        </Modal.Content>
        <Modal.Footer>
          <Button
            buttonProps={{ text: 'Continue without adding to Sequence' }}
            variant="transparent"
            onClick={skipAndContinue}
          />
          <Button
            buttonProps={{ text: 'Cancel' }}
            variant="outline"
            onClick={closeModal}
          />
          <Button buttonProps={{ text: 'Save' }} onClick={confirmClick} />
        </Modal.Footer>
      </Modal>
    )
  );
}

export { outreachSequenceState };
export default observer(OutreachSequencePopup);
