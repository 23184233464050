import React, { useId, useState, useEffect } from 'react';
import SampleIncludeExcludeCSV from 'Assets/csv/sampleIncludeExclude.csv';
import CloseIcon from 'Assets/svg/close-material.svg';
import { toasterState } from 'Components/common/base/Toaster';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { MX_AS_EVENTS } from 'Utils/constants';
import Utils from 'Utils/utils';
import trackMixpanelEvent from '../trackMixpanelEvent';
import './styles.scss';

const defaultValue = { total: 0, valid: 0 };

function StatCard(props) {
  const { title, totalSize, validSize, setStatData } = props;

  const dismissMessage = () => {
    setStatData(defaultValue);
  };

  useEffect(() => {
    setTimeout(() => {
      dismissMessage();
    }, 10000);
  }, []);

  return (
    <div className="upload-csv-stat-card">
      <CloseIcon className="close-icon" onClick={dismissMessage} />
      <div className="text">{title}</div>
      <div className="stat">{`Total entries in the file: ${totalSize}`}</div>
      <div className="stat">{`Number of valid domains: ${validSize}`}</div>
    </div>
  );
}

function handleUploadCSV(csvUploadData) {
  const {
    currentFilterState,
    filterKey,
    mxEventName,
    setStatData,
    page,
    domainSize,
    event,
  } = csvUploadData;

  const callback = async (callbackData) => {
    const data = { domains: callbackData.entries, fname: callbackData.fname };
    if (!callbackData) {
      toasterState.setToastMsg(
        'CSV file is empty, please upload a csv with domains.',
      );
    } else if (callbackData !== '' && callbackData === 'error') {
      toasterState.setToastMsg(
        `CSV not uploaded, please upload a csv with less than ${Utils.getExcludeCompanyDomainSize()} domains.`,
      );
    } else {
      const response = await advSearchFiltersState.validateDomains(
        data.domains,
      );
      if (response !== undefined) {
        setStatData({ total: data.domains?.length, valid: response?.length });
        trackMixpanelEvent({
          selectedValue: data,
          key: filterKey,
          page,
          mxUniqueKey: '',
          mxEventName,
        });
        currentFilterState.setUploadCsv(filterKey, data);
      }
    }
  };
  Utils.uploadCsv(event, callback, true, domainSize);
}

function CompanyInclusion(props) {
  const { currentFilterState, page } = props;
  const id = useId();

  const [statData, setStatData] = useState(defaultValue);

  const onFileChange = (e) => {
    e.persist();
    handleUploadCSV({
      event: e,
      currentFilterState,
      page,
      setStatData,
      filterKey: 'includedDomains',
      domainSize: Utils.getDomainSize(),
      mxEventName: MX_AS_EVENTS.LB_AS_INCLUDE_COMPANY_SEARCH_PERFORMED,
    });
  };

  return (
    <div className="company-exclusion-wrapper file-upload-wrapper">
      <p>
        Include contacts from specific companies in this search results.
        <a
          className="sample-csv"
          href={SampleIncludeExcludeCSV}
          download="sampleIncludeExclude"
        >
          Sample File
        </a>
      </p>
      <label htmlFor={id} className="csv-upload-label button outline">
        Choose File
        <input
          id={id}
          className="csv-upload"
          type="file"
          accept=".csv"
          onChange={onFileChange}
        />
      </label>
      {statData.total > 0 && (
        <StatCard
          title="Include Companies"
          totalSize={statData.total}
          validSize={statData.valid}
          setStatData={setStatData}
        />
      )}
    </div>
  );
}

export { handleUploadCSV, StatCard };

export default CompanyInclusion;
