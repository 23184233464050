import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, Navigate } from 'react-router-dom';
import CardImg from 'Assets/png/card.png';
import logo from 'Assets/logo/logo64.png';
import Button from 'Components/common/base/Button';
import Title from 'Components/common/base/Title';
import { toasterState } from 'Components/common/base/Toaster';
import profileState from 'Stores/profile';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import './styles.scss';

const constructedPlanObj = {
  currentPlan: '',
  isFreeTrial: '',
  isAnnualPlan: '',
  savedCard: '',
  planRenewsDate: '',
  planStartDate: '',
};

const stripe = {};

function constructPlanData(planObj) {
  const savedCardValue = profileState.savedCardData;
  let newConstructedPlanObj = {};
  newConstructedPlanObj.savedCard = savedCardValue || '';

  if (planObj) {
    const { currentPlan, isFreeTrial } = planObj;
    const isAnnualPlan = !!(
      planObj.memberSubscription &&
      planObj.memberSubscription.frequencyType &&
      planObj.memberSubscription.frequencyType === 1
    );
    newConstructedPlanObj = {
      currentPlan,
      isFreeTrial,
      isAnnualPlan,
      savedCard: newConstructedPlanObj.savedCard,
    };

    if (planObj.memberSubscription) {
      const renewalDate =
        planObj.memberSubscription && planObj.memberSubscription.renewalDate
          ? planObj.memberSubscription.renewalDate
          : '';
      newConstructedPlanObj.planRenewsDate =
        renewalDate !== '' ? Utils.getFormattedDate(renewalDate) : 'No Plans';

      newConstructedPlanObj.planStartDate = planObj.memberSubscription
        ?.startDate
        ? Utils.getFormattedDate(planObj.memberSubscription?.startDate)
        : 'No Plans';

      newConstructedPlanObj.price = planObj.memberSubscription?.price || 0;
    }
  }

  return newConstructedPlanObj;
}

function ManageBilling() {
  const { userInfo } = userDetail;
  const { currentPlanDisplayName } = userInfo;
  const [planData, setPlanData] = useState(constructedPlanObj);

  const isFreeUser = planData.currentPlan === '';

  const updateCardDetails = () => {
    stripe.handler.open({
      name: 'Adapt.io',
      description: 'Add new card',
    });
  };

  // eslint-disable-next-line no-undef
  stripe.handler = StripeCheckout.configure({
    key: CONSTANTS.STRIPE_KEY,
    image: logo,
    color: 'black',
    locale: 'auto',
    panelLabel: 'Add Card',
    zipCode: true,
    billingAddress: true,
    token(token) {
      stripe.sendStripeTokenToServer(token);
    },
  });

  stripe.sendStripeTokenToServer = (token) => {
    // eslint-disable-next-line no-undef
    window.showSpinner = true;

    const config = {
      url: URLS.sendStripeTokenToServer ? URLS.sendStripeTokenToServer : '',
      method: 'POST',
      params: { stripeCardDetails: JSON.stringify(token) },
    };
    makeApi(config)
      .then((response) => {
        // console.log('myLeadsList response  ', response)
        if (response !== null && response.data.hasUpdated) {
          profileState.getSavedCard();
          toasterState.setToastMsg(
            'Card updated successfully',
            CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
          );
          window.showSpinner = false;
        } else if (
          response !== null &&
          response.data !== null &&
          response.data.error === 'address failure'
        ) {
          toasterState.setToastMsg(
            'Card update failed. Address validation failed.',
          );
        } else {
          toasterState.setToastMsg(
            'Card update failed. Please contact us at <a href="mailto:support@adapt.io">support@adapt.io.</a>',
          );
        }
      })
      .catch((error) => {
        console.log('myLeadsList error ', error);
      });
  };

  const fetchBillingData = () => {
    profileState.setLoading(true);

    const savedCardPromise = profileState.getSavedCard();
    Promise.allSettled([savedCardPromise]).then(() => {
      profileState.setLoading(false);
    });
  };

  useEffect(() => {
    fetchBillingData();
  }, []);

  useEffect(() => {
    setPlanData(constructPlanData(userDetail.userInfo));
  }, [userDetail.userInfo, profileState.savedCardData]);

  return (
    <>
      <Title title="Manage Billing" />
      {!userInfo.isEnterpriseAdmin &&
        !userDetail.userInfo.memberSubscription === null && (
          <Navigate to="/profile/settings" replace />
        )}
      <div className="manage-billing-page">
        <div className="manage-billing-title">Subscription Details</div>
        <div className="subscription-details-card">
          <div className="plan-wrapper">
            <div
              className={`left-section${isFreeUser ? ' free-user' : ''}${
                planData.isFreeTrial ? ' free-trial' : ''
              }${planData.savedCard ? ' has-card' : ''}`}
            >
              <div className="plan-details-wrapper">
                <div className="plan-name-wrapper">
                  <span className="value">
                    {isFreeUser
                      ? 'Forever FREE'
                      : planData.isFreeTrial
                        ? `${currentPlanDisplayName} TRIAL ${
                            planData.isAnnualPlan ? '(Annual)' : '(Monthly)'
                          }`
                        : planData.currentPlan}
                  </span>
                </div>
                {planData.currentPlan && (
                  <span className="plan-status">Active</span>
                )}
              </div>
              {planData.currentPlan !== '' && (
                <div className="plan-period-wrapper">
                  <div className="label-value-wrapper">
                    <span className="label">
                      {planData.isFreeTrial
                        ? 'Trial Start Date'
                        : 'Purchase Date'}
                    </span>
                    <span className="value">{planData.planStartDate}</span>
                  </div>
                  <div className="label-value-wrapper">
                    <span className="label">
                      {planData.isFreeTrial ? 'Trial End Date' : 'Renewal Date'}
                    </span>
                    <span className="value">{planData.planRenewsDate}</span>
                  </div>
                  {!planData.isFreeTrial && (
                    <div className="label-value-wrapper">
                      <span className="label">Renewal Cycle</span>
                      <span className="value">{Utils.getPlanType()}</span>
                    </div>
                  )}
                  {planData.price > 0 && (
                    <div className="label-value-wrapper">
                      <span className="label">Price</span>
                      <span className="value">
                        ${Utils.numberFormat(planData.price)}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {(planData.isFreeTrial || isFreeUser) && (
                <Link to="/pricing" className="button choose-plan">
                  Choose Plan
                </Link>
              )}
            </div>
            {planData.savedCard && (
              <div className="card-wrapper">
                <img src={CardImg} alt="card" className="card-image" />
                <div className="card-details">
                  <span className="label">Card Details</span>
                  <span className="value">****{planData.savedCard}</span>
                </div>
                <Button
                  buttonProps={{
                    text: 'Update Card Details',
                    className: 'update-card-details',
                  }}
                  variant="outline"
                  onClick={updateCardDetails}
                />
              </div>
            )}
          </div>
          <div className="upgrade-plan-wrapper">
            <div className="title-text">Upgrade Plan</div>
            <div className="message">
              Contact <a href="mailto:sales@adapt.io">sales@adapt.io</a> to
              upgrade your plan.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(ManageBilling);
