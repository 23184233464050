/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { observer } from 'mobx-react';
import AdaptLogo from 'Assets/svg/new_icons/adapt-logo.svg';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import fieldMappingState from 'Stores/fieldMapping';
import Utils from 'Utils/utils';
import { DO_NOT_MAP_FIELD } from 'Utils/constants';
import Select from 'Components/common/base/Select';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import './styles.scss';

const PicklistMappingWrapper = observer((props) => {
  const { currentCRMData } = props;
  // const { picklistFieldMappingData } = fieldMappingState;
  // const { adaptFieldLabel = 'Title' } = picklistFieldMappingData;

  const CRMLogo = currentCRMData?.imgComponent;

  const processSelectedCrmField = (selectedKey, fieldData) => {
    const isDoNotMap = selectedKey === DO_NOT_MAP_FIELD.value;

    fieldMappingState.updateCustomPicklistFieldMappings(
      isDoNotMap,
      selectedKey,
      fieldData,
    );
  };

  return (
    <div className="picklist-mapping-wrapper">
      <div className="info-msg">
        In order to match the Adapt's value to your CRM's value, Please select
        the corresponding mapping below. <br />
      </div>

      <div className="field-mapping-wrapper no-enrich-pref">
        <div className="field-header">
          <div className="title-wrap adapt">
            <AdaptLogo className="logo" />
            <span>Adapt</span>
          </div>
          <div className="title-wrap crm-wrap">
            {currentCRMData.isSvg ? (
              <CRMLogo className="logo" />
            ) : (
              <img src={CRMLogo} alt={currentCRMData.title} className="logo" />
            )}
            <span>{currentCRMData.title}</span>
          </div>
        </div>
        <div className="field-body">
          {fieldMappingState.picklistFieldMappings?.map((fieldData) => {
            const { adaptLabel, crmKey, crmLabel } = fieldData;

            return (
              <div className="field" key={adaptLabel}>
                <div className="field-inner-wrap">
                  <div className="adapt-field">
                    <div>
                      <TickIcon
                        className={`tick-icon${crmKey ? '' : ' not-selected'}`}
                      />
                      {adaptLabel}
                    </div>
                  </div>
                  <div className="crm-field">
                    <div className="crm-field-select-wrapper">
                      <Select
                        enableValuesOutsideOfOptions
                        searchInputProps={{
                          placeholder: 'Search Field',
                        }}
                        value={crmLabel}
                        enableSearch
                        dropdownOffset={{ top: 220, bottom: 0 }}
                        showPlaceholderInOptions={false}
                        placeholder="Yet to be mapped"
                        onChange={(value) => {
                          const selectedKey = value;
                          processSelectedCrmField(selectedKey, fieldData);
                        }}
                        options={
                          fieldMappingState.availablePicklistCRMFields?.length >
                          0
                            ? [
                                ...(fieldMappingState.availablePicklistCRMFields?.map(
                                  (data) => {
                                    return {
                                      label: data.crmLabel,
                                      value: data.crmKey,
                                    };
                                  },
                                ) ?? []),
                                ...[DO_NOT_MAP_FIELD],
                              ]
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

const PickListActionWrapper = observer((props) => {
  const { handleCancel = () => {} } = props;
  const handleSave = () => {
    const isSaveAllowed =
      fieldMappingState.picklistFieldMappingData?.customMappingOptions?.length >
      0;
    if (isSaveAllowed) {
      const parentMappingObj = Utils.copyObjectWithoutReference(
        fieldMappingState.picklistFieldMappingData?.parentMappingObj,
      );
      parentMappingObj.options =
        fieldMappingState.picklistFieldMappingData?.customMappingOptions;
      fieldMappingState.updateCustomFieldMappings(parentMappingObj);
      fieldMappingState.setPicklistFieldMappingData(null);
    }
  };

  return (
    <>
      <Button
        variant="outline"
        buttonProps={{
          text: 'Cancel',
        }}
        onClick={handleCancel}
      />
      <Button
        onClick={handleSave}
        buttonProps={{
          text: 'Save',
          disabled:
            fieldMappingState.picklistFieldMappingData?.customMappingOptions
              ?.length === 0,
        }}
      />
    </>
  );
});

function PicklistMappingPopup(props) {
  const { picklistFieldMappingData } = fieldMappingState;
  const {
    adaptFieldLabel = 'Title',
    isInitialMapping = false,
    parentMappingObj,
  } = picklistFieldMappingData;

  const closeClick = () => {
    if (isInitialMapping) {
      // Remove picklist selections for new picklist mapping
      const { adaptKey, adaptLabel } = parentMappingObj;
      const updateObj = {
        adaptKey,
        adaptLabel,
        crmLabel: DO_NOT_MAP_FIELD.label,
        crmKey: DO_NOT_MAP_FIELD.value,
      };

      fieldMappingState.updateCustomFieldMappings(updateObj);
    } else {
      // Reset to previous state
      fieldMappingState.updateCustomFieldMappings(parentMappingObj);
    }
    fieldMappingState.setPicklistFieldMappingData(null);
  };

  return (
    <Modal show closeModal={closeClick} className="picklist-mapping-popup">
      <Modal.Title>{`Value Mapping - ${adaptFieldLabel}`}</Modal.Title>
      <Modal.Content>
        <PicklistMappingWrapper {...props} />
      </Modal.Content>
      <Modal.Footer>
        <PickListActionWrapper handleCancel={closeClick} />
      </Modal.Footer>
    </Modal>
  );
}

export default observer(PicklistMappingPopup);
