import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import PlusIcon from 'Assets/svg/plus-icon.svg';
import PrivateListIcon from 'Assets/svg/new_icons/account.svg';
import ListIcon from 'Assets/svg/header-icons/list.svg';
import SharedListIcon from 'Assets/svg/share-icon.svg';
import Button from 'Components/common/base/Button';
import Pagination from 'Components/common/pagination';
import { toasterState } from 'Components/common/base/Toaster';
import LoadingWrapper from 'Components/common/base/Loader';
import Title from 'Components/common/base/Title';
import PageContentHeader from 'Components/common/PageContentHeader';
import ListNamePopup, { listNamePopupState } from 'Pages/myLeads/ListNamePopup';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import { myLeadsList } from 'Utils/commonApi';
import Utils from 'Utils/utils';
import RenderLeadsList from './leadsList';
import './styles.scss';
import EmptyState from './EmptyState';

const tabs = [
  {
    text: 'All list',
    value: 'all',
    Icon: ListIcon,
    href: '/my-leads',
  },
  {
    text: 'My list',
    value: 'private',
    Icon: PrivateListIcon,
    href: '/my-leads?tab=all',
  },
  {
    text: 'Shared list',
    value: 'shared',
    Icon: SharedListIcon,
    href: '/my-leads?tab=shared',
  },
];

const createNewList = () => {
  const createSuccessCallback = (listId, listName, isSharedList) => {
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_LIST_CREATED, {
      listName,
    });
    toasterState.setToastMsg(
      'List created successfully',
      CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
    );
    userDetail.addNewListToMyList({
      id: listId,
      name: listName,
      isSharedList,
    });
  };

  listNamePopupState.callBack = createSuccessCallback;
  listNamePopupState.setActionMethod('create');
  listNamePopupState.setShowCreateList(true);
};

function MyLeads() {
  Utils.stopBodyScroll();

  const location = useLocation();

  const [activeListType, setActiveListType] = useState(
    new URLSearchParams(location.search).get('tab') || 'all',
  );
  const [pagination, setPagination] = useState({ page: 1, count: 50 });

  useEffect(() => {
    myLeadsList();
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_VIEWED, {});
  }, []);

  const changePageLimit = (val) => {
    setPagination({ count: val, page: 1 });
  };

  const changePage = (pageNo) => {
    setPagination({ ...pagination, page: pageNo + 1 });
  };

  const isSharedListTab = activeListType === 'shared';

  const showCreator = activeListType === 'shared' || activeListType === 'all';

  const items = userDetail.myLeadList?.filter((data) => {
    if (activeListType === 'all') {
      return true;
    }
    if (isSharedListTab) {
      return (
        data.isSharedList && data.memberId !== userDetail.userInfo.memberId
      );
    }
    return (
      !data.isSharedList ||
      (data.isSharedList && data.memberId === userDetail.userInfo.memberId)
    );
  });

  const currentPageItems = items?.filter((...args) => {
    return (
      args[1] >= (pagination.page - 1) * pagination.count &&
      args[1] < pagination.page * pagination.count
    );
  });

  const setActiveList = (type) => {
    if (activeListType !== type) {
      setActiveListType(type);
    }
  };

  return (
    <div className="my-leads">
      <Title title="My Leads" />
      <LoadingWrapper show={!userDetail.myLeadListLoaded} position="fixed" />
      <PageContentHeader
        tabData={{
          list: tabs.map((tab) => {
            return { ...tab, active: activeListType === tab.value };
          }),
          onClick: (value) => {
            setActiveList(value);
          },
        }}
      >
        <Button
          buttonProps={{ text: 'Add New List' }}
          onClick={createNewList}
          Icon={PlusIcon}
        />
      </PageContentHeader>
      <div
        className={`my-leads-list-container${
          userDetail.myLeadList.length <= 0 ? ' no-leads-list' : ''
        }${showCreator ? ' has-creator' : ''}`}
      >
        <div className="my-list-header">
          <ul className="my-list-header-inner">
            <li className="list-name">List Name </li>
            {showCreator && <li>Creator</li>}
            <li>Contacts</li>
            <li>Created On</li>
            <li>Last Purchased Date</li>
            <li> </li>
          </ul>
        </div>
        {userDetail.myLeadList.length <= 0 ? (
          <EmptyState />
        ) : isSharedListTab && items.length === 0 ? (
          <EmptyState
            showButton={false}
            title="There are no shared lists"
            description="The lists that are shared by your team members appear here"
          />
        ) : (
          <div className="leads-list-items">
            <div className="leads-list-items-inner">
              <RenderLeadsList
                showCreator={showCreator}
                list={currentPageItems}
              />
            </div>
          </div>
        )}
      </div>
      {userDetail.myLeadList.length > 0 && (
        <Pagination
          totalText="Total Lists"
          totalCount={items.length}
          rowsPerPage={pagination.count}
          currentPage={pagination.page}
          updateCurrentPage={changePage}
          onRowsPerPageUpdate={changePageLimit}
          rowsPerPageValues={[10, 25, 50, 100].map((data) => {
            return { label: data.toString(), value: data };
          })}
        />
      )}
      <ListNamePopup />
    </div>
  );
}

export default observer(MyLeads);
