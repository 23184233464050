import { observable, action } from 'mobx';
import { URLS, makeApi } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
// import { toasterState } from 'Components/common/base/Toaster';

const { MX_ONBOARDING_EVENTS, MX_PROFILE_PROPERTIES } = CONSTANTS;

const { LB_AP_POPUP_START_FREE_TRIAL_SUBMITTED } = MX_ONBOARDING_EVENTS;

const { AP_TRIAL_REQUEST_USER_TYPE } = MX_PROFILE_PROPERTIES;

class FreeTrialPopupState {
  @observable accessor freeTrialPrePopup = null;

  @observable accessor dynamicText = '';

  @action
  setFreeTrialPrePopup = (value) => {
    this.freeTrialPrePopup = value;
  };

  @action
  setDynamicText = (value = '') => {
    this.dynamicText = value;
  };

  processTrialRequest = async (failedCallback = () => {}) => {
    try {
      const config = {
        url: URLS.verifyLBTrialRequest,
        method: 'POST',
        data: {},
      };

      const response = await makeApi(config);

      const { data = null } = response;
      if (data) {
        const { responseCode = null } = data;
        if (responseCode === 200) {
          Utils.mixpanelUpdateProfileProperty({
            [AP_TRIAL_REQUEST_USER_TYPE]: 'Business',
          });
          Utils.mixpanelTrack(LB_AP_POPUP_START_FREE_TRIAL_SUBMITTED, {});
          Utils.setCookie('trial_activate_success', true);
          Utils.redirectToLbHomePage();
        } else {
          failedCallback();
        }
      } else {
        failedCallback();
      }
    } catch (e) {
      console.log('Error in processing trial request', e);
    }
  };
}

const freeTrialPopupState = new FreeTrialPopupState();

export default freeTrialPopupState;
