import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ZeroBounceLogo from 'Assets/png/zerobounce.png';
import MailIcon from 'Assets/svg/mailWithTick.svg';
import Modal from 'Components/common/base/Modal';
import ControlInput from 'Components/common/base/ControlInput';
import Button from 'Components/common/base/Button';
import { VALIDATION_STATUS } from 'Utils/constants';

function ZeroBounceValidation(props) {
  const { saveCallback, backAction, skipCallback, addToListLoading } = props;
  const [checkedStatuses, setCheckedStatuses] = useState(['valid']);
  const updateCheckboxValues = (name, checked) => {
    setCheckedStatuses(
      checked
        ? [...checkedStatuses, name]
        : checkedStatuses.filter((status) => {
            return status !== name;
          }),
    );
  };

  return (
    <>
      <Modal.Title>
        <MailIcon />
        <img src={ZeroBounceLogo} alt="ZeroBounce logo" />
      </Modal.Title>
      <Modal.Content>
        <p className="zb-description">
          Avoid the additional email verification step. Make use of our built-in
          ZeroBounce integration and Include only contacts with the below status
          in the list
        </p>
        <div className="checkbox-list">
          {VALIDATION_STATUS.map((status) => {
            const { text, value } = status;
            return (
              <ControlInput
                key={value}
                type="checkbox"
                label={text}
                checked={checkedStatuses.includes(value)}
                onChange={(checked) => {
                  updateCheckboxValues(value, checked);
                }}
              />
            );
          })}
        </div>
        <p className="note">
          Email addresses marked as <i>Invalid, Do not email and Spamtrap</i>{' '}
          will automatically be excluded from your list
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="transparent"
          buttonProps={{ text: 'Skip ZeroBounce', className: 'skip-btn' }}
          onClick={skipCallback}
        />
        <Button
          variant="outline"
          buttonProps={{ text: 'Back', className: 'back-btn' }}
          onClick={backAction}
        />
        <Button
          variant="primary"
          isLoading={addToListLoading}
          buttonProps={{ text: 'Done', disabled: checkedStatuses.length === 0 }}
          onClick={() => {
            saveCallback(checkedStatuses);
          }}
        />
      </Modal.Footer>
    </>
  );
}

export default observer(ZeroBounceValidation);
