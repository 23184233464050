import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';

function trackMixpanelEvent(inputData) {
  const {
    selectedValue,
    key,
    page,
    showExcludeToggle = false,
    isExcludeEnabled = false,
    mxUniqueKey,
    mxEventName,
  } = inputData;
  const { isFreeTrial } = userDetail;

  const data = {};
  data.filterPlace = 'popup';
  data.isFreeTrial = isFreeTrial;
  data.searchValue = selectedValue;
  data.currentTab = page;
  data.filterKey = key;

  if (showExcludeToggle) {
    data.isIncluded = !isExcludeEnabled;
  }

  if (mxUniqueKey) {
    data[mxUniqueKey] = selectedValue;
  }
  Utils.mixpanelTrack(mxEventName, data);
}

export default trackMixpanelEvent;
