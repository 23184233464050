import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import CalendarIcon from 'Assets/svg/calendar.svg';
import CloseIcon from 'Assets/svg/close-material.svg';
import { defaultDateValue, EMPTY_FUNCTION } from 'Utils/constants';
import { format } from 'date-fns';
import userDetail from 'Stores/userDetail';
import Button from '../base/Button';
import useOutsideClick from '../base/useOutsideClick';
import './styles.scss';

function DateRangePicker(props) {
  const {
    onClose,
    onSubmit,
    onCancel = EMPTY_FUNCTION,
    maxDate,
    defaultValue = defaultDateValue,
    closeIcon = true,
    editableDateInputs = true,
  } = props;

  const [showDateSelectionPopup, setShowDateSelectionPopup] = useState(false);
  const [dateRange, setDateRange] = useState({
    popup: defaultValue,
    value: defaultValue,
  });

  function handleClickOutside() {
    setShowDateSelectionPopup(false);
  }

  return (
    <div
      ref={useOutsideClick(handleClickOutside)}
      className={`filter-date${showDateSelectionPopup ? ' popup-open' : ''}`}
      onClick={() => {
        setShowDateSelectionPopup(true);
      }}
    >
      <CalendarIcon className="calendar-icon" />
      {dateRange.value.startDate !== null &&
      dateRange.value.endDate !== null ? (
        <span className="selected-date-range">
          {format(dateRange.value.startDate, 'dd MMM yyyy')} -{' '}
          {format(dateRange.value.endDate, 'dd MMM yyyy')}
          {closeIcon && (
            <CloseIcon
              title="Click here to clear date range"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
                setDateRange({
                  popup: defaultValue,
                  value: defaultValue,
                });
              }}
            />
          )}
        </span>
      ) : (
        <span className="filter-text">Filter by Date</span>
      )}
      {showDateSelectionPopup && (
        <div className="date-selection-popup">
          <div className="date-range-wrapper">
            <DateRange
              editableDateInputs={editableDateInputs}
              onChange={(item) => {
                setDateRange({ ...dateRange, popup: item.selection });
              }}
              moveRangeOnFirstSelection={false}
              ranges={[dateRange.popup]}
              startDatePlaceholder="From"
              endDatePlaceholder="To"
              minDate={
                new Date(
                  userDetail.userInfo.memberSubscription?.creationDate ||
                    userDetail.userInfo.creationTimeStamp,
                )
              }
              maxDate={maxDate || new Date()}
            />
          </div>
          <div className="button-wrapper">
            <Button
              variant="outline"
              buttonProps={{
                text: 'Cancel',
                disabled:
                  JSON.stringify(dateRange.popup) ===
                  JSON.stringify(dateRange.value),
              }}
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
                setDateRange({ ...dateRange, popup: dateRange.value });
                setShowDateSelectionPopup(false);
              }}
            />
            <Button
              buttonProps={{
                text: 'Apply',
                disabled:
                  JSON.stringify(dateRange.popup) ===
                  JSON.stringify(dateRange.value),
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowDateSelectionPopup(false);
                setDateRange({ ...dateRange, value: dateRange.popup });
                onSubmit(dateRange.popup);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DateRangePicker;
