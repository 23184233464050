import React from 'react';
import { useNavigate } from 'react-router-dom';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import Button from 'Components/common/base/Button';
import { NEW_INTEGRATION_NOT_AVAILABLE_CRMS } from 'Utils/constants';
import Menu from 'Components/common/base/Menu';
import './styles.scss';

function AdvanceActionWrapper(props) {
  const { sublinks, integrationType, disconnectCallback } = props;
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="advance-action-wrapper">
      <Menu align="end" showOnHover>
        {sublinks.length > 0 && (
          <>
            {sublinks.map((item, index) => {
              const { titleText, linkValue } = item;
              return (
                <Menu.Item
                  key={`advance-action-item-${integrationType}-${index + 1}`}
                  text={titleText}
                  onClick={() => {
                    handleClick(linkValue);
                  }}
                />
              );
            })}
          </>
        )}
        <Menu.Item
          className="disconnect-wrapper"
          text="Disconnect"
          onClick={(e) => {
            return disconnectCallback(e, integrationType);
          }}
        />
      </Menu>
    </div>
  );
}

function IntegrationItemCard(props) {
  const {
    dataMap = {},
    integrationType,
    isLoading = false,
    connectCallback = () => {},
    isConnected,
    disconnectCallback = () => {},
  } = props;

  const { title, description, sublinks = [] } = dataMap;

  return (
    <div
      className={`integration-item-wrapper ${integrationType.toLowerCase()}`}
    >
      <div className="title-wrapper">
        <div className="left-wrapper">
          <dataMap.imgComponent className="svg-icon" />
          <span>{title}</span>
        </div>
        {isConnected &&
          !NEW_INTEGRATION_NOT_AVAILABLE_CRMS.includes(integrationType) && (
            <AdvanceActionWrapper
              sublinks={sublinks}
              integrationType={integrationType}
              disconnectCallback={disconnectCallback}
            />
          )}
      </div>
      {description && <div className="description-wrapper">{description}</div>}

      {isConnected ? (
        <div className="connected-btn">
          <TickIcon />
          Connected
        </div>
      ) : (
        <Button
          buttonProps={{
            text: 'Connect',
            className: 'action-btn',
          }}
          isLoading={isLoading}
          onClick={(e) => {
            return connectCallback(e, integrationType);
          }}
        />
      )}
    </div>
  );
}

export default IntegrationItemCard;
