import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import SalesforceIcon from 'Assets/svg/export_icons/salesforce.svg';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import DottedArrow from 'Assets/svg/new_icons/dotted-arrow.svg';
import ControlInput from 'Components/common/base/ControlInput';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import fieldMappingState from 'Stores/fieldMapping';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';

let broadcastChannel;

function SuppressionListItem(props) {
  const {
    title,
    description,
    linkText,
    href,
    showLink = true,
    onClick = EMPTY_FUNCTION,
  } = props;
  return (
    <div className="suppression-list-filter-card">
      <p className="title">{title}</p>
      <p className="description">{description}</p>
      {showLink && (
        <a
          onClick={onClick}
          className="button outline"
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {linkText}
        </a>
      )}
    </div>
  );
}

function ContactExclusion() {
  const updatePreferences = (key) => {
    advSearchFilterPopupState.setCheckBoxFilters(key);
  };

  useEffect(() => {
    if (Object.keys(fieldMappingState.enrichmentSettingsData).length === 0) {
      fieldMappingState.getEnrichmentSettings('SALESFORCE');
    }
    return () => {
      broadcastChannel?.close?.();
    };
  }, []);

  const isSalesforceSuppressionEnabled =
    fieldMappingState.enrichmentSettingsData?.dont_purchase_if_present_in_crm ??
    false;

  return (
    <div className="contact-exclusion-wrapper">
      <ControlInput
        label="Do not display contacts that I own"
        checked={
          advSearchFilterPopupState.checkBoxFilters.dontDisplayOwnedContacts
        }
        type="checkbox"
        onChange={() => {
          updatePreferences('dontDisplayOwnedContacts');
        }}
      />
      <ControlInput
        label="Do not display contacts owned by my team"
        checked={
          advSearchFilterPopupState.checkBoxFilters.dontDisplayTeamContacts
        }
        type="checkbox"
        onChange={() => {
          updatePreferences('dontDisplayTeamContacts');
        }}
      />
      <SuppressionListItem
        title="Global Suppression List"
        description="Would you like to suppress a list of existing contacts who are your customers, competitors or from your current pipeline? Use the global suppression list, and exclude them from your search results"
        linkText="Go to Global Suppression List"
        href="/profile/suppression-list"
      />
      <SuppressionListItem
        title="Salesforce Suppression List"
        description="Configure your Salesforce integration settings to suppress your existing Leads/Contacts from the search results"
        linkText="Go to Salesforce Settings"
        href="/integrations/salesforce/settings"
        showLink={!isSalesforceSuppressionEnabled}
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          if ('BroadcastChannel' in self) {
            broadcastChannel = new BroadcastChannel('sf_exclude');
            broadcastChannel.onmessage = (e) => {
              if (e.data?.excluded) {
                fieldMappingState.getEnrichmentSettings('SALESFORCE');
              }
              broadcastChannel.close();
            };
          }
        }}
      />
      {isSalesforceSuppressionEnabled && (
        <div className="salesforce-connected">
          <SalesforceIcon className="salesforce-icon" />
          <DottedArrow className="arrow-icon" />
          <div className="enabled-wrapper">
            <TickIcon />
            <span>Enabled</span>
          </div>
        </div>
      )}
    </div>
  );
}

export { SuppressionListItem };

export default observer(ContactExclusion);
