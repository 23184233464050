import React from 'react';
import { observer } from 'mobx-react';
import RightArrow from 'Assets/svg/right-arrow.svg';
import NotificationSpeakerIcon from 'Assets/svg/notification-speaker.svg';
import TimerIcon from 'Assets/svg/timer-icon.svg';
import CloseIcon from 'Assets/svg/close-material.svg';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import trialUserOnboardingState from 'Stores/trialUserOnboardingState';
import Button from 'Components/common/base/Button';

const { MX_COMMON_EVENTS } = CONSTANTS;
const { LB_UPGRADE_CLICK } = MX_COMMON_EVENTS;

// Icon not updated as this currently not in use
const PaymentPendingReview = observer(() => {
  return (
    <div className="notification-bar-top payment-pending-review">
      <div className="notification-content">
        <NotificationSpeakerIcon width={30} height={24} className="info-icon" />
        Your account is currently being setup, You will receive a confirmation
        from the Adapt team soon.
      </div>
    </div>
  );
});

const TrialDaysLeft = observer(() => {
  const { trialEndDays } = trialUserOnboardingState;

  const handleCloseClick = () => {
    const callback = () => {
      userDetail.setShowNotificationType('');
    };
    trialUserOnboardingState.updateTrialOnboardingPopupActivity(
      false,
      { daysLeftPopupShown: 'COMPLETE' },
      callback,
    );
  };

  const handleUpgradeSoon = () => {
    Utils.mixpanelTrack(LB_UPGRADE_CLICK, { Source: 'banner' });
    Utils.redirectToLBPricingPage();
  };

  return (
    <div className="notification-bar-top trial-days-left-section">
      <div className="icon-wrap">
        <TimerIcon className="timer-icon" />
      </div>
      <div className="main-text">
        You have {`${trialEndDays} ${trialEndDays > 1 ? 'days' : 'day'}`} day
        remaining in trial. Please upgrade soon to power your prospecting
        efforts.
      </div>
      <Button
        Icon={RightArrow}
        buttonProps={{ className: 'upgrade', text: 'Upgrade' }}
        onClick={handleUpgradeSoon}
      />
      <Button
        variant="transparent"
        buttonProps={{ className: 'close-wrapper', text: <CloseIcon /> }}
        onClick={handleCloseClick}
      />
    </div>
  );
});

function NotificationBar(props) {
  const { notificationType = '' } = props;

  switch (notificationType) {
    case CONSTANTS.NOTIFICATION_BAR_TYPE.PAYMENT_PENDING_REVIEW:
      return <PaymentPendingReview />;
    case CONSTANTS.NOTIFICATION_BAR_TYPE.TRIAL_DAYS_LEFT_NOTIFICATION:
      return <TrialDaysLeft />;

    default:
      return '';
  }
}

export default observer(NotificationBar);
