import React from 'react';
import AdaptIcon from 'Assets/svg/adapt-logo.svg';
import './styles.scss';
import TwoIconConnector from '../TwoIconConnector';

function CRMExportHeader(props) {
  const { Logo, title, message } = props;

  return (
    <div className="crm-header-wrap">
      <TwoIconConnector firstIcon={AdaptIcon} secondIcon={Logo} />
      <div className="title">{title}</div>
      <div className="message">{message}</div>
    </div>
  );
}

export default CRMExportHeader;
