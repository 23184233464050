import React from 'react';
import { observer } from 'mobx-react';
import commonAppData from 'Stores/commonAppData';
import { MX_AS_EVENTS } from 'Utils/constants';
import AccordionCheckboxList from '../AccordionCheckboxList';

const isSelected = (data) => {
  const {
    appliedFilter,
    listData,
    suggestionKey,
    topList = { label: null },
  } = data;
  return appliedFilter?.[suggestionKey]?.some((object) => {
    return (
      object.name === listData.value && topList?.label === object.parentIndustry
    );
  });
};

function IndustrySector(props) {
  const { page, currentFilterState, selectedFilter } = props;
  return (
    <AccordionCheckboxList
      selectedFilter={selectedFilter}
      currentFilterState={currentFilterState}
      page={page}
      enableExclude
      dataList={commonAppData.industrySectorsFullData}
      suggestionKey="industrySectorsV2"
      excludeSuggestionKey="excludeIndustrySectorsV2"
      mxEventName={MX_AS_EVENTS.LB_AS_INDUSTRY_SEARCH_PERFORMED}
      getData={(data, industry) => {
        const { value } = data;
        return {
          id: value,
          industryOrSectorText: value,
          name: value,
          orgName: value,
          pName: value,
          parentIndustry: industry?.value || null,
        };
      }}
      isIncluded={isSelected}
      isExcluded={isSelected}
    />
  );
}

export default observer(IndustrySector);
