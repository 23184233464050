import React from 'react';
import { observer } from 'mobx-react';
import CalendarIcon from 'Assets/svg/calendar.svg';
import RightArrowIcon from 'Assets/svg/right-arrow.svg';
import Button from 'Components/common/base/Button';
import commonAppData from 'Stores/commonAppData';
import Utils from 'Utils/utils';
import { EXTERNAL_LINKS, MX_DASHBOARD_PAGE_EVENTS } from 'Utils/constants';
import './styles.scss';

function HelpSection() {
  return (
    <div className="dashboard-help-section">
      <h2>Have Questions?</h2>
      <p className="message">
        Talk to our expert team and learn more about sales prospecting, demand
        generation, and subscription plans.
      </p>
      <div className="sales-rep-list">
        {commonAppData.customerExecutiveList?.map((salesRepData) => {
          const { profileUrl, name, jobTitle } = salesRepData;
          return (
            <div className="sales-rep-card" key={name}>
              <div className="image-wrap">
                <img src={profileUrl} alt={name} />
              </div>
              <div className="info-section">
                <p className="name">{name}</p>
                <p className="title">{jobTitle}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        variant="outline"
        buttonProps={{
          className: 'talk-to-us',
          text: (
            <>
              <CalendarIcon />
              Talk to Us
              <RightArrowIcon />
            </>
          ),
        }}
        onClick={() => {
          Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.TALK_TO_US_CLICKED);
          window.open(EXTERNAL_LINKS.CUSTOM_PRICING_MEET, '_blank');
        }}
      />
    </div>
  );
}

export default observer(HelpSection);
