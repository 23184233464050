import React from 'react';
import LockIcon from 'Assets/svg/new_icons/lock.svg';
import Modal from 'Components/common/base/Modal';
import './styles.scss';

function ChooseExportModal(props) {
  const { list, closeModal, title } = props;

  return (
    <Modal show closeModal={closeModal} className="choose-export-modal">
      <Modal.Title>{title}</Modal.Title>
      <Modal.Content>
        <div className="export-list">
          {list.map((listItem) => {
            const { text, callback, optionEnabled, imageURL, Icon } = listItem;
            return (
              <div
                className={`export-option${optionEnabled ? '' : ' blocked-wrap'}`}
                onClick={() => {
                  callback(text, optionEnabled);
                }}
                key={text}
              >
                {optionEnabled === false && <LockIcon className="lock-icon" />}
                <div className="option-image-wrap">
                  {imageURL && <img src={imageURL} alt={text} />}
                  {Icon && <Icon />}
                </div>
                <div className="option-title">{text}</div>
              </div>
            );
          })}
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default ChooseExportModal;
