import { observable, action, computed } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import { toasterState } from 'Components/common/base/Toaster';
import crmIntegrationState from './crmIntegration';
import userDetail from './userDetail';
import { MX_ENRICHMENT_EVENTS } from '../utils/constants';

const enrichmentSteps = {
  CONNECT: false,
  FIELD_MAPPING: false,
  SETTINGS: false,
};

const adaptFieldsForCSVEnrichment = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Domain', value: 'domain' },
  { label: 'Email', value: 'email' },
  {
    label: 'Linkedin URL',
    value: 'linkedInURL',
  },
];

const defaultFieldMapping = adaptFieldsForCSVEnrichment.map((data) => {
  return {
    adaptKey: data.value,
    adaptLabel: data.label,
    csvLabel: '',
  };
});

class Enrichment {
  // for generic three dot loader any APIs
  @observable accessor isEnrichmentLoading = false;

  // individual state for showing placeholder in respective components
  @observable accessor loaderState = {
    isGetEnrichmentLoading: false,
  };

  @observable accessor enrichmentLogHistory = [];

  @observable accessor enrichmentLogCount = 0;

  fileEnrichmentCSVFields = [];

  fileEnrichmentSampleData = {};

  @observable accessor fileEnrichmentFieldMapping = defaultFieldMapping;

  @observable accessor fileEnrichmentList = [];

  @observable accessor fileEnrichmentListCount = 0;

  fileEnrichmentIdForFieldMapping = '';

  @observable accessor fileEnrichmentLoader = false;

  @observable accessor showFileEnrichmentInitiatedPopup = false;

  @computed
  get isLoading() {
    return this.isEnrichmentLoading || crmIntegrationState.isLoading;
  }

  @action
  setLoading(value) {
    this.isEnrichmentLoading = value;
  }

  @action
  getEnrichmentLog = async (data) => {
    this.loaderState.isGetEnrichmentLoading = true;
    const newData = {
      ...data,
      crmSettingsId: userDetail.userInfo?.memberSubscription?.crmSettingsId,
    };
    if (!data.page) {
      newData.page = 1;
    }
    if (!data.count) {
      newData.count = 50;
    }
    const url = Utils.generateUrl(URLS?.getEnrichmentLogs, newData);
    const config = {
      url,
      method: 'GET',
    };
    const response = await makeApi(config);
    if (response && response.data && response.data.responseData !== null) {
      this.enrichmentLogHistory = response.data.responseData;
    }
    this.loaderState.isGetEnrichmentLoading = false;
  };

  @action
  getEnrichmentLogCount = async (data) => {
    const newData = {
      ...data,
      crmSettingsId: userDetail.userInfo?.memberSubscription?.crmSettingsId,
    };
    const url = Utils.generateUrl(URLS?.getEnrichmentLogsCount, newData);
    const config = {
      url,
      method: 'GET',
    };
    const response = await makeApi(config);
    if (response && response.data && response.data.responseData !== null) {
      this.enrichmentLogCount = response.data.responseData;
    }
  };

  downloadEnrichmentLogs = async (data) => {
    this.isEnrichmentLoading = true;
    const newData = {
      ...data,
      crmSettingsId: userDetail.userInfo?.memberSubscription?.crmSettingsId,
    };
    const url = Utils.generateUrl(URLS?.downloadEnrichmentLogs, newData);
    const config = {
      url,
      method: 'GET',
    };
    const response = await makeApi(config);
    const isInitiated =
      response && response.data && response.data.responseCode === 200;
    this.isEnrichmentLoading = false;
    if (!isInitiated) {
      toasterState.setToastMsg(
        'Something went wrong. Please try again. If the problem persists, please write to us at <a href="mailto:support@adapt.io" target="_blank">support@adapt.io</a>',
        'failure',
      );
    }
    return isInitiated;
  };

  @action
  setFileEnrichmentFieldMapping = (data) => {
    this.fileEnrichmentFieldMapping = data;
  };

  @action
  getFileEnrichmentList = async (data) => {
    const { page, limit } = data;
    const url = Utils.generateUrl(URLS?.fileEnrichment.getEntries, {
      page,
      limit,
    });
    const config = {
      url,
      method: 'GET',
    };
    const response = await makeApi(config);
    if (response.status === 200) {
      this.fileEnrichmentList = response.data.responseData;
      this.fileEnrichmentListCount = response.data.total;
    }
  };

  uploadCSV = async (data) => {
    const { postBody } = data;
    const config = {
      url: URLS.fileEnrichment.uploadCSV,
      method: 'POST',
      data: postBody,
    };
    const response = await makeApi(config, true, undefined, true, {
      sendContentType: false,
    });

    let isSuccess = false;
    if (response.status === 200) {
      this.fileEnrichmentIdForFieldMapping = response.data.id;
      isSuccess = true;
    } else {
      Utils.showRefreshAndContactSupportPopup();
    }
    return isSuccess;
  };

  @action
  updateFileEnrichmentMapping = async (data) => {
    const { pagination } = data;
    this.fileEnrichmentLoader = true;
    const config = {
      url: URLS?.fileEnrichment.updateFieldMapping,
      method: 'POST',
      data: {
        id: this.fileEnrichmentIdForFieldMapping,
        fieldMapping: this.fileEnrichmentFieldMapping.reduce(
          (output, mapping) => {
            if (!mapping.csvLabel) {
              return output;
            }
            return {
              ...output,
              [mapping.adaptKey]: {
                name: mapping.csvLabel,
                index: this.fileEnrichmentCSVFields.indexOf(mapping.csvLabel),
              },
            };
          },
          {},
        ),
      },
    };
    const response = await makeApi(config);
    this.fileEnrichmentLoader = false;
    this.resetFileEnrichmentState();
    if (response.status === 200) {
      Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.FILE_ENRICHMENT_CREATED);
      this.getFileEnrichmentList(pagination);
    } else {
      Utils.showRefreshAndContactSupportPopup();
    }
  };

  resetFileEnrichmentState = () => {
    this.fileEnrichmentCSVFields = [];
    this.fileEnrichmentFieldMapping = defaultFieldMapping;
    this.fileEnrichmentIdForFieldMapping = '';
    this.fileEnrichmentSampleData = {};
  };

  @action
  setEnrichmentStepsCompleted = async (data) => {
    const url = URLS?.updateEnrichmentSteps;
    const newData = {
      ...enrichmentSteps,
      ...(userDetail.userInfo.hubspotEnrichmentSteps || {}),
      ...data,
    };
    const config = {
      url,
      method: 'POST',
      data: newData,
    };
    const response = await makeApi(config);
    if (response.status === 200) {
      userDetail.updateUserInfo({
        hubspotEnrichmentSteps: newData,
      });
    }
  };
}

const enrichmentState = new Enrichment();

export { adaptFieldsForCSVEnrichment, enrichmentSteps };
export default enrichmentState;
