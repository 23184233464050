import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { RouterProvider } from 'react-router-dom';
import LoadingWrapper from 'Components/common/base/Loader';
import Toaster from 'Components/common/base/Toaster';
import PaidUserWelcomePopup from 'Components/common/PaidUserWelcomePopup';
import FreeUserPaginationPopup from 'Components/common/FreeUserPaginationPopup';
import G2ReviewPopup from 'Components/common/G2ReviewPopup';
import UpdateUserEmailPopup from 'Components/common/UpdateUserEmail';
import ProspectorFreeTrialPopup from 'Components/common/ProspectorFreeTrialPopup';
import MaintenancePage from 'Pages/maintenance';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { loginValidate } from 'Utils/commonApi';
import router from './routes';
import './App.scss';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { showActivateAccountPage, showPasswordSetupPage, userInfo } =
    userDetail;
  const { showMaintenanceScreen } = userInfo;
  const { freeTrialPrePopup = false } = freeTrialPopupState;

  const loginCheck = async () => {
    await loginValidate(true, true);

    Utils.registerMixpanel();
    setTimeout(() => {
      setIsLoggedIn(true);
      Utils.addSessionRecordingForTrialUser();
      // Utils.addSessionRecordingForLbPaidUser();
      Utils.checkAndShowAccountActivationToast();
      Utils.checkAndShowTrialActivationToast();
      Utils.setRefinerProject();
    }, 500);
  };

  useEffect(() => {
    const { location } = window;
    const searchParams = new URLSearchParams(location.search);
    const skipMaintenanceScreen = searchParams.get('skip_mc');
    if (skipMaintenanceScreen) {
      Utils.setLocalStorage('skip_mc', JSON.stringify(skipMaintenanceScreen));
    }
    loginCheck();
  }, []);

  const skipMaintenanceScreen = Utils.getLocalStorage('skip_mc', false);

  return showMaintenanceScreen && skipMaintenanceScreen !== 'true' ? (
    <MaintenancePage />
  ) : !isLoggedIn ? (
    <LoadingWrapper show={!isLoggedIn} position="fixed" />
  ) : (
    <>
      {Utils.getUserType() === 'FREE' && <FreeUserPaginationPopup />}
      <PaidUserWelcomePopup />
      <Toaster />
      <G2ReviewPopup />
      {/* UpdateUserEmailPopup - should actually come under activation page? */}
      <UpdateUserEmailPopup />

      {freeTrialPrePopup && <ProspectorFreeTrialPopup />}
      <div
        className={`content-wrapper${
          showActivateAccountPage ? ' activate-account-flow' : ''
        }${showPasswordSetupPage ? ' password-setup-flow' : ''}`}
      >
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default observer(App);
