import React from 'react';
import { Link } from 'react-router-dom';
import MyListEmptyImg from 'Assets/png/my-list-empty.png';
import { PAGE_PATH_MAP } from 'Utils/constants';
import './styles.scss';

function EmptyState(props) {
  const {
    title = 'You have not created any lists yet.',
    description = 'Start creating your first list by adding contacts to it.',
    showButton = true,
  } = props;

  return (
    <div className="my-leads-empty-state">
      <img src={MyListEmptyImg} alt="my leads list illustration" />
      <div className="no-data-text">
        {title && <div className="no-data-title">{title}</div>}
        <div>{description}</div>
      </div>
      {showButton && (
        <div className="no-list-action-wrap">
          <Link to={PAGE_PATH_MAP.CONTACT_SEARCH} className="button">
            Go to Contact Search
          </Link>
        </div>
      )}
    </div>
  );
}

export default EmptyState;
