import React from 'react';
import Utils from 'Utils/utils';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Button from 'Components/common/base/Button';
import { exportCompaniesState } from 'Components/common/exportCompanies/exportCompanies';
import Pagination from 'Components/common/pagination';
import SelectionDropdown from 'Pages/prospectSearch/SelectionDropdown';
import { showUnableToDeselectModal } from 'Pages/prospectSearch/contactSearch/modals';
import userDetail from 'Stores/userDetail';
import companySearchState from 'Stores/companySearch';
import advSearchState from 'Stores/advSearch';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

function MiddleSection(props) {
  const { savedSearchId } = props;
  const [rangeBoxValue, setRangeBoxValue] = React.useState('');

  const navigate = useNavigate();
  const allowedMaxSelection =
    companySearchState.searchResultCount >
    companySearchState.maxSelectionAllowed
      ? companySearchState.maxSelectionAllowed
      : companySearchState.searchResultCount;

  const clearSelections = () => {
    setRangeBoxValue('');
    companySearchState.updateSelectedBulkContactAll(true);
  };

  const applySelection = () => {
    setRangeBoxValue('');
    companySearchState.selectHugeContacts(rangeBoxValue);
  };

  const rangeValueChange = (value) => {
    setRangeBoxValue(value);
  };

  const findMatchingContacts = () => {
    if (
      companySearchState.hugeSelectionDomain.length > 0 ||
      companySearchState.selectedCompanyDomain.length > 0
    ) {
      const companyPostData = {
        companyName:
          companySearchState.hugeSelectionDomain.length > 0
            ? companySearchState.hugeSelectionDomain
            : companySearchState.selectedCompanyDomain,
        limit: 50,
      };
      companySearchState.clearAllSelection();
      Utils.setLocalStorage('searchJson', companyPostData);
      navigate('/advanced-search/contact#search');
      advSearchState.setCurrentPage(0, '', true);
      advSearchState.retainSearch();
      userDetail.setCurrentTab('contact');
    }
  };

  const exportCompanyClick = () => {
    if (
      companySearchState.bulkPurchaseIds.length ||
      companySearchState.hugeSelectionBulkIds.length
    ) {
      Utils.mixpanelTrack(CONSTANTS.MX_LB_EXPORT_EVENTS.LB_CLICK_EXPORT, {
        ...exportCompaniesState.exportMxData,
      });
      exportCompaniesState.setShowCrmExportOptions(true);
    }
  };

  const changePageLimit = (val) => {
    companySearchState.setPageLimit(val);
  };

  const changePage = (pageNo, opr) => {
    if (
      companySearchState.selectedAllContactAcrossPage ||
      companySearchState.selectedContactFromRange
    ) {
      const deselectCallback = () => {
        companySearchState.updateSelectedBulkContactAll(true);
      };
      showUnableToDeselectModal(deselectCallback);
    } else {
      companySearchState.setCurrentPage(pageNo, opr);
    }
  };

  const disableButton = !(
    companySearchState.hugeSelectionBulkIds.length > 0 ||
    companySearchState.bulkPurchaseIds.length > 0
  );

  return (
    companySearchState.searchResults.length > 0 && (
      <div className="company-middle-section">
        <div className="left-section">
          <SelectionDropdown
            className={
              companySearchState.resultLoading ||
              (savedSearchId && companySearchState.searchResults.length === 0)
                ? 'disable'
                : ''
            }
            allSelected={companySearchState.allContactSelected}
            selectedCount={
              companySearchState.hugeSelectionBulkIds.length ||
              companySearchState.bulkPurchaseIds.length
            }
            maxLimit={allowedMaxSelection}
            rangeBoxValue={rangeBoxValue}
            rangeValueChange={rangeValueChange}
            applySelection={applySelection}
            clearSelections={clearSelections}
            enableLimitContactPerCompany={false}
            inputLabel="Select number of companies"
          />
          <Button
            buttonProps={{ text: 'Export', disabled: disableButton }}
            onClick={exportCompanyClick}
          />
          <Button
            variant="outline"
            buttonProps={{
              text: 'Find Matching Contacts',
              disabled: disableButton,
            }}
            onClick={findMatchingContacts}
          />
        </div>
        <div className="right-content">
          {companySearchState.searchResultCount > 0 &&
            !companySearchState.resultLoading && (
              <div className="leads-count">
                {Utils.usNumberFormatForCompanyCount(
                  companySearchState.searchResultCount,
                )}
                &nbsp;
                {companySearchState.searchResultCount > 1
                  ? 'companies'
                  : 'company'}{' '}
                found
              </div>
            )}
          {companySearchState.searchResultCount > 0 && (
            <Pagination
              totalCount={companySearchState.searchResultCount}
              rowsPerPage={companySearchState.pageLimit}
              currentPage={companySearchState.currentPage + 1}
              updateCurrentPage={changePage}
              onRowsPerPageUpdate={changePageLimit}
              pageName="advSearchCompany"
              showTotalLogs={false}
            />
          )}
        </div>
      </div>
    )
  );
}

export default observer(MiddleSection);
