/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Utils from 'Utils/utils';
import { observer } from 'mobx-react';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import PurchaseIcon from 'Assets/svg/new_icons/tick-outline.svg';
import ExportIcon from 'Assets/svg/new_icons/document-success.svg';
import NewTabIcon from 'Assets/svg/new-tab.svg';
import advSearchState from 'Stores/advSearch';
import Modal from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

function UserEmailElement({ userEmail }) {
  return (
    <a href={`mailto:${userEmail}`} target="_blank" rel="noopener noreferrer">
      {userEmail}
    </a>
  );
}

function ErrorDescription() {
  const mailElement = <UserEmailElement userEmail="support@adapt.io" />;
  return (
    <>
      Please try again after sometime. <br />
      Contact {mailElement} if the problem persists
    </>
  );
}

function Description(props) {
  const { userEmail, flags } = props;
  const { isPurchase, isCSVExport, isCRMExport } = flags;

  const userEmailElement = <UserEmailElement userEmail={userEmail} />;
  const fewMinutesText = 'Usually, this takes a few minutes.';
  return isPurchase ? (
    <>
      You'll receive an email notification when the list is ready.{' '}
      <a href="/profile/purchase-history" target="_blank">
        Track progress in Purchase History. <NewTabIcon />
      </a>
    </>
  ) : isCSVExport ? (
    <>
      We will email you at {userEmailElement} with the link to download the file
      once the export is complete.
      <br /> Usually, this takes a few minutes.
    </>
  ) : isCRMExport ? (
    <>
      We will email you at {userEmailElement} once the export is complete.{' '}
      {fewMinutesText}
    </>
  ) : (
    ''
  );
}

function RealTimeVerificationMessage() {
  return (
    <>
      The actual count may vary post verification. Don&#39;t worry! Your credits
      will be deducted only for the verified contacts.
    </>
  );
}

function WithoutEmailMessage() {
  return <>Contacts without email addresses will not be exported.</>;
}

function NoteList(props) {
  const { showVerificationNote, showWithoutEmailNote } = props;

  return (
    <section className="note-wrapper">
      <div className="note-title">Note :</div>
      <ol
        className={
          showVerificationNote && showWithoutEmailNote ? '' : 'no-list-style'
        }
      >
        {showVerificationNote && (
          <li>
            <RealTimeVerificationMessage />
          </li>
        )}
        {showWithoutEmailNote && (
          <li>
            <WithoutEmailMessage />
          </li>
        )}
      </ol>
    </section>
  );
}

function AsyncPopup() {
  const { asyncPurchaseOrExportMsg } = advSearchState;

  const handleHoldSuccessOkClick = () => {
    advSearchState.setAsyncPurchaseOrExportMsg('');
  };
  const userEmail = Utils.getUserEmail();

  const isPurchase = asyncPurchaseOrExportMsg === 'PURCHASE';
  const isCSVExport = asyncPurchaseOrExportMsg === 'CSV_EXPORT';
  const isCRMExport = asyncPurchaseOrExportMsg === 'CRM_EXPORT';
  const isError = asyncPurchaseOrExportMsg === 'UNKNOWN_ERROR';

  const titleText = isPurchase
    ? 'Purchase Initiated'
    : isCSVExport
      ? 'CSV Export Initiated'
      : isCRMExport
        ? 'Export Initiated'
        : isError
          ? 'Aaah! Something went wrong'
          : '';

  const Icon = isError ? WarningIcon : isCSVExport ? ExportIcon : PurchaseIcon;

  const flags = { isPurchase, isCSVExport, isCRMExport };

  const showVerificationNote = isPurchase;

  const showWithoutEmailNote = isCRMExport;

  const showNoteList = showWithoutEmailNote || showVerificationNote;

  return (
    <Modal
      className="async-popup"
      closeModal={handleHoldSuccessOkClick}
      show={asyncPurchaseOrExportMsg !== ''}
      align={isError ? 'left' : 'center'}
    >
      {Icon && <Modal.Icon SvgIcon={Icon} />}
      <Modal.Title>{titleText}</Modal.Title>
      <Modal.Content>
        {isError ? (
          <ErrorDescription />
        ) : (
          <>
            <Description flags={flags} userEmail={userEmail} />
            {showNoteList && (
              <NoteList
                showWithoutEmailNote={showWithoutEmailNote}
                showVerificationNote={showVerificationNote}
              />
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button
          buttonProps={{ text: 'Ok' }}
          onClick={handleHoldSuccessOkClick}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default observer(AsyncPopup);
