import DotIcon from 'Assets/png/green-dot.png';

const lineChartOptions = {
  tension: 0.3,
  responsive: true,
  maintainAspectRatio: false,
  clip: false,
  scales: {
    x: {
      offset: true,
      grid: {
        display: false,
      },
      ticks: {
        color: 'rgb(47, 46, 49)',
        callback(value) {
          return `${this.getLabelForValue(value)
            .split(' ')
            .reduce((output, item, index) => {
              if (index === 0) {
                return item.slice(0, 3);
              }
              return `${output} ${item}`;
            }, '')}`;
        },
      },
    },
    y: {
      ticks: {
        maxTicksLimit: 6,
        color: 'rgb(47, 46, 49)',
        padding: 10,
      },
      beginAtZero: true,
      grid: {
        drawBorder: false,
      },
    },
  },
  elements: {
    point: {
      pointStyle: () => {
        const img = new Image();
        img.width = 12;
        img.height = 12;
        img.src = DotIcon;
        return img;
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      position: 'top',
    },
  },
};

const barChartTooltip = (context) => {
  console.log(context);
};

const barChartOptions = {
  responsive: true,
  categoryPercentage: 1,
  datasets: {
    bar: {
      borderRadius: 4,
      barPercentage: 0.7,
      maxBarThickness: 15,
      skipNull: true,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      offset: true,
      ticks: {
        color: 'rgb(47, 46, 49)',
        callback(value) {
          return `${this.getLabelForValue(value)
            .split(' ')
            .reduce((output, item, index) => {
              if (index === 0) {
                return item.slice(0, 3);
              }
              return `${output} ${item}`;
            }, '')}`;
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        maxTicksLimit: 6,
        color: 'rgb(47, 46, 49)',
        padding: 10,
      },
      grid: {
        drawBorder: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      position: 'bottom',
      labels: {
        padding: 20,
        boxWidth: 15,
        boxHeight: 10,
        color: 'rgb(47, 46, 49)',
        font: {
          size: 13,
          lineHeight: 1.33,
        },
      },
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      external: barChartTooltip,
    },
  },
};

export { lineChartOptions, barChartOptions };
