import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import LeadershipHireImg from 'Assets/png/leadership-hire.png';
import ControlInput from 'Components/common/base/ControlInput';
import { applyFilterValueForObjects } from 'Pages/prospectSearch/utils';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { MX_AS_EVENTS } from 'Utils/constants';
import trackMixpanelEvent from '../trackMixpanelEvent';
import UpgradeSection from '../UpgradeSection';
import './styles.scss';

function getValues(data) {
  const { list, appliedValues } = data;
  if (list?.length > 0) {
    return list.map((item) => {
      const label = item.name;
      return {
        label,
        checked: appliedValues?.includes(label) ?? false,
      };
    });
  }
  return [];
}

function LeadershipHire(props) {
  const { currentFilterState, page, selectedFilter } = props;

  const [filterListData, setFilterListData] = useState({ dept: [], level: [] });

  const filterStaticValues = useMemo(() => {
    return selectedFilter.getValues();
  }, []);

  function applyValue(data) {
    applyFilterValueForObjects({
      ...data,
      currentFilterState,
      suggestionKey: 'leadershipHire',
      mandatoryFields: ['months'],
    });

    trackMixpanelEvent({
      selectedValue: data.value,
      key: 'leadershipHire',
      page,
      mxUniqueKey: data.key,
      mxEventName: MX_AS_EVENTS.AS_LEADERSHIP_HIRE_SEARCH_PERFORMED,
    });
  }

  useEffect(() => {
    const deptList = getValues({
      list: advSearchFiltersState.filterListValues.department,
      appliedValues:
        currentFilterState.appliedFilterSectionData?.leadershipHire?.dept,
    });
    const levelList = getValues({
      list: advSearchFiltersState.filterListValues.level,
      appliedValues:
        currentFilterState.appliedFilterSectionData?.leadershipHire?.level,
    });
    setFilterListData({ dept: deptList, level: levelList });
  }, [
    advSearchFiltersState.filterListValues.department,
    advSearchFiltersState.filterListValues.level,
    currentFilterState.appliedFilterSectionData,
  ]);

  useEffect(() => {
    if (
      !currentFilterState.appliedFilterSectionData?.leadershipHire?.months &&
      Object.keys(
        currentFilterState.appliedFilterSectionData?.leadershipHire ?? {},
      )?.length > 0
    ) {
      currentFilterState.removeAppliedFilterFromGroup({
        category: 'leadershipHire',
      });
    }
  }, [currentFilterState.appliedFilterSectionData]);

  const isRestricted = selectedFilter.restricted
    ? selectedFilter.restricted()
    : false;

  return (
    <div className={`leadership-hire-wrapper${isRestricted ? ' disable' : ''}`}>
      {isRestricted && (
        <UpgradeSection
          title="Your current plan doesn't allow you to filter by new hires"
          imageURL={LeadershipHireImg}
        />
      )}
      <div className="months-radio-selection">
        <p className="radio-title">Hired people in the last</p>
        <div className="radio-list">
          {filterStaticValues.months.map((monthItem) => {
            const { label, value } = monthItem;
            return (
              <ControlInput
                key={value}
                type="radio"
                label={label}
                value={value}
                checked={
                  currentFilterState.appliedFilterSectionData?.leadershipHire
                    ?.months === value
                }
                onChange={(appliedValue) => {
                  applyValue({
                    key: 'months',
                    value: appliedValue,
                    multiple: false,
                  });
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        className={`dept-level-section${
          currentFilterState.appliedFilterSectionData?.leadershipHire?.months
            ? ''
            : ' disable'
        }`}
      >
        <div className="dept-section">
          <p className="checkbox-list-title">Select the Department</p>
          {filterListData.dept.map((dept) => {
            const { label, checked } = dept;
            return (
              <ControlInput
                key={`dept-${label}`}
                type="checkbox"
                label={label}
                checked={checked}
                onChange={() => {
                  applyValue({
                    key: 'dept',
                    value: label,
                  });
                }}
              />
            );
          })}
        </div>
        <div className="level-section">
          <p className="checkbox-list-title">Select the Level</p>
          {filterListData.level.map((level) => {
            const { label, checked } = level;
            return (
              <ControlInput
                key={`level-${label}`}
                type="checkbox"
                label={label}
                checked={checked}
                onChange={() => {
                  applyValue({
                    key: 'level',
                    value: label,
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default observer(LeadershipHire);
