import React, { useState } from 'react';
import CloseIcon from 'Assets/svg/close-material.svg';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Button from '../Button';
import './styles.scss';

function ChipList(props) {
  const {
    includedList = [],
    excludedList = [],
    enableClearing = false,
    limitNumberOfTags = true,
    tagLimit = 4,
    onClear = EMPTY_FUNCTION,
    keySuffix = '',
    title = '',
  } = props;

  const [showAllTags, setShowAllTags] = useState(!limitNumberOfTags);

  const list = [...includedList, ...excludedList];

  const tagsToShow = showAllTags ? list : list.slice(0, tagLimit);

  const additionalTagsLength = list.length - tagsToShow.length;

  return (
    <div className="chip-list">
      {title && <div className="title">{title}:</div>}
      {tagsToShow.map((tag) => {
        const { text, value } = tag;
        const isExcluded = excludedList?.some((excluded) => {
          return excluded.value === value;
        });
        return (
          <span
            key={`${value}-${keySuffix}`}
            title={text}
            className={`chip ${isExcluded ? 'excluded' : ''}`.trim()}
          >
            <span className="show-ellipsis">{text}</span>
            {enableClearing && (
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  onClear(tag, isExcluded);
                }}
              />
            )}
          </span>
        );
      })}
      {additionalTagsLength > 0 && (
        <Button
          variant="transparent"
          buttonProps={{
            text: `+${additionalTagsLength} more`,
            className: 'more-text',
          }}
          onClick={() => {
            setShowAllTags(true);
          }}
        />
      )}
    </div>
  );
}

export default ChipList;
