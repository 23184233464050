import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import FolderIcon from 'Assets/svg/folder.svg';
import RightArrowIcon from 'Assets/svg/right-arrow.svg';
import SharedFolderIcon from 'Assets/svg/shared-folder.svg';
import sequenceStoreData from 'Stores/sequenceData';

function FolderLoader(props) {
  const noOfLoader = props.loadingNumber ? props.loadingNumber : 1;
  return Array.from({ length: noOfLoader }, (value, index) => {
    return (
      <div className="new-folder-loader" key={`folderLoader${index}`}>
        <FolderIcon className="folder-icon" />
        <div className="folder-name linear-background" />
        <div className="arrow-icon linear-background" />
      </div>
    );
  });
}

function MySequenceFolder(props) {
  const { cbk } = props;
  return (
    <div className="folder selected" data-name="My Sequences" onClick={cbk}>
      <FolderIcon className="folder-icon" />
      <span className="folder-name name">My Sequences</span>
      <RightArrowIcon className="right-arrow" />
    </div>
  );
}

function SharedSequenceFolder(props) {
  const { cbk } = props;
  return (
    <div
      className="folder"
      data-id="shared"
      data-name="Shared Sequences"
      onClick={cbk}
    >
      <SharedFolderIcon className="folder-icon" />
      <span className="folder-name name ">Shared Sequences</span>
      <RightArrowIcon className="right-arrow" />
    </div>
  );
}

function DynamicFolder(props) {
  const { cbk, folderData } = props;

  return (
    folderData &&
    folderData.length > 0 &&
    folderData.map((folderVal) => {
      const { folderName, id } = folderVal;
      return (
        <div
          className="folder"
          key={id}
          data-name={folderName}
          data-id={id}
          onClick={(e) => {
            cbk(e, id);
          }}
        >
          <FolderIcon className="folder-icon" />
          <span className="folder-name name">{folderName}</span>
          <RightArrowIcon className="right-arrow" />
        </div>
      );
    })
  );
}

function SequenceFolders(props) {
  const { setSequenceLoading } = props;
  const [folderData, setFolderData] = useState('');
  const [loading, setLoading] = useState(true);

  const removeSelectedClass = () => {
    const target = document.getElementsByClassName('folder');
    if (target && target.length) {
      const array = Array.from(target);
      array.forEach((ele) => {
        if (ele) {
          ele.classList.remove('selected');
        }
      });
    }
  };

  const getFolderResponse = (e, folderId) => {
    if (e) {
      const dataValue = e.getAttribute('data-name');
      setSequenceLoading(true);
      if (dataValue) {
        switch (dataValue) {
          case 'My Sequences':
            sequenceStoreData.getAllSequenceData();
            break;
          case 'Shared Sequences':
            sequenceStoreData.getSharedSequence();
            break;
          default:
            sequenceStoreData.getFolderResponseById(folderId);
        }
      }
    }
  };

  const folderClickAction = (e, folderId) => {
    if (e) {
      const { currentTarget } = e;
      removeSelectedClass();
      currentTarget.classList.add('selected');
      getFolderResponse(currentTarget, folderId);
    }
  };

  useEffect(() => {
    if (sequenceStoreData.allFolderResponse) {
      setFolderData(sequenceStoreData.allFolderResponse);
      setLoading(false);
    }
  }, [sequenceStoreData.allFolderResponse]);

  return !loading ? (
    <div className="folder-container">
      <div className="folder-list">
        <MySequenceFolder cbk={folderClickAction} />
        <SharedSequenceFolder cbk={folderClickAction} />
        <div className="dynamicFoldersWrapper" style={{ width: '100%' }}>
          <DynamicFolder folderData={folderData} cbk={folderClickAction} />
        </div>
      </div>
    </div>
  ) : (
    <div className="folder-loading-wrapper">
      <FolderLoader loadingNumber={10} />
    </div>
  );
}

export default observer(SequenceFolders);
