import React from 'react';
import TickIcon from 'Assets/svg/new_icons/tick-outline.svg';
import Button from 'Components/common/base/Button';
import Modal from 'Components/common/base/Modal';

function AutoEnrichmentInitiatedPopup(props) {
  const { togglePopup } = props;

  return (
    <Modal
      align="center"
      className="auto-enrichment-initiated"
      show
      closeModal={togglePopup}
    >
      <Modal.Icon SvgIcon={TickIcon} />
      <Modal.Title>Auto Enrichment Initiated</Modal.Title>
      <Modal.Content className="description">
        <div className="message">
          Great work! Your CRM will now be auto enriched with Adapt&#39;s
          verified database.
        </div>
      </Modal.Content>
      <Modal.Footer>
        <Button buttonProps={{ text: 'Ok' }} onClick={togglePopup} />
      </Modal.Footer>
    </Modal>
  );
}

export default AutoEnrichmentInitiatedPopup;
