import React from 'react';
import { Link } from 'react-router-dom';
import RightArrowIcon from 'Assets/svg/right-arrow.svg';
import './styles.scss';

function RenderLinks(props) {
  const {
    links,
    index,
    collapseInactiveMenu,
    expandFirstMenu,
    activeMenu,
    selectedSubMenu,
    data,
  } = props;
  const { sublinks = [] } = data || {};

  const { subMenu, icon: Icon, linkValue } = links;
  const isActiveMenu = links.linkKey === activeMenu;
  const showSubMenu =
    !collapseInactiveMenu ||
    (collapseInactiveMenu && isActiveMenu) ||
    (expandFirstMenu && index === 0);
  const hasSubMenu = subMenu?.length > 0 && showSubMenu;

  const className = `main-text${
    hasSubMenu ? ' has-sub-menu' : ''
  }${index !== sublinks.length - 1 ? ' not-last' : ''}`;

  const mainMenu = (
    <>
      {Icon ? <Icon className="nav-icon" /> : ''}
      <span>{links.titleText}</span>
    </>
  );

  return (
    <div
      className={`integration-nav-link${isActiveMenu ? ' active' : ''}`}
      key={`${data.title}-${links.linkKey}`}
    >
      <Link
        to={linkValue}
        className={`${links.linkKey} ${className} menu-link`}
      >
        {mainMenu}
      </Link>
      {hasSubMenu && (
        <div className="sub-menu">
          {subMenu.map((menu) => {
            const isSelectedTab =
              selectedSubMenu === menu.title.toLowerCase() && isActiveMenu;
            return (
              <Link
                key={`${data.title}-${links.linkKey}-${
                  menu.API_KEY ?? menu.title
                }`}
                to={menu.link}
                className={`sub-menu-link${isSelectedTab ? ' selected' : ''}`}
              >
                {menu.title} {isSelectedTab && <RightArrowIcon />}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

function SideBarNavigation(props) {
  const {
    data,
    activeMenu,
    selectedSubMenu,
    connectionStatus,
    hasIcon = true,
    collapseInactiveMenu = false,
    expandFirstMenu = false,
    customRenderList = null,
    children,
  } = props;

  const Image = data?.imgComponent;

  return (
    <div className="sidebar-section">
      <div className="sidebar-header">
        <div>
          {hasIcon ? (
            data.isSvg ? (
              <Image className="logo" />
            ) : (
              <img src={Image} alt={data.title} className="logo" />
            )
          ) : null}
        </div>
        <div className="title-wrapper">
          <span className="title-text">{data.title}</span>
          {connectionStatus && (
            <span
              className={`connection-status${
                connectionStatus.toLowerCase() === 'connected' ? '' : ' red'
              }`}
            >
              {connectionStatus}
            </span>
          )}
        </div>
      </div>
      <div className="sidebar-navigation">
        {data.sublinks?.map((links, index) => {
          const propsToPass = {
            data,
            links,
            index,
            activeMenu,
            selectedSubMenu,
            collapseInactiveMenu,
            expandFirstMenu,
          };
          if (customRenderList !== null) {
            return customRenderList(propsToPass);
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <RenderLinks {...propsToPass} key={`sidebar-${index + 1}`} />;
        })}
      </div>
      {children}
    </div>
  );
}

export default SideBarNavigation;
