import React from 'react';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import BookmarkError from 'Assets/svg/new_icons/bookmark-error.svg';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Utils from 'Utils/utils';

function showModal(options) {
  commonModalState.setShowModal(true, options);
}

function showUnableToDeselectModal(callback = EMPTY_FUNCTION) {
  showModal({
    Icon: WarningIcon,
    title: 'Unable to deselect',
    description:
      'Unable to deselect individual items while using "Select all or Range selection". To deselect an item, clear the selection and select items individually.',
    primaryButtonText: 'Cancel',
    secondaryButtonText: 'Clear selection',
    secondaryButtonCallback: callback,
  });
}

function showLimitReachedPopup(data, callback = EMPTY_FUNCTION) {
  showModal({
    Icon: BookmarkError,
    title: 'Limit Reached',
    description: `You have reached the maximum saved searches allowed per account (${data?.maxSaveSearchCount || 100}). Please delete other saved
    searches to proceed.`,
    primaryButtonText: 'Manage saved searches',
    buttonCallback: callback,
    modalProps: { align: 'center' },
  });
}

function showNotEnoughCreditsPopup(isMemberCreditLimit = false) {
  showModal({
    Icon: WarningIcon,
    title: isMemberCreditLimit ? 'Credit Limit Reached' : 'Not Enough Credits',
    description: isMemberCreditLimit ? (
      <>
        The list you have requested could not be processed. You do not have
        sufficient credit limit to perform this transaction. Please contact your
        administrator.
      </>
    ) : (
      <>
        Unfortunately you don&#39;t have enough contact credits to generate this
        list. Please revise the criteria or write to{' '}
        <a href="mailto:support@adapt.io target=_blank">support@adapt.io</a> to
        upgrade your plan.
      </>
    ),
    primaryButtonText: 'Ok',
  });
}

function showFairUsageLimitPopup() {
  commonModalState.setShowModal(true, {
    Icon: WarningIcon,
    title: 'Daily Usage Limit Reached',
    description: (
      <>
        You have reached your daily usage limit. You can resume purchasing
        contacts at 6 AM PST. <br />
        Please upgrade to a higher plan to increase or remove the limit. Contact
        us at <a href="mailto:success@adapt.io">success@adapt.io</a> if you have
        any questions.
      </>
    ),
    primaryButtonText: 'Upgrade Now',
    buttonCallback: Utils.redirectToLBPricingPage,
  });
}

export {
  showUnableToDeselectModal,
  showLimitReachedPopup,
  showNotEnoughCreditsPopup,
  showFairUsageLimitPopup,
};
