import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import DeleteIcon from 'Assets/svg/new_icons/delete.svg';
import Dropdown from 'Components/common/base/Dropdown';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import Select from 'Components/common/base/Select';
import Button from 'Components/common/base/Button';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import './styles.scss';

function formatNumberWithText(number, text = '', showContactText = true) {
  return (
    number !== undefined &&
    number !== null && (
      <>
        {Utils.usNumberFormat(number)}
        &nbsp;{text}
        {showContactText && <>{number > 1 ? 'contacts' : 'contact'}&nbsp;</>}
      </>
    )
  );
}

function SelectionDropdown(props) {
  const {
    className,
    allSelected,
    selectedCount,
    maxLimit,
    rangeBoxValue,
    rangeValueChange,
    applySelection,
    clearSelections,
    enableLimitContactPerCompany = false,
    limitContactsPerCompany,
    setLimitContactsPerCompany,
    inputLabel = 'Select number of contacts',
  } = props;

  const limitContactsPerCompanyOptions = useMemo(() => {
    return Array.from({
      length:
        userDetail.userInfo.memberSubscription
          ?.limitContactPerCompanyMaxLimit || 3,
    }).map((...args) => {
      const index = args[1] + 1;
      return { label: String(index), value: index };
    });
  }, [userDetail.userInfo.memberSubscription]);

  return (
    <div className={`selection-wrap ${className}`.trimEnd()}>
      <Dropdown
        toggleElement={
          <ControlInput
            checked={allSelected}
            type="checkbox"
            disabled
            label={
              <>
                {selectedCount > 0
                  ? formatNumberWithText(selectedCount, 'Selected', false)
                  : 'Select'}
                <i className="fa fa-angle-down dropdown-icon" />
              </>
            }
          />
        }
      >
        <div className="list-action-item range-selection">
          <div className="label-with-input">
            <label htmlFor="range-value">
              {inputLabel}
              <sup>*</sup>
              <i>({Utils.usNumberFormat(maxLimit)} max)</i>
            </label>
            <Input
              inputProps={{
                min: 1,
                step: 1,
                max: maxLimit,
                value: rangeBoxValue,
                type: 'number',
              }}
              className="range-input"
              onChange={(value) => {
                const parsedValue = parseInt(value, 10);
                let valueToPass = parsedValue;
                if (parsedValue <= maxLimit) {
                  valueToPass = parsedValue;
                } else if (value === '') {
                  valueToPass = '';
                } else {
                  valueToPass = rangeBoxValue;
                }
                rangeValueChange(valueToPass);
              }}
            />
          </div>
          {enableLimitContactPerCompany && (
            <div className="label-with-input">
              Max contacts per company
              <Select
                placeholder="select"
                options={limitContactsPerCompanyOptions}
                value={limitContactsPerCompany.value}
                onChange={(data) => {
                  setLimitContactsPerCompany({
                    value: data,
                  });
                }}
              />
            </div>
          )}
        </div>
        <div className="button-wrapper">
          <Button
            buttonProps={{
              text: 'Apply',
              type: 'submit',
              disabled: !rangeBoxValue,
              customData: { close: 'true' },
            }}
            onClick={applySelection}
          />
          {selectedCount > 0 && (
            <Button
              Icon={DeleteIcon}
              variant="transparent"
              onClick={clearSelections}
              buttonProps={{
                className: 'clear-btn',
                text: 'Clear Selection',
                customData: { close: 'true' },
              }}
            />
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default observer(SelectionDropdown);
