import React from 'react';
import LocationIcon from 'Assets/svg/new_icons/location.svg';
import './styles.scss';

function ContactLocation(props) {
  const { city = '', state = '', country = '', showIcon = true } = props;

  const locationString = [city, state, country].reduce((string, input) => {
    if (input) {
      return string !== '' ? `${string}, ${input}` : input;
    }
    return string;
  }, '');

  return (
    locationString && (
      <div className="location-wrapper">
        <div className="location-address">
          {showIcon && (
            <div className="info-icon">
              <LocationIcon />
            </div>
          )}
          <div className="info-detail show-ellipsis" title={locationString}>
            {locationString}
          </div>
        </div>
      </div>
    )
  );
}

export default ContactLocation;
