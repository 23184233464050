import React from 'react';
import { observer } from 'mobx-react';
import DottedArrow from 'Assets/svg/new_icons/dotted-arrow.svg';
import ControlInput from 'Components/common/base/ControlInput';
import { MX_AS_EVENTS } from 'Utils/constants';
import InputSuggestion from '../filterPopup/inputSuggestion';
import './styles.scss';

function JobTitle(props) {
  const { currentFilterState, page, filterKeys } = props;

  return (
    <div className="job-title-wrapper">
      <InputSuggestion
        label="Search for Job Title"
        placeholder="Title"
        currentFilterState={currentFilterState}
        suggestionKey={filterKeys[0]}
        excludeSuggestionKey={filterKeys[1]}
        page={page}
        showExcludeToggle
        tooltipMessage="Toggle here to include or exclude set of titles. Check &#34;Find exact match&#34; to include specific title."
        mxEventName={MX_AS_EVENTS.AS_TITLE_SEARCH_PERFORMED}
        mxUniqueKey="titleSearch"
      />
      <ControlInput
        type="checkbox"
        label="Find exact matches only"
        checked={currentFilterState.checkBoxFilters?.exactTitleMatch}
        onChange={() => {
          currentFilterState.setCheckBoxFilters('exactTitleMatch');
        }}
      />
      <div className="title-info">
        <div className="left-wrap">
          You can perform search with: <br />{' '}
          <span className="highlight-text">AND / OR / NOT</span>
        </div>
        <DottedArrow />
        <div className="right-wrap">
          Example:
          <br />
          <span className="highlight-text">Performance AND marketing</span>
        </div>
      </div>
    </div>
  );
}

export default observer(JobTitle);
