import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import {
  applyFilters,
  applyFiltersWithDelay,
} from 'Pages/prospectSearch/utils';
import trackMixpanelEvent from '../trackMixpanelEvent';

function CheckboxLabel(props) {
  const { label, description } = props;

  return (
    <>
      <div className={`label${description ? ' has-description' : ''}`}>
        {label}
      </div>
      {description && (
        <div className="checkbox-description show-ellipsis" title={description}>
          {description}
        </div>
      )}
    </>
  );
}

function getSelectedData(data, getCustomSelectedData) {
  if (getCustomSelectedData !== undefined) {
    return getCustomSelectedData(data);
  }

  return data.reduce((output, prop) => {
    const { label, checked: itemChecked } = prop;
    if (itemChecked) {
      return [...output, { name: label, pName: label, selected: itemChecked }];
    }
    return output;
  }, []);
}

const defaultValueChecked = (data) => {
  const { selectedValues, item } = data;
  return selectedValues?.includes(item.label);
};

function CheckboxList(props) {
  const {
    list,
    currentFilterState,
    page,
    suggestionKey,
    mxUniqueKey,
    mxEventName,
    grid = false,
    enableSearch = false,
    getCustomSelectedData,
    isValueChecked = defaultValueChecked,
  } = props;

  const [listData, setListData] = useState(list);
  const [searchValue, setSearchValue] = useState('');

  const onChangeValue = (selectedValue, checked) => {
    if (checked) {
      trackMixpanelEvent({
        selectedValue,
        key: suggestionKey,
        page,
        mxUniqueKey,
        mxEventName,
      });
    }
    const newData = listData.map((prop) => {
      if (prop.label === selectedValue) {
        return { ...prop, checked };
      }
      return prop;
    });
    setListData(newData);

    currentFilterState.resultLoading = true;

    const selectedFilters = getSelectedData(newData, getCustomSelectedData);

    currentFilterState.setAppliedFilters(
      suggestionKey,
      selectedFilters,
      null,
      true,
      false,
    );

    applyFiltersWithDelay({
      suggestionKey,
      selectedFilters,
      currentFilterState,
    });
  };

  const isAllDataChecked =
    searchValue.length === 0 &&
    listData.every((listItem) => {
      return listItem.checked;
    });

  const selectAll = () => {
    const newData = listData.map((prop) => {
      const { show, checked } = prop;
      return { ...prop, checked: show ? !isAllDataChecked : checked };
    });
    setListData(newData);
    trackMixpanelEvent({
      selectedValue: searchValue,
      key: suggestionKey,
      page,
      mxUniqueKey,
      mxEventName,
    });
    currentFilterState.resultLoading = true;
    applyFilters({
      suggestionKey,
      selectedFilters: getSelectedData(newData, getCustomSelectedData),
      currentFilterState,
    });
  };

  useEffect(() => {
    const regex = new RegExp(searchValue, 'ig');
    setListData(
      list.reduce((output, prop) => {
        const { label, description = '' } = prop;
        return [
          ...output,
          {
            ...prop,
            checked: isValueChecked({
              selectedValues:
                currentFilterState.appliedFilterSectionData?.[suggestionKey],
              item: prop,
            }),
            show: regex.test(label) || regex.test(description),
          },
        ];
      }, []),
    );
  }, [list, currentFilterState.appliedFilterSectionData, searchValue]);

  useEffect(() => {
    setSearchValue('');
  }, [suggestionKey]);

  return (
    <>
      {enableSearch && (
        <div className="checkbox-list-search-wrapper">
          <ControlInput
            type="checkbox"
            label="All"
            checked={isAllDataChecked}
            onChange={selectAll}
          />
          <Input
            showSearchIcon
            showClearIcon
            inputProps={{ placeholder: 'Search', value: searchValue }}
            onChange={(val) => {
              setSearchValue(val);
            }}
          />
        </div>
      )}
      <div className={`checkbox-list ${grid ? 'grid' : ''}`.trimEnd()}>
        {listData?.map((listItem) => {
          const { label, checked = false, description, show } = listItem;
          return (
            show && (
              <ControlInput
                key={label}
                centerAlign={false}
                type="checkbox"
                label={
                  <CheckboxLabel label={label} description={description} />
                }
                checked={checked}
                onChange={(newValue) => {
                  onChangeValue(label, newValue);
                }}
              />
            )
          );
        })}
      </div>
    </>
  );
}

export default observer(CheckboxList);
