import React from 'react';
import InlineLoader from '../InlineLoader';
import './styles.scss';

function Button(props) {
  const {
    variant = 'primary',
    buttonProps = {},
    onClick,
    isLoading = false,
    Icon,
    title,
  } = props;
  const {
    text,
    disabled,
    type = 'button',
    form,
    className = '',
    customData = {},
    id,
  } = buttonProps;

  return (
    <button
      className={`button ${variant} ${className}`.trim()}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
      form={form}
      title={title}
      id={id}
      // added for when button added in dropdown
      data-close={customData.close}
    >
      {isLoading ? (
        <InlineLoader color={variant === 'primary' ? 'white' : 'primary'} />
      ) : (
        <>
          {Icon && <Icon className="icon" />}
          <span>{text}</span>
        </>
      )}
    </button>
  );
}

export default Button;
