import React from 'react';

function CompanyName(props) {
  const { name, parentClass = '', clsName = '' } = props;

  return (
    <div className={`contact-name ${parentClass}`}>
      <div className={`name ${clsName}`}>{name}</div>
    </div>
  );
}

export { CompanyName };
export default CompanyName;
