import React, { useState, useEffect } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import CompanySearchImg from 'Assets/png/company-search.png';
import Title from 'Components/common/base/Title';
import ExportCompanies, {
  exportCompaniesState,
} from 'Components/common/exportCompanies/exportCompanies';
import SavedSearchPopup, {
  savedSearchPopupState,
} from 'Components/common/savedSearchPopup';
import companySearchState, {
  companySearchPopupState,
} from 'Stores/companySearch';
import savedSearchState from 'Stores/savedSearch';
import userDetail from 'Stores/userDetail';
import advSearchFiltersState from 'Stores/advSearchFilters';
import Utils from 'Utils/utils';
import { MX_SAVED_SEARCH_EVENTS } from 'Utils/constants';
import CompanyList from './CompanyList';
import MiddleSection from './MiddleSection';
import EmptyCriteria from '../EmptyCriteria';
import { showLimitReachedPopup } from '../contactSearch/modals';
import { getSavedSearchTemplateById } from '../utils';
import './styles.scss';

function ResultSection() {
  if (!companySearchState.resultLoading) {
    if (companySearchState.resultStatusMsg === 'empty') {
      return (
        <EmptyCriteria
          text="Provide your search criteria to find companies"
          imageURL={CompanySearchImg}
        />
      );
    }
    if (companySearchState.resultStatusMsg === 'one_more') {
      return (
        <EmptyCriteria
          text="Please select at least one more search criteria"
          imageURL={CompanySearchImg}
        />
      );
    }
    if (companySearchState.searchResults.length > 0) {
      return <CompanyList />;
    }

    return (
      <EmptyCriteria
        imageURL={CompanySearchImg}
        text={
          <>
            We did not find companies that match your search criteria. <br />
            Please revise your search criteria
          </>
        }
      />
    );
  }
  return '';
}

function CompanySearch() {
  const match = useMatch('/advanced-search/company/:savedTemplate?');
  const { params } = match;
  const { savedTemplate = '' } = params;

  const [savedSearchId, setSavedSearchId] = useState('');
  const [isCreateSearchLoading, setIsCreateSearchLoading] = useState(false);

  const navigate = useNavigate();

  userDetail.setCurrentTab('company');

  const handleSaveSearchCallback = (status, data) => {
    if (status === 'limit') {
      showLimitReachedPopup(data, () => {
        navigate('/advanced-search/contact/saved-searches');
      });
    }
  };

  const createSavedSearch = async (data) => {
    const newData = data;
    let newSavedSearchId;
    setIsCreateSearchLoading(true);
    if (data.id === undefined) {
      newData.status = 'INACTIVE';
      newData.appliedFilters = companySearchState.appliedFilterPostData;
      newSavedSearchId = await savedSearchState.saveSearch(
        newData,
        {
          callback: handleSaveSearchCallback,
        },
        'company',
      );
    } else {
      const savedSearchToUpdate = savedSearchState.companySavedSearches?.find(
        (savedSearch) => {
          return savedSearch.id === data.id;
        },
      );
      const { id } = savedSearchToUpdate;
      const { templateName, ...remainingProps } = data;

      newSavedSearchId = id;
      const success = await savedSearchState.updateSavedSearch(
        {
          id,
          searchTemplate: JSON.stringify(
            companySearchState.appliedFilterPostData,
          ),
          templateName,
          ...remainingProps,
        },
        undefined,
        'company',
      );
      if (success) {
        Utils.mixpanelTrack(MX_SAVED_SEARCH_EVENTS.REPLACE_SAVED_SEARCH, {
          name: templateName,
          searchCriteria: companySearchState.appliedFilterPostData,
          ...remainingProps,
        });
      }
    }
    setIsCreateSearchLoading(false);
    setSavedSearchId(newSavedSearchId);

    savedSearchPopupState.setShowModal(false);
    savedSearchPopupState.setData({});

    if (window.location.hash === '#search') {
      navigate('/advanced-search/company');
    }
  };

  useEffect(() => {
    if (userDetail.onFirstLoad) {
      if (
        savedTemplate !== '' &&
        savedSearchState.companySavedSearches.length > 0
      ) {
        const searchJson = getSavedSearchTemplateById(
          savedSearchState.companySavedSearches,
          savedTemplate,
          (id) => {
            setSavedSearchId(id);
          },
        );
        companySearchState.applySearch(searchJson);
        navigate('/advanced-search/company', { replace: true });
      } else {
        companySearchState.retainSearch();
      }
      companySearchPopupState.applyFilterValues(companySearchState);
    }
  }, [userDetail.onFirstLoad]);

  useEffect(() => {
    advSearchFiltersState.fetchFilterValues(false);
  }, []);

  return (
    <>
      <Title title="Company Search" />
      <div className="company-search">
        {companySearchState.searchResultCount > 0 && (
          <MiddleSection savedSearchId={savedSearchId} />
        )}
        <ResultSection savedSearchId={savedSearchId} />
        {companySearchState.resultLoading && <CompanyList isListLoading />}
      </div>
      <div
        className={`processing-export-container${
          exportCompaniesState.showProcessingExport ? '' : ' hide'
        }`}
      >
        <p className="processing-export">
          Please wait, we&apos;re processing your export
        </p>
      </div>
      <ExportCompanies />
      <SavedSearchPopup
        isAPILoading={isCreateSearchLoading}
        onSubmit={createSavedSearch}
      />
    </>
  );
}

export default observer(CompanySearch);
