import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'Components/common/pagination';
import ListNamePopup from 'Pages/myLeads/ListNamePopup';
import AsyncPopup from 'Components/common/AsyncPopup';
import LoadingWrapper from 'Components/common/base/Loader';
import { toasterState } from 'Components/common/base/Toaster';
import userDetail from 'Stores/userDetail';
import myLeadsState, { cancelPreviousAPI } from 'Stores/myLeads';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import ListPageHeader from './ListPageHeader';
import MiddleSection from './MiddleSection';
import ContactList from './ContactList';
import ProcessingMessage from './processingMessage';
import EmptyState from '../EmptyState';
import './styles.scss';

function NoLeadsInThisList() {
  return (
    <div className="empty-state">
      {myLeadsState.isPurchaseInProgress && <ProcessingMessage />}
      {!myLeadsState.isPurchaseInProgress && (
        <EmptyState
          title=""
          description={
            <>
              No contacts are added to this list. You can start adding contacts{' '}
              <br /> to this list, either from other lists or from{' '}
              <b>Contact Search</b>.
            </>
          }
        />
      )}
    </div>
  );
}

function ResultSection(props) {
  const { currentListInfo } = props;

  if (myLeadsState?.leadsResults?.length > 0) {
    return <ContactList pageName="myleads" currentListInfo={currentListInfo} />;
  }

  return <NoLeadsInThisList />;
}

function ListPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id = null } = useParams();
  const currentList = userDetail.myLeadList.find((list) => {
    return list.id === id;
  });
  const trigger = Utils.getProspectorUserTrigger(window.location.href);
  const apExportType = Utils.getQueryParamFromUrl(
    'apExportType',
    window.location.href,
  );

  if (apExportType) {
    freeTrialPopupState.setDynamicText(apExportType);
  }

  const [pagination, setPagination] = useState({ page: 1, limit: 50 });

  const changePage = (pageNo, opr) => {
    const newPage = pageNo + 1;
    setPagination({ ...pagination, page: newPage });
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_LIST_PAGE_PAGINATION_CLICK, {
      page: newPage,
    });

    if (opr === '+') {
      if (myLeadsState.lastContactId && myLeadsState.lastContactUpdateTs) {
        myLeadsState.getLeadsResults({
          pageLimit: pagination.limit,
          listId: myLeadsState.selectedList.id,
          pagination: 'next',
          lastContactId: myLeadsState.lastContactId,
          lastContactUpdateTs: myLeadsState.lastContactUpdateTs,
        });
      }
    } else if (
      myLeadsState.firstContactId &&
      myLeadsState.firstContactUpdateTs
    ) {
      myLeadsState.getLeadsResults({
        pageLimit: pagination.limit,
        listId: myLeadsState.selectedList.id,
        pagination: 'prev',
        lastContactId: myLeadsState.firstContactId,
        lastContactUpdateTs: myLeadsState.firstContactUpdateTs,
      });
    }
  };

  const changePageLimit = (val) => {
    setPagination({ limit: val, page: 1 });

    myLeadsState.getLeadsResults({
      pageLimit: val,
      listId: myLeadsState.selectedList.id,
    });
  };

  useEffect(() => {
    if (trigger !== null) {
      freeTrialPopupState.setFreeTrialPrePopup(trigger);
      window.history.replaceState(null, null, location.pathname);
    }
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_LIST_PAGE_VIEWED);
    return () => {
      cancelPreviousAPI();
    };
  }, []);

  useEffect(() => {
    if (userDetail.myLeadList.length > 0) {
      const listData = userDetail.myLeadList.find((list) => {
        return list.id === id;
      });
      if (listData !== undefined) {
        myLeadsState.setSelectedList({ id: listData.id, name: listData.name });
      } else {
        toasterState.setToastMsg('List does not exist', 'failure', {
          callback: () => {
            navigate('/my-leads');
          },
        });
      }
    }
  }, [userDetail.myLeadList]);

  useEffect(() => {
    if (userDetail.myLeadListLoaded) {
      // reset values on tab change
      setPagination({ page: 1, count: 50 });
      myLeadsState.leadsResultCount = 0;
      window.history.replaceState(null, null, location.pathname);
      myLeadsState.getLeadsResults({
        pageLimit: pagination.limit,
        listId: id,
      });
    }
  }, [location, userDetail.myLeadListLoaded]);

  return (
    <div
      className={`list-page${
        myLeadsState.resultLoading ? ' result-loading' : ''
      }`}
    >
      <LoadingWrapper show={myLeadsState.actionLoader} position="fixed" />
      <ListNamePopup />
      <AsyncPopup />
      <ListPageHeader list={currentList} />
      <div className="list-page-wrapper">
        <MiddleSection listData={currentList} pageLimit={pagination.limit} />
        {myLeadsState.resultLoading || !userDetail.myLeadListLoaded ? (
          <ContactList isListLoading pageName="myleads" />
        ) : (
          <ResultSection currentListInfo={currentList} />
        )}
        {myLeadsState.leadsResultCount > 0 && (
          <Pagination
            totalCount={myLeadsState.leadsResultCount}
            rowsPerPage={pagination.limit}
            currentPage={pagination.page}
            updateCurrentPage={changePage}
            totalText="Contacts in list"
            onRowsPerPageUpdate={changePageLimit}
            rowsPerPageValues={[10, 25, 50, 100].map((data) => {
              return { label: data.toString(), value: data };
            })}
          />
        )}
      </div>
    </div>
  );
}

export default observer(ListPage);
