import React, { useState } from 'react';
import VisibilityIcon from 'Assets/svg/visibility.svg';
import VisibilityOffIcon from 'Assets/svg/visibility-off.svg';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import Input from 'Components/common/base/Input';
import profileState from 'Stores/profile';
import './styles.scss';

function PasswordFieldWithVisibilityIcon(props) {
  const [showPassword, setShowPassword] = useState(false);
  const { label, value, placeholder, onChange, onBlur, error } = props;

  return (
    <div className="input-with-icon">
      <Input
        label={label}
        onChange={onChange}
        error={error}
        inputProps={{
          value,
          placeholder,
          type: showPassword ? 'text' : 'password',
          onBlur,
        }}
      />
      {showPassword ? (
        <VisibilityOffIcon
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        />
      ) : (
        <VisibilityIcon
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        />
      )}
    </div>
  );
}

function ChangePasswordSection(props) {
  const { values, setValues, errors, setErrors } = props;
  return (
    <form>
      <PasswordFieldWithVisibilityIcon
        label="Current Password"
        type="password"
        placeholder="Enter current password"
        value={values.currentPassword}
        onBlur={async () => {
          if (values.currentPassword) {
            const isError = await profileState.checkCurrentPassword(
              values.currentPassword,
            );
            setErrors({
              ...errors,
              currentPassword: isError ? 'Please check your password' : '',
            });
          }
        }}
        onChange={(value) => {
          setValues({ ...values, currentPassword: value });
        }}
        error={errors.currentPassword}
      />
      <PasswordFieldWithVisibilityIcon
        label="New Password"
        type="password"
        placeholder="Enter new password"
        className={errors.newPassword !== '' ? 'input-error' : ''}
        value={values.newPassword}
        onChange={(value) => {
          setValues({ ...values, newPassword: value });
          if (errors.newPassword) {
            setErrors({
              ...errors,
              newPassword:
                (value || '').length < 6
                  ? 'Password must be at least 6 characters'
                  : '',
            });
          }
        }}
        error={errors.newPassword}
      />
      <PasswordFieldWithVisibilityIcon
        label="Confirm Password"
        type="password"
        className={errors.confirmNewPassword !== '' ? 'input-error' : ''}
        placeholder="Enter new password again"
        value={values.confirmNewPassword}
        onChange={(value) => {
          setValues({ ...values, confirmNewPassword: value });
          if (errors.confirmNewPassword) {
            setErrors({
              ...errors,
              confirmNewPassword:
                values.newPassword !== value ? 'Passwords do not match' : '',
            });
          }
        }}
        error={errors.confirmNewPassword}
      />
    </form>
  );
}

function ChangePasswordPopup(props) {
  const { onClose } = props;
  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  let newErrorState = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const handleSave = () => {
    ['currentPassword', 'newPassword', 'confirmNewPassword'].some((key) => {
      if (values[key].length === 0) {
        newErrorState = {
          ...errors,
          [key]: `Please enter ${
            key === 'currentPassword'
              ? 'current password'
              : key === 'newPassword'
                ? 'new password'
                : 'new password again'
          }`,
        };
        return true;
      }
      if (key === 'newPassword') {
        if (values[key].length < 6) {
          newErrorState = {
            ...errors,
            [key]: 'Password must be at least 6 characters',
          };
          return true;
        }
      }
      if (
        key === 'confirmNewPassword' &&
        values.newPassword !== values.confirmNewPassword
      ) {
        newErrorState = {
          ...errors,
          [key]: 'Passwords do not match',
        };
        return true;
      }

      return false;
    });
    const isValid = Object.keys(newErrorState).every((key) => {
      return newErrorState[key] === '';
    });
    setErrors(newErrorState);
    if (isValid) {
      profileState.updatePassword(
        {
          newPassword: values.newPassword,
          currentPassword: values.currentPassword,
        },
        onClose,
      );
    }
  };

  return (
    <Modal show closeModal={onClose} className="change-password-popup">
      <Modal.Title>Change Password</Modal.Title>
      <Modal.Content>
        <ChangePasswordSection
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          buttonProps={{ text: 'Cancel' }}
          variant="outline"
          onClick={onClose}
        />
        <Button buttonProps={{ text: 'Save' }} onClick={handleSave} />
      </Modal.Footer>
    </Modal>
  );
}

export default ChangePasswordPopup;
