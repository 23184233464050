import React from 'react';
import format from 'date-fns/format';
import Modal from 'Components/common/base/Modal';
import Utils from 'Utils/utils';

function EnrichmentDetailsPopup(props) {
  const { togglePopup, contactEnrichmentData } = props;
  const { email, enrichedAt, jobName, enrichmentData } = contactEnrichmentData;

  const firstName =
    enrichmentData.find((data) => {
      return data.fieldName === 'firstname';
    })?.crmValue || '';
  const lastName =
    enrichmentData.find((data) => {
      return data.fieldName === 'lastname';
    })?.crmValue || '';

  const fullName = `${firstName}${lastName ? ` ${lastName}` : ''}`;

  return (
    <Modal show className="enrichment-details-popup" closeModal={togglePopup}>
      <Modal.Title>Enrichment Details</Modal.Title>
      <Modal.Content>
        <div className="details-container">
          <div className="details-card">
            <div className="user-details-wrap">
              <div className="image-placeholder">
                {(fullName || email).substring(0, 1)}
              </div>
              <div className="right-section">
                {fullName && <div className="name">{fullName}</div>}
                <div className="contact-email">{email}</div>
              </div>
            </div>
            <div className="enrich-details">
              <div className="enriched-on">
                <div className="detail-label">Updated On: </div>
                <div className="detail-value">
                  {Utils.getFormattedDate(enrichedAt, true)}
                  <span className="time">
                    {format(new Date(enrichedAt).getTime(), 'HH:mm:ss')}
                  </span>
                </div>
              </div>
              {jobName && (
                <div className="job-name">
                  <span className="detail-label">Job Name: </span>
                  <span className="detail-value">{jobName}</span>
                </div>
              )}
            </div>
          </div>
          <div className="changed-fields-header">
            <div className="header-wrap">
              <div>Field Name</div>
              <div className="current-field">Previous Data</div>
              <div>Data from Adapt</div>
            </div>
          </div>
          <div className="changed-fields-body">
            <div className="changed-fields-body-inner">
              {enrichmentData
                .sort((data1, data2) => {
                  return data1.displayPriority - data2.displayPriority;
                })
                .map((data) => {
                  const isDataEnriched = !data.crmValue && !!data.adaptValue;
                  const isDataOverwritten =
                    !!data.crmValue &&
                    !!data.adaptValue &&
                    data.crmValue !== data.adaptValue;

                  return (
                    <div className="field" key={data.fieldName}>
                      <div className="field-name-value">
                        {data.crmLabel || data.fieldName}
                      </div>
                      <div
                        className={`current-value${
                          data.crmValue ? '' : ' no-data'
                        }`}
                        title={
                          isDataOverwritten
                            ? 'Data refreshed'
                            : isDataEnriched
                              ? 'Data enriched'
                              : ''
                        }
                      >
                        {data.crmValue || '- NA -'}
                      </div>
                      <div
                        className={`adapt-value${
                          data.adaptValue ? '' : ' no-data'
                        }`}
                        title={
                          isDataOverwritten
                            ? 'Data refreshed'
                            : isDataEnriched
                              ? 'Data enriched'
                              : ''
                        }
                      >
                        {data.adaptValue || '- NA -'}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default EnrichmentDetailsPopup;
