import React from 'react';
import { Link } from 'react-router-dom';
import ChartIcon from 'Assets/svg/chart.svg';
import BarChartIcon from 'Assets/svg/bar-chart.svg';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

function EmptyState(props) {
  const {
    chart,
    title = 'No purchase data available',
    showButton = true,
  } = props;

  return (
    <div className="usage-analytics-chart-empty-state">
      {chart === 'bar' ? <BarChartIcon /> : <ChartIcon />}
      <div className="title">{title}</div>
      <div className="message">Begin your first contact purchase</div>
      {showButton && (
        <Link className="button" to={CONSTANTS.PAGE_PATH_MAP.CONTACT_SEARCH}>
          Go to Contact Search
        </Link>
      )}
    </div>
  );
}

export default EmptyState;
