import { observable, action, computed } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import { DO_NOT_MAP_FIELD, enrichmentPreferenceOptions } from 'Utils/constants';
import { toasterState } from 'Components/common/base/Toaster';
import userDetail from './userDetail';

class FieldMapping {
  @observable accessor fieldMappingResponseData = {};

  @observable accessor fieldMappingData = {};

  @observable accessor enrichmentSettingsData = {};

  @observable accessor isLoading = false;

  @observable accessor isFieldMappingLoading = false;

  @observable accessor picklistFieldMappingData = null;

  @computed
  // eslint-disable-next-line class-methods-use-this
  get crmSettingsId() {
    return userDetail.userInfo?.memberSubscription?.crmSettingsId;
  }

  @computed
  get areFieldsUpdated() {
    return (
      JSON.stringify(this.fieldMappingResponseData) !==
      JSON.stringify(this.fieldMappingData)
    );
  }

  @computed
  get availableCRMFields() {
    const fieldMappings =
      this.fieldMappingData?.defaultFieldMapping ??
      this.fieldMappingData?.customFieldMapping;

    return this.fieldMappingData.crmFields?.filter((data) => {
      const flag =
        !fieldMappings.some((field) => {
          return field.crmKey === data.key;
        }) &&
        !this.fieldMappingData.customValueMapping?.some((field) => {
          return field.crmKey === data.key;
        }) &&
        (data.options === null ||
          (data.options && Object.keys(data.options).length > 0));
      return flag;
    });
  }

  @computed
  get fieldMappings() {
    return this.fieldMappingData?.adaptFields?.map((adaptFieldData) => {
      const { label, key } = adaptFieldData;
      const dataFieldMappings =
        this.fieldMappingData?.defaultFieldMapping ??
        this.fieldMappingData?.customFieldMapping;
      const fieldData = dataFieldMappings?.find((data) => {
        return data.adaptKey === key;
      }) ?? { adaptKey: key, adaptLabel: label };
      return fieldData;
    });
  }

  @computed
  get availablePicklistCRMFields() {
    const crmOptions = this.picklistFieldMappingData?.crmOptions;

    const crmOptionsList = Object.keys(crmOptions).map((item) => {
      return {
        crmKey: item,
        crmLabel: crmOptions[item],
      };
    });

    return crmOptionsList;
  }

  @computed
  get picklistFieldMappings() {
    const adaptOptions = this.picklistFieldMappingData?.adaptOptions;

    return Object.keys(adaptOptions).map((item) => {
      const value = adaptOptions[item];
      const fieldData =
        this.picklistFieldMappingData?.customMappingOptions?.find((data) => {
          return data.adaptKey === item;
        }) ?? { adaptKey: item, adaptLabel: value };

      return fieldData;
    });
  }

  @action
  updateFieldMappingData(data) {
    this.fieldMappingData = data;
    this.setFieldMappingDataState(data);
  }

  @action
  setFieldMappingDataState(data) {
    this.fieldMappingData = data;
  }

  @action
  setPicklistFieldMappingData(data) {
    this.picklistFieldMappingData = data;
  }

  @action
  setCustomFieldMapping(data) {
    this.fieldMappingData = {
      ...this.fieldMappingData,
      customFieldMapping: data,
    };
  }

  @action
  updateCustomFieldMappings(newData) {
    if (this.fieldMappingData.defaultFieldMapping?.length > 0) {
      this.setCustomFieldMapping(this.fieldMappingData.defaultFieldMapping);
      delete this.fieldMappingData.defaultFieldMapping;
    }
    const index = this.fieldMappingData.customFieldMapping.findIndex((data) => {
      return data.adaptKey === newData.adaptKey;
    });
    if (index !== -1) {
      this.fieldMappingData.customFieldMapping[index] = newData;
    } else {
      const updatObj = newData;
      const { isTypeMismatch = false } = newData;
      if (isTypeMismatch.isTypeMismatch) {
        updatObj.enrichmentPreference = enrichmentPreferenceOptions[2].value;
      }
      this.fieldMappingData.customFieldMapping.push(updatObj);
    }
  }

  @action
  updateCustomPicklistFieldMappings(isDoNotMap, selectedKey, fieldData) {
    const newObj = fieldData;
    if (isDoNotMap) {
      newObj.crmKey = selectedKey;
      newObj.crmLabel = DO_NOT_MAP_FIELD.label;
    } else {
      const crmSelectedValue =
        this.picklistFieldMappingData?.crmOptions?.[selectedKey];
      newObj.crmKey = selectedKey;
      newObj.crmLabel = crmSelectedValue;
    }
    const itemIndex =
      this.picklistFieldMappingData.customMappingOptions.findIndex((data) => {
        return data.adaptKey === fieldData.adaptKey;
      });
    if (itemIndex !== -1) {
      this.picklistFieldMappingData.customMappingOptions =
        this.picklistFieldMappingData.customMappingOptions.map(
          (item, index) => {
            if (itemIndex === index) {
              return newObj;
            }
            return item;
          },
        );
    } else {
      this.picklistFieldMappingData.customMappingOptions.push(newObj);
    }
  }

  @action
  handleCancel() {
    this.setFieldMappingDataState(
      Utils.copyObjectWithoutReference(this.fieldMappingResponseData),
    );
  }

  @action
  updateCustomValueMapping(props) {
    const { type } = props;

    if (!this.fieldMappingData.customValueMapping?.length) {
      this.fieldMappingData.customValueMapping = [];
    }
    // eslint-disable-next-line default-case
    switch (type) {
      case 'ADD':
        this.fieldMappingData.customValueMapping.push(props.data);
        break;
      case 'EDIT':
        this.fieldMappingData.customValueMapping[props.index] = props.data;
        break;
      case 'DELETE':
        this.fieldMappingData.customValueMapping.splice(props.index, 1);
    }
  }

  @action
  getCRMFieldMappings = async ({
    crmType,
    exportType,
    notConnectedCallback = () => {},
  }) => {
    this.isFieldMappingLoading = true;
    try {
      const url = Utils.generateUrl(URLS?.crmFieldMapping, {
        crmType,
        crmSettingsId: this.crmSettingsId,
        exportType,
      });
      const config = {
        url,
        method: 'GET',
      };
      const res = await makeApi(config);
      console.log(res);
      if (res?.data) {
        const { responseCode, responseData } = res.data;
        if (responseCode === 200) {
          this.updateFieldMappingData(responseData);
          this.fieldMappingResponseData = responseData;
        } else if (responseCode === 303) {
          notConnectedCallback();
        }
      }
      this.isFieldMappingLoading = false;
    } catch (e) {
      console.error('getFieldMappings Error', e);
      this.isFieldMappingLoading = true;
    }
  };

  @action
  updateCRMFieldMappings = async ({ crmType, exportType }) => {
    try {
      this.isLoading = true;
      const url = Utils.generateUrl(URLS?.crmFieldMapping, {
        crmType,
        crmSettingsId: this.crmSettingsId,
        exportType,
      });
      const config = {
        url,
        method: 'POST',
        data: {
          customFieldMapping:
            this.fieldMappingData.customFieldMapping ??
            this.fieldMappingData.defaultFieldMapping,
          ...(this.fieldMappingData.customValueMapping
            ? { customValueMapping: this.fieldMappingData.customValueMapping }
            : {}),
        },
      };
      const res = await makeApi(config);
      this.isLoading = false;
      if (res?.data) {
        const { responseCode } = res.data;
        if (responseCode === 200) {
          toasterState.setToastMsg(
            'Field mappings updated successfully',
            'success',
          );
          this.fieldMappingResponseData = Utils.copyObjectWithoutReference(
            this.fieldMappingData,
          );
          return true;
        }
      }
      toasterState.setToastMsg(
        'Something went wrong. Please try again. If the problem persists, please write to us at <a href="mailto:support@adapt.io" target="_blank">support@adapt.io</a>',
        'failure',
      );
      return false;
    } catch (e) {
      console.error('updateFieldMappings Error', e);
      this.isLoading = false;
    }
    return false;
  };

  @action
  getEnrichmentSettings = async (crmType, options = {}) => {
    const { notConnectedCallback = () => {} } = options;
    this.isLoading = true;
    try {
      const url = Utils.generateUrl(URLS?.enrichmentSettings, {
        crmType,
        crmSettingsId: this.crmSettingsId,
      });
      const config = {
        url,
        method: 'GET',
      };
      const res = await makeApi(config);
      if (res?.data) {
        const { responseCode, responseData } = res.data;
        if (responseCode === 200) {
          this.enrichmentSettingsData = responseData;
        } else if (responseCode === 303) {
          notConnectedCallback();
        }
      }
      this.isLoading = false;
    } catch (e) {
      console.error('getEnrichmentSettings Error', e);
      this.isLoading = false;
    }
  };

  @action
  updateEnrichmentSettings = async (crmType, data) => {
    if (!this.enrichmentSettingsData?.isAdmin) {
      return false;
    }
    let isSuccess = false;
    try {
      this.isLoading = true;
      const url = Utils.generateUrl(URLS?.enrichmentSettings, {
        crmType,
        crmSettingsId: this.crmSettingsId,
      });

      const config = {
        url,
        method: 'POST',
        data,
      };
      const res = await makeApi(config);

      if (res?.data) {
        const { responseCode } = res.data;
        if (responseCode === 200) {
          isSuccess = true;
        } else {
          toasterState.setToastMsg(
            'Something went wrong. Please try again. If the problem persists, please write to us at <a href="mailto:support@adapt.io" target="_blank">support@adapt.io</a>',
            'failure',
          );
        }
      }
      this.isLoading = false;
    } catch (e) {
      console.error('getEnrichmentSettings Error', e);
      this.isLoading = false;
    }
    return isSuccess;
  };
}

const fieldMappingState = new FieldMapping();

export default fieldMappingState;
