import React, { useId, useState } from 'react';
import { observer } from 'mobx-react';
// import ControlInput from 'Components/common/base/ControlInput';
import SampleIncludeExcludeCSV from 'Assets/csv/sampleIncludeExclude.csv';
// import { companySearchPopupState } from 'Stores/companySearch';
import { MX_AS_EVENTS } from 'Utils/constants';
import Utils from 'Utils/utils';
import { SuppressionListItem } from '../ContactExclusion';
import { StatCard, handleUploadCSV } from '../CompanyInclusion';
import './styles.scss';

function CompanyExclusion(props) {
  const { currentFilterState, page } = props;
  const id = useId();
  const [statData, setStatData] = useState({ total: 0, valid: 0 });

  const onFileChange = (e) => {
    e.persist();
    handleUploadCSV({
      event: e,
      currentFilterState,
      page,
      setStatData,
      filterKey: 'excludedDomains',
      domainSize: Utils.getExcludeCompanyDomainSize(),
      mxEventName: MX_AS_EVENTS.LB_AS_EXCLUDE_COMPANY_SEARCH_PERFORMED,
    });
  };

  // const updatePreferences = (key) => {
  //   companySearchPopupState.setCheckBoxFilters(key);
  // };

  const isCompanySearch = page === 'company';

  return (
    <div className="company-exclusion-wrapper">
      {/* {isCompanySearch && (
        <>
          <ControlInput
            label="Do not display companies that I own"
            checked={
              companySearchPopupState.checkBoxFilters.dontDisplayOwnedCompanies
            }
            type="checkbox"
            onChange={() => {
              updatePreferences('dontDisplayOwnedCompanies');
            }}
          />
          <ControlInput
            label="Do not display companies owned by my team"
            checked={
              companySearchPopupState.checkBoxFilters.dontDisplayTeamCompanies
            }
            type="checkbox"
            onChange={() => {
              updatePreferences('dontDisplayTeamCompanies');
            }}
          />
        </>
      )} */}
      <div className="file-upload-wrapper">
        <p>
          {isCompanySearch
            ? 'Exclude specific companies from search results.'
            : 'Exclude contacts from specific companies in this search results.'}
          <a
            className="sample-csv"
            href={SampleIncludeExcludeCSV}
            download="sampleIncludeExclude"
          >
            Sample File
          </a>
        </p>
        <label htmlFor={id} className="csv-upload-label button outline">
          Choose File
          <input
            id={id}
            className="csv-upload"
            type="file"
            accept=".csv"
            onChange={onFileChange}
          />
        </label>
      </div>
      {statData.total > 0 && (
        <StatCard
          title="Exclude Companies"
          totalSize={statData.total}
          validSize={statData.valid}
          setStatData={setStatData}
        />
      )}
      {page === 'contact' && (
        <SuppressionListItem
          title="Global Suppression List"
          description="Would you like to suppress a list of existing contacts who are your customers, competitors or from your current pipeline? Use the global suppression list, and exclude them from your search results"
          linkText="Go to Global Suppression List"
          href="/profile/suppression-list"
        />
      )}
    </div>
  );
}

export default observer(CompanyExclusion);
