import React, { useState, useMemo } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import DocumentIcon from 'Assets/svg/new_icons/document.svg';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import LoadingWrapper from 'Components/common/base/Loader';
import { toasterState } from 'Components/common/base/Toaster';
import Modal from 'Components/common/base/Modal';
import Select from 'Components/common/base/Select';
import Button from 'Components/common/base/Button';
import ControlInput from 'Components/common/base/ControlInput';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import SharedListIcon from 'Components/common/SharedListIcon';
import userDetail from 'Stores/userDetail';
import advSearchState from 'Stores/advSearch';
import {
  MOBILE_NUMBER_EVENTS,
  PAGE_PATH_MAP,
  TOAST_MSG_TYPES,
  UPGRADE_TRIGGERS,
} from 'Utils/constants';
import Utils from 'Utils/utils';
import myLeadsState from 'Stores/myLeads';
import ZeroBounceValidation from './zeroBounceValidation';
import './styles.scss';

const defaultOptions = {
  saveAction: null,
  isSingleContactAddToList: false,
};

class AddToListWithOptionsState {
  @observable accessor showPopup = false;

  @observable accessor addToListData = {};

  @observable accessor newList = false;

  @observable accessor currentStep = 1;

  addToListOptions = defaultOptions;

  setAddToListOptions = (data) => {
    this.addToListOptions = {
      ...this.addToListOptions,
      ...data,
    };
  };

  setIsNewList(value) {
    this.newList = value;
  }

  setAddToListData(data = {}) {
    this.addToListData = data;
  }

  setShowListPopup(show) {
    this.showPopup = show;

    if (!show) {
      this.currentStep = 1;
    }
  }
}

const addToListWithOptionsState = new AddToListWithOptionsState();

function renderOption(option) {
  const { label, isNewList = false } = option;

  return isNewList ? (
    <span>Create New List “{label}”</span>
  ) : (
    <span className="list-name-wrap">
      <span className="list-name">{label}&nbsp;</span>
      <SharedListIcon data={option} />
    </span>
  );
}

function filterValue(value) {
  const filteredList = userDetail.myLeadList.reduce((result, list) => {
    const { name, id, isSharedList: sharedList, count } = list;
    const reg = new RegExp(value, 'i');
    if (name.match(reg) && id) {
      return [
        ...result,
        { label: name, value: id, isSharedList: sharedList, count },
      ];
    }
    return result;
  }, []);
  const isExactMatchAvailable =
    !value ||
    userDetail.myLeadList.some((list) => {
      return list?.name === value;
    });
  return isExactMatchAvailable
    ? filteredList
    : [{ isNewList: true, label: value, value }, ...filteredList];
}

function AddToListWithOptions() {
  const [listName, setListName] = useState('');
  const [checkboxValues, setCheckboxValues] = useState({
    email: true,
    phone: false,
    phoneOrEmail: false,
  });
  const [listId, setListId] = useState('');
  const [createListLoading, setCreateListLoading] = useState(false);
  const [addToListLoading, setAddToListLoading] = useState(false);
  const [isSharedList, setIsSharedList] = React.useState(false);

  const resetModalData = () => {
    setListName('');
    setListId('');
    setIsSharedList(false);
    setCheckboxValues({
      email: true,
      phone: false,
      phoneOrEmail: false,
    });
    addToListWithOptionsState.setIsNewList(false);
    addToListWithOptionsState.setAddToListData({});
    addToListWithOptionsState.setAddToListOptions(defaultOptions);
  };

  const addToListCallback = () => {
    setAddToListLoading(false);
    const { callback } = addToListWithOptionsState.addToListData;
    addToListWithOptionsState.setShowListPopup(false);

    if (callback) {
      callback({
        listId,
        listName: userDetail.myLeadList.find((list) => {
          return list.id === listId;
        })?.name,
      });
    }

    resetModalData();
  };

  const handleCloseClick = () => {
    resetModalData();
    addToListWithOptionsState.setShowListPopup(false);
  };

  const selectedCount =
    advSearchState.bulkRangeCount > 0
      ? advSearchState.bulkRangeCount
      : advSearchState.bulkPurchaseIds.length;

  const {
    availableEmailCredits = userDetail.userInfo.lbCredits,
    availablePhoneCredits = userDetail.userInfo.phoneCreditsRemaining,
  } = addToListWithOptionsState.addToListData;

  const handleSelectedListClear = () => {
    resetModalData();
  };

  const handleContainCheckBoxChanges = (data = {}) => {
    setCheckboxValues(data);
    addToListWithOptionsState.setAddToListData({});
  };

  const triggerAddToListCallback = async (validationStatus, newListId) => {
    setAddToListLoading(true);
    const selectedListId = listId;
    const listIdForPurchase = selectedListId || newListId;

    if (addToListWithOptionsState.addToListOptions.saveAction) {
      addToListWithOptionsState.addToListOptions.saveAction({
        listId: listIdForPurchase,
        listName: userDetail.myLeadList.find((list) => {
          return list.id === listId;
        })?.name,
        callback: addToListCallback,
        checkboxValues,
        validationStatus,
      });
    } else {
      await advSearchState.purchaseContactAsync(
        {
          listId: listIdForPurchase,
          required:
            advSearchState.getRequiredPropertyForPurchase(checkboxValues),
          validationStatus,
        },
        addToListWithOptionsState.newList,
      );
      addToListCallback();
    }
  };

  const creditCheckCallback = async () => {
    // also checking in member CRM preferences as disconnection may not remove in team
    if (
      checkboxValues.email &&
      !addToListWithOptionsState.addToListOptions.isSingleContactAddToList &&
      userDetail.userInfo?.teamCRMPreferences?.SALESFORCE
        ?.dont_purchase_if_present_in_crm &&
      userDetail.userInfo.crmPreferences?.SALESFORCE?.connected
    ) {
      setAddToListLoading(true);
      const isValid = await advSearchState.checkSFAuth();
      setAddToListLoading(false);
      if (!isValid) {
        addToListCallback();
        advSearchState.clearAllSelection(true);
        advSearchState.showSalesforceAuthError = true;
        return;
      }
    }

    if (addToListWithOptionsState.newList) {
      setCreateListLoading(true);
      const callback = (response) => {
        const id = response?.data?.id;
        if (id) {
          userDetail.addNewListToMyList({
            id,
            name: listName,
            isSharedList,
          });
          toasterState.setToastMsg(
            'List created successfully',
            TOAST_MSG_TYPES.SUCCESS,
          );
          setListId(id);
          if (checkboxValues.email) {
            addToListWithOptionsState.currentStep = 2;
          } else {
            triggerAddToListCallback(undefined, id);
          }
        } else {
          handleSelectedListClear();
        }
      };

      const response = await myLeadsState.createList({
        name: listName,
        isSharedList,
      });
      setCreateListLoading(false);
      if (response?.data?.id) {
        callback(response);
      } else {
        toasterState.setToastMsg(response?.data?.responseMsg || 'Failed');
      }
    } else if (checkboxValues.email) {
      addToListWithOptionsState.currentStep = 2;
    } else {
      triggerAddToListCallback(undefined, '');
    }
  };

  const handleSaveClick = async () => {
    if (checkboxValues.email || checkboxValues.phone) {
      if (!addToListLoading) {
        const isEnoughCreditsNotAvailable =
          checkboxValues.email && checkboxValues.phone
            ? selectedCount > userDetail.userInfo.phoneCreditsRemaining ||
              selectedCount > userDetail.userInfo.lbCredits
            : checkboxValues.email
              ? selectedCount > userDetail.userInfo.lbCredits
              : checkboxValues.phone
                ? selectedCount > userDetail.userInfo.phoneCreditsRemaining
                : '';
        if (isEnoughCreditsNotAvailable) {
          addToListWithOptionsState.addToListData = {
            ...addToListWithOptionsState.addToListData,
            showNotEnoughCreditsWarning: true,
          };
        } else {
          creditCheckCallback();
        }
      }
    } else {
      toasterState.setToastMsg(
        'Select one of email or mobile number',
        TOAST_MSG_TYPES.FAILURE,
      );
    }
  };

  const selectOptions = useMemo(() => {
    return filterValue('');
  }, [userDetail.myLeadList]);

  return (
    addToListWithOptionsState.showPopup && (
      <Modal
        show
        closeModal={handleCloseClick}
        className={
          addToListWithOptionsState.currentStep === 2
            ? 'zero-bounce-popup'
            : 'add-to-list-popup'
        }
      >
        <LoadingWrapper show={createListLoading} position="absolute" />
        {addToListWithOptionsState.currentStep === 1 ? (
          <>
            <Modal.Icon SvgIcon={DocumentIcon} />
            <Modal.Title>Add to List</Modal.Title>
            <Modal.Content>
              <Select
                placeholder="Select or create new list"
                inlineLabel={false}
                enableValuesOutsideOfOptions
                label="List name"
                enableSearch
                options={selectOptions}
                value={
                  userDetail.myLeadList.find((prop) => {
                    return prop.id === listId;
                  })?.id || (addToListWithOptionsState.newList ? listName : '')
                }
                customFilterMethod={filterValue}
                renderOption={renderOption}
                onChange={async (value) => {
                  const isAvailableOption = userDetail.myLeadList.some(
                    (data) => {
                      return data.id === value;
                    },
                  );
                  if (isAvailableOption) {
                    setListId(value);
                    addToListWithOptionsState.setIsNewList(false);
                  } else {
                    addToListWithOptionsState.setIsNewList(true);
                    setListName(value);
                    setListId('');
                  }
                }}
              />
              {addToListWithOptionsState.newList && (
                <ControlInput
                  className="share-list"
                  label="Share your list with the team"
                  type="checkbox"
                  onChange={() => {
                    setIsSharedList(true);
                  }}
                  checked={isSharedList}
                />
              )}
              {!addToListWithOptionsState.addToListOptions
                .isSingleContactAddToList && (
                <div className="purchase-checkbox-wrapper">
                  <span className="checkbox-label">Must contain</span>

                  <ControlInput
                    type="checkbox"
                    label="Business Email"
                    checked={checkboxValues.email}
                    onChange={() => {
                      const newValue = !checkboxValues.email;
                      handleContainCheckBoxChanges({
                        ...checkboxValues,
                        email: newValue,
                      });
                    }}
                  />
                  <ControlInput
                    type="checkbox"
                    label="Mobile Numbers"
                    checked={checkboxValues.phone}
                    onChange={() => {
                      if (
                        (userDetail.userInfo?.memberSubscription
                          ?.phoneCredits ?? 0) > 0
                      ) {
                        const newValue = !checkboxValues.phone;
                        const newValueForOr =
                          newValue || checkboxValues.email
                            ? false
                            : checkboxValues.phoneOrEmail;
                        handleContainCheckBoxChanges({
                          ...checkboxValues,
                          phone: newValue,
                          phoneOrEmail: newValueForOr,
                        });
                      } else {
                        Utils.mixpanelTrack(
                          MOBILE_NUMBER_EVENTS.MOBILE_NUMBER_UPGRADE_POPUP_SHOWN,
                          {
                            source: 'Add to List',
                          },
                        );
                        commonUpgradePopupState.setShowUpgradePopup(
                          UPGRADE_TRIGGERS.PURCHASE_PHONE,
                        );
                      }
                    }}
                  />
                </div>
              )}
              {addToListWithOptionsState.addToListData
                .showNotEnoughCreditsWarning && (
                <div className="credits-error-wrapper">
                  <WarningIcon />
                  <div className="text-wrapper">
                    <p>
                      {checkboxValues.email &&
                      checkboxValues.phone &&
                      selectedCount > availablePhoneCredits &&
                      selectedCount > availableEmailCredits
                        ? "You don't have enough email & phone credits"
                        : checkboxValues.email &&
                            selectedCount > availableEmailCredits
                          ? "You don't have enough email credits"
                          : checkboxValues.phone &&
                              selectedCount > availablePhoneCredits
                            ? "You don't have enough phone credits"
                            : ''}
                    </p>
                    <p>
                      To proceed with the purchase, either increase your credit
                      balance or adjust your selection accordingly
                    </p>
                  </div>
                </div>
              )}
            </Modal.Content>
            <Modal.Footer>
              <Button
                variant="outline"
                buttonProps={{
                  text: 'Cancel',
                }}
                onClick={handleCloseClick}
              />
              {addToListWithOptionsState.addToListData
                .showNotEnoughCreditsWarning ? (
                <Link
                  className="button"
                  to={PAGE_PATH_MAP.PRICING}
                  onClick={handleCloseClick}
                >
                  Upgrade Now
                </Link>
              ) : (
                <Button
                  buttonProps={{
                    text: checkboxValues.email ? 'Next' : 'Save',
                    disabled: !listId && !listName,
                  }}
                  isLoading={addToListLoading}
                  onClick={handleSaveClick}
                />
              )}
            </Modal.Footer>
          </>
        ) : (
          <ZeroBounceValidation
            addToListLoading={addToListLoading}
            saveCallback={(zbStatuses) => {
              triggerAddToListCallback(zbStatuses);
            }}
            backAction={() => {
              addToListWithOptionsState.currentStep = 1;
            }}
            skipCallback={() => {
              triggerAddToListCallback();
            }}
          />
        )}
      </Modal>
    )
  );
}

export { addToListWithOptionsState };

export default observer(AddToListWithOptions);
