import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import LeadershipHireImg from 'Assets/png/leadership-hire.png';
import ControlInput from 'Components/common/base/ControlInput';
import { applyFilters } from 'Pages/prospectSearch/utils';
import { MX_AS_EVENTS } from 'Utils/constants';
import trackMixpanelEvent from '../trackMixpanelEvent';
import UpgradeSection from '../UpgradeSection';
import './styles.scss';

function RecentJobChanges(props) {
  const { currentFilterState, page, selectedFilter } = props;

  const filterStaticValues = useMemo(() => {
    return selectedFilter.getValues();
  }, []);

  const applyValue = (data) => {
    const dataToPass = {
      suggestionKey: 'recentJobChanges',
      selectedFilters: data,
      currentFilterState,
    };
    applyFilters(dataToPass);

    trackMixpanelEvent({
      selectedValue: data,
      key: 'recentJobChanges',
      page,
      mxUniqueKey: 'filterValue',
      mxEventName: MX_AS_EVENTS.AS_RECENT_JOB_CHANGES_FILTER_APPLIED,
    });
  };

  const isRestricted = selectedFilter.restricted
    ? selectedFilter.restricted()
    : false;

  return (
    <div className={`recent-job-changes${isRestricted ? ' disable' : ''}`}>
      {isRestricted && (
        <UpgradeSection
          title="Your current plan doesn't allow you to filter by job changes"
          imageURL={LeadershipHireImg}
        />
      )}
      <div className="months-radio-selection">
        <p className="radio-title">Started a new job in the last</p>
        <div className="radio-list">
          {filterStaticValues.map((monthItem) => {
            const { label, value } = monthItem;
            return (
              <ControlInput
                key={value}
                type="radio"
                label={label}
                value={value}
                checked={
                  currentFilterState.appliedFilterSectionData
                    ?.recentJobChanges === value
                }
                onChange={applyValue}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default observer(RecentJobChanges);
