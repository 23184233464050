import React from 'react';
import { NavLink } from 'react-router-dom';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Button from '../base/Button';
import './styles.scss';
import BackIconWithText from '../BackIconWithText';

function Tabs(props) {
  const { list, onClick = EMPTY_FUNCTION } = props;

  return (
    <div className="tab-wrapper">
      {list.map((tab) => {
        const { text, Icon, value, href, active } = tab;

        return (
          <NavLink
            key={`tab-${text}`}
            to={href}
            className={({ isActive }) => {
              const activeTab = active !== undefined ? active : isActive;
              return `tab${activeTab ? ' active' : ''}`;
            }}
            onClick={() => {
              onClick(value);
            }}
          >
            <Icon />
            <span>{text}</span>
          </NavLink>
        );
      })}
    </div>
  );
}

function PageContentHeader(props) {
  const { buttonData, tabData, titleData, children, className = '' } = props;
  const { text, Icon, callback } = buttonData ?? {};

  return (
    <div className={`page-content-header ${className}`}>
      <div className="left-content">
        {tabData?.list?.length > 0 ? (
          <Tabs {...tabData} />
        ) : titleData !== undefined ? (
          <BackIconWithText {...titleData} />
        ) : null}
      </div>
      <div className="right-content">
        {children ||
          (buttonData !== undefined && (
            <Button buttonProps={{ text }} Icon={Icon} onClick={callback} />
          ))}
      </div>
    </div>
  );
}

export default PageContentHeader;
