import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { logout } from 'Utils/commonApi';
import * as CONSTANTS from 'Utils/constants';
import LogoutIcon from 'Assets/svg/logout.svg';
import SettingsIcon from 'Assets/svg/new_icons/setting.svg';
import Button from 'Components/common/base/Button';

function AccountProfileWrapper() {
  const { userInfo } = userDetail;
  const { name = '', email = '', role = 'ROLE_MEMBER' } = userInfo;

  const [showDropdown, setShowDropdown] = React.useState(false);

  const showDropdownMenu = () => {
    setShowDropdown(true);
  };

  const hideDropdownMenu = () => {
    setShowDropdown(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div
      className={`account-profile${showDropdown ? ' menu-shown' : ''}`}
      onMouseEnter={showDropdownMenu}
      onMouseLeave={() => {
        hideDropdownMenu();
      }}
    >
      <div className="account-icon-wrap">
        <SettingsIcon />
      </div>
      <div className={`user-dropdown${showDropdown ? '' : ' hide'}`}>
        <div className="list">
          <div className="name">
            <span>{name || email}</span>
            <span className="role">
              {userDetail.userInfo.isEnterpriseAdmin ? 'Admin' : 'User'}
            </span>
          </div>
          <div className="links">
            <Link to="/profile/settings" className="list-item">
              Settings
            </Link>
            {(Utils.isInternalAdminUser() || role === 'ROLE_DATA_ADMIN') && (
              <a
                href={
                  Utils.isInternalAdminUser()
                    ? CONSTANTS.NEW_ADMIN_URL
                    : `${CONSTANTS.NEW_ADMIN_URL}/data-operations`
                }
                className="list-item"
              >
                {Utils.isInternalAdminUser() ? 'Admin Home' : 'Ops Task System'}
              </a>
            )}
          </div>
          <Button
            Icon={LogoutIcon}
            variant="transparent"
            onClick={handleLogout}
            buttonProps={{ text: 'Log Out', className: 'logout' }}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(AccountProfileWrapper);
