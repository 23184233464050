import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import profileState from 'Stores/profile';
import BlueDotIcon from 'Assets/png/blue-dot.png';
import YellowDotIcon from 'Assets/png/yellow-dot.png';
import InfoIcon from 'Assets/svg/info-icon.svg';
import DateRangePicker from 'Components/common/DateRangePicker';
import Select from 'Components/common/base/Select';
import LoadingWrapper from 'Components/common/base/Loader';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { monthNames } from 'Utils/constants';
import CreditsOverviewCard from '../CreditsOverviewCard';
import { lineChartOptions, plugins } from './fixtures';
import ChartCard from '../ChartCard';
import './styles.scss';

const getStringFormat = (type, date) => {
  if (type === 'MONTHLY') {
    return `${monthNames[date.getMonth()].slice(0, 3)} ${date.getFullYear()}`;
  }

  return `${date.getDate()} ${monthNames[date.getMonth()].slice(
    0,
    3,
  )} ${date.getFullYear()}`;
};

const getData = ({ labels, obj, key, type, analyticsData }) => {
  return labels.forEach((label) => {
    obj.push(
      analyticsData.find((usageData) => {
        const sDate = new Date(usageData.startDate);
        return getStringFormat(type, sDate) === label;
      })?.[key] || 0,
    );
  });
};

const ChartWrapper = observer((props) => {
  const { title, startDate, endDate, defaultFrequency, apiType } = props;

  const defaultDateValue = {
    startDate: Number(startDate),
    endDate: Number(endDate),
    key: 'selection',
  };

  const [dateRange, setDateRange] = useState(defaultDateValue);
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState(defaultFrequency);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    const datasets = [];
    if (apiType === 'search') {
      datasets.push({
        label: 'Search API Used',
        data: [],
        borderColor: '#81D9C3',
        backgroundColor: '#81D9C3',
      });
    } else if (apiType === 'purchase') {
      datasets.push(
        {
          label: 'Total Purchased',
          data: [],
          borderColor: '#81D9C3',
          backgroundColor: '#81D9C3',
        },
        {
          label: 'Credits Reduced',
          data: [],
          borderColor: '#F7D17C',
          backgroundColor: '#F7D17C',
          pointStyle: () => {
            const img = new Image();
            img.width = 12;
            img.height = 12;
            img.src = YellowDotIcon;
            return img;
          },
        },
      );
    } else if (apiType === 'enrich') {
      datasets.push(
        {
          label: 'Enrichment Requested',
          data: [],
          borderColor: '#81D9C3',
          backgroundColor: '#81D9C3',
        },
        {
          label: 'Enriched',
          data: [],
          borderColor: '#A19EF8',
          backgroundColor: '#A19EF8',
          pointStyle: () => {
            const img = new Image();
            img.width = 12;
            img.height = 12;
            img.src = BlueDotIcon;
            return img;
          },
        },
        {
          label: 'Credits Reduced',
          data: [],
          borderColor: '#F7D17C',
          backgroundColor: '#F7D17C',
          pointStyle: () => {
            const img = new Image();
            img.width = 12;
            img.height = 12;
            img.src = YellowDotIcon;
            return img;
          },
        },
      );
    }
    const labels = [];

    const endDateForCondition = new Date(dateRange.endDate);
    const adjustedEndDate = new Date(
      endDateForCondition.getFullYear(),
      endDateForCondition.getMonth(),
      endDateForCondition.getDate(),
      23,
      59,
      59,
    );

    if (frequency === 'MONTHLY') {
      const startDateLoop = new Date(dateRange.startDate);
      while (startDateLoop < adjustedEndDate) {
        labels.push(getStringFormat(frequency, startDateLoop));
        startDateLoop.setMonth(startDateLoop.getMonth() + 1);
      }
    } else if (frequency === 'WEEKLY') {
      const startDateLoop = new Date(dateRange.startDate);
      startDateLoop.setDate(startDateLoop.getDate() - startDateLoop.getDay());
      while (startDateLoop < adjustedEndDate) {
        labels.push(getStringFormat(frequency, startDateLoop));
        startDateLoop.setDate(startDateLoop.getDate() + 7);
      }
    } else if (frequency === 'DAILY') {
      const startDateLoop = new Date(dateRange.startDate);
      while (startDateLoop < adjustedEndDate) {
        labels.push(getStringFormat(frequency, startDateLoop));
        startDateLoop.setDate(startDateLoop.getDate() + 1);
      }
    }

    if (labels.length === 1) {
      datasets.forEach((prop) => {
        // eslint-disable-next-line no-param-reassign
        prop.pointRadius = 10;
      });
    } else {
      datasets.forEach((prop) => {
        // eslint-disable-next-line no-param-reassign
        prop.pointRadius = 0;
      });
    }

    const dataToPass = {
      analyticsData: profileState.APIUsageAnalyticsData?.[apiType] || [],
      labels,
      type: frequency,
    };
    if (apiType === 'search') {
      getData({ obj: datasets[0].data, key: 'searchAPI', ...dataToPass });
    } else if (apiType === 'purchase') {
      getData({
        obj: datasets[0].data,
        key: 'purchaseAPI',
        ...dataToPass,
      });
      getData({
        obj: datasets[1].data,
        key: 'purchaseCreditReduced',
        ...dataToPass,
      });
    } else if (apiType === 'enrich') {
      getData({
        obj: datasets[0].data,
        key: 'enrichRequested',
        ...dataToPass,
      });
      getData({
        obj: datasets[1].data,
        key: 'enrichAPI',
        ...dataToPass,
      });
      getData({
        obj: datasets[2].data,
        key: 'enrichCreditReduced',
        ...dataToPass,
      });
    }
    setChartData({ labels, datasets });
  }, [profileState.APIUsageAnalyticsData, frequency, dateRange]);

  const isEmptyState = !(
    profileState.APIUsageAnalyticsData?.[apiType]?.length > 0 &&
    !profileState.APIUsageAnalyticsData?.[apiType]?.every((usageData) => {
      if (apiType === 'search') {
        return usageData.searchAPI === 0 || usageData.searchAPI === null;
      }
      if (apiType === 'enrich') {
        return (
          (usageData.enrichRequested === 0 ||
            usageData.enrichRequested === null) &&
          (usageData.enrichAPI === 0 || usageData.enrichAPI === null) &&
          (usageData.enrichCreditReduced === 0 ||
            usageData.enrichCreditReduced === null)
        );
      }
      return (
        (usageData.purchaseAPI === 0 || usageData.purchaseAPI === null) &&
        (usageData.purchaseCreditReduced === 0 || usageData.enrichAPI === null)
      );
    })
  );

  return (
    <ChartCard
      title={title}
      type="line"
      emptyState={isEmptyState}
      showEmptyStateButton={false}
      isLoading={loading}
      chartData={{ options: lineChartOptions, data: chartData, plugins }}
    >
      <DateRangePicker
        defaultValue={dateRange}
        editableDateInputs={false}
        closeIcon={false}
        onClose={() => {
          setDateRange(defaultDateValue);
        }}
        maxDate={endDate}
        onSubmit={async (range) => {
          setLoading(true);
          const newValue = {
            startDate: Number(range.startDate),
            endDate: Number(range.endDate),
          };
          await profileState.getAPIUsageAnalytics(
            {
              frequency,
              ...newValue,
            },
            apiType,
          );
          setLoading(false);
          setDateRange(newValue);
        }}
      />
      <Select
        options={[
          { label: 'Monthly', value: 'MONTHLY' },
          { label: 'Weekly', value: 'WEEKLY' },
          { label: 'Daily', value: 'DAILY' },
        ]}
        value={frequency}
        label="Frequency"
        onChange={async (value) => {
          setLoading(true);
          setFrequency(value);
          await profileState.getAPIUsageAnalytics(
            {
              frequency: value,
              ...dateRange,
            },
            apiType,
          );
          setLoading(false);
        }}
      />
      {frequency === 'WEEKLY' && (
        <i className="note">
          <sup>*</sup>Date represents starting date of the week
        </i>
      )}
    </ChartCard>
  );
});

function getValue(used, total) {
  return `${used ? Utils.numberFormat(used) : 0}/${Utils.numberFormat(total)}`;
}

function getCreditsList(data) {
  return [
    {
      label: 'Search API Credits',
      getValue: () => {
        return getValue(
          data.searchAPICreditsUsed,
          data.allottedSearchAPICredits,
        );
      },
      enabled: data.allottedSearchAPICredits > 0,
    },
    {
      label: 'Purchase API Credits',
      getValue: () => {
        return getValue(
          data.purchaseAPICreditsUsed,
          data.allottedPurchaseAPICredits,
        );
      },
      enabled: data.allottedPurchaseAPICredits > 0,
    },
    {
      label: 'Enrich API Credits',
      getValue: () => {
        return getValue(
          data.enrichAPICreditsUsed,
          data.allottedEnrichAPICredits,
        );
      },
      enabled: data.allottedEnrichAPICredits > 0,
    },
  ];
}

function APIUsageChart() {
  const [loading, setLoading] = useState(false);

  const defaultFrequency = 'MONTHLY';
  const currentDate = new Date();
  let defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 6);

  defaultStartDate =
    Utils.getStartDateComparedToSubscriptionCreatedDate(defaultStartDate);

  async function getAPIData() {
    setLoading(true);
    await profileState.getAPIUsageAnalytics({
      frequency: defaultFrequency,
      startDate: Number(defaultStartDate),
      endDate: Number(currentDate),
    });
    setLoading(false);
  }

  useEffect(() => {
    getAPIData();
  }, []);

  const creditsList = useMemo(() => {
    return getCreditsList(profileState.APIUsageAnalyticsData);
  }, [profileState.APIUsageAnalyticsData]);

  return (
    <div className="api-usage-analytics-page">
      <LoadingWrapper position="fixed" show={loading} />
      <CreditsOverviewCard title="API Usage Overview" list={creditsList} />
      <div className="api-note-wrapper">
        <div className="icon-wrapper">
          <InfoIcon />
        </div>
        <p>
          To ensure you get the most out of your analytics, we recommend
          migrating to API version 3. This will provide you with valuable
          insights into your API usage.
        </p>
      </div>
      {profileState.apiUsedForChart.map((api) => {
        const { key, title } = api;
        return (
          <ChartWrapper
            apiType={key}
            key={key}
            title={title}
            startDate={defaultStartDate}
            defaultFrequency={defaultFrequency}
            endDate={currentDate}
          />
        );
      })}
    </div>
  );
}

export default observer(APIUsageChart);
