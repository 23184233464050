import React, { useState } from 'react';
import { observer } from 'mobx-react';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import { URLS, makeApi } from 'Utils/apiUtil';
import Modal from '../base/Modal';
import Button from '../base/Button';
import Input from '../base/Input';
import './styles.scss';

const { EMAIL_TYPES, MX_UPDATE_EMAIL_EVENTS } = CONSTANTS;
const { PRIMARY, SECONDARY } = EMAIL_TYPES;

const {
  LB_UPDATE_EMAIL_CLICKED,
  LB_UPDATE_EMAIL_SUCCESS,
  LB_UPDATE_EMAIL_FAILED,
} = MX_UPDATE_EMAIL_EVENTS;

function InputError(props) {
  const { errorInfo } = props;

  return errorInfo === 'email_exist' ? (
    <>
      The email address you have entered is already registered. Please{' '}
      <a href="mailto:sales@adapt.io" target="_blank" rel="noopener noreferrer">
        contact us
      </a>{' '}
      for more details.
    </>
  ) : errorInfo === 'invalid_email_format' ? (
    <>Please enter a valid email address</>
  ) : errorInfo === 'invalid_business_email' ? (
    <>Please enter a valid business email address</>
  ) : (
    <>
      Oops! Something went wrong! Please try again in some time or{' '}
      <a href="mailto:sales@adapt.io" target="_blank" rel="noopener noreferrer">
        contact us
      </a>
      .
    </>
  );
}

const UpdateUserEmailWrapper = observer(() => {
  const { showUpdateUserEmailPopup, userInfo } = userDetail;
  const { email } = userInfo;
  const [newEmail, setNewEmail] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [errorInfo, setErrorInfo] = useState(null);

  const changeValue = (value) => {
    setNewEmail(value);
  };

  const updateEmail = async () => {
    try {
      setErrorInfo(null);
      if (!isLoading) {
        if (newEmail) {
          const mxData = {
            verificationType: showUpdateUserEmailPopup,
            newEmail,
            oldEmail: email,
          };
          Utils.mixpanelTrack(LB_UPDATE_EMAIL_CLICKED, mxData);
          setIsLoading(true);
          const config = {
            url: URLS.updateMemberEmail,
            method: 'POST',
            data: {
              email: newEmail,
              isSecondaryEmail: showUpdateUserEmailPopup === SECONDARY,
            },
          };

          const response = await makeApi(config);

          let msgStr = null;
          const { data = null } = response;
          setIsLoading(false);
          if (data) {
            const { status = 'failed', error = '' } = data;
            if (status === 'success') {
              Utils.mixpanelTrack(LB_UPDATE_EMAIL_SUCCESS, mxData);
              if (showUpdateUserEmailPopup === PRIMARY) {
                Utils.mixpanelAlias(newEmail);
              }
              Utils.redirectToLbHomePage();
            } else if (status === 'failed' && error !== '') {
              msgStr = error;
              Utils.mixpanelTrack(LB_UPDATE_EMAIL_FAILED, {
                ...mxData,
                error,
              });
            } else {
              msgStr = 'common_error';
              Utils.mixpanelTrack(LB_UPDATE_EMAIL_FAILED, {
                ...mxData,
                error,
              });
            }
            setErrorInfo(msgStr);
          }
        } else {
          setErrorInfo('invalid_email_format');
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log('update email failed', e);
    }
  };

  return (
    <div className="content-info-wrapper">
      <div className="title">Incorrect Email?</div>
      <div className="content">Enter your updated email here</div>
      <div className="email-input-wrapper">
        <Input
          inputProps={{
            type: 'email',
            placeholder: Utils.isProspectorRegisteredUser()
              ? 'Enter Your Email*'
              : 'Business Email*',
            value: newEmail,
          }}
          onChange={changeValue}
          error={<InputError errorInfo={errorInfo} />}
        />
      </div>
      <Button
        buttonProps={{ text: 'Update' }}
        isLoading={isLoading}
        onClick={updateEmail}
      />
    </div>
  );
});

function UpdateUserEmailPopup() {
  const { showUpdateUserEmailPopup } = userDetail;

  const closeClick = () => {
    userDetail.setShowUpdateUserEmailPopup(null);
  };

  return (
    showUpdateUserEmailPopup !== null && (
      <Modal show className="update-user-email-popup" closeModal={closeClick}>
        <UpdateUserEmailWrapper />
      </Modal>
    )
  );
}

export default observer(UpdateUserEmailPopup);
