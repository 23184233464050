import Utils from 'Utils/utils';

function getSavedSearchTemplateById(savedSearchList, id, callback) {
  let searchTemplatePostData = '{}';
  for (let i = 0; i < savedSearchList.length; i += 1) {
    if (savedSearchList[i].id === id) {
      searchTemplatePostData = savedSearchList[i].searchTemplate;
      callback(savedSearchList[i].id);
      break;
    }
  }

  const searchJson = JSON.parse(searchTemplatePostData);
  if (searchJson.limit !== undefined && searchJson.limit > 100) {
    searchJson.limit = 50;
  }
  return searchJson;
}

const applyFilters = ({
  suggestionKey,
  selectedFilters,
  currentFilterState,
  doSearch = true,
}) => {
  currentFilterState.setAppliedFilters(
    suggestionKey,
    selectedFilters,
    null,
    true,
    doSearch,
  );
};

const applyFiltersWithDelay = Utils.debounce(applyFilters, 1500);

function applyFilterValueForObjects(data) {
  const {
    key,
    value,
    multiple = true,
    setMultipleValue = false,
    currentFilterState,
    suggestionKey,
    mandatoryFields = [],
  } = data;

  const selectedData = Utils.copyObjectWithoutReference(
    currentFilterState.appliedFilterSectionData?.[suggestionKey],
  );

  if (multiple) {
    if (setMultipleValue) {
      selectedData[key] = value;
    } else if (selectedData?.[key]?.includes(value)) {
      selectedData[key] = selectedData[key].filter((itemValue) => {
        return itemValue !== value;
      });
    } else {
      selectedData[key] = [...(selectedData[key] ?? []), value];
    }
  } else {
    selectedData[key] = value;
  }

  const filterData = {
    suggestionKey,
    selectedFilters: selectedData,
    currentFilterState,
  };
  const isMandatoryValuesPresent = mandatoryFields.every((mandatoryField) => {
    return !!selectedData[mandatoryField];
  });
  if (isMandatoryValuesPresent) {
    currentFilterState.resultLoading = true;
  }

  applyFilters({ ...filterData, doSearch: false });
  applyFiltersWithDelay(filterData);
}

export {
  getSavedSearchTemplateById,
  applyFiltersWithDelay,
  applyFilters,
  applyFilterValueForObjects,
};
