import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import PlusIcon from 'Assets/svg/plus-icon.svg';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import Button from 'Components/common/base/Button';
import LoadingWrapper from 'Components/common/base/Loader';
import Menu from 'Components/common/base/Menu';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import Title from 'Components/common/base/Title';
import userDetail from 'Stores/userDetail';
import profileState from 'Stores/profile';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import UserDataPopup from './userDataPopup';
import './styles.scss';

function ManageTeam() {
  const {
    noOfSeatsEligible,
    noOfSeatsUsed,
    isEnterpriseAdmin,
    memberSubscription,
  } = userDetail.userInfo;

  const [userDataPopup, setUserDataPopup] = useState({
    show: false,
    type: '',
    data: {},
  });

  useEffect(() => {
    profileState.getTeamDetails();
  }, []);

  const removeUser = (member) => {
    const { emailId, id } = member;

    commonModalState.setShowModal(true, {
      modalProps: { className: 'remove-user-modal' },
      title: 'Remove User',
      description: (
        <>
          Are you sure you want to remove{' '}
          <span className="highlight">{emailId}</span> from your team plan ?
        </>
      ),
      primaryButtonText: 'Confirm',
      buttonCallback: () => {
        profileState.removeUser({ email: emailId, id });
        Utils.mixpanelTrack(CONSTANTS.MANAGE_TEAM_EVENTS.LB_DELETE_MEMBER, {
          memberEmail: emailId,
        });
      },
    });
  };

  const onDataSubmit = async (data) => {
    const isCreditLimitNotSet = data.limit === '';
    if (userDataPopup.type === 'ADD') {
      Utils.mixpanelTrack(CONSTANTS.MANAGE_TEAM_EVENTS.LB_ADD_MEMBER, {
        memberEmail: data.email,
        creditLimit: isCreditLimitNotSet ? 'No Limit' : data.credits,
      });
      await profileState.addUser(data);
    } else {
      Utils.mixpanelTrack(CONSTANTS.MANAGE_TEAM_EVENTS.LB_EDIT_MEMBER, {
        memberEmail: data.email,
        creditLimit: isCreditLimitNotSet ? 'No Limit' : data.credits,
      });
      await profileState.editUser({
        limit: data.limit,
        email: data.email,
        credits: data.credits,
        memberId: data.memberId,
      });
    }

    setUserDataPopup({
      show: false,
      type: '',
      data: {},
    });
  };

  const billingCycle =
    profileState.teamDetails?.startDate && profileState.teamDetails?.endDate
      ? `${Utils.getFormattedDate(
          profileState.teamDetails?.startDate,
        )} - ${Utils.getFormattedDate(profileState.teamDetails.endDate)}`
      : '';

  const monthlyCycle =
    profileState.teamDetails?.monthlyCreditUsageStartDate &&
    profileState.teamDetails?.monthlyCreditUsageEndDate
      ? `${Utils.getFormattedDate(
          profileState.teamDetails.monthlyCreditUsageStartDate,
        )} - ${Utils.getFormattedDate(profileState.teamDetails.monthlyCreditUsageEndDate)}`
      : '';

  const isYearlyLimitAvailable = memberSubscription?.frequencyType === 1;

  return (
    <div
      className={`manage-team-page ${isYearlyLimitAvailable ? 'has-yearly' : ''}`}
    >
      <Title title="Manage Team" />
      {(!isEnterpriseAdmin ||
        noOfSeatsEligible <= 1 ||
        memberSubscription === null) && (
        <Navigate to="/profile/settings" replace />
      )}
      <LoadingWrapper
        show={profileState.teamMemberApiLoading}
        position="fixed"
      />
      {userDataPopup.show && (
        <UserDataPopup
          title={userDataPopup.type === 'ADD' ? 'Add New User' : 'Edit User'}
          onDataSubmit={onDataSubmit}
          type={userDataPopup.type}
          data={userDataPopup.data}
          options={userDataPopup.options}
          onClose={() => {
            setUserDataPopup({
              show: false,
              type: '',
              data: {},
            });
          }}
        />
      )}
      <div className="top-section">
        <div className="seats-and-billing">
          My Team
          <span className="seats-used">
            (
            <span>
              {noOfSeatsUsed}/{noOfSeatsEligible}
            </span>{' '}
            seats used)
          </span>
        </div>
        <Button
          onClick={() => {
            if (
              noOfSeatsUsed &&
              noOfSeatsEligible &&
              noOfSeatsUsed < noOfSeatsEligible
            ) {
              setUserDataPopup({
                show: true,
                type: 'ADD',
                data: {},
              });
            } else {
              commonModalState.setShowModal(true, {
                title: 'Add User',
                description: (
                  <>
                    You have used all the seats available in your current plan.
                    To add more users, please write to us at{' '}
                    <a href="mailto:sales@adapt.io">sales@adapt.io</a>.
                  </>
                ),
                primaryButtonText: 'Ok',
              });
            }
          }}
          Icon={PlusIcon}
          buttonProps={{ text: 'Add User' }}
        />
      </div>
      <div className="table-header-wrap">
        <div className="team-table-header">
          <span>Name</span>
          <span>Email Address</span>
          <span className="credit-limit">
            Credit Limit
            <ToolTipWithIcon>
              Credit limit is the maximum credit that can be used in a{' '}
              {isYearlyLimitAvailable ? 'month/year' : 'month'}.
            </ToolTipWithIcon>
          </span>
          <span className="credit-used">
            Monthly Usage
            <ToolTipWithIcon>
              This shows the total number of credits between{' '}
              <span>{monthlyCycle}</span>
            </ToolTipWithIcon>
          </span>
          {isYearlyLimitAvailable && (
            <span className="credit-used">
              Yearly Usage
              <ToolTipWithIcon>
                This shows the total number of credits used for the billing
                cycle <span>{billingCycle}</span>
              </ToolTipWithIcon>
            </span>
          )}
          <span className="status">Status</span>
          <span />
        </div>
      </div>
      <div className="team-table-body">
        <div className="team-table-body-inner">
          {profileState.isGetTeamMemberLoading
            ? Array.from({ length: 10 }).map((...args) => {
                return (
                  <div
                    key={`member-placeholder-${args[1]}`}
                    className="team-member-row placeholder"
                  >
                    <div className="name-wrapper">
                      <div className="name linear-background" />
                      <div className="last-login linear-background" />
                    </div>
                    <div className="email linear-background" />
                    <div className="credit-limit linear-background" />
                    <div className="credit-used linear-background" />
                    {isYearlyLimitAvailable && (
                      <div className="credit-used linear-background" />
                    )}
                    <div className="status linear-background" />
                    <div className="linear-background" />
                  </div>
                );
              })
            : profileState.teamMembers.map((teamMember) => {
                const { status = '', emailId, memberId } = teamMember;

                const name = `${teamMember.firstName ?? ''}${
                  teamMember.lastName ? ` ${teamMember.lastName}` : ''
                }`;

                return (
                  <div className="team-member-row" key={memberId}>
                    <div className="name-wrapper">
                      <div className="name-with-badge">
                        <span className="name" title={name}>
                          {name}
                        </span>
                        {teamMember.role === 'ADMIN' && (
                          <span className="badge">Admin</span>
                        )}
                      </div>
                      {Utils.isNotUndefinedNotNull(teamMember.lastActivity) && (
                        <div className="last-login">
                          Last Activity:{' '}
                          {Utils.getFormattedDate(teamMember.lastActivity)}
                        </div>
                      )}
                    </div>
                    <div className="email" title={emailId}>
                      {emailId}
                    </div>
                    <div className="credit-limit">
                      {status === 'INACTIVE'
                        ? ' - '
                        : teamMember.lbCreditsIndividualLimitFrequency ===
                              'MONTHLY' ||
                            teamMember.lbCreditsIndividualLimitFrequency ===
                              'YEARLY'
                          ? `${Utils.numberFormat(teamMember.lbCreditsIndividualLimit)}${isYearlyLimitAvailable ? (teamMember.lbCreditsIndividualLimitFrequency === 'YEARLY' ? ' / year' : ' / month') : ''}`
                          : 'No Limit Set'}
                    </div>
                    <div className="credit-used">
                      {teamMember.lbCreditsIndividualLimitUsedMonthly > 0
                        ? Utils.numberFormat(
                            teamMember.lbCreditsIndividualLimitUsedMonthly,
                          )
                        : '-'}
                    </div>
                    {isYearlyLimitAvailable && (
                      <div className="credit-used">
                        {teamMember.lbCreditsIndividualLimitUsedYearly > 0
                          ? Utils.numberFormat(
                              teamMember.lbCreditsIndividualLimitUsedYearly,
                            )
                          : '-'}
                      </div>
                    )}
                    <div className={`status ${status.toLowerCase()}`}>
                      {status.toLowerCase()}
                    </div>
                    {status !== 'INACTIVE' && (
                      <Menu align="end" fixedPosition>
                        <Menu.Item
                          onClick={() => {
                            const limit =
                              teamMember.lbCreditsIndividualLimitFrequency ??
                              '';
                            setUserDataPopup({
                              show: true,
                              type: 'EDIT',
                              data: {
                                memberId: teamMember.memberId,
                                firstName: teamMember.firstName,
                                lastName: teamMember.lastName,
                                email: teamMember.emailId,
                                credits:
                                  teamMember.lbCreditsIndividualLimit || null,
                                limit,
                                lbCreditsIndividualLimitUsedMonthly:
                                  teamMember.lbCreditsIndividualLimitUsedMonthly,
                                lbCreditsIndividualLimitUsedYearly:
                                  teamMember.lbCreditsIndividualLimitUsedYearly,
                              },
                            });
                          }}
                          text="Edit"
                        />
                        {teamMember.role !== 'ADMIN' && (
                          <Menu.Item
                            onClick={() => {
                              removeUser(teamMember);
                            }}
                            text="Delete"
                          />
                        )}
                      </Menu>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
      <div className="footer-text">
        {profileState.teamDetails?.startDate &&
          profileState.teamDetails?.endDate && (
            <span className="billing-cycle">
              Billing Cycle: <span className="text">{billingCycle}</span>
            </span>
          )}
        {profileState.teamDetails.monthlyCreditUsageEndDate &&
          Utils.isLBPaidSubscription() && (
            <div className="note-wrapper">
              <span className="label">Note: </span>
              <span className="text">Monthly credit limit resets on </span>
              <span className="date">
                {Utils.getFormattedDate(
                  profileState.teamDetails.monthlyCreditUsageEndDate,
                )}
              </span>
              {isYearlyLimitAvailable && (
                <>
                  <span className="text">, Yearly credit limit resets on </span>
                  <span className="date">
                    {Utils.getFormattedDate(profileState.teamDetails.endDate)}
                  </span>
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export default observer(ManageTeam);
