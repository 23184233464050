import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RightArrow from 'Assets/svg/right-arrow.svg';
import userDetail from 'Stores/userDetail';
import myLeadsState from 'Stores/myLeads';
import { observer } from 'mobx-react';
import SharedIcon from 'Components/common/SharedListIcon';
import Utils from 'Utils/utils';

function LeadsList(props) {
  const navigate = useNavigate();
  const { list, showCreator } = props;

  return list.map((item) => {
    return (
      <Link
        to={item.id ? `/my-leads/list/${item.id}` : '/my-leads/list'}
        onClick={(e) => {
          e.preventDefault();
          navigate(item.id ? `/my-leads/list/${item.id}` : '/my-leads/list');
        }}
        key={item.id}
        className={`leads-list-item${
          myLeadsState.selectedList.id === item.id ? ' selected' : ''
        }`}
      >
        <span className="list-name">
          <span className="show-ellipsis" title={item.name}>
            {item.name}
          </span>
          <SharedIcon data={item} />
        </span>
        {showCreator && (
          <span>
            {item.memberId === userDetail.userInfo.memberId
              ? 'Myself'
              : userDetail.sharedListAuthorData[item.memberId]}
          </span>
        )}
        <span>{Utils.numberFormat(item.count || 0)}</span>
        <span className={`${!item.createdDate ? 'default' : ''}`}>
          {item.createdDate
            ? Utils.getFormattedDate(item.createdDate, true)
            : '- Default -'}
        </span>
        <span
          className={`${
            !item.lastPurchasedDate ? 'default not-available-text' : ''
          }`}
        >
          {item.lastPurchasedDate
            ? Utils.getFormattedDate(item.lastPurchasedDate, true)
            : '- NA -'}
        </span>
        <RightArrow className="right-arrow" />
      </Link>
    );
  });
}

export default observer(LeadsList);
