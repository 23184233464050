/* eslint-disable quotes */
/* eslint-disable react/no-unescaped-entities */

import React, { useState, useEffect } from 'react';
import Utils from 'Utils/utils';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import adaptLogo from 'Assets/logo/logo_new.png';
import passwordSetupImg from 'Assets/png/passwordSetup.png';
import mailchimpIcon from 'Assets/png/email_campaigns/mailchimp.png';
import sendinblueIcon from 'Assets/png/email_campaigns/sendinblue.png';
import hubspotIcon from 'Assets/png/email_campaigns/hubspot.png';
import campaignMonitorIcon from 'Assets/png/email_campaigns/campaign-monitor.png';
import constantContactIcon from 'Assets/png/email_campaigns/constant-contact.png';
import dripIcon from 'Assets/png/email_campaigns/drip.png';
import moosendIcon from 'Assets/png/email_campaigns/moosend.png';
import salesforceIcon from 'Assets/png/crms/salesforce.png';
import zohoIcon from 'Assets/png/crms/zoho.png';
import pipedriveIcon from 'Assets/png/crms/pipedrive.png';
import freshsalesIcon from 'Assets/png/crms/freshsales.png';
import msDynamicsIcon from 'Assets/png/crms/ms-dynamics.png';
import zendeshIcon from 'Assets/png/crms/zendesh.png';
import LoadingWrapper from 'Components/common/base/Loader';
import Button from 'Components/common/base/Button';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import Title from 'Components/common/base/Title';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import { URLS, makeApi } from 'Utils/apiUtil';
import '../../../styles/layout/_passwordSetup.scss';

const EMAIL_CAMPAIGN_TOOL_LOGOS = {
  MailChimp: mailchimpIcon,
  SendinBlue: sendinblueIcon,
  Hubspot: hubspotIcon,
  'Campaign Monitor': campaignMonitorIcon,
  'Constant Contact': constantContactIcon,
  Drip: dripIcon,
  Moosend: moosendIcon,
};
const CRM_TOOL_LOGOS = {
  Salesforce: salesforceIcon,
  HubSpot: hubspotIcon,
  'Zoho CRM': zohoIcon,
  Pipedrive: pipedriveIcon,
  Freshsales: freshsalesIcon,
  'Dynamics 365': msDynamicsIcon,
  Zendesk: zendeshIcon,
};
const {
  MX_SET_PASSWORD_EVENTS,
  PAGE_PATH_MAP,
  SEOCookieQueryMapping,
  USER_INTEREST_LIST,
} = CONSTANTS;
const { PASSWORD_SETUP } = PAGE_PATH_MAP;
const {
  LB_SET_PASSWORD_PAGE_VIEWED,
  LB_SET_PASSWORD_CLICKED,
  LB_SET_PASSWORD_SUCCESS,
  LB_USE_CASE_LIST_PAGE_VIEWED,
  LB_USE_CASE_LIST_UPDATED,
  LB_USE_CASE_LIST_SKIPPED,
} = MX_SET_PASSWORD_EVENTS;

const PWD_ERROR_MAP = {
  EMPTY: 'Password is required',
  MINIMUM_CHARATER: 'Password should have at least 6 characters',
  NOT_MATCH: "Passwords don't match",
};

function StepIndicator(props) {
  const { currentStep = 1 } = props;
  return (
    <div className={`step-indicator ${currentStep === 2 ? 'step-two' : ''}`}>
      <div className="circle" />
      <div className="rectangle" />
    </div>
  );
}

function PasswordSetupWrapper(props) {
  const { passwordUpdateSuccesCbk = () => {} } = props;
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pwdErr, setPwdErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = () => {
    let validationSuccess = false;
    setPwdErr(null);
    if (newPassword === '' || confirmPassword === '') {
      setPwdErr('EMPTY');
    } else if (newPassword.length < 6) {
      setPwdErr('MINIMUM_CHARATER');
    } else if (newPassword !== confirmPassword) {
      setPwdErr('NOT_MATCH');
    } else {
      validationSuccess = true;
    }
    return validationSuccess;
  };

  const handleUpdatePasswordSubmitAction = async () => {
    validatePassword();

    try {
      setPwdErr(null);
      if (!isLoading) {
        if (validatePassword()) {
          Utils.mixpanelTrack(LB_SET_PASSWORD_CLICKED, {});
          setIsLoading(true);
          const config = {
            url: URLS.setUserPassword,
            method: 'POST',
            data: {
              password: newPassword,
            },
          };

          const response = await makeApi(config);

          const { data = null } = response;
          setIsLoading(false);
          if (data) {
            Utils.mixpanelTrack(LB_SET_PASSWORD_SUCCESS, {});
            passwordUpdateSuccesCbk(true);
          } else {
            setPwdErr('common_error');
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.error('update password failed', e);
    }
  };

  const handleSubmitKeyboardAction = (event) => {
    if (event.keyCode === 13) {
      handleUpdatePasswordSubmitAction();
    }
    const curId = event.target.id;
    const val = event.target.value;
    if (curId === 'newPassword' && val.length < 6) {
      setPwdErr('MINIMUM_CHARATER');
    } else if (curId === 'confirmPassword' && val !== newPassword) {
      setPwdErr('NOT_MATCH');
    } else {
      setPwdErr(null);
    }
  };

  const handleFocusAction = (event) => {
    event.persist();
    const val = event.target.value;
    if (val.length < 6) {
      setPwdErr('MINIMUM_CHARATER');
    }
  };

  const updateNewPassword = (value) => {
    setNewPassword(value);
  };

  const updateConfirmPassword = (value) => {
    setConfirmPassword(value);
  };

  useEffect(() => {
    Utils.mixpanelTrack(LB_SET_PASSWORD_PAGE_VIEWED, {});
  }, []);

  return (
    <div className="password-setup-wrapper">
      <img
        src={passwordSetupImg}
        className="password-setup-img"
        alt="passwordSetupImg"
      />
      <div className="title">Set your password</div>
      <Input
        inputProps={{
          name: 'newPassword',
          type: 'password',
          placeholder: 'New Password*',
          value: newPassword,
          onFocus: handleFocusAction,
        }}
        onChange={updateNewPassword}
      />
      <Input
        inputProps={{
          name: 'confirmPassword',
          type: 'password',
          placeholder: 'Confirm Password*',
          value: confirmPassword,
          onFocus: handleFocusAction,
        }}
        onChange={updateConfirmPassword}
      />
      <span
        className={`error-wrapper ${pwdErr !== null ? '' : 'hide-visibility'}`}
      >
        {PWD_ERROR_MAP[pwdErr] ? (
          PWD_ERROR_MAP[pwdErr]
        ) : pwdErr === 'common_error' ? (
          <>
            Oops! Something went wrong! Please try again in some time or{' '}
            <a
              href="mailto:sales@adapt.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
            .
          </>
        ) : (
          ''
        )}
      </span>

      <Button
        buttonProps={{ text: 'Continue' }}
        isLoading={isLoading}
        onClick={handleUpdatePasswordSubmitAction}
      />
    </div>
  );
}

function ListOfEmailCampaigns(props) {
  const { setSelectedEmailCampaignTool, selectedEmailCampaignTool, toolsList } =
    props;

  const handleToolSelection = (e, item) => {
    if (e) {
      e.preventDefault();
    }
    if (selectedEmailCampaignTool === item) {
      setSelectedEmailCampaignTool('');
    } else {
      setSelectedEmailCampaignTool(item);
    }
  };

  return (
    toolsList.length > 0 && (
      <div className="tools-list-wrapper">
        <div className="tools-title">Which tool are you using?</div>
        <div className="tools-list">
          {toolsList.map((item, index) => {
            const itemClassName = item.replaceAll(' ', '');
            return (
              <div
                className={`tools-item ${
                  item === selectedEmailCampaignTool ? 'selected' : ''
                } ${itemClassName}`}
                key={`${item}-${index}`}
                onClick={(e) => handleToolSelection(e, item)}
              >
                {EMAIL_CAMPAIGN_TOOL_LOGOS[item] ? (
                  <img src={EMAIL_CAMPAIGN_TOOL_LOGOS[item]} alt={item} />
                ) : (
                  item
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

function ListOfCrms(props) {
  const { setSelectedCrmTool, selectedCrmTool, toolsList } = props;

  const handleToolSelection = (e, item) => {
    if (e) {
      e.preventDefault();
    }
    if (selectedCrmTool === item) {
      setSelectedCrmTool('');
    } else {
      setSelectedCrmTool(item);
    }
  };

  return (
    toolsList.length > 0 && (
      <div className="tools-list-wrapper">
        <div className="tools-title">Which tool are you using?</div>
        <div className="tools-list">
          {toolsList.map((item, index) => {
            const itemClassName = item.replaceAll(' ', '');
            return (
              <div
                className={`tools-item ${
                  item === selectedCrmTool ? 'selected' : ''
                } ${itemClassName}`}
                key={`${item}-${index}`}
                onClick={(e) => handleToolSelection(e, item)}
              >
                {CRM_TOOL_LOGOS[item] ? (
                  <img src={CRM_TOOL_LOGOS[item]} alt={item} />
                ) : (
                  item
                )}
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

function UserInterestWrapper() {
  const [userInterestList, setUserInterestList] = useState(USER_INTEREST_LIST);
  const [errorInfo, setErrorInfo] = useState(null);

  const [selectedEmailCampaignTool, setSelectedEmailCampaignTool] =
    useState('');
  const [selectedCrmTool, setSelectedCrmTool] = useState('');

  const getInterestListPostData = () => {
    const userList = [];

    // eslint-disable-next-line array-callback-return
    userInterestList.map((item) => {
      const { selected, value } = item;
      if (selected) {
        userList.push(value);
      }
    });
    return userList;
  };

  const handleSubmitAction = async () => {
    try {
      const postData = getInterestListPostData();
      setErrorInfo(null);
      if (postData.length > 0) {
        const toolsData = {};
        if (selectedEmailCampaignTool !== '') {
          toolsData.emailToolUsed = selectedEmailCampaignTool;
        }
        if (selectedCrmTool !== '') {
          toolsData.crmToolUsed = selectedCrmTool;
        }
        Utils.mixpanelTrack(LB_USE_CASE_LIST_UPDATED, {
          userInterestList: postData,
          ...toolsData,
        });
        const config = {
          url: URLS.captureUserInterestInAdapt,
          method: 'POST',
          data: {
            useCaseList: postData,
            ...toolsData,
          },
        };
        makeApi(config);
        Utils.checkAndRedirectTrialUser();
      } else {
        setErrorInfo('EMPTY');
      }
    } catch (e) {
      console.error('update email failed', e);
    }
  };

  const handleSkipAction = () => {
    Utils.mixpanelTrack(LB_USE_CASE_LIST_SKIPPED, {});
    Utils.checkAndRedirectTrialUser();
  };

  const handleCheckboxClick = (selectedKey) => {
    const tempList = userInterestList.map((item) => {
      const { key, selected } = item;
      const tempObj = Utils.copyObjectWithoutReference(item);
      if (selectedKey === 'EMAIL_CAMPAIGN_LIST' && selected) {
        setSelectedEmailCampaignTool('');
      } else if (selectedKey === 'ENRICH_CRM_DATA' && selected) {
        setSelectedCrmTool('');
      }

      if (key === selectedKey) {
        tempObj.selected = !selected;
      }
      return tempObj;
    });
    setUserInterestList(tempList);
  };

  useEffect(() => {
    Utils.mixpanelTrack(LB_USE_CASE_LIST_PAGE_VIEWED, {});
  }, []);

  return (
    <div className="password-setup-wrapper user-interest-wrapper">
      <div className="title">What brings you to Adapt?</div>

      <div className="primary-description">
        Select the options that describe your goals. Don't worry you can explore
        the product without any limitations.
      </div>

      <div className="user-interest-list">
        {userInterestList.map((item, index) => {
          const {
            key,
            content,
            selected,
            toolsList = [],
            selectedContent,
          } = item;
          return (
            <div
              className={`user-interest-item ${
                selected ? 'user-interest-selected' : ''
              }`}
              key={`user-interest-item-${index}`}
            >
              <div className="user-interest-item-wrapper">
                <ControlInput
                  type="checkbox"
                  checked={selected}
                  label={
                    <div className="content">
                      {selected &&
                      (key === 'EMAIL_CAMPAIGN_LIST' ||
                        key === 'ENRICH_CRM_DATA')
                        ? selectedContent
                        : content}
                    </div>
                  }
                  onChange={() => {
                    handleCheckboxClick(key);
                  }}
                />
              </div>
              {selected && toolsList.length && key === 'EMAIL_CAMPAIGN_LIST' ? (
                <ListOfEmailCampaigns
                  setSelectedEmailCampaignTool={setSelectedEmailCampaignTool}
                  selectedEmailCampaignTool={selectedEmailCampaignTool}
                  toolsList={toolsList}
                />
              ) : (
                ''
              )}
              {selected && toolsList.length && key === 'ENRICH_CRM_DATA' ? (
                <ListOfCrms
                  setSelectedCrmTool={setSelectedCrmTool}
                  selectedCrmTool={selectedCrmTool}
                  toolsList={toolsList}
                />
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>

      <span
        className={`error-wrapper ${
          errorInfo !== null ? '' : 'hide-visibility'
        }`}
      >
        {errorInfo === 'EMPTY'
          ? 'Please select atleast one option to proceed further'
          : ''}
      </span>

      <div className="action-btn-wrapper">
        <Button
          buttonProps={{ text: 'Skip' }}
          variant="outline"
          onClick={handleSkipAction}
        />
        <Button buttonProps={{ text: 'Done' }} onClick={handleSubmitAction} />
      </div>
    </div>
  );
}

const PasswordSetup = observer(() => {
  Utils.stopBodyScroll(false, true);

  const { showPasswordSetupPage } = userDetail;
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();

  const handleAfterLoginCheckFlow = () => {
    const searchParams = new URLSearchParams(location.search);
    const seoSearchData = searchParams.get('searchData');
    const landingURL = searchParams.get('landingURL');

    if (seoSearchData !== null) {
      const searchDecodedData = JSON.parse(decodeURIComponent(seoSearchData));
      Object.keys(searchDecodedData).forEach((key) => {
        Utils.setCookie(
          SEOCookieQueryMapping[key],
          key === 'seoSearchJSON'
            ? JSON.stringify(searchDecodedData[key])
            : searchDecodedData[key],
          1,
        );
      });
    }
    if (landingURL !== null) {
      Utils.setCookie(SEOCookieQueryMapping.landingURL, landingURL);
    }
    window.history.replaceState(null, null, PASSWORD_SETUP);
  };

  useEffect(() => {
    if (!Utils.checkPasswordSetupFlow()) {
      Utils.checkAndRedirectTrialUser();
    } else {
      userDetail.setShowPasswordSetupPage(true);
      handleAfterLoginCheckFlow();
    }
  }, []);

  const passwordUpdateSuccesCbk = (val) => {
    if (val) {
      setCurrentStep(2);
    }
  };

  return (
    <>
      <Title title="Password Setup" />
      {showPasswordSetupPage ? (
        <div className="password-setup-page">
          <div className="logo-wrapper">
            <img
              src={adaptLogo}
              className="logo"
              alt="adaptLogoWhitemediuImg"
            />
          </div>
          <div className="password-setup-content-section">
            <div className="password-setup-content-wrapper">
              <StepIndicator currentStep={currentStep} />

              {currentStep === 1 ? (
                <PasswordSetupWrapper
                  passwordUpdateSuccesCbk={passwordUpdateSuccesCbk}
                />
              ) : currentStep === 2 ? (
                <UserInterestWrapper />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingWrapper show position="fixed" />
      )}
    </>
  );
});

export default PasswordSetup;
