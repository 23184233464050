import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Select from 'Components/common/base/Select';
import Input from 'Components/common/base/Input';
import Button from 'Components/common/base/Button';
import { toasterState } from 'Components/common/base/Toaster';
import Title from 'Components/common/base/Title';
import userDetail from 'Stores/userDetail';
import profileState from 'Stores/profile';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import ChangePasswordPopup from './changePasswordPopup';
import './styles.scss';

function getTimeZoneValue(timeZone) {
  return `${
    timeZone.zone_value?.includes('(')
      ? timeZone.zone_value
      : `(${timeZone.zone_value})`
  } ${timeZone.zone_name}`;
}

function CancelSaveButton(props) {
  const { cancelAction, saveAction } = props;
  return (
    <div className="button-wrapper">
      <Button
        buttonProps={{ text: 'Cancel' }}
        onClick={cancelAction}
        variant="outline"
      />
      <Button
        buttonProps={{ text: 'Save', type: 'submit' }}
        onClick={saveAction}
      />
    </div>
  );
}

function HighlightCard(props) {
  const { title, children } = props;
  return (
    <div className="profile-highlight-card">
      <div className="highlight-card-title">{title}</div>
      <div className="highlight-card-content">{children}</div>
    </div>
  );
}

function getPersonalInfo(userInfo) {
  const { firstName, lastName, email, timeZone } = userInfo;

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    email,
    timeZone: timeZone.zone_value ? getTimeZoneValue(timeZone) : timeZone,
  };
}

function ProfileSettings() {
  const [personalInfo, setPersonalInfo] = useState(
    getPersonalInfo(userDetail.userInfo),
  );
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

  const { userInfo } = userDetail;
  const { apiKey } = userInfo;

  const copyFunction = () => {
    Utils.copyToClipBoard(apiKey);
    toasterState.setToastMsg('Token Copied', CONSTANTS.TOAST_MSG_TYPES.SUCCESS);
  };

  useEffect(() => {
    setPersonalInfo(getPersonalInfo(userDetail.userInfo));
  }, [userDetail.userInfo]);

  return (
    <div className="profile-settings-wrapper">
      <Title title="Profile Settings" />
      <div className="profile-setting-title">Personal Info</div>
      <div className="profile-card personal-info-card">
        <form
          className="name-section"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="image-placeholder">
            {userInfo.name
              ? userInfo.name.substring(0, 1)
              : userInfo.email
                ? userInfo.email.substring(0, 1)
                : ''}
          </div>
          <Input
            onChange={(value) => {
              setPersonalInfo({
                ...personalInfo,
                firstName: value,
              });
            }}
            label="First Name"
            inputProps={{ value: personalInfo.firstName }}
          />
          <Input
            onChange={(value) => {
              setPersonalInfo({
                ...personalInfo,
                lastName: value,
              });
            }}
            label="Last Name"
            inputProps={{ value: personalInfo.lastName }}
          />

          {(personalInfo.firstName !== (userInfo.firstName || '') ||
            personalInfo.lastName !== (userInfo.lastName || '')) && (
            <CancelSaveButton
              cancelAction={() => {
                setPersonalInfo(
                  getPersonalInfo({
                    ...personalInfo,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                  }),
                );
              }}
              saveAction={() => {
                if (personalInfo.firstName) {
                  profileState.updateName({
                    firstName: personalInfo.firstName,
                    lastName: personalInfo.lastName,
                  });
                }
              }}
            />
          )}
        </form>
        <div className="time-zone-wrapper">
          <Select
            inlineLabel={false}
            label="Time Zone"
            selectWrapperProps={{ className: 'timezone-dropdown' }}
            options={CONSTANTS.timezones.map((timezone) => {
              return {
                label: timezone,
                value: timezone,
              };
            })}
            value={personalInfo.timeZone}
            onChange={(value) => {
              setPersonalInfo({ ...personalInfo, timeZone: value });
            }}
          />
          {personalInfo.timeZone !== getTimeZoneValue(userInfo.timeZone) && (
            <CancelSaveButton
              cancelAction={() => {
                setPersonalInfo(
                  getPersonalInfo({
                    ...personalInfo,
                    timeZone: getTimeZoneValue(userInfo.timeZone),
                  }),
                );
              }}
              saveAction={() => {
                profileState.updateTimeZone(personalInfo.timeZone);
              }}
            />
          )}
        </div>
        <div className="highlight-card-section">
          <HighlightCard title="Registered Email ID">
            Your registered email address is{' '}
            <span className="bold">{personalInfo.email}</span>. To change your
            email you can write to us at&nbsp;
            <a href="mailto:support@adapt.io">support@adapt.io</a>
          </HighlightCard>
          <HighlightCard title="Change Password">
            <p>Your password must contain at least 6 characters</p>
            <Button
              buttonProps={{
                text: 'Change Password',
                className: 'change-password',
              }}
              onClick={() => {
                setShowChangePasswordPopup(true);
              }}
              variant="transparent"
            />
          </HighlightCard>
        </div>
      </div>
      <div className="profile-setting-title">Integration</div>
      <div className="profile-card integrations-card">
        <div className="api-token">
          <Input
            label="API Token"
            className="api-token-wrapper"
            inputProps={{
              placeholder: 'API Token',
              disabled: true,
              value: apiKey,
            }}
          />
          <Button
            buttonProps={{
              text: 'Copy',
            }}
            onClick={copyFunction}
          />
        </div>
      </div>

      <div className="profile-setting-title">Account</div>
      <div className="profile-card account-card">
        <div className="deactivate-title">Deactivate my account</div>
        <div className="deactivate-message">
          By deactivating your account, you will no longer receive notifications
          from Adapt. Please make sure to save your information before
          proceeding to deactivate. To deactivate your account, please write to{' '}
          <a href="mailto:support@adapt.io">support@adapt.io</a>
        </div>
      </div>
      {showChangePasswordPopup && (
        <ChangePasswordPopup
          onClose={() => {
            setShowChangePasswordPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default observer(ProfileSettings);
