import React from 'react';
import RipplingLogo from 'Assets/png/payg_pricing/rippling.png';
import Ai4Logo from 'Assets/png/payg_pricing/ai4.png';
import PeospectrumLogo from 'Assets/png/payg_pricing/peospectrum.png';
import RiseAgainstHungerLogo from 'Assets/png/payg_pricing/riseagainsthunger.png';
import OpenPriseLogo from 'Assets/png/payg_pricing/openprise.png';
import KineticLogo from 'Assets/png/payg_pricing/kinetic.png';
import CallboxLogo from 'Assets/png/payg_pricing/callbox.png';
import HrTechLogo from 'Assets/png/payg_pricing/hrtech.png';
import VereignLogo from 'Assets/png/payg_pricing/vd.png';
import './styles.scss';

const imageLinks = [
  {
    image: RipplingLogo,
    alt: 'Rippling logo',
  },
  {
    image: Ai4Logo,
    alt: 'Ai4 logo',
    width: 80,
  },
  {
    image: PeospectrumLogo,
    alt: 'PeoSpectrum logo',
  },
  {
    image: RiseAgainstHungerLogo,
    alt: 'Rise Against hunger logo',
    width: 80,
  },
  {
    image: OpenPriseLogo,
    alt: 'Open Prise logo',
  },
  {
    image: KineticLogo,
    alt: 'Kinetic logo',
    width: 80,
  },
  {
    image: CallboxLogo,
    alt: 'Callbox logo',
    width: 80,
  },
  {
    image: HrTechLogo,
    alt: 'Hrtech logo',
    width: 130,
  },
  {
    image: VereignLogo,
    alt: 'Vereigen Media logo',
    width: 85,
  },
];

function ImageGrid() {
  return (
    <div className="image-grid">
      <h2>Join 250,000 other businesses</h2>
      <div className="image-list">
        {imageLinks.map((imageData) => {
          const { image, alt, width, height } = imageData;
          return (
            <div className="image-wrapper" key={alt}>
              <img
                width={width || 180}
                height={height || 25}
                src={image}
                alt={alt}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ImageGrid;
