import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import InfoIcon from 'Assets/svg/info-icon.svg';
import ErrorIcon from 'Assets/svg/bg-error.svg';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import './styles.scss';

const { TOAST_MSG_TYPES } = CONSTANTS;

const defaultValue = {
  show: false,
  message: '',
  type: '',
  className: '',
};
class ToasterState {
  @observable accessor toastOptions = defaultValue;

  @action
  setToastMsg = (customTxt, msgType, options = {}) => {
    const { callback, timeout = 3500, className = '' } = options;
    let str = '';
    const thisObj = this;

    if (customTxt !== null && customTxt !== undefined) {
      if (str !== '') {
        str += ' <br />';
      }
      str += customTxt;
    }

    if (window.timeoutVar !== undefined) {
      clearTimeout(window.timeoutVar);
    }
    setTimeout(() => {
      thisObj.toastOptions = {
        show: true,
        message: str,
        type: msgType?.toUpperCase() || TOAST_MSG_TYPES.FAILURE,
        className,
      };
      window.timeoutVar = setTimeout(() => {
        thisObj.toastOptions = defaultValue;
        if (Utils.isNotUndefinedNotNull(callback)) {
          callback();
        }
      }, timeout);
    }, 100);
  };
}

const toasterState = new ToasterState();

function Toaster() {
  const className = `toast-wrapper${
    toasterState.toastOptions.show ? ' show-toast' : ''
  }${
    toasterState.toastOptions.className !== ''
      ? ` ${toasterState.toastOptions.className}`
      : ''
  } ${toasterState.toastOptions.type}`;
  return (
    <div className={className.trim()}>
      <div className="messageCont">
        {toasterState.toastOptions.type === TOAST_MSG_TYPES.INFO ? (
          <span className="svgIcon tickIcon">
            <InfoIcon />
          </span>
        ) : toasterState.toastOptions.type === TOAST_MSG_TYPES.SUCCESS ? (
          <span className="svgIcon tickIcon">
            <TickIcon />
          </span>
        ) : (
          <span className="crossIcon svgIcon">
            <ErrorIcon />
          </span>
        )}
        <span
          className="message" // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: toasterState.toastOptions.message || '',
          }}
        />
      </div>
    </div>
  );
}

export { toasterState };

export default observer(Toaster);
