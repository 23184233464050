import React, { useId } from 'react';
import { observer } from 'mobx-react';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';

function ControlInput(props) {
  const {
    label,
    onChange = EMPTY_FUNCTION,
    checked,
    type,
    name,
    value,
    disabled = false,
    centerAlign = true,
    className = '',
    eventStopPropagation = false,
    color = 'primary',
  } = props;

  const id = useId();

  const onChangeCallback = (e) => {
    if (eventStopPropagation) {
      e.stopPropagation();
    }
    if (type === 'checkbox') {
      onChange(!checked);
    } else {
      onChange(value);
    }
  };

  return (
    <div
      className={`control-input${centerAlign ? ' center' : ''} ${color} ${className}`.trim()}
    >
      <input
        type={type}
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        onChange={onChangeCallback}
        checked={checked}
      />
      <label htmlFor={id}>
        <span>{label}</span>
      </label>
    </div>
  );
}

export default observer(ControlInput);
