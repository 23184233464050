import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ControlInput from '../base/ControlInput';
import Modal from '../base/Modal';
import Button from '../base/Button';

class ExportTypeState {
  @observable accessor showPopup = false;

  defaultPopupValue = {
    title: '',
    actionBtnText: 'Export',
    defaultPreferenceCheckBox: true,
    callback() {},
  };

  @observable accessor popupValues = this.defaultPopupValue;

  @action
  setShowPopup = (val) => {
    this.showPopup = val;
    if (val === false) {
      this.popupValues = this.defaultPopupValue;
    }
  };

  @action
  setPopupValues = (val) => {
    this.popupValues = { ...this.popupValues, ...val };
  };
}
const exportTypeState = new ExportTypeState();

function ExportLeadsTypePopup() {
  const [exportType, setExportType] = React.useState('lead');
  const [saveDefault, setSaveDefault] = React.useState(false);

  const setLeadTypeChange = (val) => {
    setExportType(val);
  };
  const confirmClick = () => {
    exportTypeState.popupValues.callback(exportType, saveDefault);
    exportTypeState.setShowPopup(false);
  };

  React.useEffect(() => {
    setExportType('lead');
    setSaveDefault(false);
  }, [exportTypeState.showPopup]);

  const { popupValues } = exportTypeState;
  const { defaultPreferenceCheckBox = true } = popupValues || {};

  const closeModal = () => {
    exportTypeState.setShowPopup(false);
  };

  return (
    exportTypeState.showPopup && (
      <Modal className="export-type-popup" show closeModal={closeModal}>
        <Modal.Title>Export to {exportTypeState.popupValues.title}</Modal.Title>
        <Modal.Content>
          <div className="export-content-title">
            How to Export these contacts to {exportTypeState.popupValues.title}?
          </div>
          <div className="radio-wrapper">
            <ControlInput
              type="radio"
              label="Export as Lead"
              onChange={setLeadTypeChange}
              checked={exportType === 'lead'}
              value="lead"
            />
            <ControlInput
              type="radio"
              label="Export as Contact"
              onChange={setLeadTypeChange}
              checked={exportType === 'contact'}
              value="contact"
            />
          </div>
          {defaultPreferenceCheckBox && (
            <div className="check-box-wrap">
              <ControlInput
                type="checkbox"
                label="Always use this option"
                checked={saveDefault}
                onChange={() => {
                  setSaveDefault(!saveDefault);
                }}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button
            variant="outline"
            buttonProps={{ text: 'Cancel' }}
            onClick={closeModal}
          />
          <Button
            buttonProps={{ text: exportTypeState.popupValues.actionBtnText }}
            onClick={confirmClick}
          />
        </Modal.Footer>
      </Modal>
    )
  );
}

export { exportTypeState };

export default observer(ExportLeadsTypePopup);
