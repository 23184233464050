import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Outlet, useMatch, useLocation } from 'react-router-dom';
import AccountIcon from 'Assets/svg/new_icons/account.svg';
import ContactsIcon from 'Assets/svg/new_icons/contacts.svg';
import DollarIcon from 'Assets/svg/dollar.svg';
import ChartIcon from 'Assets/svg/chart-icon.svg';
import PurchaseHistoryIcon from 'Assets/svg/purchase-history.svg';
import SuppressionListIcon from 'Assets/svg/suppression-list.svg';
import SideBarNavigation from 'Components/common/SideBarNavigation';
import LoadingWrapper from 'Components/common/base/Loader';
import profileState from 'Stores/profile';
import userDetail from 'Stores/userDetail';
import './styles.scss';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';

const sideBarData = {
  title: 'Settings',
  sublinks: [
    {
      titleText: 'Profile Setting',
      linkKey: 'settings',
      linkValue: '/profile/settings',
      isNavLink: true,
      icon: AccountIcon,
    },
    {
      titleText: 'Manage Team',
      linkKey: 'manage-team',
      linkValue: '/profile/manage-team',
      isNavLink: true,
      icon: ContactsIcon,
    },
    {
      titleText: 'Manage Billing',
      linkKey: 'billing',
      linkValue: '/profile/billing',
      isNavLink: true,
      icon: DollarIcon,
    },
    {
      titleText: 'Credits and Usage',
      linkKey: 'usage-analytics',
      linkValue: '/profile/usage-analytics',
      isNavLink: true,
      icon: ChartIcon,
      subMenu: [
        {
          title: 'Credits Usage',
          link: '/profile/usage-analytics',
        },
      ],
    },
    {
      titleText: 'Bulk Purchase History',
      linkKey: 'purchase-history',
      linkValue: '/profile/purchase-history',
      isNavLink: true,
      icon: PurchaseHistoryIcon,
    },
    {
      titleText: 'Suppression Lists',
      linkKey: 'suppression-list',
      linkValue: '/profile/suppression-list',
      isNavLink: true,
      icon: SuppressionListIcon,
    },
  ],
};

function Profile() {
  const match = useMatch('/profile/:menu/:subMenu?');
  const menu = match?.params?.menu ?? 'settings';
  const location = useLocation();
  const [sidebarMenu, setSidebarMenu] = useState(sideBarData);

  const { isEnterpriseAdmin, noOfSeatsEligible, memberSubscription } =
    userDetail.userInfo;

  const showBilling = isEnterpriseAdmin || memberSubscription === null;

  const showManageTeam = isEnterpriseAdmin && noOfSeatsEligible > 1;

  useEffect(() => {
    const modifiedSideBarData = {
      ...sideBarData,
      sublinks: sideBarData.sublinks.filter((data) => {
        if (data.linkKey === 'billing' && !showBilling) {
          return false;
        }
        if (data.linkKey === 'manage-team' && !showManageTeam) {
          return false;
        }
        return true;
      }),
    };

    // to reinstate again after data fix
    if (featureFlagsAndPreferencesState.flags.apiEnabled) {
      modifiedSideBarData.sublinks.forEach((data) => {
        if (data.linkKey === 'usage-analytics' && data.subMenu.length < 2) {
          data.subMenu.push({
            title: 'API Usage',
            link: '/profile/usage-analytics/api',
          });
        }
      });
    } else {
      modifiedSideBarData.sublinks.forEach((data) => {
        if (data.linkKey === 'usage-analytics') {
          data.subMenu.splice(0, 1);
        }
      });
    }
    setSidebarMenu(modifiedSideBarData);
  }, []);

  return (
    <div className={`edit-profile ${menu} ${match?.params?.subMenu ?? ''}`}>
      <LoadingWrapper show={profileState.isLoading} position="fixed" />
      <div className="profile-content">
        <SideBarNavigation
          data={sidebarMenu}
          activeMenu={menu}
          collapseInactiveMenu
          selectedSubMenu={
            menu === 'usage-analytics'
              ? location.pathname.includes('api')
                ? 'api usage'
                : 'credits usage'
              : ''
          }
          hasIcon={false}
        />
        <div className="main-section">
          <div className="main-section-inner-wrap">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(Profile);
