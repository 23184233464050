import ListBuildingIcon from 'Assets/svg/use-cases/list-building.svg';
import APIIcon from 'Assets/svg/use-cases/api.svg';
import ABMListBuildingIcon from 'Assets/svg/use-cases/abm-list-building.svg';
import AccountDiscoveryIcon from 'Assets/svg/use-cases/account-discovery.svg';
import ProspectingIcon from 'Assets/svg/use-cases/prospecting.svg';
import HelpIcon from 'Assets/svg/use-cases/help.svg';
import EnrichmentIcon from 'Assets/svg/use-cases/enrichment.svg';
import { PAGE_PATH_MAP, EXTERNAL_LINKS } from 'Utils/constants';
import filters from 'Pages/prospectSearch/filterSection/filters';

const useCases = [
  {
    title: 'List Building',
    description:
      'Build a perfect prospect list with precision through our 50+ search filters and get verified emails, phone numbers and more within seconds.',
    Icon: ListBuildingIcon,
    internalLink: PAGE_PATH_MAP.CONTACT_SEARCH,
    linkText: 'Try Now',
  },
  {
    title: 'API/Data Partnership',
    description:
      'Harness the power of high-performance B2B data solutions to supercharge your business.',
    Icon: APIIcon,
    linkText: 'Talk to Us',
    link: EXTERNAL_LINKS.HUBSPOT_CALENDAR_LINK_DATA_PARTNERSHIP,
  },
  {
    title: 'ABM List Building',
    description:
      'Create a list of potential prospects from your target accounts with all personas required for your ABM campaigns.',
    Icon: ABMListBuildingIcon,
    internalLink: PAGE_PATH_MAP.CONTACT_SEARCH,
    // companyInclusion
    filterItem: filters[1]?.list?.[10],
    linkText: 'Try Now',
  },
  {
    title: 'Account Discovery & Research',
    description:
      'Discover fast growing target accounts for your outbound efforts using signals like funding, head count growth and press coverage.',
    Icon: AccountDiscoveryIcon,
    internalLink: PAGE_PATH_MAP.COMPANY_SEARCH,
    linkText: 'Try Now',
  },
  {
    title: 'LinkedIn Prospecting',
    description:
      'Use our email finder extension on LinkedIn and across the Web to effortlessly discover verified email addresses of prospects.',
    Icon: ProspectingIcon,
    link: EXTERNAL_LINKS.PROSPECTOR,
    linkText: 'Install',
  },
  {
    title: 'Need Help',
    description: 'Learn more about how Adapt can help you grow your business.',
    Icon: HelpIcon,
    linkText: 'Talk to Us',
    link: EXTERNAL_LINKS.CUSTOM_PRICING_MEET,
  },
  {
    title: 'Enrichment',
    description:
      'Put an end to your data inconsistencies and keep your lead data updated. Enrich contact lists, CRM and marketing automation systems.',
    Icon: EnrichmentIcon,
    internalLink: PAGE_PATH_MAP.ENRICHMENT,
    linkText: 'Try Now',
  },
];

export default useCases;
