import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import PipedriveExportIcon from 'Assets/svg/export_icons/pipedrive.svg';
import Modal from '../base/Modal';
import Button from '../base/Button';
import CRMExportHeader from './CRMExportHeader';
import Input from '../base/Input';

class PipedriveExportState {
  @observable accessor showPopup = false;

  defaultPopupValue = {
    callback() {},
  };

  @observable accessor popupValues = this.defaultPopupValue;

  @action
  setShowPopup = (val) => {
    this.showPopup = val;
    if (val === false) {
      this.popupValues = this.defaultPopupValue;
    }
  };

  @action
  setPopupValues = (val) => {
    this.popupValues = { ...this.popupValues, ...val };
  };
}
const pipedriveExportState = new PipedriveExportState();

const defaultState = { apikey: '', companyDomain: '' };

function PipedriveAuthenticationPopup() {
  const [stateValues, setStateValues] = React.useState(defaultState);
  const [errors, setErrors] = React.useState(defaultState);

  const confirmClick = () => {
    const regexMatch = /(?<companyDomain>[^/.]+)(?=\.pipedrive\.com)/.exec(
      stateValues.companyDomain,
    );
    const stateValueCopy = {
      apikey: stateValues.apikey,
      companyDomain: regexMatch?.groups?.companyDomain || '',
    };
    if (
      stateValueCopy.apikey !== undefined &&
      stateValueCopy.apikey !== '' &&
      stateValueCopy.companyDomain !== undefined &&
      stateValueCopy.companyDomain !== ''
    ) {
      pipedriveExportState.popupValues.callback(stateValueCopy);
      pipedriveExportState.setShowPopup(false);
    } else {
      setErrors({
        apikey: !stateValues.apikey,
        companyDomain: !stateValues.companyDomain,
      });
    }
  };

  const setInputValues = (name, value) => {
    setErrors({
      ...errors,
      [name]: errors[name] !== '' ? !value : errors[name],
    });
    setStateValues({ ...stateValues, [name]: value });
  };

  React.useEffect(() => {
    if (!pipedriveExportState.showPopup) {
      setErrors(defaultState);
      setStateValues(defaultState);
    }
  }, [pipedriveExportState.showPopup]);

  const closeModal = () => {
    pipedriveExportState.setShowPopup(false);
  };

  return (
    pipedriveExportState.showPopup && (
      <Modal className="pipedrive-auth-modal" show closeModal={closeModal}>
        <CRMExportHeader
          Logo={PipedriveExportIcon}
          title="Pipedrive Export"
          message="Use your Pipedrive API Key for connecting with Adapt"
        />
        <Modal.Content>
          <Input
            inputProps={{
              type: 'text',
              placeholder: 'Enter Pipedrive API Key here.',
              value: stateValues.apikey,
              required: true,
            }}
            onChange={(value) => {
              setInputValues('apikey', value);
            }}
            error={errors.apikey ? 'Please enter the API key' : ''}
            label="API Key"
          />
          <Input
            inputProps={{
              type: 'text',
              placeholder: 'Example: https://companydomainname.pipedrive.com',

              value: stateValues.companyDomain,
              required: true,
            }}
            onChange={(value) => {
              setInputValues('companyDomain', value);
            }}
            error={errors.companyDomain ? 'Please enter the Instance URL' : ''}
            label="Company URL"
          />
        </Modal.Content>
        <Modal.Footer>
          <Button
            buttonProps={{ text: 'Cancel' }}
            variant="outline"
            onClick={closeModal}
          />
          <Button buttonProps={{ text: 'Save' }} onClick={confirmClick} />
        </Modal.Footer>
      </Modal>
    )
  );
}

export { pipedriveExportState };

export default observer(PipedriveAuthenticationPopup);
