import React, { useEffect, useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import DoubleArrow from 'Assets/svg/double-arrow.svg';
import ContactsIcon from 'Assets/svg/new_icons/contacts.svg';
import CompanyIcon from 'Assets/svg/new_icons/companies.svg';
import PageContentHeader from 'Components/common/PageContentHeader';
import advSearchState from 'Stores/advSearch';
import FilterSection from './filterSection';
import './styles.scss';

function ProspectSearch() {
  const match = useMatch('/advanced-search/:page/:subPath?');
  const page = match?.params?.page;

  const [collapseFilter, setCollapseFilter] = useState(false);

  useEffect(() => {
    setCollapseFilter(false);
  }, [match.params.page]);

  return (
    <div className="prospect-search">
      <PageContentHeader
        tabData={{
          list: [
            { text: 'Contacts', Icon: ContactsIcon, href: 'contact#search' },
            { text: 'Companies', Icon: CompanyIcon, href: 'company#search' },
          ],
        }}
      />
      <div className="page-content">
        <div className={`filter-wrapper${collapseFilter ? ' collapse' : ''}`}>
          {page === 'contact' &&
            advSearchState.searchResults.length > 0 &&
            Object.keys(advSearchState.appliedFilterSectionData).length > 0 && (
              <div
                className="collapse-filter"
                onClick={() => {
                  setCollapseFilter(!collapseFilter);
                }}
              >
                <DoubleArrow />
              </div>
            )}
          <FilterSection />
        </div>
        <div className="right-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default observer(ProspectSearch);
