import React from 'react';
import { observer } from 'mobx-react';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { MX_AS_EVENTS } from 'Utils/constants';
import JobTitle from '../JobTitle';
import CheckboxList from './checkboxList';
import Location from '../Location';
import EmailAccuracy from '../EmailAccuracy';
import ContactExclusion from '../ContactExclusion';
import IndustrySector from '../IndustrySector';
import CompanyInclusion from '../CompanyInclusion';
import CompanyExclusion from '../CompanyExclusion';
import InputSuggestion from './inputSuggestion';
import LeadershipHire from '../LeadershipHire';
import HeadcountGrowth from '../HeadcountGrowth';
import Funding from '../Funding';
import TechnologySector from '../TechnologySector';
import RecentJobChanges from '../RecentJobChanges';

const {
  AS_LEAD_NAME_SEARCH_PERFORMED,
  AS_DEPARTMENT_SEARCH_PERFORMED,
  AS_LEVEL_SEARCH_PERFORMED,
  AS_EMPLOYEE_COUNT_SEARCH_PERFORMED,
  AS_REVENUE_SEARCH_PERFORMED,
  AS_COMPANY_SEARCH_PERFORMED,
  LB_AS_TECHNOLOGY_SEARCH_PERFORMED,
  AS_COMPANY_KEYWORD_SEARCH_PERFORMED,
  LB_AS_SIC_CODE_SEARCH_PERFORMED,
  LB_AS_NAICS_CODE_SEARCH_PERFORMED,
} = MX_AS_EVENTS;

const employeeCount = [
  {
    name: '0 - 25',
    pName: '0 - 25',
    selected: false,
  },
  {
    name: '25 - 100',
    pName: '25 - 100',
    selected: false,
  },
  {
    name: '100 - 250',
    pName: '100 - 250',
    selected: false,
  },
  {
    name: '250 - 1000',
    pName: '250 - 1000',
    selected: false,
  },
  {
    name: '1K - 10K',
    pName: '1K - 10K',
    selected: false,
  },
  {
    name: '10K - 50K',
    pName: '10K - 50K',
    selected: false,
  },
  {
    name: '50K - 100K',
    pName: '50K - 100K',
    selected: false,
  },
  {
    name: '> 100K',
    pName: '> 100K',
    selected: false,
  },
];

const revenue = [
  {
    name: '$0 - 1M',
    pName: '$0 - 1M',
    selected: false,
  },
  {
    name: '$1 - 10M',
    pName: '$1 - 10M',
    selected: false,
  },
  {
    name: '$10 - 50M',
    pName: '$10 - 50M',
    selected: false,
  },
  {
    name: '$50 - 100M',
    pName: '$50 - 100M',
    selected: false,
  },
  {
    name: '$100 - 250M',
    pName: '$100 - 250M',
    selected: false,
  },
  {
    name: '$250 - 500M',
    pName: '$250 - 500M',
    selected: false,
  },
  {
    name: '$500M - 1B',
    pName: '$500M - 1B',
    selected: false,
  },
  {
    name: '> $1B',
    pName: '> $1B',
    selected: false,
  },
];

function mapValuesForCheckbox(data) {
  return data?.map((prop) => {
    const { name, selected, description = '' } = prop;
    return { label: name, checked: selected, description };
  });
}

function FilterPopupMapping(props) {
  const { selectedFilter, currentFilterState, page } = props;

  const { key, filterKeys } = selectedFilter;

  const commonProps = {
    currentFilterState,
    page,
    suggestionKey: filterKeys?.[0],
  };

  switch (key) {
    case 'contactName':
      return (
        <InputSuggestion
          key={key}
          label="Search for Name"
          placeholder="Name"
          suggestionKey={filterKeys[0]}
          currentFilterState={currentFilterState}
          page={page}
          mxEventName={AS_LEAD_NAME_SEARCH_PERFORMED}
          mxUniqueKey="nameSearch"
        />
      );
    case 'jobTitle':
      return (
        <JobTitle
          currentFilterState={currentFilterState}
          page={page}
          filterKeys={filterKeys}
        />
      );
    case 'companyName':
      return (
        <InputSuggestion
          key={key}
          label="Search for Company"
          placeholder="Company"
          mxEventName={AS_COMPANY_SEARCH_PERFORMED}
          mxUniqueKey="companySearch"
          suggestionKey={filterKeys[0]}
          currentFilterState={currentFilterState}
          page={page}
          selectMultiple
          getSuggestions={advSearchFiltersState.getCompanyNameSuggestions}
          valueKey="description"
        />
      );
    case 'technology':
      return (
        <InputSuggestion
          key={key}
          label="Search for Technology"
          placeholder="Technology"
          mxEventName={LB_AS_TECHNOLOGY_SEARCH_PERFORMED}
          mxUniqueKey="technologiesSearch"
          suggestionKey={filterKeys[0]}
          currentFilterState={currentFilterState}
          page={page}
          selectMultiple
          getSuggestions={advSearchFiltersState.getTechnologiesSuggestions}
        />
      );
    case 'keyword':
      return (
        <InputSuggestion
          key={key}
          label="Search for Keyword"
          placeholder="Keyword"
          mxEventName={AS_COMPANY_KEYWORD_SEARCH_PERFORMED}
          mxUniqueKey="companyKeywordSearch"
          suggestionKey={filterKeys[0]}
          excludeSuggestionKey={filterKeys[1]}
          currentFilterState={currentFilterState}
          page={page}
          selectMultiple
          showExcludeToggle
          tooltipMessage="Toggle here to include or omit keywords."
          getSuggestions={advSearchFiltersState.getKeywordSuggestion}
        />
      );
    case 'department':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(
            advSearchFiltersState.filterListValues.department,
          )}
          mxUniqueKey="deptSearch"
          mxEventName={AS_DEPARTMENT_SEARCH_PERFORMED}
          {...commonProps}
        />
      );
    case 'level':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(
            advSearchFiltersState.filterListValues.level,
          )}
          mxUniqueKey="levelSearch"
          mxEventName={AS_LEVEL_SEARCH_PERFORMED}
          {...commonProps}
        />
      );
    case 'employeeCount':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(employeeCount)}
          mxUniqueKey="empCountSearch"
          mxEventName={AS_EMPLOYEE_COUNT_SEARCH_PERFORMED}
          {...commonProps}
        />
      );
    case 'revenue':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(revenue)}
          mxUniqueKey="revenueSearch"
          mxEventName={AS_REVENUE_SEARCH_PERFORMED}
          {...commonProps}
        />
      );
    case 'sicCode':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(
            advSearchFiltersState.filterListValues.sicCode,
          )}
          mxUniqueKey="sicCodeSearch"
          mxEventName={LB_AS_SIC_CODE_SEARCH_PERFORMED}
          grid
          enableSearch
          {...commonProps}
        />
      );
    case 'naicsCode':
      return (
        <CheckboxList
          key={key}
          list={mapValuesForCheckbox(
            advSearchFiltersState.filterListValues.naicsCode,
          )}
          mxUniqueKey="naicsCodeSearch"
          mxEventName={LB_AS_NAICS_CODE_SEARCH_PERFORMED}
          grid
          enableSearch
          {...commonProps}
        />
      );
    case 'contactLocation':
      return (
        <Location
          key={key}
          currentFilterState={currentFilterState}
          page={page}
          keys={{
            selectedCities: 'selectedCitiesContact',
            selectedStates: 'selectedStatesContact',
            selectedCountries: 'selectedCountriesContact',
            selectedRegions: 'selectedRegionsContact',
          }}
        />
      );
    case 'companyLocation':
      return (
        <Location
          key={key}
          currentFilterState={currentFilterState}
          page={page}
          keys={{
            selectedCities: 'selectedCitiesCompany',
            selectedStates: 'selectedStatesCompany',
            selectedCountries: 'selectedCountriesCompany',
            selectedRegions: 'selectedRegionsCompany',
          }}
        />
      );
    case 'emailAccuracy':
      return <EmailAccuracy />;
    case 'contactExclusion':
      return <ContactExclusion />;
    case 'industry':
      return (
        <IndustrySector
          currentFilterState={currentFilterState}
          page={page}
          selectedFilter={selectedFilter}
        />
      );
    case 'companyInclusion':
      return (
        <CompanyInclusion page={page} currentFilterState={currentFilterState} />
      );
    case 'companyExclusion':
      return (
        <CompanyExclusion page={page} currentFilterState={currentFilterState} />
      );
    case 'leadershipHire':
      return (
        <LeadershipHire
          page={page}
          currentFilterState={currentFilterState}
          selectedFilter={selectedFilter}
        />
      );
    case 'headCount':
      return (
        <HeadcountGrowth
          page={page}
          currentFilterState={currentFilterState}
          selectedFilter={selectedFilter}
        />
      );
    case 'funding':
      return (
        <Funding
          page={page}
          currentFilterState={currentFilterState}
          selectedFilter={selectedFilter}
        />
      );
    case 'technologySector':
      return (
        <TechnologySector
          page={page}
          currentFilterState={currentFilterState}
          selectedFilter={selectedFilter}
          suggestionKey={filterKeys[0]}
        />
      );
    case 'recentJobChanges':
      return (
        <RecentJobChanges
          page={page}
          currentFilterState={currentFilterState}
          selectedFilter={selectedFilter}
          suggestionKey={filterKeys[0]}
        />
      );
    default:
      return null;
  }
}

export default observer(FilterPopupMapping);
