import { action, observable } from 'mobx';
import { toasterState } from 'Components/common/base/Toaster';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import makeApi, { URLS } from 'Utils/apiUtil';
import userDetail from './userDetail';

class SavedSearchState {
  @observable accessor contactSavedSearches = [];

  @observable accessor companySavedSearches = [];

  @observable accessor initialLoadedAPIs = {
    contactSavedSearches: false,
    companySavedSearches: false,
  };

  getSavedSearchList(searchType = 'contact') {
    return searchType === 'contact'
      ? this.contactSavedSearches
      : this.companySavedSearches;
  }

  @action
  setSavedSearches(obj = [], searchType = 'contact') {
    if (searchType === 'contact') {
      this.contactSavedSearches = obj;
      if (!this.initialLoadedAPIs.contactSavedSearches) {
        this.updateInitialLoadedAPIs('contactSavedSearches');
      }
    } else if (searchType === 'company') {
      this.companySavedSearches = obj;
      if (!this.initialLoadedAPIs.companySavedSearches) {
        this.updateInitialLoadedAPIs('companySavedSearches');
      }
    }
  }

  @action
  updateInitialLoadedAPIs = (key) => {
    this.initialLoadedAPIs = { ...this.initialLoadedAPIs, [key]: true };
  };

  @action
  getSavedSearch = async (searchType = 'contact') => {
    const config = {
      url: Utils.generateUrl(URLS.savedSearchV2, {
        searchType: searchType.toUpperCase(),
        limit: 700,
      }),
      method: 'GET',
    };
    const response = await makeApi(config);
    if (response && response.data && response.data.savedSearchList) {
      this.setSavedSearches(response.data.savedSearchList, searchType);
    }
  };

  @action
  saveSearch = async (data, options = {}, searchType = 'contact') => {
    const { callback = CONSTANTS.EMPTY_FUNCTION } = options;
    const { appliedFilters, ...remainingProps } = data;

    const searchJson = JSON.parse(JSON.stringify(appliedFilters));
    searchJson.limit = 50;
    if (searchJson.exclusionListIds !== undefined) {
      delete searchJson.exclusionListIds;
    }

    const config = {
      url: Utils.generateUrl(URLS.savedSearchV2),
      method: 'POST',
      data: {
        searchTemplate: JSON.stringify(searchJson),
        searchType: searchType.toUpperCase(),
        ...remainingProps,
      },
    };

    const response = await makeApi(config);
    const responseStatus = response?.status;

    if (responseStatus === 201) {
      Utils.mixpanelTrack(
        CONSTANTS.MX_SAVED_SEARCH_EVENTS.CREATE_SAVED_SEARCH,
        {
          searchCriteria: searchJson,
          name: data.name,
          searchType,
          ...data,
        },
      );
      toasterState.setToastMsg(
        'Search saved successfully',
        CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
      );

      this.setSavedSearches(
        [response.data.responseData, ...this.getSavedSearchList(searchType)],
        searchType,
      );
      callback('success');
    } else if (
      response.response?.status === 400 &&
      response.response?.data?.responseCode === 110
    ) {
      callback('limit', response.response.data);
    } else {
      toasterState.setToastMsg(
        'Unable to save this search, please try again',
        'failure',
      );
    }

    return response.data?.responseData;
  };

  @action
  updateSavedSearch = async (
    data,
    toastMessage = {
      success: 'Search updated successfully',
      failure: 'Unable to update this search, please try again',
    },
    searchType = 'contact',
  ) => {
    const config = {
      url: Utils.generateUrl(URLS.savedSearchV2),
      method: 'PUT',
      data: { ...data, searchType: searchType.toUpperCase() },
    };

    const response = await makeApi(config);

    if (response && response.status && response.status === 200) {
      this.setSavedSearches(
        this.getSavedSearchList(searchType).map((savedSearch) => {
          if (savedSearch.id === data.id) {
            return { ...savedSearch, ...response.data.responseData };
          }
          return savedSearch;
        }),
        searchType,
      );
      toasterState.setToastMsg(
        toastMessage.success,
        CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
      );
    } else {
      toasterState.setToastMsg(toastMessage.failure, 'failure');
    }
    return response.status === 200;
  };

  @action
  deleteSavedSearch = async (
    id,
    callback = CONSTANTS.EMPTY_FUNCTION,
    searchType = 'contact',
  ) => {
    const config = {
      url: Utils.generateUrl(URLS.deleteSavedSearchV2, { id }),
      method: 'DELETE',
    };

    const response = await makeApi(config);

    if (response && response.status && response.status === 200) {
      const str = 'Saved search deleted successfully';
      Utils.mixpanelTrack(
        CONSTANTS.MX_SAVED_SEARCH_EVENTS.DELETE_SAVED_SEARCH,
        {
          id,
          name: this.getSavedSearchList(searchType).find((savedSearch) => {
            return savedSearch.id === id;
          })?.name,
        },
      );
      toasterState.setToastMsg(str, CONSTANTS.TOAST_MSG_TYPES.SUCCESS);
    } else {
      toasterState.setToastMsg(
        'Error while deleting the saved search',
        'failute',
      );
    }
    this.getSavedSearch(searchType);
    callback();
  };
}

const savedSearchState = new SavedSearchState();

export default savedSearchState;
