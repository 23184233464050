import React from 'react';
import { observer } from 'mobx-react';
import profileState from 'Stores/profile';
import Utils from 'Utils/utils';
import './styles.scss';

function CreditsOverviewCard(props) {
  const { title, list } = props;

  return (
    <div className="credits-overview-card">
      <div className="top-section">
        <h1>{title}</h1>

        <div className="billing-cycle">
          {profileState.usageAnalyticsData.subscriptionStartDate &&
          profileState.usageAnalyticsData.subscriptionRenewalDate
            ? `Billing Cycle: ${Utils.getFormattedDate(profileState.usageAnalyticsData.subscriptionStartDate)} - ${Utils.getFormattedDate(profileState.usageAnalyticsData.subscriptionRenewalDate)}`
            : ''}
        </div>
      </div>
      <div className="credits-section">
        {list.map((creditValue) => {
          const { label, value, getValue, enabled = true } = creditValue;
          return (
            enabled && (
              <div className="title-with-value" key={label}>
                <span className="title">{label}</span>
                <span className="value">
                  <span>
                    {getValue !== undefined
                      ? getValue()
                      : value
                        ? Utils.numberFormat(value)
                        : 0}
                  </span>
                </span>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default observer(CreditsOverviewCard);
