import React from 'react';
import ContactPhoneIcon from 'Assets/svg/new_icons/phone.svg';
import CopyIcon from 'Assets/svg/copy.svg';
import { toasterState } from 'Components/common/base/Toaster';
import Button from 'Components/common/base/Button';
import userDetail from 'Stores/userDetail';
import advSearchState from 'Stores/advSearch';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import Dropdown from '../../base/Dropdown';
import './styles.scss';
import LoaderWithTooltip from '../LoaderWithTooltip';

function copyContactInfo(number, pageName) {
  Utils.copyToClipBoard(number);
  if (pageName === 'advsearch') {
    Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_COPY_CLICKED, {
      type: 'phone',
    });
  } else if (pageName === 'myleads') {
    Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_COPY_CLICKED, {
      type: 'phone',
    });
  }
  toasterState.setToastMsg('Copied', CONSTANTS.TOAST_MSG_TYPES.SUCCESS);
}

function PhoneItem(props) {
  const {
    phoneNumber,
    pageName,
    Icon = ContactPhoneIcon,
    showIcon = true,
  } = props;

  return (
    <div className="phone-wrapper owned">
      {showIcon && <Icon className="phone-icon" />}
      <div className="info-detail show-ellipsis">{phoneNumber}</div>
      <CopyIcon
        className="copy-icon"
        onClick={() => {
          copyContactInfo(phoneNumber, pageName);
        }}
        data-value={phoneNumber}
      />
    </div>
  );
}

function ContactPhone(props) {
  const {
    phone = null,
    contactId,
    purchaseMobile = CONSTANTS.EMPTY_FUNCTION,
    pageName,
  } = props;

  return (
    (phone === null || phone?.length > 0) && (
      <div className="contact-phone">
        {phone === null ? (
          <div className="phone-wrapper">
            <ContactPhoneIcon className="phone-icon" />
            <div
              className="info-detail show-ellipsis"
              onClick={() => {
                if (
                  (userDetail.userInfo?.memberSubscription?.phoneCredits ?? 0) >
                  0
                ) {
                  purchaseMobile(contactId);
                }
              }}
            >
              +***-***-****
            </div>
            {(phone === null || phone === undefined) &&
              !advSearchState.singlePurchaseLoaderIds.includes(contactId) && (
                <Button
                  variant="light"
                  buttonProps={{ text: 'Find' }}
                  onClick={() => {
                    purchaseMobile(contactId);
                  }}
                />
              )}
          {advSearchState.singlePurchaseType === 'PHONE' && (
              <LoaderWithTooltip contactId={contactId} />
            )}
          </div>
        ) : (
          phone.length > 0 && (
            <div className="number-with-more-text">
              <PhoneItem
                phoneNumber={phone[0]}
                pageName={pageName}
                tag="Mobile"
              />
              {phone.length > 1 && (
                <Dropdown
                  toggleElement={
                    <span className="more-phone">
                      {`+${phone.length - 1} More`}
                    </span>
                  }
                  offsetValues={{ top: 60 }}
                >
                  {phone.slice(1).map((phoneNumber) => {
                    return (
                      <PhoneItem
                        key={phone}
                        phoneNumber={phoneNumber}
                        pageName={pageName}
                      />
                    );
                  })}
                </Dropdown>
              )}
            </div>
          )
        )}
      </div>
    )
  );
}

export { PhoneItem };
export default ContactPhone;
