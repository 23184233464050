import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import * as CONSTANTS from 'Utils/constants';
import Banner1 from 'Assets/png/prospector_banners/banner1.png';
import Banner2 from 'Assets/png/prospector_banners/banner2.png';
import Banner3 from 'Assets/png/prospector_banners/banner3.png';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import Utils from 'Utils/utils';
import { toasterState } from 'Components/common/base/Toaster';
import Carousel from '../Carousel';
import Modal from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

const banners = [
  {
    text: 'Join 500K+ prospectors from fast growing companies.',
    imageURL: Banner1,
  },
  {
    text: 'Scale your prospecting, work with teams and achieve faster conversions',
    imageURL: Banner2,
  },
  {
    text: 'Find Adapt in the list of best tools globally. #badgesofhonour',
    imageURL: Banner3,
  },
];

const { FREE_TRIAL_POPUP_TRIGGERS, MX_ONBOARDING_EVENTS } = CONSTANTS;
const {
  VIEW_LIST,
  EXPORT_CONTACT,
  SINGLE_VIEW_CONTACTS,
  WEBSITE_VIEW_CONTACTS,
  DIRECT_ACCESS,
} = FREE_TRIAL_POPUP_TRIGGERS;
const tenSecondSleepOptions = [
  VIEW_LIST,
  SINGLE_VIEW_CONTACTS,
  WEBSITE_VIEW_CONTACTS,
  DIRECT_ACCESS,
];

const {
  LB_AP_START_FREE_TRIAL_ACTION_TRIGGER,
  LB_AP_POPUP_START_FREE_TRIAL_INFO_SCREEN,
} = MX_ONBOARDING_EVENTS;

function ProspectorFreeTrialPrePopup(props) {
  const { freeTrialPrePopup, dynamicText } = props;
  const [trialApiLoading, setTrialApiLoading] = useState(false);

  const submitTrialRequest = () => {
    setTrialApiLoading(true);
    if (!trialApiLoading) {
      const failedCallback = () => {
        setTrialApiLoading(false);
        toasterState.setToastMsg(
          'Unable to process your request now, please try again later ',
          'failure',
        );
      };
      freeTrialPopupState.processTrialRequest(failedCallback);
    }
  };

  return (
    <>
      <Carousel
        list={banners}
        renderItem={(item) => {
          const { text, imageURL } = item;
          return (
            <div className="banner-item">
              <div className="banner-text">{text}</div>
              <img src={imageURL} alt="banner" />
            </div>
          );
        }}
        itemWidth={476}
      />
      <div className="text-wrapper">
        <div>
          {freeTrialPrePopup === SINGLE_VIEW_CONTACTS ||
          freeTrialPrePopup === WEBSITE_VIEW_CONTACTS ? (
            <>
              <div className="title">You are almost there! </div>
              <div className="description">
                Looking for more information? Get access to all employee data at{' '}
                <span className="custom-link-style">{dynamicText}</span> when
                you sign up for our FREE trial.
              </div>
            </>
          ) : freeTrialPrePopup === EXPORT_CONTACT ? (
            <>
              <div className="title">Close your customers at scale</div>
              <div className="description">
                Sign-up for Adapt free trial and get access to{' '}
                {CONSTANTS.CONTACT_COUNT_IN_MILLIONS} contacts instantly. With
                Adapt Web Platform and{' '}
                <span className="custom-link-style">{dynamicText}</span> you
                can,
                <div className="info-list">
                  <div className="info-item">
                    <TickIcon className="tick-icon" /> Power up your CRM with
                    Adapt’s data in a single click.
                  </div>
                  <div className="info-item">
                    <TickIcon className="tick-icon" /> Improve productivity by
                    automating your data export using workflows and Adapt.
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="title">
                Build your prospecting lists with precision search
              </div>
              <div className="description">
                Sign up for our FREE trial and search your target contacts
                within the Adapt Web Platform.
              </div>
            </>
          )}
        </div>
        <Button
          buttonProps={{ text: 'Start Free Trial' }}
          isLoading={trialApiLoading}
          onClick={submitTrialRequest}
        />
      </div>
    </>
  );
}

let showAfterTimeout = false;

function ProspectorFreeTrialPopup() {
  const { freeTrialPrePopup, dynamicText } = freeTrialPopupState;
  const [showPopup, setShowPopup] = useState(0);
  /* showPopup values and use case
    0 - false
    1 - pre popup
  */

  const updatePopupValue = (value) => {
    if (value > 0) {
      Utils.mixpanelTrack(LB_AP_POPUP_START_FREE_TRIAL_INFO_SCREEN, {});
    }
    setShowPopup(value);
  };

  const trackMxTriggerEvent = (eventName) => {
    Utils.mixpanelTrack(LB_AP_START_FREE_TRIAL_ACTION_TRIGGER, {
      triggerPlace: eventName,
    });
  };

  useEffect(() => {
    if (
      !Utils.isPaidSubscription() &&
      !Utils.isFreeTrialPlan() &&
      !Utils.isTrialUsed() &&
      freeTrialPrePopup !== null
    ) {
      if (freeTrialPrePopup === EXPORT_CONTACT) {
        updatePopupValue(1);
        trackMxTriggerEvent(freeTrialPrePopup);
      } else if (tenSecondSleepOptions.includes(freeTrialPrePopup)) {
        showAfterTimeout = true;
        trackMxTriggerEvent(freeTrialPrePopup);
        // eslint-disable-next-line no-inner-declarations
        function updateAfterTimeout() {
          if (showAfterTimeout) {
            updatePopupValue(1);
          }
        }
        setTimeout(updateAfterTimeout, 10000);
      } else {
        trackMxTriggerEvent('unknown');
        updatePopupValue(1);
      }
    } else {
      updatePopupValue(0);
    }
  }, [freeTrialPrePopup]);

  const closeClick = () => {
    freeTrialPopupState.setFreeTrialPrePopup(null);
  };

  return (
    showPopup > 0 && (
      <Modal
        className="prospector-free-trial-popup"
        show
        closeModal={closeClick}
      >
        {showPopup === 1 && (
          <ProspectorFreeTrialPrePopup
            freeTrialPrePopup={freeTrialPrePopup}
            dynamicText={dynamicText}
            setShowPopup={setShowPopup}
          />
        )}
      </Modal>
    )
  );
}

export default observer(ProspectorFreeTrialPopup);
