import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import SearchIcon from 'Assets/svg/open-search.svg';
import ContactSearchImg from 'Assets/png/contact-search.png';
import Button from 'Components/common/base/Button';
import SavedSearchList from 'Components/common/ListTable/SavedSearchList';
import filterState from 'Pages/prospectSearch/filterSection/state';
import advSearchState from 'Stores/advSearch';
import {
  CONTACT_COUNT_IN_MILLIONS,
  MX_DASHBOARD_PAGE_EVENTS,
} from 'Utils/constants';
import Utils from 'Utils/utils';
import newFilterList from './fixtures';
import './styles.scss';

function RecommendationAndSavedSearch(props) {
  const { setSavedSearchId } = props;
  const navigate = useNavigate();

  return (
    <div className="recommendation-and-saved-search-wrapper">
      <div className="inner-wrapper">
        <div className="top-section">
          <img
            src={ContactSearchImg}
            alt="Contact Search"
            className="contact-search-image"
          />
          <p className="title">
            Target with precision and build audience for your campaign from over{' '}
            {CONTACT_COUNT_IN_MILLIONS} B2B profiles
          </p>
          <span className="quick-start-title">Quick Start Guide</span>
          <div className="new-filter-list">
            {newFilterList.map((filterInfo) => {
              const { Icon, title, description, filterItem, mxEvent } =
                filterInfo;
              return (
                <div className="filter-item" key={title}>
                  <div className="icon-with-title">
                    <Icon />
                    <span className="filter-title">{title}</span>
                  </div>
                  <p className="filter-description">{description}</p>
                  <Button
                    variant="transparent"
                    onClick={() => {
                      Utils.mixpanelTrack(mxEvent);
                      filterState.setSelectedFilter({
                        ...filterItem,
                        focus: true,
                        ripple: true,
                      });
                    }}
                    buttonProps={{ text: 'Try this', className: 'try-this' }}
                    Icon={SearchIcon}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <p className="saved-search-title">Saved Search</p>
        <SavedSearchList
          callback={(data) => {
            Utils.mixpanelTrack(
              MX_DASHBOARD_PAGE_EVENTS.VIEW_SAVED_SEARCHES_CLICKED,
              { page: 'advSearch' },
            );
            const { id, searchTemplate } = data;
            setSavedSearchId(id);
            const searchJson = JSON.parse(searchTemplate);
            advSearchState.applySearch(searchJson);
            if (window.location.hash === '#search') {
              navigate('/advanced-search/contact');
            }
          }}
        />
      </div>
    </div>
  );
}

export default observer(RecommendationAndSavedSearch);
