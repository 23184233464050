import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import FundingImg from 'Assets/png/funding.png';
import ControlInput from 'Components/common/base/ControlInput';
// import Select from 'Components/common/base/Select';
import Slider from 'Components/common/base/Slider';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { applyFilterValueForObjects } from 'Pages/prospectSearch/utils';
import { MX_AS_EVENTS } from 'Utils/constants';
import UpgradeSection from '../UpgradeSection';
import trackMixpanelEvent from '../trackMixpanelEvent';
import { totalFundingAmount } from '../filters';
import './styles.scss';

function Funding(props) {
  const { currentFilterState, page, selectedFilter } = props;

  const applyValue = (data) => {
    trackMixpanelEvent({
      selectedValue: data.value,
      key: 'fundingData',
      page,
      mxUniqueKey: data.key,
      mxEventName: MX_AS_EVENTS.AS_FUNDING_SEARCH_PERFORMED,
    });
    applyFilterValueForObjects({
      ...data,
      currentFilterState,
      suggestionKey: 'fundingData',
    });
  };

  const isRestricted = selectedFilter.restricted
    ? selectedFilter.restricted()
    : false;

  const values = useMemo(() => {
    return selectedFilter.getValues();
  }, []);

  const appliedFilter =
    currentFilterState?.appliedFilterSectionData?.fundingData;

  const lastFundingTypeValue = appliedFilter?.lastFundingType ?? [];

  const isAllFundingTypeChecked =
    advSearchFiltersState.filterListValues.funding?.every((prop) => {
      return lastFundingTypeValue?.includes(prop.value);
    });

  return (
    <div
      className={`funding-filter-wrapper${isRestricted ? ' restricted' : ''}`}
    >
      {isRestricted && (
        <UpgradeSection
          title="Discover contacts in companies that raised funds recently based on funding date and round type"
          imageURL={FundingImg}
        />
      )}
      <div className="funding-filters">
        <div className="funding-date-wrapper">
          <p className="label">Last Funding Date</p>
          <div className="radio-wrapper">
            {values.days.map((dateData) => {
              const { label, value } = dateData;
              return (
                <ControlInput
                  key={`last-funding-${value}`}
                  type="radio"
                  label={label}
                  value={value}
                  checked={appliedFilter?.days === value || false}
                  onChange={() => {
                    applyValue({ key: 'days', value, multiple: false });
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="last-funding-type-wrapper">
          <p className="label">Last Funding Type</p>
          <div className="checkbox-list-with-select-all">
            <ControlInput
              className="select-all"
              key="last-funding-type-all"
              type="checkbox"
              label="Select All"
              checked={isAllFundingTypeChecked}
              onChange={() => {
                applyValue({
                  key: 'lastFundingType',
                  value: isAllFundingTypeChecked
                    ? []
                    : advSearchFiltersState.filterListValues.funding.map(
                        (data) => {
                          return data.value;
                        },
                      ),
                  setMultipleValue: true,
                });
              }}
            />
            <div className="funding-checkbox-list">
              {advSearchFiltersState.filterListValues.funding.map(
                (dateData) => {
                  const { label, value, subList } = dateData;
                  const isParentListChecked =
                    lastFundingTypeValue?.includes(value) ?? false;
                  const toggleMainFilter = () => {
                    applyValue({ key: 'lastFundingType', value });
                  };
                  return (
                    <div
                      key={`last-funding-type-${value}`}
                      className={`list-wrapper${subList ? '' : ' no-sub'}`}
                    >
                      <ControlInput
                        type="checkbox"
                        label={label}
                        value={value}
                        checked={isParentListChecked}
                        onChange={() => {
                          toggleMainFilter();
                          subList
                            ?.filter((subListData) => {
                              return lastFundingTypeValue?.includes(
                                subListData.value,
                              );
                            })
                            .forEach((subListData) => {
                              applyFilterValueForObjects({
                                key: 'lastFundingType',
                                value: subListData.value,
                                currentFilterState,
                                suggestionKey: 'fundingData',
                              });
                            });
                        }}
                      />
                      {subList && (
                        <div className="sub-list">
                          {subList.map((subListData) => {
                            const subListValue = subListData.value;
                            return (
                              <ControlInput
                                key={`last-funding-type-${subListValue}`}
                                type="checkbox"
                                label={subListData.label}
                                value={subListValue}
                                checked={
                                  lastFundingTypeValue?.includes(
                                    subListValue,
                                  ) || isParentListChecked
                                }
                                onChange={() => {
                                  const otherSubFilters = subList?.filter(
                                    (data) => {
                                      return data.value !== subListValue;
                                    },
                                  );
                                  const toggleSubFilters = () => {
                                    otherSubFilters.forEach((data) => {
                                      applyFilterValueForObjects({
                                        key: 'lastFundingType',
                                        value: data.value,
                                        currentFilterState,
                                        suggestionKey: 'fundingData',
                                      });
                                    });
                                  };
                                  if (isParentListChecked) {
                                    applyFilterValueForObjects({
                                      key: 'lastFundingType',
                                      value,
                                      currentFilterState,
                                      suggestionKey: 'fundingData',
                                    });
                                    toggleSubFilters();
                                  } else {
                                    const otherSubFiltersAllChecked =
                                      otherSubFilters.every((data) => {
                                        return lastFundingTypeValue?.includes(
                                          data.value,
                                        );
                                      });
                                    if (otherSubFiltersAllChecked) {
                                      toggleSubFilters();
                                      toggleMainFilter();
                                    } else {
                                      applyValue({
                                        key: 'lastFundingType',
                                        value: subListValue,
                                      });
                                    }
                                  }
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </div>
        <div className="total-funding-amount-wrapper">
          <p className="label">Total Funding Amount</p>
          <Slider
            rangeSelection
            type={0}
            list={totalFundingAmount}
            onChange={(value) => {
              applyValue({
                key: 'totalFundingMinAmount',
                value: value.left,
                multiple: false,
              });
              applyValue({
                key: 'totalFundingMaxAmount',
                value: value.right,
                multiple: false,
              });
            }}
            value={{
              left:
                appliedFilter?.totalFundingMinAmount ??
                totalFundingAmount[0]?.value,
              right:
                appliedFilter?.totalFundingMaxAmount ??
                totalFundingAmount[0]?.value,
            }}
          />
        </div>
        {/* <div className="current-funding-stage">
          <p className="label">Current Stage of Funding</p>
          <Select
            options={values.currentStageFunding}
            dropdownOffset={{ top: 160, bottom: 20 }}
            value={appliedFilter?.currentStageFunding}
            onChange={(newValue) => {
              applyValue({
                key: 'currentStageFunding',
                value: newValue,
                setMultipleValue: true,
              });
            }}
            placeholder="Current stage"
            enableMultiple
            showSelectedValue={false}
          />
        </div> */}
      </div>
    </div>
  );
}

export default observer(Funding);
