import React, { useEffect, useState } from 'react';
import PlusIcon from 'Assets/svg/plus-icon.svg';
import Menu from 'Components/common/base/Menu';
import LoadingWrapper from 'Components/common/base/Loader';
import { toasterState } from 'Components/common/base/Toaster';
import profileState from 'Stores/profile';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import './styles.scss';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import ChipList from 'Components/common/base/ChipList';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';

function DeleteModalDescription(props) {
  const { emailSchedule } = props;
  return (
    <>
      <p>
        On deleting <b>“{emailSchedule.name}”</b> email digest, the users added
        to this will no longer be notified with any of the credit usage updates.
      </p>
      <p className="question">Are you sure you want to delete?</p>
    </>
  );
}

function ManageEmailDigestPopup(props) {
  const { onClose, openRecurringEmail } = props;

  const [listOfSchedules, setListOfSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRecurringEmails = async () => {
    setIsLoading(true);
    const response = await profileState.getTheRecurringEmails();
    if (response.status === 200) {
      setListOfSchedules(response.data);
    }

    setIsLoading(false);
  };

  const handleOnClickDeleteMail = async (emailSchedule) => {
    const response = await profileState.deleteRecurringMail(emailSchedule.id);

    if (response.status === 200) {
      Utils.mixpanelTrack(CONSTANTS.MX_EMAIL_DIGEST_EVENTS.EMAIL_DIGEST_DELETE);
      getRecurringEmails();

      toasterState.setToastMsg(
        `${emailSchedule.name} is deleted successfully.`,
        CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
      );
    } else {
      toasterState.setToastMsg('Error while deleting the email digest');
    }
  };

  const showDeleteModal = (emailSchedule) => {
    commonModalState.setShowModal(true, {
      modalProps: { className: 'delete-email-digest', level: 2 },
      title: 'Delete Email Digest',
      description: <DeleteModalDescription emailSchedule={emailSchedule} />,
      primaryButtonText: 'Cancel',
      secondaryButtonText: 'Delete',
      secondaryButtonCallback: async () => {
        await handleOnClickDeleteMail(emailSchedule);
      },
    });
  };

  useEffect(() => {
    getRecurringEmails();
  }, []);

  return (
    <Modal show closeModal={onClose} className="email-digest-list-popup">
      <LoadingWrapper position="fixed" show={isLoading} />
      <Modal.Title>Manage Email Digest</Modal.Title>
      <Modal.Content>
        <div className="top-container">
          <span className="sub-title">List of scheduled emails</span>
          <Button
            buttonProps={{ text: 'Create Email Digest' }}
            Icon={PlusIcon}
            onClick={() => {
              openRecurringEmail({ oneTimeEmail: false });
            }}
          />
        </div>
        <div className="list-container">
          <ul className="list-header">
            <li>Name </li>
            <li>Scheduled On</li>
            <li>Time</li>
            <li>Recipients</li>
            <li> </li>
          </ul>
          <div className="scheduled-emails-list-wrapper">
            {listOfSchedules.length > 0 ? (
              <div className="scheduled-emails-list-inner-wrapper">
                {listOfSchedules?.map((emailSchedule) => {
                  return (
                    <ul
                      className="scheduled-emails-list"
                      key={emailSchedule.id}
                    >
                      <li>{emailSchedule.name}</li>
                      {emailSchedule.frequency === 'WEEKLY' ? (
                        <li className="scheduled-on">
                          <span>{emailSchedule.frequency}</span>
                          <span>on</span>
                          <span>{emailSchedule.day}</span>
                        </li>
                      ) : (
                        <li className="scheduled-on">
                          <span>{emailSchedule.frequency}</span>
                          <span>on</span>
                          <span>{emailSchedule.ordinalDay}</span>
                          <span>{emailSchedule.day}</span>
                        </li>
                      )}
                      <li>
                        {emailSchedule.time} ({emailSchedule.timeZone})
                      </li>
                      <li>
                        <ChipList
                          includedList={
                            emailSchedule?.recepients?.map((data) => {
                              return { text: data, value: data };
                            }) ?? []
                          }
                          tagLimit={2}
                        />
                      </li>
                      <li>
                        <Menu align="end">
                          <Menu.Item
                            text="Edit"
                            onClick={() => {
                              openRecurringEmail({
                                ...emailSchedule,
                                oneTimeEmail: false,
                              });
                            }}
                          />
                          <Menu.Item
                            text="Delete"
                            onClick={() => {
                              showDeleteModal(emailSchedule);
                            }}
                          />
                        </Menu>
                      </li>
                    </ul>
                  );
                })}
              </div>
            ) : (
              listOfSchedules.length === 0 && (
                <div className="no-email-digest-text">
                  You do not have any scheduled emails. <br /> Create your first
                  email digest by clicking on <q>Create Email Digest</q>
                </div>
              )
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default ManageEmailDigestPopup;
