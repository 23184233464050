import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageNotAvail from 'Assets/png/companyLogo.png';

function LazyImage(image) {
  const { parentClass, clsName, alt, src, caption, threshold } = image;
  return (
    <div className={parentClass || ''}>
      <LazyLoadImage
        className={clsName || ''}
        alt={alt}
        threshold={threshold || 50}
        src={src !== null ? src : ImageNotAvail}
      />
      <span>{caption}</span>
    </div>
  );
}

export { LazyImage };
export default LazyImage;
