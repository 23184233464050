/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import NewTabIcon from 'Assets/svg/new-tab.svg';
import InlineLoader from 'Components/common/base/InlineLoader';

function ProcessingMessage() {
  return (
    <div className="processing-message-wrapper">
      <div className="loader-wrapper">
        <InlineLoader />
      </div>
      <div className="processing-message-text-wrapper">
        Your recently purchased contacts are now being added to this list.{' '}
        <a href="/profile/purchase-history" target="_blank">
          Track progress in Purchase History. <NewTabIcon />
        </a>
      </div>
    </div>
  );
}

export default ProcessingMessage;
