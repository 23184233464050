import React from 'react';
import AdaptIcon from 'Assets/svg/new_icons/adapt-logo.svg';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import TwoIconConnector from 'Components/common/ExportLeads/TwoIconConnector';

function IntegrationPopup(props) {
  const { data, closePopup } = props;
  const { imgComponent } = data;

  return (
    <Modal
      show
      closeModal={closePopup}
    >
      <TwoIconConnector firstIcon={AdaptIcon} secondIcon={imgComponent} />
      <Modal.Title>Export Contacts</Modal.Title>
      <Modal.Content>
        {`To export contacts to your ${data.title} CRM, Please add contacts to a list, and
        then export it from the 'My Lists' page.`}
      </Modal.Content>
      <Modal.Footer>
        <Button
          buttonProps={{
            text: 'Ok',
          }}
          onClick={closePopup}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default IntegrationPopup;
