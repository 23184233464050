import { observable, action, computed } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import { myLeadsList } from 'Utils/commonApi';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import { toasterState } from 'Components/common/base/Toaster';
import userDetail from './userDetail';
import advSearchFiltersState from './advSearchFilters';

let cancelMyleadsCall = null;

let cancelAPI = null;

function cancelPreviousAPI() {
  if (cancelMyleadsCall !== null) {
    cancelMyleadsCall('cancel');
    cancelMyleadsCall = null;
  }
  if (cancelAPI !== null) {
    cancelAPI('cancel');
    cancelAPI = null;
  }
}

class MyLeadsState {
  @observable accessor leadsResults = [];

  @observable accessor leadsResultCount = 0;

  @observable accessor lastContactId = null;

  @observable accessor lastContactUpdateTs = null;

  @observable accessor firstContactId = null;

  @observable accessor firstContactUpdateTs = null;

  @observable accessor bulkPurchaseIds = [];

  @observable accessor phoneNumberUpdated = {
    id: '',
    type: '',
  };

  @observable accessor bulkSelectedIdForPurchase = [];

  @observable accessor resultLoading = false;

  @observable accessor allContactSelected = false;

  @observable accessor actionLoader = false;

  @observable accessor selectedList = {
    id: '',
    name: '',
  };

  @observable accessor isPurchaseInProgress = false;

  @computed
  get getSelectedIds() {
    const ids = this.leadsResults.reduce((output, input) => {
      if (this.bulkPurchaseIds.includes(input.id)) {
        return [...output, input.memberContactId];
      }
      return output;
    }, []);
    return ids;
  }

  @computed
  get modifiedSearchResults() {
    return this.leadsResults.map((result) => {
      const { company = {} } = result;
      const { paKeywords, lastFundingType, ...remainingCompanyData } = company;
      return {
        ...result,
        company: {
          ...remainingCompanyData,
          paKeywords: advSearchFiltersState.getTextForValues(
            advSearchFiltersState.filterListValues.paKeywords,
            paKeywords,
          ),
          lastFundingType: advSearchFiltersState.getTextForValues(
            advSearchFiltersState.filterListValues.funding,
            lastFundingType?.length > 0
              ? [lastFundingType[lastFundingType.length - 1]]
              : [],
          )?.[0],
        },
      };
    });
  }

  @action
  setActionLoader(value) {
    this.actionLoader = value;
  }

  @action
  setPhoneNumberFlag = (value, id) => {
    this.phoneNumberUpdated = {
      id,
      type: value,
    };
  };

  @action
  setSelectedList(value) {
    this.selectedList = value;
  }

  @action
  updateSelectedBulkContactIds(index) {
    const obj = JSON.parse(JSON.stringify(this.leadsResults));
    if (index !== undefined) {
      const curObj = obj[index];
      if (!obj[index].checked) {
        this.bulkPurchaseIds.push(curObj.id);
      } else if (this.bulkPurchaseIds.includes(curObj.id)) {
        const ind = this.bulkPurchaseIds.indexOf(curObj.id);
        this.bulkPurchaseIds.splice(ind, 1);
      }
      obj[index].checked = !curObj.checked;
    }
    this.leadsResults = obj;
    this.checkIsAllContactSelected();
  }

  @action
  checkIsAllContactSelected() {
    const results = JSON.parse(JSON.stringify(this.leadsResults));
    let i = 0;
    results.map((item, index) => {
      // eslint-disable-next-line no-plusplus
      if (item.checked) {
        i++;
      }
      return true;
    });
    if (i === results.length) {
      this.allContactSelected = true;
    } else {
      this.allContactSelected = false;
    }
  }

  @action
  updateSelectedBulkContactAll(clearSelection) {
    const results = JSON.parse(JSON.stringify(this.leadsResults));

    const bulkIds = JSON.parse(JSON.stringify(this.bulkPurchaseIds));
    let newResults = [];
    if (this.allContactSelected || clearSelection) {
      newResults = results.map((item) => {
        const newItem = item;
        newItem.checked = false;

        if (bulkIds.includes(item.id)) {
          const ind = bulkIds.indexOf(item.id);
          bulkIds.splice(ind, 1);
        }
        return newItem;
      });
      this.allContactSelected = false;
    } else {
      newResults = results.map((item) => {
        const newItem = item;
        newItem.checked = true;

        if (!bulkIds.includes(item.id)) {
          bulkIds.push(item.id);
        }

        return newItem;
      });
      this.allContactSelected = true;
      this.leadsResults = newResults;
    }
    this.leadsResults = newResults;
    this.bulkPurchaseIds = bulkIds;
  }

  @action
  clearPreviouslySelectedContacts() {
    this.bulkPurchaseIds = [];
    this.updateSelectedBulkContactAll(true);
  }

  @action
  getLeadsResults(data) {
    const {
      listId,
      pagination,
      lastContactId,
      lastContactUpdateTs,
      pageLimit = 50,
    } = data;

    const currentListData =
      userDetail.myLeadList?.find((prop) => {
        return prop.id === listId;
      }) ?? {};

    const config = {
      url: Utils.generateUrl(URLS.myList.getListContacts, {
        id: listId !== null && listId !== '' ? listId : 'all',
        limit: pageLimit,
        pagination,
        lastOwnedId: lastContactId,
        lastOwnedIdUpdateTs: lastContactUpdateTs,
        isSharedList: currentListData?.isSharedList || false,
        teamId: userDetail.userInfo?.teamId,
      }),
      method: 'GET',
    };
    if ((listId === '' || listId === null) && this.selectedList.id !== '') {
      this.selectedList.id = '';
      this.selectedList.name = 'All Leads';
    }
    const thisObj = this;

    this.clearPreviouslySelectedContacts();

    cancelPreviousAPI();
    const cancelMyleadsCallBack = (value) => {
      cancelMyleadsCall = value;
    };
    this.resultLoading = true;
    makeApi(config, false, cancelMyleadsCallBack)
      .then((response) => {
        if (response && response.message === undefined) {
          this.isPurchaseInProgress = Utils.isNotUndefinedNotNull(
            response.data.purchaseInProgress,
          )
            ? response.data.purchaseInProgress
            : false;
          if (currentListData?.count >= 0) {
            this.leadsResultCount = currentListData?.count;
          }

          if (response.data.list?.length > 0) {
            const listDetails = response.data.list;
            const results = listDetails.map((item) => {
              const newObj = item;
              if (this.bulkPurchaseIds.includes(item.id)) {
                newObj.checked = true;
              } else {
                newObj.checked = false;
              }
              return newObj;
            });
            this.leadsResults = results;

            this.lastContactId = response.data.nextOwnedContactId;
            this.lastContactUpdateTs = response.data.nextOwnedContactIdUpdateTs;

            this.firstContactId = response.data?.prevOwnedContactId;
            this.firstContactUpdateTs =
              response.data?.prevOwnedContactIdUpdateTs;

            this.checkIsAllContactSelected();

            thisObj.resultLoading = false;
          } else {
            this.leadsResults = [];
            thisObj.resultLoading = false;
          }
        }
      })
      .catch((error) => {
        this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  }

  @action
  createList = async (data) => {
    const config = {
      url: URLS.myList.list,
      method: 'POST',
      data: {
        name: data.name,
        isSharedList: data.isSharedList,
        teamId: userDetail.userInfo.teamId,
      },
    };

    const response = await makeApi(config);
    if (response?.status !== 200) {
      return response?.response;
    }
    return response;
  };

  @action
  renameLeadsList = (id, name, oldName, callback = () => {}) => {
    if (id !== undefined && name !== '') {
      const config = {
        url: Utils.generateUrl(URLS.myList.listUpdate, { id }),
        method: 'PATCH',
        data: {
          name,
        },
      };
      makeApi(config)
        .then((response) => {
          console.log('renameLeadsList ', response);

          if (response?.data?.result === 'Success') {
            Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_LIST_RENAMED, {
              newListName: name,
              oldListName: oldName,
            });
            callback('success');
            userDetail.setMyLeadList(
              userDetail.myLeadList.map((list) => {
                if (list.id === id) {
                  return { ...list, name };
                }
                return list;
              }),
            );

            if (this.selectedList.id === id) {
              this.selectedList.name = name;
            }
          } else {
            callback('failed');
          }
        })
        .catch((error) => {
          console.log('renameLeadsList error ', error);
          callback('failed');
        });
    }
  };

  @action
  deleteLeadsList = async (id, listName) => {
    if (id !== undefined) {
      const config = {
        url: Utils.generateUrl(URLS.myList.listUpdate, { id }),
        method: 'DELETE',
      };
      this.actionLoader = true;
      const response = await makeApi(config);
      this.actionLoader = false;
      if (response?.data?.result === 'Success') {
        toasterState.setToastMsg(
          'List deleted successfully',
          CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
        );
        Utils.mixpanelTrack(CONSTANTS.MX_ML_EVENTS.ML_LIST_DELETED, {
          listName,
        });
        myLeadsList();
        return true;
      }
      toasterState.setToastMsg(
        'Failed to delete the list',
        CONSTANTS.TOAST_MSG_TYPES.FAILURE,
      );
      return false;
    }
    return false;
  };

  @action
  addToList = async (data) => {
    const { listId, listName, memberContactIds } = data;

    if (listId !== undefined) {
      const config = {
        url: Utils.generateUrl(URLS.myList.listContacts, { id: listId }),
        method: 'POST',
        data: {
          ids: memberContactIds,
        },
      };

      const response = await makeApi(config);
      const count = memberContactIds.length;
      if (response?.data?.result === 'Success') {
        toasterState.setToastMsg(
          `${count} contact${count > 1 ? 's' : ''} added to ${listName}`,
          CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
        );
        userDetail.updateMyleadsListCount(response.data?.countByListId);
      } else {
        toasterState.setToastMsg(
          `Failed to add leads to the list ${listName}`,
          CONSTANTS.TOAST_MSG_TYPES.FAILURE,
        );
      }
    }
  };

  @action
  addToAnotherList = async (id, listName) => {
    this.actionLoader = true;

    await this.addToList({
      memberContactIds: this.getSelectedIds,
      listId: id,
      listName,
    });

    this.actionLoader = false;
    this.clearPreviouslySelectedContacts();
    const listId = this.selectedList.id;
    this.getLeadsResults({
      listId,
    });
  };

  @action
  moveToAnotherList(listId, listName) {
    if (listId !== undefined) {
      const config = {
        url: Utils.generateUrl(URLS.myList.listContacts, {
          id: this.selectedList.id,
        }),
        method: 'PATCH',
        data: {
          listId,
          ids: this.getSelectedIds,
        },
      };
      this.actionLoader = true;
      makeApi(config)
        .then((response) => {
          const count = this.bulkPurchaseIds.length;
          if (response?.data?.result === 'Success') {
            toasterState.setToastMsg(
              `${count} contact${count > 1 ? 's' : ''} moved to ${listName}`,
              CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
            );
            userDetail.updateMyleadsListCount(response.data?.countByListId);
          } else {
            toasterState.setToastMsg(
              `Failed to move leads to list ${listName}`,
              CONSTANTS.TOAST_MSG_TYPES.FAILURE,
            );
          }
          this.actionLoader = false;
          this.clearPreviouslySelectedContacts();
          const currentListId = this.selectedList.id;
          this.getLeadsResults({
            listId: currentListId,
          });
        })
        .catch((error) => {
          console.log('moveToAnotherList error ', error);
          this.actionLoader = false;
        });
    }
  }

  @action
  deleteFromList = async (listId, listName) => {
    if (listId !== undefined) {
      const config = {
        url: Utils.generateUrl(URLS.myList.listContacts, {
          id: this.selectedList.id,
        }),
        method: 'DELETE',
        data: {
          ids: this.getSelectedIds,
        },
      };
      this.actionLoader = true;
      const response = await makeApi(config);

      const count = this.bulkPurchaseIds.length;

      if (response?.data?.result === 'Success') {
        toasterState.setToastMsg(
          `${count} contact${
            count > 1 ? 's' : ''
          } has been deleted from ${listName}`,
          CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
        );
        userDetail.updateMyleadsListCount(response.data?.countByListId);
      } else {
        toasterState.setToastMsg(
          `Failed to delete leads from list ${listName}`,
          CONSTANTS.TOAST_MSG_TYPES.FAILURE,
        );
      }
      this.actionLoader = false;
      const currentListId = this.selectedList.id;
      this.getLeadsResults({
        listId: currentListId,
      });
    }
  };

  @action
  updateSearchResult = (newList = []) => {
    if (newList.length) {
      const updatedList = Utils.copyObjectWithoutReference(this.leadsResults);
      newList.map((item) => {
        const newObj = item;
        newObj.checked = false;
        if (
          updatedList.findIndex((obj) => {
            return obj.id === newObj.id;
          }) >= 0
        ) {
          const ind = updatedList.findIndex((obj) => {
            return obj.id === newObj.id;
          });
          const searchObjData = updatedList[ind];
          searchObjData.email = newObj.email || null;
          searchObjData.phoneScoreEntity = newObj.phoneScoreEntity || null;
          searchObjData.owned = newObj.owned || false;
          searchObjData.emailValid = newObj.emailValid || false;
          updatedList[ind] = searchObjData;
        }
        return true;
      });
      this.leadsResults = updatedList;
    }
  };
}

const myLeadsState = new MyLeadsState();

export { cancelPreviousAPI };
export default myLeadsState;
