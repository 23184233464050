import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import ToggleType from 'Components/common/base/Toggle';
import Input from 'Components/common/base/Input';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Utils from 'Utils/utils';
import trackMixpanelEvent from '../trackMixpanelEvent';

const defaultSuggestionValue = {
  list: [],
  isLoading: false,
};

const getSuggestionsFn = Utils.debounce((value, fn = EMPTY_FUNCTION) => {
  return fn(value);
});

function isSuggestionSelected(data) {
  const { list, selectedValues, key, excludeKey } = data;

  const isValueAvailable = (prop, filterKey) => {
    return (
      selectedValues?.[filterKey]?.includes(prop.text) ||
      selectedValues?.[filterKey]?.includes(prop.description)
    );
  };

  return list.map((prop) => {
    const isIncluded = isValueAvailable(prop, key);
    const isExcluded = excludeKey && isValueAvailable(prop, excludeKey);

    return {
      ...prop,
      checked: isIncluded || isExcluded,
      color: isExcluded ? 'error' : 'primary',
    };
  });
}

function defaultConstructData(data) {
  const { selectedObject, previousValues, checked, valueKey } = data;
  const selectedValue = selectedObject[valueKey];

  let valueArray = [];

  if (previousValues && previousValues !== undefined) {
    if (previousValues.length > 0) {
      previousValues.forEach((item) => {
        const newValue = {};
        newValue.pName = item.trim();
        valueArray.push(newValue);
      });
    }
  }

  if (!checked) {
    valueArray = valueArray.filter((valueItem) => {
      return valueItem.pName !== selectedValue;
    });
  } else {
    selectedValue.split(',').forEach((item) => {
      const newValue = {};
      if (item.trim() !== '') {
        newValue.pName = item.trim();
      }
      valueArray.push(newValue);
    });
  }
  return valueArray;
}

function InputSuggestion(props) {
  const {
    label,
    placeholder,
    showExcludeToggle = false,
    currentFilterState,
    suggestionKey,
    excludeSuggestionKey,
    page,
    mxEventName,
    mxUniqueKey,
    getSuggestions,
    selectMultiple = false,
    tooltipMessage = '',
    constructSelectedData,
    suggestionSelected = isSuggestionSelected,
    valueKey = 'text',
  } = props;

  const [value, setValue] = useState('');
  const [isExcludeEnabled, setIsExcludeEnabled] = useState(false);
  const [suggestionData, setSuggestionData] = useState(defaultSuggestionValue);

  const onSelectSuggestion = (selectedVal, newChecked) => {
    const currentSuggestionKey = isExcludeEnabled
      ? excludeSuggestionKey
      : suggestionKey;
    const selectedValue = selectedVal.text;

    let valueArray = [];
    const dataToProcess = {
      selectedObject: selectedVal,
      key: currentSuggestionKey,
      previousValues:
        currentFilterState.appliedFilterSectionData[currentSuggestionKey],
      checked: newChecked,
      valueKey,
    };
    if (constructSelectedData) {
      valueArray = constructSelectedData(dataToProcess);
    } else {
      valueArray = defaultConstructData(dataToProcess);
    }

    trackMixpanelEvent({
      selectedValue,
      key: currentSuggestionKey,
      page,
      showExcludeToggle,
      isExcludeEnabled,
      mxUniqueKey,
      mxEventName,
    });

    currentFilterState.setAppliedFilters(
      currentSuggestionKey,
      valueArray,
      null,
      true,
    );
  };

  const getSuggestionsForInput = (val) => {
    if (getSuggestions) {
      setSuggestionData((prev) => {
        return { ...prev, isLoading: true };
      });
      getSuggestionsFn(val, getSuggestions).then((data) => {
        setSuggestionData({
          list: suggestionSelected({
            list: data,
            selectedValues: currentFilterState.appliedFilterSectionData,
            key: suggestionKey,
            excludeKey: excludeSuggestionKey,
          }),
          isLoading: false,
        });
      });
    } else {
      setSuggestionData({ list: [{ text: val }], isLoading: false });
    }
  };

  useEffect(() => {
    setSuggestionData((prevData) => {
      return {
        ...prevData,
        list: suggestionSelected({
          list: prevData.list,
          selectedValues: currentFilterState.appliedFilterSectionData,
          key: suggestionKey,
          excludeKey: excludeSuggestionKey,
        }),
      };
    });
  }, [currentFilterState.appliedFilterSectionData, suggestionKey]);

  const resetSuggestion = () => {
    setSuggestionData(defaultSuggestionValue);
  };

  const handleInputOnChange = (val) => {
    setValue(val);
    if (val.length > 0) {
      getSuggestionsForInput(val);
    } else {
      resetSuggestion();
    }
  };

  return (
    <div className="input-search-filter">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!selectMultiple) {
            onSelectSuggestion({ text: value }, true);
            setValue('');
            resetSuggestion();
            // triggering outside click to close suggestion
            document
              .getElementsByClassName('input-search-filter')?.[0]
              ?.click();
          }
        }}
      >
        <Input
          label={label}
          offsetValues={{ top: 0, bottom: 50 }}
          inputProps={{ placeholder, autoComplete: 'new-password', value }}
          showSearchIcon
          clearValueOnOutsideClick
          isSuggestionsLoading={suggestionData.isLoading}
          selectMultiple={selectMultiple}
          enableSuggestions
          onChange={handleInputOnChange}
          suggestions={suggestionData.list}
          onSuggestionSelect={(selectedVal, newChecked) => {
            onSelectSuggestion(selectedVal, newChecked);
            if (!selectMultiple) {
              setValue('');
              resetSuggestion();
            }
          }}
        />
      </form>
      {showExcludeToggle && (
        <div className="include-exclude-toggle">
          <ToggleType
            firstText="Include"
            lastText="Exclude"
            toggle={isExcludeEnabled}
            cbk={(newValue) => {
              setIsExcludeEnabled(newValue);
            }}
          />
          <ToolTipWithIcon>{tooltipMessage}</ToolTipWithIcon>
        </div>
      )}
    </div>
  );
}

export default observer(InputSuggestion);
