/* eslint-disable no-param-reassign */
import { observable, action, computed } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import userDetail from 'Stores/userDetail';
import advSearchFiltersState from './advSearchFilters';

let cancelCompanyCall = null;
class CompanySearchState {
  @observable accessor appliedFilters = [];

  @observable accessor maxSelectionAllowed = 2000;

  @observable accessor salesforceExportMaxLimit = 200;

  @observable accessor resultStatusMsg = 'empty';

  @observable accessor appliedFilterPostData = {};

  @observable accessor appliedFilterSectionData = {};

  @observable accessor appliedFilterData = {};

  @observable accessor searchResults = [];

  @observable accessor searchResultCount = 0;

  @observable accessor searchCursorMark = [];

  @observable accessor pageLimit = 50;

  @observable accessor currentPage = 0;

  @observable accessor bulkPurchaseIds = [];

  @observable accessor selectedCompanyDomain = [];

  @observable accessor hugeSelectionBulkIds = [];

  @observable accessor hugeSelectionDomain = [];

  @observable accessor curListIds = [];

  @observable accessor resultLoading = false;

  @observable accessor searchLoading = false;

  @observable accessor allContactSelected = false;

  @observable accessor selectedAllContactFromPage = false;

  @observable accessor selectedContactFromRange = false;

  @observable accessor selectedAllContactAcrossPage = false;

  @observable accessor bulkSelectionPostData = {};

  @observable accessor checkBoxFilters = {
    dontDisplayOwnedCompanies: true,
    dontDisplayTeamCompanies: true,
  };

  @observable accessor uploadCsv = {
    includedDomains: false,
    excludedDomains: false,
  };

  @observable accessor actionLoader = false;

  @computed
  get modifiedSearchResults() {
    return this.searchResults.map((result) => {
      const { paKeywords, lastFundingType } = result;
      return {
        ...result,
        paKeywords: advSearchFiltersState.getTextForValues(
          advSearchFiltersState.filterListValues.paKeywords,
          paKeywords,
        ),
        lastFundingType: advSearchFiltersState.getTextForValues(
          advSearchFiltersState.filterListValues.funding,
          lastFundingType?.length > 0
            ? [lastFundingType[lastFundingType.length - 1]]
            : [],
        )?.[0],
      };
    });
  }

  @action
  setAllContactsInPageSelected(value) {
    this.selectedAllContactFromPage = value;
  }

  @action
  setActionLoader(value) {
    this.actionLoader = value;
  }

  @action
  updateSelectedBulkContactIds(index) {
    const obj = JSON.parse(JSON.stringify(this.searchResults));
    if (index !== undefined) {
      if (!obj[index].checked) {
        this.bulkPurchaseIds.push(obj[index].id);
        this.selectedCompanyDomain.push(obj[index].primaryDomain);
      } else if (this.bulkPurchaseIds.includes(obj[index].id)) {
        const ind = this.bulkPurchaseIds.indexOf(obj[index].id);
        this.bulkPurchaseIds.splice(ind, 1);
        this.selectedCompanyDomain.splice(ind, 1);
      }
      obj[index].checked = !obj[index].checked;
    }
    this.searchResults = obj;
    this.checkIsAllContactSelected();
  }

  @action
  checkIsAllContactSelected() {
    const results = JSON.parse(JSON.stringify(this.searchResults));
    let i = 0;
    results.map((item) => {
      // eslint-disable-next-line no-plusplus
      if (item.checked) {
        i += 1;
      }
      return true;
    });
    if (i === results.length) {
      this.allContactSelected = true;
    } else {
      this.allContactSelected = false;
    }
  }

  @action
  updateSelectedBulkContactRange(selectionValue) {
    this.allContactSelected = false;
    this.bulkPurchaseIds = [];
    this.selectedCompanyDomain = [];

    const results = JSON.parse(JSON.stringify(this.searchResults));
    const bulkIds = JSON.parse(JSON.stringify(this.bulkPurchaseIds));
    const bulkDomains = [];
    let newResults = [];
    this.selectedContactFromRange = false;
    this.setAllContactsInPageSelected(false);
    this.selectedAllContactAcrossPage = false;

    if (this.pageLimit > selectionValue) {
      newResults = results.map((item, index) => {
        const newItem = item;
        if (selectionValue > index) {
          newItem.checked = true;
        } else {
          newItem.checked = false;
        }

        if (!bulkIds.includes(item.id)) {
          bulkIds.push(item.id);
          bulkDomains.push(item.primaryDomain);
        }

        return newItem;
      });
      this.allContactSelected = false;
    } else {
      newResults = results.map((item) => {
        const newItem = item;
        newItem.checked = true;

        if (!bulkIds.includes(item.id)) {
          bulkIds.push(item.id);
          bulkDomains.push(item.primaryDomain);
        }

        return newItem;
      });
      this.allContactSelected = true;
      this.searchResults = newResults;
    }

    this.searchResults = newResults;
    this.bulkPurchaseIds = bulkIds;
    this.selectedCompanyDomain = bulkDomains;

    console.log('bulkIds ', bulkIds);
    console.log('bulkPurchaseIds ', this.bulkPurchaseIds);
    console.log('selectedCompanyDomain ', this.selectedCompanyDomain);
  }

  @action
  updateSelectedBulkContactAll(clearSelection, setAllSelectedFalse) {
    if (clearSelection) {
      this.allContactSelected = true;
      this.hugeSelectionBulkIds = [];
      this.hugeSelectionDomain = [];
      this.bulkSelectionPostData = {};
    } else {
      this.hugeSelectionBulkIds = [];
      this.hugeSelectionDomain = [];
    }

    if (setAllSelectedFalse) {
      this.allContactSelected = false;
      this.bulkPurchaseIds = [];
      this.selectedCompanyDomain = [];
    }

    const results = JSON.parse(JSON.stringify(this.searchResults));
    const bulkIds = JSON.parse(JSON.stringify(this.bulkPurchaseIds));
    const bulkDomains = JSON.parse(JSON.stringify(this.selectedCompanyDomain));

    let newResults = [];
    this.selectedContactFromRange = false;
    this.setAllContactsInPageSelected(false);
    this.selectedAllContactAcrossPage = false;
    if (this.allContactSelected) {
      newResults = results.map((item) => {
        const newItem = item;
        newItem.checked = false;
        if (bulkIds.includes(item.id)) {
          const ind = bulkIds.indexOf(item.id);
          bulkIds.splice(ind, 1);
          bulkDomains.splice(ind, 1);
        }
        return newItem;
      });
      this.allContactSelected = false;
    } else {
      newResults = results.map((item) => {
        const newItem = item;
        newItem.checked = true;

        if (!bulkIds.includes(item.id)) {
          bulkIds.push(item.id);
          bulkDomains.push(item.primaryDomain);
        }

        return newItem;
      });
      this.allContactSelected = true;
      this.searchResults = newResults;
    }
    this.searchResults = newResults;
    this.bulkPurchaseIds = bulkIds;
    this.selectedCompanyDomain = bulkDomains;

    console.log('bulkIds ', bulkIds);
    console.log('bulkPurchaseIds ', this.bulkPurchaseIds);
    console.log('selectedCompanyDomain ', this.selectedCompanyDomain);
    console.log('hugeSelectionBulkIds ', this.hugeSelectionBulkIds);
    console.log('hugeSelectionDomain ', this.hugeSelectionDomain);
  }

  @action
  setPageLimit(limit) {
    if (limit > 0) {
      this.pageLimit = limit;
      if (this.searchCursorMark.length) {
        this.searchCursorMark.pop();
      }
      this.setAppliedFilters();
    }
  }

  @action
  setCurrentPage(pageNo, opr, changePagenoOnly = false) {
    if (!this.resultLoading) {
      if (opr === '-') {
        if (this.searchCursorMark.length) {
          this.searchCursorMark.pop();
          this.searchCursorMark.pop();
        }
      }

      this.bulkPurchaseIds = [];
      this.selectedCompanyDomain = [];

      this.currentPage = pageNo;
      if (!changePagenoOnly) {
        this.setAppliedFilters(undefined, undefined, true);
      }
    }
  }

  @action
  setCheckBoxFilters(name) {
    if (name !== undefined && name !== '' && name !== null) {
      this.checkBoxFilters[name] = !this.checkBoxFilters[name];
      this.clearAllSelection();
      this.setAppliedFilterPostData(this.appliedFilterData);
    }
  }

  @action
  setUploadCsv(key, value) {
    if (
      key !== undefined &&
      key !== '' &&
      key !== null &&
      value !== undefined
    ) {
      this.uploadCsv[key] = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
      this.appliedFilterSectionData[key] = [value.fname];
    }
  }

  @action
  clearAllFilters() {
    this.uploadCsv = {
      includedDomains: false,
      excludedDomains: false,
    };
    this.checkBoxFilters = {
      dontDisplayOwnedCompanies: true,
      dontDisplayTeamCompanies: true,
    };

    this.resultStatusMsg = '';

    Utils.setLocalStorage('companySerPostData', {});
    this.retainSearch();
  }

  @action
  removeAppliedFilterFromGroup({ category, value, objectKey }) {
    if (window.location.hash !== '#search') {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/company#search');
    }

    const appFilterData = JSON.parse(JSON.stringify(this.appliedFilterData));
    if (
      category === 'fundingData' ||
      category === 'leadershipHire' ||
      category === 'headcountGrowth'
    ) {
      if (objectKey) {
        const appliedValue = appFilterData[category][objectKey];
        if (Array.isArray(appliedValue) && value) {
          appFilterData[category][objectKey] = appliedValue.filter((prop) => {
            return prop !== value;
          });
        } else {
          delete appFilterData[category][objectKey];
        }
      } else {
        delete appFilterData[category];
      }
    } else if (
      category === 'industrySectors' &&
      appFilterData[category] &&
      appFilterData[category] !== undefined &&
      appFilterData[category].industries
    ) {
      let filterData = JSON.parse(JSON.stringify(appFilterData[category]));

      if (Object.keys(value).length === 0) {
        filterData = {};
      } else {
        appFilterData[category].industries.filter((item, index) => {
          if (
            item.industry &&
            typeof item.industry === 'string' &&
            (item.industry === value || item.industry === value.toString())
          ) {
            filterData.industries.splice(index, 1);
          } else if (item.sectors && item.sectors.length) {
            // eslint-disable-next-line array-callback-return
            item.sectors.filter((sectorValue, idx) => {
              if (sectorValue && value && value.toString() === sectorValue) {
                filterData.industries[index].sectors.splice(idx, 1);
              }
            });
            if (
              filterData.industries[index] !== undefined &&
              filterData.industries[index].sectors !== undefined &&
              filterData.industries[index].sectors.length === 0
            ) {
              filterData.industries.splice(index, 1);
            }
          } else if (
            item.industry &&
            typeof item.industry === 'object' &&
            item.industry.includes(value)
          ) {
            if (item.industry.length === 1) {
              filterData.industries.splice(index, 1);
            } else {
              const newItem = item;
              newItem.industry = item.industry.splice(
                item.industry.indexOf(value),
                1,
              );
              filterData[index] = newItem;
            }
          }

          return true;
        });
      }

      if (
        filterData.industries !== undefined &&
        filterData.industries.length === 0
      ) {
        appFilterData[category] = [];
      } else {
        appFilterData[category] = filterData;
      }
    } else if (
      category === 'industrySectors' &&
      appFilterData[category] &&
      appFilterData[category] !== undefined
    ) {
      appFilterData[category] = {};
    } else if (appFilterData[category] && appFilterData[category].length > 0) {
      let filterData = JSON.parse(JSON.stringify(appFilterData[category]));

      const loopFilterData = JSON.parse(JSON.stringify(filterData));
      if (value.length === 0) {
        filterData = [];
      } else {
        loopFilterData.filter((item, index) => {
          if (
            item.pName &&
            typeof item.pName === 'string' &&
            (item.pName === value || item.pName === value.toString())
          ) {
            filterData.splice(index, 1);
            if (category === 'keyword') {
              if (
                appFilterData.industryKeywordsJson !== undefined &&
                appFilterData.industryKeywordsJson.length > 0
              ) {
                appFilterData.industryKeywordsJson.splice(index, 1);
              }
            }
          } else if (
            item.pName &&
            typeof item.pName === 'object' &&
            item.pName.includes(value)
          ) {
            if (item.pName.length === 1) {
              filterData.splice(index, 1);
            } else {
              const newItem = item;
              newItem.pName = item.pName.splice(item.pName.indexOf(value), 1);
              filterData[index] = newItem;
            }
          }

          return true;
        });
      }

      appFilterData[category] = filterData;
    }
    if (category === 'includedDomains' || category === 'excludedDomains') {
      this.uploadCsv[category] = false;
    }
    this.appliedFilterData = appFilterData;
    this.resetPageNumberToDefaultValue(true);
    this.setAppliedFilterPostData(appFilterData);
  }

  @action
  resetPageNumberToDefaultValue(isClearSelection = false) {
    this.searchCursorMark = [];
    this.currentPage = 0;
    if (isClearSelection) {
      this.clearAllSelection();
    }
  }

  @action
  setAppliedFilters(key, value, isPagination, hashParam, performSearch = true) {
    if (key === 'industrySectors') {
      if (value.isAllIndustry === true) {
        value = '';
      }
    }

    const appliedFiltersTemp = this.appliedFilterData;
    if (appliedFiltersTemp[key] === undefined && key !== undefined) {
      appliedFiltersTemp[key] = [];
    }

    if (key !== undefined && value !== undefined) {
      appliedFiltersTemp[key] = value;
      if (key === 'keyword') {
        appliedFiltersTemp.industryKeywordsJson = value;
      }
      if (performSearch) {
        this.searchCursorMark.pop();
      }
    }
    this.appliedFilterData = appliedFiltersTemp;
    if (isPagination === undefined || isPagination === null) {
      this.resetPageNumberToDefaultValue(true);
    }

    this.setAppliedFilterPostData(appliedFiltersTemp, hashParam, performSearch);
  }

  @action
  setAppliedFilterPostData(data, hashParam, makeSearchCall = true) {
    let performSearch = makeSearchCall;
    if (hashParam !== undefined && hashParam === true) {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/company#search');
    }
    // this.appliedFilterPostData = {};
    const appliedKeys = Object.keys(data);
    const postData = {};
    postData.industryKeywords = [];
    postData.sectorKeywords = [];
    // eslint-disable-next-line array-callback-return
    appliedKeys.map((item) => {
      const keyItem = this.appliedFilterData[item];
      let keyValue = [];

      if (item === 'fundingData') {
        Object.keys(this.appliedFilterData[item]).forEach((prop) => {
          const value = this.appliedFilterData[item]?.[prop];
          if (
            Number.isFinite(value) ||
            value?.length > 0 ||
            Object.keys(value)?.length > 0
          ) {
            if (!postData[item]) {
              postData[item] = {};
            }
            postData[item][prop] = value;
          }
        });
      } else if (item === 'headcountGrowth') {
        const isMonthsAvailable =
          this.appliedFilterData[item].months !== undefined &&
          this.appliedFilterData[item].months !== '';
        postData[item] = this.appliedFilterData[item];
        if (!isMonthsAvailable || !keyItem?.percentage) {
          performSearch = false;
        }
      } else if (item === 'leadershipHire') {
        const isDaysValueAvailable =
          this.appliedFilterData[item].months !== undefined &&
          this.appliedFilterData[item].months !== '';

        postData[item] = this.appliedFilterData[item];
        if (!isDaysValueAvailable) {
          performSearch = false;
        }
      } else if (item === 'industryKeywords' || item === 'sectorKeywords') {
        console.log('dont do anything');
      } else if (item === 'industryKeywordsJson') {
        postData[item] = this.appliedFilterData[item];
        postData.industryKeywords = Utils.getIndustryKeywordArray(
          this.appliedFilterData[item],
        );
        postData.sectorKeywords = Utils.getSectorKeywordArray(
          this.appliedFilterData[item],
        );
      } else if (item === 'industrySectors') {
        if (this.appliedFilterData[item].industries !== undefined) {
          postData[item] = this.appliedFilterData[item];
        }
      } else if (keyItem.length > 0 && typeof keyItem === 'object') {
        if (
          item === 'selectedCities' ||
          item === 'selectedStates' ||
          item === 'selectedCountries' ||
          item === 'selectedRegions' ||
          item === 'selectedExcldedCountriesWholeData' ||
          item === 'industrySectorsV2' ||
          item === 'excludeIndustrySectorsV2'
        ) {
          keyValue = keyItem.map((i) => {
            return i;
          });
        } else {
          keyValue = keyItem.map((i, eIndex) => {
            if (i !== undefined && i !== null && i.pName !== undefined) {
              return i.pName;
            }
            if (typeof i === 'string') {
              return i;
            }
            keyItem.splice(eIndex, 1);
            return '';
          });
        }
        if (keyValue.length > 0) {
          keyValue = keyValue.filter((el) => {
            return el !== null && el !== '';
          });
        }
        postData[item] = keyValue;
      }
    });

    if (this.uploadCsv.includedDomains) {
      postData.includedDomains = this.uploadCsv.includedDomains;
    }
    if (this.uploadCsv.excludedDomains) {
      postData.excludedDomains = this.uploadCsv.excludedDomains;
    }

    const postDataKeys = Object.keys(postData);
    postDataKeys.map((item) => {
      if (postData[item].length === 0 || postData[item] === '') {
        delete postData[item];
      }

      // migrated to v2 obj
      if (item === 'companyKeyword') {
        delete postData[item];
      }
      if (item === 'industrySectors') {
        delete postData[item];
      }
      return true;
    });
    console.log('postData ', postData);

    this.appliedFilterSectionData = postData;
    if (this.uploadCsv.includedDomains) {
      this.appliedFilterSectionData.includedDomains = [
        this.uploadCsv.includedDomains.fname,
      ];
    }
    if (this.uploadCsv.excludedDomains) {
      this.appliedFilterSectionData.excludedDomains = [
        this.uploadCsv.excludedDomains.fname,
      ];
    }

    if (Object.keys(postData).length > 0) {
      const postDataCheckObj = Utils.copyObjectWithoutReference(postData);

      if (
        Object.keys(postDataCheckObj).length === 1 &&
        (postData.selectedCountries !== undefined ||
          (postData.industrySectors !== undefined &&
            postData.industrySectors.industries === undefined))
      ) {
        this.resultStatusMsg = 'one_more';
        this.searchResults = [];
        this.searchResultCount = 0;
      } else {
        this.resultStatusMsg = '';
      }

      postData.limit = this.pageLimit;
      if (this.searchCursorMark.length && this.currentPage > 0) {
        postData.cursorMark =
          this.searchCursorMark[this.searchCursorMark.length - 1];
      } else {
        delete postData.cursorMark;
      }
      if (this.currentPage > 0) {
        postData.currentPage = this.currentPage;
      } else {
        delete postData.currentPage;
      }

      postData.dontDisplayOwnedCompanies =
        this.checkBoxFilters.dontDisplayOwnedCompanies;
      postData.dontDisplayTeamCompanies =
        this.checkBoxFilters.dontDisplayTeamCompanies;
      this.appliedFilterPostData = postData;

      Utils.setLocalStorage('companySerPostData', postData);

      if (this.resultStatusMsg !== 'one_more' && performSearch) {
        this.getSearchResults();
      }
    } else {
      if (cancelCompanyCall !== null) {
        cancelCompanyCall('cancel');
        cancelCompanyCall = null;
        this.searchLoading = false;
        this.resultLoading = false;
      }
      this.resultStatusMsg = 'empty';
      postData.dontDisplayOwnedCompanies =
        this.checkBoxFilters.dontDisplayOwnedCompanies;
      postData.dontDisplayTeamCompanies =
        this.checkBoxFilters.dontDisplayTeamCompanies;

      Utils.setLocalStorage('companySerPostData', postData);
      this.appliedFilterPostData = {};
      this.searchResultCount = 0;
      this.searchResults = [];
    }
  }

  @action
  trackSearchEvent() {
    const thisObj = this;
    const mxData = {};
    mxData.companyCount = thisObj.searchResultCount;
    mxData.searchQuery = this.appliedFilterPostData;
    mxData.searchType = 'Company';
    mxData.filterPlace = 'page';
    Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_LB_SEARCH_PERFORMED, mxData);
  }

  getSearchResults() {
    const config = {
      url: `${URLS.companySearchResults}?NOW=${Date.now()}`,
      method: 'POST',
      data: {
        ...this.appliedFilterPostData,
        showContactsWithoutEmail:
          userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail,
        showContactsWithEmail:
          userDetail.userInfo.searchFilterFlags.showContactsWithEmail,
      },
    };
    const thisObj = this;
    if (cancelCompanyCall !== null) {
      cancelCompanyCall('cancel');
      cancelCompanyCall = null;
    }
    const companyCancelCallback = (value) => {
      cancelCompanyCall = value;
    };

    this.resultLoading = true;
    this.searchLoading = true;
    makeApi(config, false, companyCancelCallback)
      .then((response) => {
        if (response && response.message === undefined) {
          thisObj.searchLoading = false;
          if (response && response.data) {
            const responseData = response.data;
            if (responseData.result && responseData.result.length > 0) {
              const results = responseData.result.map((item) => {
                const newObj = item;
                if (this.bulkPurchaseIds.includes(item.id)) {
                  newObj.checked = true;
                } else {
                  newObj.checked = false;
                }
                return newObj;
              });

              this.searchResults = results;
              const ids = responseData.result.map((item) => {
                return item && item.id ? item.id : '';
              });
              this.curListIds = ids;
              this.checkIsAllContactSelected();
            } else {
              this.searchResults = [];
            }
            if (response && response.data && response.data.hits >= 0) {
              this.searchResultCount = response.data.hits;
            }

            thisObj.trackSearchEvent();

            if (responseData && responseData.cursorMark) {
              this.searchCursorMark.push(responseData.cursorMark);
            }
          }

          thisObj.resultLoading = false;
        }
      })
      .catch((error) => {
        thisObj.resultLoading = false;
        thisObj.searchLoading = false;
        console.log('suggestionList error ', error);
      });
  }

  @action
  selectHugeContacts(limit, allFromSearch) {
    const postData = this.appliedFilterPostData;
    postData.limit = limit;
    const config = {
      url: URLS.companySearchResults,
      method: 'POST',
      data: postData,
    };
    this.bulkSelectionPostData = postData;
    this.resultLoading = true;
    const thisObj = this;
    makeApi(config)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result.length > 0
        ) {
          const hugeBulkIds = [];
          const hugeBulkDomains = [];
          response.data.result.map((item) => {
            hugeBulkIds.push(item.id);
            hugeBulkDomains.push(item.primaryDomain);
            return true;
          });

          if (allFromSearch) {
            this.bulkPurchaseIds = [];
            this.selectedCompanyDomain = [];
            this.allContactSelected = false;
            this.updateSelectedBulkContactAll();

            this.selectedContactFromRange = false;
            this.setAllContactsInPageSelected(false);
            this.selectedAllContactAcrossPage = true;
          } else {
            this.updateSelectedBulkContactRange(limit);

            this.selectedAllContactAcrossPage = false;
            this.setAllContactsInPageSelected(false);
            this.selectedContactFromRange = true;
          }

          this.hugeSelectionBulkIds = hugeBulkIds;
          this.hugeSelectionDomain = hugeBulkDomains;
          console.log('hugeSelectionBulkIds ', hugeBulkIds);
          console.log('hugeSelectionDomain ', hugeBulkDomains);
        } else {
          // TODO unable to select
        }

        thisObj.resultLoading = false;
      })
      .catch((error) => {
        this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  }

  @action
  clearHugePurchaseIds() {
    this.hugeSelectionBulkIds = [];
    this.hugeSelectionDomain = [];
    this.selectedAllContactAcrossPage = false;
    this.selectedAllContactFromPage = false;
    this.selectedContactFromRange = false;
  }

  @action
  clearAllSelection() {
    this.bulkPurchaseIds = [];
    this.selectedCompanyDomain = [];
    this.bulkSelectionPostData = {};
    this.clearHugePurchaseIds();
  }

  @action
  applySearch(localValue, resetPageNumber = false) {
    const localValKeys = Object.keys(localValue);

    const thisObj = this;
    thisObj.appliedFilterData = {};
    if (resetPageNumber) {
      thisObj.resetPageNumberToDefaultValue(true);
    }

    if (localValKeys.length) {
      localValKeys.map((item) => {
        if (item === 'keyword') {
          console.log('dont do anything');
        } else if (item === 'limit') {
          thisObj.pageLimit = localValue[item];
        } else if (item === 'includedDomains' || item === 'excludedDomains') {
          thisObj.uploadCsv[item] = localValue[item];
        } else if (
          item === 'dontDisplayOwnedCompanies' ||
          item === 'dontDisplayTeamCompanies'
        ) {
          thisObj.checkBoxFilters[item] = localValue[item];
        } else if (
          item === 'selectedCities' ||
          item === 'selectedStates' ||
          item === 'selectedCountries' ||
          item === 'selectedRegions' ||
          item === 'selectedExcldedCountriesWholeData' ||
          item === 'industrySectorsV2' ||
          item === 'excludeIndustrySectorsV2' ||
          item === 'leadershipHire' ||
          item === 'headcountGrowth' ||
          item === 'fundingData'
        ) {
          thisObj.appliedFilterData[item] = localValue[item];
        } else if (item === 'industrySectors') {
          thisObj.appliedFilterData[item] = localValue[item];
        } else if (item === 'industryKeywordsJson') {
          thisObj.appliedFilterData[item] = localValue[item];
          thisObj.appliedFilterData.keyword = localValue[item];
        } else {
          let formattedValue = localValue[item];
          if (localValue[item].length && typeof localValue[item] === 'object') {
            formattedValue = localValue[item].map((i) => {
              return {
                name: i,
                pName: i,
              };
            });
          }

          thisObj.appliedFilterData[item] = formattedValue;
        }
        return true;
      });
      this.setAppliedFilterPostData(this.appliedFilterData);
    } else {
      this.setAppliedFilterPostData({});
    }
  }

  @action
  retainSearch() {
    if (window.location.hash === '#search') {
      const localValue = Utils.getLocalStorage('companySerPostData');
      if (localValue !== undefined && localValue !== null) {
        this.applySearch(localValue);
      } else {
        this.searchResults = [];
        this.appliedFilterData = {};
        this.searchResultCount = 0;
        this.setAppliedFilterPostData({});
      }
    } else {
      localStorage.removeItem('companySerPostData');
      this.searchResults = [];
      this.appliedFilterData = {};
      this.searchResultCount = 0;
      const freshValue = {
        dontDisplayOwnedCompanies: true,
        dontDisplayTeamCompanies: true,
      };
      // this.setAppliedFilterPostData(this.appliedFilterData);
      this.applySearch(freshValue);
    }
  }

  @action
  applyFilterValues = (state) => {
    this.appliedFilters = state.appliedFilters;
    this.resultStatusMsg = state.resultStatusMsg;
    this.appliedFilterPostData = state.appliedFilterPostData;
    this.appliedFilterSectionData = state.appliedFilterSectionData;
    this.appliedFilterData = state.appliedFilterData;
    this.searchResults = state.searchResults;
    this.searchResultCount = state.searchResultCount;
    this.searchCursorMark = state.searchCursorMark;
    this.checkBoxFilters = state.checkBoxFilters;
    this.uploadCsv = state.uploadCsv;
  };
}

const companySearchState = new CompanySearchState();

const companySearchPopupState = new CompanySearchState();

export { companySearchPopupState };

export default companySearchState;
