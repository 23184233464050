/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import TickIcon from 'Assets/svg/tick-icon.svg';
import Button from 'Components/common/base/Button';
import Utils from 'Utils/utils';
import './styles.scss';

const features = [
  { text: 'Email Credits', key: 'lbCredits', type: 'number' },
  { text: 'Enrichment Credits', key: 'enrichmentCredits', type: 'number' },
  { text: 'Phone Credits', key: 'phoneCredits', type: 'number' },
  {
    text: 'Daily Limit',
    key: 'fairUsageDailyLbCreditLimit',
    type: 'number',
    noValueText: 'No Limit',
  },
  { text: 'Chrome Extension', type: 'boolean', defaultValue: true },
  { text: 'Advanced Filters', type: 'boolean', defaultValue: true },
  { text: 'List Building', type: 'boolean', defaultValue: true },
  { text: 'Email Support', type: 'boolean', defaultValue: true },
  { text: 'CSV Export', type: 'boolean', key: 'csvExportEnabled' },
  { text: 'Saved Search', type: 'boolean', key: 'savedSearchEnabled' },
  { text: 'ABM List Building', type: 'boolean', key: 'inclusionListEnabled' },
  {
    text: 'Technology Sector',
    type: 'boolean',
    key: 'practiceAreaFilterEnabled',
  },
  { text: 'CRM Export', type: 'boolean', key: 'crmExportEnabled' },
  {
    text: 'Limit Contact per Company',
    type: 'boolean',
    key: 'limitPerCompanyEnabled',
  },
  {
    text: 'Basic Reporting',
    type: 'boolean',
    key: 'usageAnalyticsBasicEnabled',
  },
  { text: 'Suppression List', type: 'boolean', key: 'suppressionListEnabled' },
  { text: 'VC Funding', type: 'boolean', key: 'fundingFilterEnabled' },
  {
    text: 'Job Change Alerts',
    type: 'boolean',
    key: 'savedSearchJobChangeAlertEnabled',
  },
  {
    text: 'Department wise growth',
    type: 'boolean',
    key: 'growthFilterEnabled',
  },
  { text: 'Team Management', type: 'boolean', key: 'teamManagementEnabled' },
  {
    text: 'Advanced Reporting',
    type: 'boolean',
    key: 'usageAnalyticsAdvancedEnabled',
  },
  { text: 'API Access', type: 'boolean', key: 'apiEnabled' },
  {
    text: 'Priority Email Support',
    type: 'boolean',
  },
  {
    text: 'Dedicated Account Manager',
    type: 'boolean',
  },
  { text: 'Onboarding Support', type: 'boolean' },
  { text: 'Chat & Call Support', type: 'boolean' },
];

function PricingComparison(props) {
  const { pricingPlans, upgradeCallback } = props;
  const [planType, setPlanType] = useState('MONTHLY');
  const headerRef = useRef();
  const bodyRef = useRef();

  const handleScroll = (e) => {
    const scrollLeftValue = e.target.scrollLeft;
    headerRef.current.scrollLeft = scrollLeftValue;
    bodyRef.current.scrollLeft = scrollLeftValue;
  };

  const isMonthlySelected = planType === 'MONTHLY';

  return (
    <div className="pricing-comparison-wrapper">
      <h2>Comparison Table</h2>
      <div className="comparison-table">
        <div
          className="comparison-table-header"
          ref={headerRef}
          onScroll={handleScroll}
        >
          <div className="comparison-attributes">
            <span className="billed-text">Billed monthly or annually</span>
            <div className="switch-wrapper">
              <span>Monthly</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={planType === 'YEARLY'}
                  onChange={() => {
                    if (planType === 'YEARLY') {
                      setPlanType('MONTHLY');
                    } else {
                      setPlanType('YEARLY');
                    }
                  }}
                />
                <div className="slider" />
              </label>
              <span>Yearly</span>
              <span className="save-tag">Save 20%</span>
            </div>
          </div>
          {pricingPlans?.map((pricingData) => {
            const { name, price, annualPrice, id } = pricingData;
            const isEnterprisePlan = name === 'CUSTOM';
            const isFreePlan = name === 'FREE';

            return (
              <div className="plan-header" key={`plan-header-${id}`}>
                <div className="pricing-title">{name.toLowerCase()}</div>
                <div className="pricing-value-per">
                  {isEnterprisePlan ? (
                    <span className="contact-us">Contact Us</span>
                  ) : (
                    <>
                      <span className="price-value">
                        ${isMonthlySelected ? price : annualPrice}
                      </span>
                      <span className="price-per-text">
                        {isMonthlySelected ? '/month' : '/year'}
                      </span>
                    </>
                  )}
                </div>
                {!isFreePlan && (
                  <Button
                    variant="primary"
                    buttonProps={{
                      className: 'upgrade-now',
                      text: isEnterprisePlan ? 'Talk to Sales' : 'Upgrade Now',
                    }}
                    onClick={() => {
                      upgradeCallback(isEnterprisePlan, pricingData);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div className="comparison-body" ref={bodyRef} onScroll={handleScroll}>
          {features.map((featureProps) => {
            const {
              text,
              key,
              noValueText = 'Custom',
              defaultValue,
              type,
            } = featureProps;
            return (
              <div className="table-row" key={key || text}>
                <div className="attribute-name">{text}</div>
                {pricingPlans?.map((pricingData) => {
                  const isEnterprisePlan = pricingData.name === 'CUSTOM';
                  const value =
                    key === undefined ? isEnterprisePlan : pricingData[key];

                  return (
                    <div
                      key={`${key}-${pricingData.id}`}
                      className="comparison-value"
                    >
                      {type === 'number' ? (
                        <div className="number-value">
                          {isEnterprisePlan
                            ? noValueText
                            : (value ?? 0) === 0
                              ? ''
                              : [
                                    'lbCredits',
                                    'phoneCredits',
                                    'enrichmentCredits',
                                  ].includes(key)
                                ? Utils.numberFormat(
                                    isMonthlySelected ? value : value * 12,
                                  )
                                : Utils.numberFormat(value)}
                        </div>
                      ) : (
                        <div className="arrow-wrapper">
                          {(value || defaultValue) && <TickIcon />}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PricingComparison;
