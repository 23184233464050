import React, { useState, useEffect } from 'react';
import { observable, action } from 'mobx';
import DocumentIcon from 'Assets/svg/new_icons/document.svg';
import ControlInput from 'Components/common/base/ControlInput';
import { observer } from 'mobx-react';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import Input from 'Components/common/base/Input';
import { toasterState } from 'Components/common/base/Toaster';
import myLeadsState from 'Stores/myLeads';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

class ListNamePopupState {
  @observable accessor showCreateList = false;

  @observable accessor callBack = CONSTANTS.EMPTY_FUNCTION;

  @observable accessor actionMethod = 'create';

  @observable accessor defaultValue = '';

  @observable accessor defaultListId = '';

  @action
  setShowCreateList = (val) => {
    if (!val) {
      this.defaultValue = '';
      this.defaultListId = '';
    }
    this.showCreateList = val;
  };

  @action
  setActionMethod = (val) => {
    this.actionMethod = val;
  };

  @action
  setDefaultValue = (val) => {
    this.defaultValue = val;
  };

  @action
  setDefaultListId = (val) => {
    this.defaultListId = val;
  };
}

const listNamePopupState = new ListNamePopupState();

function ListNamePopup() {
  const [inputVal, setInputVal] = useState('');
  const [errorToolMsg, showErrorToolMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSharedList, setIsSharedList] = useState(false);

  const isCreateList = listNamePopupState.actionMethod === 'create';

  const changeInputVal = (value) => {
    if (value !== '') {
      showErrorToolMsg('');
    }
    setInputVal(value);
  };

  const closeListPopup = () => {
    setIsSharedList(false);
    listNamePopupState.setShowCreateList(false);
  };

  const createNewList = async () => {
    const inputValue = inputVal.trim();
    if (!isLoading) {
      if (inputValue !== '') {
        if (
          inputValue.toLowerCase() === 'plugin history' ||
          inputValue.toLowerCase() === 'all leads'
        ) {
          showErrorToolMsg('Please provide a different name');
        } else if (isCreateList) {
          setIsLoading(true);
          const response = await myLeadsState.createList({
            name: inputValue,
            isSharedList,
          });
          setIsLoading(false);
          if (
            response?.data?.result === 'Success' &&
            response?.data?.listId !== ''
          ) {
            listNamePopupState.callBack(
              response.data.id,
              inputValue,
              isSharedList,
            );
            Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_LIST_CREATED, {
              listName: inputValue,
              isSharedList,
            });
            closeListPopup();
          } else if (response.data?.responseCode === 'ORC_LIST_003') {
            showErrorToolMsg('List name already exist');
          }
        } else if (listNamePopupState.defaultValue === inputValue) {
          showErrorToolMsg('List name already exist');
          setInputVal(listNamePopupState.defaultValue);
        } else if (listNamePopupState.actionMethod === 'update') {
          setIsLoading(true);
          const renameCallback = (status = 'success') => {
            let str = '';
            if (status === 'success') {
              str = 'List name updated successfully';
            } else {
              str = 'Failed to update the list name';
            }

            toasterState.setToastMsg(
              str,
              status === 'success'
                ? CONSTANTS.TOAST_MSG_TYPES.SUCCESS
                : CONSTANTS.TOAST_MSG_TYPES.FAILURE,
            );
            setIsLoading(false);
            closeListPopup();
          };
          myLeadsState.renameLeadsList(
            listNamePopupState.defaultListId,
            inputValue,
            listNamePopupState.defaultValue,
            renameCallback,
          );
        } else {
          listNamePopupState.callBack(inputValue);
        }
      } else {
        showErrorToolMsg('Please enter a name');
        setInputVal('');
      }
    }
  };

  const submitAction = (e) => {
    if (e.charCode === 13) {
      createNewList();
    }
  };

  useEffect(() => {
    if (listNamePopupState.showCreateList) {
      if (listNamePopupState.actionMethod === 'update') {
        setInputVal(listNamePopupState.defaultValue);
      } else {
        setInputVal('');
      }
    }
  }, [listNamePopupState.showCreateList]);

  return (
    listNamePopupState.showCreateList && (
      <Modal show closeModal={closeListPopup} className="list-name-popup">
        <Modal.Icon SvgIcon={DocumentIcon} />
        <Modal.Title>
          {isCreateList ? 'Create New List' : 'Rename List'}
        </Modal.Title>

        <Modal.Content>
          <Input
            error={errorToolMsg}
            label="List Name"
            onChange={(value) => {
              changeInputVal(value);
            }}
            inputProps={{
              placeholder: 'Enter your new list name',
              value: inputVal,

              onKeyPress: submitAction,
            }}
          />
          {isCreateList && (
            <div className="checkbox-wrapper">
              <ControlInput
                type="checkbox"
                label="Share your list with the team"
                isChecked={isSharedList}
                onChange={setIsSharedList}
              />
            </div>
          )}
        </Modal.Content>

        <Modal.Footer>
          <Button
            variant="outline"
            onClick={closeListPopup}
            buttonProps={{ text: 'Cancel' }}
          />
          <Button
            buttonProps={{ text: isCreateList ? 'Create' : 'Update' }}
            isLoading={isLoading}
            onClick={createNewList}
          />
        </Modal.Footer>
      </Modal>
    )
  );
}

export { listNamePopupState };
export default observer(ListNamePopup);
