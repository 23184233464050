import React from 'react';
import EmptyCriteriaIcon from 'Assets/svg/new_icons/empty-criteria.svg';
import './styles.scss';

function EmptyCriteria(props) {
  const { text, className = '', imageURL } = props;

  return (
    <div className={`empty-result ${className}`.trimEnd()}>
      {imageURL ? (
        <img src={imageURL} alt="illustration" />
      ) : (
        <EmptyCriteriaIcon />
      )}
      <p>{text}</p>
    </div>
  );
}

export default EmptyCriteria;
