import React from 'react';
import FacebookIcon from 'Assets/svg/company-icon/facebook-logo.svg';
import TwitterIcon from 'Assets/svg/company-icon/twitter-new-logo.svg';
import YoutubeIcon from 'Assets/svg/company-icon/youtube-logo.svg';
import LinkedinIcon from 'Assets/svg/company-icon/linkedin-logo.svg';
import WebsiteIcon from 'Assets/svg/company-icon/link.svg';
import Utils from 'Utils/utils';
import './styles.scss';

function SocialLinks(props) {
  const { facebookId, twitterId, youtubeId, linkedInId, website } = props;

  const checkAndAddHttpProtocol = (value) => {
    if (value.includes('http://') || value.includes('https://')) {
      return value;
    }
    return `https://${value}`;
  };

  if (
    Utils.isNotUndefinedNotNull(website) ||
    Utils.isNotUndefinedNotNull(facebookId) ||
    Utils.isNotUndefinedNotNull(linkedInId) ||
    Utils.isNotUndefinedNotNull(youtubeId) ||
    Utils.isNotUndefinedNotNull(twitterId)
  ) {
    return (
      <div className="social-links">
        {Utils.isNotUndefinedNotNull(website) && (
          <a
            href={checkAndAddHttpProtocol(website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WebsiteIcon className="svg-icon website" />
          </a>
        )}
        {Utils.isNotUndefinedNotNull(facebookId) && (
          <a
            href={checkAndAddHttpProtocol(facebookId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className="svg-icon facebook" />
          </a>
        )}
        {Utils.isNotUndefinedNotNull(twitterId) && (
          <a
            href={checkAndAddHttpProtocol(twitterId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="svg-icon twitter" />
          </a>
        )}
        {Utils.isNotUndefinedNotNull(youtubeId) && (
          <a
            href={checkAndAddHttpProtocol(youtubeId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon className="svg-icon youtube" />
          </a>
        )}
        {Utils.isNotUndefinedNotNull(linkedInId) && (
          <a
            href={checkAndAddHttpProtocol(linkedInId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinIcon className="svg-icon linkedin" />
          </a>
        )}
      </div>
    );
  }
  return null;
}

export default SocialLinks;
