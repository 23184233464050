/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import Button from 'Components/common/base/Button';
import userDetail from 'Stores/userDetail';
import { monthNames } from 'Utils/constants';
import LeftArrowIcon from 'Assets/svg/left-arrow.svg';
import RightArrowIcon from 'Assets/usageAnalytics/right-arrow.svg';
import './styles.scss';

const defaultRangeValue = {
  startMonth: '',
  startYear: new Date().getFullYear(),
  endMonth: '',
  endYear: new Date().getFullYear(),
};

function MonthRangeDropdown(props) {
  const { onClose, defaultValue, onApply, maxRange } = props;
  const [currentFocus, setCurrentFocus] = useState(1);
  const [monthRange, setMonthRange] = useState(
    { popup: defaultValue, value: defaultValue } || {
      popup: defaultRangeValue,
      value: defaultRangeValue,
    },
  );
  const [year, setYear] = useState(monthRange.popup.startYear);

  const creationYear = useMemo(() => {
    return new Date(userDetail.userInfo.creationTimeStamp).getFullYear();
  }, [userDetail.userInfo.creationTimeStamp]);

  const creationMonth = useMemo(() => {
    return new Date(userDetail.userInfo.creationTimeStamp).getMonth();
  }, [userDetail.userInfo.creationTimeStamp]);

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();

  const currentMonth = currentDate.getMonth();

  const handleOnClickLeftArrow = (value) => {
    let data;
    if (value === false) {
      data = typeof year === 'string' ? Number(year) - 1 : year - 1;
    } else {
      data = typeof year === 'string' ? Number(year) + 1 : year + 1;
    }

    setYear(data);
  };

  return (
    <div className="month-range-wrapper">
      <div className="range-wrap">
        <div className="input-with-label">
          <label htmlFor="start-month">From</label>
          <input
            type="text"
            readOnly
            required
            id="start-month"
            placeholder="Choose month"
            className={currentFocus === 1 ? 'focus' : ''}
            onFocus={() => {
              setCurrentFocus(1);
              if (monthRange.popup.startYear) {
                setYear(Number(monthRange.popup.startYear));
              }
            }}
            value={
              monthRange.popup.startMonth && monthRange.popup.startYear
                ? `${monthRange.popup.startMonth.slice(0, 3)} ${
                    monthRange.popup.startYear
                  }`
                : ''
            }
          />
        </div>
        <div className="input-with-label">
          <label htmlFor="end-month">To</label>
          <input
            type="text"
            required
            readOnly
            id="end-month"
            placeholder="Choose month"
            className={currentFocus === 2 ? 'focus' : ''}
            onFocus={() => {
              setCurrentFocus(2);
              if (monthRange.popup.endYear) {
                setYear(Number(monthRange.popup.endYear));
              }
            }}
            value={
              monthRange.popup.endMonth && monthRange.popup.endYear
                ? `${monthRange.popup.endMonth.slice(0, 3)} ${
                    monthRange.popup.endYear
                  }`
                : ''
            }
          />
        </div>
        <div className="note">
          <i>{`You can select maximum of ${maxRange} months only`}</i>
        </div>
      </div>
      <div className="calendar-header">
        <LeftArrowIcon
          onClick={() => {
            if (Number(year) !== Number(creationYear)) {
              handleOnClickLeftArrow(false);
            }
          }}
          className={Number(year) === Number(creationYear) ? 'disabled' : ''}
          aria-hidden="true"
        />
        <span className="custom-year"> {year} </span>
        <RightArrowIcon
          onClick={() => {
            if (Number(year) !== Number(currentYear)) {
              handleOnClickLeftArrow(true);
            }
          }}
          className={Number(year) === Number(currentYear) ? 'disabled' : ''}
          aria-hidden="true"
        />
      </div>
      <div className="month-wrapper">
        {monthNames.map((monthName, index) => {
          let isDisabled = false;
          const iterationDate = new Date(year, index);
          // for start date
          if (currentFocus === 1) {
            if (
              iterationDate < new Date(creationYear, creationMonth) ||
              iterationDate > new Date(currentYear, currentMonth)
            ) {
              isDisabled = true;
            }
          } else {
            // https://stackoverflow.com/a/2536445/10288733
            let diffInMonths = 0;
            const selectedStartDate = new Date(
              monthRange.popup.startYear,
              monthNames.indexOf(monthRange.popup.startMonth),
            );
            diffInMonths =
              (iterationDate.getFullYear() - selectedStartDate.getFullYear()) *
              12;
            diffInMonths -= selectedStartDate.getMonth();
            diffInMonths += iterationDate.getMonth();

            if (
              (monthRange.popup.startMonth &&
                (iterationDate < selectedStartDate ||
                  iterationDate > new Date(currentYear, currentMonth))) ||
              diffInMonths + 1 > maxRange
            ) {
              isDisabled = true;
            }
          }

          return (
            <div
              key={monthName}
              className={`${
                (currentFocus === 1 &&
                  monthName === monthRange.popup.startMonth &&
                  Number(year) === Number(monthRange.popup.startYear)) ||
                (currentFocus === 2 &&
                  monthName === monthRange.popup.endMonth &&
                  Number(year) === Number(monthRange.popup.endYear))
                  ? 'active'
                  : ''
              }${isDisabled ? ' disabled' : ''}`}
              onClick={() => {
                if (!isDisabled) {
                  setMonthRange({
                    ...monthRange,
                    popup: {
                      ...monthRange.popup,
                      [currentFocus === 1 ? 'startMonth' : 'endMonth']:
                        monthName,
                      [currentFocus === 1 ? 'startYear' : 'endYear']: year,
                      ...(currentFocus === 1 && {
                        endMonth: '',
                        endYear: year,
                      }),
                    },
                  });
                }
                setCurrentFocus(currentFocus === 1 ? 2 : 1);
              }}
            >
              {monthName.slice(0, 3)}
            </div>
          );
        })}
      </div>
      <div className="button-wrapper">
        <Button
          variant="outline"
          buttonProps={{ text: 'Cancel' }}
          onClick={(e) => {
            setMonthRange({ ...monthRange, popup: monthRange.value });
            e.stopPropagation();
            onClose();
          }}
        />
        <Button
          buttonProps={{
            text: 'Apply',
            disabled:
              !monthRange.popup.startMonth || !monthRange.popup.endMonth,
          }}
          onClick={(e) => {
            setMonthRange({ ...monthRange, value: monthRange.popup });
            onApply(monthRange.popup);
            e.stopPropagation();
            onClose();
          }}
        />
      </div>
    </div>
  );
}

export default observer(MonthRangeDropdown);
