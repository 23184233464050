import React from 'react';
import { observer } from 'mobx-react';
import ArchivedIcon from 'Assets/svg/archive-icon.svg';
import ControlInput from 'Components/common/base/ControlInput';
import ContactName from 'Components/common/ContactList/ContactName';
import ContactIndustry from 'Components/common/ContactList/ContactIndustry';
import ContactCompany from 'Components/common/ContactList/ContactCompany';
import ContactEmailPhone from 'Components/common/ContactList/ContactEmailPhone';
import CompanySector from 'Components/common/ContactList/CompanySector';
import CompanyFundingDetails from 'Components/common/ContactList/CompanyFundingDetails';
import myLeadsState from 'Stores/myLeads';
import ProcessingMessage from '../processingMessage';
import './styles.scss';
import EmailValidationStatus from './EmailValidationStatus';

function MyLeadsListLoadingPlaceHolder() {
  return (
    <div className="my-contact-list-wrap list-placeholder">
      {Array(15)
        .fill()
        .map((item, index) => {
          return (
            <div
              className="my-contact-item-wrapper"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className="my-contact-item">
                <div className="check-box-wrap">
                  <div className="control-input linear-background" />
                </div>
                <div className="contact-name-wrapper">
                  <div className="name linear-background" />
                  <div className="title linear-background" />
                  <div className="location-wrapper linear-background" />
                </div>
                <div className="contact-info">
                  <div className="contact-email">
                    <div className="info-icon linear-background" />
                    <div className="contact-email-copy-wrap">
                      <div className="info-detail linear-background" />
                    </div>
                  </div>
                  <div className="email-validation-status">
                    <div className="validation-status" />
                    <div className="validation-date" />
                  </div>
                  <div className="contact-phone">
                    <div className="phone-wrapper">
                      <div className="phone-icon linear-background" />
                      <div className="phone-value linear-background" />
                    </div>
                  </div>
                </div>
                <div className="company-name-wrapper">
                  <div className="text-section">
                    <div className="company-name linear-background" />
                    <div className="location-wrapper linear-background"> </div>
                  </div>
                </div>
                <div className="industry-wrapper">
                  <div className="info-wrap linear-background" />
                  <div className="industry-content-info linear-background" />
                  <div className="company-number linear-background" />
                </div>
                <div className="tech-sector-list">
                  <div className="inner-wrapper">
                    <div className="sector linear-background" />
                    <div className="sector linear-background" />
                  </div>
                </div>
                <div className="company-funding-details">
                  <p className="linear-background" />
                  <p className="linear-background last-funding" />
                  <p className="linear-background" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function Header() {
  return (
    <div className="list-header">
      <div className="check-box-wrap">&nbsp;</div>
      <div className="contact-name-wrapper">Name</div>
      <div className="contact-info">Contact</div>
      <div className="validation-status">ZeroBounce Status</div>
      <div>Company</div>
      <div>Company Details</div>
      <div>Sector</div>
      <div>Funding Details</div>
    </div>
  );
}

function ContactList(props) {
  const { isListLoading = false, currentListInfo = {} } = props;
  const { archivedCount = 0 } = currentListInfo;

  const contactSelection = (index) => {
    myLeadsState.updateSelectedBulkContactIds(index);
  };

  const listView = myLeadsState.modifiedSearchResults.map((item, index) => {
    const { id, checked } = item;

    return (
      <div className="my-contact-item-wrapper" key={id}>
        <div className="my-contact-item">
          <div className="check-box-wrap">
            <ControlInput
              type="checkbox"
              label=""
              checked={checked}
              onChange={() => {
                contactSelection(index);
              }}
            />
          </div>
          <ContactName
            name={`${item.firstName}${
              item.lastName !== null && item.lastName !== undefined
                ? ` ${item.lastName}`
                : ''
            }`}
            title={item.title}
            facebookId={item.facebookURL}
            twitterId={item.twitterURL}
            linkedInId={item.linkedinURL}
            cname={item.companyName}
            contactCity={item.address?.city}
            contactState={item.address?.state}
            contactCountry={item.address?.country}
            companyCity={item.company?.city}
            companyState={item.company?.state}
            companyCountry={item.company?.country}
            contactId={item.id}
            email={item.email}
            phoneNumberFromVendor={item.phoneNumberFromVendor}
            owned
            status={item.status}
            showFindEmail={!!item.email}
          />
          <ContactEmailPhone
            contactId={item.id}
            email={item.email}
            phoneNumberFromVendor={item.phoneNumberFromVendor || []}
            status={item.status}
            isDownloaded
            showFindEmail={!!item.email}
            pageName="myleads"
          />
          <EmailValidationStatus
            email={item.email}
            zbStatus={item.emailValidationStatus}
            validationDate={item.lastEmailValidatedDate}
          />
          <ContactCompany
            cname={item.company?.name}
            companyCountry={item.company?.country}
            companyState={item.company?.state}
            companyCity={item.company?.city}
            companyId={item.company?.id}
            companyLogo={item.company?.logoUrl}
            company={item?.company || {}}
          />
          <ContactIndustry
            contactId={item.id}
            industry={item.company?.industry}
            employeeCount={item.company?.headCount}
            revenue={item.company?.revenue}
            companyPhone={item.company?.phone?.[0]?.number}
          />
          <CompanySector paKeywords={item.company?.paKeywords} />
          <CompanyFundingDetails
            lastFundingAmount={item.company?.lastFundingAmount}
            lastFundingDate={item.company?.lastFundingDate}
            lastFundingType={item.company?.lastFundingType}
            totalFundingAmount={item.company?.totalFundingAmount}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="my-list-content">
      <div className="my-contact-list">
        <Header />
        {isListLoading ? (
          <MyLeadsListLoadingPlaceHolder />
        ) : (
          <>
            <div className="my-contact-list-wrap">
              {myLeadsState.isPurchaseInProgress && <ProcessingMessage />}
              {listView}
            </div>
            {archivedCount > 0 && (
              <div className="archived-contacts">
                <div className="archived-icon-wrapper">
                  <ArchivedIcon />
                </div>
                <div className="archived-contacts-content">
                  <span className="archived-content-first">
                    We have archived the older contacts in your list.&nbsp;
                  </span>
                  <span className="archived-content-second">
                    Please write to{' '}
                    <a href="mailto:support@adapt.io">support@adapt.io</a>, if
                    you want to access the archived contacts
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(ContactList);
