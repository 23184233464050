import React from 'react';
import logo from 'Assets/logo/logo_new.png';
import MaintenanceBanner from 'Assets/png/maintenance-banner.png';
import './styles.scss';

function MaintenancePage() {
  return (
    <div className="maintenance-page">
      <div className="gradient-bg">
        <div className="blue-spread" />
        <div className="pink-spread" />
      </div>
      <img src={logo} className="app-logo" alt="logo" />
      <p className="title">We Will Be Back Soon</p>
      <p className="description">
        We are upgrading our systems and will be back live around 8pm PST.
      </p>
      <p className="description">
        We apologize for the inconvenience and appreciate your patience.
      </p>
      <img
        className="maintenance-banner"
        src={MaintenanceBanner}
        alt="maintenance banner"
      />
    </div>
  );
}

export default MaintenancePage;
