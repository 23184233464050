import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import logo from 'Assets/logo/logo_new.png';
import DashboardIcon from 'Assets/svg/dashboard.svg';
import SearchIcon from 'Assets/svg/header-icons/search.svg';
import ListIcon from 'Assets/svg/header-icons/list.svg';
import RefreshIcon from 'Assets/svg/refresh.svg';
import IntegrationsIcon from 'Assets/svg/header-icons/integrations.svg';
import WorkflowIcon from 'Assets/svg/workflow/workflow.svg';
import Utils from 'Utils/utils';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';

const sideBarItemKeys = {
  dashboard: 'dashboard',
  advancedSearch: 'advancedSearch',
  myleads: 'myleads',
  enrichment: 'enrichment',
  integrations: 'integrations',
  workflow: 'workflow',
};

const sideNavItems = [
  {
    path: '/dashboard',
    mainPath: '/dashboard',
    key: sideBarItemKeys.dashboard,
    title: 'Dashboard',
    Icon: DashboardIcon,
  },
  {
    path: '/advanced-search/contact#search',
    mainPath: '/advanced-search/',
    key: sideBarItemKeys.advancedSearch,
    title: 'Prospect Search',
    Icon: SearchIcon,
  },
  {
    path: '/my-leads',
    mainPath: '/my-leads',
    key: sideBarItemKeys.myleads,
    title: 'My Lists',
    Icon: ListIcon,
  },
  {
    path: '/enrichment',
    mainPath: '/enrichment',
    key: sideBarItemKeys.enrichment,
    title: 'Enrichment',
    Icon: RefreshIcon,
  },
  {
    path: '/integrations',
    mainPath: '/integrations',
    key: sideBarItemKeys.integrations,
    title: 'Integrations',
    Icon: IntegrationsIcon,
  },
  {
    path: '/workflow',
    mainPath: '/workflow',
    key: sideBarItemKeys.workflow,
    title: 'Agents',
    Icon: WorkflowIcon,
  },
];

const checkIsNavLinkAllowed = (key) => {
  if (key === sideBarItemKeys.workflow) {
    return featureFlagsAndPreferencesState.isWorkflowEnabled();
  }
  return true;
};

function NavigationWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const redirectToPath = (e) => {
    if (e) {
      e.preventDefault();
    }

    const redirectPath = e.currentTarget.getAttribute('data-path');

    if (pathname !== redirectPath) {
      Utils.enableBodyScroll();
      navigate(redirectPath);
    }
  };

  return (
    <div className="nav-wrapper-left">
      <Link
        onClick={(e) => {
          redirectToPath(e, '');
        }}
        className="logo-wrapper"
        data-path="/"
        to="/"
      >
        <img src={logo} className="app-logo" alt="logo" />
      </Link>
      <div className="links-wrapper">
        <ul>
          {sideNavItems.map((item) => {
            const { key, mainPath, path, title, Icon } = item;
            return (
              checkIsNavLinkAllowed(key) && (
                <li
                  key={`link${key}`}
                  className={`${key}${
                    pathname.includes(mainPath) ? ' selected-path' : ''
                  }`}
                >
                  <Link
                    onClick={(e) => {
                      redirectToPath(e);
                    }}
                    data-path={path}
                    to={path}
                  >
                    {Icon && <Icon />}
                    <span>{title}</span>
                  </Link>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default observer(NavigationWrapper);
