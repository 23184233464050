/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import TickIcon from 'Assets/svg/tick-outline2.svg';
import UpgradeIcon from 'Assets/svg/header-icons/upgrade.svg';
import CloseIcon from 'Assets/svg/close-circle.svg';
import RocketIcon from 'Assets/svg/rocket.svg';
import PlansIcon from 'Assets/svg/plans.svg';
import Utils from 'Utils/utils';
import { MX_COMMON_EVENTS } from 'Utils/constants';
import Button from '../base/Button';
import Modal from '../base/Modal';
import './styles.scss';

const AVAILABLE_FROM_STARTER = {
  free: false,
  starter: true,
  growth: true,
  enterprise: true,
};

const AVAILABLE_FROM_GROWTH = {
  free: false,
  starter: false,
  growth: true,
  enterprise: true,
};

const AVAILABLE_ONLY_FOR_CUSTOM_PLANS = {
  free: false,
  starter: false,
  growth: false,
  enterprise: true,
};

const UPGRADE_POPUP_CASES = {
  VIEW_CONTACT_NO_CREDIT: {
    mxSource: 'no_credits',
  },
  CSV_EXPORT: {
    featureListMap: AVAILABLE_FROM_STARTER,
    mxSource: 'csv_export',
  },
  CRM_EXPORT: {
    featureListMap: AVAILABLE_FROM_GROWTH,
    mxSource: 'crm_export',
  },
  PURCHASE_PHONE: {
    featureListMap: AVAILABLE_FROM_GROWTH,
    mxSource: 'purchase_phone',
  },
  MANAGE_TEAM: {
    featureListMap: AVAILABLE_ONLY_FOR_CUSTOM_PLANS,
    mxSource: 'manage_team',
  },
  SAVED_SEARCH: {
    featureListMap: AVAILABLE_FROM_STARTER,
    mxSource: 'saved_search',
  },
  ABM_LIST_BUILDING: {
    featureListMap: AVAILABLE_FROM_STARTER,
    mxSource: 'abm_list_building',
  },
  ADVANCED_REPORTING: {
    featureListMap: AVAILABLE_ONLY_FOR_CUSTOM_PLANS,
    mxSource: 'advanced_reporting',
  },
  CRM_ENRICHMENT: {
    featureListMap: AVAILABLE_FROM_GROWTH,
    mxSource: 'crm_enrichment',
  },
};

class CommonUpgradePopupState {
  @observable accessor showUpgradePopup = null;

  defaultPopupValue = {
    callback: null,
  };

  @observable accessor popupValues = this.defaultPopupValue;

  @action
  setShowUpgradePopup = (value) => {
    this.showUpgradePopup = value;
  };

  @action
  setPopupValues = (data) => {
    this.popupValues = {
      callback: data.callback || null,
    };
  };
}

const commonUpgradePopupState = new CommonUpgradePopupState();

function CommonUpgradePopup() {
  const { showUpgradePopup, popupValues } = commonUpgradePopupState;
  const { callback = null } = popupValues;

  const { featureListMap = {}, mxSource } = showUpgradePopup
    ? UPGRADE_POPUP_CASES[showUpgradePopup]
    : {};

  const closeClick = () => {
    commonUpgradePopupState.setShowUpgradePopup(null);
  };

  const actionCallback = () => {
    if (callback) {
      callback();
    } else {
      Utils.mixpanelTrack(MX_COMMON_EVENTS.LB_UPGRADE_CLICK, {
        Source: mxSource,
      });
      Utils.redirectToLBPricingPage();
      commonUpgradePopupState.setShowUpgradePopup(null);
    }
  };

  const featureList = Object.keys(featureListMap);
  return commonUpgradePopupState.showUpgradePopup !== null ? (
    <Modal
      align="center"
      show
      closeModal={closeClick}
      className="common-upgrade-popup"
    >
      <Modal.Icon SvgIcon={RocketIcon} />
      {/* {title && message && <Modal.Title>{title}</Modal.Title>} */}
      <Modal.Content>
        <p className="message">
          Sorry, your current plan doesn't support this feature Upgrade now to
          unlock this feature.
        </p>
        {featureList.length > 0 && (
          <div className="feature-availability">
            <div className="feature-heading">Feature available in</div>
            <div className="feature-list">
              {featureList.map((item) => {
                return (
                  <div className="feature-item" key={item}>
                    {featureListMap[item] ? (
                      <TickIcon className="svg-icon tick" />
                    ) : (
                      <CloseIcon className="svg-icon close" />
                    )}
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button
          Icon={UpgradeIcon}
          buttonProps={{ text: 'Upgrade Now' }}
          onClick={actionCallback}
        />
        <p>
          <PlansIcon />
          Plans start from $49 month
        </p>
      </Modal.Footer>
    </Modal>
  ) : null;
}

export { commonUpgradePopupState };

export default observer(CommonUpgradePopup);
