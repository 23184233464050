import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EMPTY_FUNCTION } from 'Utils/constants';

const defaultValue = { page: 1, size: 50 };

const getNewSearchParams = (location, newValue) => {
  const searchParams = location.search
    .substring(1)
    .split('&')
    .reduce((result, value) => {
      const parts = value.split('=');
      if (parts[0]) {
        return {
          ...result,
          [decodeURIComponent(parts[0])]: decodeURIComponent(parts[1]),
        };
      }
      return result;
    }, {});
  const newSearch = Object.entries({
    ...searchParams,
    ...newValue,
  }).reduce((output, input) => {
    const [key, value] = input;
    return `${output}${output ? '&' : ''}${key}=${value}`;
  }, '');
  return newSearch;
};

function usePagination(options = {}) {
  const {
    callback = EMPTY_FUNCTION,
    initialValue = defaultValue,
    modifyHistory = false,
  } = options;
  const [pagination, setPagination] = useState(initialValue);
  const navigate = useNavigate();
  const location = useLocation();

  const changePage = (page) => {
    const newValue = { ...pagination, page: page + 1 };
    setPagination(newValue);
    if (modifyHistory) {
      navigate(
        `${location.pathname}?${getNewSearchParams(location, newValue)}`,
      );
    }
    callback(newValue);
  };

  const changePageSize = (size) => {
    const newValue = { size, page: 1 };
    setPagination(newValue);
    if (modifyHistory) {
      navigate(
        `${location.pathname}?${getNewSearchParams(location, newValue)}`,
      );
    }
    callback(newValue);
  };

  return {
    pagination,
    changePage,
    changePageSize,
    setPagination,
  };
}

export default usePagination;
