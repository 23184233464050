import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import UploadIcon from 'Assets/svg/new_icons/upload.svg';
import LoadingWrapper from 'Components/common/base/Loader';
import Pagination from 'Components/common/pagination';
import Modal from 'Components/common/base/Modal';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import Title from 'Components/common/base/Title';
import PageContentHeader from 'Components/common/PageContentHeader';
import enrichmentState, {
  adaptFieldsForCSVEnrichment,
} from 'Stores/enrichment';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { MX_ENRICHMENT_EVENTS } from 'Utils/constants';
import CsvEnrichmentInitiatedPopup from './CsvEnrichmentInitiatedPopup';
import CsvFieldMappingPopup from './CsvFieldMappingPopup';
import EnrichedList from './EnrichedList';
import UploadFile from './UploadFile';
import './styles.scss';

function FileEnrichment() {
  const [showFieldMappingPopup, togglePopup] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    page: Number(searchParams?.get('page')) || 1,
    limit: 10,
  });
  const [isListLoading, setIsListLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const processFile = (file, type) => {
    if (userDetail.userInfo.enrichmentCreditsRemaining > 0) {
      if (file) {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = async (e) => {
          const { result } = e.target;
          if (result.split('\n').length <= 25000 + 1) {
            Utils.mixpanelTrack(
              MX_ENRICHMENT_EVENTS.FILE_ENRICHMENT_FILE_UPLOAD,
              {
                type,
              },
            );
            const csvHeaderNames = result
              .split('\n')?.[0]
              ?.split(',')
              ?.map((data) => {
                return data.trim();
              });
            if (csvHeaderNames?.length > 0) {
              enrichmentState.fileEnrichmentCSVFields = csvHeaderNames;
              const twoDarrayOfData = result
                .split('\n')
                ?.splice(1)
                ?.map((data) => {
                  return data.split(',');
                });
              const sampleData = csvHeaderNames.reduce((output, input) => {
                const index = csvHeaderNames.findIndex((label) => {
                  return (
                    label?.toLowerCase()?.replace(/\s/g, '') ===
                    input.toLowerCase()?.replace(/\s/g, '')
                  );
                });
                const sampleValue = twoDarrayOfData.find((data) => {
                  return !!data[index];
                })?.[index];
                return { ...output, [input]: sampleValue };
              }, {});

              enrichmentState.fileEnrichmentSampleData = sampleData;
              enrichmentState.setFileEnrichmentFieldMapping(
                adaptFieldsForCSVEnrichment.map((data) => {
                  const csvLabel = csvHeaderNames.find((label) => {
                    return (
                      label?.toLowerCase()?.replace(/\s/g, '') ===
                      data.label.toLowerCase()?.replace(/\s/g, '')
                    );
                  });
                  return {
                    adaptKey: data.value,
                    adaptLabel: data.label,
                    csvLabel,
                    sampleData: sampleData[csvLabel],
                  };
                }),
              );
            }
            const formData = new FormData();
            formData.append('csvFile', file);
            setShowLoader(true);
            const isSuccess = await enrichmentState.uploadCSV({
              postBody: formData,
            });
            setShowLoader(false);
            if (isSuccess) {
              togglePopup(true);
            } else {
              Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.ERROR_POPUP_SHOWN, {
                type: 'Upload Generic Error',
              });
              Utils.showRefreshAndContactSupportPopup();
            }
          } else {
            Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.ERROR_POPUP_SHOWN, {
              type: 'Limit Reached',
            });
            commonModalState.setShowModal(true, {
              title: 'File Limit Exceeded',
              Icon: WarningIcon,
              description:
                'Please select a file that has 25,000 or less records and try again.',
              primaryButtonText: 'Ok',
            });
          }
        };
      }
    } else {
      Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.ERROR_POPUP_SHOWN, {
        type: 'No Credits',
      });
      commonModalState.setShowModal(true, {
        title: 'No Enrichment Credits left!',
        Icon: WarningIcon,
        description: (
          <>
            You ran out of enrichment credits and kindly write to{' '}
            <a href={`mailto:${Utils.getSupportEmail()}`}>
              {Utils.getSupportEmail()}
            </a>{' '}
            for more credits
          </>
        ),
        primaryButtonText: 'Ok',
      });
    }
  };

  const onFileUpload = (event, type) => {
    event.persist();
    const file = event.target.files?.[0];
    processFile(file, type);
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const uploadNewFileClick = () => {
    setShowUploadPopup(true);
  };

  const getFileEnrichmentList = async () => {
    setIsListLoading(true);
    await enrichmentState.getFileEnrichmentList(pagination);
    setIsListLoading(false);
    setIsInitialLoad(false);
  };

  const changePage = (page) => {
    const newValues = { limit: pagination.limit, page: page + 1 };
    setPagination(newValues);
    setSearchParams(newValues);
  };

  const changePageLimit = (limit) => {
    const newValues = { page: 1, limit };
    setPagination(newValues);
    setSearchParams(newValues);
  };

  useEffect(() => {
    getFileEnrichmentList();
  }, [pagination]);

  useEffect(() => {
    Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.FILE_ENRICHMENT_VIEWED);
  }, []);

  return (
    <>
    <Title title="File Enrichment" />
      <PageContentHeader
        titleData={{ text: 'CSV Enrichment', href: '/enrichment' }}
        buttonData={{
          text: 'Upload file',
          Icon: UploadIcon,
          callback: uploadNewFileClick,
        }}
      />
      <div className="file-enrichment-page-content">
        <div
          className={`file-enrichment-view${
            enrichmentState.fileEnrichmentListCount === 0 ||
            (isListLoading && isInitialLoad)
              ? ' empty-state'
              : ''
          }`}
        >
          {enrichmentState.fileEnrichmentListCount === 0 && (
            <UploadFile onFileUpload={onFileUpload} processFile={processFile} />
          )}
          {(enrichmentState.fileEnrichmentList.length > 0 || isListLoading) && (
            <EnrichedList
              isListLoading={isListLoading}
            />
          )}
        </div>
        <Pagination
          totalCount={enrichmentState.fileEnrichmentListCount}
          rowsPerPage={pagination.limit}
          currentPage={pagination.page}
          updateCurrentPage={changePage}
          onRowsPerPageUpdate={changePageLimit}
          rowsPerPageValues={[10, 25, 50, 100].map((data) => {
            return { label: data.toString(), value: data };
          })}
          showRowsPerPage
        />
      </div>
      {showFieldMappingPopup && (
        <CsvFieldMappingPopup
          pagination={pagination}
          togglePopup={() => {
            return togglePopup(false);
          }}
        />
      )}
      {enrichmentState.showFileEnrichmentInitiatedPopup && (
        <CsvEnrichmentInitiatedPopup
          togglePopup={() => {
            enrichmentState.showFileEnrichmentInitiatedPopup = false;
          }}
        />
      )}
      {showUploadPopup && (
        <Modal
          show
          closeModal={() => {
            return setShowUploadPopup(false);
          }}
          className="upload-file-popup"
        >
          <Modal.Content>
            <UploadFile
              setShowUploadPopup={setShowUploadPopup}
              onFileUpload={onFileUpload}
              processFile={processFile}
            />
          </Modal.Content>
        </Modal>
      )}
      <LoadingWrapper
        show={showLoader || (isListLoading && isInitialLoad)}
        position="fixed"
      />
    </>
  );
}

export default observer(FileEnrichment);
