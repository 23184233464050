import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import G2ReviewImg from 'Assets/png/g2-review.png';
import G2Badge from 'Assets/png/users-love-us.png';
import userDetail from 'Stores/userDetail';
import profileState from 'Stores/profile';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import Modal from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

function G2ReviewPopup() {
  const closeModal = () => {
    userDetail.setShowG2Popup(false);
  };

  const reviewClicked = () => {
    const previousData = Utils.getLocalStorage('g2');
    const { lastShown, count } = previousData;
    Utils.setLocalStorage('g2', {
      lastShown,
      count,
      reviewed: true,
    });
    userDetail.setShowG2Popup(false);
    profileState.updateUserActions({ g2Reviewed: true });
    Utils.mixpanelTrack(CONSTANTS.G2_EVENTS.REVIEW_CLICKED);
  };

  const callback = useMemo(() => {
    return (event) => {
      if (event.target.id === 'g2-write-a-review-button') {
        reviewClicked();
      }
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const documentBody = document.body;
    if (userDetail.showG2Popup) {
      const previousData = Utils.getLocalStorage('g2');
      const { count } = previousData;
      Utils.mixpanelTrack(CONSTANTS.G2_EVENTS.G2_POPUP_SHOWN, {
        timesShown: count,
      });
      const script = document.createElement('script');
      script.innerHTML =
        "(function(w,d,s,u,o,e,f) { w['G2WriteAReview'] = o; w[o] = w[o] || function() {(w[o].q = w[o].q || []).push(arguments)}; e = d.createElement(s); f = d.getElementsByTagName(s)[0]; e.async = 1;e.src = u; e.defer = 1;f.parentNode.insertBefore(e, f) })(window,document,'script','https://www.g2.com/assets/write_a_review_entry.js','g2'); g2('write-a-review', { productSlug: 'adapt-adapt', host: \"https://www.g2.com\", element: document.getElementById('g2-write-a-review-container'), buttonText: \"Write a review\", buttonColor: \"#6047d9\", buttonHoverColor: \"#4329c4\", buttonTextColor: \"#fff\" });";
      documentBody.appendChild(script);
      documentBody.addEventListener('click', callback);
      return () => {
        documentBody.removeEventListener('click', callback);
      };
    }
  }, [userDetail.showG2Popup]);

  return (
    <Modal
      closeModalOnOutsideClick={false}
      show={userDetail.showG2Popup}
      closeModal={closeModal}
      className="g2-review-popup"
    >
      <div className="top-section">
        <div className="image-section">
          <img src={G2Badge} alt="g2 badge" />
        </div>
        <div>
          <h2 className="title">We'd love your feedback!</h2>
          <p>
            Thanks for being a valued user of Adapt! Since you've stuck around,
            we'd love to hear your feedback on how we're doing. It would mean a
            lot to us if you could review your Adapt journey so far.
          </p>
          <div className="button-group">
            <div id="g2-write-a-review-container" />
            <Button
              buttonProps={{ text: 'Not now', className: 'not-now' }}
              variant="transparent"
              onClick={() => {
                userDetail.setShowG2Popup(false);
                Utils.mixpanelTrack(CONSTANTS.G2_EVENTS.REVIEW_SKIPPED);
              }}
            />
          </div>
        </div>
      </div>
      <div className="g2-middle-section">
        <p className="title bold">Why write a review on G2?</p>
        <p>
          <span className="bold">Help your peers.</span> Your peers go to G2 to
          research on Sales and Marketing Account Intelligence Software
          solutions. Adding your perspective on Adapt will help others pick the
          right solution based on real user experience.
        </p>
        <p>
          <span className="bold">Earn recognition.</span> Your detailed,
          balanced reviews of the solutions that you know inside and out can
          position you as an expert.
        </p>
      </div>
      <div className="g2-bottom-section">
        <img src={G2ReviewImg} alt="g2 rating" />
      </div>
    </Modal>
  );
}

export default observer(G2ReviewPopup);
