import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Modal from 'Components/common/base/Modal';
import userDetail from 'Stores/userDetail';
import filterState from 'Pages/prospectSearch/filterSection/state';
import Utils from 'Utils/utils';
import { MX_DASHBOARD_PAGE_EVENTS } from 'Utils/constants';
import useCases from '../fixtures';
import './styles.scss';

function UseCaseModal(props) {
  const { closeModal } = props;

  const clickLink = (filterItem, title) => {
    Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.USE_CASE_CLICKED, {
      text: title,
      type: 'popup',
    });
    closeModal();
    if (filterItem !== undefined) {
      setTimeout(() => {
        filterState.setSelectedFilter(filterItem);
      }, 300);
    }
  };

  useEffect(() => {
    Utils.setLocalStorage('use_pop_shown', 'true');
  }, []);

  const { firstName, lastName } = userDetail.userInfo;

  const isPopupAlreadyShown = Utils.getLocalStorage('use_pop_shown') !== null;

  return (
    <Modal show className="use-case-modal" closeModal={closeModal}>
      {Utils.isFreeTrialPlan() && !isPopupAlreadyShown ? (
        <>
          <div className="welcome-title">
            Hey {firstName || ''} {lastName || ''}, Welcome aboard! 🎉
          </div>
          <div className="message">
            Grow your business with Adapt. Here are some use cases to help you
            get started.
          </div>
        </>
      ) : (
        <div className="welcome-title">What are you looking for today?</div>
      )}
      <div className="list">
        {useCases.map((useCase) => {
          const {
            title,
            description,
            Icon,
            link,
            internalLink,
            linkText,
            filterItem,
          } = useCase;
          return (
            <div className="use-case-card" key={title}>
              <Icon className="use-case-icon" />
              <div className="text-wrap">
                <p className="title">{title}</p>
                <p className="description">{description}</p>
              </div>
              {link ? (
                <a
                  className="link button outline"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    clickLink(filterItem, title);
                  }}
                >
                  {linkText}
                </a>
              ) : (
                <Link
                  className="link button outline"
                  to={internalLink}
                  onClick={() => {
                    clickLink(filterItem, title);
                  }}
                >
                  {linkText}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default observer(UseCaseModal);
