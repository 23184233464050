import React from 'react';
import BookmarkError from 'Assets/svg/new_icons/bookmark-error.svg';
import Utils from 'Utils/utils';
import { MX_LB_EXPORT_EVENTS } from 'Utils/constants';
import { commonModalState } from '../base/Modal/CommonModal';

function showAssignOwnersPopup(data) {
  const {
    assignOwnerShipCallback,
    doNotAssignOwnerShipCallback,
    exportType,
    saveType,
  } = data;

  commonModalState.setShowModal(true, {
    title: `Export ${saveType || ''} to ${exportType} `,
    description: `Do you want to assign owners to these ${exportType.toLowerCase() === 'hubspot' ? 'companies' : 'accounts?'}`,
    primaryButtonText: 'Yes',
    buttonCallback: assignOwnerShipCallback,
    secondaryButtonText: 'No, Continue to Export',
    secondaryButtonCallback: doNotAssignOwnerShipCallback,
  });
}

function showExportFailureLimitPopup(limit) {
  commonModalState.setShowModal(true, {
    Icon: BookmarkError,
    title: 'Limit Exceeded',
    description: `You can export a maximum of ${limit} companies to your Salesforce CRM in a single transaction.`,
    primaryButtonText: 'OK',
    modalProps: { align: 'center' },
  });
}

function DescriptionForExport(props) {
  const { result, customMsg, exportType } = props;

  if (customMsg !== undefined && customMsg !== '') {
    return customMsg;
  }

  const { exportSuccessCount, exportFailedCount, duplicateCompanyCount } =
    result || '';

  const successMsg =
    exportSuccessCount && exportSuccessCount > 1
      ? `${exportSuccessCount} Companies`
      : `${exportSuccessCount} Company`;
  const failedMsg =
    exportFailedCount && exportFailedCount > 1
      ? `${exportFailedCount} Companies`
      : `${exportFailedCount} Company`;

  const duplicateMsg =
    duplicateCompanyCount && duplicateCompanyCount > 1
      ? `${duplicateCompanyCount} Companies`
      : `${duplicateCompanyCount} Company`;

  if (exportSuccessCount > 0 || duplicateCompanyCount > 0) {
    return (
      <>
        <p>
          {exportSuccessCount > 0 && `${successMsg} exported successfully.`}
        </p>
        <p>{exportFailedCount > 0 && `${failedMsg} exported failed.`}</p>
        <p>
          {duplicateCompanyCount > 0 &&
            ` ${duplicateMsg} not exported as they already exist in your ${exportType} account.`}
        </p>
      </>
    );
  }

  return (
    <>
      An error occurred while exporting the ${failedMsg}. Please try again or
      contact{' '}
      <a href="mailto:support@adapt.io" target="_blank" rel="noreferrer">
        support@adapt.io
      </a>
      .
    </>
  );
}

function showExportStatusPopup(data) {
  const { result, customMsg, exportType, exportMxData } = data;
  const status =
    result?.exportSuccessCount > 0 || result?.duplicateCompanyCount > 0
      ? 'Success'
      : 'Failure';
  if (status === 'Success') {
    Utils.mixpanelTrack(MX_LB_EXPORT_EVENTS.LB_VIEW_EXPORT_SUCCESS, {
      exportType,
      ...exportMxData,
    });
  } else {
    Utils.mixpanelTrack(MX_LB_EXPORT_EVENTS.LB_VIEW_EXPORT_FAILED, {
      exportType,
      reason: customMsg || '',
      ...this.exportMxData,
    });
  }
  commonModalState.setShowModal(true, {
    title: `${exportType} Export ${status}`,
    description: (
      <DescriptionForExport
        result={result}
        customMsg={customMsg}
        exportType={exportType}
      />
    ),
    primaryButtonText: 'OK',
  });
}

export {
  showAssignOwnersPopup,
  showExportFailureLimitPopup,
  showExportStatusPopup,
};
