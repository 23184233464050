import React from 'react';
import RevenueIcon from 'Assets/svg/new_icons/revenue.svg';

function CompanyRevenue(props) {
  const { revenue = '', parentClass = '', clsName = '' } = props;

  return revenue !== undefined && revenue !== '' && revenue !== null ? (
    <div className={`company-revenue icon-wrap ${parentClass}`}>
      <RevenueIcon className="svg-icon" />
      <div className={`item-name show-ellipsis ${clsName}`} title={revenue}>
        {revenue}
      </div>
    </div>
  ) : (
    ''
  );
}

export { CompanyRevenue };
export default CompanyRevenue;
