import AccountIcon from 'Assets/svg/new_icons/account.svg';
import BagIcon from 'Assets/svg/new_icons/bag.svg';
import DepartmentIcon from 'Assets/svg/new_icons/department.svg';
import ChairIcon from 'Assets/svg/new_icons/chair.svg';
import LocationIcon from 'Assets/svg/new_icons/location.svg';
import EmailIcon from 'Assets/svg/new_icons/email.svg';
import KeyIcon from 'Assets/svg/new_icons/key.svg';
import ContactExclusionIcon from 'Assets/svg/new_icons/contactExclusion.svg';
import CompanyInclusionIcon from 'Assets/svg/new_icons/company-inclusion.svg';
import SicCodeIcon from 'Assets/svg/new_icons/sic-code.svg';
import NaicsCodeIcon from 'Assets/svg/new_icons/naics-code.svg';
import CompanyIcon from 'Assets/svg/new_icons/companies.svg';
import IndustryIcon from 'Assets/svg/new_icons/industry.svg';
import ContactIcon from 'Assets/svg/new_icons/contacts.svg';
import RevenueIcon from 'Assets/svg/new_icons/revenue.svg';
import TechnologyIcon from 'Assets/svg/new_icons/technology.svg';
import CompanyExclusionIcon from 'Assets/svg/new_icons/companyExclusion.svg';
import FundingIcon from 'Assets/svg/new_icons/funding.svg';
import LeadershipHireIcon from 'Assets/svg/new_icons/leadership-hire.svg';
import GrowthIcon from 'Assets/svg/new_icons/growth.svg';
import TechnologySectorIcon from 'Assets/svg/technology-sector.svg';
import Utils from 'Utils/utils';
import advSearchFiltersState from 'Stores/advSearchFilters';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';

const fundingDays = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '180 Days', value: 180 },
];

const totalFundingAmount = [
  { label: '$0M', value: 0 },
  { label: '$1M', value: 1000000 },
  { label: '$2.5M', value: 2500000 },
  { label: '$5M', value: 5000000 },
  { label: '$10M', value: 10000000 },
  { label: '$25M', value: 25000000 },
  { label: '$50M', value: 50000000 },
  { label: '$100M', value: 100000000 },
  { label: '$250M', value: 250000000 },
  { label: '$500M', value: 500000000 },
  { label: '$1B', value: 1000000000 },
];

const monthsFilter = [
  { label: '3 Months', value: '3' },
  { label: '6 Months', value: '6' },
  // { label: '12 Months', value: '12' },
];

const jobChangesMonthsFilter = [
  { label: '3 Months', value: 3 },
  { label: '6 Months', value: 6 },
  { label: '9 Months', value: 9 },
  { label: '12 Months', value: 12 },
];

const headCountGrowthPercentage = [
  { label: 'Any', value: 'any', filterLabel: 'any' },
  { label: '0+', value: '*', filterLabel: 'Above 0%' },
  { label: '10+', value: '10', filterLabel: 'Above 10%' },
  { label: '20+', value: '20', filterLabel: 'Above 20%' },
  { label: '30+', value: '30', filterLabel: 'Above 30%' },
  { label: '40+', value: '40', filterLabel: 'Above 40%' },
  { label: '50+', value: '50', filterLabel: 'Above 50%' },
  { label: '60+', value: '60', filterLabel: 'Above 60%' },
  { label: '70+', value: '70', filterLabel: 'Above 70%' },
  { label: '80+', value: '80', filterLabel: 'Above 80%' },
  { label: '90+', value: '90', filterLabel: 'Above 90%' },
  { label: '100+', value: '100', filterLabel: 'Above 100%' },
];

const filters = [
  {
    title: 'Contact Criteria',
    key: 'contact',
    list: [
      {
        text: 'Contact Name',
        filterKeys: ['name'],
        key: 'contactName',
        Icon: AccountIcon,
      },
      {
        text: 'Job Title',
        filterKeys: ['title', 'excludeTitle'],
        key: 'jobTitle',
        Icon: BagIcon,
      },
      {
        text: 'Department',
        filterKeys: ['dept'],
        key: 'department',
        Icon: DepartmentIcon,
      },
      {
        text: 'Level',
        title: 'Level',
        filterKeys: ['level'],
        key: 'level',
        Icon: ChairIcon,
      },
      {
        text: 'Job Changes',
        description: 'Discover contacts who started a new job recently',
        filterKeys: ['recentJobChanges'],
        key: 'recentJobChanges',
        Icon: LeadershipHireIcon,
        getValues: () => {
          return jobChangesMonthsFilter;
        },
        restricted: () => {
          return !featureFlagsAndPreferencesState.isGrowthFilterEnabled();
        },
        isNewFilter: true,
      },
      {
        text: 'Location',
        filterKeys: [
          'selectedCitiesContact',
          'selectedStatesContact',
          'selectedCountriesContact',
          'selectedRegionsContact',
        ],
        key: 'contactLocation',
        objectKey: 'name',
        objectValue: 'id',
        Icon: LocationIcon,
      },
      {
        text: 'Email Availability',
        Icon: EmailIcon,
        key: 'emailAccuracy',
      },
      {
        text: 'Contact Exclusion List', // suppression list tags shown
        Icon: ContactExclusionIcon,
        key: 'contactExclusion',
      },
    ],
  },
  {
    title: 'Company Criteria',
    key: 'company',
    list: [
      {
        text: 'Company Domain',
        filterKeys: ['companyName'],
        key: 'companyName',
        Icon: CompanyIcon,
      },
      {
        text: 'Industry',
        filterKeys: ['industrySectorsV2', 'excludeIndustrySectorsV2'],
        key: 'industry',
        objectKey: 'name',
        Icon: IndustryIcon,
      },
      {
        text: 'Technology Sector',
        filterKeys: ['paKeywords'],
        key: 'technologySector',
        Icon: TechnologySectorIcon,
        restricted: () => {
          return !featureFlagsAndPreferencesState.flags.practiceAreaEnabled;
        },
        getValues: () => {
          return advSearchFiltersState.flatListKeywords;
        },
        isNewFilter: true,
      },
      {
        text: 'SIC code',
        filterKeys: ['sicCodeV2'],
        key: 'sicCode',
        Icon: SicCodeIcon,
      },
      {
        text: 'NAICS code',
        filterKeys: ['naicsCodeV2'],
        key: 'naicsCode',
        Icon: NaicsCodeIcon,
      },
      {
        text: 'HQ Address',
        Icon: LocationIcon,
        key: 'companyLocation',
        filterKeys: [
          'selectedCitiesCompany',
          'selectedStatesCompany',
          'selectedCountriesCompany',
          'selectedRegionsCompany',
        ],
        objectKey: 'name',
        objectValue: 'id',
      },
      {
        text: 'Employee Count',
        filterKeys: ['employeeCount'],
        key: 'employeeCount',
        Icon: ContactIcon,
      },
      {
        text: 'Revenue',
        filterKeys: ['revenue'],
        key: 'revenue',
        Icon: RevenueIcon,
      },
      {
        text: 'Keyword',
        filterKeys: ['companyKeywordV2', 'excludeCompanyKeywordV2'],
        key: 'keyword',
        Icon: KeyIcon,
      },
      {
        text: 'Technology',
        filterKeys: ['technologies'],
        key: 'technology',
        Icon: TechnologyIcon,
      },
      {
        text: 'Company Inclusion List',
        Icon: CompanyInclusionIcon,
        filterKeys: ['includedDomains'],
        key: 'companyInclusion',
        objectKey: 'fname',
      },
      {
        text: 'Company Exclusion List',
        Icon: CompanyExclusionIcon,
        filterKeys: ['excludedDomains'],
        key: 'companyExclusion',
        objectKey: 'fname',
      },
    ],
  },
  {
    title: 'Growth Signals',
    key: 'growth',
    list: [
      {
        text: 'Funding',
        Icon: FundingIcon,
        key: 'funding',
        description:
          'Discover contacts in companies that raised funds recently based on funding date and round type',
        restricted: () => {
          return !featureFlagsAndPreferencesState.flags.fundingFilterEnabled;
        },
        getValues: () => {
          return {
            days: fundingDays,
            currentStageFunding: advSearchFiltersState.flatListFunding,
            lastFundingType: advSearchFiltersState.flatListFunding,
            totalFundingMinAmount: totalFundingAmount,
            totalFundingMaxAmount: totalFundingAmount,
          };
        },
        filterKeys: ['fundingData'],
        objectValueKeys: [
          'days',
          'currentStageFunding',
          'lastFundingType',
          'totalFundingMinAmount',
          'totalFundingMaxAmount',
        ],
        isNewFilter: true,
      },
      {
        text: 'Headcount Growth',
        Icon: GrowthIcon,
        description:
          'Discover contacts in companies that had a growth in their headcount',
        key: 'headCount',
        filterKeys: ['headcountGrowth'],
        // objectValueKeys: ['months', 'percentage', 'dept'],
        objectValueKeys: ['percentage', 'dept'],
        restricted: () => {
          return !featureFlagsAndPreferencesState.isGrowthFilterEnabled();
        },
        getValues: () => {
          return {
            months: monthsFilter,
            percentage: headCountGrowthPercentage,
          };
        },
        isNewFilter: true,
      },
    ],
  },
];

const modifiedCompanyFilters = Utils.copyObjectWithoutReference(
  filters[1].list,
).reduce((output, filter) => {
  const filterKey = filter.key;
  const excludeKey = [
    'companyInclusion',
    'keyword',
    'naicsCode',
    'sicCode',
  ].includes(filterKey);
  if (excludeKey) {
    return output;
  }
  const newFilter = filter;
  if (filterKey === 'companyLocation') {
    newFilter.filterKeys = [
      'selectedCities',
      'selectedStates',
      'selectedCountries',
      'selectedRegions',
    ];
  }

  const currentFilter = filters[1].list.find((companyItem) => {
    return companyItem.key === filterKey;
  });

  return [
    ...output,
    {
      ...filter,
      Icon: currentFilter?.Icon,
      enabled: currentFilter?.enabled,
      getValues: currentFilter?.getValues,
    },
  ];
}, []);

const companyFilters = [
  { ...filters[1], list: modifiedCompanyFilters },
  {
    ...filters[2],
    list: [
      filters[2].list[0],
      {
        text: 'New Hires',
        Icon: LeadershipHireIcon,
        description: 'Discover companies that made new hires recently',
        key: 'leadershipHire',
        filterKeys: ['leadershipHire'],
        objectValueKeys: ['dept', 'months', 'level'],
        restricted: () => {
          return !featureFlagsAndPreferencesState.isGrowthFilterEnabled();
        },
        getValues: () => {
          return {
            months: jobChangesMonthsFilter.map((data) => {
              const { label, value } = data;
              return { label, value: String(value) };
            }),
          };
        },
        isNewFilter: true,
      },

      filters[2].list[1],
    ],
  },
];

function getText(data) {
  const { filter, selectedValue, objectKey } = data;
  if (filter.getValues) {
    const values = filter.getValues();
    const isListDataAvailable = !objectKey || objectKey in values;
    if (isListDataAvailable) {
      const list = objectKey ? values[objectKey] : values;
      const object = list?.find((listProp) => {
        return listProp.value === selectedValue;
      });
      return object?.filterLabel || object?.label || selectedValue;
    }
  }
  return selectedValue;
}

function getParsedData({ keys, value, parsedFilter }) {
  const availableKey = keys.find((key) => {
    return parsedFilter[key] !== undefined;
  });
  let data = [];
  if (availableKey) {
    if (Array.isArray(parsedFilter[availableKey])) {
      if (
        typeof parsedFilter[availableKey][0] === 'object' &&
        value.objectKey
      ) {
        if (keys.length > 1) {
          data = keys.reduce((result, key) => {
            if (parsedFilter[key] !== undefined) {
              return [
                ...result,
                ...parsedFilter[key].map((prop) => {
                  return {
                    text: prop[value.objectKey],
                    value: prop[value.objectValue] || prop[value.objectKey],
                    key,
                  };
                }),
              ];
            }
            return result;
          }, []);
        } else {
          data = parsedFilter[keys[0]].map((prop) => {
            return {
              text: getText({
                filter: value,
                selectedValue: prop[value.objectKey],
              }),
              value: prop[value.objectValue] || prop[value.objectKey],
            };
          });
        }
      } else {
        data = parsedFilter[keys[0]].map((prop) => {
          const text = getText({ filter: value, selectedValue: prop });
          return {
            text,
            value: prop,
          };
        });
      }
    } else if (typeof parsedFilter[availableKey] === 'object') {
      if (value.objectKey) {
        const text = parsedFilter[availableKey][value.objectKey];
        data = [{ text, value: text }];
      } else if (value.objectValueKeys) {
        const appliedObject = parsedFilter?.[availableKey];
        value.objectValueKeys.forEach((key) => {
          const appliedValue = appliedObject?.[key];
          if (appliedValue) {
            const newValues = Array.isArray(appliedValue)
              ? appliedValue.map((prop) => {
                  return {
                    text: getText({
                      selectedValue: prop,
                      filter: value,
                      objectKey: key,
                    }),
                    value: prop,
                    objChainKey: key,
                  };
                })
              : [
                  {
                    text: getText({
                      selectedValue: appliedValue,
                      filter: value,
                      objectKey: key,
                    }),
                    value: appliedValue,
                    objChainKey: key,
                  },
                ];
            data = [...data, ...newValues];
          }
        });
      }
    } else {
      const selectedValue = parsedFilter[keys[0]];
      return [
        {
          text: getText({
            selectedValue,
            filter: value,
          }),
          value: selectedValue,
        },
      ];
    }
  }

  return data;
}

function getFilterList(filterObjectList) {
  return filterObjectList.flatMap((filterObject) => {
    return filterObject.list.map((prop) => {
      return {
        ...prop,
        restricted: prop?.restricted ? prop?.restricted() : false,
        searchRestricted: prop?.searchRestricted
          ? prop?.searchRestricted()
          : false,
      };
    });
  });
}

function getAppliedFilters(appliedFilters, page = 'contact') {
  const parsedFilter = Utils.copyObjectWithoutReference(appliedFilters);

  if (Object.keys(parsedFilter).length > 0) {
    const filterList =
      page === 'contact'
        ? getFilterList(filters)
        : getFilterList(companyFilters);

    const modifiedFilterValues = filterList.reduce((output, input) => {
      const { filterKeys, text } = input;
      const includedKeys = filterKeys?.filter((key) => {
        return !key.includes('exclude') && !key.includes('exclusion');
      });
      const excludedKeys = filterKeys?.filter((key) => {
        return key.includes('exclude') || key.includes('exclusion');
      });

      if (
        (includedKeys &&
          includedKeys.some((includedKey) => {
            return (
              parsedFilter[includedKey] !== undefined &&
              (Array.isArray(parsedFilter[includedKey])
                ? parsedFilter[includedKey].length > 0
                : true)
            );
          })) ||
        (excludedKeys &&
          excludedKeys.some((excludedKey) => {
            return (
              parsedFilter[excludedKey] !== undefined &&
              (Array.isArray(parsedFilter[excludedKey])
                ? parsedFilter[excludedKey].length > 0
                : true)
            );
          }))
      ) {
        return [
          ...output,
          {
            text,
            includeKey: filterKeys[0],
            excludeKey: filterKeys?.[1] ?? filterKeys[0],
            included: getParsedData({
              keys: includedKeys,
              parsedFilter,
              value: input,
            }),
            excluded: getParsedData({
              keys: excludedKeys,
              parsedFilter,
              value: input,
            }),
            restricted: input.restricted ?? false,
            searchRestricted: input.searchRestricted ?? false,
          },
        ];
      }
      return output;
    }, []);
    return modifiedFilterValues;
  }
  return [];
}

export { getAppliedFilters, companyFilters, totalFundingAmount };

export default filters;
