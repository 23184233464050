/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import * as CONSTANTS from 'Utils/constants';
import Select from '../base/Select';
import ControlInput from '../base/ControlInput';
import Modal from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

const dayPreference = [
  { label: 'First', value: 'First' },
  { label: 'Last', value: 'Last' },
];

function TimeZonePopup(props) {
  const {
    setShowTimeZonePopup,
    timeZoneValue,
    setTimeZoneValue,
    selectedEmailValues,
    handleChangeRadio,
  } = props;

  return (
    <Modal
      level={2}
      show
      closeModal={() => {
        setShowTimeZonePopup(false);
      }}
    >
      <Modal.Title>Time Zone</Modal.Title>
      <Modal.Content>
        <Select
          selectWrapperProps={{
            className: 'frequency-dropDown zone-pref',
          }}
          searchInputProps={{ placeholder: 'Search' }}
          dropdownOffset={{ top: 100 }}
          enableSearch
          options={CONSTANTS.timeZonesList}
          value={timeZoneValue}
          onChange={(data) => {
            setTimeZoneValue(data);
          }}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="outline"
          buttonProps={{
            text: 'Cancel',
          }}
          onClick={() => {
            setTimeZoneValue(selectedEmailValues.timeZone);
            setShowTimeZonePopup(false);
          }}
        />
        <Button
          buttonProps={{
            text: 'Save',
          }}
          onClick={() => {
            handleChangeRadio('timeZone', timeZoneValue);
            setShowTimeZonePopup(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

function SetEmailNotification(props) {
  const {
    customTitle,
    customClassName = '',
    handleChangeRadio,
    selectedEmailValues,
  } = props;

  const [showTimeZonePopup, setShowTimeZonePopup] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = useState(
    selectedEmailValues.timeZone,
  );

  return (
    <>
      <div className={`set-email-container ${customClassName}`}>
        <div>
          <label>{customTitle}</label>
          <div className="radio-buttons">
            <ControlInput
              type="radio"
              name="frequency"
              label="Weekly"
              value="Weekly"
              checked={
                selectedEmailValues.selectedTime === 'Weekly' ? 'checked' : ''
              }
              onChange={() => {
                handleChangeRadio('selectedTime', 'Weekly');
              }}
            />
            <ControlInput
              type="radio"
              name="frequency"
              label="Monthly"
              value="Monthly"
              checked={
                selectedEmailValues.selectedTime === 'Monthly' ? 'checked' : ''
              }
              onChange={() => {
                handleChangeRadio('selectedTime', 'Monthly');
              }}
            />
          </div>
        </div>
        <div className="dropdowns-container">
          <div className="dropdown-with-label-day">
            {selectedEmailValues.selectedTime !== 'Weekly' && (
              <Select
                inlineLabel={false}
                label="Day"
                selectWrapperProps={{
                  className: 'frequency-dropDown day-pref',
                }}
                dropdownOffset={{ top: 100 }}
                options={dayPreference}
                value={selectedEmailValues.ordinalDay}
                onChange={(data) => {
                  handleChangeRadio('ordinalDay', data);
                }}
              />
            )}
            <Select
              {...(selectedEmailValues.selectedTime === 'Weekly' && {
                inlineLabel: false,
                label: 'Day',
              })}
              selectWrapperProps={{
                className: 'frequency-dropDown',
              }}
              dropdownOffset={{ top: 100 }}
              options={CONSTANTS.weekDays}
              value={selectedEmailValues.day}
              onChange={(data) => {
                handleChangeRadio('day', data);
              }}
            />
          </div>
          <span className="small-text">at</span>
          <div className="dropdown-with-label">
            <Select
              selectWrapperProps={{
                className: 'frequency-dropDown time',
              }}
              inlineLabel={false}
              label="Time"
              dropdownOffset={{ top: 100 }}
              options={CONSTANTS.time}
              value={selectedEmailValues.time}
              onChange={(data) => {
                handleChangeRadio('time', data);
              }}
            />
          </div>
          <div
            className="timezone-selected show-ellipsis"
            title={selectedEmailValues.timeZone}
            onClick={() => {
              setShowTimeZonePopup(true);
            }}
          >
            {selectedEmailValues.timeZone}
          </div>
        </div>
      </div>
      {showTimeZonePopup && (
        <TimeZonePopup
          setShowTimeZonePopup={setShowTimeZonePopup}
          timeZoneValue={timeZoneValue}
          setTimeZoneValue={setTimeZoneValue}
          selectedEmailValues={selectedEmailValues}
          handleChangeRadio={handleChangeRadio}
        />
      )}
    </>
  );
}

export default SetEmailNotification;
