import { observable, action } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';

class CommonAppData {
  @observable accessor industrySectorsFullData = [];

  @observable accessor customerExecutiveList = [];

  @action
  setIndustrySectorsFullData = (value = []) => {
    this.industrySectorsFullData = value;
  };

  getAndSetIndustryFullData = async () => {
    const response = await this.getIndustrySuggestions();

    if (response && response.data) {
      this.setIndustrySectorsFullData(
        response.data
          .sort((ind1, ind2) => {
            return ind1?.industry?.originalText === 'Other'
              ? 1
              : ind2?.industry?.originalText === 'Other'
                ? -1
                : 0;
          })
          .map((data) => {
            const text = data.industry?.originalText;
            return {
              label: text,
              value: text,
              subList: data.sectors?.map((sector) => {
                const sectorText = sector.originalText;
                return { label: sectorText, value: sectorText };
              }),
            };
          }),
      );
    }
  };

  getIndustrySuggestions = async (
    queryString = '',
    cancelCallback = () => {},
  ) => {
    const config = {
      url: URLS.getIndustrySectorsSuggestion,
      method: 'POST',
      data: {
        q: queryString,
      },
    };

    try {
      const response = await makeApi(config, false, cancelCallback);
      return response;
    } catch (error) {
      return error;
    }
  };

  @action
  getCustomerExecutiveList = async () => {
    if (this.customerExecutiveList.length === 0) {
      const config = {
        url: URLS.customerExecutiveList,
        method: 'GET',
      };

      const response = await makeApi(config, false);

      this.customerExecutiveList = response?.data?.list ?? [];
    }
  };
}

const commonAppData = new CommonAppData();

export default commonAppData;
