import React from 'react';
import TripleDotNavIcon from 'Assets/svg/triple-dot-nav.svg';
import Dropdown from 'Components/common/base/Dropdown';
import './styles.scss';

function Menu(props) {
  const {
    children,
    wrapperClassName = '',
    className = '',
    align = 'start',
    fixedPosition = false,
    toggleElement,
    excludeElementForOutsideClick,
  } = props;

  const isDropdownMenu = toggleElement !== undefined;

  return (
    <Dropdown
      excludeElementForOutsideClick={excludeElementForOutsideClick}
      wrapperClassName={`menu-dropdown${isDropdownMenu ? ' custom-menu' : ''} ${wrapperClassName}`.trimEnd()}
      align={align}
      fixedPosition={fixedPosition}
      toggleElement={
        isDropdownMenu ? (
          <div className="dropdown-menu-wrap">{toggleElement}</div>
        ) : (
          <div className="menu-wrap">
            <TripleDotNavIcon className="menu-icon" />
          </div>
        )
      }
    >
      <div className={`menu-list ${className}`.trimEnd()}>{children}</div>
    </Dropdown>
  );
}

function MenuItem(props) {
  const { text, onClick, className = '', dataClose = 'true' } = props;
  return (
    <div
      className={`menu-item ${className}`.trimEnd()}
      data-close={dataClose}
      onClick={onClick}
    >
      {text}
    </div>
  );
}

export default Object.assign(Menu, { Item: MenuItem });
