import React, { useState, useEffect } from 'react';
import UpgradeImg from 'Assets/salesgear/png/upgrade.png';
import Button from 'Components/common/base/Button';
import Modal from 'Components/common/base/Modal';
import Utils from 'Utils/utils';
import { MX_EVENTS_AE, SALESGEAR_URL } from 'Utils/constants';
import './styles.scss';

function SalesgearUpgradePopup(props) {
  const { hide, value } = props;
  const [subTitle, setSubtitle] = useState('');

  const closePopup = () => {
    hide(false);
  };

  const getPopupText = () => {
    const { exportSuccess, name, count } = value || '';
    const contentObj = {};
    if (name && name === 'export') {
      contentObj.text =
        'Upgrade your Salesgear account and<br> export unlimited contacts';
      contentObj.title = 'Contact limit reached';
      contentObj.countText = `<span>${exportSuccess} out of ${count} contacts</span> successfully exported to Salesgear.`;
    } else {
      contentObj.text =
        'You have hit the limit of 100 contacts in the free plan.';
      contentObj.title = 'Upgrade to continue';
    }
    setSubtitle(contentObj);
  };

  const redirectToSalesgearPricing = () => {
    hide(false);
    Utils.mixpanelTrack(MX_EVENTS_AE.bannerClicked);
    // eslint-disable-next-line no-undef
    window.open(`${SALESGEAR_URL}/pricing`);
  };

  useEffect(() => {
    getPopupText();
  }, []);

  return (
    <Modal
      align="center"
      show
      closeModal={closePopup}
      className="salesgear-upgrade-popup"
    >
      <img src={UpgradeImg} alt="Illustration" className="illustration" />
      <Modal.Title>{subTitle.title}</Modal.Title>
      <Modal.Content>
        {subTitle.countText && (
          <p
            className="message"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitle.countText || '',
            }}
          />
        )}
        <p
          className="message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: subTitle.text || '',
          }}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          buttonProps={{ text: 'Upgrade Now' }}
          onClick={redirectToSalesgearPricing}
        />
        <ul className="about-policy">
          <li>No Contracts</li>
          <li>No Obligations</li>
          <li>Cancel Anytime</li>
        </ul>
      </Modal.Footer>
    </Modal>
  );
}

export default SalesgearUpgradePopup;
