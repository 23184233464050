import React from 'react';
import SharedIcon from 'Assets/svg/share-icon.svg';
import './styles.scss';

function SharedListIcon(props) {
  const { data, condition, iconTitle = 'Shared list' } = props;

  const displayCondition =
    condition !== undefined ? condition : data?.isSharedList;

  return (
    displayCondition && (
      <span className="shared-icon-wrap">
        <SharedIcon title={iconTitle} />
        <span>Shared</span>
      </span>
    )
  );
}

export default SharedListIcon;
