import React from 'react';
import { toasterState } from 'Components/common/base/Toaster';
import Button from 'Components/common/base/Button';
import { makeApi, URLS } from 'Utils/apiUtil';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import SocialLinks from '../../SocialLinks';
import ContactEmailPhone from '../ContactEmailPhone';
import ContactLocation from '../ContactLocation';
import ContactCompany from '../ContactCompany';
import Dropdown from '../../base/Dropdown';
import './styles.scss';

function ContactCard(props) {
  const {
    name,
    title,
    cname,
    contactCity = '',
    contactState = '',
    contactCountry = '',
    companyCountry = '',
    companyState = '',
    companyCity = '',
    contactId,
    email,
    phoneNumberFromVendor,
    pageName = 'advsearch',
    status = '',
    showFindEmail,
  } = props;
  const [showMarkAsInactiveConfirmation, setShowMarkAsInactiveConfirmation] =
    React.useState(false);

  const markAsInactiveClick = (masterContactId) => {
    const config = {
      url: URLS.reportMarkAsInactive,
      method: 'POST',
      params: {
        masterContactId,
      },
      data: {},
    };
    makeApi(config)
      .then((response) => {
        if (response && response.data !== undefined && response.data === true) {
          toasterState.setToastMsg(
            'Contact marked as inactive.',
            CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
          );
        } else {
          toasterState.setToastMsg(
            'Unable to process your request now. Please try later',
            CONSTANTS.TOAST_MSG_TYPES.FAILURE,
          );
        }
        setShowMarkAsInactiveConfirmation(false);
      })
      .catch((error) => {
        console.log('reportMarkAsInactive error ', error);
      });
  };
  return (
    <div
      className="contact-card"
      onMouseLeave={() => {
        setShowMarkAsInactiveConfirmation(false);
      }}
    >
      <div className="contact-card-details">
        <span className="sharp-point" />
        <div className="basic-info-wrapper">
          <div className="name-wrapper">
            <div className="contact-name">{name}</div>
            <SocialLinks {...props} />
          </div>
          <div className="title">{title}</div>
          <ContactCompany cname={cname} showLogo={false} />
        </div>
        <div className="additional-info-wrapper">
          <ContactEmailPhone
            contactId={contactId}
            email={email}
            status={status}
            phoneNumberFromVendor={phoneNumberFromVendor}
            pageName={pageName}
            isDownloaded
            showFindEmail={showFindEmail}
          />
          {(contactCity !== '' ||
            contactState !== '' ||
            contactCountry !== '' ||
            companyCity !== '' ||
            companyState !== '' ||
            companyCountry !== '') && (
            <div className="contact-location-wrapper">
              <ContactLocation
                city={contactCity}
                state={contactState}
                country={contactCountry}
              />
              <ContactLocation
                country={companyCountry}
                state={companyState}
                city={companyCity}
              />
            </div>
          )}
          {status !== '' &&
            status !== 'DEAD' &&
            email !== null &&
            email !== undefined && (
              <div className="contact-card-action-wrapper">
                {showMarkAsInactiveConfirmation ? (
                  <>
                    <Button
                      buttonProps={{
                        text: 'Confirm',
                        className: 'action-btn',
                      }}
                      onClick={() => {
                        markAsInactiveClick(contactId);
                      }}
                    />
                    <Button
                      variant="outline"
                      buttonProps={{ text: 'Cancel', className: 'cancel-btn' }}
                      onClick={() => {
                        setShowMarkAsInactiveConfirmation(false);
                      }}
                    />
                  </>
                ) : (
                  <Button
                    variant="outline"
                    buttonProps={{
                      text: 'Mark as Inactive',
                      className: 'action-btn',
                    }}
                    onClick={() => {
                      setShowMarkAsInactiveConfirmation(true);
                    }}
                  />
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

function ContactName(props) {
  const {
    name,
    title,
    contactId,
    owned = false,
    purchaseContact,
    contactCity,
    contactState,
    contactCountry,
  } = props;

  const handleNameClick = (e, id) => {
    Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_CONTACT_NAME_CLICK, {
      contactName: name,
    });
    purchaseContact(e, id);
  };

  return (
    <div className="contact-name-wrapper">
      <div className="name-wrapper">
        {owned ? (
          <Dropdown
            toggleElement={
              <div className="contact-name contact-card-wrapper">{name}</div>
            }
            showOnHover
          >
            <ContactCard {...props} />
          </Dropdown>
        ) : (
          <div
            title={name}
            className="contact-name show-ellipsis"
            onClick={(e) => {
              handleNameClick(e, contactId);
            }}
          >
            {name}
          </div>
        )}
        <SocialLinks {...props} />
      </div>
      <div className="title show-ellipsis" title={title}>
        {title}
      </div>
      <ContactLocation
        city={contactCity}
        state={contactState}
        country={contactCountry}
        showUnavailableText={false}
      />
    </div>
  );
}

export default ContactName;
