import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LoadingWrapper from 'Components/common/base/Loader';
import EmptyState from '../EmptyState';
import { barChartOptions, lineChartOptions } from './fixtures';
import './styles.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
);

function ChartCard(props) {
  const {
    title,
    children,
    chartData,
    emptyState = false,
    isLoading = false,
    showEmptyStateButton = true,
  } = props;
  const { type = 'line', data, options, plugins } = chartData;

  return (
    <div className="usage-analytics-chart-card">
      <LoadingWrapper position="fixed" show={isLoading} />
      <div className="chart-title">{title}</div>
      <div className="chart-filters">{children}</div>
      {!isLoading && (
        <div className="chart-wrapper">
          {emptyState ? (
            <EmptyState chart={type} showButton={showEmptyStateButton} />
          ) : type === 'bar' ? (
            <Bar
              data={data}
              options={{ ...barChartOptions, ...options }}
              plugins={plugins}
            />
          ) : (
            <Line
              data={data}
              options={options || lineChartOptions}
              plugins={plugins}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ChartCard;
