/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import DownloadIcon from 'Assets/svg/download-new.svg';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import enrichmentState from 'Stores/enrichment';
import LoadingWrapper from 'Components/common/base/Loader';
import Pagination from 'Components/common/pagination';
import Button from 'Components/common/base/Button';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import DateRangePicker from 'Components/common/DateRangePicker';
import fieldMappingState from 'Stores/fieldMapping';
import {
  defaultDateValue,
  ENRICHMENT_LOG_MAX_LIMIT,
  MX_ENRICHMENT_EVENTS,
} from 'Utils/constants';
import Utils from 'Utils/utils';
import EnrichmentDetailsPopup from './enrichmentDetailsPopup';
import './styles.scss';

const staticData = [
  {
    email: 'janecooper@wall.com',
    enrichedAt: '20 Jan 2021',
    status: 'COMPLETED',
    enrichedFields: 1,
    refreshedFields: 1,
  },
  {
    email: 'janecooper2@wall.com',
    enrichedAt: '20 Jan 2021',
    status: 'COMPLETED',
    enrichedFields: 1,
    refreshedFields: 1,
  },
];

function TableHeader() {
  return (
    <div className="log-header">
      <span>Email Address</span>
      <span>Action</span>
    </div>
  );
}

function TableBody(props) {
  const { data, index, setDetailsPopupData = () => {}, crmName } = props;
  const { enrichedFields, refreshedFields } = data;
  const isNotEnriched = enrichedFields === 0 && refreshedFields === 0;

  const date = format(new Date(data.enrichedAt), 'dd MMM yyyy');

  return (
    <div key={`log-${index}`} className="log-field">
      <div className="log-field-inner">
        <div className="email">
          <TickIcon
            className={`tick-icon${isNotEnriched ? ' not-enriched' : ''}`}
          />
          <div className="text-wrap">
            {data.email}
            <div className="date">
              {Utils.getFormattedDate(date, true)}
              <span className="time">
                {format(new Date(data.enrichedAt).getTime(), 'HH:mm:ss')}
              </span>
            </div>
          </div>
        </div>
        <div className={`view-report${isNotEnriched ? ' not-enriched' : ''}`}>
          {isNotEnriched ? (
            '- Not Updated -'
          ) : (
            <Button
              variant="outline"
              buttonProps={{ text: 'View Updates', className: 'view-updates' }}
              onClick={() => {
                Utils.mixpanelTrack(
                  MX_ENRICHMENT_EVENTS.VIEW_UPDATES_BTN_CLICKED,
                  {
                    type: crmName,
                  },
                );
                setDetailsPopupData({
                  show: true,
                  currentData: data,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function EmptyState() {
  return (
    <div className="log-body empty-state">
      {staticData.map((data, index) => {
        return <TableBody key={data.email} data={data} index={index} />;
      })}
      <div className="no-data-wrapper">
        <div className="no-data-title">
          {fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
            ?.crmContact
            ? 'Auto enrichment is enabled now'
            : 'No enrichment data available'}
        </div>
        <div className="no-data-message">
          {fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
            ?.crmContact
            ? 'We will enrich any new contacts that get added to your CRM'
            : 'Auto enrichment is disabled, to enable go to settings'}
        </div>
      </div>
    </div>
  );
}

function EnrichmentLog() {
  const { crmType } = useParams();

  const logBodyRef = useRef();

  const [detailsPopup, setDetailsPopupData] = useState({
    show: false,
    currentData: {},
  });
  const [paginationData, setPaginationData] = useState({
    page: 1,
    noOfItems: 50,
  });
  const [dateRange, setDateRange] = useState(defaultDateValue);
  const crmName = crmType.toUpperCase();

  const downloadLog = async () => {
    Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.DOWNLOAD_LOG_CLICKED, {
      count: enrichmentState.enrichmentLogCount,
    });
    if (enrichmentState.enrichmentLogCount > ENRICHMENT_LOG_MAX_LIMIT) {
      commonModalState.setShowModal(true, {
        Icon: WarningIcon,
        title: 'Count exceeds maximum limit',
        description: `The maximum limit for contact downloads is 50,000 records. Please reduce your contact selection to less than 50,000 by ${
          dateRange.startDate !== null ? 'altering the' : 'choosing a'
        } date range.`,
        primaryButtonText: 'Ok',
      });
    } else {
      const isSuccess = await enrichmentState.downloadEnrichmentLogs({
        crmType: crmName,
        ...(dateRange.startDate && {
          from: format(dateRange.startDate, 'YYYYY-MM-dd'),
        }),
        ...(dateRange.endDate &&
          dateRange.endDate != 'Invalid Date' && {
            to: format(dateRange.endDate, 'YYYYY-MM-dd'),
          }),
      });
      if (isSuccess) {
        commonModalState.setShowModal(true, {
          modalProps: {
            align: 'center',
            className: 'download-initiated-popup',
          },
          Icon: DownloadIcon,
          title: 'Download Initiated',
          description: (
            <>
              Your download request is initiated. We will email you at{' '}
              <span>{Utils.getUserEmail()}</span> once the download is complete.
            </>
          ),
          primaryButtonText: 'Ok',
        });
      }
    }
  };

  const changePage = (page) => {
    setPaginationData({
      page: page + 1,
      noOfItems: paginationData.noOfItems,
    });
    enrichmentState.getEnrichmentLog({
      crmType: crmName,
      ...(dateRange.startDate && {
        from: format(dateRange.startDate, 'YYYYY-MM-dd'),
      }),
      ...(dateRange.endDate &&
        dateRange.endDate != 'Invalid Date' && {
          to: format(dateRange.endDate, 'YYYYY-MM-dd'),
        }),
      page: page + 1,
      count: paginationData.noOfItems,
    });
  };

  const changePageLimit = (value) => {
    setPaginationData({
      page: 1,
      noOfItems: value,
    });
    enrichmentState.getEnrichmentLog({
      crmType: crmName,
      ...(dateRange.startDate && {
        from: format(dateRange.startDate, 'YYYYY-MM-dd'),
      }),
      ...(dateRange.endDate &&
        dateRange.endDate != 'Invalid Date' && {
          to: format(dateRange.endDate, 'YYYYY-MM-dd'),
        }),
      page: 1,
      count: value,
    });
  };

  useEffect(() => {
    if (logBodyRef.current) {
      logBodyRef.current.scrollTop = 0;
    }
  }, [paginationData]);

  useEffect(() => {
    enrichmentState.getEnrichmentLog({
      crmType: crmName,
    });
    enrichmentState.getEnrichmentLogCount({ crmType: crmName });
    Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.CRM_ENRICHMENT_LOG_PAGE_VIEWED, {
      type: crmName,
    });
  }, []);

  return (
    <div
      className={`enrichment-log-wrapper${
        enrichmentState.enrichmentLogCount > 0 ||
        enrichmentState.loaderState.isGetEnrichmentLoading
          ? ''
          : ' no-data'
      }`}
    >
      <div className="top-wrapper">
        <div className="title">Enrichment Activity Log</div>
        {(enrichmentState.enrichmentLogCount > 0 ||
          enrichmentState.loaderState.isGetEnrichmentLoading ||
          dateRange.startDate !== null) && (
          <DateRangePicker
            onClose={() => {
              enrichmentState.getEnrichmentLog({
                crmType: crmName,
              });
              enrichmentState.getEnrichmentLogCount({
                crmType: crmName,
              });
              setDateRange(defaultDateValue);
            }}
            onSubmit={(range) => {
              Utils.mixpanelTrack(MX_ENRICHMENT_EVENTS.DATE_FILTER_APPLIED, {
                startDate: range.startDate,
                endDate: range.endDate,
              });

              setDateRange(range);
              const data = {
                crmType: crmName,
                from: format(range.startDate, 'yyyy-MM-dd'),
                to: format(range.endDate, 'yyyy-MM-dd'),
              };
              enrichmentState.getEnrichmentLog(data);
              enrichmentState.getEnrichmentLogCount(data);
            }}
          />
        )}
        {enrichmentState.enrichmentLogCount > 0 && (
          <span className="download-log" onClick={downloadLog}>
            <DownloadIcon title="Download enrichment logs" />
          </span>
        )}
      </div>

      <TableHeader />
      {enrichmentState.enrichmentLogHistory.length > 0 ||
      enrichmentState.loaderState.isGetEnrichmentLoading ? (
        <>
          {!fieldMappingState.enrichmentSettingsData?.autoEnrichConfig
            ?.crmContact &&
            !enrichmentState.loaderState.isGetEnrichmentLoading &&
            enrichmentState.enrichmentLogHistory.length > 0 && (
              <div className="enrichment-settings-banner">
                {fieldMappingState.enrichmentSettingsData?.isAdmin ? (
                  <>
                    <WarningIcon />
                    <span>
                      Auto Enrichment is disabled now - To enable, go to
                    </span>
                    <Link to={`/enrichment/${crmType}/settings`}>Settings</Link>
                  </>
                ) : (
                  <>
                    <WarningIcon />
                    <span>
                      Auto Enrichment is disabled now - To enable, contact your
                      Account administrator.
                    </span>
                  </>
                )}
              </div>
            )}
          <div className="log-body" ref={logBodyRef}>
            <div className="log-body-inner">
              {enrichmentState.loaderState.isGetEnrichmentLoading
                ? Array.from({ length: 10 }).map((...args) => {
                    return (
                      <div
                        key={`log-placeholder-${args[1]}`}
                        className="log-field placeholder"
                      >
                        <div className="log-field-inner">
                          <div className="email-wrap">
                            <div className="email linear-background" />
                            <div className="date linear-background" />
                          </div>
                          <div className="view-report linear-background" />
                        </div>
                      </div>
                    );
                  })
                : enrichmentState.enrichmentLogHistory.map((data, index) => {
                    return (
                      <TableBody
                        crmName={crmName}
                        key={data.id}
                        data={data}
                        index={index}
                        setDetailsPopupData={setDetailsPopupData}
                      />
                    );
                  })}
            </div>
          </div>
          {enrichmentState.enrichmentLogCount > 15 && (
            <Pagination
              totalCount={enrichmentState.enrichmentLogCount}
              rowsPerPage={paginationData.noOfItems}
              currentPage={paginationData.page}
              updateCurrentPage={changePage}
              onRowsPerPageUpdate={changePageLimit}
              showTotalLogs
            />
          )}
        </>
      ) : (
        <EmptyState />
      )}
      <LoadingWrapper show={enrichmentState.isLoading} position="fixed" />
      {detailsPopup.show && (
        <EnrichmentDetailsPopup
          contactEnrichmentData={detailsPopup.currentData}
          togglePopup={() => {
            setDetailsPopupData({
              show: false,
              currentData: {},
            });
          }}
        />
      )}
    </div>
  );
}

export default observer(EnrichmentLog);
