import React, { useState, useEffect } from 'react';
import sequenceStoreData from 'Stores/sequenceData';
import { observer } from 'mobx-react';
import TickIcon from 'Assets/svg/tick-icon-new.svg';
import NoSequenceComponent from './NoSequence';

function LoadingComponent() {
  return Array.from({ length: 10 }, (value, index) => {
    return (
      <div className="sequence-details" key={`sequenceDetails ${index}`}>
        <span className="linear-background" />
      </div>
    );
  });
}

function SequenceList(props) {
  const { setSelectedSeq, setLoading, loading, setEnableConfirmButton } = props;
  const [sequenceData, setSequenceData] = useState('');

  const addSelectedFlag = (id) => {
    setSequenceData(
      sequenceData.map((seqObj) => {
        return { ...seqObj, isSelected: id === seqObj.id };
      }),
    );
  };

  const addSelectedFlagForFirstSequence = (sequence) => {
    const newSequence = sequence;
    if (newSequence && newSequence[0] && newSequence[0].id) {
      newSequence[0].isSelected = true;
      return newSequence;
    }
    return newSequence;
  };

  const setSelectedSequence = (sequence) => {
    setSelectedSeq(sequence.id);
  };

  const getFirstSequence = (seqList) => {
    const newList = seqList;
    if (newList && newList[0] && newList[0].id) {
      return newList[0];
    }
    return newList;
  };

  const constructSequenceData = (data) => {
    if (data && data.length) {
      const firstSequence = getFirstSequence(data);
      setSequenceData(addSelectedFlagForFirstSequence(data));
      setSelectedSequence(firstSequence);
      setLoading(false);
      setEnableConfirmButton(true);
    } else {
      setSequenceData([]);
      setEnableConfirmButton(false);
      setLoading(false);
    }
  };

  const hasSequences = () => {
    if (sequenceData && sequenceData.length > 0) {
      return true;
    }
    return false;
  };

  const selectedSeqAction = (e, seqId) => {
    e.preventDefault();
    addSelectedFlag(seqId);
    setSelectedSeq(seqId);
  };

  const getOwnerInitial = (owner) => {
    if (owner) {
      const fn = owner.firstName;
      const ln = owner.lastName;
      if (fn && ln) {
        return fn[0] + ln[0];
      }
      if (fn) {
        return fn.slice(0, 2);
      }
      if (ln) {
        return ln.slice(0, 2);
      }
    }
    return '';
  };

  useEffect(() => {
    if (sequenceStoreData.sequenceData) {
      constructSequenceData(sequenceStoreData.sequenceData);
    }
  }, [sequenceStoreData.sequenceData]);

  return !loading ? (
    <div className="sequence-container">
      {hasSequences() ? (
        // eslint-disable-next-line consistent-return
        sequenceData.map((seqObj) => {
          const { id, name, isSelected, owner } = seqObj;
          if (name) {
            return (
              <div
                className={`sequence-details name ${
                  isSelected ? 'selected' : ''
                }`}
                key={id}
                data-name={name}
                data-id={id}
                onClick={(e) => {
                  selectedSeqAction(e, id);
                }}
              >
                <span className="name">{name}</span>
                <span className="owner">{getOwnerInitial(owner)} </span>
                {isSelected && (
                  <span className="tick-icon">
                    <TickIcon />
                  </span>
                )}
              </div>
            );
          }
          return null;
        })
      ) : (
        <div className="no-sequence">
          <NoSequenceComponent />
        </div>
      )}
    </div>
  ) : (
    <div className="sequence-loading">
      <LoadingComponent />
    </div>
  );
}

export default observer(SequenceList);
