import React from 'react';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import './styles.scss';

function PageNotFound() {
  return (
    <div className="page-not-found">
      <WarningIcon />
      <div className="not-found-title">Oops!</div>
      <div className="not-found-text">
        <div>
          Something went wrong. We couldn't find what you are looking for.
        </div>
        <div>Please try after sometime.</div>
      </div>
    </div>
  );
}

export default PageNotFound;
