import React from 'react';
import WebIcon from 'Assets/svg/company-icon/link.svg';

function CompanyWebsite(props) {
  const { primaryDomain = '', parentClass = '', clsName = '' } = props;

  return primaryDomain !== undefined &&
    primaryDomain !== '' &&
    primaryDomain !== null ? (
    <div className={`company-website icon-wrap ${parentClass}`}>
      <a
        href={`https://www.${primaryDomain}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <WebIcon className="svg-icon" />
        <div className={`item-name show-ellipsis ${clsName}`}>
          {primaryDomain}
        </div>
      </a>
    </div>
  ) : (
    ''
  );
}

export { CompanyWebsite };
export default CompanyWebsite;
