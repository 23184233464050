import React from 'react';

function EmailTagComponent({ email }) {
  return (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
      {email}
    </a>
  );
}

export default EmailTagComponent;
export { EmailTagComponent };
