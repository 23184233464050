import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function UpgradeSection(props) {
  const {
    imageURL,
    title = '',
    message = 'Upgrade your account to use this filter',
  } = props;

  return (
    <div className="filter-upgrade-section">
      <div className="image-wrapper">
        <img src={imageURL} alt="" />
      </div>
      <div className="text-section">
        <p className="title">{title}</p>
        <p className="message">{message}</p>
      </div>
      <Link to="/pricing" className="view-pricing">
        Upgrade Now
      </Link>
    </div>
  );
}

export default UpgradeSection;
