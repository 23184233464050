/* eslint-disable react/no-unescaped-entities */

import React, { useState, useEffect } from 'react';
import Utils from 'Utils/utils';
import { observer } from 'mobx-react';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import adaptLogo from 'Assets/logo/logo_new.png';
import activateEmailImg from 'Assets/png/activateEmailImg.png';
import Title from 'Components/common/base/Title';
import EmailTagComponent from 'Components/common/emailTagComponent';
import { resendActivationLink } from 'Utils/commonApi';
import LoadingWrapper from 'Components/common/base/Loader';
import '../../../styles/layout/_activateAccount.scss';

const { EMAIL_TYPES, MX_UPDATE_EMAIL_EVENTS } = CONSTANTS;
const { PRIMARY, SECONDARY } = EMAIL_TYPES;

const ActivateAccount = observer(() => {
  const { showActivateAccountPage, userInfo } = userDetail;
  const { emailUpdateHistory, secondaryEmailUpdateHistory } = userInfo;
  const [isPrimaryVerification, setIsPrimaryVerification] = useState(
    Utils.checkIsSecondaryEmailVerified(),
  );

  const [updateEmailEnabled, setUpdateEmailEnabled] = useState(false);
  Utils.stopBodyScroll(false, true);
  useEffect(() => {
    if (
      Utils.checkIsEmailVerified(false) &&
      Utils.checkIsSecondaryEmailVerified()
    ) {
      Utils.redirectToLbHomePage();
    } else {
      Utils.deleteCookie('seo_searchJson');
      Utils.deleteCookie('seo_contact_url');
      Utils.deleteCookie('seo_search');
      Utils.deleteCookie('seo_page_data');

      Utils.loadRegistrationTrackingCode();
      userDetail.setShowActivateAccountPage(true);
    }
  }, []);

  const resendActivationEmail = () => {
    const { email } = userDetail.userInfo;
    Utils.mixpanelTrack('resendClick');
    resendActivationLink(email);
  };

  const handleUpdateHereClick = () => {
    const verificationType = isPrimaryVerification ? PRIMARY : SECONDARY;
    Utils.mixpanelTrack(
      MX_UPDATE_EMAIL_EVENTS.LB_UPDATE_EMAIL_SHOW_POPUP_CLICK,
      {
        verificationType,
      },
    );
    userDetail.setShowUpdateUserEmailPopup(verificationType);
  };

  useEffect(() => {
    if (isPrimaryVerification && emailUpdateHistory.length < 2) {
      setUpdateEmailEnabled(true);
    } else if (
      !isPrimaryVerification &&
      secondaryEmailUpdateHistory.length < 2
    ) {
      setUpdateEmailEnabled(true);
    }
  }, []);

  return (
    <>
      <Title title="Activate Account" />
      {showActivateAccountPage ? (
        <div className="activate-account-page">
          <div className="logo-wrapper">
            <img
              src={adaptLogo}
              className="logo"
              alt="adaptLogoWhitemediuImg"
            />
          </div>
          <div className="activate-content-wrapper">
            {!isPrimaryVerification && (
              <div className="title">Please verify your business email id</div>
            )}
            <img
              src={activateEmailImg}
              className="activate-account"
              alt="activateEmailImg"
            />
            <div
              className={`title ${isPrimaryVerification ? '' : ' sub-title'}`}
            >
              {isPrimaryVerification
                ? 'One Step Away!'
                : 'Deja Vu! Hey, you are here again!'}
            </div>
            <div className="primary-description">
              We have sent an email to{' '}
              <EmailTagComponent
                email={
                  isPrimaryVerification
                    ? Utils.getUserEmail()
                    : Utils.getUserSecondaryEmail()
                }
              />{' '}
              <br /> Follow the instructions to activate your account.
            </div>
            <div className="secondary-description">
              Haven't recieved your email? please check spam folder. <br /> If
              not{' '}
              <span
                className="resend-activation-link"
                onClick={resendActivationEmail}
              >
                Resend
              </span>{' '}
              or contact <EmailTagComponent email="support@adapt.io" />
            </div>
            {updateEmailEnabled && (
              <div className="incorrent-email-update-wrapper">
                Incorrect Email?{' '}
                <div
                  className="update-here-btn"
                  onClick={handleUpdateHereClick}
                >
                  Update here
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoadingWrapper show position="fixed" />
      )}
    </>
  );
});

export default ActivateAccount;
