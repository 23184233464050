import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from 'Assets/svg/new_icons/delete-document.svg';
import ArchivedIcon from 'Assets/svg/archive-icon.svg';
import Menu from 'Components/common/base/Menu';
import BackIconWithText from 'Components/common/BackIconWithText';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import SharedListIcon from 'Components/common/SharedListIcon';
import myLeadsState from 'Stores/myLeads';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { listNamePopupState } from '../../ListNamePopup';
import './styles.scss';

function ListPageHeader(props) {
  const navigate = useNavigate();
  const { list = {} } = props;
  const { archivedCount = 0 } = list;

  const renameLeadsList = () => {
    listNamePopupState.setActionMethod('update');
    listNamePopupState.setDefaultValue(list.name);
    listNamePopupState.setDefaultListId(list.id);
    listNamePopupState.setShowCreateList(true);
  };

  const deleteLeadsList = () => {
    const callback = async () => {
      const isSuccess = await myLeadsState.deleteLeadsList(list.id, list.name);
      if (isSuccess) {
        navigate('/my-leads');
      }
    };

    commonModalState.setShowModal(true, {
      Icon: DeleteIcon,
      modalProps: { className: 'delete-list-popup' },
      title: 'Delete List',
      description: (
        <>
          <div>
            Are you sure you want to delete the List <b>“{list.name}”</b>?{' '}
            <br />
          </div>
          <div>
            All the contacts in this list will still be available in &#39;All
            Leads&#39;
          </div>
        </>
      ),
      primaryButtonText: 'Delete',
      secondaryButtonText: 'Cancel',
      buttonCallback: callback,
    });
  };

  return (
    <div className="list-page-header">
      <BackIconWithText
        text={list.name}
        href="/my-leads"
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      />
      <SharedListIcon data={list} />
      {list.name !== 'All Leads' &&
        list.name !== 'Plugin History' &&
        list.memberId === userDetail.userInfo.memberId && (
          <Menu>
            <Menu.Item text="Rename List" onClick={renameLeadsList} />
            <Menu.Item text="Delete List" onClick={deleteLeadsList} />
          </Menu>
        )}
      {archivedCount > 0 && (
        <div className="archived-count-wrapper">
          <ArchivedIcon className="archived-icon" />
          Archived Contacts:
          <span className="archived-count">
            {Utils.usNumberFormat(archivedCount)}
          </span>
        </div>
      )}
    </div>
  );
}

export default observer(ListPageHeader);
