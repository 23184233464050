import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import AlertIcon from 'Assets/svg/new_icons/alert.svg';
import CloseIcon from 'Assets/svg/close-material.svg';
import ControlInput from 'Components/common/base/ControlInput';
import ContactName from 'Components/common/ContactList/ContactName';
import ContactCompany from 'Components/common/ContactList/ContactCompany';
import ContactEmailPhone from 'Components/common/ContactList/ContactEmailPhone';
import { savedSearchPopupState } from 'Components/common/savedSearchPopup';
import ContactIndustry from 'Components/common/ContactList/ContactIndustry';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import Button from 'Components/common/base/Button';
import CompanySector from 'Components/common/ContactList/CompanySector';
import CompanyFundingDetails from 'Components/common/ContactList/CompanyFundingDetails';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import advSearchState from 'Stores/advSearch';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import { showUnableToDeselectModal } from '../modals';
import './styles.scss';

const { SEOCookieQueryMapping, MX_AS_EVENTS } = CONSTANTS;

function ContactListLoadingPlaceHolder() {
  return (
    <div className="contact-list-wrap">
      {Array(15)
        .fill()
        .map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="contact-item-wrap" key={`loader-${index}`}>
              <div className="contact-item">
                <div className="contact-check-box-wrap">
                  <div className="control-input linear-background" />
                </div>
                <div className="contact-name-wrapper">
                  <div className="name linear-background" />
                  <div className="title linear-background" />
                  <div className="location-wrapper linear-background" />
                </div>
                <div className="contact-info">
                  <div className="contact-email">
                    <div className="info-icon linear-background" />
                    <div className="contact-email-copy-wrap">
                      <div className="info-detail linear-background" />
                    </div>
                  </div>
                  <div className="contact-phone">
                    <div className="phone-wrapper">
                      <div className="phone-icon linear-background" />
                      <div className="phone-value linear-background" />
                    </div>
                  </div>
                </div>
                <div className="company-name-wrapper">
                  <div className="text-section">
                    <div className="company-name linear-background" />
                    <div className="location-wrapper linear-background"> </div>
                  </div>
                </div>
                <div className="industry-wrapper">
                  <div className="info-wrap linear-background" />
                  <div className="industry-content-info linear-background" />
                  <div className="company-number linear-background" />
                </div>
                <div className="tech-sector-list">
                  <div className="inner-wrapper">
                    <div className="sector linear-background" />
                    <div className="sector linear-background" />
                  </div>
                </div>
                <div className="company-funding-details">
                  <p className="linear-background" />
                  <p className="linear-background last-funding" />
                  <p className="linear-background" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

function ContactHeader() {
  return (
    <div className="contact-header">
      <div className="contact-check-box-wrap">&nbsp;</div>
      <div className="contact-name-wrapper">Name</div>
      <div className="contact-info">Contact</div>
      <div>Company</div>
      <div>Company Details</div>
      <div>Sector</div>
      <div>Funding Details</div>
    </div>
  );
}

const ListItemView = observer((props) => {
  const {
    contactSelection,
    purchaseContact,
    handleMaskedContactInfoClick,
    purchaseMobile,
    item,
    index,
  } = props;

  const seoContactId = Utils.getCookie(SEOCookieQueryMapping.contactId);
  let seoPathVal = false;
  seoPathVal = seoContactId && seoContactId === item.id;
  const contactName = `${item.firstName}${
    item.lastName !== null ? ` ${item.lastName}` : ''
  }`;

  const { id } = item ?? {};

  return (
    <div
      className={`contact-item-wrap ${
        advSearchState.singlePurchaseLoaderIds.includes(id) ? 'disable' : ''
      }`}
      key={item.id}
    >
      <div
        className={`contact-item${
          seoPathVal && index === 0 && advSearchState.currentPage === 0
            ? ' highlight'
            : ''
        }`}
      >
        <div className="contact-check-box-wrap">
          <ControlInput
            checked={advSearchState.bulkPurchaseIds.includes(id)}
            type="checkbox"
            label=""
            onChange={() => {
              contactSelection(index);
            }}
          />
        </div>

        <ContactName
          name={contactName}
          title={item.title}
          facebookId={item.facebookId}
          twitterId={item.twitterId}
          linkedInId={item.linkedInId}
          cname={item.companyName}
          contactCity={item.contactCity}
          contactState={item.contactState}
          contactCountry={item.contactCountry}
          companyCountry={item.companyCountry}
          companyState={item.companyState}
          companyCity={item.companyCity}
          contactId={item.id}
          email={item.email}
          phoneNumberFromVendor={item.phoneNumberFromVendor}
          owned={item.owned}
          status={item.status}
          purchaseContact={purchaseContact}
          showFindEmail={item.showFindEmail}
        />
        <ContactEmailPhone
          contactId={item.id}
          email={item.email}
          status={item.status}
          phoneNumberFromVendor={item.phoneNumberFromVendor}
          isDownloaded={item.owned}
          pageName="advSearch"
          purchaseContactFunc={handleMaskedContactInfoClick}
          purchaseMobile={purchaseMobile}
          isEmailValid={item.emailValid}
          showFindEmail={item.showFindEmail}
          zbStatus={item.emailValidationStatus}
          expectedStatus={
            item.emailConfidenceScoreV2 === 95
              ? 'Valid'
              : item.emailConfidenceScoreV2 === 85 ||
                  item.emailConfidenceScoreV2 === 75
                ? 'Catch-All'
                : 'Unknown'
          }
        />
        <ContactCompany
          cname={item.companyName}
          companyCountry={item.companyCountry}
          companyState={item.companyState}
          companyCity={item.companyCity}
          companyLogo={item.logo}
          companyId={item.companyId}
          company={{
            linkedinURL: item.companyLinkedInId,
            twitterURL: item.companyTwitterId,
            facebookURL: item.companyFacebookId,
            website: item.companyDomain,
          }}
        />
        <ContactIndustry
          industry={item.industry}
          employeeCount={item.employeeCount}
          revenue={item.revenue}
          companyPhone={item.companyPhone}
        />
        <CompanySector paKeywords={item.paKeywords} />
        <CompanyFundingDetails
          lastFundingAmount={item.lastFundingAmount}
          lastFundingDate={item.lastFundingDate}
          lastFundingType={item.lastFundingType}
          totalFundingAmount={item.totalFundingAmount}
        />
      </div>
    </div>
  );
});

function ContactList(props) {
  const { savedSearchId, isListLoading = false } = props;

  const { userInfo } = userDetail;

  const purchaseContact = (id) => {
    Utils.totangoTrack(
      CONSTANTS.TOTANGO.EVENTS.PURCHASE,
      CONSTANTS.TOTANGO.MODULES.LB,
    );
    if (userInfo.userStatus === 0) {
      if (userInfo.lbCredits <= 0 || !Utils.isCreditsWithinMonthlyLimit()) {
        Utils.showCreditExpiredPopup('views');
        Utils.mixpanelTrack(MX_AS_EVENTS.AS_VIEWS_EXPIRED, {});
      } else {
        advSearchState.purchaseEmail(id);
      }
    } else {
      Utils.showEmailConfirmationPopup(userInfo.email);
    }
  };

  const purchaseMobile = (id) => {
    if ((userDetail.userInfo?.memberSubscription?.phoneCredits ?? 0) === 0) {
      Utils.mixpanelTrack(
        CONSTANTS.MOBILE_NUMBER_EVENTS.MOBILE_NUMBER_UPGRADE_POPUP_SHOWN,
      );
      commonUpgradePopupState.setShowUpgradePopup(
        CONSTANTS.UPGRADE_TRIGGERS.PURCHASE_PHONE,
      );
    } else if (userDetail.userInfo.phoneCreditsRemaining === 0) {
      advSearchState.showPhoneCreditsExhaustedPopup();
    } else {
      advSearchState.purchaseMobileNumber(id);
    }
  };

  const handleMaskedContactInfoClick = (id) => {
    Utils.mixpanelTrack(MX_AS_EVENTS.AS_PHONE_SCORE_LOCK_CLICK, {
      contactId: id,
    });
    purchaseContact(id);
  };

  const contactSelection = (index) => {
    if (
      advSearchState.selectedAllContactAcrossPage ||
      advSearchState.selectedContactFromRange
    ) {
      const deselectCallback = () => {
        advSearchState.updateSelectedBulkContactAll(true);
      };
      showUnableToDeselectModal(deselectCallback);
    } else {
      advSearchState.updateSelectedBulkContactIds(index);
    }
  };

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isToShowBanner =
      (Utils.getLocalStorage('saved-search-banner') === null ||
        advSearchState.searchResultCount < 50) &&
      !savedSearchId;
    if (isToShowBanner) {
      Utils.mixpanelTrack(CONSTANTS.MX_SAVED_SEARCH_BANNER.SHOWN, {
        resultsCount: advSearchState.searchResultCount,
      });
    }
    setShowBanner(isToShowBanner);
  }, [savedSearchId, advSearchState.searchResultCount]);

  return (
    <div
      className={`contact-list${isListLoading ? ' contact-list-loading-placeholder' : ''}`}
    >
      <ContactHeader />
      {isListLoading ? (
        <ContactListLoadingPlaceHolder />
      ) : (
        <>
          {showBanner && (
            <div className="saved-search-banner">
              <div className="icon-with-text">
                <div className="icon-wrap">
                  <AlertIcon className="alert-icon" />
                </div>
                <div className="text">
                  Want more leads? Create Alerts and get notified when we find
                  more leads for you.
                </div>
              </div>
              <Button
                buttonProps={{
                  text: 'Create Alert',
                  className: 'create-alert',
                }}
                onClick={() => {
                  Utils.setLocalStorage('saved-search-banner', true);
                  setShowBanner(false);
                  if (featureFlagsAndPreferencesState.isSavedSearchEnabled()) {
                    savedSearchPopupState.setData({});
                    savedSearchPopupState.setShowModal(true);
                    Utils.mixpanelTrack(
                      CONSTANTS.MX_SAVED_SEARCH_BANNER.SAVE_SEARCH_CLICKED,
                      { resultsCount: advSearchState.searchResultCount },
                    );
                  } else {
                    commonUpgradePopupState.setShowUpgradePopup(
                      CONSTANTS.UPGRADE_TRIGGERS.SAVED_SEARCH,
                    );
                  }
                }}
              />
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  Utils.setLocalStorage('saved-search-banner', true);
                  setShowBanner(false);
                  Utils.mixpanelTrack(CONSTANTS.MX_SAVED_SEARCH_BANNER.CLOSED, {
                    resultsCount: advSearchState.searchResultCount,
                  });
                }}
              />
            </div>
          )}
          <div className="contact-list-wrap">
            {advSearchState.modifiedSearchResults.map((item, index) => {
              return (
                <ListItemView
                  key={item.id}
                  item={item}
                  index={index}
                  contactSelection={contactSelection}
                  purchaseContact={purchaseContact}
                  handleMaskedContactInfoClick={handleMaskedContactInfoClick}
                  purchaseMobile={purchaseMobile}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export { ContactListLoadingPlaceHolder };

export default observer(ContactList);
