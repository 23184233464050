import React from 'react';
import { observer } from 'mobx-react';
import TrialPagination from 'Assets/png/trial-pagination.png';
import ArrowIcon from 'Assets/svg/right-arrow.svg';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import Modal, { ModalState } from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

const freeUserPaginationPopupState = new ModalState();

function TrialUserPaginationPopup() {
  const closePopup = () => {
    freeUserPaginationPopupState.setShowModal(false);
  };

  const upgradeNowBtnClick = () => {
    Utils.mixpanelTrack(
      CONSTANTS.MX_AS_EVENTS.LB_AS_PAGINATION_UPGRADE_NOW,
      {},
    );
    closePopup();
    Utils.showUserPricingPopup(true, 'pagination', false);
  };

  return (
    freeUserPaginationPopupState.showModal && (
      <Modal
        show
        className="trial-user-pagination-upgrade-popup"
        closeModal={closePopup}
      >
        <div className="img-wrap">
          <img src={TrialPagination} alt="trial user pagination" />
        </div>
        <p>
          Only first 50 results are available as a free user. Please purchase
          a plan to access more.
        </p>
        <Button
          buttonProps={{ text: 'Upgrade Now', className: 'upgrade' }}
          Icon={ArrowIcon}
          onClick={upgradeNowBtnClick}
        />
      </Modal>
    )
  );
}

export { freeUserPaginationPopupState };

export default observer(TrialUserPaginationPopup);
