import React from 'react';
import { observer } from 'mobx-react';
import CloseIcon from 'Assets/svg/close-material.svg';
import Dropdown from 'Components/common/base/Dropdown';
import './styles.scss';

function CompanySector(props) {
  const { paKeywords } = props;

  const itemsToShow = paKeywords?.slice(0, 2);

  return (
    <div className="tech-sector-list">
      {(paKeywords || []).length > 0 ? (
        <div className="inner-wrapper">
          {itemsToShow.map((keyword, index) => {
            return (
              <React.Fragment key={keyword}>
                <div className="sector show-ellipsis" title={keyword}>
                  {keyword}
                </div>
                {paKeywords.length - itemsToShow.length > 0 &&
                  itemsToShow.length - 1 === index && (
                    <Dropdown
                      showArrow
                      className="sector-list-dropdown"
                      fixedPosition
                      offsetValues={{ top: 200, bottom: 20 }}
                      toggleElement={
                        <span className="show-more">
                          +{paKeywords.length - itemsToShow.length} More
                        </span>
                      }
                    >
                      <div className="sector-list-header">All Sectors</div>
                      <CloseIcon className="close-icon" data-close="true" />
                      <div className="sector-list-wrapper">
                        <div className="sector-list">
                          {paKeywords.map((paKeyword) => {
                            return <div key={paKeyword}>{paKeyword}</div>;
                          })}
                        </div>
                      </div>
                    </Dropdown>
                  )}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        '- NA -'
      )}
    </div>
  );
}

export default observer(CompanySector);
