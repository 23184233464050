import React, { useEffect, useState } from 'react';
import Button from 'Components/common/base/Button';
import ControlInput from 'Components/common/base/ControlInput';
import Input from 'Components/common/base/Input';
import './styles.scss';

function DropdownOption(props) {
  const {
    selectedTeamMembers,
    setSelectedTeamMembers,
    teamMemberLimit,
    setError,
    setActiveRadio,
    ...remainingProps
  } = props;

  const { memberId } = remainingProps;

  const isSelected = selectedTeamMembers.some((member) => {
    return member.memberId === memberId;
  });
  const isNameNotAvailable =
    !remainingProps.firstName && !remainingProps.lastName;

  const valueOfName = isNameNotAvailable
    ? remainingProps.emailId
    : `${remainingProps.firstName || ''} ${remainingProps.lastName || ''}`;

  return (
    <li
      className={`option${
        remainingProps.status === 'INACTIVE' ? ' inactive' : ''
      }`}
      onClick={() => {
        if (isSelected) {
          setSelectedTeamMembers(
            selectedTeamMembers.filter((member) => {
              return member.memberId !== memberId;
            }),
          );
          setActiveRadio('');
        } else if (selectedTeamMembers.length < teamMemberLimit) {
          setSelectedTeamMembers([...selectedTeamMembers, remainingProps]);
          setActiveRadio('');
        } else if (selectedTeamMembers.length === teamMemberLimit) {
          setError(
            `You can select a maximum of ${teamMemberLimit} team members at a time`,
          );
        }
      }}
    >
      <ControlInput
        type="checkbox"
        label={
          <div className="option-text">
            <div className="name show-ellipsis" title={valueOfName}>
              {valueOfName}
            </div>
            {!isNameNotAvailable && (
              <div
                className="email show-ellipsis"
                title={remainingProps.emailId}
              >
                {remainingProps.emailId}
              </div>
            )}
          </div>
        }
        checked={isSelected}
      />
      {remainingProps.status === 'INACTIVE' && (
        <span className="status-badge">
          {remainingProps.status.toLowerCase()}
        </span>
      )}
    </li>
  );
}

function TeamMembersDropdown(props) {
  const {
    onApply,
    teamMembers,
    selectedTeamMembers: defaultValue,
    teamMemberLimit,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(defaultValue);
  const [error, setError] = useState('');
  const [activeRadio, setActiveRadio] = useState('');

  const searchMatch = new RegExp(searchTerm, 'ig');

  useEffect(() => {
    if (error !== '') {
      setTimeout(() => {
        setError('');
      }, 3500);
    }
  }, [error]);

  const activeUsers = teamMembers.filter((member) => {
    return member.status === 'ACTIVE';
  });

  return (
    <div className="team-members-dropdown">
      <div className="search-wrapper">
        <Input
          showSearchIcon
          inputProps={{ placeholder: 'Search user by name or email' }}
          onChange={(value) => {
            return setSearchTerm(value);
          }}
        />
      </div>
      <div className="dropdown-options">
        <i className="text">
          You can select a maximum of {teamMemberLimit} users.
        </i>
        <ul className="option-list">
          {teamMembers
            .filter((member) => {
              return (
                member.emailId.match(searchMatch) ||
                member.firstName.match(searchMatch) ||
                member.lastName.match(searchMatch)
              );
            })
            .map((teamMember) => {
              return (
                <DropdownOption
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...teamMember}
                  key={`${teamMember.emailId}`}
                  setError={setError}
                  setActiveRadio={setActiveRadio}
                  teamMemberLimit={teamMemberLimit}
                  selectedTeamMembers={selectedTeamMembers}
                  setSelectedTeamMembers={setSelectedTeamMembers}
                />
              );
            })}
        </ul>
      </div>
      <div className="radio-wrapper">
        <ControlInput
          type="radio"
          label="All Active Users"
          value="All Active Users"
          onChange={() => {
            if (activeUsers.length > teamMemberLimit) {
              setError(
                `You can select a maximum of ${teamMemberLimit} team members at a time`,
              );
            } else {
              setSelectedTeamMembers(activeUsers);
              setActiveRadio('ACTIVE');
            }
          }}
          checked={activeRadio === 'ACTIVE'}
        />
        <ControlInput
          type="radio"
          label="All Users"
          value="All Users"
          onChange={() => {
            if (teamMembers.length > teamMemberLimit) {
              setError(
                `You can select a maximum of ${teamMemberLimit} team members at a time`,
              );
            } else {
              setSelectedTeamMembers(teamMembers);
              setActiveRadio('ALL');
            }
          }}
          checked={activeRadio === 'ALL'}
        />
      </div>
      <div className="button-wrapper">
        <Button
          variant="outline"
          buttonProps={{ text: 'Cancel', customData: { close: 'true' } }}
          data-close="true"
        />
        <Button
          buttonProps={{ text: 'Apply', customData: { close: 'true' } }}
          data-close="true"
          onClick={() => {
            onApply(selectedTeamMembers);
          }}
        />
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

export default TeamMembersDropdown;
