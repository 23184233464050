import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import SalesforceExportIcon from 'Assets/png/export_icons/salesforce_export.png';
import CsvExportIcon from 'Assets/png/export_icons/csv_export.png';
import OwnershipMappingPopup from 'Components/common/OwnershipMapping';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import companySearchState from 'Stores/companySearch';
import {
  showAssignOwnersPopup,
  showExportFailureLimitPopup,
  showExportStatusPopup,
} from './modals';
import ChooseExportModal from '../ChooseExportModal';
import ownershipMappingState from '../OwnershipMapping/state';
import { commonUpgradePopupState } from '../CommonUpgradePopup';

const { CONTACT_EXPORT_TYPES } = CONSTANTS;
const { DIRECT_EXPORT } = CONTACT_EXPORT_TYPES;

const { COMPANY_EXPORT_TYPE, CRM_EXPORT_TYPE } = CONSTANTS;

const exportOptionList = [
  {
    text: COMPANY_EXPORT_TYPE.CSV,
    imageURL: CsvExportIcon,
  },
  {
    text: CRM_EXPORT_TYPE.SALESFORCE,
    imageURL: SalesforceExportIcon,
  },
];

const defaultExportMxData = {
  pageType: 'AS', // AS/ML
  searchType: 'COMPANY',
};
class ExportCompaniesState {
  @observable accessor exportOption = '';

  @observable accessor callBack;

  @observable accessor showCrmExportOptions = false;

  @observable accessor showProcessingExport = false;

  @observable accessor exportPostData = {};

  @observable accessor exportMxData = defaultExportMxData;

  @action
  setExportOption = (val) => {
    this.exportOption = val;
  };

  @action
  setShowCrmExportOptions = (val) => {
    this.showCrmExportOptions = val;
  };

  @action
  setExportPostData = (val = {}) => {
    this.exportPostData = val;
  };

  @action
  setExportMxData = (val = defaultExportMxData) => {
    this.exportMxData = { ...this.exportMxData, ...val };
  };

  @action
  setShowProcessingExport = (value) => {
    this.showProcessingExport = value;
  };

  @action
  checkAndexport = (
    selectedType,
    source,
    exportOption = 'export selected',
    isExportEnabled,
  ) => {
    const thisObj = this;
    this.exportPostData = {};

    if (isExportEnabled) {
      Utils.mixpanelTrack(
        CONSTANTS.MX_LB_EXPORT_EVENTS.LB_CLICK_SELECT_EXPORT_TYPE,
        { exportType: selectedType, ...thisObj.exportMxData },
      );
      if (selectedType === 'CSV') {
        this.exportCompaniesToCSV(selectedType, source, exportOption);
      } else if (selectedType === 'Salesforce') {
        this.exportCompaniesToSalesforce();
      }
    } else {
      Utils.mixpanelTrack(
        CONSTANTS.MX_LB_EXPORT_EVENTS.LB_CLICK_EXPORT_UPGRADE,
        {
          exportType: selectedType,
          ...thisObj.exportMxData,
        },
      );
      if (selectedType === COMPANY_EXPORT_TYPE.CSV) {
        Utils.showUserPricingPopup(true, 'csv_export', false);
      } else {
        Utils.showUserPricingPopup(true, 'crm_export', false);
      }
    }
  };

  @action
  exportCompaniesMakeApiCall = (apiUrl, callBack) => {
    const config = {
      url: apiUrl,
      method: 'POST',
      data: this.exportPostData,
    };
    makeApi(config)
      .then((response) => {
        callBack(response);
      })
      .catch((error) => {
        console.log('exportCompaniesMakeApiCall error ', error);
      });
  };

  @action
  constructExportStatusPopup = (
    statusType = 'Success',
    result,
    selectedSrc = '',
    customMsg,
  ) => {
    companySearchState.clearAllSelection();
    this.showProcessingExport = false;

    showExportStatusPopup({
      status: statusType,
      result,
      exportType: selectedSrc,
      customMsg,
      exportMxData: this.exportMxData,
    });
  };

  @action
  exportCompaniesToCSV = () => {
    let exportCompanyCount = 1;
    let exportCsvUrl = URLS.companyExport.exportSelectedCompaniesToCSV;
    if (companySearchState.hugeSelectionBulkIds.length > 0) {
      exportCompanyCount = companySearchState.hugeSelectionBulkIds.length;
      this.exportPostData = {
        ...companySearchState.bulkSelectionPostData,
      };
      exportCsvUrl = URLS.companyExport.exportBulkCompaniesToCSV;
    } else {
      exportCompanyCount = companySearchState.bulkPurchaseIds.length;
      this.exportPostData = {
        companyIds: companySearchState.bulkPurchaseIds,
      };
    }
    this.exportCompaniesToCSVFlow(exportCompanyCount, exportCsvUrl);
  };

  @action
  exportCompaniesToSalesforce = (accountOwners) => {
    const exportSalesforceUrl = Utils.generateUrl(URLS?.crmExport?.export, {
      crmType: 'SALESFORCE',
      type: DIRECT_EXPORT,
      source: 'ADVANCED_SEARCH',
    });

    const companyIds =
      companySearchState.hugeSelectionBulkIds.length > 0
        ? companySearchState.hugeSelectionBulkIds
        : companySearchState.bulkPurchaseIds;

    if (companyIds.length > companySearchState.salesforceExportMaxLimit) {
      this.exportFailureLimit();
      return;
    }
    this.showProcessingExport = true;
    this.exportPostData = {
      companyIds: JSON.stringify(
        companySearchState.hugeSelectionBulkIds.length > 0
          ? companySearchState.hugeSelectionBulkIds
          : companySearchState.bulkPurchaseIds,
      ),
      exportSource: 'advancedSearch',
      export_type: 'Salesforce',
      isEu: false,
      now: Date.now(),
      requestType: 'DIRECT_EXPORT',
      source: 'salesLift',
      isCompanyExport: true,
    };
    const salesforceExportCallback = (res) => {
      this.showProcessingExport = false;
      this.exportCompaniesToCrmResponseHandler(res, 'Salesforce');
    };
    const { exportPostData } = this;
    if (accountOwners !== undefined && accountOwners !== '') {
      exportPostData.accountOwners = accountOwners;
    }

    this.exportPostData = exportPostData;
    this.exportCompaniesMakeApiCall(
      exportSalesforceUrl,
      salesforceExportCallback,
    );
  };

  @action
  assignOwnerShipToSalesforce = () => {
    const thisObj = this;
    const assignedOwners = (postData) => {
      Utils.mixpanelTrack(
        CONSTANTS.MX_LB_EXPORT_EVENTS.LB_SF_EXPORT_ASSIGN_OWNERS,
        {
          isAllowed: 'Yes',
          ...thisObj.exportMxData,
        },
      );
      thisObj.exportCompaniesToSalesforce(postData);
    };
    const withoutAssignOwners = () => {
      Utils.mixpanelTrack(
        CONSTANTS.MX_LB_EXPORT_EVENTS.LB_SF_EXPORT_ASSIGN_OWNERS,
        {
          isAllowed: 'Yes',
          ...thisObj.exportMxData,
        },
      );
      thisObj.exportCompaniesToSalesforce({});
    };
    ownershipMappingState.setExportType('Salesforce');
    ownershipMappingState.setExportSource(
      CONSTANTS.EXPORT_SOURCE.ADVANCED_SEARCH,
    );
    ownershipMappingState.setPostData(this.exportPostData);
    ownershipMappingState.setExportCallback(assignedOwners);
    ownershipMappingState.setCancelCallback(withoutAssignOwners);
    ownershipMappingState.setCompanyExport(true);
    ownershipMappingState.setShowPopup(true);
  };

  @action
  askAssignOwnerShipPopup = (exportType) => {
    const thisObj = this;
    const assignOwnerShipCallback = () => {
      if (exportType === 'Salesforce') {
        thisObj.assignOwnerShipToSalesforce();
      }
    };
    const doNotAssignOwnerShipCallback = () => {
      if (exportType === 'Salesforce') {
        Utils.mixpanelTrack(
          CONSTANTS.MX_LB_EXPORT_EVENTS.LB_SF_EXPORT_ASSIGN_OWNERS,
          {
            isAllowed: 'No',
            ...thisObj.exportMxData,
          },
        );
        thisObj.exportCompaniesToSalesforce({});
      }
    };

    showAssignOwnersPopup({
      assignOwnerShipCallback,
      doNotAssignOwnerShipCallback,
      exportType,
      saveType: thisObj.exportPostData.save_type,
    });
  };

  @action
  showPreferencePopUp = () => {
    this.askAssignOwnerShipPopup('Salesforce');
  };

  @action
  exportCompaniesToCrmResponseHandler = (res, crmType) => {
    const thisObj = this;
    if (res && res.data) {
      const response = res.data;
      const crmTypeVal = crmType && thisObj.exportPostData.export_type;
      const { responseCode = '', responseData } = response;
      switch (responseCode) {
        case 303:
        case 412:
        case 407:
          Utils.showCrmConnectionError({
            showPopup: true,
            crmType: crmTypeVal,
            ...response,
            ...responseData,
            redirectToIntegrations: responseCode === 303,
          });
          break;
        case 417: {
          const { inputRequired } = responseData;
          if (inputRequired === 'ACCOUNT_OWNERS') {
            this.askAssignOwnerShipPopup(crmTypeVal);
          }
          if (inputRequired === 'CRM_PREFERENCES') {
            if (crmType === CRM_EXPORT_TYPE.SALESFORCE) {
              this.showPreferencePopUp(crmType);
            }
          }
          break;
        }
        case 200:
          {
            const {
              duplicateCount,
              failedCount,
              newlyAddedCount,
              successCount,
              totalCount,
              isCreditLimitExhausted = false,
            } = response || {};

            const resData = {
              responseCode: 200,
              exportSuccessCount: successCount,
              newDownloadedCount: newlyAddedCount,
              exportFailedCount: failedCount,
              duplicateCompanyCount: duplicateCount,
              creditsReduced: newlyAddedCount,
              totalCount,
              isCreditLimitExhausted,
            };
            this.constructExportStatusPopup('Success', resData, crmType);
            companySearchState.updateSelectedBulkContactAll(true);
          }
          break;
        case 402: {
          this.showProcessingExport = false;
          companySearchState.updateSelectedBulkContactAll(true);
          break;
        }
        default:
          this.constructExportStatusPopup('Failed', {}, crmType);
          break;
      }
    } else {
      this.constructExportStatusPopup('Failed', {}, crmType);
    }
  };

  @action
  exportCompaniesResponseHandler = (res, exportType) => {
    const thisObj = this;

    if (res && res.data) {
      const response = res.data;
      if (response.responseCode === 200) {
        const exportTypeVal =
          exportType !== undefined
            ? exportType
            : thisObj.exportPostData.export_type;
        if (exportTypeVal === 'CSV') {
          Utils.downloadToCsv(
            response,
            `AdaptCompanyExport-${Utils.getExportDateString()}`,
          );
        }

        companySearchState.updateSelectedBulkContactAll(true);
        this.constructExportStatusPopup('Success', response, exportType);
      } else if (response.error === 'limit') {
        this.showProcessingExport = false;
        companySearchState.updateSelectedBulkContactAll(true);
        const errorMsg =
          'Sorry, you can download max of 2000 companies per transaction.';
        this.constructExportStatusPopup('Failed', {}, exportType, errorMsg);
        companySearchState.updateSelectedBulkContactAll(true);
        this.showProcessingExport = false;
      } else if (
        response.responseCode === 500 &&
        response.exportSuccessCount &&
        parseInt(response.exportSuccessCount, 10) > 0 &&
        response.exportFailedCount &&
        parseInt(response.exportFailedCount, 10) > 0
      ) {
        const errorMsg = `We were able to export only ${parseInt(
          response.exportSuccessCount,
          10,
        )} companies to ${exportType}. Please try again to export the remaining companies.`;
        this.constructExportStatusPopup('Failed', {}, exportType, errorMsg);
        companySearchState.updateSelectedBulkContactAll(true);
      } else if (response.responseCode === 500) {
        if (response.errorMsg !== null && response.errorMsg !== undefined) {
          this.constructExportStatusPopup(
            'Failed',
            {},
            exportType,
            response.errorMsg,
          );
        } else {
          this.constructExportStatusPopup('Failed', {}, exportType);
        }
        companySearchState.updateSelectedBulkContactAll(true);
      } else {
        this.constructExportStatusPopup('Failed', {}, exportType);
        companySearchState.updateSelectedBulkContactAll(true);
      }
    } else {
      this.constructExportStatusPopup('Failed', {}, exportType);
      companySearchState.updateSelectedBulkContactAll(true);
    }
  };

  @action
  exportCompaniesToCSVFlow = (exportCompanyCount, exportCsvUrl) => {
    this.showProcessingExport = true;

    const csvExportCallback = (res) => {
      this.exportCompaniesResponseHandler(res, 'CSV');
    };

    this.exportCompaniesMakeApiCall(exportCsvUrl, csvExportCallback);
  };

  @action
  exportFailureLimit = (str, exportCompanyCount) => {
    Utils.mixpanelTrack(
      CONSTANTS.MX_LB_EXPORT_EVENTS.LB_VIEW_EXPORT_LIMIT_EXCEED,
      {
        exportType: str,
        triedLimit: exportCompanyCount,
        currentLimit: companySearchState.salesforceExportMaxLimit,
        ...this.exportMxData,
      },
    );

    showExportFailureLimitPopup(companySearchState.salesforceExportMaxLimit);
  };
}

const exportCompaniesState = new ExportCompaniesState();

function ExportCompanies() {
  const selectExportType = (exportType, isExportEnabled) => {
    if (isExportEnabled) {
      exportCompaniesState.checkAndexport(
        exportType,
        'salesLift',
        'export selected',
        isExportEnabled,
      );
    } else {
      commonUpgradePopupState.setShowUpgradePopup(
        exportType === COMPANY_EXPORT_TYPE.CSV
          ? CONSTANTS.UPGRADE_TRIGGERS.CSV_EXPORT
          : CONSTANTS.UPGRADE_TRIGGERS.CRM_EXPORT,
      );
    }
    exportCompaniesState.setShowCrmExportOptions(false);
  };

  return (
    <>
      <OwnershipMappingPopup />
      {exportCompaniesState.showCrmExportOptions && (
        <ChooseExportModal
          closeModal={() => {
            exportCompaniesState.setShowCrmExportOptions(false);
          }}
          title="Export Companies"
          list={exportOptionList.map((item) => {
            const isCSV = item.text === COMPANY_EXPORT_TYPE.CSV;
            return {
              ...item,
              callback: selectExportType,
              optionEnabled: isCSV
                ? featureFlagsAndPreferencesState.isCSVExportEnabled()
                : featureFlagsAndPreferencesState.isCRMExportEnabled(),
            };
          })}
        />
      )}
    </>
  );
}

export { exportCompaniesState };

export default observer(ExportCompanies);
