import React from 'react';
import RightArrow from 'Assets/svg/right-arrow.svg';
import PeospectrumLogo from 'Assets/png/payg_pricing/peospectrum.png';
import PracticalBusinessLogo from 'Assets/png/payg_pricing/practical-business.png';
import KineticLogo from 'Assets/png/payg_pricing/kinetic.png';
import ResourcingEdgeLogo from 'Assets/png/payg_pricing/resourcing-edge.png';

import Lori from 'Assets/png/payg_pricing/lori.png';
import Locke from 'Assets/png/payg_pricing/locke.png';
import Dondi from 'Assets/png/payg_pricing/dondi.png';
import Robert from 'Assets/png/payg_pricing/robert.png';
import { WEB_URL } from 'Utils/constants';
import './styles.scss';

const caseStudyList = [
  {
    logoURL: PeospectrumLogo,
    altText: 'Peospectrum Logo',
    reviewText: '30% increase in email success',
    authorName: 'Lori Frucht',
    authorTitle: 'Marketing Director',
    authorCompany: '',
    link: `${WEB_URL}/customer-stories/peo-spectrum/`,
    authorImage: Lori,
  },
  {
    logoURL: PracticalBusinessLogo,
    altText: 'Practical Business Improvements Logo',
    reviewText: 'Prospecting productivity increased by 40%',
    authorName: 'Locke Gilmour',
    authorTitle: 'Lean Coach',
    authorCompany: '',
    link: `${WEB_URL}/customer-stories/practical-business-improvements/`,
    authorImage: Locke,
  },
  {
    logoURL: KineticLogo,
    altText: 'Kinetic logo',
    reviewText: '25% increase in our cold prospecting revenue.',
    authorName: 'Dondi Alentajan',
    authorTitle: 'VP-Sales & Marketing',
    authorCompany: '',
    link: `${WEB_URL}/customer-stories/kinetic-innovative-staffing/`,
    authorImage: Dondi,
  },
  {
    logoURL: ResourcingEdgeLogo,
    altText: 'Resourcing edge logo',
    reviewText: 'Adapt offers the best overall value!',
    authorName: 'Robert Calderella',
    authorTitle: 'Business Development',
    authorCompany: '',
    link: `${WEB_URL}/customer-stories/resourcing-edge/`,
    authorImage: Robert,
  },
];

function Card(props) {
  const {
    logoURL,
    altText,
    reviewText,
    authorName,
    authorTitle,
    authorCompany,
    link,
    authorImage,
  } = props;

  return (
    <div className="card-wrapper">
      <div className="card-wrapper-inner">
        <div className="image-wrap">
          <img src={logoURL} alt={altText} className="company-logo" />
        </div>
        <div className="card-title">{reviewText}</div>
        <div className="bottom-wrapper">
          <div className="author-section">
            <div className="name">{authorName}</div>
            <div className="about-author">
              {authorTitle}
              {authorCompany ? `, ${authorCompany}` : ''}
            </div>
            <a
              href={link}
              target="_blank"
              className="read-more-link"
              aria-label={`More descriptive text about ${authorTitle} case study`}
              rel="noreferrer"
            >
              Read More <RightArrow className="right-arrow" />
            </a>
          </div>
          <div className="author-image">
            <img src={authorImage} alt={authorName} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CaseStudyList() {
  return (
    <div className="case-study-section">
      <div className="case-study-wrapper">
        <h2>What our customers are saying</h2>
        <section className="card-list">
          {caseStudyList.map((caseStudy) => {
            return <Card {...caseStudy} key={caseStudy.authorName} />;
          })}
        </section>
      </div>
    </div>
  );
}

export default CaseStudyList;
