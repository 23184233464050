import React from 'react';
import { Link } from 'react-router-dom';
import LeftArrowIcon from 'Assets/svg/new_icons/left-arrow.svg';
import './styles.scss';
import { EMPTY_FUNCTION } from 'Utils/constants';

function BackIconWithText(props) {
  const { text, href, onClick = EMPTY_FUNCTION, state } = props;

  return (
    <div className="back-icon-with-text">
      <Link to={href} className="icon-wrap" onClick={onClick} state={state}>
        <LeftArrowIcon className="left-arrow" />
      </Link>
      <h1>{text}</h1>
    </div>
  );
}

export default BackIconWithText;
