import React, { useState } from 'react';
import AddToSequencePopup from 'Components/salesgear/sequence/AddToSequence';
import { toasterState } from 'Components/common/base/Toaster';
import BulkContactValidationPopup from 'Components/salesgear/sequence/BulkValidationPopup';
import myLeadsState from 'Stores/myLeads';
import { makeApi, URLS } from 'Utils/apiUtil';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import { MX_EVENTS_AE } from 'Utils/constants';
import SelectExportType from './SelectExportType';
import './styles.scss';

function SalesgearExportCRM(props) {
  const {
    hide,
    option,
    upgrade,
    loader,
    resultResponse,
    limit,
    showConnectInbox,
    clearSelectedFields,
  } = props;

  const [addSequence, setAddSequence] = useState(true);
  const [showAeSequencePopup, setShowAeSequencePopup] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [selectExport, setSelectedExport] = useState('sequences');
  const [exportData, setExportData] = useState('');
  const [showBulkContactValidationPopup, setShowBulkContactValidationPopup] =
    useState(false);

  const [selectedSeqId, setSelectedSeqId] = useState('');

  const closePopup = () => {
    if (clearSelectedFields) {
      clearSelectedFields();
    }
    hide(false);
  };

  const getToggleValue = (id) => {
    if (id === 'addToSeq') {
      setSelectedExport('sequences');
      setAddSequence(!addSequence);
      setAddContact(false);
    } else {
      setSelectedExport('contacts');
      setAddContact(!addContact);
      setAddSequence(false);
    }
  };

  const checkExportLimit = () => {
    if (myLeadsState.leadsResultCount > 5000) {
      return false;
    }
    return true;
  };

  const getContactsIdToExport = () => {
    const payload = {};
    if (option === 'export selected') {
      payload.contactIds = Utils.extractValuesFromObject(
        myLeadsState.bulkPurchaseIds,
      );
    } else if (myLeadsState.selectedList.name !== 'All Leads') {
      if (checkExportLimit()) {
        payload.listId = myLeadsState.selectedList.id;
      } else {
        limit(myLeadsState.leadsResultCount);
        return false;
      }
    } else if (checkExportLimit()) {
      payload.listId = 'all';
    } else {
      limit(myLeadsState.leadsResultCount);
      return false;
    }
    return payload;
  };

  const upgradePopupLB = () => {
    loader(false);
    if (
      userDetail.userInfo.memberSubscription !== null &&
      userDetail.userInfo.memberSubscription !== undefined
    ) {
      Utils.showCreditExpiredPopup('downloads');
    } else {
      Utils.showUserPricingPopup(true, 'downloads', false);
    }
  };

  const upgradePopupAE = (data, exportSuccess) => {
    const value = {
      name: 'export',
      exportSuccess,
    };
    if (Object.keys(data).length > 0) {
      if (data.contactIds && data.contactIds.length > 0) {
        value.count = data.contactIds.length;
      } else {
        value.count = myLeadsState.leadsResultCount;
      }
    }
    upgrade(value);
  };

  const reduceDownloads = (res) => {
    const { lbCredits } = userDetail.userInfo;
    const { creditsReduced } = res;

    const reducedCount = creditsReduced && parseInt(creditsReduced, 10);
    if (lbCredits && lbCredits >= reducedCount) {
      userDetail.updateUserInfo({
        lbCredits: lbCredits - reducedCount,
      });
    }
  };

  const exportToContacts = async (data = {}) => {
    try {
      const config = {
        url: URLS.salesgear.exportToContact,
        method: 'POST',
        data,
      };

      const res = await makeApi(config);
      if (
        res &&
        res.data &&
        Object.keys(res).length > 0 &&
        res.data.status === 200 &&
        res.data.errorType !== 'LIMIT_REACHED'
      ) {
        reduceDownloads(res.data);
        resultResponse(res.data);
      } else if (res.data.status === 101) {
        upgradePopupLB();
      } else if (
        res.data.status === 509 ||
        res.data.errorType === 'LIMIT_REACHED'
      ) {
        if (res.data.exportSuccessCount > 0) {
          reduceDownloads(res.data);
          upgradePopupAE(data, res.data.exportSuccessCount);
        } else {
          upgrade('');
        }
      } else {
        resultResponse('failed', res.data);
      }
      console.log(res);
    } catch (err) {
      console.error(err, 'Failed to Export Contact in SALESGEAR CRM');
    }
  };

  const showSequencePopup = (data) => {
    setShowAeSequencePopup(true);
    setExportData(data);
  };

  const continueExport = () => {
    if (selectExport === 'sequences') {
      showSequencePopup(getContactsIdToExport());
      Utils.mixpanelTrack(MX_EVENTS_AE.addSequenceClicked, {
        type: selectExport,
      });
    } else {
      loader(true);
      Utils.mixpanelTrack(MX_EVENTS_AE.addContactClicked, {
        type: selectExport,
      });
      exportToContacts(getContactsIdToExport());
    }
  };

  const handleSequenceResponse = (res) => {
    const respData = res && res.data;
    const respStatus = respData && respData.status;
    const respErrorType = respData && respData.errorType;
    const respSuccessCount = respData && respData.exportSuccessCount;
    if (respData && respStatus === 200 && respErrorType === null) {
      reduceDownloads(respData);
      resultResponse(respData);
      if (respSuccessCount) {
        Utils.mixpanelTrack(MX_EVENTS_AE.addedToSequence);
        Utils.mixpanelTrack(MX_EVENTS_AE.aeSequenceStarted, {
          pageType: 'Lead Builder',
        });
      }
    } else if (respStatus === 101) {
      upgradePopupLB();
    } else if (respStatus === 509 || respErrorType === 'LIMIT_REACHED') {
      if (respData.exportSuccessCount > 0) {
        reduceDownloads(respData);
        upgradePopupAE(exportData, respData.exportSuccessCount);
      } else {
        upgrade('');
      }
    } else if (respErrorType === 'FROM_ADDRESS_EMPTY') {
      showConnectInbox(true);
    } else if (respErrorType === 'SEQUENCE_EMPTY_STAGE') {
      loader(false);
      toasterState.setToastMsg(
        'Add stages before adding contacts to a sequence',
      );
    } else {
      resultResponse('failed', respData);
    }
  };

  const showBulkValidationPopup = async (selectedSequenceId) => {
    if (exportData) {
      setShowAeSequencePopup(false);
      setSelectedSeqId(selectedSequenceId);
      setShowBulkContactValidationPopup(true);
    }
  };

  const bulkContactAddToSeq = async (validContacts) => {
    if (validContacts && validContacts.length) {
      try {
        loader(true);
        const payload = {
          contactIds: validContacts,
          sequenceId: selectedSeqId,
          openTrack: true,
          linkTrack: true,
          force: true,
        };
        const config = {
          url: URLS.salesgear.exportToContact,
          method: 'POST',
          data: payload,
        };
        const res = await makeApi(config);
        if (clearSelectedFields) {
          clearSelectedFields();
        }
        if (res && res.status === 200) {
          handleSequenceResponse(res);
        } else {
          resultResponse('failed', res.data);
        }
      } catch (err) {
        console.error(err, 'Failed to add contact sequence');
      }
    } else {
      resultResponse('failed', {});
    }
  };

  return showAeSequencePopup ? (
    <AddToSequencePopup
      closePopup={closePopup}
      submitCbk={showBulkValidationPopup}
    />
  ) : showBulkContactValidationPopup ? (
    <BulkContactValidationPopup
      bulkContactAddToSeq={bulkContactAddToSeq}
      contactIds={exportData}
      closePopup={closePopup}
      selectedSeqId={selectedSeqId}
    />
  ) : (
    <SelectExportType
      closePopup={closePopup}
      addSequence={addSequence}
      getToggleValue={getToggleValue}
      addContact={addContact}
      continueExport={continueExport}
    />
  );
}

export default SalesgearExportCRM;
