import { observable, action } from 'mobx';
import TickIcon from 'Assets/svg/new_icons/tick-outline.svg';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';

class PricingDataState {
  @observable accessor showPaymentForm = false;

  @observable accessor isAnnualSelected = false;

  @observable accessor selectedPlan = null;

  @observable accessor isPriceCalculationLoading = false;

  @action
  setShowPaymentForm = (value = false) => {
    this.showPaymentForm = value;
    if (value) {
      document.getElementsByTagName('body')[0].classList.add('scrollHidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('scrollHidden');
    }
  };

  @action
  setSelectedPlan = (value) => {
    this.selectedPlan = value;
  };

  @action
  setAnnualSelected = (value = false) => {
    this.isAnnualSelected = value;
  };

  @action
  paymentSubmit = async (inputData, tokenObj, callback = () => {}) => {
    try {
      const thisObj = this;
      const { phone } = inputData;
      const { selectedPlan, isAnnualSelected } = thisObj;
      const {
        id: selectedPlanId,
        name: selectedPlanName,
        // price,
      } = selectedPlan;
      const { id: token } = tokenObj;

      const config = {
        url: URLS.purchasePlan,
        method: 'POST',
        data: {
          annual: isAnnualSelected,
          freeTrial: Utils.isFreeTrialPlan(),
          phone,
          planId: selectedPlanId,
          stripeToken: token,
        },
      };

      Utils.mixpanelTrack(
        CONSTANTS.MX_PRICING_PAGE.CLICK_LB_PRICING_PURCHASE_PLAN,
        {
          isAnnual: isAnnualSelected,
          phoneNumber: phone,
          selectedPlanType: selectedPlanName,
        },
      );
      const res = await makeApi(config, false);

      if (
        res &&
        res.data &&
        Object.keys(res.data).length > 0 &&
        res.data.status?.toLowerCase() === 'success'
      ) {
        Utils.mixpanelTrack(
          CONSTANTS.MX_PRICING_PAGE.LB_PRICING_PAYMENT_SUCCESS,
          {
            isAnnual: isAnnualSelected,
            phoneNumber: phone,
            selectedPlanType: selectedPlanName,
          },
        );
        thisObj.showPaymentForm = false;
        commonModalState.setShowModal(true, {
          modalProps: {
            align: 'center',
            className: 'payment-success-popup',
            closeModalOnOutsideClick: false,
          },
          Icon: TickIcon,
          title: 'Purchase Successful',
          description:
            'Thank you for choosing Adapt. You are now a part of our 500K+ prospector community. Happy Prospecting!',
          primaryButtonText: 'Ok',
          closeCallback: Utils.redirectToLbHomePage,
        });
      } else {
        let { message } = res.data || {};
        if (message === null || message === undefined) {
          message = 'Your payment has failed';
        }
        Utils.mixpanelTrack(
          CONSTANTS.MX_PRICING_PAGE.LB_PRICING_PAYMENT_FAILED,
          {
            isAnnual: isAnnualSelected,
            phoneNumber: phone,
            selectedPlanType: selectedPlanName,
            paymentFailedReason: message,
          },
        );
        callback(message);
      }
    } catch (err) {
      console.error(err, 'payment submit');
    }
  };
}

const pricingDataState = new PricingDataState();

export default pricingDataState;
