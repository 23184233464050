import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import TimerIcon from 'Assets/svg/timer-icon.svg';
import UpgradeIcon from 'Assets/svg/upgrade.svg';
import Button from 'Components/common/base/Button';
import { toasterState } from 'Components/common/base/Toaster';
import userDetail from 'Stores/userDetail';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import trialUserOnboardingState from 'Stores/trialUserOnboardingState';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import NotificationBar from './notificationBar';
import NavigationWrapper from './navigationWrapper';
import './styles.scss';
import AccountProfileWrapper from './accountProfileWrapper';

const { PAGE_PATH_MAP } = CONSTANTS;

function Header() {
  const { userInfo, showNotificationType = '' } = userDetail;
  const {
    enrichmentCreditsRemaining,
    lbCreditsIndividualLimitFrequency,
    remainingIndividualLbCredits,
  } = userInfo;

  const [trialApiLoading, setTrialApiLoading] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  const isPricingPage = () => {
    return pathname === '/pricing';
  };

  const handleUpgradeNowClick = () => {
    Utils.upgradeNowClick('Header');
  };

  const handleStartFreeTrialClick = () => {
    setTrialApiLoading(true);
    if (!trialApiLoading) {
      const failedCallback = () => {
        setTrialApiLoading(false);
        toasterState.setToastMsg(
          'Unable to process your request now, please try again later ',
          'failure',
        );
      };
      freeTrialPopupState.processTrialRequest(failedCallback);
    }
  };

  const isCreditLimitEnabled =
    featureFlagsAndPreferencesState.isTeamManageEnabled() &&
    lbCreditsIndividualLimitFrequency !== '';

  const showEnrichmentCreditsInHeader = pathname.includes('/enrichment');

  const { trialEndDays } = trialUserOnboardingState;

  return (
    <>
      <NotificationBar notificationType={showNotificationType} />
      <header
        className={
          pathname === PAGE_PATH_MAP.ACTIVATE_ACCOUNT ||
          pathname === PAGE_PATH_MAP.PASSWORD_SETUP
            ? 'hide'
            : ''
        }
      >
        <NavigationWrapper />
        <div className="nav-wrapper-right">
          <div className="credit-and-upgrade-section">
            {/* Free user trial not taken */}
            {!Utils.isPaidUser() && !Utils.isTrialUsed() && (
              <Button
                buttonProps={{ text: 'Start Free Trial' }}
                isLoading={trialApiLoading}
                onClick={handleStartFreeTrialClick}
              />
            )}

            {/* Active Trial Users */}
            {userDetail.isFreeTrial && (
              <>
                {trialEndDays !== '' && (
                  <div className="trial-end-info">
                    <TimerIcon />
                    <span>
                      {trialEndDays === 0
                        ? 'Trial ends today'
                        : `Trial ends in ${trialEndDays} Days`}
                    </span>
                  </div>
                )}

                {!isPricingPage() && !Utils.isPaymentPendingReview() && (
                  <Button
                    Icon={UpgradeIcon}
                    buttonProps={{ text: 'Upgrade' }}
                    isLoading={trialApiLoading}
                    onClick={handleUpgradeNowClick}
                  />
                )}
              </>
            )}

            {/* Free user trial already taken */}
            {!Utils.isPaidUser() && Utils.isTrialUsed() && !isPricingPage() && (
              <Button
                Icon={UpgradeIcon}
                buttonProps={{ text: 'Upgrade' }}
                onClick={handleUpgradeNowClick}
              />
            )}

            {/* Paid user */}
            {!showEnrichmentCreditsInHeader && isCreditLimitEnabled && (
              <div className="credits">
                <p>
                  {Utils.viewsDownloadsNumberFormat(
                    remainingIndividualLbCredits,
                  )}
                </p>
                <span className="count-name">
                  Available{' '}
                  {lbCreditsIndividualLimitFrequency === 'YEARLY'
                    ? 'Yearly'
                    : 'Monthly'}{' '}
                  Credit
                </span>
              </div>
            )}
            {showEnrichmentCreditsInHeader && (
              <div className="credits">
                <p>
                  {Utils.viewsDownloadsNumberFormat(enrichmentCreditsRemaining)}
                </p>
                <span className="count-name">Enrichment Credits</span>
              </div>
            )}
          </div>
          <AccountProfileWrapper />
        </div>
      </header>
    </>
  );
}

export default observer(Header);
