import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import EmptyChart from 'Assets/png/empty-chart.png';
import EmailIcon from 'Assets/svg/contact-email.svg';
import RightArrow from 'Assets/svg/right-arrow.svg';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import userDetail from 'Stores/userDetail';
import profileState from 'Stores/profile';
import { MX_DASHBOARD_PAGE_EVENTS, PAGE_PATH_MAP } from 'Utils/constants';
import Utils from 'Utils/utils';
import './styles.scss';
import UpgradeOverlay from 'Components/common/UpgradeOverlay';

defaults.font.family = 'Roboto-Regular';

const chartOptions = {
  indexAxis: 'x',
  responsive: true,
  categoryPercentage: 0.9,
  datasets: {
    bar: {
      borderRadius: 4,
      barPercentage: 0.9,
      maxBarThickness: 15,
      skipNull: true,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        color: '#5a595f',
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Filler,
);

const sampleUsageData = [40, 70, 48, 50, 60, 55];

function UsageCard() {
  const {
    firstName,
    lastName,
    lbCredits,
    memberSubscription = {},
  } = userDetail.userInfo;

  const lastSixMonthsLabel = Utils.getPreviousMonthData(new Date(), 6);

  useEffect(() => {
    if (
      memberSubscription !== null &&
      featureFlagsAndPreferencesState.isBasicReportingEnabled()
    ) {
      profileState.getUsageAnalyticsData({
        startMonth: lastSixMonthsLabel[0].split(' ')[0],
        startYear: lastSixMonthsLabel[0].split(' ')[1],
        endMonth:
          lastSixMonthsLabel[lastSixMonthsLabel.length - 1].split(' ')[0],
        endYear:
          lastSixMonthsLabel[lastSixMonthsLabel.length - 1].split(' ')[1],
      });
    }
  }, []);

  const isUsageAvailable =
    profileState.usageAnalyticsData?.data?.length > 0 &&
    profileState.usageAnalyticsData?.data.some((data) => {
      return (
        data?.credits > 0 && data?.memberId === userDetail.userInfo?.memberId
      );
    });

  const creditsUsed = memberSubscription
    ? memberSubscription.lbCredits - lbCredits < 0
      ? memberSubscription.lbCredits
      : memberSubscription.lbCredits - lbCredits
    : 0;

  const isBasicReportingEnabled =
    featureFlagsAndPreferencesState.isBasicReportingEnabled();

  return (
    <div className="usage-chart-wrapper">
      <h1>
        Welcome {firstName || ''} {lastName || ''} 🎉
      </h1>
      <div className="credits-used-wrapper">
        <div className="email-credits">
          <div className="icon-wrap">
            <EmailIcon />
          </div>
          <span className="credits-text">Email Credits Used</span>
          <div className="usage-bar">
            {memberSubscription?.lbCredits > 0 && (
              <div
                className="usage-used"
                style={{
                  width: `${(creditsUsed / memberSubscription.lbCredits) * 100}%`,
                }}
              />
            )}
          </div>
          {memberSubscription?.lbCredits && (
            <span className="usage">
              {Utils.numberFormat(creditsUsed)}/
              {Utils.numberFormat(memberSubscription.lbCredits)}
            </span>
          )}
        </div>
      </div>
      <div className="usage-chart-title">Last 6 Months Usage</div>
      {(memberSubscription?.lbCredits && isUsageAvailable) ||
      !isBasicReportingEnabled ? (
        <>
          <div className="chart-with-overlay">
            {!isBasicReportingEnabled && <UpgradeOverlay />}
            <div className="chart-wrapper">
              <Bar
                options={chartOptions}
                data={{
                  labels: lastSixMonthsLabel.map((label) => {
                    const [month, year] = label.split(' ');
                    return `${month.slice(0, 3)} ${year}`;
                  }),
                  datasets: [
                    {
                      label: 'Email used',
                      data: isBasicReportingEnabled
                        ? profileState.getTeamMemberAnalyticsData(
                            lastSixMonthsLabel,
                            6,
                          )
                        : sampleUsageData,
                      backgroundColor: '#6047d9',
                    },
                  ],
                }}
              />
            </div>
          </div>
          {isBasicReportingEnabled && (
            <Link
              to={PAGE_PATH_MAP.USAGE_ANALYTICS}
              className="view-link"
              onClick={() => {
                Utils.mixpanelTrack(
                  MX_DASHBOARD_PAGE_EVENTS.VIEW_DETAILED_USAGE_CLICK,
                );
              }}
            >
              View detailed usage <RightArrow />
            </Link>
          )}
        </>
      ) : (
        <div className="chart-empty-state">
          <img src={EmptyChart} alt="Empty Chart" />
          <p>There is no usage in the last 6 months</p>
        </div>
      )}
    </div>
  );
}

export default observer(UsageCard);
