import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import MyListEmptyImg from 'Assets/png/my-list-empty.png';
import Title from 'Components/common/base/Title';
import ListTable from 'Components/common/ListTable';
import SavedSearchList from 'Components/common/ListTable/SavedSearchList';
import userDetail from 'Stores/userDetail';
import commonAppData from 'Stores/commonAppData';
import { MX_DASHBOARD_PAGE_EVENTS, PAGE_PATH_MAP } from 'Utils/constants';
import Utils from 'Utils/utils';
import UsageCard from './UsageCard';
import UseCases from './UseCases';
import HelpSection from './HelpSection';
import './styles.scss';

const myListHeaders = [
  {
    text: 'List Name',
    key: 'name',
    showShared: true,
  },
  {
    text: 'Contacts',
    key: 'count',
    type: 'number',
  },
  {
    text: 'Last Purchased',
    key: 'lastPurchasedDate',
    type: 'date',
  },
];

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    commonAppData.getCustomerExecutiveList();
    Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.PAGE_VIEWED);
  }, []);

  return (
    <div className="dashboard-page">
      <Title title="Dashboard" />
      <div className="page-wrap">
        <div className="chart-and-use-cases">
          <UsageCard />
          <UseCases />
        </div>
        <div className="saved-search-my-list">
          <div className="list-card">
            <h2>Saved Searches</h2>
            <SavedSearchList
              callback={(data) => {
                const { id } = data;
                Utils.mixpanelTrack(
                  MX_DASHBOARD_PAGE_EVENTS.VIEW_SAVED_SEARCHES_CLICKED,
                  { page: 'dashboard' },
                );
                navigate(`/advanced-search/contact/${id}`);
              }}
            />
          </div>
          <div className="list-card my-list">
            <h2>My Lists</h2>
            <ListTable
              viewAllData={{
                link: PAGE_PATH_MAP.MY_LEADS,
                text: 'View all lists',
              }}
              emptyState={{
                imageURL: MyListEmptyImg,
                title: "You haven't created any lists yet",
                message:
                  "Contact lists allows you to manage all contacts you've purchased from Adapt.",
              }}
              onClick={(data) => {
                const { id } = data;
                Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.VIEW_LIST_CLICKED);
                navigate(`/my-leads/list/${id}`);
              }}
              headersList={myListHeaders}
              list={userDetail.myLeadList
                ?.filter((myList) => {
                  return !!myList.id;
                })
                ?.map((myList) => {
                  return {
                    id: myList.id,
                    name: myList.name,
                    isShared: myList.isSharedList,
                    lastPurchasedDate: myList.lastPurchasedDate,
                    count: myList.count,
                  };
                })}
            />
          </div>
          <HelpSection />
        </div>
      </div>
    </div>
  );
}

export default observer(Dashboard);
