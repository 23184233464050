import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import DropDownIcon from 'Assets/svg/dropdown-icon.svg';
import ContactEmailIcon from 'Assets/svg/contact-email.svg';
import Dropdown from 'Components/common/base/Dropdown';
import Menu from 'Components/common/base/Menu';
import UpgradeOverlay from 'Components/common/UpgradeOverlay';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import userDetail from 'Stores/userDetail';
import profileState from 'Stores/profile';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import TeamMembersDropdown from './TeamMembersDropdown';
import EmailDigestPopup from './EmailDigestPopup';
import ManageEmailDigestPopup from './ManageEmailDigestPopup';
import CustomRangeSelection from './CustomRangeSelection';
import CreditsOverviewCard from './CreditsOverviewCard';
import ChartCard from './ChartCard';
import './styles.scss';

const barChartColors = [
  '#F5AAA1',
  '#9DB6FB',
  '#92DFA4',
  '#F3A1C9',
  '#A1E5E3',
  '#D0B8F0',
  '#DFAD7F',
  '#ADBCCC',
  '#61B7B9',
  '#A08CBD',
  '#709CD9',
  '#F4CE90',
  '#95CDF4',
  '#C98789',
  '#A8A8A8',
];

const sampleUsageData = [20, 30, 44, 68, 53, 61, 53, 49, 39, 37];

const teamMemberLimit = 15;

function getMonthRange(monthArray) {
  return {
    startMonth: monthArray[0].split(' ')[0],
    startYear: monthArray[0].split(' ')[1],
    endMonth: monthArray[monthArray.length - 1].split(' ')[0],
    endYear: monthArray[monthArray.length - 1].split(' ')[1],
  };
}

const reportDropdown = [
  {
    label: 'One-Time Email',
    popup: 'form',
    oneTimeEmail: true,
  },
  { label: 'Email Digest', popup: 'form', oneTimeEmail: false },
  { label: 'Manage Email Digest', popup: 'list' },
];

const monthsDropDown = [
  { label: 'Current Month' },
  { label: 'Current + Last 1 Month' },
  { label: 'Current + Last 2 Months' },
  { label: 'Custom Date Range ' },
];

function TeamMemberTab(props) {
  const {
    currentTab,
    setCurrentTab,
    selectedTeamMembers,
    setSelectedTeamMembers,
    setMonthRange,
    teamMembers,
    currentDate,
  } = props;

  return (
    <span
      className={`tab team-member ${currentTab === 1 ? 'active' : ''}`}
      onClick={() => {
        if (
          !(
            teamMembers.length > teamMemberLimit &&
            selectedTeamMembers.length === 0
          )
        ) {
          setSelectedTeamMembers(
            selectedTeamMembers.length > 0 ? selectedTeamMembers : teamMembers,
          );
          setMonthRange(
            getMonthRange(Utils.getPreviousMonthData(currentDate, 6)),
          );
          setCurrentTab(1);
        }
      }}
    >
      Team Members
      <DropDownIcon />
    </span>
  );
}

function UsageAnalytics() {
  const {
    isEnterpriseAdmin,
    lbCredits,
    phoneCreditsRemaining,
    enrichmentCreditsRemaining,
    memberSubscription,
  } = userDetail.userInfo;

  const [currentTab, setCurrentTab] = useState(0);

  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [popupData, setPopupData] = useState({ show: '', data: {} });

  const currentDate = new Date();
  const [monthRange, setMonthRange] = useState(
    getMonthRange(Utils.getPreviousMonthData(currentDate, 10)),
  );
  const canvasWrapRef = useRef();

  const selectedStartDate = new Date(
    monthRange.startYear,
    CONSTANTS.monthNames.indexOf(monthRange.startMonth),
  );

  const selectedEndDate = new Date(
    monthRange.endYear,
    CONSTANTS.monthNames.indexOf(monthRange.endMonth),
  );

  const monthRangeData = Utils.getPreviousMonthData(
    selectedEndDate,
    selectedEndDate.getMonth() -
      selectedStartDate.getMonth() +
      1 +
      12 * (selectedEndDate.getFullYear() - selectedStartDate.getFullYear()),
  );

  const isBasicReportingEnabled =
    featureFlagsAndPreferencesState.isBasicReportingEnabled();

  const getTeamMembers = async () => {
    const response = await profileState.fetchTeamMembers();
    setTeamMembers(
      response?.data?.members
        ? response.data.members
            ?.reverse()
            .filter((member) => {
              return (
                member.status !== 'INACTIVE' && member.status !== 'INVITED'
              );
            })
            .concat(
              response.data.members.filter((member) => {
                return member.status === 'INVITED';
              }),
              response.data.members.filter((member) => {
                return member.status === 'INACTIVE';
              }),
            )
        : [],
    );
  };

  const fetchUsageAnalytics = async (range) => {
    setIsLoading(true);
    await profileState.getUsageAnalyticsData(range || monthRange);
    setIsLoading(false);
  };

  const getMonthRangeFromDropDown = async (monthValue) => {
    let monthsObj = {};
    const date = new Date();
    switch (monthValue) {
      case monthsDropDown[0].label: {
        monthsObj = {
          endMonth: CONSTANTS.monthNames[date.getMonth()],
          endYear: date.getFullYear(),
          startMonth: CONSTANTS.monthNames[date.getMonth()],
          startYear: date.getFullYear(),
        };
        break;
      }
      case monthsDropDown[1].label: {
        monthsObj = getMonthRange(Utils.getPreviousMonthData(date, 2));
        break;
      }
      case monthsDropDown[2].label: {
        monthsObj = getMonthRange(Utils.getPreviousMonthData(date, 3));
        break;
      }
      default:
        break;
    }
    setMonthRange(monthsObj);
    fetchUsageAnalytics(monthsObj);
  };

  useEffect(() => {
    if (isBasicReportingEnabled && memberSubscription !== null) {
      fetchUsageAnalytics();
    }
  }, [currentTab]);

  useEffect(() => {
    if (isEnterpriseAdmin && isBasicReportingEnabled) {
      getTeamMembers();
    }
    Utils.mixpanelTrack(
      CONSTANTS.MX_EMAIL_DIGEST_EVENTS.USAGE_ANALYTICS_VIEWED,
    );
  }, []);

  const closePopup = () => {
    setPopupData({ show: '', data: {} });
  };

  const tabProps = {
    currentTab,
    setCurrentTab,
    selectedTeamMembers,
    setSelectedTeamMembers,
    setMonthRange,
    teamMembers,
    currentDate,
  };

  const chartData = {
    labels: monthRangeData,
    datasets: isBasicReportingEnabled
      ? currentTab === 0
        ? [
            {
              label: 'Usage',
              backgroundColor: '#6047d9',
              data: isEnterpriseAdmin
                ? profileState.getAdminOverallAnalyticsData(
                    monthRangeData,
                    monthRangeData.length,
                  )
                : profileState.getTeamMemberAnalyticsData(
                    monthRangeData,
                    monthRangeData.length,
                  ),
            },
          ]
        : profileState.getTeamBarChartData({
            rangeData: monthRangeData,
            rangeLength: monthRangeData.length,
            barChartColors,
            memberData:
              selectedTeamMembers.length > 0
                ? selectedTeamMembers
                : teamMembers,
          })
      : [
          {
            label: 'Usage',
            backgroundColor: '#6047d9',
            data: sampleUsageData,
          },
        ],
  };

  const chartOptions = {
    categoryPercentage:
      selectedTeamMembers.length > 5
        ? 0.75
        : selectedTeamMembers.length * 0.1 + 0.2,
  };

  return (
    <div className="usage-analytics-page">
      <CreditsOverviewCard
        title="Usage Overview"
        list={[
          { label: 'Email Credits Remaining', value: lbCredits },
          { label: 'Phone Credits Remaining', value: phoneCreditsRemaining },
          {
            label: 'Enrichment Credits Remaining',
            value: enrichmentCreditsRemaining,
          },
          {
            label:
              profileState.usageAnalyticsData
                .lbCreditsIndividualLimitFrequency === 'YEARLY'
                ? 'Yearly Credit Limit'
                : 'Monthly Credit Limit',
            value: profileState.usageAnalyticsData.lbCreditsIndividualLimit,
            getValue: () => {
              return profileState.usageAnalyticsData
                .lbCreditsIndividualLimitFrequency !== null
                ? profileState.usageAnalyticsData.lbCreditsIndividualLimit
                  ? Utils.numberFormat(
                      profileState.usageAnalyticsData.lbCreditsIndividualLimit,
                    )
                  : '-'
                : 'No Limit Set';
            },
          },
        ]}
      />

      <div className="chart-container" ref={canvasWrapRef}>
        {!isBasicReportingEnabled && <UpgradeOverlay />}
        <ChartCard
          isLoading={isLoading}
          title="Credits Usage"
          chartData={{
            type: 'bar',
            data: chartData,
            options: chartOptions,
          }}
          emptyState={
            !(
              profileState.usageAnalyticsData.data.length > 0 &&
              !profileState.usageAnalyticsData.data?.every((usageData) => {
                return usageData.credits === 0 || usageData.credits === null;
              })
            )
          }
        >
          <div className="chart-actions">
            {isEnterpriseAdmin && teamMembers.length > 1 && (
              <div className="tab-wrapper">
                <span
                  className={`tab overall ${currentTab === 0 ? 'active' : ''}`}
                  onClick={() => {
                    setMonthRange(
                      getMonthRange(
                        Utils.getPreviousMonthData(currentDate, 10),
                      ),
                    );
                    setCurrentTab(0);
                  }}
                >
                  Overall Team
                </span>
                {(teamMembers.length > teamMemberLimit &&
                  selectedTeamMembers.length === 0) ||
                currentTab === 1 ? (
                  <Dropdown toggleElement={<TeamMemberTab {...tabProps} />}>
                    <TeamMembersDropdown
                      isLoading={isLoading}
                      teamMemberLimit={teamMemberLimit}
                      selectedTeamMembers={selectedTeamMembers}
                      teamMembers={teamMembers}
                      onApply={(data) => {
                        setSelectedTeamMembers(data);
                        setCurrentTab(1);
                        setMonthRange(
                          getMonthRange(
                            Utils.getPreviousMonthData(currentDate, 6),
                          ),
                        );
                      }}
                    />
                  </Dropdown>
                ) : (
                  <TeamMemberTab {...tabProps} />
                )}
              </div>
            )}
            <CustomRangeSelection
              monthsDropDown={monthsDropDown}
              fetchUsageAnalytics={fetchUsageAnalytics}
              getMonthRangeFromDropDown={getMonthRangeFromDropDown}
              isEnterpriseAdmin={isEnterpriseAdmin}
              currentTab={currentTab}
              getMonthRange={getMonthRange}
              monthRange={monthRange}
              setMonthRange={setMonthRange}
            />
            {isEnterpriseAdmin === true && (
              <Menu
                align="end"
                wrapperClassName="email-report-dropdown"
                toggleElement={
                  <>
                    <ContactEmailIcon className="contact-email-icon" />
                    <div>Email report</div>
                  </>
                }
              >
                {reportDropdown.map((prop) => {
                  const { label, popup, oneTimeEmail = false } = prop;
                  return (
                    <Menu.Item
                      text={label}
                      className="dropdown-item"
                      onClick={() => {
                        if (
                          featureFlagsAndPreferencesState.isAdvancedReportingEnabled()
                        ) {
                          setPopupData({
                            show: popup,
                            data: { oneTimeEmail },
                          });
                        } else {
                          commonUpgradePopupState.setShowUpgradePopup(
                            CONSTANTS.UPGRADE_TRIGGERS.ADVANCED_REPORTING,
                          );
                        }
                      }}
                      key={label}
                    />
                  );
                })}
              </Menu>
            )}
          </div>
        </ChartCard>
      </div>
      {/* popups */}
      {popupData.show === 'form' && (
        <EmailDigestPopup onClose={closePopup} data={popupData.data} />
      )}
      {popupData.show === 'list' && (
        <ManageEmailDigestPopup
          onClose={closePopup}
          openRecurringEmail={(data) => {
            setPopupData({ show: 'form', data });
          }}
        />
      )}
    </div>
  );
}

export default observer(UsageAnalytics);
