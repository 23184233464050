import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import MarketoLogo from 'Assets/svg/export_icons/marketo.svg';
import CRMExportHeader from './CRMExportHeader';
import Modal from '../base/Modal';
import Button from '../base/Button';
import Input from '../base/Input';

class MarketoExportState {
  @observable accessor showPopup = false;

  @observable accessor errorResponse = null;

  defaultPopupValue = {
    title: '',
    actionBtnText: 'Export',
    callback() {},
  };

  defaultAuthValue = { end_point: '', client_id: '', client_secret: '' };

  @observable accessor popupValues = this.defaultPopupValue;

  @observable accessor authValues = this.defaultAuthValue;

  @action
  setShowPopup = (val) => {
    this.showPopup = val;
    if (val === false) {
      this.popupValues = this.defaultPopupValue;
    }
  };

  @action
  setErrorResponse = (val) => {
    this.errorResponse = val;
  };

  @action
  setPopupValues = (val) => {
    this.popupValues = { ...this.popupValues, ...val };
  };

  @action
  setAuthValues = (val = this.defaultAuthValue) => {
    this.authValues = { ...this.authValues, ...val };
  };
}
const marketoExportState = new MarketoExportState();

const defaultState = JSON.parse(
  JSON.stringify(marketoExportState.defaultAuthValue),
);

function MarketoAuthenticationPopup() {
  const [stateValues, setStateValues] = React.useState(defaultState);
  const [errors, setErrors] = React.useState(defaultState);

  const confirmClick = () => {
    if (
      stateValues.end_point !== undefined &&
      stateValues.end_point !== '' &&
      stateValues.client_id !== undefined &&
      stateValues.client_id !== '' &&
      stateValues.client_secret !== undefined &&
      stateValues.client_secret !== ''
    ) {
      marketoExportState.setAuthValues(stateValues);
      marketoExportState.popupValues.callback(stateValues);
      marketoExportState.setShowPopup(false);
    } else {
      setErrors({
        end_point: !stateValues.end_point,
        client_id: !stateValues.client_id,
        client_secret: !stateValues.client_secret,
      });
    }
  };

  const setInputValues = (name, value) => {
    setErrors({
      ...errors,
      [name]: errors[name] !== '' ? !value : errors[name],
    });
    setStateValues({ ...stateValues, [name]: value });
  };

  React.useEffect(() => {
    if (marketoExportState.showPopup) {
      setStateValues(JSON.parse(JSON.stringify(marketoExportState.authValues)));
    }
  }, [marketoExportState.showPopup]);

  const closeModal = () => {
    marketoExportState.setShowPopup(false);
  };

  return (
    marketoExportState.showPopup && (
      <Modal className="marketo-auth-modal" show closeModal={closeModal}>
        <CRMExportHeader
          Logo={MarketoLogo}
          title="Marketo Export"
          message="Use your Marketo API Credentials for connecting with Adapt"
        />
        <Modal.Content>
          <Input
            inputProps={{
              type: 'text',
              placeholder: 'Example: https://some-endpoint-id.mktorest.com',

              value: stateValues.end_point,
              required: true,
            }}
            onChange={(value) => {
              setInputValues('end_point', value);
            }}
            error={errors.end_point ? 'Please enter the endpoint' : ''}
            label="End Point"
          />
          <Input
            inputProps={{
              type: 'text',
              placeholder: 'Client Id',
              value: stateValues.client_id,
              required: true,
            }}
            onChange={(value) => {
              setInputValues('client_id', value);
            }}
            error={errors.client_id ? 'Please enter the client ID' : ''}
            label="Client ID"
          />
          <Input
            inputProps={{
              type: 'text',
              name: 'marketoClientSecret',
              id: 'client_secret',
              placeholder: 'Client Secret',
              value: stateValues.client_secret,
              required: true,
            }}
            onChange={(value) => {
              setInputValues('client_secret', value);
            }}
            error={errors.client_secret ? 'Please enter the Client Secret' : ''}
            label="Client Secret"
          />
          {marketoExportState.errorResponse !== null &&
            marketoExportState.errorResponse.errorDescription !== undefined && (
              <div className="error-text show" id="errMarketo">
                {marketoExportState.errorResponse.errorDescription}
              </div>
            )}
        </Modal.Content>
        <Modal.Footer>
          <Button
            buttonProps={{ text: 'Cancel' }}
            variant="outline"
            onClick={closeModal}
          />
          <Button buttonProps={{ text: 'Save' }} onClick={confirmClick} />
        </Modal.Footer>
      </Modal>
    )
  );
}

export { marketoExportState };

export default observer(MarketoAuthenticationPopup);
