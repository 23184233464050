/* eslint-disable react/no-array-index-key */
import React from 'react';
import './styles.scss';

let timeout;

function Carousel(props) {
  const { list, renderItem, interval = 5000, itemWidth } = props;
  const [currentBanner, setCurrentBanner] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);
  const carouselRef = React.useRef();
  const totalItemsCount = list?.length;

  const moveCarouselItem = (nextPosition = null) => {
    const newPosition =
      nextPosition !== null
        ? nextPosition
        : currentBanner + 1 === totalItemsCount
          ? 0
          : currentBanner + 1;

    if (carouselRef.current) {
      [...carouselRef.current.children].forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.style = `transform: translateX(-${newPosition * itemWidth}px)`;
      });

      setCurrentBanner(newPosition);
    }
  };

  React.useEffect(() => {
    if (!isHovered) {
      timeout = setTimeout(() => {
        moveCarouselItem();
      }, interval);
    }

    if (isHovered) {
      clearTimeout(timeout);
      timeout = undefined;
    }
  }, [currentBanner, isHovered]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  }, []);

  return (
    <div className="carousel">
      <div className="carousel-item-wrapper" ref={carouselRef}>
        {list.map((item, index) => {
          return (
            <div
              className="carousel-item"
              key={`carousel-item-${index}`}
              onMouseOver={() => {
                setIsHovered(true);
              }}
              onMouseOut={() => {
                setIsHovered(false);
              }}
            >
              {renderItem(item)}
            </div>
          );
        })}
      </div>
      <div className="pagination">
        {Array.from({ length: totalItemsCount }).map((item, index) => {
          return (
            <span
              onClick={() => {
                clearTimeout(timeout);
                timeout = undefined;
                moveCarouselItem(index);
              }}
              className={`pagination-item${
                index === currentBanner ? ' active' : ''
              }`}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Carousel;
