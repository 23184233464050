import { observable, action } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import advSearchState from 'Stores/advSearch';
import userDetail from 'Stores/userDetail';
import suppressionListState from 'Stores/suppressionList';
import featureFlagsAndPreferencesState from './featureFlagsAndPreferences';

const { MX_PROFILE_PROPERTIES, MX_AS_EVENTS } = CONSTANTS;

let cancelAdvPopupCall = null;
class AdvanceSearchFilterPopupState {
  @observable accessor appliedFilters = [];

  @observable accessor resultStatusMsg = 'empty';

  @observable accessor appliedFilterPostData = {};

  @observable accessor appliedFilterSectionData = {};

  @observable accessor appliedFilterData = {};

  @observable accessor searchResults = [];

  @observable accessor searchResultCount = 0;

  @observable accessor searchCursorMark = [];

  @observable accessor pageLimit = 50;

  @observable accessor currentPage = 0;

  @observable accessor bulkPurchaseIds = [];

  @observable accessor selectedContactForDownload = {};

  @observable accessor curListIds = [];

  @observable accessor resultLoading = false;

  @observable accessor allContactSelected = false;

  @observable accessor checkBoxFilters = {
    exactTitleMatch: false,
    dontDisplayOwnedContacts: true,
    dontDisplayTeamContacts: true,
    dontDisplayDeadContacts: true,
    displayOnlyDirectDialContacts: false,
    displayOnlyMobileNumberContacts: false,
    displayOnlyContactsWithAnyPhoneNumbers: false,
    showContactsWithoutEmail: false,
    showContactsWithEmail: true,
  };

  @observable accessor uploadCsv = {
    includedDomains: false,
    excludedDomains: false,
  };

  @observable accessor locationPreferences = '*';

  @observable accessor actionLoader = false;

  @observable accessor contactLimitsPerCompany = 0;

  @observable accessor uniqueCompanyCount = 0;

  @observable accessor isExcludeIndustryFilter = false;

  @observable accessor isExcludeTitleFilter = false;

  @action
  setIsExcludeTitleFilter(value = false) {
    this.isExcludeTitleFilter = value;
  }

  @action
  setIsExcludeIndustryFilter(value = false) {
    this.isExcludeIndustryFilter = value;
  }

  @action
  setActionLoader(value) {
    this.actionLoader = value;
  }

  @action
  setCheckBoxFilters(name) {
    if (name !== undefined && name !== '' && name !== null) {
      this.checkBoxFilters[name] = !this.checkBoxFilters[name];
      this.setAppliedFilterPostData(this.appliedFilterData, true);
    }
  }

  @action
  setUploadCsv(key, value) {
    if (
      key !== undefined &&
      key !== '' &&
      key !== null &&
      value !== undefined
    ) {
      this.uploadCsv[key] = value;
      // if (this.appliedFilterData[key] === undefined)
      // this.appliedFilterData[key] = {};

      this.setAppliedFilterPostData(this.appliedFilterData, true);
      this.appliedFilterSectionData[key] = [value.fname];
    }
  }

  @action
  setLocationPreferences(value) {
    if (value !== undefined && value !== '' && value !== null) {
      if (value === 'contact') {
        this.appliedFilterData.zip = [];
      }
      this.locationPreferences = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
    }
  }

  @action
  setContactLimitsPerCompany(value) {
    if (value !== undefined && value !== '' && value !== null) {
      this.contactLimitsPerCompany = value;
      this.setAppliedFilterPostData(this.appliedFilterData, true);
    }
  }

  @action
  removeAppliedFilterFromGroup({ category, value, objectKey }) {
    const appFilterData = JSON.parse(JSON.stringify(this.appliedFilterData));

    if (category === 'recentJobChanges') {
      delete appFilterData[category];
    } else if (
      category === 'fundingData' ||
      category === 'leadershipHire' ||
      category === 'headcountGrowth'
    ) {
      if (objectKey) {
        const appliedValue = appFilterData[category][objectKey];
        if (Array.isArray(appliedValue) && value) {
          appFilterData[category][objectKey] = appliedValue.filter((prop) => {
            return prop !== value;
          });
        } else {
          delete appFilterData[category][objectKey];
        }
      } else {
        delete appFilterData[category];
      }
    } else if (
      category === 'industrySectors' &&
      appFilterData[category] &&
      appFilterData[category] !== undefined &&
      appFilterData[category].industries
    ) {
      let filterData = JSON.parse(JSON.stringify(appFilterData[category]));

      if (Object.keys(value).length === 0) {
        filterData = {};
      } else {
        appFilterData[category].industries.filter((item, index) => {
          if (
            item.industry &&
            typeof item.industry === 'string' &&
            (item.industry === value || item.industry === value.toString())
          ) {
            filterData.industries.splice(index, 1);
          } else if (item.sectors && item.sectors.length) {
            // eslint-disable-next-line array-callback-return
            item.sectors.filter((sectorValue, idx) => {
              if (sectorValue && value && value.toString() === sectorValue) {
                filterData.industries[index].sectors.splice(idx, 1);
              }
            });
            if (
              filterData.industries[index] !== undefined &&
              filterData.industries[index].sectors !== undefined &&
              filterData.industries[index].sectors.length === 0
            ) {
              filterData.industries.splice(index, 1);
            }
          } else if (
            item.industry &&
            typeof item.industry === 'object' &&
            item.industry.includes(value)
          ) {
            if (item.industry.length === 1) {
              filterData.industries.splice(index, 1);
            } else {
              const newItem = item;
              newItem.industry = item.industry.splice(
                item.industry.indexOf(value),
                1,
              );
              filterData[index] = newItem;
            }
          }

          return true;
        });
      }

      if (
        filterData.industries !== undefined &&
        filterData.industries.length === 0
      ) {
        appFilterData[category] = [];
      } else {
        appFilterData[category] = filterData;
      }
    } else if (
      category === 'industrySectors' &&
      appFilterData[category] &&
      appFilterData[category] !== undefined
    ) {
      appFilterData[category] = {};
    } else if (appFilterData[category] && appFilterData[category].length > 0) {
      let filterData = JSON.parse(JSON.stringify(appFilterData[category]));

      const loopFilterData = JSON.parse(JSON.stringify(filterData));
      if (value.length === 0) {
        filterData = [];
      } else {
        loopFilterData.filter((item, index) => {
          if (
            item.pName &&
            typeof item.pName === 'string' &&
            (item.pName === value || item.pName === value.toString())
          ) {
            filterData.splice(index, 1);
            if (category === 'keyword') {
              if (
                appFilterData.industryKeywordsJson !== undefined &&
                appFilterData.industryKeywordsJson.length > 0
              ) {
                appFilterData.industryKeywordsJson.splice(index, 1);
              }
            }
          } else if (
            item.pName &&
            typeof item.pName === 'object' &&
            item.pName.includes(value)
          ) {
            if (item.pName.length === 1) {
              filterData.splice(index, 1);
            } else {
              const newItem = item;
              newItem.pName = item.pName.splice(item.pName.indexOf(value), 1);
              filterData[index] = newItem;
            }
          }

          return true;
        });
      }

      appFilterData[category] = filterData;
    }
    if (category === 'includedDomains' || category === 'excludedDomains') {
      this.uploadCsv[category] = false;
    }
    this.appliedFilterData = appFilterData;
    this.setAppliedFilterPostData(appFilterData);
  }

  @action
  setAppliedFilters(key, value, isPagination, hashParam, performSearch = true) {
    const appliedFiltersTemp = this.appliedFilterData;
    if (appliedFiltersTemp[key] === undefined && key !== undefined) {
      appliedFiltersTemp[key] = [];
    }

    if (key !== undefined && value !== undefined) {
      appliedFiltersTemp[key] = value;
      if (key === 'keyword') {
        appliedFiltersTemp.industryKeywordsJson = value;
      }
      if (performSearch) {
        this.searchCursorMark.pop();
      }
    }
    this.appliedFilterData = appliedFiltersTemp;
    // console.log(JSON.parse(JSON.stringify(appliedFiltersTemp)));
    if (isPagination === undefined || isPagination === null) {
      this.bulkPurchaseIds = [];
      this.selectedContactForDownload = {};
    }
    this.setAppliedFilterPostData(appliedFiltersTemp, hashParam, performSearch);
  }

  @action
  setAppliedFilterPostData(data, hashParam, makeSearchCall = true) {
    let performSearch = makeSearchCall;
    if (hashParam !== undefined && hashParam === true) {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/contact#search', { replace: true });
    }
    // this.appliedFilterPostData = {};
    const appliedKeys = Object.keys(data);
    const postData = {};
    postData.industryKeywords = [];
    postData.sectorKeywords = [];
    // console.log('appliedKeys ', data);
    // eslint-disable-next-line array-callback-return
    appliedKeys.map((item) => {
      const keyItem = this.appliedFilterData[item];
      let keyValue = [];

      if (item === 'recentJobChanges') {
        postData[item] = this.appliedFilterData[item] || '';
      } else if (item === 'fundingData') {
        Object.keys(this.appliedFilterData[item]).forEach((prop) => {
          const value = this.appliedFilterData[item]?.[prop];
          if (
            Number.isFinite(value) ||
            value?.length > 0 ||
            Object.keys(value)?.length > 0
          ) {
            if (!postData[item]) {
              postData[item] = {};
            }
            postData[item][prop] = value;
          }
        });
      } else if (item === 'headcountGrowth' || item === 'leadershipHire') {
        if (item === 'headcountGrowth') {
          const isMonthsAvailable =
            this.appliedFilterData[item].months !== undefined &&
            this.appliedFilterData[item].months !== '';

          if (!isMonthsAvailable || !keyItem?.percentage) {
            performSearch = false;
          }
        } else {
          const isDaysValueAvailable =
            this.appliedFilterData[item].months !== undefined &&
            this.appliedFilterData[item].months !== '';

          postData[item] = this.appliedFilterData[item];
          if (!isDaysValueAvailable) {
            performSearch = false;
          }
        }
        const valueAvailable = Object.values(this.appliedFilterData[item]).some(
          (prop) => {
            return prop.length > 0;
          },
        );

        postData[item] = valueAvailable ? this.appliedFilterData[item] : '';
      } else if (item === 'industryKeywords' || item === 'sectorKeywords') {
        console.log('dont do anything');
      } else if (item === 'industryKeywordsJson') {
        postData[item] = this.appliedFilterData[item];
        postData.industryKeywords = Utils.getIndustryKeywordArray(
          this.appliedFilterData[item],
        );
        postData.sectorKeywords = Utils.getSectorKeywordArray(
          this.appliedFilterData[item],
        );
        // postData.keyword = [
        //   ...postData.industryKeywords,
        //   ...postData.sectorKeywords,
        // ];
      } else if (item === 'industrySectors') {
        if (this.appliedFilterData[item].industries !== undefined) {
          postData[item] = this.appliedFilterData[item];
        }
      } else if (keyItem.length > 0 && typeof keyItem === 'object') {
        if (
          [
            'selectedCitiesContact',
            'selectedStatesContact',
            'selectedCountriesContact',
            'selectedRegionsContact',
            'selectedCitiesCompany',
            'selectedStatesCompany',
            'selectedCountriesCompany',
            'selectedRegionsCompany',
          ].includes(item) ||
          item === 'selectedCities' ||
          item === 'selectedStates' ||
          item === 'selectedCountries' ||
          item === 'selectedRegions' ||
          item === 'selectedExcldedCountriesWholeData' ||
          item === 'industrySectorsV2' ||
          item === 'excludeIndustrySectorsV2'
        ) {
          // console.log('selected cities');
          keyValue = keyItem.map((i) => {
            return i;
          });
        } else {
          // console.log('pre keyItem ', keyItem);
          keyValue = keyItem.map((i, eIndex) => {
            if (i !== undefined && i !== null && i.pName !== undefined) {
              return i.pName;
            }
            if (typeof i === 'string') {
              return i;
            }
            keyItem.splice(eIndex, 1);
            return '';
          });
          // console.log('keyItem ', keyItem);
        }
        if (keyValue.length > 0) {
          keyValue = keyValue.filter((el) => {
            return el !== null && el !== '';
          });
        }

        postData[item] = keyValue;
      }
    });

    if (this.uploadCsv.includedDomains) {
      postData.includedDomains = this.uploadCsv.includedDomains;
    }
    if (this.uploadCsv.excludedDomains) {
      postData.excludedDomains = this.uploadCsv.excludedDomains;
    }

    const postDataKeys = Object.keys(postData);

    postDataKeys.map((item) => {
      if (postData[item].length === 0 || postData[item] === '') {
        delete postData[item];
      }

      if (item === 'exclusionListIds') {
        delete postData[item];
      }
      // migrated to v2 obj
      if (item === 'companyKeyword') {
        delete postData[item];
      }
      if (item === 'industrySectors') {
        delete postData[item];
      }
      return true;
    });
    // console.log('postData ', postData);

    if (
      Object.keys(postData).length > 0 &&
      featureFlagsAndPreferencesState.isSuppressionListEnabled()
    ) {
      // Add Suppression list
      const { suppressionListEntries = [] } = suppressionListState;
      const exclusionListIds = [];
      // eslint-disable-next-line array-callback-return
      suppressionListEntries.map((item) => {
        const { listId, listName, status, enabled } = item;
        if (status === 'COMPLETE' && enabled && listId !== null) {
          exclusionListIds.push({ listId, listName });
        }
      });
      if (exclusionListIds.length) {
        postData.exclusionListIds = exclusionListIds;
      }
    }

    this.appliedFilterSectionData = postData;
    if (this.uploadCsv.includedDomains) {
      this.appliedFilterSectionData.includedDomains = [
        this.uploadCsv.includedDomains.fname,
      ];
    }
    if (this.uploadCsv.excludedDomains) {
      this.appliedFilterSectionData.excludedDomains = [
        this.uploadCsv.excludedDomains.fname,
      ];
    }

    if (Object.keys(postData).length > 0) {
      const postDataCheckObj = Utils.copyObjectWithoutReference(postData);
      if (postDataCheckObj.exclusionListIds !== undefined) {
        delete postDataCheckObj.exclusionListIds;
      }

      if (
        Object.keys(postDataCheckObj).length === 1 &&
        (postData.selectedCountries !== undefined ||
          (postData.industrySectors !== undefined &&
            postData.industrySectors.industries === undefined))
      ) {
        this.resultStatusMsg = 'one_more';
        this.searchResults = [];
        this.searchResultCount = 0;
        this.uniqueCompanyCount = 0;
      } else {
        this.resultStatusMsg = '';
      }
      postData.dontDisplayDeadContacts =
        this.checkBoxFilters.dontDisplayDeadContacts;
      postData.dontDisplayOwnedContacts =
        this.checkBoxFilters.dontDisplayOwnedContacts;
      postData.dontDisplayTeamContacts =
        this.checkBoxFilters.dontDisplayTeamContacts;
      postData.displayOnlyDirectDialContacts =
        this.checkBoxFilters.displayOnlyDirectDialContacts;
      postData.showContactsWithoutEmail =
        this.checkBoxFilters.showContactsWithoutEmail;
      postData.showContactsWithEmail =
        this.checkBoxFilters.showContactsWithEmail;
      postData.displayOnlyMobileNumberContacts =
        this.checkBoxFilters.displayOnlyMobileNumberContacts;
      postData.displayOnlyContactsWithAnyPhoneNumbers =
        this.checkBoxFilters.displayOnlyContactsWithAnyPhoneNumbers;
      postData.locationPreferences = this.locationPreferences;
      postData.contactLimitsPerCompany = this.contactLimitsPerCompany;
      postData.startGroup = 0;

      if (this.checkBoxFilters.exactTitleMatch) {
        postData.exactTitleMatch = this.checkBoxFilters.exactTitleMatch;
      }

      postData.limit = this.pageLimit;
      if (this.searchCursorMark.length && this.currentPage > 0) {
        postData.cursorMark =
          this.searchCursorMark[this.searchCursorMark.length - 1];
      } else {
        delete postData.cursorMark;
      }
      if (this.currentPage > 0) {
        postData.currentPage = this.currentPage;
      } else {
        delete postData.currentPage;
      }
      this.appliedFilterPostData = postData;

      if (this.resultStatusMsg !== 'one_more' && performSearch) {
        if (
          Object.keys(postData).some((key) => {
            return [
              'selectedCitiesContact',
              'selectedStatesContact',
              'selectedCountriesContact',
              'selectedRegionsContact',
              'selectedCitiesCompany',
              'selectedStatesCompany',
              'selectedCountriesCompany',
              'selectedRegionsCompany',
            ].includes(key);
          }) &&
          !featureFlagsAndPreferencesState.isEUSearchEnabled()
        ) {
          this.checkEuCountrySearch();
        } else {
          this.getSearchResults();
        }
      }
    } else {
      if (cancelAdvPopupCall !== null) {
        cancelAdvPopupCall('cancel');
        cancelAdvPopupCall = null;
        this.resultLoading = false;
      }
      this.resultStatusMsg = 'empty';
      this.appliedFilterPostData = {};
      this.searchResultCount = 0;
      this.uniqueCompanyCount = 0;
      this.searchResults = [];
    }

    // console.log('bulkPurchaseIds ', this.bulkPurchaseIds);
    // console.log('selectedContactForDownload ', this.selectedContactForDownload);
  }

  @action
  checkEuCountrySearch = () => {
    const config = {
      url: URLS.checkEuCountry,
      method: 'POST',
      data: this.appliedFilterPostData,
    };
    const thisObj = this;
    this.resultLoading = true;
    makeApi(config)
      .then((response) => {
        thisObj.resultLoading = false;
        if (
          response &&
          response.data &&
          response.data.showEUMessage !== undefined &&
          response.data.showEUMessage === true
        ) {
          if (featureFlagsAndPreferencesState.isEUSearchEnabled()) {
            thisObj.getSearchResults();
          } else {
            Utils.showEURestrictionPopup();
          }
        } else {
          this.getSearchResults();
        }
      })
      .catch((error) => {
        this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  };

  getSearchMxData() {
    const thisObj = this;
    const { appliedFilterSectionData } = thisObj;
    const {
      dept = [],
      level = [],
      selectedStates = [],
      selectedCountries = [],
      selectedRegions = [],
      industrySectorsV2 = [],
      companyKeywordV2 = [],
      excludeIndustrySectorsV2 = [],
      excludeCompanyKeywordV2 = [],
    } = appliedFilterSectionData;

    const objKeys = Object.keys(appliedFilterSectionData);
    const mxData = {};

    objKeys.forEach((item) => {
      if (item === 'dept') {
        mxData.department = dept;
      } else if (item === 'level') {
        mxData.level = level;
      } else if (
        item === 'selectedStates' ||
        item === 'selectedCountries' ||
        item === 'selectedRegions'
      ) {
        const location = [
          ...selectedStates,
          ...selectedCountries,
          ...selectedRegions,
        ];
        mxData.location = location.map((item1) => {
          return item1.name;
        });
      } else if (item === 'industrySectorsV2' || item === 'companyKeywordV2') {
        const industryList = industrySectorsV2.map((item1) => {
          return item1.name;
        });
        mxData.industry = [...companyKeywordV2, ...industryList];
      } else if (
        item === 'excludeIndustrySectorsV2' ||
        item === 'excludeCompanyKeywordV2'
      ) {
        const industryList = excludeIndustrySectorsV2.map((item1) => {
          return item1.name;
        });
        mxData.excludeIndustry = [...excludeCompanyKeywordV2, ...industryList];
      }
    });

    return mxData;
  }

  @action
  trackSearchEvent() {
    const thisObj = this;

    const {
      TOTAL_SEARCHES_PERFORMED,
      LAST_SEARCH_PERFORMED,
      TOTAL_SEARCH_RESULT_COUNT,
      TOTAL_SEARCH_COMPANY_RESULT_COUNT,
    } = MX_PROFILE_PROPERTIES;
    Utils.mixpanelIncrementProfileProperty(
      TOTAL_SEARCH_RESULT_COUNT,
      thisObj.searchResultCount,
    );
    Utils.mixpanelIncrementProfileProperty(
      TOTAL_SEARCH_COMPANY_RESULT_COUNT,
      thisObj.uniqueCompanyCount,
    );

    Utils.mixpanelIncrementProfileProperty(TOTAL_SEARCHES_PERFORMED);
    Utils.mixpanelUpdateProfileProperty({
      [LAST_SEARCH_PERFORMED]: new Date().toISOString(),
    });
    const mxData = thisObj.getSearchMxData();
    mxData.contactCount = thisObj.searchResultCount;
    mxData.companyCount = thisObj.uniqueCompanyCount;
    mxData.searchQuery = this.appliedFilterPostData;
    mxData.searchType = 'Contact';
    mxData.zeroBounce = true;
    mxData.filterPlace = 'popup';
    Utils.mixpanelTrack(MX_AS_EVENTS.AS_LB_SEARCH_PERFORMED, mxData);
  }

  @action
  getSearchResults() {
    const thisObj = this;
    const postData = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );
    const config = {
      url: URLS.searchResults,
      method: 'POST',
      data: postData,
    };
    postData.limit = 0;
    if (cancelAdvPopupCall !== null) {
      cancelAdvPopupCall('cancel');
      cancelAdvPopupCall = null;
    }
    const advPopupCancelCallback = (value) => {
      cancelAdvPopupCall = value;
    };

    this.resultLoading = true;
    makeApi(config, false, advPopupCancelCallback)
      .then((response) => {
        if (response && response.message === undefined) {
          this.searchResultCount = 0;

          if (response && response.data && response.data.hits >= 0) {
            this.searchResultCount = response.data.hits;
          }
          thisObj.trackSearchEvent();

          if (response && response.data && response.data.cursorMark) {
            this.searchCursorMark.push(response.data.cursorMark);
          }

          thisObj.resultLoading = false;
        }
      })
      .catch((error) => {
        this.resultLoading = false;
        console.log('suggestionList error ', error);
      });
  }

  @action
  returnSearchResults = async () => {
    try {
      const postData = Utils.copyObjectWithoutReference(
        this.appliedFilterPostData,
      );
      postData.companyCount = true;
      const config = {
        url: URLS.searchResults,
        method: 'POST',
        data: postData,
      };
      const response = await makeApi(config);
      return response;
    } catch (error) {
      console.error('return Search Results error', error);
      return null;
    }
  };

  @action
  setPreviousState = () => {
    this.appliedFilterPostData = advSearchState.appliedFilterPostData;
    this.searchResultCount = advSearchState.searchResultCount;
    this.uniqueCompanyCount = advSearchState.uniqueCompanyCount;
    this.appliedFilterSectionData = advSearchState.appliedFilterSectionData;

    this.appliedFilterData = advSearchState.appliedFilterData;
    this.locationPreferences = advSearchState.locationPreferences;
    this.contactLimitsPerCompany = advSearchState.contactLimitsPerCompany;
    this.checkBoxFilters = advSearchState.checkBoxFilters;
    this.uploadCsv = advSearchState.uploadCsv;
    this.resultLoading = false;
    if (cancelAdvPopupCall !== null) {
      cancelAdvPopupCall('cancel');
      cancelAdvPopupCall = null;
    }
  };

  @action
  setPageStateToPopup = () => {
    const thisObj = this;
    const { isExcludeIndustryFilter, isExcludeTitleFilter } = advSearchState;
    thisObj.setIsExcludeIndustryFilter(isExcludeIndustryFilter);
    thisObj.setIsExcludeTitleFilter(isExcludeTitleFilter);
  };

  @action
  applyPopupFilters = () => {
    const thisObj = this;
    if (window.location.hash !== '#search') {
      const { navigate } = Utils.routerProps;
      navigate('/advanced-search/contact#search', { replace: true });
    }
    const postData = Utils.copyObjectWithoutReference(
      this.appliedFilterPostData,
    );
    if (postData.currentPage) {
      delete postData.currentPage;
    }
    Utils.setLocalStorage('searchJson', postData);

    const { isExcludeIndustryFilter, isExcludeTitleFilter } = thisObj;
    advSearchState.setIsExcludeIndustryFilter(isExcludeIndustryFilter);
    advSearchState.setIsExcludeTitleFilter(isExcludeTitleFilter);
    advSearchState.setAllCheckboxFilters(Utils.copyObjectWithoutReference(this.checkBoxFilters));
    advSearchState.setAllDomainFilter(Utils.copyObjectWithoutReference(this.uploadCsv));

    advSearchState.setCurrentPage(0, '', true);
    advSearchState.retainSearch();
  };

  @action
  clearAllFilters() {
    this.appliedFilterData = {};
    this.uploadCsv = {
      includedDomains: false,
      excludedDomains: false,
    };
    this.checkBoxFilters = {
      exactTitleMatch: false,
      dontDisplayOwnedContacts: true,
      dontDisplayTeamContacts: true,
      dontDisplayDeadContacts: true,
      displayOnlyMobileNumberContacts: false,
      displayOnlyDirectDialContacts: false,
      showContactsWithoutEmail:
        userDetail.userInfo.searchFilterFlags.showContactsWithoutEmail,
      showContactsWithEmail:
        userDetail.userInfo.searchFilterFlags.showContactsWithEmail,
      displayOnlyContactsWithAnyPhoneNumbers: false,
    };
    this.resultStatusMsg = '';
    this.locationPreferences = '*';
    this.contactLimitsPerCompany = 0;

    this.setAppliedFilterPostData({});
  }
}

const advSearchFilterPopupState = new AdvanceSearchFilterPopupState();

export default advSearchFilterPopupState;
