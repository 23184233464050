/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import CheckIcon from 'Assets/svg/tick-icon-new.svg';
import SetEmailNotification from 'Components/common/setEmailNotification';
import Modal from 'Components/common/base/Modal';
import profileState from 'Stores/profile';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import Button from 'Components/common/base/Button';
import Input from 'Components/common/base/Input';
import ControlInput from 'Components/common/base/ControlInput';
import { toasterState } from 'Components/common/base/Toaster';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import 'react-multi-email/dist/style.css';
import './styles.scss';

function getTime(time) {
  return (
    CONSTANTS.time.find((data) => {
      return data.value === time;
    }).label || time
  );
}

const timeOptions = [
  { label: 'Current Month', value: 'CURRENT_MONTH' },
  { label: 'Current + Last 1 Month', value: 'CURRENT_PLUS_LAST_ONE_MONTH' },
  { label: 'Current + Last 2 Months', value: 'CURRENT_PLUS_LAST_TWO_MONTH' },
];

function EmailDigestPopup(props) {
  const { onClose, data = {} } = props;
  const { oneTimeEmail, ...remainingProps } = data;

  const [initialData] = useState(remainingProps ?? {});

  const [errorMessage, setErrorMessage] = useState(false);

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState('CURRENT_MONTH');
  const [emailNotification, setEmailNotification] = useState({
    selectedTime: 'Weekly',
    ordinalDay: 'First',
    day: 'Monday',
    time: '11:00 AM',
    timeZone: 'GMT',
  });

  useEffect(() => {
    if (remainingProps.id) {
      const {
        name: reportName = '',
        subject: mailSubject = '',
        message: reportMessage = '',
        recepients: addedRecipients = [],
        frequency,
        day: selectedDay,
        time: selectedTime,
        ordinalDay: selectedOrdinalDay,
        timeZone: selectedTimeZone,
        dateRange: selectedDateRange,
      } = remainingProps;

      setName(reportName);
      setSubject(mailSubject);
      setMessage(reportMessage);
      setRecipients(addedRecipients);
      setEmailNotification((previousState) => {
        return {
          ...previousState,
          selectedTime: frequency === 'MONTHLY' ? 'Monthly' : 'Weekly',
          day: selectedDay.toLowerCase().replace(/\b(\w)/g, (s) => {
            return s.toUpperCase();
          }),
          time: selectedTime,
          ordinalDay: selectedOrdinalDay
            ? selectedOrdinalDay.toLowerCase().replace(/\b(\w)/g, (s) => {
                return s.toUpperCase();
              })
            : 'First',
          timeZone: selectedTimeZone,
        };
      });
      setDateRange(selectedDateRange);
    }
  }, []);

  const handleOnChangeEmail = (type, emailValue) => {
    setEmailNotification((prevState) => {
      return {
        ...prevState,
        [type]: emailValue,
      };
    });
  };

  const handleOnClickSchedule = async () => {
    if (featureFlagsAndPreferencesState.isAdvancedReportingEnabled()) {
      setIsLoading(true);
      let response;
      if (!data.id) {
        if (
          recipients.length > 0 &&
          (((name || '').length > 0 && !oneTimeEmail) || oneTimeEmail)
        ) {
          if (!oneTimeEmail) {
            response = await profileState.createRecurringEmail({
              name,
              subject,
              message,
              recepients: recipients,
              type: 'RECURRING',
              frequency:
                emailNotification.selectedTime === 'Weekly'
                  ? 'WEEKLY'
                  : 'MONTHLY',
              day: emailNotification.day.toUpperCase(),
              ...(emailNotification.selectedTime !== 'Weekly' && {
                ordinalDay: emailNotification.ordinalDay.toUpperCase(),
              }),
              time: emailNotification.time,
              timeZone: emailNotification.timeZone,
              dateRange,
            });
            if (response.status !== 200 || response.status !== 201) {
              toasterState.setToastMsg(
                !oneTimeEmail
                  ? 'Error While creating the email digest'
                  : 'Error while sending the email',
              );
              onClose();
            }
          } else {
            response = await profileState.createRecurringEmail({
              subject,
              message,
              recepients: recipients,
              type: 'ONE_TIME',
              dateRange,
            });
            if (response?.status !== 200 || response?.status !== 201) {
              toasterState.setToastMsg('Error while creating the email');
              onClose();
            }
          }
          if (response?.status === 200 || response?.status === 201) {
            Utils.mixpanelTrack(
              !oneTimeEmail
                ? CONSTANTS.MX_EMAIL_DIGEST_EVENTS.EMAIL_DIGEST_CREATE
                : CONSTANTS.MX_EMAIL_DIGEST_EVENTS.ONE_TIME_EMAIL_CREATE,
            );
            toasterState.setToastMsg(
              !oneTimeEmail
                ? 'Your email digest has been created successfully.'
                : 'Email has been sent successfully.',
              CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
            );
            onClose();
          }
        } else {
          setErrorMessage(true);
        }
      } else {
        const newObj = { id: initialData.id };
        if (initialData.name.length !== name.length) {
          newObj.name = name;
        }
        if (initialData.subject.length !== subject.length) {
          newObj.subject = subject;
        }
        if (initialData.message.length !== message.length) {
          newObj.message = message;
        }
        if (initialData.recepients.length !== recipients.length) {
          newObj.recepients = recipients;
        } else {
          for (let i = 0; i < recipients.length; i = +1) {
            if (recipients[i] !== initialData.recepients[i]) {
              newObj.recepients = recipients;
              break;
            }
          }
        }
        if (
          initialData.frequency !== emailNotification.selectedTime.toUpperCase()
        ) {
          newObj.frequency = emailNotification.selectedTime.toUpperCase();
        }
        if (initialData.day !== emailNotification.day.toUpperCase()) {
          newObj.day = emailNotification.day.toUpperCase();
        }
        if (
          initialData.ordinalDay !== emailNotification.ordinalDay.toUpperCase()
        ) {
          newObj.ordinalDay = emailNotification.ordinalDay.toUpperCase();
        }
        if (initialData.time !== emailNotification.time) {
          newObj.time = emailNotification.time;
        }
        if (initialData.timeZone !== emailNotification.timeZone) {
          newObj.timeZone = emailNotification.timeZone;
        }
        if (initialData.dateRange !== dateRange) {
          newObj.dateRange = dateRange;
        }
        response = await profileState.updateRecurringEmail(newObj);

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 202
        ) {
          Utils.mixpanelTrack(
            CONSTANTS.MX_EMAIL_DIGEST_EVENTS.EMAIL_DIGEST_UPDATE,
          );
          toasterState.setToastMsg(
            'Your changes have been successfully saved!',
            CONSTANTS.TOAST_MSG_TYPES.SUCCESS,
          );
          onClose();
        } else {
          toasterState.setToastMsg('Error while saving the changes');
          onClose();
        }
      }
      setIsLoading(false);
    } else {
      commonUpgradePopupState.setShowUpgradePopup(
        CONSTANTS.UPGRADE_TRIGGERS.ADVANCED_REPORTING,
      );
    }
  };

  return (
    <Modal show closeModal={onClose} className="email-digest-popup">
      <Modal.Title>
        {oneTimeEmail ? 'One-Time Email' : 'Email Digest'}
      </Modal.Title>
      <Modal.Content>
        <div className="first-container">
          {!oneTimeEmail && (
            <Input
              label={
                <>
                  Name of the Email Digest<sup>*</sup>&nbsp;
                  <span className="light-text">
                    (Recipients won’t see this)
                  </span>
                </>
              }
              inputProps={{
                placeholder: 'Name of the Email Digest',
                value: name,
              }}
              onChange={(value) => {
                setName(value);
              }}
              error={
                errorMessage && (name || '').length === 0
                  ? 'Please enter name for email digest'
                  : ''
              }
            />
          )}
          <Input
            label="Email Subject"
            inputProps={{
              placeholder: 'Subject for the email',
              value: subject,
            }}
            onChange={(value) => {
              setSubject(value);
            }}
          />
        </div>
        <div className="message-container">
          <div className="label-top-wrapper">
            <label htmlFor="message">Message</label>
            <p className="characters-added">{`${message?.length}/200 characters`}</p>
          </div>
          <textarea
            id="message"
            maxLength="200"
            name="textarea"
            value={message}
            placeholder="Type in your message for the email here."
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>
        <div className="recipients-container">
          <div className="label-top-wrapper">
            <label>Recipients*</label>
            {recipients.length > 0 && (
              <div className="recipients-added">
                <CheckIcon />
                <span>{recipients.length} Added</span>
              </div>
            )}
          </div>
          <ReactMultiEmail
            emails={recipients}
            placeholder="Enter email id."
            className="react-email-tag"
            onChange={(emails) => {
              setRecipients(emails);
            }}
            getLabel={(email, i, removeEmail) => {
              return (
                <div data-tag key={i}>
                  {email}
                  <span
                    data-tag-handle
                    onClick={() => {
                      return removeEmail(i);
                    }}
                  >
                    ×
                  </span>
                </div>
              );
            }}
          />
          {errorMessage && recipients.length === 0 && (
            <div className="error-message">
              Please enter at least one email address.
            </div>
          )}
        </div>
        <div className="time-container">
          {!oneTimeEmail && (
            <SetEmailNotification
              customTitle="Frequency"
              handleChangeRadio={handleOnChangeEmail}
              selectedEmailValues={emailNotification}
            />
          )}

          <div className="time-period-text">Time Period</div>
          <div className="time-period-container">
            {timeOptions.map((time) => {
              const { label, value } = time;
              return (
                <ControlInput
                  key={value}
                  type="radio"
                  name="time-period"
                  label={label}
                  value={value}
                  checked={dateRange === value ? 'checked' : ''}
                  onChange={setDateRange}
                />
              );
            })}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <p className="footer-text">
          {oneTimeEmail ? (
            'This email will be sent only once.'
          ) : emailNotification.selectedTime === 'Weekly' ? (
            <p className="footerText">
              {`This email is scheduled to send every week on ${
                emailNotification.day
              } at ${getTime(emailNotification.time)} ${
                emailNotification.timeZone
              }.`}
            </p>
          ) : (
            <p className="footerText">
              {`This email is scheduled to send every month on ${
                emailNotification.ordinalDay
              } ${emailNotification.day} at ${getTime(
                emailNotification.time,
              )} ${emailNotification.timeZone}.`}
            </p>
          )}
        </p>
        <Button
          buttonProps={{ text: 'Cancel' }}
          onClick={onClose}
          variant="outline"
        />
        <Button
          isLoading={isLoading}
          buttonProps={{ text: oneTimeEmail ? 'Send Email' : 'Schedule Email' }}
          onClick={handleOnClickSchedule}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default EmailDigestPopup;
