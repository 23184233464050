import { observable, action } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';

let cancelSequenceRequest = null;
let cancelFolderRequest = null;

function checkAndcancelSeqRequest() {
  if (cancelSequenceRequest !== null) {
    cancelSequenceRequest('cancel');
    cancelSequenceRequest = null;
  }
}

function checkAndcancelFolderRequest() {
  if (cancelFolderRequest !== null) {
    cancelFolderRequest('cancel');
    cancelFolderRequest = null;
  }
}

function cancelSeqRequestCbk(value) {
  cancelSequenceRequest = value;
}

function cancelFolderRequestCbk(value) {
  cancelFolderRequest = value;
}

class SequenceData {
  @observable accessor sequenceData = null;

  @observable accessor allFolderResponse = null;

  @action
  setSequenceData(value) {
    this.sequenceData = value;
  }

  @action
  setFolderResponse = (value) => {
    if (value) {
      this.allFolderResponse = value;
    }
  };

  @action
  getAllSequenceData = () => {
    const config = {
      url: URLS.salesgear.getAllSequence,
      method: 'GET',
    };
    checkAndcancelSeqRequest();
    makeApi(config, false, cancelSeqRequestCbk)
      .then((response) => {
        if (response && response.data) {
          this.setSequenceData(response.data);
        }
      })
      .catch((error) => {});
  };

  @action
  getAllSequenceFolders = async () => {
    try {
      const config = {
        url: URLS.salesgear.getAllSequenceFolders,
        method: 'GET',
      };
      checkAndcancelFolderRequest();
      const res = await makeApi(config, false, cancelFolderRequestCbk);
      if (res && res.data) {
        this.setFolderResponse(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  @action
  getFolderResponseById = async (folderId) => {
    if (folderId.length) {
      let targetURL = URLS.salesgear.getFolderSeqeunceById;
      // eslint-disable-next-line curly
      if (targetURL) {
        targetURL = targetURL.replace('<<folderId>>', folderId);
      }
      const config = {
        url: targetURL,
        method: 'GET',
      };
      checkAndcancelSeqRequest();
      makeApi(config, false, cancelSeqRequestCbk)
        .then((response) => {
          if (response && response.data) {
            if (response.data.length) {
              this.setSequenceData(response.data);
            } else {
              this.setSequenceData([]);
            }
          }
        })
        .catch((error) => {
          console.error('Get Folder Data error ', error);
        });
    }
  };

  @action
  getSharedSequence = async () => {
    const config = {
      url: URLS.salesgear.getSharedSequence,
      method: 'GET',
    };
    checkAndcancelSeqRequest();
    try {
      const res = await makeApi(config, false, cancelSeqRequestCbk);
      if (res && res.data) {
        this.setSequenceData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

const sequenceStoreData = new SequenceData();

export default sequenceStoreData;
