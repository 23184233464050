import React from 'react';
import { createPortal } from 'react-dom';
import { observable, action } from 'mobx';
import { EMPTY_FUNCTION } from 'Utils/constants';
import CloseIcon from 'Assets/svg/close-material.svg';
import Button from '../Button';
import './styles.scss';

class ModalState {
  @observable accessor showModal = false;

  @action
  setShowModal = (show) => {
    this.showModal = show;
  };
}

function Modal(props) {
  const {
    children,
    level = 1,
    show = false,
    closeModal = EMPTY_FUNCTION,
    className = '',
    align = 'left', // and 'center'
    modalRef,
    closeModalOnOutsideClick = true,
  } = props;

  return (
    show &&
    createPortal(
      <>
        <div
          className={`modal ${align} l-${level} ${className}`.trim()}
          tabIndex={-1}
          role="dialog"
          ref={modalRef}
        >
          <div className="modal-close">
            <Button
              onClick={closeModal}
              variant="transparent"
              buttonProps={{ text: <CloseIcon /> }}
            />
          </div>
          {children}
        </div>
        <div
          className="modal-backdrop"
          onClick={() => {
            if (closeModalOnOutsideClick) {
              closeModal();
            }
          }}
          aria-hidden="true"
        />
      </>,
      document.getElementById('root'),
    )
  );
}

function ModalTitle(props) {
  const { children } = props;
  return <p className="modal-title">{children}</p>;
}

function ModalDescription(props) {
  const { children } = props;
  return <div className="modal-content">{children}</div>;
}

function ModalIcon(props) {
  /**
   * other values for color 'success', 'error' as of now,
   * by default primary probably not needed in className
   */
  const { SvgIcon, color = 'primary' } = props;

  return (
    <div className={`modal-icon-wrapper ${color}`}>
      <SvgIcon />
    </div>
  );
}

function ModalFooter(props) {
  const { children } = props;

  return <div className="modal-footer">{children}</div>;
}

export { ModalState };

export default Object.assign(Modal, {
  Title: ModalTitle,
  Content: ModalDescription,
  Footer: ModalFooter,
  Icon: ModalIcon,
});
