import React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { EMPTY_FUNCTION } from 'Utils/constants';
import Button from '../Button';
import Modal, { ModalState } from '.';

const modalState = new ModalState();

const defaultModalData = {
  title: '',
  description: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  Icon: null,
  buttonCallback: EMPTY_FUNCTION,
  secondaryButtonCallback: EMPTY_FUNCTION,
  modalProps: { align: 'left', className: '', closeModalOnOutsideClick: true },
  iconProps: { color: 'primary' },
  closeCallback: EMPTY_FUNCTION,
};

class CommonModalState {
  modalData = defaultModalData;

  @action
  setShowModal = (show, data = {}) => {
    modalState.setShowModal(show);
    if (show) {
      this.modalData = { ...defaultModalData, ...data };
    } else {
      this.modalData = {};
    }
  };
}

const commonModalState = new CommonModalState();

function CommonModal() {
  const {
    Icon,
    title,
    description,
    primaryButtonText = 'Ok',
    buttonCallback,
    modalProps,
    iconProps,
    secondaryButtonText,
    secondaryButtonCallback,
    closeCallback,
  } = commonModalState.modalData;

  const { align, className, level, closeModalOnOutsideClick } = modalProps;
  const { color } = iconProps;

  const closeModal = () => {
    modalState.setShowModal(false);
    closeCallback();
  };

  return (
    <Modal
      align={align}
      level={level}
      className={className}
      show={modalState.showModal}
      closeModal={closeModal}
      closeModalOnOutsideClick={closeModalOnOutsideClick}
    >
      {Icon && <Modal.Icon color={color} SvgIcon={Icon} />}
      {title && <Modal.Title>{title}</Modal.Title>}
      <Modal.Content>{description}</Modal.Content>
      <Modal.Footer>
        {secondaryButtonText !== '' && (
          <Button
            variant="outline"
            buttonProps={{ text: secondaryButtonText }}
            onClick={async () => {
              await secondaryButtonCallback();
              closeModal();
            }}
          />
        )}
        <Button
          buttonProps={{ text: primaryButtonText }}
          onClick={async () => {
            await buttonCallback();
            closeModal();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export { commonModalState };

export default observer(CommonModal);
