import TechnologySectorIcon from 'Assets/svg/technology-sector.svg';
import CompanyInclusionIcon from 'Assets/svg/new_icons/company-inclusion.svg';
import FundingIcon from 'Assets/svg/new_icons/funding.svg';
import LeadershipHireIcon from 'Assets/svg/new_icons/leadership-hire.svg';
import filters from 'Pages/prospectSearch/filterSection/filters';
import { newFilterEvents } from 'Utils/constants';

const newFilterList = [
  {
    title: 'Technology Sector',
    description: 'Discover target accounts from 3,000+ micro-level sectors',
    Icon: TechnologySectorIcon,
    filterItem: filters[1].list[2],
    mxEvent: newFilterEvents.SECTOR_SEARCH,
  },
  {
    title: 'ABM List Building',
    description: 'Get decision makers in defined target account list',
    Icon: CompanyInclusionIcon,
    filterItem: filters[1].list[10],
    mxEvent: newFilterEvents.ABM_SEARCH,
  },
  {
    title: 'Funded Companies',
    description: 'Connect with decision makers in recently funded companies',
    Icon: FundingIcon,
    filterItem: filters[2].list[0],
    mxEvent: newFilterEvents.FUNDING_SEARCH,
  },
  {
    title: 'Job Change Alerts',
    description: 'Identify people who changed their job recently',
    Icon: LeadershipHireIcon,
    filterItem: filters[0].list[4],
    mxEvent: newFilterEvents.JOB_CHANGE_SEARCH,
  },
];

export default newFilterList;
