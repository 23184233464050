import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import NoSequenceImg from 'Assets/salesgear/png/no-sequence.png';
import Button from 'Components/common/base/Button';
import { SALESGEAR_URL, MX_EVENTS_AE } from 'Utils/constants';
import Utils from 'Utils/utils';

function NoSequenceComponent() {
  const redirectToSalesgear = () => {
    if (SALESGEAR_URL) {
      window.open(SALESGEAR_URL, '_blank', 'noopener');
    }
    Utils.mixpanelTrack(MX_EVENTS_AE.createSeqClicked);
  };

  useEffect(() => {
    Utils.mixpanelTrack(MX_EVENTS_AE.createSeqShown);
  }, []);

  return (
    <div className="empty-sequence">
      <img src={NoSequenceImg} alt="" />
      <span className="text">
        You don&#39;t have any sequences in this folder
      </span>
      <Button
        buttonProps={{ text: 'Create a Sequence' }}
        onClick={redirectToSalesgear}
      />
    </div>
  );
}

export default observer(NoSequenceComponent);
