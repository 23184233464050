import React, { useState } from 'react';
import SalesgearIcon from 'Assets/svg/export_icons/salesgear.svg';
import QuestionMark from 'Assets/svg/new_icons/question.svg';
import TickIcon from 'Assets/svg/new_icons/tick-outline.svg';
import profileState from 'Stores/profile';
import * as CONSTANTS from 'Utils/constants';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import AdaptIcon from 'Assets/svg/new_icons/adapt-logo.svg';
import Input from 'Components/common/base/Input';
import TwoIconConnector from 'Components/common/ExportLeads/TwoIconConnector';
import './styles.scss';

function SalesgearAPIConnect(props) {
  const { closePopup, saveButtonText = 'Export' } = props;
  const [showConnectSteps, setShowConnectSteps] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState('');

  const handleSave = async () => {
    if (formData !== '') {
      setShowLoader(true);
      const isSuccess = await profileState.connectToSalesgear(formData);
      setShowLoader(false);
      if (isSuccess) {
        closePopup(true);
      }
    }
  };
  const cancelPopup = () => {
    closePopup(false);
  };

  const changeInputVal = (value) => {
    setFormData(value);
  };

  return (
    <Modal show closeModal={cancelPopup} className="salesgear-connect-popup">
      <TwoIconConnector firstIcon={AdaptIcon} secondIcon={SalesgearIcon} />
      <Modal.Title>Salesgear Export</Modal.Title>
      <Modal.Content>
        <div className="short-info">
          Now use your Salesgear API Key for connecting with Adapt
        </div>

        <Input
          id="createUpdateListbox"
          offsetValues={{ top: 0, bottom: 50 }}
          inputProps={{
            placeholder: 'Enter Salesgear API Key here.',
            value: formData,
            required: true,
          }}
          onChange={changeInputVal}
          label="API Key"
          className="api-key-wrap"
        />

        {showConnectSteps && (
          <div className="connect-section">
            <div className="question-title">
              Know how to get Salesgear API Key
            </div>
            <ul className="connect-steps">
              <li>
                <TickIcon />
                <span className="span-mr">Login to your</span>
                <a
                  href={`${CONSTANTS.SALESGEAR_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Salesgear
                </a>
                <span className="span-ml span-mr">
                  account and navigate to the
                </span>
                <a
                  href={`${CONSTANTS.SALESGEAR_URL}/settings/profile`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  settings page
                </a>
                .
              </li>
              <li>
                <TickIcon />
                <span>Copy the API key from the Salesgear settings page</span>
              </li>
              <li>
                <TickIcon />
                <span>
                  Add the API key to the text box above and click on Export.
                </span>
              </li>
            </ul>
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="action-btn">
          <Button
            variant="outline"
            buttonProps={{
              text: 'Cancel',
            }}
            onClick={cancelPopup}
          />
          <Button
            buttonProps={{
              text: saveButtonText,
              disabled: formData === '',
            }}
            isLoading={showLoader}
            onClick={handleSave}
          />
        </div>
        {!showConnectSteps && (
          <div
            className="question"
            onClick={() => {
              return setShowConnectSteps(true);
            }}
          >
            <span>How to get API Key</span>
            <QuestionMark />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default SalesgearAPIConnect;
