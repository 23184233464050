import React, { useEffect, useState } from 'react';
import UploadGif from 'Assets/gif/upload.gif';
import Modal from 'Components/common/base/Modal';
import { toasterState } from 'Components/common/base/Toaster';
import { makeApi, URLS } from 'Utils/apiUtil';
import BulkPopupMessage from './BulkPopupMessage';
import './styles.scss';

function BulkValidationPopup(props) {
  const { closePopup, selectedSeqId, bulkContactAddToSeq, contactIds } = props;

  const [errorDetails, setErrorDetails] = useState(false);
  const [showSkipContactsBtn, setShowSkipContactsBtn] = useState(false);
  const [showBulkUploadingLoader, setShowBulkUploadingLoader] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const skipAndUploadContact = (validContactIds) => {
    bulkContactAddToSeq(validContactIds);
  };

  const constructErrorDetails = (
    validCount,
    uploadedCount,
    missingFields,
    validContactIds,
  ) => {
    const tempObj = {
      validCount,
      uploadedCount,
      missingFields,
      validContactIds,
      cbk: skipAndUploadContact,
      closePopup,
    };
    return tempObj;
  };

  const handleBulkUploadContactError = (
    validCount,
    uploadedCount,
    missingFields,
    validContactIds,
  ) => {
    setShowBulkUploadingLoader(false);
    setShowMessage(true);
    if (validCount === 0) {
      setShowSkipContactsBtn(false);
    } else {
      setShowSkipContactsBtn(true);
    }

    setErrorDetails(
      constructErrorDetails(
        validCount,
        uploadedCount,
        missingFields,
        validContactIds,
      ),
    );
  };

  const validateMandatoryFields = async (validationPayload) => {
    // By default tracking enabled
    const payload = validationPayload;
    if (payload) {
      payload.sequenceId = selectedSeqId;
      const config = {
        url: URLS.salesgear.validateMandatoryFields,
        method: 'POST',
        data: payload,
      };

      const response = await makeApi(config);
      if (response && response.data) {
        const { validCount, uploadedCount, missingFields, validContactIds } =
          response.data;
        if (
          validCount === uploadedCount &&
          validContactIds &&
          validContactIds.length
        ) {
          bulkContactAddToSeq(validContactIds);
        } else {
          handleBulkUploadContactError(
            validCount,
            uploadedCount,
            missingFields,
            validContactIds,
          );
        }
      } else {
        closePopup();
        toasterState.setToastMsg(
          'Something went wrong. Please try again later',
        );
      }
    }
  };

  useEffect(() => {
    validateMandatoryFields(contactIds);
  }, [contactIds]);

  return (
    <Modal show className="mandatory-fields-wrapper">
      <div className="validation-container">
        {showBulkUploadingLoader && (
          <div className="generating-preview">
            <img src={UploadGif} alt="upload animation" />
            <div className="preview-text">
              We are processing your contacts. Please wait...
            </div>
          </div>
        )}
        {showMessage && !showBulkUploadingLoader && (
          <BulkPopupMessage
            errorDetails={errorDetails}
            showSkipContacts={showSkipContactsBtn}
            closePopup={closePopup}
          />
        )}
      </div>
    </Modal>
  );
}

export default BulkValidationPopup;
