import React, { useState } from 'react';
import DropDownIcon from 'Assets/svg/dropdown-icon.svg';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';

function Accordion(props) {
  const {
    title,
    children,
    className,
    isAccordionOpen,
    setIsAccordionOpen = EMPTY_FUNCTION,
    defaultOpen = false,
    renderIcon,
  } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const showContent = isAccordionOpen !== undefined ? isAccordionOpen : isOpen;

  const onAccordionClick = () => {
    setIsOpen(!showContent);
    setIsAccordionOpen(!showContent);
  };

  return (
    <div
      className={`accordion${showContent ? ' open' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="accordion-title" onClick={onAccordionClick}>
        <span className="title-text">{title}</span>
        {renderIcon ? renderIcon(showContent) : <DropDownIcon className="dropdown-icon" />}
      </div>
      <div className="content">{children}</div>
    </div>
  );
}

export default Accordion;
