import React from 'react';
import { observer } from 'mobx-react';
import Utils from 'Utils/utils';
import { makeApi, URLS } from 'Utils/apiUtil';
import * as CONSTANTS from 'Utils/constants';
import advSearchState from 'Stores/advSearch';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import LoadingWrapper from '../base/Loader';
import Modal from '../base/Modal';
import Button from '../base/Button';
import ownershipMappingState from './state';
import './styles.scss';
import Select from '../base/Select';

const { EXPORT_SOURCE, EXPORT_PAGE_SOURCE } = CONSTANTS;
const { ADVANCED_SEARCH } = EXPORT_SOURCE;

function AccountOwnerMapping(props) {
  const { ownershipData, showAssigned, onChange } = props;

  const showCount =
    ownershipMappingState.exportSource === EXPORT_SOURCE.MY_LEADS;

  return (
    <div className="account-owner-map-list">
      {ownershipData.accounts &&
        ownershipData.accounts.length > 0 &&
        ownershipData.accounts.map((accountItem) => {
          if (showAssigned) {
            return (
              accountItem.alreadyAssigned === true &&
              accountItem.domain !== '' &&
              accountItem.domain !== null && (
                <div className="account-item" key={accountItem.name}>
                  <div className="account-name">{accountItem.name}</div>
                  <div className="account-owner">{accountItem.ownerName}</div>
                  {showCount && (
                    <div className="account-contacts">
                      {accountItem.contactsCount}
                    </div>
                  )}
                </div>
              )
            );
          }

          return (
            accountItem.alreadyAssigned === false &&
            accountItem.domain !== '' &&
            accountItem.domain !== null && (
              <div className="account-item" key={accountItem.name}>
                <div className="account-name">{accountItem.name}</div>
                <Select
                  placeholder="Choose from dropdown"
                  value={accountItem.ownerId}
                  options={[
                    { label: 'None', value: '' },
                    ...ownershipData.users.map((data) => {
                      return { label: data.name, value: data.id };
                    }),
                  ]}
                  onChange={(value) => {
                    const name = value
                      ? ownershipData.users.find((user) => {
                          return user.id === value;
                        })?.name
                      : '';
                    onChange(accountItem.domain, value, name);
                  }}
                />
                {showCount && (
                  <div className="account-contacts">
                    {accountItem.contactsCount}
                  </div>
                )}
              </div>
            )
          );
        })}
    </div>
  );
}

function OwnershipMappingPopup() {
  const [ownershipData, setOwnershipData] = React.useState({});
  const [ownerShipOriginalData, setOwnerShipOriginalData] = React.useState({});
  const [hasAlreadyAssigned, setHasAlreadyAssigned] = React.useState(false);
  const [hasAlreadyNotAssigned, setHasAlreadyNotAssigned] =
    React.useState(false);
  const [assignAllUnAssigned, setAssignAllUnAssigned] = React.useState('');
  const [assignAll, setAssignAll] = React.useState('');

  // Until this state is true, error message shown, anyhow API is made
  const [showLoading, setShowLoading] = React.useState(true);

  const getOwnerName = (data, ownerId) => {
    if (ownerId !== undefined && ownerId !== null && ownerId !== '') {
      if (data && data.users && data.users.length > 0) {
        let ownerName = '';
        data.users.forEach((item) => {
          if (item.id === ownerId) {
            ownerName = item.name;
          }
        });
        return ownerName;
      }
    }

    return '';
  };

  const chooseAssignAll = (id) => {
    setAssignAllUnAssigned('');
    const formattedData = Utils.copyObjectWithoutReference(ownershipData);

    let newAccounts;
    const ownerName = getOwnerName(formattedData, id);
    if (
      formattedData &&
      formattedData.accounts &&
      formattedData.accounts.length > 0
    ) {
      newAccounts = formattedData.accounts.map((item) => {
        const newItem = item;

        if (!newItem.alreadyAssigned) {
          newItem.ownerName = ownerName;
          newItem.ownerId = id;
        }

        return newItem;
      });
      formattedData.accounts = newAccounts;
    }
    setOwnershipData(formattedData);
  };

  const chooseAssignAllUnassigned = (id) => {
    setAssignAll('');
    const formattedData = Utils.copyObjectWithoutReference(ownershipData);

    let newAccounts;
    const ownerName = getOwnerName(formattedData, id);
    if (
      formattedData &&
      formattedData.accounts &&
      formattedData.accounts.length > 0
    ) {
      newAccounts = formattedData.accounts.map((item) => {
        const newItem = item;

        if (newItem.ownerName === '' && !newItem.alreadyAssigned) {
          newItem.ownerName = ownerName;
          newItem.ownerId = id;
        }

        return newItem;
      });
      formattedData.accounts = newAccounts;
    }
    setOwnershipData(formattedData);
  };

  const chooseOwnerSeparately = (accountDomain, id, name) => {
    const newFormattedData = Utils.copyObjectWithoutReference(ownershipData);

    if (
      newFormattedData &&
      newFormattedData.accounts &&
      newFormattedData.accounts.length > 0
    ) {
      const newAccounts = newFormattedData.accounts.map((item) => {
        const newItem = item;
        if (accountDomain === item.domain) {
          newItem.ownerName = name;
          newItem.ownerId = id;
        }
        return newItem;
      });

      newFormattedData.accounts = newAccounts;
      setOwnershipData(newFormattedData);
    }
  };

  const confirmClick = () => {
    const postData = {};

    if (ownershipData && ownershipData.accounts) {
      ownershipData.accounts.forEach((item) => {
        if (item.ownerId !== '') {
          postData[item.domain] = item.ownerId;
        }
      });
    }
    ownershipMappingState.exportCallback(postData);
    ownershipMappingState.setShowPopup(false);
  };

  const continueWithoutExport = () => {
    ownershipMappingState.cancelCallback();
    ownershipMappingState.setShowPopup(false);
  };

  const formatOwnershipData = (data) => {
    const formattedData = JSON.parse(JSON.stringify(data));

    let newAccounts = [];
    if (data && data.accounts && data.accounts.length > 0) {
      newAccounts = data.accounts.map((item) => {
        const newItem = item;
        newItem.ownerName = getOwnerName(data, item.ownerId);
        if (newItem.ownerName !== '') {
          // console.log('item newItem if', JSON.parse(JSON.stringify(newItem)));
          // console.log('hasAlreadyAssigned ', hasAlreadyAssigned);
          newItem.alreadyAssigned = true;
          if (!hasAlreadyAssigned) {
            setHasAlreadyAssigned(true);
          }
        } else {
          // console.log('item newItem else', JSON.parse(JSON.stringify(newItem)));
          // console.log('hasAlreadyNotAssigned ', hasAlreadyNotAssigned);
          newItem.alreadyAssigned = false;
          if (!hasAlreadyNotAssigned) {
            setHasAlreadyNotAssigned(true);
          }
        }
        newItem.ownerId = '';
        return newItem;
      });
      formattedData.accounts = newAccounts;
    }
    setOwnershipData(formattedData);
  };

  const getOwnershipData = () => {
    let config = {};
    const {
      companyExport = false,
      exportType,
      exportSource,
      postData: exportPostData,
    } = ownershipMappingState;

    let postData = {};

    const { requestType } = exportPostData;

    if (exportSource === ADVANCED_SEARCH && !companyExport) {
      postData = advSearchState.getExportIdsPostData();
      postData.euContacts = featureFlagsAndPreferencesState.isEUSearchEnabled();
    } else {
      postData = Utils.copyObjectWithoutReference(
        ownershipMappingState.postData,
      );
      postData.exportSource = exportSource;
    }

    const url = Utils.generateUrl(URLS?.crmExport?.accountOwnershipMapping, {
      crmType: exportType.toUpperCase(),
      type: requestType,
      source:
        exportSource === ADVANCED_SEARCH
          ? EXPORT_PAGE_SOURCE.ADVANCED_SEARCH
          : EXPORT_PAGE_SOURCE.MY_LEADS,
    });

    config = {
      url,
      method: 'POST',
      data: postData,
    };

    setShowLoading(true);
    makeApi(config)
      .then((response) => {
        if (response && response.data) {
          const { responseCode = '', responseData } = response.data;
          if (responseCode === 200) {
            setOwnerShipOriginalData(JSON.parse(JSON.stringify(responseData)));
            formatOwnershipData(responseData);
          }
        }
        setShowLoading(false);
      })
      .catch((error) => {
        console.log('setOwnershipData error ', error);
      });
  };

  const resetToDefault = () => {
    formatOwnershipData(JSON.parse(JSON.stringify(ownerShipOriginalData)));
    setAssignAll('');
    setAssignAllUnAssigned('');
  };

  React.useEffect(() => {
    if (ownershipMappingState.showPopup) {
      setAssignAll('');
      setAssignAllUnAssigned('');
      setOwnershipData({});
      setOwnerShipOriginalData({});
      setHasAlreadyAssigned(false);
      setHasAlreadyNotAssigned(false);
      getOwnershipData();
    } else {
      setAssignAll('');
      setAssignAllUnAssigned('');
      setOwnershipData({});
      setOwnerShipOriginalData({});
      setHasAlreadyAssigned(false);
      setHasAlreadyNotAssigned(false);
      setShowLoading(true);
    }
  }, [ownershipMappingState.showPopup]);

  const showCount =
    ownershipMappingState.exportSource === EXPORT_SOURCE.MY_LEADS;

  return (
    ownershipMappingState.showPopup && (
      <Modal
        className="ownership-mapping-modal"
        show
        closeModal={() => {
          ownershipMappingState.setShowPopup(false);
        }}
      >
        <LoadingWrapper show={showLoading} />
        <Modal.Title>
          <span>Assign Owners</span>
          {hasAlreadyNotAssigned && (
            <Button
              buttonProps={{ text: 'Reset' }}
              variant="outline"
              onClick={resetToDefault}
            />
          )}
        </Modal.Title>
        <Modal.Content>
          {!showLoading && (
            <div className="export-sequence-wrap">
              {hasAlreadyNotAssigned && (
                <div className="common-selection-wrap">
                  <div className="assign-wrapper">
                    <div className="assign-label">
                      {`Assign all unassigned ${
                        ownershipMappingState.exportType === 'Salesforce'
                          ? 'accounts'
                          : 'companies'
                      } to`}
                    </div>
                    <Select
                      value={assignAllUnAssigned}
                      placeholder="Select From Dropdown"
                      options={ownershipData.users.map((data) => {
                        return { label: data.name, value: data.id };
                      })}
                      onChange={(value) => {
                        chooseAssignAllUnassigned(value);
                        setAssignAllUnAssigned(value);
                      }}
                    />
                  </div>
                  <div className="assign-wrapper">
                    <div className="assign-label">
                      {`Assign all ${
                        ownershipMappingState.exportType === 'Salesforce'
                          ? 'accounts'
                          : 'companies'
                      } to`}
                    </div>
                    <Select
                      value={assignAll}
                      placeholder="Select From Dropdown"
                      options={ownershipData.users.map((data) => {
                        return { label: data.name, value: data.id };
                      })}
                      onChange={(value) => {
                        chooseAssignAll(value);
                        setAssignAll(value);
                      }}
                    />
                  </div>
                </div>
              )}
              {hasAlreadyNotAssigned && (
                <div
                  className={`account-owner-map${showCount ? ' show-count' : ''}`}
                >
                  <div className="header">
                    <div>
                      {ownershipMappingState.exportType === 'Salesforce'
                        ? 'Account'
                        : 'Company'}
                    </div>
                    <div>Owner</div>
                    {showCount && <div>No.of Contacts</div>}
                  </div>
                  <AccountOwnerMapping
                    ownershipData={ownershipData}
                    showAssigned={false}
                    onChange={chooseOwnerSeparately}
                  />
                </div>
              )}
              {!hasAlreadyAssigned && !hasAlreadyNotAssigned && (
                <div>
                  Unable to retrieve{' '}
                  <span className="span-mr">
                    {ownershipMappingState.exportType === 'Salesforce'
                      ? 'account'
                      : 'company'}
                  </span>
                  details from your CRM. You can try exporting without assigning
                  owners.
                  <br /> <br />
                  Please contact{' '}
                  <a
                    href="mailto:support@adapt.io"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    support@adapt.io
                  </a>{' '}
                  if this issue persists.
                </div>
              )}
              {hasAlreadyAssigned && (
                <>
                  {hasAlreadyNotAssigned ? (
                    <div className="account-already-assigned-title">
                      {ownershipMappingState.exportType === 'Salesforce'
                        ? 'Accounts'
                        : 'Companies'}{' '}
                      with owners already assigned in your CRM
                    </div>
                  ) : (
                    <div className="all-account-already-assigned-title">
                      All{' '}
                      {ownershipMappingState.exportType === 'Salesforce'
                        ? 'accounts'
                        : 'companies'}{' '}
                      already have owners assigned in your CRM
                    </div>
                  )}
                  <div
                    className={`account-owner-map assigned${showCount ? ' show-count' : ''}`}
                  >
                    <div className="header">
                      <div>
                        {ownershipMappingState.exportType === 'Salesforce'
                          ? 'Account'
                          : 'Company'}
                      </div>
                      <div>Owner</div>
                      {showCount && <div>No.of Contacts</div>}
                    </div>
                    <AccountOwnerMapping
                      ownershipData={ownershipData}
                      showAssigned
                      onChange={chooseOwnerSeparately}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </Modal.Content>
        <Modal.Footer>
          {hasAlreadyNotAssigned && (
            <Button
              buttonProps={{ text: 'Continue without Assigning Owners' }}
              variant="outline"
              onClick={continueWithoutExport}
            />
          )}
          <Button buttonProps={{ text: 'Export' }} onClick={confirmClick} />
        </Modal.Footer>
      </Modal>
    )
  );
}

export default observer(OwnershipMappingPopup);
