import React from 'react';
import TickIcon from 'Assets/svg/tick-icon.svg';
import TickIconRound from 'Assets/svg/tick-icon-new.svg';
import Button from 'Components/common/base/Button';
import Utils from 'Utils/utils';
import './styles.scss';

const pricingPlanFeatures = [
  {
    title: 'Free Includes:',
    list: [
      {
        text: 'Chrome Extension',
        tooltip:
          'Find verified email addresses from anywhere online with our Email Finder extension',
      },
      {
        text: 'Advanced Filters',
        tooltip:
          'Search for companies and contacts using variety of filter options like job title, department, level, employee count, revenue and location.',
      },
      {
        text: 'List Building',
        tooltip:
          'Organize and group your contacts into contact lists for efficient management of purchased contacts.',
      },
    ],
    support: ['Email Support'],
  },
  {
    title: 'Everything in Free plan, plus',
    list: [
      {
        text: 'CSV Export',
        tooltip:
          'Download your contacts to a spreadsheet and import them in to your CRM and outreach platforms.',
      },
      {
        text: 'Technology Sector',
        tooltip:
          'Segment companies using technology sectors like SaaS, Fintech, Web3.0, Generative AI and 3,000+ sectors.',
      },
      {
        text: 'Saved Search',
        tooltip:
          'Create alters and get notified whenever we get add new contacts matching your criteria',
      },
      {
        text: 'ABM List Building',
        tooltip:
          'Search for contacts in multiple companies by uploading list of company websites.',
      },
    ],
    support: ['Priority Email Support'],
  },
  {
    title: 'Everything in Starter plan, plus',
    popular: true,
    list: [
      {
        text: 'Phone Numbers',
        tooltip: 'Get mobile numbers for cold calling outreach',
      },
      {
        text: 'CRM Export',
        tooltip:
          'Export data from Adapt to Salesforce, Hubspot, Pipedrive, Zoho, Outreach and Salesgear',
      },
      {
        text: 'Limit Contact per Company',
        tooltip:
          'Set the limit on number of contacts you can download per company when building email lists for AMB campaigns.',
      },
      {
        text: 'Basic Reporting',
        tooltip: 'Check your account usage on a monthly basis',
      },
    ],
    support: ['Priority Email Support'],
  },
  {
    title: 'Everything in Basic plan, plus',
    list: [
      {
        text: 'Suppression List',
        tooltip:
          'Exclude contacts that you already own and in your CRM from search results to avoid duplication.',
      },
      {
        text: 'VC Funding',
        tooltip:
          'Discover contacts and companies of VC funded companies. Filter by funding date, amount and round type.',
      },
      {
        text: 'Job Change Alerts',
        tooltip: 'Find contacts that recently started a new job',
      },
      {
        text: 'Department wise growth',
        tooltip:
          'Identify contacts in fast growing companies using employee count change both overall and in specific departments.',
      },
      {
        text: 'Team Management',
        tooltip:
          'Manage teams with ease with monthly credit allocation rules to ensure there is no over-usage.',
      },
      {
        text: 'Advanced Reporting',
        tooltip:
          'Get detailed usage report of your team members via configurable weekly/monthly email reports',
      },
      {
        text: 'API Access',
        tooltip:
          'Use our API suite and seamlessly integrate out data with your systems.',
      },
    ],
    support: [
      'Dedicated Account Manager',
      'Onboarding Support',
      'Priority Email Support',
      'Chat & Call Support',
    ],
  },
];

function Credits(props) {
  const { credits, text } = props;
  return (
    credits > 0 && (
      <p className="credits-value">
        <span className="credits-bold">{`${Utils.numberFormat(credits)} ${text}`}</span>
        <span> Credits</span>
      </p>
    )
  );
}

function PricingCard(props) {
  const { activeTab, pricingPlans, upgradeCallback, existingPlanType } = props;
  return (
    <div className="pricing-card">
      {pricingPlans?.map((pricingData, index) => {
        const {
          lbCredits,
          phoneCredits,
          enrichmentCredits = 0,
          name,
          price,
          annualPrice,
          id,
          fairUsageDailyLbCreditLimit,
        } = pricingData;

        const pricingFeatures = pricingPlanFeatures[index] ?? {};

        const isEnterprisePlan = name === 'CUSTOM';

        const isFreePlan = name === 'FREE';

        const isMonthlySelected = activeTab === 0;

        return (
          <div
            className={`pricing-card-item ${
              pricingFeatures.popular ? 'border' : ''
            }`}
            key={id}
          >
            {pricingFeatures.popular && (
              <div className="popular-text">Popular</div>
            )}
            <div className="pricing-and-credits">
              <div className="pricing-title">{name.toLowerCase()}</div>
              <div className="pricing-value-per">
                {isEnterprisePlan ? (
                  <span className="contact-us">Contact Us</span>
                ) : (
                  <>
                    <span className="price-value">
                      ${isMonthlySelected ? price : annualPrice}
                    </span>
                    <span className="price-per-text">
                      {isMonthlySelected ? '/month' : '/year'}
                    </span>
                  </>
                )}
              </div>
              {isFreePlan && existingPlanType === '' ? (
                <Button
                  buttonProps={{
                    text: 'Current Plan',
                    className: 'active-plan',
                  }}
                  Icon={TickIconRound}
                />
              ) : (
                !isFreePlan && (
                  <Button
                    variant="primary"
                    buttonProps={{
                      text: isEnterprisePlan ? 'Talk to Sales' : 'Upgrade Now',
                      className: 'upgrade-now',
                    }}
                    onClick={() => {
                      upgradeCallback(isEnterprisePlan, pricingData);
                    }}
                  />
                )
              )}
              {isEnterprisePlan ? (
                <div className="custom-credits-text">
                  Custom plan based on your needs
                </div>
              ) : (
                <>
                  <Credits
                    credits={isMonthlySelected ? lbCredits : lbCredits * 12}
                    text="Email"
                  />
                  <Credits
                    credits={
                      isMonthlySelected ? phoneCredits : phoneCredits * 12
                    }
                    text="Phone"
                  />
                  <Credits
                    credits={
                      isMonthlySelected
                        ? enrichmentCredits
                        : enrichmentCredits * 12
                    }
                    text="Enrichment"
                  />
                  <p className="credits-value">
                    <span>Usage Limit: </span>
                    <span className="credits-bold">{`${Utils.numberFormat(fairUsageDailyLbCreditLimit)} Contacts`}</span>
                    <span> per day</span>
                  </p>
                </>
              )}
            </div>
            <div className="features-wrapper">
              <div className="features-title">{pricingFeatures.title}</div>
              <div className="features-list">
                {pricingFeatures.list?.map((featureText) => {
                  const { text, tooltip } = featureText;
                  return (
                    <div className="feature-text-wrapper" key={text}>
                      <TickIcon className="tick-icon" />
                      <span>{text}</span>
                      <div className="feature-tooltip">{tooltip}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="support-list">
              {pricingFeatures.support?.map((supportText) => {
                return (
                  <div className="support-text-wrapper" key={supportText}>
                    <TickIcon className="tick-icon" />
                    <span>{supportText}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PricingCard;
