import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import LoadingWrapper from 'Components/common/base/Loader';
import SalesgearAPIConnect from 'Components/salesgear/SalesgearAPIConnect';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import Title from 'Components/common/base/Title';
import PageContentHeader from 'Components/common/PageContentHeader';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import profileState from 'Stores/profile';
import crmIntegrationState from 'Stores/crmIntegration';
import userDetail from 'Stores/userDetail';
import enrichmentState, { enrichmentSteps } from 'Stores/enrichment';
import * as CONSTANTS from 'Utils/constants';
import IntegrationItemCard from './IntegrationItemCard';
import integrationDataMap from './integrationDataMap';
import IntegrationPopup from './integrationPopup';
import './styles.scss';

const { UPGRADE_TRIGGERS } = CONSTANTS;

function Integration() {
  const navigate = useNavigate();
  const [showSGPopup, setShowSGPopup] = useState(false);
  const [integrationMap, setIntegrationMap] = useState({});
  const [integrationPopup, setIntegrationPopupData] = useState({
    show: false,
    data: {},
  });
  const { allowedCrms, crmConnectionStatus } = crmIntegrationState;

  useEffect(() => {
    crmIntegrationState.getCrmConnectionDetails();
  }, []);

  const successCallback = (crmType) => {
    navigate(`/integrations/${crmType.toLowerCase()}/field-mapping/contacts`);
  };

  const connectToCrmClick = (e, crmType) => {
    if (e) {
      e.preventDefault();
    }

    if (!featureFlagsAndPreferencesState.isCRMExportEnabled()) {
      commonUpgradePopupState.setShowUpgradePopup(UPGRADE_TRIGGERS.CRM_EXPORT);
    } else if (CONSTANTS.NEW_INTEGRATION_NOT_AVAILABLE_CRMS.includes(crmType)) {
      const crmData = integrationDataMap[crmType];
      setIntegrationPopupData({ show: true, data: crmData });
    } else if (crmType === 'SALESGEAR') {
      setShowSGPopup(true);
    } else {
      crmIntegrationState.connectToCrm({
        crmType,
        successCallback: () => {
          return successCallback(crmType);
        },
      });
    }
  };

  const disconnectCrmClick = (e, crmType) => {
    if (e) {
      e.preventDefault();
    }

    const callback = async () => {
      if (crmType === 'SALESGEAR') {
        crmIntegrationState.isLoading = true;
        const isSuccess = await profileState.deleteSalesgearAPIKey();
        crmIntegrationState.isLoading = false;
        if (isSuccess) {
          crmIntegrationState.setCrmConnectionStatus({ [crmType]: false });
        }
      } else {
        // for showing enrichment steps again
        if (crmType === 'HUBSPOT') {
          enrichmentState.setEnrichmentStepsCompleted(enrichmentSteps);
        }
        crmIntegrationState.disconnectCrm(crmType);
      }
    };
    const isEnterpriseAdmin = userDetail.userInfo?.isEnterpriseAdmin;

    const crmName = integrationDataMap[crmType]?.title || crmType;

    const description =
      isEnterpriseAdmin &&
      !(crmType === 'SALESGEAR') &&
      userDetail.userInfo.noOfSeatsUsed > 1
        ? `As you disconnect, your team members will no longer be connected to your ${crmName} CRM. Do you want to proceed?`
        : 'Are you sure?';

    commonModalState.setShowModal(true, {
      title: `Disconnecting from your ${crmName} CRM`,
      description,
      primaryButtonText: isEnterpriseAdmin ? 'Yes' : 'Proceed',
      buttonCallback: callback,
      secondaryButtonText: 'Cancel',
    });
  };

  useEffect(() => {
    setIntegrationMap(integrationDataMap);
  }, []);

  return (
    <div className="integration-page">
      <Title title="CRM Integrations" />
      <PageContentHeader
        titleData={{
          text: 'Integrations',
          href: CONSTANTS.PAGE_PATH_MAP.DASHBOARD,
        }}
      />
      <div className="page-content">
        <LoadingWrapper show={crmIntegrationState.isLoading} position="fixed" />
        <div className="integration-content-wrapper">
          <div className="integration-list-wrapper">
            {allowedCrms.map((item, index) => {
              const dataMap = integrationMap[item] || null;
              if (dataMap) {
                return (
                  <IntegrationItemCard
                    key={`integration-item-${index + 1}`}
                    dataMap={dataMap}
                    integrationType={item}
                    isConnected={crmConnectionStatus[item] || false}
                    isLoading={false}
                    connectCallback={connectToCrmClick}
                    disconnectCallback={disconnectCrmClick}
                  />
                );
              }
              return '';
            })}
          </div>
        </div>
      </div>
      {integrationPopup.show && (
        <IntegrationPopup
          data={integrationPopup.data}
          closePopup={() => {
            return setIntegrationPopupData({ data: {}, show: false });
          }}
        />
      )}
      {showSGPopup && (
        <SalesgearAPIConnect
          saveButtonText="Connect"
          closePopup={(isSuccess) => {
            if (isSuccess) {
              crmIntegrationState.setCrmConnectionStatus({ SALESGEAR: true });
            }
            setShowSGPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default observer(Integration);
