import React from 'react';
import ContactUploadErrorIcon from 'Assets/salesgear/png/contactUploadError.png';
import Button from 'Components/common/base/Button';

function BulkPopupMessage(props) {
  const { errorDetails, showSkipContacts, closePopup } = props;

  const getMissingFieldsDom = (missingFields = []) => {
    const missingFieldsLen = missingFields?.length;
    return (
      <div className="missing-fields-wrapper">
        {missingFields &&
          missingFields.map((value, index) => {
            return (
              <span key={value} className="missing-field">
                {missingFieldsLen > 1 && (
                  <span className="index-value"> {index + 1}.&nbsp;</span>
                )}
                <span className="value">{value}</span>
                {index + 1 !== missingFieldsLen && (
                  <span className="delimiter">|</span>
                )}
              </span>
            );
          })}
      </div>
    );
  };

  const partialFieldsMissingDom = (
    uploadedCount = 0,
    validCount = 0,
    missingFields = [],
  ) => {
    const missingFieldsLen = missingFields?.length || 0;
    const contactsText = uploadedCount > 1 ? 'contacts' : 'contact';
    const fieldText = missingFieldsLen > 1 ? 'fields' : 'field';
    const invalidContactCount = Math.abs(uploadedCount - validCount);
    return (
      <div className="partial-missing-fields">
        <div className="message-wrapper">
          <div className="message">
            You have &nbsp;
            <b>
              selected {uploadedCount} {contactsText}
            </b>{' '}
            and <b>{invalidContactCount} of them </b>
            don't have the following mandatory {fieldText}
          </div>
          {getMissingFieldsDom(missingFields)}
        </div>
      </div>
    );
  };

  const constructAllFieldsMissingDom = (
    missingFields = [],
    uploadedCount = 0,
  ) => {
    const missingFieldsLen = missingFields?.length;
    const contactText = uploadedCount > 1 ? 'contacts' : 'contact';
    const fieldText = missingFieldsLen > 1 ? 'fields are' : 'field is';
    return (
      <div className="all-fields-missing-wrap">
        <div className="message-wrapper">
          <div className="message">
            Sorry, we cannot add the selected {contactText} to the sequence as
            the following mandatory {fieldText} missing
          </div>
          {getMissingFieldsDom(missingFields)}
        </div>
      </div>
    );
  };

  const getMessage = () => {
    if (errorDetails && Object.keys(errorDetails).length) {
      const { missingFields, validCount, uploadedCount } = errorDetails;

      if (validCount === 0 && uploadedCount > 0) {
        return constructAllFieldsMissingDom(missingFields, uploadedCount);
      }
      return partialFieldsMissingDom(uploadedCount, validCount, missingFields);
    }
  };

  const getSkipContactBtn = () => {
    const skipAndUpload = () => {
      const { cbk, validContactIds } = errorDetails;
      cbk(validContactIds);
    };

    return (
      <div className="button-wrapper">
        <Button
          buttonProps={{ text: 'Cancel' }}
          variant="outline"
          onClick={closePopup}
        />
        <Button
          buttonProps={{
            text: 'Skip the contacts with missing fields and proceed',
          }}
          onClick={skipAndUpload}
        />
      </div>
    );
  };

  const getOkBtn = () => {
    return (
      <div className="button-wrapper">
        <Button buttonProps={{ text: 'Ok' }} onClick={closePopup} />
      </div>
    );
  };

  return (
    <div className="bulk-popup-message">
      <div className="image-wrapper">
        <img src={ContactUploadErrorIcon} alt="" className="contactErrorIcon" />
      </div>
      <div className="error-message">{getMessage()}</div>
      {errorDetails && (
        <div className="bottom-wrapper">
          {showSkipContacts ? getSkipContactBtn() : getOkBtn()}
        </div>
      )}
    </div>
  );
}

export default BulkPopupMessage;
