import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import RightArrow from 'Assets/svg/right-arrow.svg';
import Button from 'Components/common/base/Button';
import { ModalState } from 'Components/common/base/Modal';
import filterState from 'Pages/prospectSearch/filterSection/state';
import Utils from 'Utils/utils';
import { MX_DASHBOARD_PAGE_EVENTS } from 'Utils/constants';
import useCases from './fixtures';
import UseCaseModal from './UseCaseModal';
import './styles.scss';

const useCasePopupState = new ModalState();

function UseCaseContent(props) {
  const { Icon, title } = props;
  return (
    <>
      <Icon />
      <span className="text">{title}</span>
      <RightArrow className="right-arrow" />
    </>
  );
}

function UseCases() {
  const mixpanelEvent = (title) => {
    Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.USE_CASE_CLICKED, {
      text: title,
      type: 'page',
    });
  };

  useEffect(() => {
    if (Utils.getCookie('trial_activate_success') === 'true') {
      useCasePopupState.setShowModal(true);
    }
  }, []);

  return (
    <div className="use-cases-card">
      <h2>Popular Use-Cases</h2>
      <div className="use-cases-list">
        {[useCases[0], useCases[2], useCases[4], useCases[6], useCases[3]].map(
          (useCase) => {
            const { title, link, internalLink, Icon, filterItem } = useCase;
            return (
              <div className="use-case" key={title}>
                {link ? (
                  <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      mixpanelEvent(title);
                    }}
                  >
                    <UseCaseContent Icon={Icon} title={title} />
                  </a>
                ) : (
                  <Link
                    to={internalLink}
                    onClick={() => {
                      mixpanelEvent(title);
                      if (filterItem !== undefined) {
                        setTimeout(() => {
                          filterState.setSelectedFilter(filterItem);
                        }, 300);
                      }
                    }}
                  >
                    <UseCaseContent Icon={Icon} title={title} />
                  </Link>
                )}
              </div>
            );
          },
        )}
      </div>
      <Button
        buttonProps={{ text: 'View all use-cases', className: 'view-all' }}
        onClick={() => {
          Utils.mixpanelTrack(MX_DASHBOARD_PAGE_EVENTS.VIEW_ALL_USE_CASES);
          useCasePopupState.setShowModal(true);
        }}
        Icon={RightArrow}
        variant="transparent"
      />
      {useCasePopupState.showModal && (
        <UseCaseModal
          closeModal={() => {
            useCasePopupState.setShowModal(false);
          }}
        />
      )}
    </div>
  );
}

export default observer(UseCases);
