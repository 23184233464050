import React from 'react';
import TickIcon from 'Assets/svg/new_icons/tick-outline.svg';
import InfoIcon from 'Assets/svg/info-icon.svg';
import Button from 'Components/common/base/Button';
import Modal from 'Components/common/base/Modal';
import Utils from 'Utils/utils';
import './styles.scss';

function CsvEnrichmentInitiatedPopup(props) {
  const { togglePopup } = props;

  return (
    <Modal
      align="center"
      className="csv-enrichment-initiated"
      show
      closeModal={togglePopup}
    >
      <Modal.Icon SvgIcon={TickIcon} />
      <Modal.Title>CSV Enrichment Initiated</Modal.Title>
      <Modal.Content className="description">
        <div className="message">
          Your file is uploaded successfully and the enrichment process has
          begun.
        </div>
        <section className="note">
          <InfoIcon className="info-icon" />
          <span>
            <span>This will take a few minutes. You will be notified at</span>{' '}
            <span className="highlight">{Utils.getUserEmail()}</span>{' '}
            <span>once the enrichment is complete.</span>
          </span>
        </section>
      </Modal.Content>
      <Modal.Footer>
        <Button buttonProps={{ text: 'Ok' }} onClick={togglePopup} />
      </Modal.Footer>
    </Modal>
  );
}

export default CsvEnrichmentInitiatedPopup;
