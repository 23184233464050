import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useMatch, Outlet } from 'react-router-dom';
import EnrichmentLogIcon from 'Assets/svg/new_icons/enrichment-log.svg';
import SettingIcon from 'Assets/svg/new_icons/setting.svg';
import TickIcon from 'Assets/svg/tick-icon.svg';
import enrichmentState from 'Stores/enrichment';
import crmIntegrationState from 'Stores/crmIntegration';
import SideBarNavigation from 'Components/common/SideBarNavigation';
import LoadingWrapper from 'Components/common/base/Loader';
import Title from 'Components/common/base/Title';
import PageContentHeader from 'Components/common/PageContentHeader';
import integrationDataMap from 'Pages/integration/integrationDataMap';
import Utils from 'Utils/utils';
import {
  EMPTY_FUNCTION,
  MX_ENRICHMENT_EVENTS,
  UPGRADE_TRIGGERS,
} from 'Utils/constants';
import FieldMapping from 'Pages/integration/FieldMapping';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import fieldMappingState from 'Stores/fieldMapping';
import userDetail from 'Stores/userDetail';
import ConnectYourCrm from './ConnectYourCrm';
import EnrichmentSettings from './enrichmentSettings';
import AutoEnrichmentInitiatedPopup from './autoEnrichmentInitiatedPopup';
import './styles.scss';

const getMenuData = (crmType) => {
  const showLeads =
    integrationDataMap[crmType.toUpperCase()]?.sublinks?.find((data) => {
      return data.linkKey === 'field-mapping';
    })?.subMenu?.length === 2 || false;

  return [
    {
      titleText: 'Enrichment Log',
      linkKey: 'enrichment-log',
      linkValue: `/enrichment/${crmType}/enrichment-log/contacts`,
      isNavLink: true,
      icon: EnrichmentLogIcon,
      ...(showLeads && {
        subMenu: [
          {
            title: 'Contacts',
            link: `/enrichment/${crmType}/enrichment-log/contacts`,
            API_KEY: 'CONTACT',
          },
          {
            title: 'Leads',
            link: `/enrichment/${crmType}/enrichment-log/leads`,
            API_KEY: 'LEAD',
          },
        ],
      }),
    },
    {
      titleText: 'Settings',
      linkKey: 'settings',
      linkValue: '/enrichment/hubspot/settings',
      isNavLink: true,
      icon: SettingIcon,
    },
  ];
};

function RenderSteps(props) {
  const { index, links, currentStep } = props;
  const stepCompleted = currentStep > index;

  return (
    <div
      className={`step-wrapper${currentStep === index ? ' active' : ''}${
        stepCompleted ? ' completed' : ''
      }`}
    >
      <div className="step-number">
        {stepCompleted ? (
          <TickIcon className="tick-icon" />
        ) : (
          <span>{index + 1}</span>
        )}
      </div>
      <div className="right-section">
        <div className="step-title">{`Step ${index + 1}`}</div>
        <div className="step-description">{links}</div>
      </div>
    </div>
  );
}

function EnrichmentRoutes() {
  const match = useMatch('/enrichment/:crmType/:menu?/:subMenu?');
  const { crmType = '', menu = '', subMenu = '' } = match?.params ?? {};

  const [currentStep, setCurrentStep] = useState(
    Utils.isNotUndefinedNotNull(userDetail.userInfo.hubspotEnrichmentSteps)
      ? Object.keys(userDetail.userInfo.hubspotEnrichmentSteps).findIndex(
          (key) => {
            return !userDetail.userInfo.hubspotEnrichmentSteps[key];
          },
        )
      : -1,
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showEnrichmentInitiatedPopup, setShowEnrichmentPopup] =
    useState(false);
  const [isEnrichmentSettingsFetched, setIsEnrichmentSettingsFetched] =
    useState(false);

  const { title, imgComponent, isSvg } =
    integrationDataMap[crmType.toUpperCase()];

  const isConnected =
    crmIntegrationState.crmConnectionStatus[crmType.toUpperCase()];

  const showConnectionSteps =
    currentStep > -1 && userDetail.userInfo.isEnterpriseAdmin;

  const data = {
    title,
    imgComponent,
    isSvg,
    sublinks: showConnectionSteps
      ? ['Connect your CRM', 'Field Mapping', 'Settings']
      : getMenuData(crmType),
  };

  const getConnectedStatus = async () => {
    if (!crmIntegrationState.apiStat.getAllStatusFetched) {
      await crmIntegrationState.getCrmConnectionDetails();
    }

    if (!crmIntegrationState.crmConnectionStatus[crmType.toUpperCase()]) {
      setCurrentStep(0);
    }

    if (crmIntegrationState.crmConnectionStatus[crmType.toUpperCase()]) {
      await fieldMappingState.getEnrichmentSettings(crmType.toUpperCase(), {
        notConnectedCallback: EMPTY_FUNCTION,
      });
      if (currentStep === 0) {
        setCurrentStep(1);
      }
    }
    setIsEnrichmentSettingsFetched(true);
  };

  const fieldMappingCallback = (success) => {
    if (success) {
      enrichmentState.setEnrichmentStepsCompleted({
        CONNECT: true,
        FIELD_MAPPING: true,
      });
      setCurrentStep(2);
    }
  };

  const enrichmentSettingsCallback = (success) => {
    if (success) {
      enrichmentState.setEnrichmentStepsCompleted({ SETTINGS: true });
      setShowEnrichmentPopup(true);
    }
  };

  useEffect(() => {
    if ([0, 1, 2].includes(currentStep)) {
      let eventName = '';
      if (currentStep === 0) {
        eventName = MX_ENRICHMENT_EVENTS.CRM_ENRICHMENT_CONNECT_STEP_SHOWN;
      } else if (currentStep === 1) {
        eventName = MX_ENRICHMENT_EVENTS.CRM_ENRICHMENT_MAPPING_STEP_SHOWN;
      } else {
        eventName = MX_ENRICHMENT_EVENTS.CRM_ENRICHMENT_SETTINGS_STEP_SHOWN;
      }

      Utils.mixpanelTrack(eventName, { type: crmType });
    }
  }, [currentStep]);

  React.useEffect(() => {
    getConnectedStatus();
  }, []);

  return (
    <div className="crm-enrichment-wrapper">
      <Title title={`${Utils.capitalize(crmType)} Enrichment`} />
      <PageContentHeader
        titleData={{ text: `${title} Enrichment`, href: '/enrichment' }}
      />
      <div
        className={`crm-enrichment-page-content${isConnected ? '' : ' disable'}`}
        onClickCapture={(event) => {
          if (!Utils.isCrmEnrichmentEnabled()) {
            event.stopPropagation();
            event.preventDefault();
            commonUpgradePopupState.setShowUpgradePopup(
              UPGRADE_TRIGGERS.CRM_ENRICHMENT,
            );
          }
        }}
      >
        <SideBarNavigation
          expandFirstMenu={!isConnected}
          collapseInactiveMenu
          data={data}
          customRenderList={
            showConnectionSteps
              ? // eslint-disable-next-line react/no-unstable-nested-components
                (listProps) => {
                  return (
                    <RenderSteps
                      key={listProps.links}
                      {...listProps}
                      currentStep={currentStep}
                    />
                  );
                }
              : null
          }
          activeMenu={isConnected ? menu : ''}
          selectedSubMenu={isConnected ? subMenu : ''}
          connectionStatus={isConnected ? 'Connected' : 'Not Connected'}
        />
        <div className="main-section">
          <LoadingWrapper show={showLoader || fieldMappingState.isLoading} />
          {((showConnectionSteps && currentStep === 0) || !isConnected) && (
            <ConnectYourCrm
              title="Connect your CRM"
              description={`Sync your ${title} CRM with Adapt and start enriching your records.`}
              linkText="Connect Now"
              crmLogoData={data}
              crmType={crmType}
              setShowLoader={setShowLoader}
              setCurrentStep={setCurrentStep}
            />
          )}
          {showConnectionSteps && currentStep === 1 && isConnected && (
            <FieldMapping callback={fieldMappingCallback} alwaysEnableButton />
          )}
          {showConnectionSteps && currentStep === 2 && isConnected && (
            <EnrichmentSettings
              callback={enrichmentSettingsCallback}
              animateSwitch
              showToast={false}
              enableEdit
              isEnrichmentSettingsFetched={isEnrichmentSettingsFetched}
            />
          )}
          {isConnected && !showConnectionSteps && (
            <Outlet context={{ isEnrichmentSettingsFetched }} />
          )}
        </div>
        {showEnrichmentInitiatedPopup && (
          <AutoEnrichmentInitiatedPopup
            togglePopup={() => {
              setShowEnrichmentPopup(false);
              setCurrentStep(-1);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default observer(EnrichmentRoutes);
