import DotIcon from 'Assets/png/green-dot.png';

const lineChartOptions = {
  tension: 0.3,
  responsive: true,
  maintainAspectRatio: true,
  clip: false,
  hover: {
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
      pointStyle: () => {
        const img = new Image();
        img.width = 12;
        img.height = 12;
        img.src = DotIcon;
        return img;
      },
    },
  },
  scales: {
    x: {
      offset: true,
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 15,
        color: 'rgb(47, 46, 49)',
      },
    },
    y: {
      ticks: {
        maxTicksLimit: 6,
        color: 'rgb(47, 46, 49)',
        padding: 10,
      },
      beginAtZero: true,
      grid: {
        drawBorder: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      position: 'bottom',
      labels: {
        padding: 20,
        boxWidth: 15,
        boxHeight: 10,
        color: 'rgb(47, 46, 49)',
        font: {
          size: 13,
          lineHeight: 1.33,
        },
      },
    },
  },
};

// https://jsfiddle.net/h4n8xzk3/1/
const plugins = [
  {
    afterDraw: (chart) => {
      const {
        tooltip,
        scales: { y },
        ctx,
      } = chart;

      // eslint-disable-next-line no-underscore-dangle
      if (tooltip?._active?.length) {
        const { _active } = tooltip || {};
        const { x } = _active[0].element;
        const yAxis = y;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yAxis.top);
        ctx.setLineDash([2, 2]);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#FB7D7D';
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];

export { lineChartOptions, plugins };
