/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams, useOutletContext } from 'react-router-dom';
import Select from 'Components/common/base/Select';
import Button from 'Components/common/base/Button';
import Title from 'Components/common/base/Title';
import ControlInput from 'Components/common/base/ControlInput';
import CRMPreferencesCard from 'Components/common/CRMPreferencesCard';
import fieldMappingState from 'Stores/fieldMapping';
import userDetail from 'Stores/userDetail';
import Utils from 'Utils/utils';
import integrationDataMap from '../integrationDataMap';
import './styles.scss';

let broadcastChannel;

// Essentially this component is just salesforce settings now, if needed make some adjustments
function CrmSettings() {
  const { crmType } = useParams();
  const { redirectToIntegrations } = useOutletContext();
  const [exportType, setExportType] = useState('');
  const [excludeContacts, setExcludeContacts] = useState(
    userDetail.userInfo?.teamCRMPreferences?.SALESFORCE
      ?.dont_purchase_if_present_in_crm,
  );
  const currentCRMData = integrationDataMap[crmType?.toUpperCase()];

  const hasLeads =
    currentCRMData?.sublinks?.find((data) => {
      return data.linkKey === 'field-mapping';
    })?.subMenu?.length === 2 || false;

  React.useEffect(() => {
    fieldMappingState.getEnrichmentSettings(crmType.toUpperCase(), {
      notConnectedCallback: redirectToIntegrations,
    });
    if ('BroadcastChannel' in self) {
      broadcastChannel = new BroadcastChannel('sf_exclude');
    }
    return () => {
      broadcastChannel?.close?.();
    };
  }, []);

  useEffect(() => {
    setExportType(fieldMappingState.enrichmentSettingsData.exportType);
    setExcludeContacts(
      fieldMappingState.enrichmentSettingsData.dont_purchase_if_present_in_crm,
    );
  }, [fieldMappingState.enrichmentSettingsData.exportType]);

  const hasEditAccess =
    fieldMappingState.enrichmentSettingsData?.isAdmin || false;
  const crmName =
    currentCRMData?.title
      ?.split(' ')
      ?.map((data) => {
        return `${data.substring(0, 1).toUpperCase()}${data.substring(1)}`;
      })
      ?.join(' ') ?? crmType;

  const isExportTypeUpdated =
    exportType &&
    exportType !== fieldMappingState.enrichmentSettingsData.exportType;
  const isExcludeContactUpdated =
    excludeContacts !==
    fieldMappingState.enrichmentSettingsData.dont_purchase_if_present_in_crm;

  const handleSave = async () => {
    const isSuccess = await fieldMappingState.updateEnrichmentSettings(
      crmType.toUpperCase(),
      {
        ...(isExportTypeUpdated && { exportType }),
        ...(isExcludeContactUpdated && {
          dont_purchase_if_present_in_crm: excludeContacts,
        }),
      },
    );
    if (isSuccess) {
      fieldMappingState.enrichmentSettingsData = {
        ...fieldMappingState.enrichmentSettingsData,
        exportType,
        dont_purchase_if_present_in_crm: excludeContacts,
      };
      userDetail.setUserInfo({
        ...userDetail.userInfo,
        teamCRMPreferences: {
          ...(userDetail.userInfo.teamCRMPreferences || {}),
          SALESFORCE: {
            ...(userDetail.userInfo.teamCRMPreferences?.SALESFORCE || {}),
            export_as: exportType.toLowerCase(),
            dont_purchase_if_present_in_crm: excludeContacts,
          },
        },
      });

      broadcastChannel?.postMessage({ excluded: true });
    }
  };

  const handleCancel = () => {
    setExportType(fieldMappingState.enrichmentSettingsData.exportType);
    setExcludeContacts(
      fieldMappingState.enrichmentSettingsData.dont_purchase_if_present_in_crm,
    );
  };

  const purchasePreference = () => {
    if (exportType) {
      setExcludeContacts(!excludeContacts);
    }
  };

  return (
    <div className={`crm-settings${hasEditAccess ? '' : ' no-edit'}`}>
      <Title title={`${Utils.capitalize(crmType)} Settings`} />
      <div className="settings-wrapper">
        {hasLeads && (
          <CRMPreferencesCard title="Export Preference" header="General">
            <div className="message">
              {`When exporting to ${crmName}, export as`}
            </div>
            <div className="pref-select-wrap">
              <Select
                selectWrapperProps={{ className: 'salesforce-export-pref' }}
                options={[
                  { label: 'Leads', value: 'LEAD' },
                  { label: 'Contacts', value: 'CONTACT' },
                ]}
                placeholder="Select"
                value={exportType}
                onChange={(value) => {
                  setExportType(value);
                }}
              />
            </div>
          </CRMPreferencesCard>
        )}
        <CRMPreferencesCard title="Purchase Preference">
          <ControlInput
            label={`When purchasing contacts, exclude contacts that are already present in Salesforce${
              exportType ? ` as a ${exportType.toLowerCase()}` : ''
            }`}
            type="checkbox"
            disabled={!exportType}
            onChange={purchasePreference}
            checked={excludeContacts}
          />
        </CRMPreferencesCard>
        {(isExportTypeUpdated || isExcludeContactUpdated) && (
          <div className="button-wrapper">
            <Button
              onClick={handleCancel}
              variant="outline"
              buttonProps={{
                text: 'Cancel',
              }}
            />
            <Button
              onClick={handleSave}
              buttonProps={{
                text: 'Save',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(CrmSettings);
