import { observable, action } from 'mobx';
import * as CONSTANTS from 'Utils/constants';
import { makeApi, URLS } from 'Utils/apiUtil';
import userDetail from 'Stores/userDetail';

class TrialUserOnboardingState {
  @observable accessor trialEndDays = '';

  @observable accessor memberRole = 'ADMIN';

  @observable accessor optionalFlowStepsToBeUpdate = {};

  @observable accessor optionalPopupShown = {
    daysLeftPopupShown: 'INCOMPLETE',
  };

  @observable accessor showOptionalPopup = {
    daysLeftPopup: false,
  };

  @action
  updateOptionalPopupShown(obj = {}) {
    const newobj = { ...this.optionalPopupShown, ...obj };
    this.optionalPopupShown = newobj;
  }

  @action
  setShowOptionalPopup(obj = {}) {
    const newobj = { ...this.showOptionalPopup, ...obj };
    this.showOptionalPopup = newobj;
  }

  @action
  setTrialInfoDetail(obj = {}) {
    const { daysLeft = null, memberRole = null } = obj;
    this.memberRole = memberRole;

    if (daysLeft !== null) {
      this.setTrialEndDays(parseInt(daysLeft, 10));
    }
  }

  @action
  setTrialEndDays(val = '') {
    this.trialEndDays = val;
  }

  @action
  upgradeNow(callback = () => {}) {
    const config = {
      url: URLS.trialUser.upgradeNow,
    };
    makeApi(config)
      .then(() => {
        callback();
      })
      .catch((error) => {
        callback();
        console.log('URLS.trialUser.upgradeNow error ', error);
      });
  }

  @action
  updateTrialOnboardingPopupActivity(
    isUpgradeRequest = false,
    stepsToUpdate = null,
    callback = () => {},
  ) {
    const thisObj = this;

    const config = {
      url: URLS.trialUser.updatePopupActivity,
      method: 'POST',
      data: {
        requestUpgrade: isUpgradeRequest,
        step:
          stepsToUpdate !== null
            ? stepsToUpdate
            : thisObj.optionalFlowStepsToBeUpdate,
      },
    };
    makeApi(config)
      .then((response) => {
        if (response && response.status === 200) {
          callback(true);
        } else {
          callback(false);
        }
      })
      .catch((error) => {
        callback(false);
        console.log('updatePopupActivity error ', error);
      });
  }

  @action
  handleOptionalFlows = async (userObj = {}, trialOnboardingObj = {}) => {
    const thisObj = this;

    const { popupDetails = null } = trialOnboardingObj;

    const { INCOMPLETE } = CONSTANTS.trialOnboarding.status;
    const { memberRole } = thisObj;

    // Optional flows only for Team Admin users
    if (memberRole === 'ADMIN' && popupDetails !== null) {
      thisObj.updateOptionalPopupShown(popupDetails);

      const { optionalPopupShown, trialEndDays } = thisObj;
      const { daysLeftPopupShown } = optionalPopupShown;

      const stepsToBeUpdated = {};

      // Days left popup
      if (trialEndDays <= 4 && daysLeftPopupShown === INCOMPLETE) {
        setTimeout(() => {
          userDetail.setShowNotificationType(
            CONSTANTS.NOTIFICATION_BAR_TYPE.TRIAL_DAYS_LEFT_NOTIFICATION,
          );
        }, 5000);
      }

      thisObj.optionalFlowStepsToBeUpdate = stepsToBeUpdated;
    }
  };
}

const trialUserOnboardingState = new TrialUserOnboardingState();

export default trialUserOnboardingState;
