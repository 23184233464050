import SalesforceExportIcon from 'Assets/svg/export_icons/salesforce.svg';
import HubspotExportIcon from 'Assets/svg/export_icons/hubspot.svg';
import PipedriveExportIcon from 'Assets/svg/export_icons/pipedrive.svg';
import ZohoExportIcon from 'Assets/svg/export_icons/zoho.svg';
import OutreachExportIcon from 'Assets/svg/export_icons/outreach.svg';
import MarketoExportIcon from 'Assets/svg/export_icons/marketo.svg';
import SalesgearIcon from 'Assets/svg/export_icons/salesgear.svg';
import SettingIcon from 'Assets/svg/new_icons/setting.svg';
import SubMenuIcon from 'Assets/svg/new_icons/sub-menu.svg';

const integrationDataMap = {
  SALESFORCE: {
    title: 'Salesforce',
    imgComponent: SalesforceExportIcon,
    isSvg: true,
    description:
      'Integrate Salesforce with Adapt. Ship potential lead/contact data right into your workspace and enrich your Salesforce Database at ease.',
    sublinks: [
      {
        titleText: 'Field Mapping',
        linkKey: 'field-mapping',
        linkValue: '/integrations/salesforce/field-mapping/contacts',
        isNavLink: true,
        icon: SubMenuIcon,
        subMenu: [
          {
            title: 'Contacts',
            link: '/integrations/salesforce/field-mapping/contacts',
            API_KEY: 'CONTACT',
          },
          {
            title: 'Leads',
            link: '/integrations/salesforce/field-mapping/leads',
            API_KEY: 'LEAD',
          },
        ],
      },
      {
        titleText: 'Settings',
        linkKey: 'settings',
        linkValue: '/integrations/salesforce/settings',
        isNavLink: true,
        icon: SettingIcon,
      },
    ],
  },
  HUBSPOT: {
    title: 'Hubspot',
    imgComponent: HubspotExportIcon,
    isSvg: true,
    description:
      'Empower your Hubspot CRM in seconds with the most valuable leads. Integrate Hubspot with Adapt and capture promising leads seamlessly.',
    sublinks: [
      {
        titleText: 'Field Mapping',
        linkKey: 'field-mapping',
        linkValue: '/integrations/hubspot/field-mapping/contacts',
        isNavLink: true,
        icon: SubMenuIcon,
        subMenu: [
          {
            title: 'Contacts',
            link: '/integrations/hubspot/field-mapping/contacts',
            API_KEY: 'CONTACT',
          },
        ],
      },
    ],
  },
  PIPEDRIVE: {
    title: 'Pipedrive',
    imgComponent: PipedriveExportIcon,
    isSvg: true,
    description:
      'Power your Pipedrive workflows with hot prospects from Adapt and land more opportunities.',
  },
  ZOHO: {
    title: 'Zoho',
    imgComponent: ZohoExportIcon,
    isSvg: true,
    description:
      'Connect to Zoho and power your sales workflows with promising leads and land deals effortlessly.',
  },
  OUTREACH: {
    title: 'Outreach',
    imgComponent: OutreachExportIcon,
    isSvg: true,
    description:
      'Connect to Outreach and remove deal risks right at the beginning with quality data from Adapt.',
  },
  MARKETO: {
    title: 'Marketo',
    imgComponent: MarketoExportIcon,
    isSvg: true,
    description:
      'Connect Adapt with Marketo and increase your conversions with fresh and accurate data from Adapt.',
  },
  SALESGEAR: {
    title: 'Salesgear',
    isSvg: true,
    imgComponent: SalesgearIcon,
    description:
      "Ensure all promising leads land on the right sequence. Accelerate your sales and effectively engage with your Prospective customers with Adapt's data.",
  },
};

export default integrationDataMap;
