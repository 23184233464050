import React, { useState, useRef, useEffect, useCallback } from 'react';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';
import Utils from 'Utils/utils';

/**
 * @param type 0 - UI moving the selection includes from start to selection
 * @param type 1 - UI moving the selection includes from selection to end
 */
function Slider(props) {
  const {
    list,
    type = 0,
    onChange = EMPTY_FUNCTION,
    value = '',
    rangeSelection = false,
  } = props;
  const { length } = list;

  const [activeSliderIndex, setActiveSliderIndex] = useState({
    left: 0,
    right: 0,
  });
  const [pointerData, setPointerData] = useState({
    active: false,
    type: 'left',
    stepPositions: [],
  });

  const sliderRef = useRef();

  const changeSliderPosition = Utils.debounce(
    useCallback((newValue) => {
      if (rangeSelection) {
        onChange({
          left: list[newValue.left].value,
          right: list[newValue.right].value,
        });
      } else {
        onChange(list[newValue.right].value);
      }
    }, []),
  );

  useEffect(() => {
    setActiveSliderIndex({
      left:
        list.findIndex((item) => {
          return item.value === value?.left;
        }) ?? 0,
      right:
        list.findIndex((item) => {
          return item.value === (value?.right ?? value);
        }) ?? 0,
    });
  }, [value]);

  useEffect(() => {
    const onMouseMove = (event) => {
      const newIndex = pointerData.stepPositions.findIndex((position) => {
        return position > event.x;
      });
      const newValue = {
        [pointerData.type]:
          newIndex !== -1 ? newIndex : pointerData.stepPositions.length - 1,
      };
      setActiveSliderIndex((prevValue) => {
        changeSliderPosition({ ...prevValue, ...newValue });
        return {
          ...prevValue,
          ...newValue,
        };
      });
    };
    const onMouseUp = () => {
      setPointerData((prevData) => {
        return { ...prevData, active: false };
      });
    };
    if (pointerData.active) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [pointerData.active]);

  const trackSlider = (pointer = 'left') => {
    if (sliderRef.current) {
      const stepElementsPosition = [];
      [...sliderRef.current.querySelectorAll('.slider-item')].forEach(
        (element) => {
          stepElementsPosition.push(element?.getBoundingClientRect()?.left);
        },
      );
      if (pointer === 'left') {
        stepElementsPosition.splice(activeSliderIndex.right);
      }
      setPointerData({
        active: true,
        stepPositions: stepElementsPosition,
        type: pointer,
      });
    }
  };

  const sliderPosition = `calc(${(activeSliderIndex.left / (length - 1)) * 100}% + 7px)`;
  const rightSliderPosition = `calc(${(activeSliderIndex.right / (length - 1)) * 100}% + 7px)`;

  return (
    <div className={`slider-wrapper type-${type}`} ref={sliderRef}>
      <div
        className="slider-range"
        style={{ width: `${((length - 1) / length) * 100}%` }}
      >
        {rangeSelection && (
          <span
            className="slider-selection"
            style={{
              left: sliderPosition,
            }}
            onMouseDown={() => {
              trackSlider('left');
            }}
          />
        )}
        <span
          className="slider-selection slider-selection-right"
          style={{
            left: rightSliderPosition,
          }}
          onMouseDown={() => {
            trackSlider('right');
          }}
        />
        <div
          className="slider-track"
          style={
            rangeSelection
              ? {
                  width: `calc(${((activeSliderIndex.right - activeSliderIndex.left) / (length - 1)) * 100}% + 7px)`,
                  left: sliderPosition,
                }
              : {
                  width: rightSliderPosition,
                }
          }
        />
        <div className="slider-bg" />
      </div>
      <div className="slider-steps">
        {list.map((item, index) => {
          const { label, value: itemValue } = item;
          return (
            <div
              className="slider-item"
              key={`item-${itemValue}`}
              onClick={() => {
                if (!rangeSelection) {
                  changeSliderPosition({ ...activeSliderIndex, right: index });
                }
              }}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Slider;
