import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMatch, useNavigate } from 'react-router-dom';
import ContactDirectoryIcon from 'Assets/svg/new_icons/contact-directory.svg';
import AsyncPopup from 'Components/common/AsyncPopup';
import LoadingWrapper from 'Components/common/base/Loader';
import SavedSearchPopUp, {
  savedSearchPopupState,
} from 'Components/common/savedSearchPopup';
import suppressionListState from 'Stores/suppressionList';
import Button from 'Components/common/base/Button';
import Title from 'Components/common/base/Title';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import advSearchState from 'Stores/advSearch';
import advSearchFiltersState from 'Stores/advSearchFilters';
import freeTrialPopupState from 'Stores/freeTrialPopupState';
import savedSearchState from 'Stores/savedSearch';
import userDetail from 'Stores/userDetail';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import SalesforceAuthPopup from './SalesforceAuthPopup';
import AddToListWithOptions from './AddToList';
import ContactList from './ContactList';
import ContactMiddleSection from './ContactMiddleSection';
import { showLimitReachedPopup } from './modals';
import EmptyCriteria from '../EmptyCriteria';
import { getSavedSearchTemplateById } from '../utils';
import RecommendationAndSavedSearch from './RecommendationAndSavedSearch';
import './styles.scss';

function NoResultsFoundForSavedSearch() {
  return (
    <div className="empty-result no-result">
      <ContactDirectoryIcon />
      <div className="no-results-title">
        We couldn&#39;t find any contacts that match your search.
      </div>
      <p className="message">
        Save your search and sit back! We’ve put our data team to the task. Get
        notified as soon we add new contacts that match your search.
      </p>
      <Button
        buttonProps={{ text: 'Save Search' }}
        onClick={() => {
          if (featureFlagsAndPreferencesState.isSavedSearchEnabled()) {
            Utils.mixpanelTrack(
              CONSTANTS.MX_SAVED_SEARCH_EVENTS.EMPTY_RESULTS_SAVE_SEARCH_CLICK,
            );
            savedSearchPopupState.setData({});
            savedSearchPopupState.setShowModal(true);
          } else {
            commonUpgradePopupState.setShowUpgradePopup(
              CONSTANTS.UPGRADE_TRIGGERS.SAVED_SEARCH,
            );
          }
        }}
      />
    </div>
  );
}

function ResultSection(props) {
  const { savedSearchId, setSavedSearchId } = props;

  if (!advSearchState.resultLoading) {
    if (advSearchState.resultStatusMsg === 'empty') {
      return (
        <RecommendationAndSavedSearch setSavedSearchId={setSavedSearchId} />
      );
    }
    if (advSearchState.resultStatusMsg === 'one_more') {
      return (
        <EmptyCriteria text="Please select at least one more search criteria" />
      );
    }
    if (advSearchState.searchResults.length > 0) {
      return <ContactList savedSearchId={savedSearchId} />;
    }
    if (savedSearchId !== '' && advSearchState.searchResults.length === 0) {
      return (
        <EmptyCriteria
          className="saved-search-empty"
          text="You do not have any new set of contacts. But don&#39;t forget to
        check it out later, as we are constantly enhancing our contacts
        data."
        />
      );
    }

    return <NoResultsFoundForSavedSearch />;
  }
  return '';
}

function ContactSearch() {
  const navigate = useNavigate();
  const match = useMatch('/advanced-search/contact/:savedTemplate?');
  const { params } = match;
  const { savedTemplate = '' } = params;

  const companyDomain = Utils.getQueryParamFromUrl(
    'companyDomain',
    window.location.href,
  );
  const trigger = Utils.getProspectorUserTrigger(window.location.href);

  userDetail.setCurrentTab('contact');

  // for showing in UI alone
  const [savedSearchId, setSavedSearchId] = React.useState('');
  const [isCreateSearchLoading, setIsCreateSearchLoading] =
    React.useState(false);

  const afterLogInCheckFlow = () => {
    if (trigger !== null) {
      freeTrialPopupState.setDynamicText(companyDomain);
      freeTrialPopupState.setFreeTrialPrePopup(trigger);

      navigate('/advanced-search/contact#search');
    }
    if (
      trigger === 'single-view-contacts' ||
      trigger === 'website-view-contacts'
    ) {
      advSearchState.checkBoxFilters.showContactsWithoutEmail = true;
      advSearchState.checkBoxFilters.showContactsWithEmail = true;
    }

    if (companyDomain !== null) {
      advSearchState.domainSearchFilter(companyDomain);
    }

    if (
      savedTemplate !== '' &&
      savedSearchState.contactSavedSearches.length > 0
    ) {
      const searchJson = getSavedSearchTemplateById(
        savedSearchState.contactSavedSearches,
        savedTemplate,
        (id) => {
          setSavedSearchId(id);
        },
      );
      const modifiedSearchJson = advSearchState.checkAndAddEmailStatus(
        searchJson,
        true,
      );
      advSearchState.applySearch(modifiedSearchJson);

      const noOfDays = Utils.getQueryParamFromUrl(
        'createdInDays',
        window.location.href,
      );

      advSearchState.applySearch({
        ...modifiedSearchJson,
        ...(noOfDays && { createdInLastNoOfDays: parseInt(noOfDays) }),
      });

      navigate('/advanced-search/contact', { replace: true });
    } else {
      advSearchState.retainSearch();
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setSavedSearchId('');
    }
  }, [advSearchState.appliedFilterPostData]);

  useEffect(() => {
    // After login check - flow
    if (userDetail.onFirstLoad && suppressionListState.suppressionListLoaded) {
      afterLogInCheckFlow();
    }
  }, [userDetail.onFirstLoad, suppressionListState.suppressionListLoaded]);

  useEffect(() => {
    advSearchFiltersState.fetchFilterValues();

    return () => {
      advSearchState.searchResults = [];
      advSearchState.searchResultCount = 0;
      advSearchState.uniqueCompanyCount = 0;
      advSearchFilterPopupState.searchResults = [];
      advSearchFilterPopupState.searchResultCount = 0;
      advSearchFilterPopupState.uniqueCompanyCount = 0;
    };
  }, []);

  const handleSaveSearchCallback = (status, data) => {
    if (status === 'limit') {
      showLimitReachedPopup(data, () => {
        navigate('/advanced-search/contact/saved-searches');
      });
    }
  };

  const createSavedSearch = async (data) => {
    const newData = data;
    let newSavedSearchId;
    setIsCreateSearchLoading(true);
    if (data.id === undefined) {
      if (data.status === undefined) {
        newData.status = 'INACTIVE';
      }
      newData.appliedFilters = advSearchState.appliedFilterPostData;
      newSavedSearchId = await savedSearchState.saveSearch(newData, {
        callback: handleSaveSearchCallback,
      });
    } else {
      const savedSearchToUpdate = savedSearchState.contactSavedSearches?.find(
        (savedSearch) => {
          return savedSearch.id === data.id;
        },
      );
      const { id } = savedSearchToUpdate;
      const { templateName, ...remainingProps } = data;
      const isEmailScheduleUpdated = Object.keys(remainingProps).some((key) => {
        return remainingProps[key] !== savedSearchToUpdate[key];
      });
      newSavedSearchId = id;
      const success = await savedSearchState.updateSavedSearch(
        {
          id,
          searchTemplate: JSON.stringify(advSearchState.appliedFilterPostData),
          templateName,
          ...(isEmailScheduleUpdated
            ? { ...remainingProps }
            : remainingProps.frequency
              ? {}
              : {
                  status: 'INACTIVE',
                }),
        },
        undefined,
      );
      if (success) {
        Utils.mixpanelTrack(
          CONSTANTS.MX_SAVED_SEARCH_EVENTS.REPLACE_SAVED_SEARCH,
          {
            name: templateName,
            searchCriteria: advSearchState.appliedFilterPostData,
            ...remainingProps,
          },
        );
      }
    }
    setIsCreateSearchLoading(false);
    setSavedSearchId(newSavedSearchId);

    savedSearchPopupState.setShowModal(false);
    savedSearchPopupState.setData({});

    if (window.location.hash === '#search') {
      navigate('/advanced-search/contact');
    }
  };

  return (
    <>
      <Title title="Contact Search" />
      <div className="contact-search">
        {(advSearchState.searchResultCount > 0 ||
          (savedSearchId !== '' &&
            advSearchState.searchResults.length === 0)) && (
          <ContactMiddleSection
            isResultLoading={advSearchState.resultLoading}
            savedSearchId={savedSearchId}
          />
        )}
        <ResultSection
          savedSearchId={savedSearchId}
          setSavedSearchId={setSavedSearchId}
        />
        {advSearchState.resultLoading && <ContactList isListLoading />}
      </div>
      <LoadingWrapper show={advSearchState.actionLoader} position="fixed" />
      {/* Shove all the popups here */}
      <AddToListWithOptions />
      <SavedSearchPopUp
        isAPILoading={isCreateSearchLoading}
        onSubmit={createSavedSearch}
      />
      <AsyncPopup />
      <SalesforceAuthPopup />
    </>
  );
}

export default observer(ContactSearch);
