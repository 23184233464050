import React from 'react';
import { observer } from 'mobx-react';
import ToolTipWithIcon from 'Components/common/ToolTipWithIcon';
import InlineLoader from 'Components/common/base/InlineLoader';
import advSearchState from 'Stores/advSearch';
import './styles.scss';

function LoaderWithTooltip(props) {
  const { contactId } = props;
  return (
    advSearchState.singlePurchaseLoaderIds.includes(contactId) && (
      <span className="contact-action-loader">
        <InlineLoader />
        <ToolTipWithIcon>
          {`It's taking a bit longer than usual. ${
            advSearchState.singlePurchaseType === 'EMAIL'
              ? 'We will add this contacts to your list once we verify this email'
              : ''
          }`}
        </ToolTipWithIcon>
      </span>
    )
  );
}

export default observer(LoaderWithTooltip);
