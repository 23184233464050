import React from 'react';
import { observer } from 'mobx-react';
import Select from 'Components/common/base/Select';
import LeftArrow from 'Assets/svg/new_icons/left-arrow.svg';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import { freeUserPaginationPopupState } from '../FreeUserPaginationPopup';
import './styles.scss';

const { MX_AS_EVENTS } = CONSTANTS;
const { LB_AS_PAGINATION_CLICK } = MX_AS_EVENTS;

const itemPerPageValues = [
  { label: '15', value: 15 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '75', value: 75 },
  { label: '100', value: 100 },
];

function Pagination(props) {
  const {
    currentPage = 1,
    totalCount = 0,
    rowsPerPage = 50,
    onRowsPerPageUpdate,
    updateCurrentPage,
    pageName = '',
    rowsPerPageValues = itemPerPageValues,
    showRowsPerPage = true,
    showTotalLogs = true,
    totalText = 'Total Records',
  } = props;

  const totalPage =
    totalCount % rowsPerPage === 0
      ? Math.floor(totalCount / rowsPerPage)
      : Math.floor(totalCount / rowsPerPage) + 1;

  const handleNext = () => {
    if (pageName === 'advSearchContact' || pageName === 'advSearchCompany') {
      Utils.mixpanelTrack(LB_AS_PAGINATION_CLICK, {
        pageName,
      });

      if (Utils.checkIsEmailVerified(true)) {
        if (Utils.getUserType() === 'FREE') {
          freeUserPaginationPopupState.setShowModal(true);
        } else {
          updateCurrentPage(currentPage, '+');
        }
      }
    } else {
      updateCurrentPage(currentPage, '+');
    }
  };

  return (
    <div className={`pagination-wrapper${showTotalLogs ? '' : ' no-logs'}`}>
      {showTotalLogs && totalCount > 0 && (
        <div className="total-count">
          <span>{`${totalText}: ${Utils.numberFormat(totalCount)}`}</span>
        </div>
      )}
      {(totalPage > 1 || totalCount > rowsPerPageValues[0].value) && (
        <div className="pagination-controls">
          {showRowsPerPage && (
            <div className="page-count-wrap">
              <div className="text">Rows Per Page</div>
              <Select
                selectWrapperProps={{ className: 'pagination-dropdown' }}
                options={
                  Utils.getUserType() === 'FREE'
                    ? rowsPerPageValues.filter((page) => {
                        return page.value <= 50;
                      })
                    : rowsPerPageValues
                }
                value={rowsPerPage}
                onChange={(value) => {
                  onRowsPerPageUpdate(value);
                }}
              />
            </div>
          )}
          <div className="pagination">
            <div className="text">
              {`Pages ${Utils.numberFormat(currentPage)} of ${Utils.numberFormat(totalPage)}`}
            </div>
            <span
              className={`previous-page${currentPage === 1 ? ' disable' : ''}`}
              onClick={() => {
                if (currentPage !== 1) {
                  updateCurrentPage(currentPage - 2, '-');
                }
              }}
            >
              <LeftArrow />
            </span>
            <span
              className={`next-page${
                currentPage === totalPage ? ' disable' : ''
              }`}
              onClick={() => {
                if (currentPage !== totalPage) {
                  handleNext();
                }
              }}
            >
              <LeftArrow />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(Pagination);
