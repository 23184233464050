import React, { useState } from 'react';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { MX_AS_EVENTS } from 'Utils/constants';
import InputSuggestion from '../filterPopup/inputSuggestion';
import './styles.scss';
import CheckboxList from '../filterPopup/checkboxList';

const { LB_AS_LOCATION_SEARCH_PERFORMED } = MX_AS_EVENTS;

const tabs = [
  { text: 'City', key: 'selectedCities' },
  { text: 'State', key: 'selectedStates' },
  { text: 'Country', key: 'selectedCountries' },
  { text: 'Region', key: 'selectedRegions' },
];

const constructSelectedData = (data) => {
  const { selectedObject, previousValues, checked } = data;
  let valueArray = [];
  const { originalData } = selectedObject;

  if (previousValues && previousValues !== undefined) {
    if (previousValues.length > 0) {
      previousValues.forEach((item) => {
        valueArray.push(item);
      });
    }
  }

  if (!checked) {
    valueArray = valueArray.filter((valueItem) => {
      return valueItem.id !== originalData.id;
    });
  } else {
    valueArray.push({ ...originalData, pName: originalData.id.toString() });
  }
  return valueArray;
};

function isSuggestionSelected(data) {
  const { list, selectedValues, key, excludeKey } = data;

  const isValueAvailable = (prop, filterKey) => {
    return selectedValues?.[filterKey]?.some((selectedData) => {
      return selectedData.id === prop.originalData.id;
    });
  };

  return list.map((prop) => {
    const isIncluded = isValueAvailable(prop, key);
    const isExcluded = excludeKey && isValueAvailable(prop, excludeKey);

    return {
      ...prop,
      checked: isIncluded || isExcluded,
      color: isExcluded ? 'error' : 'primary',
    };
  });
}

function isRegionSelected(data) {
  const { selectedValues, item } = data;
  return selectedValues?.some((prop) => {
    return prop.name === item.label;
  });
}

function Location(props) {
  const { currentFilterState, page, keys } = props;
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const suggestionKey =
    page === 'contact' ? keys[currentTab.key] : currentTab.key;

  return (
    <div className="location-filter-wrapper">
      <div className="tabs">
        {tabs.map((tabData) => {
          const { text, key } = tabData;
          return (
            <div
              className={`tab${currentTab.key === key ? ' active' : ''}`}
              key={key}
              onClick={() => {
                setCurrentTab(tabData);
              }}
            >
              {text}
            </div>
          );
        })}
      </div>
      {currentTab.key === 'selectedRegions' ? (
        <CheckboxList
          key={currentTab.key}
          list={advSearchFiltersState.filterListValues.regions}
          mxEventName={LB_AS_LOCATION_SEARCH_PERFORMED}
          currentFilterState={currentFilterState}
          page={page}
          suggestionKey={suggestionKey}
          getCustomSelectedData={(values) => {
            return values
              .filter((value) => {
                return value.checked;
              })
              .map((value) => {
                return {
                  ...value.originalData,
                  pName: value.originalData.id.toString(),
                };
              });
          }}
          isValueChecked={isRegionSelected}
        />
      ) : (
        <InputSuggestion
          key={currentTab.key}
          placeholder={currentTab.text}
          label={`Search for ${currentTab.text}`}
          currentFilterState={currentFilterState}
          page={page}
          selectMultiple
          getSuggestions={(value) => {
            return advSearchFiltersState.getLocationSuggestion(
              currentTab.text.toLowerCase(),
              value,
            );
          }}
          suggestionKey={suggestionKey}
          mxEventName={LB_AS_LOCATION_SEARCH_PERFORMED}
          constructSelectedData={constructSelectedData}
          suggestionSelected={isSuggestionSelected}
        />
      )}
    </div>
  );
}

export default Location;
