import React, { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMatch } from 'react-router-dom';
import LockIcon from 'Assets/svg/new_icons/lock.svg';
import Button from 'Components/common/base/Button';
import ChipList from 'Components/common/base/ChipList';
import LoadingWrapper from 'Components/common/base/Loader';
import { toasterState } from 'Components/common/base/Toaster';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import companySearchState, {
  companySearchPopupState,
} from 'Stores/companySearch';
import advSearchState from 'Stores/advSearch';
import Utils from 'Utils/utils';
import { MX_AS_EVENTS } from 'Utils/constants';
import { getAppliedFilters } from '../filters';
import FilterPopupMapping from './filterPopupMapping';
import './styles.scss';

function FilterPopup(props) {
  const { selectedFilter, closePopup } = props;
  const match = useMatch('/advanced-search/:page/:subPath?');

  const currentFilterState = useMemo(() => {
    return match?.params?.page === 'contact'
      ? advSearchFilterPopupState
      : companySearchPopupState;
  }, [match?.params?.page]);

  const appliedFilters = useMemo(() => {
    return getAppliedFilters(
      currentFilterState.appliedFilterSectionData,
      match.params?.page,
    );
  }, [currentFilterState.appliedFilterSectionData, match]);

  const page = match?.params?.page;
  const isContactPage = page === 'contact';

  const totalFilterCount = appliedFilters.reduce((count, input) => {
    return (
      count + (input.included?.length || 0) + (input.excluded?.length || 0)
    );
  }, 0);

  useEffect(() => {
    if (currentFilterState.resultStatusMsg === 'one_more') {
      toasterState.setToastMsg(
        'Please select at least one more search criteria',
      );
    }
  }, [currentFilterState.resultStatusMsg]);

  const filterDescription = selectedFilter.description;

  const isSelectedFilterRestricted = selectedFilter?.restricted
    ? selectedFilter.restricted()
    : false;

  const isRestrictedFilterObject = appliedFilters.find((prop) => {
    return prop.searchRestricted;
  });

  return (
    <div className="filter-popup-content">
      <div className="left-content">
        <div className="title-section">
          {selectedFilter.title || selectedFilter.text}&nbsp;
          {/* temporary? */}
          {!isSelectedFilterRestricted && (
            <span className="filter-description">
              {filterDescription && `- ${filterDescription}`}
            </span>
          )}
        </div>
        <div className="filter-content-section">
          <FilterPopupMapping
            page={page}
            selectedFilter={selectedFilter}
            currentFilterState={currentFilterState}
          />
        </div>
      </div>
      <div className="right-content">
        <div className="selected-filters-title">
          <span className="text">
            Selected Filters {totalFilterCount ? `(${totalFilterCount})` : ''}
          </span>
          {totalFilterCount > 0 && (
            <Button
              buttonProps={{ text: 'Clear All', className: 'clear-all' }}
              variant="transparent"
              onClick={() => {
                // clear all clears on the main result page also
                if (isContactPage) {
                  advSearchState.clearAllFilters();
                } else {
                  companySearchState.clearAllFilters();
                }
                currentFilterState.clearAllFilters();
              }}
            />
          )}
        </div>
        <div className="applied-filters">
          <div>
            {isContactPage &&
              currentFilterState.appliedFilterSectionData?.exclusionListIds && (
                <div className="applied-filter-item">
                  <div className="filter-text">Suppression Lists</div>
                  <ChipList
                    includedList={[]}
                    excludedList={currentFilterState.appliedFilterSectionData?.exclusionListIds.map(
                      (exclusionList) => {
                        return {
                          text: exclusionList.listName,
                          value: exclusionList.listId,
                        };
                      },
                    )}
                    limitNumberOfTags={false}
                  />
                </div>
              )}
            {appliedFilters.map((appliedFilter) => {
              const { text, included, excluded, includeKey, excludeKey } =
                appliedFilter;
              return (
                <div className="applied-filter-item" key={text}>
                  <div className="filter-text">{text}</div>
                  <ChipList
                    onClear={(tag, isExcluded) => {
                      Utils.mixpanelTrack(MX_AS_EVENTS.AS_TAG_REMOVED, {});
                      currentFilterState.removeAppliedFilterFromGroup({
                        category: isExcluded
                          ? excludeKey
                          : tag.key || includeKey,
                        value: tag.value,
                        objectKey: tag.objChainKey,
                      });
                    }}
                    enableClearing
                    includedList={included}
                    excludedList={excluded}
                    limitNumberOfTags={false}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {totalFilterCount > 0 && (
          <div className="right-content-footer">
            <div className="count">
              {currentFilterState.resultLoading ? (
                <LoadingWrapper show position="absolute" />
              ) : isContactPage ? (
                `${Utils.usNumberFormat(currentFilterState.searchResultCount)} contacts available`
              ) : (
                `${Utils.usNumberFormat(currentFilterState.searchResultCount)} companies available`
              )}
            </div>
            <div className="button-wrapper">
              <Button
                Icon={isRestrictedFilterObject !== undefined ? LockIcon : null}
                buttonProps={{
                  text: `See matching ${isContactPage ? 'contacts' : 'companies'}`,
                  className: 'see-matching-contacts',
                  disabled:
                    currentFilterState.resultLoading ||
                    currentFilterState.searchResultCount === 0,
                }}
                onClick={() => {
                  if (isRestrictedFilterObject !== undefined) {
                    // show upgrade popup
                  } else if (isContactPage) {
                    advSearchFilterPopupState.applyPopupFilters();
                  } else {
                    companySearchState.applyFilterValues(
                      companySearchPopupState,
                    );
                  }
                  closePopup();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(FilterPopup);
