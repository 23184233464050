import React from 'react';
import LocationIcon from 'Assets/svg/new_icons/location.svg';

function CompanyLocation(props) {
  const {
    city = '',
    state = '',
    country = '',
    parentClass = '',
    clsName = '',
  } = props;
  let str = '';
  if (city !== undefined && city !== null && city !== '') {
    str += city;
  }
  if (state !== undefined && state !== null && state !== '') {
    if (str !== '') {
      str += `,&nbsp;${state}`;
    } else {
      str += state;
    }
  }
  if (country !== undefined && country !== null && country !== '') {
    if (str !== '') {
      str += `,&nbsp;${country}`;
    } else {
      str += country;
    }
  }
  let titleStr = str;
  while (titleStr.includes('&nbsp;')) {
    titleStr = titleStr.replace('&nbsp;', '');
  }
  return titleStr !== '' ? (
    <div className={`company-location ${parentClass}`}>
      <LocationIcon className="svg-icon" />
      <div
        className={`item-name show-ellipsis ${clsName}`}
        title={titleStr || 'Unavailable'}
        dangerouslySetInnerHTML={{ __html: str || 'Unavailable' }}
      />
    </div>
  ) : (
    ''
  );
}

export { CompanyLocation };
export default CompanyLocation;
