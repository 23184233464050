import React from 'react';
import AdaptIcon from 'Assets/svg/adapt-logo.svg';
import Button from 'Components/common/base/Button';
import TwoIconConnector from 'Components/common/ExportLeads/TwoIconConnector';
import crmIntegrationState from 'Stores/crmIntegration';
import enrichmentState from 'Stores/enrichment';
import Utils from 'Utils/utils';
import { MX_ENRICHMENT_EVENTS } from 'Utils/constants';
import './styles.scss';

function GenericEmptyState(props) {
  const { crmLogoData, title, description, children } = props;

  const Icon = crmLogoData.imgComponent;
  return (
    <div className="no-connection-wrapper">
      <TwoIconConnector firstIcon={AdaptIcon} secondIcon={Icon} />
      <div className="title">{title}</div>
      <div className="message">{description}</div>
      {children}
    </div>
  );
}

function ConnectYourCrm(props) {
  const {
    crmLogoData,
    title,
    description,
    linkText,
    crmType,
    setShowLoader,
    setCurrentStep,
  } = props;

  return (
    <GenericEmptyState
      crmLogoData={crmLogoData}
      title={title}
      description={description}
    >
      <Button
        buttonProps={{
          text: linkText,
        }}
        onClick={async () => {
          setShowLoader(true);
          Utils.mixpanelTrack(
            MX_ENRICHMENT_EVENTS.CRM_ENRICHMENT_CONNECT_CLICKED,
          );
          await crmIntegrationState.connectToCrm({
            crmType: crmType.toUpperCase(),
            successCallback: () => {
              enrichmentState.setEnrichmentStepsCompleted({ CONNECT: true });
              setCurrentStep(1);
            },
          });
          setShowLoader(false);
        }}
      />
    </GenericEmptyState>
  );
}

export default ConnectYourCrm;
