import React from 'react';
import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import Title from 'Components/common/base/Title';
import enrichmentState from 'Stores/enrichment';
import LoadingWrapper from 'Components/common/base/Loader';
import './styles.scss';

function Enrichment() {
  return (
    <div className="enrichment-page">
      <Title title="Enrichment" />
      <Outlet />
      <LoadingWrapper show={enrichmentState.isLoading} position="fixed" />
    </div>
  );
}
export default observer(Enrichment);
