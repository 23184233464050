import React from 'react';
import './styles.scss';

function EmailValidationStatusColor(props) {
  const { zbStatus } = props;
  return (
    <div
      className={`email-validation-status-color ${zbStatus.toLowerCase()}`}
    />
  );
}

export default EmailValidationStatusColor;
