import React, { useState } from 'react';
import { observer } from 'mobx-react';
import LoadingWrapper from 'Components/common/base/Loader';
import { toasterState } from 'Components/common/base/Toaster';
import DeleteIcon from 'Assets/svg/new_icons/delete.svg';
import DownloadIcon from 'Assets/svg/new_icons/download.svg';
import PlusIcon from 'Assets/svg/plus-icon.svg';
import EmptyListIcon from 'Assets/svg/new_icons/document.svg';
import ShieldIcon from 'Assets/svg/new_icons/shield.svg';
import Button from 'Components/common/base/Button';
import ToggleType from 'Components/common/base/Toggle';
import Title from 'Components/common/base/Title';
import * as CONSTANTS from 'Utils/constants';
import Utils from 'Utils/utils';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import suppressionListState from 'Stores/suppressionList';
import AddToSuppressionListPopup from './AddToSuppressionList';
import './styles.scss';

const frequentlyAskedQuestions = [
  {
    indexNo: '1',
    question: 'What are suppression lists?',
    answer:
      'Suppression lists provide the capability to exclude contacts from your search results. This is useful to omit contacts you already have from search results.',
  },
  {
    indexNo: '2',
    question:
      'Are there any limits on the number of suppressed emails or company domains?',
    answer:
      'Yes. Each account can exclude up to 500k email-ids and 100k domains. Each list can hold up to 25k entries.',
  },
  {
    indexNo: '3',
    question: 'Are my suppression lists shared across my team&apos;s licence?',
    answer: 'Yes. The suppression lists are shared across the team.',
  },
];

function FrequentlyAskedSection() {
  return (
    <div className="frequently-asked-section">
      <div className="title">Frequently Asked Questions</div>
      <div className="questions-list">
        {frequentlyAskedQuestions.map((item) => {
          const { indexNo, question, answer } = item;
          return (
            <div className="question-item" key={`frequet-question${indexNo}`}>
              <div className="left-wrap">
                <div className="round-wrap">{indexNo}</div>
              </div>
              <div className="right-wrap">
                <div
                  className="question-title"
                  dangerouslySetInnerHTML={{ __html: question }}
                />
                <div
                  className="question-answer"
                  dangerouslySetInnerHTML={{ __html: answer }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function EmptyListFound() {
  const [enterpriseInterestApiLoading, setEnterpriseInterestApiLoading] =
    useState(false);
  const handleEnterprisePlanClick = () => {
    setEnterpriseInterestApiLoading(true);
    if (!enterpriseInterestApiLoading) {
      Utils.mixpanelTrack(
        CONSTANTS.MX_PRICING_PAGE.CLICK_LB_PRICING_TALK_TO_US,
        {},
      );
      const callback = () => {
        setEnterpriseInterestApiLoading(false);
      };
      Utils.enterprisePlanInterestNotification('SUPPRESSION_LIST', callback);
    }
  };
  return (
    <div className="empty-list-wrap">
      {featureFlagsAndPreferencesState.isSuppressionListEnabled() ? (
        <div className="empty-list-section">
          <div className="icon-circle">
            <EmptyListIcon />
          </div>
          <div className="no-results-text">
            You don&apos;t have any suppression lists. <br />
            Please create one using the &quot;Add List&quot; button on top.
          </div>
        </div>
      ) : (
        <div className="upgrade-list-section">
          <div className="icon-circle">
            <ShieldIcon />
          </div>
          <div className="no-results-text">
            Exclude contacts you own from other sources using Suppression lists.
            <br />
            Unlock this feature by upgrading to our enterprise plan.
          </div>
          <Button
            buttonProps={{ text: 'Yes, I am interested', id: 'buyNow' }}
            onClick={handleEnterprisePlanClick}
            isLoading={enterpriseInterestApiLoading}
          />
        </div>
      )}
      <FrequentlyAskedSection />
    </div>
  );
}

function SuppressionListHeader() {
  const addNewSuppressionList = () => {
    if (featureFlagsAndPreferencesState.isSuppressionListEnabled()) {
      Utils.mixpanelTrack(
        CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_ADD_LIST,
        {},
      );
      suppressionListState.setShowCreateList(true);
    } else {
      Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_PAYWALL_PRICING_POPUP, {
        btnSource: 'SUPPRESSION_LIST',
      });
      Utils.showUserPricingPopup(true, '', true);
    }
  };

  return (
    <div className="suppression-list-page-header">
      <h1>Suppression Lists</h1>
      {featureFlagsAndPreferencesState.isSuppressionListEnabled() && (
        <Button
          buttonProps={{ text: 'Add List' }}
          onClick={addNewSuppressionList}
          Icon={PlusIcon}
        />
      )}
    </div>
  );
}

function SuppressionList() {
  const { getListLoading, suppressionListEntries } = suppressionListState;

  const [enableOrDisableListLoadingIds, setEnableOrDisableListLoadingIds] =
    React.useState([]);

  React.useEffect(() => {
    if (
      featureFlagsAndPreferencesState.isSuppressionListEnabled() &&
      !getListLoading
    ) {
      suppressionListState.getSuppressionList();
    }
  }, []);

  const enableOrDisableList = (listObj = {}) => {
    const { listId, status, actionType, listName } = listObj;
    if (!enableOrDisableListLoadingIds[listId]) {
      if (status === 'COMPLETE') {
        const enableDisableListCallback = (response) => {
          if (response !== null) {
            const newList = suppressionListEntries.map((item) => {
              const newObj = item;
              if (item.listId === listId) {
                newObj.enabled = actionType;
              }
              return item;
            });

            const msg = actionType
              ? `<b>${listName}</b> list will be excluded from all search.`
              : `<b>${listName}</b> list is disabled.`;
            suppressionListState.setSuppressionListEntries(newList);

            toasterState.setToastMsg(msg, 'success');
          } else {
            toasterState.setToastMsg(
              'Update failed. Please try again later.',
              'failure',
            );
          }
          const listLoadingIds = enableOrDisableListLoadingIds.filter((e) => {
            return e !== listId;
          });
          setEnableOrDisableListLoadingIds(listLoadingIds);
        };
        const listLoadingIds = enableOrDisableListLoadingIds;
        listLoadingIds.push(listId);
        setEnableOrDisableListLoadingIds(listLoadingIds);
        suppressionListState.enableDisableList(
          listId,
          actionType ? 'enable' : 'disable',
          enableDisableListCallback,
        );
      } else {
        const msg = `<b>${listName}</b> list is in progress, You can enable it once its processed`;
        toasterState.setToastMsg(msg, 'failure');
      }
    }
  };

  const downloadList = (listId, listName) => {
    const downloadListCallback = (response) => {
      if (response !== null) {
        Utils.downloadToCsv(response, listName);

        const msg = `${listName} downloaded successfully.`;
        toasterState.setToastMsg(msg, 'success');
      } else {
        toasterState.setToastMsg(
          'Download failed. Please try again later.',
          'failure',
        );
      }
    };
    suppressionListState.downloadList(listId, downloadListCallback);
  };
  const deleteList = (listId) => {
    const deleteListCallback = (response) => {
      if (response !== null) {
        const newList = Utils.copyObjectWithoutReference(
          suppressionListEntries,
        );
        let listIndex = -1;
        // eslint-disable-next-line array-callback-return
        suppressionListEntries.map((item, index) => {
          if (item.listId === listId) {
            listIndex = index;
          }
        });
        if (listIndex > -1) {
          newList.splice(listIndex, 1);
        }

        const msg = 'Suppression list deleted successfully.';
        suppressionListState.setSuppressionListEntries(newList);

        toasterState.setToastMsg(msg, 'success');
      } else {
        toasterState.setToastMsg(
          'Delete failed. Please try again later.',
          'failure',
        );
      }
    };
    suppressionListState.deleteList(listId, deleteListCallback);
  };
  return (
    <div className="suppression-list-page">
      <Title title="Suppression Lists" />
      <AddToSuppressionListPopup />
      <SuppressionListHeader />
      <div className="page-content">
        {getListLoading ? (
          <LoadingWrapper show={getListLoading} />
        ) : featureFlagsAndPreferencesState.isSuppressionListEnabled() &&
          suppressionListEntries.length > 0 ? (
          <div className="suppression-list-wrapper">
            <div className="suppression-list-header">
              <div className="suppression-list-header-item">List Name</div>
              <div className="suppression-list-header-item">Type</div>
              <div className="suppression-list-header-item">List Size</div>
              <div className="suppression-list-header-item">Created By</div>
              <div className="suppression-list-header-item">Actions</div>
              <div className="suppression-list-header-item">Status</div>
            </div>
            <div className="suppression-list-wrap">
              <div className="suppression-list-inner-wrap">
                {suppressionListEntries.map((item) => {
                  const {
                    listName,
                    type,
                    numOfEntries,
                    createdBy,
                    enabled,
                    status,
                    listId,
                  } = item;
                  return (
                    <div className="suppression-list-item" key={listId}>
                      <div>{Utils.upperCaseFirstCharacter(listName)}</div>
                      <div className="list-type">{type.toLowerCase()}</div>
                      <div>{Utils.usNumberFormat(numOfEntries)}</div>
                      <div className="created-name">
                        {createdBy !== null ? createdBy : '-'}
                      </div>
                      <div>
                        {status === 'INPROGRESS' ? (
                          '-'
                        ) : (
                          <div className="action-icons">
                            <DownloadIcon
                              className="svg-icon"
                              title="Remove User"
                              onClick={() => {
                                downloadList(listId, listName);
                              }}
                            />
                            <DeleteIcon
                              className="svg-icon"
                              title="Remove User"
                              onClick={() => {
                                deleteList(listId);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="toggle-btn">
                        {status === 'COMPLETE' && (
                          <ToggleType
                            firstText=""
                            lastText=""
                            toggle={enabled}
                            cbk={(newValue) => {
                              enableOrDisableList({
                                listId,
                                status,
                                actionType: newValue,
                                listName,
                              });
                            }}
                          />
                        )}

                        {status === 'INPROGRESS' && <span>IN PROGRESS</span>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="suppression-list-wrapper">
            <EmptyListFound />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(SuppressionList);
