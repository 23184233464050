import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import RightArrow from 'Assets/svg/right-arrow.svg';
import BookmarkIcon from 'Assets/svg/new_icons/bookmark-success.svg';
import LockIcon from 'Assets/svg/new_icons/lock.svg';
import CloseIcon from 'Assets/svg/close-material.svg';
import ThunderIcon from 'Assets/svg/thunder-outline.svg';
import Button from 'Components/common/base/Button';
import Accordion from 'Components/common/base/Accordion';
import ChipList from 'Components/common/base/ChipList';
import RippleEffect from 'Components/common/base/RippleEffect';
import { savedSearchPopupState } from 'Components/common/savedSearchPopup';
import { commonUpgradePopupState } from 'Components/common/CommonUpgradePopup';
import advSearchState from 'Stores/advSearch';
import advSearchFilterPopupState from 'Stores/advSearchFilterPopup';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import companySearchState, {
  companySearchPopupState,
} from 'Stores/companySearch';
import advSearchFiltersState from 'Stores/advSearchFilters';
import Utils from 'Utils/utils';
import { UPGRADE_TRIGGERS } from 'Utils/constants';
import filters, { companyFilters, getAppliedFilters } from './filters';
import FilterPopup from './filterPopup';
import filterState from './state';
import './styles.scss';

function FilterList(props) {
  const {
    list,
    appliedFilters = [],
    setSelectedFilter,
    selectedFilter,
    page,
  } = props;

  return (
    <div className="filter-list">
      {list.map((listItem) => {
        const {
          key,
          text,
          Icon,
          enabled = () => {
            return true;
          },
          restricted = () => {
            return false;
          },
          isNewFilter = false,
        } = listItem;

        const appliedFilterItem =
          appliedFilters?.find((filter) => {
            return filter.text === text;
          }) ?? {};

        const filterApplied = Object.keys(appliedFilterItem).length > 0;

        const isRestricted = restricted();

        return (
          enabled(page) && (
            <div
              key={key}
              className={`filter-item ${key} ${selectedFilter?.key === key ? ' selected' : ''}${filterApplied ? ' filter-applied' : ''}`}
              onClick={() => {
                setSelectedFilter(listItem);
              }}
            >
              {selectedFilter?.ripple && selectedFilter?.key === key && (
                <RippleEffect />
              )}
              <div className="item-wrapper">
                {isNewFilter && (
                  <div className="upgrade-icon-wrapper">
                    <ThunderIcon className="upgrade-icon" />
                  </div>
                )}
                <Icon className="filter-icon" />
                <span>{text}</span>
                {isRestricted && <LockIcon className="lock-icon" />}
                <RightArrow className="right-arrow" />
              </div>
              {filterApplied && (
                <div className="applied-filters">
                  <ChipList
                    includedList={appliedFilterItem?.included}
                    excludedList={appliedFilterItem?.excluded}
                    tagLimit={2}
                  />
                </div>
              )}
            </div>
          )
        );
      })}
    </div>
  );
}

function FilterSection() {
  const navigate = useNavigate();
  const location = useLocation();

  const match = useMatch('/advanced-search/:page/:subPath?');
  const [openFilterGroups, setOpenFilterGroups] = useState({
    contact: true,
    company: true,
    growth: true,
  });

  const [filterList, setFilterList] = useState(filters);

  const anyFilterOpen = Object.keys(openFilterGroups).some((key) => {
    return openFilterGroups[key];
  });

  const currentFilterState = useMemo(() => {
    const isContact = match?.params?.page === 'contact';
    return isContact ? advSearchState : companySearchState;
  }, [match?.params?.page]);

  const appliedFilters = useMemo(() => {
    return getAppliedFilters(
      currentFilterState.appliedFilterSectionData,
      match?.params?.page,
    );
  }, [
    currentFilterState.appliedFilterSectionData,
    match,
    advSearchFiltersState.filterListValues,
  ]);

  const showPopup = filterState.selectedFilter !== null;

  const setAccordionOpen = (key) => {
    setOpenFilterGroups((previousValues) => {
      return { ...previousValues, [key]: !previousValues[key] };
    });
  };

  const closePopup = (isApply = true) => {
    filterState.closeFilter();
    if (!isApply) {
      if (match?.params?.page === 'contact') {
        advSearchFilterPopupState.setPreviousState();
      } else {
        companySearchPopupState.applyFilterValues(companySearchState);
      }
    }
  };

  const filterApplied =
    Object.keys(currentFilterState.appliedFilterPostData).length > 0;

  const searchResultsAvailable =
    currentFilterState.searchResultCount > 0 && filterApplied;

  const page = match?.params?.page;

  useEffect(() => {
    // redirect if some mistyped url
    if (!match?.params?.page) {
      navigate('/advanced-search/contact#search');
    }
    setFilterList(match?.params?.page === 'contact' ? filters : companyFilters);
  }, [match?.params?.page]);

  useEffect(() => {
    Utils.toggleDriftVisibility(filterState.selectedFilter !== null);
  }, [filterState.selectedFilter]);

  useEffect(() => {
    return () => {
      filterState.closeFilter();
    };
  }, []);

  return (
    <>
      <div className={`filter-popup${showPopup ? ' show' : ''}`}>
        <div
          className="close-icon-wrapper"
          onClick={() => {
            closePopup(false);
          }}
        >
          <span>Close</span>
          <CloseIcon />
        </div>
        {showPopup && (
          <FilterPopup
            selectedFilter={filterState.selectedFilter}
            closePopup={closePopup}
          />
        )}
      </div>
      <div className="filter-backdrop" />

      <div className="filter-section">
        <div className="top-section">
          <p>Filters</p>
          {!showPopup && (
            <div className="button-wrap">
              {filterApplied && (
                <Button
                  variant="transparent"
                  buttonProps={{ text: 'Clear All', className: 'clear-all' }}
                  onClick={() => {
                    currentFilterState.clearAllFilters();
                    if (page === 'company') {
                      companySearchPopupState.applyFilterValues(
                        companySearchState,
                      );
                    }
                  }}
                />
              )}
              <Button
                buttonProps={{
                  text: 'Saved Searches',
                  className: 'saved-search-btn',
                }}
                variant={searchResultsAvailable ? 'light' : 'primary'}
                onClick={() => {
                  if (featureFlagsAndPreferencesState.isSavedSearchEnabled()) {
                    navigate(`${match?.params?.page}/saved-searches`);
                  } else {
                    commonUpgradePopupState.setShowUpgradePopup(
                      UPGRADE_TRIGGERS.SAVED_SEARCH,
                    );
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className="filters-list">
          <div className="filters-list-inner">
            <Button
              variant="transparent"
              buttonProps={{
                className: 'collapse-btn',
                text: anyFilterOpen ? 'Collapse All' : 'Expand All',
              }}
              onClick={() => {
                const newValue = !anyFilterOpen;
                setOpenFilterGroups({
                  contact: newValue,
                  company: newValue,
                  growth: newValue,
                });
              }}
            />
            {filterList.map((filterObject) => {
              const { key, title, list } = filterObject;

              return (
                <Accordion
                  className={
                    filterState.selectedFilter?.ripple ? 'has-ripple' : ''
                  }
                  key={key}
                  title={title}
                  isAccordionOpen={openFilterGroups[key]}
                  setIsAccordionOpen={() => {
                    setAccordionOpen(key);
                  }}
                >
                  <FilterList
                    list={list}
                    appliedFilters={appliedFilters}
                    selectedFilter={filterState.selectedFilter}
                    setSelectedFilter={filterState.setSelectedFilter}
                    page={page}
                  />
                </Accordion>
              );
            })}
          </div>
        </div>
        {searchResultsAvailable &&
          !showPopup &&
          location.hash === '#search' && (
            <div className="save-search-button-wrapper">
              <Button
                buttonProps={{ text: 'Save this Search' }}
                onClick={() => {
                  if (featureFlagsAndPreferencesState.isSavedSearchEnabled()) {
                    savedSearchPopupState.setShowModal(true);
                    savedSearchPopupState.setData(
                      {},
                      {
                        enableEmailSchedule: page === 'contact',
                        type: page,
                      },
                    );
                  } else {
                    commonUpgradePopupState.setShowUpgradePopup(
                      UPGRADE_TRIGGERS.SAVED_SEARCH,
                    );
                  }
                }}
                Icon={BookmarkIcon}
              />
            </div>
          )}
      </div>
    </>
  );
}

export default observer(FilterSection);
