import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import sequenceStoreData from 'Stores/sequenceData';
import Folders from './SequenceFolders';
import SequenceList from './SequenceList';
import './styles.scss';

function AddToSequencePopup(props) {
  const { closePopup, submitCbk } = props;
  const [selectedSeq, setSelectedSeq] = useState('');
  const [sequenceLoading, setSequenceLoading] = useState(true);
  const [enableConfirmButton, setEnableConfirmButton] = useState(false);

  const close = () => {
    closePopup(false);
  };

  const addToSeqAction = async () => {
    if (selectedSeq) {
      submitCbk(selectedSeq);
    }
  };

  useEffect(() => {
    sequenceStoreData.getAllSequenceData();
    sequenceStoreData.getAllSequenceFolders();
    return () => {
      setSequenceLoading(true);
      sequenceStoreData.setSequenceData(null);
    };
  }, []);

  return (
    <Modal show closeModal={close} className="sequence-popup">
      <Modal.Content>
        <div className="left-section">
          <p className="select-sequence-title">Select Sequence</p>
          <Folders setSequenceLoading={setSequenceLoading} />
        </div>
        <div className="right-section">
          <SequenceList
            setSelectedSeq={setSelectedSeq}
            setLoading={setSequenceLoading}
            loading={sequenceLoading}
            setEnableConfirmButton={setEnableConfirmButton}
          />
          {enableConfirmButton && (
            <div className="confirm-button-wrapper">
              <Button
                buttonProps={{
                  text: 'Confirm',
                  className: 'confirm-button',
                  disabled: !enableConfirmButton,
                }}
                onClick={addToSeqAction}
              />
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default observer(AddToSequencePopup);
