import React from 'react';
import { observer } from 'mobx-react';
import SavedSearchEmpty from 'Assets/png/saved-search-empty.png';
import savedSearchState from 'Stores/savedSearch';
import { EMPTY_FUNCTION, PAGE_PATH_MAP } from 'Utils/constants';
import ListTable from '..';
import './styles.scss';

const savedSearchHeaders = [
  {
    text: 'Name',
    key: 'name',
    showShared: true,
  },
  {
    text: 'Created On',
    key: 'createdDate',
    type: 'date',
  },
];

function SavedSearchList(props) {
  const { callback = EMPTY_FUNCTION } = props;

  return (
    <ListTable
      className="saved-search-list"
      viewAllData={{
        link: PAGE_PATH_MAP.CONTACT_SAVED_SEARCH,
        text: 'View all saved searches',
      }}
      emptyState={{
        imageURL: SavedSearchEmpty,
        title: "It seems you haven't saved searches yet",
        message:
          'Saved search allows you to configure and receive notifications when we add new contacts matching your search criteria.',
      }}
      headersList={savedSearchHeaders}
      onClick={(data) => {
        callback(data);
      }}
      list={savedSearchState.contactSavedSearches?.map((contactSavedSearch) => {
        return {
          id: contactSavedSearch.id,
          name: contactSavedSearch.templateName,
          isShared: contactSavedSearch.isSharedSearch,
          createdDate: contactSavedSearch.creationTimeStamp,
          searchTemplate: contactSavedSearch.searchTemplate,
        };
      })}
    />
  );
}

export default observer(SavedSearchList);
