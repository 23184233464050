import React from 'react';
import { observer } from 'mobx-react';
import HandshakeIcon from 'Assets/svg/new_icons/handshake.svg';
import { updatePaidUserWelcomePopupShown } from 'Utils/commonApi';
import userDetail from 'Stores/userDetail';
import Modal from '../base/Modal';
import Button from '../base/Button';
import './styles.scss';

function PaidUserWelcomePopup() {
  const [showPopup, setShowPopup] = React.useState(false);

  const { memberSubscription } = userDetail.userInfo;
  const { lbpaidPlanWelcomePopupShown = null, id = null } =
    memberSubscription || {};

  React.useEffect(() => {
    if (
      lbpaidPlanWelcomePopupShown !== null &&
      lbpaidPlanWelcomePopupShown === false &&
      id !== null
    ) {
      setShowPopup(true);
      updatePaidUserWelcomePopupShown(id);
    }
  }, []);

  const handleClick = () => {
    setShowPopup(false);
  };

  return (
    showPopup && (
      <Modal
        align="center"
        className="paid-user-welcome-popup"
        show
        closeModal={handleClick}
      >
        <Modal.Icon SvgIcon={HandshakeIcon} color="transparent" />
        <Modal.Title>Welcome to Adapt</Modal.Title>
        <Modal.Content className="description">
          We are thrilled that you have chosen us!
          <br /> We are delighted to have you as part of our growing family with
          over 300K happy users!
        </Modal.Content>
        <Modal.Footer>
          <Button buttonProps={{ text: 'Done' }} onClick={handleClick} />
        </Modal.Footer>
      </Modal>
    )
  );
}

export default observer(PaidUserWelcomePopup);
