/* eslint-disable */
const loadScriptsDynamically = (
  elementId,
  scriptUrl,
  callback,
  tagName = 'script',
) => {
  const existingScript = document.getElementById(elementId);
  if (!existingScript) {
    const script = document.createElement(tagName);
    script.src = scriptUrl;
    script.id = elementId;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export { loadScriptsDynamically };
export default loadScriptsDynamically;
