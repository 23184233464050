/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { observer } from 'mobx-react';
import SampleEmailCSV from 'Assets/csv/sampleExcludeSuppressionListEmail.csv';
import SampleDomainCSV from 'Assets/csv/sampleExcludeSuppressionListDomain.csv';
import DocumentIcon from 'Assets/svg/new_icons/document.svg';
import InfoIcon from 'Assets/svg/info-icon.svg';
import ProcessingIcon from 'Assets/svg/processing-hour-glass.svg';
import suppressionListState from 'Stores/suppressionList';
import Modal from 'Components/common/base/Modal';
import Select from 'Components/common/base/Select';
import Input from 'Components/common/base/Input';
import Button from 'Components/common/base/Button';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';

function AddToSuppressionListPopup() {
  const [selectedListType, setSelectedListType] = React.useState('');
  const ERROR_FIELD = {
    CHOOSE_LIST_TYPE: 'CHOOSE_LIST_TYPE',
    ENTER_LIST_NAME: 'ENTER_LIST_NAME',
    UPLOAD_NEW_LIST: 'UPLOAD_NEW_LIST',
  };
  const typesOfList = [
    { label: 'DOMAIN', value: 'DOMAIN' },
    { label: 'EMAIL', value: 'EMAIL' },
  ];
  const [selectedListName, setSelectedListName] = React.useState('');

  const [errorToolMsg, showErrorToolMsg] = React.useState('');
  const [inputValidation, setInputValidation] = React.useState({});
  const [verifiedListEntries, setVerifiedListEntries] = React.useState([]);
  const [uploadedFileName, setUploadedFileName] = React.useState('');
  const [isVerified, setIsVerified] = React.useState('');
  const [listVerifyMsg, setListVerifyMsg] = React.useState('');

  const [fileUpload, setFileUpload] = React.useState(true);

  const [isLoading, setIsLoading] = React.useState(false);

  const changeInputVal = (value) => {
    showErrorToolMsg('');
    if (inputValidation[ERROR_FIELD.ENTER_LIST_NAME] !== null) {
      const newValObj = Utils.copyObjectWithoutReference(inputValidation);
      delete newValObj[ERROR_FIELD.ENTER_LIST_NAME];
      setInputValidation(newValObj);
    }
    setSelectedListName(value);
  };

  const closeListPopup = () => {
    suppressionListState.setGetListLoading(false);
    suppressionListState.setShowCreateList(false);
  };

  const onContactLimitChange = (val) => {
    showErrorToolMsg('');
    if (inputValidation[ERROR_FIELD.CHOOSE_LIST_TYPE] !== null) {
      const newValObj = Utils.copyObjectWithoutReference(inputValidation);
      delete newValObj[ERROR_FIELD.CHOOSE_LIST_TYPE];
      setInputValidation(newValObj);
    }
    if (val !== selectedListType) {
      setIsVerified('');
      setVerifiedListEntries([]);
      setUploadedFileName('');
    }
    setSelectedListType(val);
  };

  const verifyCsvEntries = (entries) => {
    setIsVerified('verifying');
    setListVerifyMsg('');
    const verifyCallback = (response) => {
      const {
        errorCode = null,
        validEntries = null,
        remainingEntriesCount = null,
      } = response;
      if (errorCode === null && validEntries !== null && validEntries.length) {
        setIsVerified('true');
        setVerifiedListEntries(validEntries);
        const msg = `Found ${validEntries.length} valid ${
          selectedListType === 'DOMAIN'
            ? `${validEntries.length > 1 ? 'domains' : 'domain'}`
            : `${validEntries.length > 1 ? 'emails' : 'email'}`
        } to suppress in search results`;
        setListVerifyMsg(msg);
      } else if (
        errorCode === 203 ||
        (validEntries !== null && validEntries.length === 0)
      ) {
        setIsVerified('error');
        setListVerifyMsg('Uploaded list has no valid rows.');
        Utils.mixpanelTrack(
          CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
          {
            errorLocation: 'VERIFICATION',
            errorType: 'EMPTY_ROWS',
          },
        );
      } else if (errorCode === 202) {
        setIsVerified('error');
        setListVerifyMsg('Maximum number of rows allowed is 25K / List.');
        Utils.mixpanelTrack(
          CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
          {
            errorLocation: 'VERIFICATION',
            errorType: 'LIMIT_EXCEED_PER_LIST',
          },
        );
      } else if (errorCode === 201) {
        if (remainingEntriesCount !== null) {
          setIsVerified('error_custom');
          setListVerifyMsg(remainingEntriesCount);
        } else {
          setIsVerified('error');
          let msg = '';
          if (selectedListType === 'EMAIL') {
            msg =
              'Oops! You have reached the limit of 500K emails in your suppression list.';
          } else {
            msg =
              'Oops! You have reached the limit of 100K domains in your suppression list.';
          }
          setListVerifyMsg(msg);
        }
        Utils.mixpanelTrack(
          CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
          {
            errorLocation: 'VERIFICATION',
            errorType: 'LIMIT_EXCEED_TOTAL_VALUE',
          },
        );
      } else {
        setIsVerified('error');
        const msg =
          'Unable to process your request now, please try again later';
        setListVerifyMsg(msg);
        Utils.mixpanelTrack(
          CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
          {
            errorLocation: 'VERIFICATION',
            errorType: 'UNKNOWN_ERROR',
          },
        );
      }
    };
    suppressionListState.verifyCsvEntries({
      entries,
      type: selectedListType,
      isInclusion: false,
      callback: verifyCallback,
    });
  };

  const onFileChange = async (e) => {
    setIsVerified('');
    const callBack = (data) => {
      if (!data) {
        showErrorToolMsg('Uploaded list has no valid rows.');
      } else if (data !== '' && data === 'error') {
        showErrorToolMsg(
          `Maximum number of rows allowed is ${
            selectedListType === 'EMAIL'
              ? Utils.getSuppressionListEmailLimit() / 1000
              : '25'
          }K / List.`,
        );
      } else {
        if (inputValidation[ERROR_FIELD.UPLOAD_NEW_LIST] !== null) {
          const newValObj = Utils.copyObjectWithoutReference(inputValidation);
          delete newValObj[ERROR_FIELD.UPLOAD_NEW_LIST];
          setInputValidation(newValObj);
        }
        const { fname, entries } = data;
        setUploadedFileName(fname);
        verifyCsvEntries(entries);
      }
      setTimeout(() => {
        setFileUpload(false);
      }, 0);
      setTimeout(() => {
        setFileUpload(true);
      }, 50);
    };
    if (selectedListType === 'EMAIL') {
      Utils.uploadCsv(e, callBack, true, Utils.getSuppressionListEmailLimit());
    } else {
      Utils.uploadCsv(e, callBack, true, 25000);
    }
  };

  const triggerUpload = () => {
    setInputValidation({});
    const validationObj = {};
    if (selectedListType === '') {
      validationObj[ERROR_FIELD.CHOOSE_LIST_TYPE] = 1;
    }
    if (selectedListName === '') {
      validationObj[ERROR_FIELD.ENTER_LIST_NAME] = 1;
    }

    if (Object.keys(validationObj).length === 0) {
      showErrorToolMsg('');
      document.getElementById('uploadSuppressionListCsv').click();
    } else {
      setInputValidation(validationObj);
    }
  };

  const saveUserList = () => {
    showErrorToolMsg('');
    setInputValidation({});
    const validationObj = {};
    if (selectedListType === '') {
      validationObj[ERROR_FIELD.CHOOSE_LIST_TYPE] = 1;
    }
    if (selectedListName === '') {
      validationObj[ERROR_FIELD.ENTER_LIST_NAME] = 1;
    }
    if (verifiedListEntries.length === 0) {
      validationObj[ERROR_FIELD.UPLOAD_NEW_LIST] = 1;
    }

    if (
      Object.keys(validationObj).length === 0 &&
      verifiedListEntries.length > 0 &&
      isVerified === 'true'
    ) {
      setIsLoading(true);
      const saveCallback = (response) => {
        const { listDetail = null, errorCode = null } = response || {};
        if (errorCode === null && response !== null) {
          if (listDetail !== null) {
            const newList = Utils.copyObjectWithoutReference(
              suppressionListState.suppressionListEntries,
            );
            newList.push(listDetail);
            suppressionListState.setSuppressionListEntries(newList);
          }
          const userEmail = Utils.getUserEmail();
          commonModalState.setShowModal(true, {
            modalProps: { align: 'center' },
            Icon: ProcessingIcon,
            title: 'Your suppression list is being processed',
            description: (
              <>
                Please allow a few minutes. You'll receive an email notification
                to{' '}
                <a
                  href={`mailto:${userEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {userEmail}
                </a>{' '}
                once it's ready. You can then start building your list{' '}
              </>
            ),
            primaryButtonText: 'Ok',
          });
          setIsLoading(false);
          suppressionListState.setShowCreateList(false);
        } else if (errorCode === 203) {
          setIsVerified('error');
          setListVerifyMsg('Uploaded list has no valid rows.');
          Utils.mixpanelTrack(
            CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
            {
              errorLocation: 'SAVE',
              errorType: 'EMPTY_ROWS',
            },
          );
        } else if (errorCode === 202) {
          setIsVerified('error');
          setListVerifyMsg('Maximum number of rows allowed is 25K / List.');
          Utils.mixpanelTrack(
            CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
            {
              errorLocation: 'SAVE',
              errorType: 'LIMIT_EXCEED_PER_LIST',
            },
          );
        } else if (errorCode === 201) {
          setIsVerified('error');
          let msg = '';
          if (selectedListType === 'EMAIL') {
            msg =
              'Oops! You have reached the limit of 500K emails in your suppression list.';
          } else {
            msg =
              'Oops! You have reached the limit of 100K domains in your suppression list.';
          }
          setListVerifyMsg(msg);
          Utils.mixpanelTrack(
            CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
            {
              errorLocation: 'SAVE',
              errorType: 'LIMIT_EXCEED_TOTAL_VALUE',
            },
          );
        } else {
          setIsVerified('error');
          const msg =
            'Unable to process your request now, please try again later';
          setListVerifyMsg(msg);
          Utils.mixpanelTrack(
            CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_UPLOAD_FAILED,
            {
              errorLocation: 'SAVE',
              errorType: 'UNKNOWN_ERROR',
            },
          );
        }
      };
      suppressionListState.saveNewList({
        entries: verifiedListEntries,
        type: selectedListType,
        listName: selectedListName,
        isInclusion: false,
        callback: saveCallback,
      });
    } else {
      setInputValidation(validationObj);
    }
  };

  React.useEffect(() => {
    if (!suppressionListState.showCreateList) {
      setSelectedListName('');
      setSelectedListType('');
      showErrorToolMsg('');
      setInputValidation({});
      showErrorToolMsg('');
      setUploadedFileName('');
      setIsVerified('');
      setListVerifyMsg('');
      setVerifiedListEntries([]);
      setIsLoading(false);
    }
  }, [suppressionListState.showCreateList]);

  return (
    suppressionListState.showCreateList && (
      <Modal
        show
        closeModal={closeListPopup}
        className="add-to-suppression-list-popup"
      >
        <Modal.Icon SvgIcon={DocumentIcon} />
        <Modal.Title>Add New List</Modal.Title>
        <Modal.Content>
          <div className="short-info">
            Upload specific domains or email IDs to be excluded
            <br /> from search results. Limited to 25k rows / list. <br /> (
            <a
              className="sample-csv"
              href={SampleEmailCSV}
              download="sampleExcludeSuppressionListEmail"
            >
              Email Sample
            </a>
            ) (
            <a
              className="sample-csv"
              href={SampleDomainCSV}
              download="sampleExcludeSuppressionListDomain"
            >
              Domain Sample
            </a>
            )
          </div>

          <Select
            placeholder="Choose List Type"
            inlineLabel={false}
            enableValuesOutsideOfOptions
            label=""
            options={typesOfList}
            value={selectedListType || ''}
            onChange={(value) => {
              onContactLimitChange(value);
            }}
            wrapperClassName={`choose-list-type ${
              inputValidation[ERROR_FIELD.CHOOSE_LIST_TYPE] !== undefined
                ? 'error-border'
                : ''
            }`}
          />
          <Input
            id="createUpdateListbox"
            offsetValues={{ top: 0, bottom: 50 }}
            inputProps={{
              placeholder: 'List name',
              autoComplete: 'off',
              value: selectedListName,
            }}
            onChange={changeInputVal}
            className={`list-name ${
              inputValidation[ERROR_FIELD.ENTER_LIST_NAME] !== undefined
                ? 'error-border'
                : ''
            }`}
          />

          <div className="csv-upload-wrapper upload-csv">
            {fileUpload && (
              <input
                id="uploadSuppressionListCsv"
                className="custom-file-input"
                type="file"
                accept=".csv"
                onChange={onFileChange}
              />
            )}
            <div className="choose-file-wrapper">
              <div
                className={`choose-file ${
                  inputValidation[ERROR_FIELD.UPLOAD_NEW_LIST] !== undefined
                    ? 'error-border'
                    : ''
                }`}
                onClick={triggerUpload}
              >
                Choose a file
              </div>
              {uploadedFileName !== '' && (
                <div className="uploaded-file-name">{uploadedFileName}</div>
              )}
            </div>
          </div>

          <div className="validation-info-wrapper">
            <div className={`${errorToolMsg !== '' ? 'show' : ''} error-text`}>
              {errorToolMsg}
            </div>
            {(isVerified === 'true' ||
              isVerified === 'error' ||
              isVerified === 'error_custom') && (
              <div
                className={`verified-info-wrapper ${
                  isVerified === 'error' || isVerified === 'error_custom'
                    ? ' error'
                    : ''
                }`}
              >
                <InfoIcon width={18} height={18} className="title-info-icon" />
                {isVerified === 'error_custom' ? (
                  <div className="verified-info">
                    {listVerifyMsg === 0 ? (
                      <span>
                        Sorry, you have hit the limit of
                        <span className="span-ml span-mr">
                          {selectedListType === 'EMAIL'
                            ? '500K emails'
                            : '100K domains'}
                        </span>
                        in your suppression list. You cannot suppress any more
                        <span className="span-ml span-mr">
                          {selectedListType === 'EMAIL' ? 'emails' : 'domains'}
                        </span>
                      </span>
                    ) : (
                      <span>
                        Oops! You are about to hit the limit of
                        <span className="span-ml span-mr">
                          {selectedListType === 'EMAIL'
                            ? '500K emails'
                            : '100K domains'}
                        </span>{' '}
                        in your suppression list. Please reduce the number of
                        <span className="span-ml span-mr">
                          {selectedListType === 'EMAIL' ? 'emails' : 'domains'}
                        </span>
                        in the uploaded file to{' '}
                        {Utils.usNumberFormat(listVerifyMsg)} rows and try
                        again.
                      </span>
                    )}
                  </div>
                ) : (
                  <div
                    className="verified-info"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: listVerifyMsg || '',
                    }}
                  />
                )}
              </div>
            )}
            {isVerified === 'verifying' && (
              <div className="verifying-msg">Verifying ...</div>
            )}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            variant="outline"
            buttonProps={{
              text: 'Cancel',
            }}
            onClick={closeListPopup}
          />
          <Button
            buttonProps={{
              text: 'Save',
              disabled:
                Object.keys(inputValidation).length !== 0 ||
                selectedListName === '' ||
                uploadedFileName === '' ||
                verifiedListEntries.length === 0 ||
                selectedListType === '',
            }}
            isLoading={isLoading}
            onClick={saveUserList}
          />
        </Modal.Footer>
      </Modal>
    )
  );
}

export default observer(AddToSuppressionListPopup);
