import React from 'react';
import { observer } from 'mobx-react';
import ArrowIcon from 'Assets/svg/link-arrow.svg';
import ContactIndustry from 'Components/common/ContactList/ContactIndustry';
import ControlInput from 'Components/common/base/ControlInput';
import { showUnableToDeselectModal } from 'Pages/prospectSearch/contactSearch/modals';
import ContactCompany from 'Components/common/ContactList/ContactCompany';
import Button from 'Components/common/base/Button';
import CompanySector from 'Components/common/ContactList/CompanySector';
import CompanyFundingDetails from 'Components/common/ContactList/CompanyFundingDetails';
import companySearchState from 'Stores/companySearch';
import advSearchState from 'Stores/advSearch';
import userDetail from 'Stores/userDetail';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';
import './styles.scss';

function CompanyListLoadingPlaceHolder() {
  return (
    <div className="company-list-wrap">
      {Array(10)
        .fill()
        .map(() => {
          return (
            <div className="company-list-item" key={Math.random() * 100}>
              <div className="checkbox-wrap">
                <div className="check-box linear-background" />
              </div>
              <div className="company-name-wrapper">
                <div className="text-section">
                  <div className="company-name linear-background" />
                  <div className="location-wrapper linear-background"> </div>
                  <div className="contact-count linear-background"> </div>
                </div>
              </div>
              <div className="industry-wrapper">
                <div className="info-wrap linear-background" />
                <div className="industry-content-info linear-background" />
                <div className="company-number linear-background" />
              </div>
              <div className="tech-sector-list">
                <div className="inner-wrapper">
                  <div className="sector linear-background" />
                  <div className="sector linear-background" />
                </div>
              </div>
              <div className="company-funding-details">
                <p className="linear-background" />
                <p className="linear-background last-funding" />
                <p className="linear-background" />
              </div>
            </div>
          );
        })}
    </div>
  );
}

function CompanyHeader() {
  return (
    <div className="company-header">
      <div className="checkbox-wrap">&nbsp;</div>
      <div>Name</div>
      <div>Company Details</div>
      <div>Sector</div>
      <div>Funding Details</div>
    </div>
  );
}

function CompanyList(props) {
  const { isListLoading = false } = props;

  const companySelection = (index) => {
    if (
      companySearchState.selectedAllContactAcrossPage ||
      companySearchState.selectedContactFromRange
    ) {
      const deSelectCallback = () => {
        companySearchState.updateSelectedBulkContactAll(true);
      };
      showUnableToDeselectModal(deSelectCallback);
    } else {
      companySearchState.updateSelectedBulkContactIds(index);
    }
  };

  const goToContactTab = (domain = '', euCountry = false) => {
    if (domain !== '' && domain !== null) {
      Utils.mixpanelTrack(
        CONSTANTS.MX_AS_EVENTS.AS_LB_COMPANY_PAGE.VIEW_CONTACTS_CLICK,
        { domain },
      );

      const handleGotoContactTab = () => {
        if (euCountry && !featureFlagsAndPreferencesState.isEUSearchEnabled()) {
          Utils.showEURestrictionPopup();
        } else {
          Utils.setLocalStorage('searchJson', {
            companyName: [domain],
            dontDisplayOwnedContacts: true,
            dontDisplayTeamContacts: true,
            dontDisplayDeadContacts: true,
          });
          window.open('/advanced-search/contact#search', '_blank');
          advSearchState.setCurrentPage(0, '', true);
          advSearchState.retainSearch();
          userDetail.setCurrentTab('contact');
        }
      };

      handleGotoContactTab();
    }
  };

  const listView = companySearchState.modifiedSearchResults.map(
    (item, index) => {
      return (
        <div className="company-list-item" key={item.id}>
          <div className="checkbox-wrap">
            <ControlInput
              checked={item.checked}
              type="checkbox"
              centerAlign={false}
              label=""
              onChange={() => {
                companySelection(index);
              }}
            />
          </div>

          <ContactCompany
            cname={item.name}
            companyCountry={item.country}
            companyState={item.state}
            companyCity={item.city}
            companyLogo={item.logo}
            enablePopup={false}
            primaryDomain={item.primaryDomain}
            company={{
              linkedinURL: item.linkedInId,
              twitterURL: item.twitterId,
              facebookURL: item.facebookId,
              website: item.website,
            }}
          >
            <div className="company-action">
              {item.contactCount !== null &&
                item.contactCount !== undefined &&
                item.contactCount > 0 && (
                  <Button
                    variant="light"
                    buttonProps={{
                      text: `${Utils.usNumberFormat(item.contactCount)} ${item.contactCount > 1 ? 'Contacts' : 'Contact'}`,
                    }}
                    Icon={ArrowIcon}
                    onClick={() => {
                      goToContactTab(item.primaryDomain, item.euCountry);
                    }}
                  />
                )}
            </div>
          </ContactCompany>
          <ContactIndustry
            companyPhone={item.phone}
            industry={item.industry}
            employeeCount={item.employeeCount}
            revenue={item.revenue}
            showNA={false}
          />
          <CompanySector paKeywords={item.paKeywords} />
          <CompanyFundingDetails
            lastFundingAmount={item.lastFundingAmount}
            lastFundingDate={item.lastFundingDate}
            lastFundingType={item.lastFundingType}
            totalFundingAmount={item.totalFundingAmount}
          />
        </div>
      );
    },
  );

  return (
    <div
      className={`company-list${isListLoading ? ' company-list-loading-placeholder' : ''}`}
    >
      <CompanyHeader />
      <div className="company-list-wrap">
        {isListLoading ? <CompanyListLoadingPlaceHolder /> : listView}
      </div>
    </div>
  );
}

export default observer(CompanyList);
