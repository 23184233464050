import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import ReloadIcon from 'Assets/svg/new_icons/reload.svg';
import Modal from 'Components/common/base/Modal';
import Button from 'Components/common/base/Button';
import advSearchState from 'Stores/advSearch';

function SalesforceAuthPopup() {
  const navigate = useNavigate();

  const closeModal = () => {
    advSearchState.showSalesforceAuthError = false;
  };

  return (
    <Modal
      show={advSearchState.showSalesforceAuthError}
      closeModal={closeModal}
      className="salesforce-auth-popup"
    >
      <Modal.Icon SvgIcon={ReloadIcon} />
      <Modal.Title>Salesforce CRM Authorization</Modal.Title>
      <Modal.Content>
        It appears that your Salesforce CRM connection is not working. Please
        disconnect and reconnect your Salesforce account before purchasing
        contacts.
      </Modal.Content>
      <Modal.Footer>
        <p className="note">
          Need help?&nbsp;
          <a href="mailto:success@adapt.io">success@adapt.io</a>
        </p>
        <Button
          buttonProps={{
            text: 'Go to Salesforce Settings',
          }}
          onClick={() => {
            closeModal();
            navigate('/integrations/salesforce/settings');
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default observer(SalesforceAuthPopup);
