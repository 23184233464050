import React from 'react';
import { observer } from 'mobx-react';
import TechnologySectorImg from 'Assets/png/technology-sector.png';
import advSearchFiltersState from 'Stores/advSearchFilters';
import { MX_AS_EVENTS } from 'Utils/constants';
import AccordionCheckboxList from '../AccordionCheckboxList';

function TechnologySector(props) {
  const { page, currentFilterState, suggestionKey, selectedFilter } = props;

  return (
    <AccordionCheckboxList
      selectedFilter={selectedFilter}
      currentFilterState={currentFilterState}
      page={page}
      dataList={advSearchFiltersState.filterListValues.paKeywords}
      suggestionKey={suggestionKey}
      mxEventName={MX_AS_EVENTS.TECH_SECTOR_FILTER_APPLIED}
      getData={(data) => {
        return { pName: data.value };
      }}
      upgradeData={{
        title: '',
        message:
          'Discover contacts and companies based on 3,000+ sectors the company operates in. The sectors are derived from the target market the company operates in and their solutions',
        imageURL: TechnologySectorImg,
      }}
      isIncluded={(data) => {
        const { appliedFilter, listData, suggestionKey: key } = data;
        return appliedFilter?.[key]?.some((object) => {
          return object === listData.value;
        });
      }}
    />
  );
}

export default observer(TechnologySector);
