import { observable, action } from 'mobx';
import { makeApi, URLS } from 'Utils/apiUtil';
// eslint-disable-next-line import/no-cycle
import Utils from 'Utils/utils';
import * as CONSTANTS from 'Utils/constants';

class SuppressionListState {
  @observable accessor showCreateList = false;

  @observable accessor getListLoading = false;

  @observable accessor suppressionListEntries = [];

  @observable accessor suppressionListLoaded = false;

  @observable accessor callBack = () => {};

  @action
  setSuppressionListLoaded = (val = false) => {
    this.suppressionListLoaded = val;
  };

  @action
  setGetListLoading = (val = false) => {
    this.getListLoading = val;
  };

  @action
  setSuppressionListEntries = (val = []) => {
    this.suppressionListEntries = val;
  };

  @action
  setShowCreateList = (val) => {
    this.showCreateList = val;
    if (val) {
      document.getElementsByTagName('body')[0].classList.add('scrollHidden');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('scrollHidden');
    }
  };

  verifyCsvEntries = (props) => {
    const {
      entries,
      type,
      isInclusion = false,
      callback = () => {},
    } = props;
    const config = {
      url: URLS.suppressionList.getValidEntries,
      method: 'POST',
      data: { entries, type },
    };
    Utils.mixpanelTrack(
      CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_ADD_LIST_VERIFY,
      { listType: type, isInclusion },
    );
    makeApi(config)
      .then((response) => {
        if (response && response.status === 200 && response.data !== undefined) {
          callback(response.data);
        } else {
          callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  saveNewList = (
    props,
  ) => {
    const {
      entries,
      type,
      listName,
      isInclusion = false,
      callback = () => {},
    } = props;
    const config = {
      url: URLS.suppressionList.saveList,
      method: 'POST',
      data: { entries, type, listName, isInclusion },
    };
    Utils.mixpanelTrack(CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_SAVE_LIST, {
      listType: type,
      listName,
      isInclusion,
    });
    makeApi(config)
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data !== undefined
        ) {
          callback(response.data);
          Utils.mixpanelTrack(
            CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_LIST_CREATED_SUCCESS,
            {
              listType: type,
              listName,
              isInclusion,
            },
          );
        } else {
          callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  enableDisableList = (listId, actionType = 'enable', callback = () => {}) => {
    const config = {
      url: URLS.suppressionList.enableDisableList,
      method: 'POST',
      data: { listId, action: actionType },
    };
    Utils.mixpanelTrack(CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_TOGGLE, {
      listId,
      actionType,
    });
    makeApi(config)
      .then((response) => {
        if (response && response.status === 200 && response.data !== undefined) {
          callback(response.data);
        } else {
           callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  deleteList = (listId, callback = () => {}) => {
    const config = {
      url: URLS.suppressionList.deleteList,
      method: 'DELETE',
      data: { listId },
    };
    Utils.mixpanelTrack(CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_DELETE, {
      listId,
    });
    makeApi(config)
      .then((response) => {
        if (response && response.status === 200 && response.data !== undefined) {
          callback(response.data);
        } else {
          callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  downloadList = (listId, callback = () => {}) => {
    const config = {
      url: URLS.suppressionList.downloadList,
      method: 'POST',
      data: { listId },
    };
    Utils.mixpanelTrack(CONSTANTS.MX_SUPPRESSION_LIST_EVENTS.LB_SL_DOWNLOAD, {
      listId,
    });
    makeApi(config)
      .then((response) => {
        if (response && response.status === 200 && response.data !== undefined) {
          callback(response.data);
        } else {
          callback(null);
        }
      })
      .catch(() => {
        callback(null);
      });
  };

  @action
  getSuppressionList = (type = 'exclusion') => {
    const config = {
      url: URLS.suppressionList.getList + type,
      method: 'GET',
    };
    this.setGetListLoading(true);
    makeApi(config)
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data !== undefined &&
          response.data.list !== undefined &&
          response.data.list !== null
        ) {
          this.setSuppressionListEntries(response.data.list);
        }
        this.setSuppressionListLoaded(true);
        this.setGetListLoading(false);
      })
      .catch(() => {
        this.setGetListLoading(false);
      });
  };
}
const suppressionListState = new SuppressionListState();

export default suppressionListState;
