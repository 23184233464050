import React from 'react';
import { observer } from 'mobx-react';
import { useMatch, Navigate, useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CompaniesEnrichedImg from 'Assets/png/companies-enriched.png';
import ContactsEnrichedImg from 'Assets/png/contacts-enriched.png';
import enrichmentState from 'Stores/enrichment';
import Utils from 'Utils/utils';
import './styles.scss';
import PageContentHeader from 'Components/common/PageContentHeader';

const contactFields = [
  'First Name',
  'Last Name',
  'Email',
  'Linkedin Url',
  'Title',
  'Department1',
  'Department2',
  'Level',
  'City',
  'State',
  'Country',
  'Phone Direct',
  'Phone Mobile',
  'Phone Other',
  'Email Validation Status',
];

const companyFields = [
  'Website',
  'Company Name',
  'Company Street',
  'Company City',
  'Company State',
  'Company Country',
  'Company Zipcode',
  'Company Head Count',
  'Company Revenue',
  'Phone Company',
  'Industry',
  'Sector',
];

const chartOptions = {
  indexAxis: 'y',
  responsive: true,
  categoryPercentage: 0.9,
  datasets: {
    bar: {
      borderRadius: 6,
      barPercentage: 0.9,
      maxBarThickness: 8,
      skipNull: true,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#6670A2',
        font: {
          size: 14,
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'right',
      formatter: Math.round,
      font: {
        size: 14,
        lineHeight: 14,
      },
      color: '#6670A2',
    },
    legend: {
      display: false,
    },
    tooltip: { enabled: false },
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Filler,
  ChartDataLabels,
);

function getChartData(fieldCount, fields) {
  const chartData = Object.keys(fieldCount).reduce(
    (output, key) => {
      if (fields.includes(key)) {
        return {
          labels: [...output.labels, key],
          datasets: [
            {
              ...output.datasets[0],
              data: [...output.datasets[0].data, fieldCount[key]],
            },
          ],
        };
      }

      return output;
    },
    {
      labels: [],
      datasets: [
        { label: 'No. of contacts', data: [], backgroundColor: '#6047D9' },
      ],
    },
  );
  return chartData;
}

function FileEnrichmentReport() {
  const match = useMatch('/enrichment/file/:id');

  const navigate = useNavigate();

  const data = enrichmentState.fileEnrichmentList.find((list) => {
    return list.id === match.params?.id;
  });

  if (!data) {
    return <Navigate to="/enrichment/file" />;
  }

  const {
    enrichedCsvFile,
    totalRecord,
    enrichedRecord,
    fieldCount,
    creditUsed,
    enrichExclude = 0,
  } = data;

  const companyFieldsData = getChartData(fieldCount, companyFields);
  const contactFieldsData = getChartData(fieldCount, contactFields);

  const chartHeight =
    Math.max(companyFieldsData.labels.length, contactFieldsData.labels.length) *
      40 +
    40;

  const maxCompanyValue = Math.max.apply(
    null,
    companyFieldsData.datasets[0].data,
  );

  const maxContactValue = Math.max.apply(
    null,
    contactFieldsData.datasets[0].data,
  );

  const companyChartOptions = Utils.copyObjectWithoutReference(chartOptions);

  const contactChartOptions = Utils.copyObjectWithoutReference(chartOptions);

  companyChartOptions.scales.x.max = maxCompanyValue + maxCompanyValue * 0.3;

  contactChartOptions.scales.x.max = maxContactValue + maxContactValue * 0.3;

  const isContactsExcluded = enrichExclude !== null && enrichExclude > 0;

  return (
    <div className="file-enrichment-report">
      <PageContentHeader
        titleData={{
          text: 'CSV Enrichment Report',
          href: '/enrichment/file',
          onClick: (e) => {
            e.preventDefault();
            navigate(-1);
          },
        }}
      />
      <div className="report-card">
        <h2>Enrichment Report</h2>
        <div className="description">
          We have enriched your file with accurate and up-to-date information.
          Here&#39;s a quick summary,
        </div>
        <div className="stats-wrapper">
          <div className="stats-card">
            <img src={CompaniesEnrichedImg} alt="Companies enriched" />
            <div className="stat-content">
              <span className="count">
                {Utils.numberFormat(enrichedRecord)}
              </span>
              <span className="text">
                {`${enrichedRecord > 1 ? 'Companies' : 'Company'} Enriched`}
              </span>
            </div>
          </div>
          <div className="stats-card">
            <img src={ContactsEnrichedImg} alt="Contacts enriched" />
            <div className="stat-content">
              <span className="count">{Utils.numberFormat(creditUsed)}</span>
              <span className="text">
                {`${creditUsed > 1 ? 'Contacts' : 'Contact'}  Enriched`}
              </span>
            </div>
          </div>
        </div>
        <div className="note">
          <span className="span-mr note-title">Note:</span>
          <span className="note-text">
            <span>
              Personal, Disposable & Group Email addresses will not be enriched.
            </span>
            {isContactsExcluded && (
              <span>
                {Utils.numberFormat(enrichExclude)} out of{' '}
                {Utils.numberFormat(totalRecord)} Contact Information is not
                enriched.
              </span>
            )}
          </span>
        </div>
        <div className="charts-wrapper">
          {companyFieldsData.labels.length > 0 && (
            <div className="chart-wrapper">
              <div className="chart-title">Company Fields Enriched</div>
              <div className="chart-box" style={{ height: chartHeight }}>
                <Bar
                  options={companyChartOptions}
                  data={{
                    labels: companyFieldsData.labels,
                    datasets: companyFieldsData.datasets,
                  }}
                />
              </div>
            </div>
          )}
          {contactFieldsData.labels.length > 0 && (
            <div className="chart-wrapper">
              <div className="chart-title">Contact Fields Enriched </div>
              <div className="chart-box" style={{ height: chartHeight }}>
                <Bar
                  options={contactChartOptions}
                  data={{
                    labels: contactFieldsData.labels,
                    datasets: contactFieldsData.datasets,
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <a
          className="button"
          type="file"
          href={enrichedCsvFile}
          target="_blank"
          rel="noreferrer"
        >
          Download
        </a>
      </div>
    </div>
  );
}

export default observer(FileEnrichmentReport);
