import React from 'react';
import './styles.scss';

function CRMPreferencesCard(props) {
  const { title, children, header, className = '' } = props;

  return (
    <>
      {header && <div className="crm-header-title">{header}</div>}
      <div className={`crm-preferences-card ${className}`.trimEnd()}>
        <div className="setting-row-wrap">
          {title && <div className="title">{title}</div>}
          {children}
        </div>
      </div>
    </>
  );
}

export default CRMPreferencesCard;
