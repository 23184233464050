import React from 'react';
import { observer } from 'mobx-react';
import AddToListIcon from 'Assets/svg/new_icons/document.svg';
import Button from 'Components/common/base/Button';
import Pagination from 'Components/common/pagination';
import LoadingWrapper from 'Components/common/base/Loader';
import userDetail from 'Stores/userDetail';
import * as CONSTANTS from 'Utils/constants';
import advSearchState from 'Stores/advSearch';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import Utils from 'Utils/utils';
import SelectionDropdown from '../../SelectionDropdown';
import { addToListWithOptionsState } from '../AddToList';
import {
  showNotEnoughCreditsPopup,
  showUnableToDeselectModal,
} from '../modals';
import './styles.scss';

function ContactMiddleSection(props) {
  const { savedSearchId } = props;
  const [rangeBoxValue, setRangeBoxValue] = React.useState('');
  const [limitContactsPerCompany, setLimitContactsPerCompany] = React.useState({
    value: '',
  });

  const searchCount = advSearchState.searchResultCount;

  const maxPurchaseLimit =
    searchCount > userDetail.userInfo.purchaseLimit
      ? userDetail.userInfo.purchaseLimit
      : searchCount;

  const { userInfo } = userDetail;

  const { MX_AS_EVENTS } = CONSTANTS;
  const { AS_LIMIT_CONTACT_PER_COMPANY } = MX_AS_EVENTS;

  const addToListBtnClick = () => {
    if (
      advSearchState.bulkPurchaseIds.length > 0 ||
      advSearchState.bulkRangeCount > 0
    ) {
      if (Utils.checkIsEmailVerified(true)) {
        addToListWithOptionsState.setShowListPopup(true);
      }
    }
  };

  const clearSelections = () => {
    advSearchState.clearAllSelection();
    advSearchState.updateSelectedBulkContactAll(true);
  };

  const rangeSelection = () => {
    if (
      rangeBoxValue !== '' &&
      rangeBoxValue > 0 &&
      rangeBoxValue <= maxPurchaseLimit
    ) {
      advSearchState.selectHugeContacts(rangeBoxValue);
      setRangeBoxValue('');
    }
  };

  const rangeValueChange = (value) => {
    setRangeBoxValue(value);
  };

  const limitContactsPerCompanyAndPurchase = () => {
    const noOfContacts = limitContactsPerCompany.value;
    const limit = rangeBoxValue;
    setLimitContactsPerCompany({ value: '' });
    setRangeBoxValue('');

    if (Utils.checkIsEmailVerified()) {
      const { lbCredits } = userInfo;
      const isMemberCreditLimit =
        featureFlagsAndPreferencesState.isTeamManageEnabled() &&
        !userDetail.userInfo.isEnterpriseAdmin &&
        userDetail.userInfo.remainingIndividualLbCredits !== -1 &&
        advSearchState.uniqueCompanyCount < lbCredits;
      const availCreditCount = isMemberCreditLimit
        ? userDetail.userInfo.remainingIndividualLbCredits
        : lbCredits;

      const limitAndListIdCallback = async (data) => {
        const { listId, callback, checkboxValues, validationStatus } = data;
        const limitValue = rangeBoxValue || advSearchState.uniqueCompanyCount;
        const condition =
          checkboxValues.email && checkboxValues.phone
            ? limitValue > availCreditCount ||
              limitValue > userDetail.userInfo.phoneCreditsRemaining
            : checkboxValues.email
              ? limitValue > availCreditCount
              : checkboxValues.phone
                ? limitValue > userDetail.userInfo.phoneCreditsRemaining
                : false;

        if (condition) {
          Utils.mixpanelTrack(
            AS_LIMIT_CONTACT_PER_COMPANY.PURCHASE_VIEWS_EXCEEDED,
            {},
          );
          showNotEnoughCreditsPopup(isMemberCreditLimit);
          callback();
        } else {
          await advSearchState.purchaseContactAsync({
            listId,
            required:
              advSearchState.getRequiredPropertyForPurchase(checkboxValues),
            limitContactsPerCompany: true,
            limit,
            limitPerDomain: noOfContacts,
            validationStatus,
          });

          callback();
        }
      };
      addToListWithOptionsState.setAddToListOptions({
        saveAction: limitAndListIdCallback,
      });
      addToListWithOptionsState.setShowListPopup(true);
    }
  };

  const applySelection = () => {
    if (limitContactsPerCompany.value) {
      limitContactsPerCompanyAndPurchase();
    } else {
      rangeSelection();
      addToListBtnClick();
    }
  };

  const changePage = (pageNo, opr) => {
    if (
      advSearchState.selectedAllContactAcrossPage ||
      advSearchState.selectedContactFromRange
    ) {
      const deselectCallback = () => {
        advSearchState.clearAllSelection();
        advSearchState.updateSelectedBulkContactAll(true);
      };

      showUnableToDeselectModal(deselectCallback);
    } else {
      advSearchState.setCurrentPage(pageNo, opr);
    }
  };

  const changePageLimit = (val) => {
    advSearchState.setPageLimit(val);
  };

  const isSavedSearchEmptyState =
    savedSearchId !== '' && advSearchState.searchResults.length === 0;

  const isCompanyCountLoading = advSearchState.companyCountLoading;

  return (
    (advSearchState.searchResults.length > 0 || isSavedSearchEmptyState) && (
      <div className="middle-section">
        <div className="actions-section">
          <SelectionDropdown
            className={
              isSavedSearchEmptyState ||
              advSearchState.resultLoading ||
              advSearchState.companyCountLoading
                ? 'disable'
                : ''
            }
            allSelected={advSearchState.allContactSelected}
            selectedCount={
              advSearchState.bulkRangeCount ||
              advSearchState.bulkPurchaseIds.length
            }
            maxLimit={maxPurchaseLimit}
            rangeBoxValue={rangeBoxValue}
            rangeValueChange={rangeValueChange}
            applySelection={applySelection}
            clearSelections={clearSelections}
            enableLimitContactPerCompany={
              featureFlagsAndPreferencesState.flags
                .limitContactPerCompanyEnabled
            }
            limitContactsPerCompany={limitContactsPerCompany}
            setLimitContactsPerCompany={setLimitContactsPerCompany}
          />
          {(advSearchState.bulkRangeCount ||
            advSearchState.bulkPurchaseIds.length > 0) && (
            <div className="add-to-list-wrapper">
              <Button
                onClick={addToListBtnClick}
                Icon={AddToListIcon}
                buttonProps={{
                  text: 'Add to list',
                  className: 'add-to-list',
                  disabled:
                    advSearchState.bulkPurchaseIds.length === 0 &&
                    advSearchState.bulkRangeCount === 0,
                }}
              />
            </div>
          )}
        </div>
        <div className="count-and-pagination">
          {searchCount > 0 && !advSearchState.resultLoading && (
            <div className="leads-count">
              {Utils.usNumberFormat(searchCount)}
              &nbsp;
              {searchCount > 1 ? 'contacts' : 'contact'}
              &nbsp;from&nbsp;
              {isCompanyCountLoading ? (
                <div className="company-count-loader">
                  <LoadingWrapper show />
                </div>
              ) : (
                advSearchState.uniqueCompanyCount > 0 && (
                  <>
                    {Utils.usNumberFormatForCompanyCount(
                      advSearchState.uniqueCompanyCount,
                    )}
                    &nbsp;
                    {advSearchState.uniqueCompanyCount > 1
                      ? 'companies'
                      : 'company'}
                  </>
                )
              )}
            </div>
          )}
          {searchCount > 0 && (
            <Pagination
              totalCount={searchCount}
              rowsPerPage={advSearchState.pageLimit}
              currentPage={advSearchState.currentPage + 1}
              updateCurrentPage={changePage}
              onRowsPerPageUpdate={changePageLimit}
              pageName="advSearchContact"
              showTotalLogs={false}
            />
          )}
        </div>
      </div>
    )
  );
}

export default observer(ContactMiddleSection);
