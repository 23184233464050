import React, { useEffect, useRef, useState } from 'react';
import { EMPTY_FUNCTION } from 'Utils/constants';
import './styles.scss';
import useOutsideClick from '../useOutsideClick';
import { getSuggestionStyle } from '../Input';

function Arrow({ showArrow, style }) {
  const { top, bottom, left, right } = style;
  return (
    showArrow && (
      /**
       * getting style from dropdown and subtracting 12(height of arrow)
       * to show above/below dropdown
       * */
      <div
        className="arrow-wrapper"
        style={{
          left,
          right,
          top: top !== 'auto' ? `${parseFloat(top) - 12}px` : 'auto',
          bottom:
            bottom !== undefined ? `${parseFloat(bottom) - 12}px` : undefined,
        }}
      >
        <div className="arrow" />
      </div>
    )
  );
}

function Dropdown(props) {
  const {
    toggleElement,
    children,
    align = 'start',
    className = '',
    wrapperClassName = '',
    showOnHover = false,
    dropdownHeight = null,
    onCloseDropdown = EMPTY_FUNCTION,
    onOpenDropdown = EMPTY_FUNCTION,
    offsetValues = { top: 0, bottom: 0 },
    fixedPosition = false,
    excludeElementForOutsideClick,
    showArrow = false,
  } = props;

  const [dropdownValues, setDropdownValues] = useState({
    show: false,
    style: {},
  });
  const toggleRef = useRef(null);

  const getStyle = () => {
    const toggleEle = toggleRef.current;
    const style = getSuggestionStyle(toggleEle, offsetValues, {
      fixedPosition,
      elementOffset: { top: 12, bottom: 12 },
    });

    style.position = fixedPosition ? 'fixed' : 'absolute';

    if (!fixedPosition) {
      if (align === 'end') {
        style.right = 0;
        style.left = 'auto';
      } else {
        style.left = 0;
      }
    }
    if (dropdownHeight) {
      style.maxHeight = `${dropdownHeight}px`;
    }

    return style;
  };

  const closeDropdown = () => {
    setDropdownValues({ show: false, style: {} });
  };

  const dropdownRef = useOutsideClick(
    closeDropdown,
    excludeElementForOutsideClick,
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (dropdownValues.show) {
      setDropdownValues({
        show: true,
        style: getStyle(),
      });
      onOpenDropdown();
    }
    onCloseDropdown();
  }, [dropdownValues.show]);

  useEffect(() => {
    if (dropdownValues.style.visibility === 'hidden') {
      setDropdownValues({
        ...dropdownValues,
        style: getStyle(),
      });
    }
  }, [dropdownValues.style]);

  const toggleDropdown = (show) => {
    let style = {};
    if (show) {
      style = getStyle();
    }
    setDropdownValues({ show, style });
  };

  function onDropdownClick(e) {
    if (
      !showOnHover &&
      (e.target.dataset?.close === 'true' ||
        toggleRef?.current?.contains(e.target))
    ) {
      toggleDropdown(!dropdownValues.show);
    }
  }

  return (
    <div
      ref={dropdownRef}
      className={`dropdown${showArrow ? ' has-arrow' : ''} ${wrapperClassName}`.trim()}
      onClick={onDropdownClick}
      onKeyDown={onDropdownClick}
      role="menu"
      tabIndex={0}
    >
      {showOnHover ? (
        <div
          className="toggle-wrap hover-toggle"
          ref={toggleRef}
          onMouseEnter={() => {
            setDropdownValues({
              ...dropdownValues,
              style: getStyle(),
            });
          }}
        >
          {toggleElement}
          <div
            className={`dropdown-wrapper animated-dropdown animated-fast${
              dropdownValues.style.bottom ? ' drop-up' : ''
            } ${className}`}
            style={dropdownValues.style}
          >
            {children}
          </div>
          <Arrow showArrow={showArrow} style={dropdownValues.style} />
        </div>
      ) : (
        <>
          <div className="toggle-wrap" ref={toggleRef}>
            {toggleElement}
          </div>
          {dropdownValues.show && (
            <>
              <div
                className={`dropdown-wrapper animated-dropdown animated-fast${
                  dropdownValues.style.bottom ? ' drop-up' : ''
                } ${className}`}
                style={dropdownValues.style}
              >
                {children}
              </div>
              <Arrow showArrow={showArrow} style={dropdownValues.style} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Dropdown;
