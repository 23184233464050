import React from 'react';
import { Link } from 'react-router-dom';
import RightArrow from 'Assets/svg/right-arrow.svg';
import Utils from 'Utils/utils';
import { EMPTY_FUNCTION } from 'Utils/constants';
import SharedListIcon from '../SharedListIcon';
import './styles.scss';

function ListTable(props) {
  const {
    list,
    headersList,
    className = '',
    viewAllData = {},
    emptyState = {},
    onClick = EMPTY_FUNCTION,
  } = props;

  const listToShow = list.slice(0, 5);

  if (listToShow.length === 0) {
    const { imageURL, title, message } = emptyState;

    return (
      <div className="list-table-empty">
        <img src={imageURL} alt="empty state illustration" />
        <p className="empty-state-title">{title}</p>
        <p className="empty-state-message">{message}</p>
      </div>
    );
  }

  return (
    <div className="list-table-wrapper">
      <div className={`list-table ${className}`.trimEnd()}>
        <div className="list-table-header">
          {headersList.map((headerObject) => {
            const { text, key } = headerObject;
            return (
              <div className="header-item" key={`${text}-${key}`}>
                {text}
              </div>
            );
          })}
        </div>
        <div className="list-body">
          <div className="list-body-inner">
            {listToShow.map((data) => {
              const { id, isShared } = data;
              const linkAction = () => {
                onClick(data);
              };
              return (
                <div className="list-row-item" key={id} onClick={linkAction}>
                  {headersList.map((headerObject) => {
                    const { key, showShared, type = '' } = headerObject;
                    const value = data[key];

                    return (
                      <div className="list-item" key={`${id}-${key}`}>
                        <span className="show-ellipsis" title={value}>
                          {type === 'date'
                            ? Utils.getFormattedDate(value) || '-NA-'
                            : type === 'number'
                              ? Utils.numberFormat(value)
                              : value}
                        </span>
                        {showShared && isShared && (
                          <SharedListIcon data={{ isSharedList: true }} />
                        )}
                      </div>
                    );
                  })}
                  <div className="list-item icon-wrapper">
                    <RightArrow
                      className="right-arrow"
                      onClick={() => {
                        onClick(data);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {list.length > listToShow.length && (
        <Link to={viewAllData.link} className="view-all-link">
          {viewAllData.text}
          <RightArrow />
        </Link>
      )}
    </div>
  );
}

export default ListTable;
