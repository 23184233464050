import React, { useId } from 'react';
import './styles.scss';

function Toggle(props) {
  const { toggle = false, cbk, firstText, lastText } = props;

  const getTogglePlan = () => {
    cbk(!toggle);
  };

  const id = useId();

  return (
    <div className="toggle-button-wrapper">
      {firstText && (
        <span
          className={`toggle-text right-pad ${!toggle ? 'active-toggle' : ''}`}
        >
          {firstText}
        </span>
      )}
      <div className={`toggle-sequence ${toggle ? 'active' : 'inactive'}`}>
        <div className="toggle-button">
          <input
            id={id}
            type="checkbox"
            className="toggle-checkbox"
            checked={toggle}
            onChange={() => {}}
            onClick={getTogglePlan}
          />
          <div className="knobs" />
          <div className="layer" />
        </div>
      </div>
      {lastText && (
        <span
          className={`toggle-text left-pad ${toggle ? 'active-toggle' : ''}`}
        >
          {lastText}
        </span>
      )}
    </div>
  );
}

export default Toggle;
