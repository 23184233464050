import React from 'react';
import ConnectionIcon from 'Assets/svg/new_icons/connection-arrow.svg';
import './styles.scss';

function TwoIconConnector(props) {
  const FirstIcon = props?.firstIcon;
  const SecondIcon = props?.secondIcon;

  return (
    <div className="icon-connect-wrap">
      <div className="icon-box">
        <FirstIcon />
      </div>
      <ConnectionIcon className="swap-icon" />
      <div className="icon-box">
        <SecondIcon className="logo" />
      </div>
    </div>
  );
}

export default TwoIconConnector;
