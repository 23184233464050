import React from 'react';
import format from 'date-fns/format';
import _refiner from 'refiner-js';
import WarningIcon from 'Assets/svg/new_icons/warning.svg';
import LifeSaverIcon from 'Assets/svg/life-saver.svg';
import { toasterState } from 'Components/common/base/Toaster';
import { commonModalState } from 'Components/common/base/Modal/CommonModal';
import { loadScriptsDynamically } from 'Components/common/loadScriptDynamically';
import userDetail from 'Stores/userDetail';
import trialUserOnboardingState from 'Stores/trialUserOnboardingState';
import { URLS, makeApi } from 'Utils/apiUtil';
import {
  getAdminConnectedToDifferentCrmMsg,
  getAdminNotConnectedMsg,
  getUserNotConnectedMsg,
  getAuthorizationRequiredMsg,
} from 'Pages/integration/crmConnectionErrorModals';
import featureFlagsAndPreferencesState from 'Stores/featureFlagsAndPreferences';
import * as CONSTANTS from './constants';

const Utils = {};

Utils.routerProps = {};

const {
  PAGE_PATH_MAP,
  LB_WEB_URL,
  SEOCookieQueryMapping,
  REFINER_EVENTS_AND_PROPERTIES,
} = CONSTANTS;

Utils.enableBodyScroll = () => {
  if (window && window.document) {
    const ele = window.document.getElementsByTagName('body');
    if (ele[0].classList.contains('stop-scroll')) {
      ele[0].classList.remove('stop-scroll');
    } else if (ele[0].classList.contains('stop-scroll-x')) {
      ele[0].classList.remove('stop-scroll-x');
    } else if (ele[0].classList.contains('stop-scroll-y')) {
      ele[0].classList.remove('stop-scroll-y');
    }
  }
};

Utils.stopBodyScroll = (hiddenX = false, hiddenY = false) => {
  if (window && window.document) {
    if (!hiddenX && !hiddenY) {
      const ele = window.document.getElementsByTagName('body');
      if (!ele[0].classList.contains('stop-scroll')) {
        ele[0].classList.add('stop-scroll');
      }
    } else if (hiddenX) {
      const ele = window.document.getElementsByTagName('body');
      if (!ele[0].classList.contains('stop-scroll-x')) {
        ele[0].classList.add('stop-scroll-x');
      }
    } else if (hiddenY) {
      const ele = window.document.getElementsByTagName('body');
      if (!ele[0].classList.contains('stop-scroll-y')) {
        ele[0].classList.add('stop-scroll-y');
      }
    }
  }
};

Utils.getExportDateString = (dateObj = new Date()) => {
  const dateString = `${
    `0${dateObj.getMonth() + 1}`.slice(-2) +
    `0${dateObj.getDate()}`.slice(-2) +
    dateObj.getFullYear()
  }-${`0${dateObj.getHours()}`.slice(-2)}${`0${dateObj.getMinutes()}`.slice(
    -2,
  )}${`0${dateObj.getSeconds()}`.slice(-2)}`;

  return dateString;
};

Utils.downloadToCsv = (obj, customPrefix = '') => {
  const fileName =
    customPrefix === ''
      ? `AdaptExport-${Utils.getExportDateString()}`
      : customPrefix;

  const ele = document.createElement('a');
  const href = document.createAttribute('href');
  href.value = `data:attachment/csv;charset=utf-8,${encodeURIComponent(
    obj.csvData,
  )}`;
  ele.setAttributeNode(href);
  const download = document.createAttribute('download');
  download.value = `${fileName}.csv`;
  ele.setAttributeNode(download);
  ele.click();
  ele.remove();
};

// include company domain CSV limit
Utils.getDomainSize = () => {
  const { memberId } = userDetail.userInfo;
  if (
    Object.keys(CONSTANTS.customDomainSizeIncludeCompany).includes(memberId)
  ) {
    return CONSTANTS.customDomainSizeIncludeCompany[memberId];
  }
  return CONSTANTS.defaultNameDomainsMaxSize;
};

Utils.getExcludeCompanyDomainSize = () => {
  const { memberId } = userDetail.userInfo;
  if (
    Object.keys(CONSTANTS.customDomainSizeExcludeCompany).includes(memberId)
  ) {
    return CONSTANTS.customDomainSizeExcludeCompany[memberId];
  }
  return CONSTANTS.defaultNameDomainsMaxSize;
};

// eslint-disable-next-line consistent-return
Utils.uploadCsv = (
  changeEvent,
  callBack,
  checkMaxEntry,
  checkMaxEntryValue = 25000,
) => {
  const nameDomainsMaxSize = 500;
  const { files } = changeEvent.target;
  if (files.length) {
    const r = new FileReader();
    r.onload = (e) => {
      const contents = e.target.result;
      const domains = contents.split(/\r\n|\n|\r/);

      if (checkMaxEntry !== undefined && checkMaxEntry === true) {
        if (domains.length > checkMaxEntryValue) {
          callBack('error');
        } else {
          const domainMap = {};
          domainMap.fname = files[0].name;
          const validDomain = [];
          // eslint-disable-next-line no-unused-expressions
          domains &&
            domains.forEach((item) => {
              if (item !== '') {
                validDomain.push(item);
              }
            });
          domainMap.entries = validDomain;
          callBack(domainMap);
        }
      } else if (domains.length > nameDomainsMaxSize) {
        callBack('error');
      } else {
        const domainMap = {};
        domainMap.fname = files[0].name;
        let domainNew = [];
        let j = 0;
        for (let i = 0; i < domains.length; i += 1) {
          if (domains[i] !== undefined) {
            // eslint-disable-next-line prefer-destructuring
            domainNew[(j += 1)] = domains[i].split(',')[0];
          }
        }

        // eslint-disable-next-line consistent-return
        domainNew = domainNew.filter((item) => {
          if (item !== '' && item !== undefined) {
            return item;
          }
          return false;
        });
        domainMap.domains = domainNew;
        callBack(domainMap);
      }
    };

    r.readAsText(files[0]);
  } else {
    callBack(false);
  }
};

Utils.validateDomain = (domain) => {
  if (
    // eslint-disable-next-line no-useless-escape
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      domain,
    )
  ) {
    return true;
  }

  return false;
};

Utils.setLocalStorage = (key, value) => {
  if (key !== undefined && value !== undefined) {
    let val = value;
    if (typeof value === 'object') {
      val = JSON.stringify(val);
    }
    // console.log('set local ', typeof val);
    // console.log('set local ', val);
    localStorage.setItem(key, val);
  }

  // console.log('reads data', JSON.parse(localStorage.getItem(key)));
};

Utils.removeLocalStorage = (key) => {
  if (key !== undefined) {
    localStorage.removeItem(key);
  }
};

Utils.getLocalStorage = (key, parseVal = true) => {
  let data = {};
  if (localStorage.getItem(key) !== undefined && parseVal) {
    data = JSON.parse(localStorage.getItem(key));
  } else {
    data = localStorage.getItem(key);
  }
  return data;
};

Utils.numberFormat = (value = '') => {
  return new Intl.NumberFormat().format(value);
};

Utils.viewsDownloadsNumberFormat = (count) => {
  if (count >= 10000000) {
    return 'Unlimited';
  }
  return Utils.numberFormat(count);
};

Utils.getCreditResetPeriodInString = (type = 0, creditResetPeriod = 0) => {
  let creditResetPeriodInString = 'per month';
  if (type === 0) {
    creditResetPeriodInString = 'per month';
  } else if (creditResetPeriod === 0) {
    creditResetPeriodInString = 'per month';
  } else if (creditResetPeriod === 3) {
    creditResetPeriodInString = 'every 3 months';
  } else if (creditResetPeriod === 6) {
    creditResetPeriodInString = 'every 6 months';
  } else if (creditResetPeriod === 12) {
    creditResetPeriodInString = 'per year';
  }
  return creditResetPeriodInString;
};

Utils.usNumberFormat = (count) => {
  let totalNumberFormatted = '';

  // eslint-disable-next-line radix
  if (count >= 1000000) {
    totalNumberFormatted = `${parseInt(count / 1000000)}M+`;
  } else {
    totalNumberFormatted = Utils.numberFormat(count);
  }

  return totalNumberFormatted;
};

Utils.usNumberFormatForCompanyCount = (count) => {
  let totalNumberFormatted = '';
  // eslint-disable-next-line radix
  if (count >= 1000000) {
    totalNumberFormatted = `${parseInt(count / 1000000)}M+`;
  } else if (count >= 10000) {
    // eslint-disable-next-line radix
    totalNumberFormatted = `${parseInt(count / 1000)}K+`;
  } else {
    totalNumberFormatted = Utils.numberFormat(count);
  }

  return totalNumberFormatted;
};

Utils.formatNumber = (count) => {
  let totalNumberFormatted = '';
  // eslint-disable-next-line radix
  if (count >= 1000000000) {
    totalNumberFormatted = `${Number(parseFloat(count / 1000000000).toFixed(2))}B`;
  } else if (count >= 1000000) {
    totalNumberFormatted = `${Number(parseFloat(count / 1000000).toFixed(2))}M`;
  } else if (count >= 10000) {
    // eslint-disable-next-line radix
    totalNumberFormatted = `${Number(parseFloat(count / 1000).toFixed(2))}K`;
  } else {
    totalNumberFormatted = Utils.numberFormat(count);
  }

  return totalNumberFormatted;
};

Utils.registerMixpanel = () => {
  const { userInfo } = userDetail;
  const {
    name,
    email,
    creationTimeStamp = '',
    source,
    mixpanelData = {},
    pageSource,
    lbCredits,
    phoneCreditsRemaining,
  } = userInfo;

  const { referrer = '' } = mixpanelData || {};

  const userType = Utils.isFreeTrialPlan()
    ? 'Trial'
    : Utils.isPaidUser()
      ? 'Paid'
      : 'Free';

  mixpanel.register({
    email,
    name,
    'Signup Date': creationTimeStamp
      ? new Date(creationTimeStamp).toISOString()
      : null,
    'User Source': source,
    'User Referrer': referrer,
    'User Type': userType,
    'Page Source': pageSource,
    isLbCreditsPlan: true,
  });
  mixpanel.identify(email);
  Utils.mixpanelUpdateProfileProperty({
    $email: email,
    $name: name,
    'User Type': userType,
    'Email Credits Remaining': lbCredits,
    'Phone Credits Remaining': phoneCreditsRemaining || 0,
    'LB Version': process.env.BUILD_VERSION,
  });
};

Utils.mixpanelAlias = (email) => {
  mixpanel.alias(email);
  mixpanel.identify(email);
};

Utils.mixpanelTrack = (eventName = '', property = {}) => {
  window.mixpanel.track(eventName, property);
};

Utils.mixpanelUpdateProfileProperty = (property = {}) => {
  window.mixpanel.people.set(property);
};

Utils.mixpanelIncrementProfileProperty = (propertyName = '', value = 1) => {
  if (propertyName) {
    window.mixpanel.people.increment(propertyName, value);
  }
};

Utils.mixpanelReset = () => {
  window.mixpanel.reset();
};

Utils.checkAndRegisterTotango = () => {
  if (!window.totango_options.user) {
    const { userInfo } = userDetail;
    const { email, memberSubscription } = userInfo;
    const totOptions = {
      service_id: CONSTANTS.TOTANGO_SERVICE_ID,
      user: { id: email },
      disable_heartbeat: true,
    };
    if (memberSubscription && memberSubscription.totangoId) {
      totOptions.account = { id: memberSubscription.totangoId };
    }
    window.totango_options = totOptions;
    totango.go(window.totango_options);
  }
};

Utils.hubspotEventPush = (event = '', value = '') => {
  const config = {
    url: URLS.hubspotEventPush,
    method: 'POST',
    data: {
      event,
      value,
    },
  };
  makeApi(config);
};

Utils.totangoTrack = (activity = '', moduleValue = '') => {
  if (window.totango && Utils.isPaidSubscription()) {
    Utils.checkAndRegisterTotango();
    window.totango.track(activity, moduleValue, null, null);
  }
};

Utils.showCreditExpiredPopup = (
  type,
  remainingCredits = null,
  isCreditLimitReached = false,
) => {
  let modalValues = {};
  Utils.mixpanelTrack(CONSTANTS.MX_AS_EVENTS.AS_PAYWALL_UPGRADE_POPUP, {
    type,
  });
  const callback = () => {
    Utils.upgradeNowClick('paid credit exhausted');
  };

  if (
    (!Utils.isCreditsWithinMonthlyLimit() ||
      remainingCredits !== null ||
      isCreditLimitReached) &&
    userDetail.userInfo.lbCredits > 0
  ) {
    const message = userDetail.userInfo.isEnterpriseAdmin
      ? `You have reached a maximum limit of credits allotted for ${userDetail.userInfo.lbCreditsIndividualLimitFrequency === 'YEARLY' ? 'the year' : 'this month'}.`
      : `You have reached a maximum limit of credits allotted to you. ${
          remainingCredits !== null
            ? ` Please reduce the number of contacts purchased to ${remainingCredits} credits or contact your administrator for more credits`
            : 'Please contact your administrator for more credits.'
        }`;

    modalValues = {
      Icon: WarningIcon,
      title: 'Credit Limit Reached',
      description: message,
      primaryButtonText: 'Ok',
    };
  } else if (
    userDetail.userInfo.isEnterpriseAdmin ||
    Utils.getUserType() === 'FREE'
  ) {
    modalValues = {
      Icon: WarningIcon,
      title: `You've run out of credits`,
      description: "Don't miss out on your next big deal!",
      primaryButtonText: 'Upgrade Now',
      buttonCallback: callback,
    };
  } else {
    modalValues = {
      Icon: WarningIcon,
      title: 'No Credits Left!',
      description:
        'All the credits are utilized by your team members. Please contact your administrator for more details.',
      primaryButtonText: 'Ok',
    };
  }

  commonModalState.setShowModal(true, modalValues);
};

Utils.showEmailConfirmationPopup = (emailId) => {
  const callback = () => {
    const config = {
      url: URLS.resendEmailConfirmation,
      method: 'POST',
      data: {
        email: emailId,
      },
    };
    makeApi(config)
      .then((response) => {
        if (
          response &&
          response.data !== undefined &&
          response.data === 'true'
        ) {
          commonModalState.setShowModal(true, {
            title: 'Success',
            description:
              'Verification link has been sent! Check your inbox now. Make sure to check your spam/junk folder as well.',
            primaryButtonText: 'Ok',
          });
        }
      })
      .catch((error) => {
        console.log('myLeadsList error ', error);
      });
    // make call
  };

  commonModalState.setShowModal(true, {
    title: 'Please confirm your account',
    description: (
      <>
        You must confirm your account before purchasing contacts. Please check
        your inbox at <span>{emailId}</span> for your confirmation email. Make
        sure to check your spam/junk folder as well.
      </>
    ),
    primaryButtonText: "Can't see it",
    buttonCallback: callback,
  });
};

Utils.redirectToLbHomePage = () => {
  // eslint-disable-next-line no-undef
  window.location.href = LB_WEB_URL;
};

Utils.redirectToUrl = (pathname = null) => {
  if (pathname !== null) {
    const { navigate = null } = Utils.routerProps || {};
    if (navigate) {
      navigate(pathname);
    }
  }
};

Utils.redirectToLBPricingPage = () => {
  const { navigate = null } = Utils.routerProps || {};
  if (navigate) {
    navigate('/pricing');
  }
};

Utils.upgradeNowClick = (source) => {
  Utils.mixpanelTrack(CONSTANTS.MX_COMMON_EVENTS.LB_UPGRADE_CLICK, {
    Source: source,
  });
  Utils.redirectToLBPricingPage();
};

Utils.isPaidUser = () => {
  const { type } = userDetail.userInfo;

  if (type && type === 1) {
    return true;
  }

  return false;
};

Utils.isPaidSubscription = () => {
  const { isFreeTrial } = userDetail;
  return Utils.isPaidUser() ? !isFreeTrial : false;
};

Utils.isFreeTrialPlan = () => {
  const { isFreeTrial } = userDetail;
  return isFreeTrial;
};

Utils.isEnterpriseFreeTrialPlan = () => {
  const { isFreeTrial, isEnterpriseFreeTrial } = userDetail;
  return isFreeTrial && isEnterpriseFreeTrial;
};

Utils.isLBPaidUser = () => {
  const { type } = userDetail.userInfo;

  if (type && type === 1) {
    return true;
  }

  return false;
};

Utils.isLBPaidSubscription = () => {
  const { isFreeTrial, userInfo } = userDetail;
  const { type } = userInfo;

  if (type && type === 1 && !isFreeTrial) {
    return true;
  }

  return false;
};

Utils.isTrialUsed = () => {
  const { trialUsed } = userDetail.userInfo;

  if (trialUsed) {
    return true;
  }

  return false;
};

Utils.isPaymentPendingReview = () => {
  const { paymentPending } = userDetail.userInfo;

  if (paymentPending !== null) {
    return true;
  }

  return false;
};

Utils.showUserPricingPopup = () => {
  const { userInfo } = userDetail;
  const { userStatus, email } = userInfo;

  if (userStatus === 0) {
    Utils.redirectToLBPricingPage();
  } else {
    Utils.showEmailConfirmationPopup(email);
  }
};

Utils.generateRandomPayload = () => {
  const number1 = Math.floor(Math.random() * 10 + 1);
  const number2 = Math.floor(Math.random() * 10 + 1);
  const newObj = { n1: number1, n2: number2, n3: number1 + number2 };
  return newObj;
};

Utils.getQueryParamFromUrl = (params, url) => {
  const href = url;
  const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i');
  const queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

Utils.copyToClipBoard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

Utils.addDays = (currentDate, days) => {
  // eslint-disable-next-line radix
  currentDate.setDate(currentDate.getDate() + parseInt(days));
  return currentDate;
};

Utils.validateEmailFormat = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

Utils.getTrialExpiryDate = (noOfDays) => {
  const currentDate = new Date();
  let trialExpiryDate;
  if (noOfDays === undefined) {
    trialExpiryDate = this.addDays(currentDate, 7);
  } else {
    trialExpiryDate = this.addDays(currentDate, noOfDays);
  }

  const day = trialExpiryDate.getDate();
  const monthIndex = trialExpiryDate.getMonth();
  const year = trialExpiryDate.getFullYear();
  return `${CONSTANTS.monthNames[monthIndex]} ${day}, ${year}`;
};

Utils.getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

Utils.setCookie = (cname, cvalue, exdays, exHours = 24) => {
  const d = new Date();
  console.log('cutrent date  ', d);
  d.setTime(
    d.getTime() + exdays * 24 * 60 * 60 * 1000 + exHours * 60 * 60 * 1000,
  );
  console.log(' Expiry Date   ', d);

  const expires = `expires=${d.toUTCString()}`;
  let domain = window.location.host;
  if (domain.includes('localhost:3000')) {
    domain = 'localhost';
  } else {
    domain = Utils.getDomainName();
  }

  const curDomain = `domain=${domain};`;
  document.cookie = `${cname}=${cvalue};${curDomain}${expires};path=/`;
};

Utils.getDomainName = (url = window.location.host) => {
  const parts = url.split('.');
  if (parts.length > 2) {
    return `${parts[1]}.${parts[2]}`;
  }

  return '';
};

Utils.deleteCookie = (name) => {
  let domain = window.location.host;
  if (domain.includes('localhost:3000')) {
    domain = 'localhost';
  } else {
    domain = Utils.getDomainName();
  }

  const curDomain = `domain=${domain};`;
  document.cookie = `${name}=;${curDomain}expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
};

Utils.getIndustryKeywordArray = (data = []) => {
  const industryKeywordArray = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    if (data[i].sectors === null || data[i].sectors.length <= 0) {
      const industryKW = data[i].industries;
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < industryKW.length; j++) {
        if (industryKeywordArray.indexOf(industryKW[j]) === -1) {
          industryKeywordArray.push(industryKW[j]);
        }
      }
    }
  }

  return industryKeywordArray;
};

Utils.getSectorKeywordArray = (data = []) => {
  const sectorKeywordArray = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    if (data[i].sectors !== null && data[i].sectors.length > 0) {
      const sectorKW = data[i].sectors;
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < sectorKW.length; j++) {
        if (sectorKeywordArray.indexOf(sectorKW[j]) === -1) {
          sectorKeywordArray.push(sectorKW[j]);
        }
      }
    }
  }

  return sectorKeywordArray;
};

Utils.extractValuesFromObject = (item) => {
  return Object.values(item);
};

Utils.copyObjectWithoutReference = (obj = {}) => {
  return JSON.parse(JSON.stringify(obj));
};

// eslint-disable-next-line consistent-return
Utils.checkAndInsertAt = (array = [], index, newElem) => {
  if (array.length && index && newElem) {
    if (!array.includes(newElem)) {
      array.splice(index, 0, newElem);
    }
    return array;
  }
  return array;
};

Utils.numberformatWithText = (
  number,
  text = '',
  addBoldTag = false,
  isAddZero = null,
) => {
  let forStr = '';
  if (number !== undefined && number !== null) {
    if (isAddZero !== null) {
      forStr = isAddZero + number;
    } else {
      forStr = number;
    }
    if (addBoldTag) {
      forStr = `<b>${forStr}</b>`;
    }

    forStr += text + (number > 1 && text !== '' ? 's' : '');
  }

  return forStr;
};

Utils.validateStringLength = (value, len) => {
  const str = value.trim();
  if (
    str &&
    str !== undefined &&
    str !== '' &&
    str.length > 0 &&
    str.length >= len
  ) {
    return true;
  }

  return false;
};

Utils.isValidPhone = (phone) => {
  const numberPattern = /[0-9]/g;
  const matchNo = phone.match(numberPattern);
  if (matchNo) {
    return phone.match(numberPattern).length > 6;
  }

  return false;
};

Utils.formatNumberToDecimalPoints = (number = 1, places = 2) => {
  return (Math.round(number * 100) / 100).toFixed(places);
};

Utils.getLockFilterBtnText = () => {
  return 'Upgrade now';
};

Utils.checkValueInObject = (obj = null, key = null, value) => {
  if (obj !== null && key !== null && obj[key] === value) {
    return true;
  }

  return false;
};

Utils.generateCustomS3Scriptpath = (path) => {
  if (path) {
    const newPath = CONSTANTS.S3_BASE_PATH + CONSTANTS.S3_BUCKET_PATH + path;
    return newPath;
  }
  return '';
};

Utils.isProductionEnv = () => {
  if (process.env.BUILD_ENV && process.env.BUILD_ENV === 'production') {
    return true;
  }

  return false;
};

Utils.isStagingEnv = () => {
  if (process.env.BUILD_ENV && process.env.BUILD_ENV === 'staging') {
    return true;
  }

  return false;
};

Utils.isLocalEnv = () => {
  if (process.env.BUILD_ENV && process.env.BUILD_ENV === 'local') {
    return true;
  }

  return false;
};

Utils.addSessionRecordingForTrialUser = () => {
  const { isFreeTrial, userInfo } = userDetail;
  const { email } = userInfo;
  if (isFreeTrial && Utils.isProductionEnv()) {
    const script = document.createElement('script');
    script.src = Utils.generateCustomS3Scriptpath(
      CONSTANTS.EXTERNAL_S3_SCRIPTS.INSPECTLET_SESSION_RECORDING,
    );
    script.async = true;
    document.body.appendChild(script);

    const intervalObj = setInterval(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (window.__insp) {
        clearInterval(intervalObj);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['identify', email]);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['tagSession', { email }]);
      }
    }, 100);
  }
};

Utils.addSessionRecordingForLbPaidUser = () => {
  const { userInfo } = userDetail;
  const { email } = userInfo;
  if (Utils.isProductionEnv()) {
    const script = document.createElement('script');
    script.src = Utils.generateCustomS3Scriptpath(
      CONSTANTS.EXTERNAL_S3_SCRIPTS.INSPECTLET_SESSION_RECORDING,
    );
    script.async = true;
    document.body.appendChild(script);

    const intervalObj = setInterval(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (window.__insp) {
        clearInterval(intervalObj);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['identify', email]);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['tagSession', { email }]);
      }
    }, 100);
  }
};

Utils.addSessionRecordingForPricingRedirectionFlow = () => {
  const { userInfo } = userDetail;
  const { email } = userInfo;
  if (Utils.isProductionEnv()) {
    const script = document.createElement('script');
    script.src = Utils.generateCustomS3Scriptpath(
      CONSTANTS.EXTERNAL_S3_SCRIPTS.INSPECTLET_SESSION_RECORDING,
    );
    script.async = true;
    document.body.appendChild(script);

    const intervalObj = setInterval(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (window.__insp) {
        clearInterval(intervalObj);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['identify', email]);
        // eslint-disable-next-line no-underscore-dangle
        window.__insp.push(['tagSession', { email }]);
      }
    }, 100);
  }
};

Utils.checkEmailDomain = (email, domain) => {
  const emailAddress = email.toLowerCase();
  if (emailAddress && domain) {
    const newDomain = emailAddress.split('@').pop();
    if (newDomain && newDomain.indexOf(domain) >= 0) {
      return false;
    }
  }
  return true;
};

Utils.upperCaseFirstCharacter = (str = '') => {
  let newStr = str;
  if (str !== '') {
    newStr = str.substring(0, 1).toUpperCase() + str.substring(1, str.length);
  }

  return newStr;
};

Utils.generateUrl = (url, data = {}) => {
  if (url) {
    let newUrl = '';
    const splitUrl = url.split('?');
    if (splitUrl[0]) {
      newUrl += splitUrl[0];
      const dynamicParams = newUrl.match(/(<<[^<>]*>>)/g);
      if (dynamicParams && dynamicParams.length > 0) {
        dynamicParams.forEach((item) => {
          newUrl = newUrl.replace(item, data[item.replace(/[<>]/g, '')]);
        });
      }
    }

    if (splitUrl[1]) {
      newUrl += '?';
      const paramSplit = splitUrl[1].split('&');
      paramSplit.forEach((item, index) => {
        if (item.includes('::')) {
          const splitParamKey = item.split('=')[0];
          if (data[splitParamKey] !== undefined) {
            if (index > 0) {
              newUrl += '&';
            }
            newUrl = `${newUrl + splitParamKey}=${data[splitParamKey]}`;
          }
        } else {
          if (index > 0) {
            newUrl += '&';
          }
          newUrl += item;
        }
      });
    }

    return newUrl;
  }
  return '';
};

Utils.refreshPage = () => {
  window.location.reload();
};

Utils.loadRegistrationTrackingCode = () => {
  const { THIRD_PARTY_SCRIPTS } = CONSTANTS;
  const { REGISTRATION_TRACKING } = THIRD_PARTY_SCRIPTS;
  const { ID, SCRIPT_URL } = REGISTRATION_TRACKING;
  loadScriptsDynamically(
    ID,
    SCRIPT_URL,
    () => {
      console.log('registration tracking loaded');
    },
    'img',
  );
};

// super admin/ Adapt internal employee admins
Utils.isInternalAdminUser = () => {
  const { userInfo } = userDetail;
  const { role = 'ROLE_MEMBER' } = userInfo;
  if (role === 'ROLE_ADMIN') {
    return true;
  }
  return false;
};

Utils.getUserType = () => {
  const { isFreeTrial } = userDetail;

  const userType = Utils.isPaidUser()
    ? isFreeTrial
      ? 'TRIAL'
      : 'PAID'
    : 'FREE';

  return userType;
};

Utils.getPlanType = () => {
  const { userInfo, isFreeTrial } = userDetail;
  const { memberSubscription } = userInfo;

  const planType =
    memberSubscription && !isFreeTrial
      ? memberSubscription.frequencyType === 0
        ? 'Monthly'
        : 'Yearly'
      : '';

  return planType;
};

Utils.getSecondaryHsEmail = () => {
  const { secondaryEmail = '', secondaryHsEmail = '' } = userDetail.userInfo;

  return secondaryEmail || secondaryHsEmail;
};

Utils.getPlanAmount = () => {
  const { userInfo } = userDetail;
  const { memberSubscription } = userInfo;

  const planAmount = memberSubscription ? memberSubscription.price : 0;

  return planAmount;
};

Utils.sendAttributeToHotjar = () => {
  if (window.hj) {
    const { memberId, email } = userDetail.userInfo;

    const userType = Utils.getUserType();
    const secondaryHsEmail = Utils.getSecondaryHsEmail();
    const planAmount = Utils.getPlanAmount();
    const planType = Utils.getPlanType();

    window.hj('identify', memberId, {
      email,
      userType,
      secondaryEmail: secondaryHsEmail,
      planAmount,
      planType,
    });
  }
};

Utils.checkIsEmailVerified = (showPopup = true) => {
  const { userInfo } = userDetail;
  const { userStatus, email } = userInfo;
  if (userStatus !== 0) {
    if (showPopup) {
      Utils.showEmailConfirmationPopup(email);
    }
    return false;
  }
  return true;
};

Utils.checkIsSecondaryEmailVerified = () => {
  const { userInfo } = userDetail;
  const { secondaryEmail, secondaryEmailStatus } = userInfo;
  if (secondaryEmail && secondaryEmailStatus !== 0) {
    return false;
  }
  return true;
};

Utils.getUserEmail = () => {
  const { userInfo } = userDetail;
  const { email } = userInfo;

  return email;
};

Utils.getUserSecondaryEmail = () => {
  const { userInfo } = userDetail;
  const { secondaryEmail = '' } = userInfo;

  return secondaryEmail;
};

Utils.getSuppressionListEmailLimit = () => {
  const { userInfo } = userDetail;
  const { suppressionListEmailLimit } = userInfo;
  if (suppressionListEmailLimit) {
    return suppressionListEmailLimit;
  }
  return 25000;
};

Utils.checkIfDateIsBiggerThanCompareDate = (date, compareDate = new Date()) => {
  if (compareDate.getTime() > date.getTime()) {
    return true;
  }
  return false;
};

Utils.isNotUndefinedNotNull = (value) => {
  if (value !== undefined && value !== null) {
    return true;
  }
  return false;
};

Utils.showActivateAccountFlow = () => {
  Utils.redirectToUrl('/activate-account');
  userDetail.setShowActivateAccountPage(true);
};

Utils.checkAndShowAccountActivationToast = () => {
  setTimeout(() => {
    if (Utils.getCookie('account_verification_flow') === 'true') {
      toasterState.setToastMsg(
        'Welcome aboard! Your account activation is successful.',
        'success',
        { timeout: 5000 },
      );
      Utils.deleteCookie('account_verification_flow');
    }
  }, 200);
};

Utils.checkAndShowTrialActivationToast = () => {
  setTimeout(() => {
    if (Utils.getCookie('trial_activate_success') === 'true') {
      toasterState.setToastMsg('Your trial is now activated.', 'success', {
        timeout: 5000,
      });
      Utils.deleteCookie('trial_activate_success');
    }
  }, 200);
};

Utils.isCrmEnrichmentEnabled = () => {
  return featureFlagsAndPreferencesState.isCRMExportEnabled();
};

Utils.getCustomPagePath = (path) => {
  if (path) {
    return `${window.location.origin}${PAGE_PATH_MAP[path]}`;
  }
  return '';
};

const aDayInMilliSeconds = 86400000;

Utils.checkAndShowG2Popup = () => {
  const { userInfo } = userDetail;
  const { memberSubscription, lbCredits } = userInfo;
  if (memberSubscription?.id) {
    const { creationDate } = memberSubscription;
    const { g2Reviewed } = featureFlagsAndPreferencesState.actions ?? {};

    const currentDate = Number(new Date());
    const subscriptionCreationDate = Number(new Date(creationDate));

    const { lbCredits: allocatedCredits, planType } = memberSubscription ?? {};

    const dateDiff =
      (currentDate - subscriptionCreationDate) / aDayInMilliSeconds;

    const sessionTimeInMins =
      (currentDate - performance.timeOrigin) / (1000 * 60);

    if (
      !g2Reviewed &&
      ((planType === 'TRIAL' &&
        allocatedCredits - lbCredits > 10 &&
        dateDiff >= 3) ||
        (planType === 'SELF_SERVE_PAID_PLAN' &&
          allocatedCredits - lbCredits > 50 &&
          dateDiff >= 15) ||
        (planType === 'CUSTOM_PAID_PLAN' &&
          allocatedCredits - lbCredits > 200 &&
          dateDiff >= 30)) &&
      sessionTimeInMins >= 5
    ) {
      if (localStorage.getItem('g2') === null) {
        userDetail.setShowG2Popup(true);
        Utils.setLocalStorage('g2', {
          lastShown: Number(new Date()),
          count: 1,
          reviewed: false,
        });
      } else if (localStorage.getItem('g2') !== null) {
        const previousData = Utils.getLocalStorage('g2');
        const { lastShown, count, reviewed = false } = previousData;
        if ((currentDate - lastShown) / aDayInMilliSeconds > 30 && !reviewed) {
          userDetail.setShowG2Popup(true);
          Utils.setLocalStorage('g2', {
            lastShown: currentDate,
            count: count + 1,
            reviewed,
          });
        }
      }
    }
  }
};

Utils.checkFieldMappingTypeSupport = (adaptType, crmType) => {
  const { ENUM, STRING } = CONSTANTS.CRM_FIELD_TYPES;
  if (adaptType === crmType || (adaptType === ENUM && crmType === STRING)) {
    return true;
  }

  return false;
};

Utils.getProspectorUserTrigger = (url) => {
  return Utils.getQueryParamFromUrl('trigger', url);
};

Utils.addQueryParamToUrl = (key, value) => {
  const { navigate = null } = Utils.routerProps || {};
  const url = new URL(window.location.href);
  const { searchParams } = url;
  searchParams.set(key, value);
  url.search = searchParams.toString();

  const newUrl = decodeURIComponent(url.toString());
  const { pathname = '', search = '', hash = '' } = new URL(newUrl);

  if (navigate && key) {
    navigate(`${pathname}${search}${hash}`);
  }
};

Utils.removeQueryParamToUrl = (key) => {
  const { navigate = null } = Utils.routerProps || {};
  const url = new URL(window.location.href);
  const { searchParams } = url;
  searchParams.delete(key);
  url.search = searchParams.toString();

  const newUrl = decodeURIComponent(url.toString());
  const { pathname = '', search = '', hash = '' } = new URL(newUrl);

  if (navigate && key) {
    navigate(`${pathname}${search}${hash}`);
  }
};

Utils.isCreditsWithinMonthlyLimit = () => {
  const { remainingIndividualLbCredits } = userDetail.userInfo;
  if (
    remainingIndividualLbCredits === -1 ||
    remainingIndividualLbCredits === null ||
    remainingIndividualLbCredits === undefined ||
    !featureFlagsAndPreferencesState.isTeamManageEnabled()
  ) {
    return true;
  }
  return remainingIndividualLbCredits > 0;
};

Utils.openManageBillingPage = () => {
  window.open(Utils.getCustomPagePath('BILLING'), '_blank');
};

Utils.isProspectorRegisteredUser = () => {
  const { USER_SOURCE } = CONSTANTS;
  const { ADAPT_PROSPECTOR } = USER_SOURCE;
  const { userInfo } = userDetail;
  const { source } = userInfo;

  if (source === ADAPT_PROSPECTOR) {
    return true;
  }
  return false;
};

Utils.checkPasswordSetupFlow = () => {
  const { userInfo } = userDetail;
  const { isSetPassword } = userInfo;
  if (Utils.checkIsEmailVerified(false) && isSetPassword) {
    return true;
  }
  return false;
};

Utils.showPasswordSetupFlow = () => {
  Utils.redirectToUrl('/password-setup');
};

Utils.checkAndRedirectTrialUser = () => {
  const landingURL = Utils.getCookie(SEOCookieQueryMapping.landingURL);
  if (landingURL !== null) {
    Utils.deleteCookie(SEOCookieQueryMapping.landingURL);
    if (PAGE_PATH_MAP.CONTACT_SEARCH.includes(landingURL)) {
      window.location.href = `${LB_WEB_URL}${PAGE_PATH_MAP.CONTACT_SEARCH}`;
    } else {
      window.location.href = `${LB_WEB_URL}${landingURL}`;
    }
  } else {
    window.location.href = `${LB_WEB_URL}${PAGE_PATH_MAP.DASHBOARD}`;
  }
};

Utils.enterprisePlanInterestNotification = (
  notificationType = '',
  callback = () => {},
) => {
  const url = Utils.generateUrl(URLS?.notifyEnterprisePlanInterest, {
    notificationType,
  });
  const config = {
    url,
  };

  makeApi(config)
    .then((response) => {
      callback();
      const status = response && response.data;
      Utils.showFeedbackPopup(status);
    })
    .catch(() => {
      callback();
      Utils.showFeedbackPopup(false);
    });
};

Utils.showFeedbackPopup = (success, email = 'support@adapt.io') => {
  let Icon = LifeSaverIcon;
  let description = (
    <>
      A Growth Expert from Adapt will reach out to you shortly. If you have any
      prior questions, please write to us at{' '}
      <a href={`mailto:${email}`}>{email}</a>
    </>
  );
  let title = 'Awesome! Help is on the way.';
  if (!success) {
    Icon = WarningIcon;
    description = (
      <>
        Please try again in some time or if you have any prior questions please
        write to us at <a href={`mailto:${email}`}>{email}</a>
      </>
    );
    title = 'Oops! Something went wrong!';
  }
  commonModalState.setShowModal(true, {
    modalProps: {
      align: success ? 'center' : 'left',
      className: success ? 'feedback-popup' : '',
    },
    Icon,
    title,
    description,
    primaryButtonText: 'Ok',
  });
};

Utils.getActiveSubscriptionId = () => {
  const { userInfo } = userDetail;
  const { memberSubscription = null } = userInfo;

  if (memberSubscription) {
    const { id } = memberSubscription;
    return id;
  }
  return null;
};

Utils.getUserName = () => {
  const { userInfo } = userDetail;
  const { email = '', name = '' } = userInfo;

  const nameStr =
    name || (email && email.includes('@') ? email.split('@')[0] : '');
  return nameStr;
};

Utils.openNewTab = (url) => {
  window.open(url, '_blank');
};

Utils.checkIsRefinerAllowed = () => {
  if (
    Utils.isFreeTrialPlan() &&
    !Utils.isEnterpriseFreeTrialPlan() &&
    Utils.isProductionEnv()
  ) {
    return true;
  }
  return false;
};

Utils.setRefinerProject = () => {
  if (Utils.checkIsRefinerAllowed()) {
    _refiner('setProject', '1a9cf9a0-9de2-11ec-b9cd-e953878f83f2');
  }
};

Utils.registerRefiner = (property = {}) => {
  const { userInfo } = userDetail;
  const { name, email, pageSource } = userInfo;
  const { trialEndDays } = trialUserOnboardingState;
  const {
    LB_REF_PROPERTY_USER_PAGE_SOURCE,
    LB_REF_PROPERTY_NO_OF_DAYS_LEFT_IN_TRIAL,
  } = REFINER_EVENTS_AND_PROPERTIES;

  const data = {
    id: email,
    email,
    name,
    [LB_REF_PROPERTY_USER_PAGE_SOURCE]: pageSource,
    [LB_REF_PROPERTY_NO_OF_DAYS_LEFT_IN_TRIAL]: trialEndDays,
    ...property,
  };

  try {
    _refiner('identifyUser', data);
  } catch (e) {
    console.error('_refiner not defined ', e);
  }
};

Utils.trackRefinerProperty = (property = {}) => {
  if (property) {
    Utils.registerRefiner(property);
  }
};

Utils.addRemainingCreditsToRefinerProperty = (data) => {
  const { userInfo } = userDetail;
  const { lbCredits } = userInfo;
  const newObj = data || {};
  const { LB_REF_PROPERTY_CREDITS_REMAINING } = REFINER_EVENTS_AND_PROPERTIES;

  newObj[LB_REF_PROPERTY_CREDITS_REMAINING] = lbCredits;

  return newObj;
};

Utils.trackRefinerSearchPerformedCount = () => {
  if (Utils.checkIsRefinerAllowed()) {
    const { LB_REF_PROPERTY_SEARCH_PERFORMED } = REFINER_EVENTS_AND_PROPERTIES;
    let searchPerformedCount = Utils.getCookie('searchPerformed');

    if (searchPerformedCount) {
      searchPerformedCount = parseInt(searchPerformedCount, 10);
      searchPerformedCount += 1;
    } else {
      searchPerformedCount = 1;
    }
    Utils.setCookie('searchPerformed', searchPerformedCount);
    let data = {
      [LB_REF_PROPERTY_SEARCH_PERFORMED]: searchPerformedCount,
    };
    data = Utils.addRemainingCreditsToRefinerProperty(data);
    Utils.trackRefinerProperty(data);
  }
};

Utils.trackRefinerContactOrCompanyFilterApplied = (
  appliedFilterData = {},
  searchCount,
) => {
  if (Utils.checkIsRefinerAllowed()) {
    const { companyName = [], name = [] } = appliedFilterData;
    if ((companyName && companyName.length > 0) || (name && name.length > 0)) {
      const {
        LB_REF_PROPERTY_CONTACT_OR_COMPANY_NAME_FILTER_APPLIED,
        LB_REF_PROPERTY_SEARCH_RESULT_COUNT,
      } = REFINER_EVENTS_AND_PROPERTIES;
      Utils.trackRefinerProperty({
        [LB_REF_PROPERTY_CONTACT_OR_COMPANY_NAME_FILTER_APPLIED]: true,
        [LB_REF_PROPERTY_SEARCH_RESULT_COUNT]: searchCount,
      });
    }
  }
};

Utils.trackRefinerPricingPageViewed = () => {
  if (Utils.checkIsRefinerAllowed()) {
    const { LB_REF_PROPERTY_PRICING_VISITED } = REFINER_EVENTS_AND_PROPERTIES;
    let data = {
      [LB_REF_PROPERTY_PRICING_VISITED]: true,
    };
    data = Utils.addRemainingCreditsToRefinerProperty(data);
    Utils.trackRefinerProperty(data);
  }
};

Utils.trackRefinerRemainingCredits = () => {
  if (Utils.checkIsRefinerAllowed()) {
    const data = Utils.addRemainingCreditsToRefinerProperty({});

    setTimeout(() => {
      Utils.trackRefinerProperty(data);
    }, 2000);
  }
};

Utils.getFormattedDate = (dateNumber, showRecentText = false) => {
  if (dateNumber) {
    const date = format(new Date(dateNumber), 'MMM dd, yyyy');
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const currentDate = new Date();
    let modifiedDate = date;
    if (showRecentText) {
      if (date === format(currentDate, 'MMM dd, yyyy')) {
        modifiedDate = 'Today';
      } else if (date === format(yesterday, 'MMM dd, yyyy')) {
        modifiedDate = 'Yesterday';
      }
    }
    return modifiedDate;
  }
  return '';
};

Utils.getSupportEmail = () => {
  if (Utils.isLBPaidSubscription()) {
    return 'success@adapt.io';
  }
  if (Utils.isLBPaidUser()) {
    return 'sales@adapt.io';
  }
  return 'support@adapt.io';
};

Utils.debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(async () => {
        resolve(await func.apply(this, args));
      }, timeout);
    });
  };
};

Utils.toggleDriftVisibility = (hideDrift) => {
  if (hideDrift) {
    document.getElementsByTagName('body')?.[0]?.classList.add('hide-drift');
  } else {
    document.getElementsByTagName('body')?.[0]?.classList.remove('hide-drift');
  }
};

Utils.showEURestrictionPopup = () => {
  commonModalState.setShowModal(true, {
    title: 'Access Restricted',
    description:
      'Access to European contacts is restricted. Contact us to get access.',
    primaryButtonText: 'Request Access',
    buttonCallback: () => {
      window.location.hash = '#request-access';
    },
  });
};

Utils.showCrmConnectionError = (props) => {
  const { showPopup, responseCode, redirectToIntegrations } = props;
  const { name } = userDetail.userInfo;
  const newProps = { ...props, userName: name };
  if (showPopup) {
    const actionBtnClick = () => {
      if (redirectToIntegrations) {
        window.open(Utils.getCustomPagePath('INTEGRATIONS'), '_blank');
      }
    };
    let popupValues = {};
    if (responseCode === 403) {
      popupValues = getAdminConnectedToDifferentCrmMsg(newProps);
    } else if (responseCode === 412) {
      popupValues = getAdminNotConnectedMsg(newProps);
    } else if (responseCode === 303) {
      popupValues = getUserNotConnectedMsg(newProps);
    } else if (responseCode === 407) {
      popupValues = getAuthorizationRequiredMsg(newProps);
    }
    const { title, description, btnText } = popupValues;
    commonModalState.setShowModal(true, {
      title,
      description,
      primaryButtonText: btnText,
      buttonCallback: actionBtnClick,
      modalProps: {
        className: 'crm-connection-error',
      },
    });
  }
};

Utils.showRefreshAndContactSupportPopup = () => {
  commonModalState.setShowModal(true, {
    title: 'Oops! Something went wrong',
    Icon: WarningIcon,
    description: (
      <>
        Please refresh the page and try again. If you face the error again,
        write to us at <a href="mailto:support@adapt.io">support@adapt.io</a>.
      </>
    ),
    primaryButtonText: 'Ok',
  });
};

Utils.getPreviousMonthData = (date, length) => {
  return Array.from({ length }).reduce((result, item, index) => {
    const previousDate = new Date(
      date.getFullYear(),
      date.getMonth() - index,
      1,
    );
    const createdDate = new Date(
      userDetail.userInfo.memberSubscription?.creationDate,
    );
    if (
      previousDate <
      new Date(createdDate.getFullYear(), createdDate.getMonth(), 1)
    ) {
      return result;
    }
    return [
      `${
        CONSTANTS.monthNames[previousDate.getMonth()]
      } ${previousDate.getFullYear()}`,
      ...result,
    ];
  }, []);
};

Utils.capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

Utils.getStartDateComparedToSubscriptionCreatedDate = (date) => {
  const subscriptionCreationDate = userDetail.userInfo.memberSubscription
    ? new Date(userDetail.userInfo.memberSubscription?.creationDate)
    : date;

  if (
    userDetail.userInfo.memberSubscription &&
    date < subscriptionCreationDate
  ) {
    return subscriptionCreationDate;
  }
  return date;
};

Utils.getValidationStatusTextForValue = (zbStatus) => {
  return (
    CONSTANTS.VALIDATION_STATUS.find((statusObj) => {
      return statusObj.values.includes(zbStatus);
    })?.text ?? ''
  );
};

Utils.getPaginationFromParams = (params, sizeList) => {
  const parsedSize = parseInt(params.get('size') ?? 1);
  return {
    page: parseInt(params.get('page') ?? 1),
    size: sizeList
      ? sizeList.some((sizeData) => {
          return sizeData.value === parsedSize;
        })?.value ?? sizeList[0].value
      : parsedSize,
  };
};

Utils.getValueFromKeys = (data, path) => {
  return path.split('.').length > 1
    ? Utils.getValueFromKeys(
        data[path.substring(0, path.indexOf('.'))],
        path.substring(path.indexOf('.') + 1, path.length),
      )
    : Array.isArray(data)
      ? (data ?? {})[parseInt(path)]
      : (data ?? {})[path] ?? '';
};

export default Utils;
