import React from 'react';
import BarChartIcon from 'Assets/svg/bar-chart2.svg';
import RightArrowIcon from 'Assets/svg/right-arrow.svg';
import Utils from 'Utils/utils';
import Button from '../base/Button';
import './styles.scss';

function UpgradeOverlay(props) {
  const {
    Icon = BarChartIcon,
    text = 'Upgrade your account to see your usage.',
  } = props;

  return (
    <div className="upgrade-overlay">
      <div className="icon-wrapper">
        <Icon />
      </div>
      <div className="upgrade-text">{text}</div>
      <Button
        buttonProps={{ text: 'Upgrade now' }}
        variant="outline"
        Icon={RightArrowIcon}
        onClick={Utils.redirectToLBPricingPage}
      />
    </div>
  );
}

export default UpgradeOverlay;
